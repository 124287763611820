import { useMemo } from "react";
import { useRentHousesSupportChat } from "../../../../api/hooks/rentHousesSupport";
import { isRentHousesSupportChatEnabled } from "../../../../constants/feature-toggles";
import { RentHousesSupportChatStatus } from "../../../../types/rentHousesSupport";
import useSupportChatModal from "../dialog/support/useSupportChatModal";
import { HeaderTab } from "@russpass/partner-front-ui";

export const useNavbar = (): HeaderTab[] | undefined => {
    const { data } = useRentHousesSupportChat();

    const { open: openSupportChat } = useSupportChatModal();

    return useMemo(
        () =>
            isRentHousesSupportChatEnabled
                ? ([
                      {
                          label: "Гости",
                          name: "navbarBookingNames.pending",
                      },
                      {
                          label: "Поддержка",
                          name: "navbarBookingNames.active",
                          alert: data?.data?.userStatus === RentHousesSupportChatStatus.NEW_MESSAGE,
                          onClick: openSupportChat,
                      },
                  ] as HeaderTab[])
                : undefined,
        [data?.data?.userStatus, openSupportChat]
    );
};
