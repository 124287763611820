import React, { FC, useMemo, useRef, useCallback } from "react";
import { Form, Formik, FormikProps } from "formik";
import {
    InputField,
    InputListField,
    TextareaField,
    Checkbox,
    MultiMenuRef,
    AutoSubmit,
    SelectInputField,
    DatePickerField,
} from "@russpass/partner-front-ui";
import { maskedInputNumbers } from "../../../../utils/validation";
import { TourDataExpanded } from "../types";
import Region from "../../../../components/async-selects/region";
import { navbarTourNames } from "../constants";
import { tourInfoValidation } from "../validation-schema";
import styles from "./tour.module.sass";
import { EXTENDED_DATE_TIME_FORMATZ, DOTTED_DATE_FORMAT_FNS } from "../../../../constants/dates-format";
import { AgeRestriction } from "../../service//helpers/types";
import SelectCity from "../../../../components/async-selects/select-city";
import { TSelectValue } from "../../../../types/dictionaries";

const ALL_YEAR_TITLE = "Круглый год";

type TourInfoProps = {
    data: TourDataExpanded;
    onChange: (values: TourDataExpanded) => void;
    onSubmit: (path: string) => void;
    isShowErrors: boolean;
    region: TSelectValue;
    onChangeRegion: (region: TSelectValue) => void;
    city: TSelectValue;
    onChangeCity: (city: TSelectValue) => void;
    disabled: boolean;
    optionsAgeRestriction: AgeRestriction[];
};

const TourInfo: FC<TourInfoProps> = ({
    data,
    onChange,
    onSubmit,
    isShowErrors,
    region,
    onChangeRegion,
    city,
    onChangeCity,
    disabled,
    optionsAgeRestriction,
}) => {
    const menuRefSeasonStart = useRef<MultiMenuRef>(null);
    const menuRefSeasonEnd = useRef<MultiMenuRef>(null);

    const actionsComponent = (
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
        fieldName: "seasonStart" | "seasonEnd"
    ) => {
        return (
            <div className={styles.actions}>
                <Checkbox
                    id="1"
                    label={ALL_YEAR_TITLE}
                    checked={data.isAllYear}
                    handleChange={(isChecked) => {
                        if (isChecked) {
                            fieldName === "seasonStart" && menuRefSeasonStart.current?.closeMenu();
                            fieldName === "seasonEnd" && menuRefSeasonEnd.current?.closeMenu();
                        }
                        setFieldValue("isAllYear", !data.isAllYear);
                        setFieldValue("seasonStart", "");
                        setFieldValue("seasonEnd", "");
                    }}
                />
            </div>
        );
    };
    const disabledDays = useMemo(() => {
        const today = new Date();
        return data.isAllYear
            ? [
                  today,
                  {
                      before: today,
                      after: today,
                  },
              ]
            : {
                  before: today,
                  after: undefined,
              };
    }, [data]);

    const handleFormSubmit = useCallback(() => {
        onSubmit(navbarTourNames.tariffs);
    }, [onSubmit]);

    return (
        <div className="content-wrapper">
            <Formik
                validateOnBlur
                initialValues={data}
                validationSchema={tourInfoValidation}
                enableReinitialize
                validateOnMount
                onSubmit={handleFormSubmit}
            >
                {(form: FormikProps<TourDataExpanded>) => {
                    const { isValid, setFieldValue, values } = form;
                    return (
                        <Form>
                            <div className="content-wrapper__form">
                                <AutoSubmit onChange={onChange} />
                                <InputField
                                    name="title"
                                    noHover
                                    disabled={disabled}
                                    label={"Название"}
                                    placeholder={"Зимняя сказка Байкала"}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                />
                                <TextareaField
                                    label="Описание"
                                    name="description"
                                    noHover
                                    maxRows={3}
                                    placeholder="Максимально 400 символов"
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    maxLength={400}
                                />
                                <Region
                                    name="region"
                                    value={region}
                                    disabled={disabled}
                                    placeholder="Иркутская область"
                                    handleChange={(value) => {
                                        onChangeRegion(value);
                                        setFieldValue("region", value.id);
                                    }}
                                    isShowErrors={isShowErrors}
                                />
                                <SelectCity
                                    name="city"
                                    value={city}
                                    disabled={disabled}
                                    placeholder="Иркутск"
                                    hint="Если в туре несколько городов укажите город отправления"
                                    handleChange={(value) => {
                                        onChangeCity(value);
                                        setFieldValue("city", value.id);
                                    }}
                                    isShowErrors={isShowErrors}
                                />
                                <InputField
                                    label="Количество ночей"
                                    name="nights"
                                    noHover
                                    placeholder="3"
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    renderComponent={maskedInputNumbers}
                                    maxLength={3}
                                />
                                <InputField
                                    label="Численность группы"
                                    noHover
                                    name="minGroupCount"
                                    placeholder="10-20 чел"
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    renderComponent={maskedInputNumbers}
                                    maxLength={6}
                                />
                                <DatePickerField
                                    ref={menuRefSeasonStart}
                                    label="Начало туристического сезона"
                                    name="seasonStart"
                                    placeholder="Выберите"
                                    disabled={disabled}
                                    emptyValue={values.isAllYear ? ALL_YEAR_TITLE : ""}
                                    handleChange={(date) => {
                                        setFieldValue("seasonStart", date);
                                    }}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    valueFormat={EXTENDED_DATE_TIME_FORMATZ}
                                    inputFormat={DOTTED_DATE_FORMAT_FNS}
                                    noHover
                                    numberOfMonths={1}
                                    isClearable={false}
                                    disabledDays={disabledDays}
                                    value={values.seasonStart}
                                    actionsComponent={actionsComponent(setFieldValue, "seasonStart")}
                                />
                                <DatePickerField
                                    ref={menuRefSeasonEnd}
                                    label="Конец туристического сезона"
                                    name="seasonEnd"
                                    disabled={disabled}
                                    noHover
                                    emptyValue={values.isAllYear ? ALL_YEAR_TITLE : ""}
                                    isClearable={false}
                                    placeholder="Выберите"
                                    valueFormat={EXTENDED_DATE_TIME_FORMATZ}
                                    inputFormat="dd.MM.yyyy"
                                    handleChange={(date) => {
                                        setFieldValue("seasonEnd", date);
                                    }}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    disabledDays={disabledDays}
                                    numberOfMonths={1}
                                    value={values.seasonEnd}
                                    actionsComponent={actionsComponent(setFieldValue, "seasonEnd")}
                                />
                                <SelectInputField
                                    name="minAge"
                                    handleChange={(value) => {
                                        setFieldValue("minAge", value.name);
                                    }}
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    options={optionsAgeRestriction}
                                    value={{ name: values.minAge }}
                                    valueField="name"
                                    labelField="name"
                                    placeholder="Выберите"
                                    isChecked
                                    label="Возрастное ограничение"
                                />
                                <InputListField
                                    uniquePrefixId="inc"
                                    name="included"
                                    label="В стоимость включено"
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    disabled={disabled}
                                    placeholderItems={["Опишите по пунктам"]}
                                    hint="Пишите каждый новый пункт с новой строчки"
                                />
                                <InputListField
                                    uniquePrefixId="paid"
                                    name="paidSeparately"
                                    label="Оплачивается отдельно"
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    disabled={disabled}
                                    placeholderItems={["Опишите по пунктам"]}
                                    hint="Пишите каждый новый пункт с новой строчки"
                                />
                            </div>
                            {isValid && !disabled && (
                                <div className="mt-32">
                                    <button
                                        type="submit"
                                        className="button button--second button--full-width"
                                        data-qa-name="next-button"
                                    >
                                        Далее
                                    </button>
                                </div>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default TourInfo;
