import { object as objectYup, string as stringYup, array as arrayYup } from "yup";
import { timePeriodRegexp } from "../../../utils/validation";
import { FORM_FIELD_REQUIRED_ERROR, FORMAT_ERROR } from "../../../constants/errors";

export const placeTimetableValidation = (validationWithBreakTime: boolean, validateWithoutTime: boolean = false) => {
    return objectYup().shape({
        workingDays: arrayYup().min(1, FORM_FIELD_REQUIRED_ERROR),
        time: arrayYup().of(
            stringYup()
                .test("test1", FORMAT_ERROR, function (value) {
                    if (value) {
                        return value.length === 13;
                    }
                    return true;
                })
                .test("required", FORM_FIELD_REQUIRED_ERROR, function (value) {
                    if (validateWithoutTime) {
                        return true;
                    }
                    const timeIntervals: string[] = this.parent;
                    if (timeIntervals.length && timeIntervals[0]) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .matches(timePeriodRegexp, FORMAT_ERROR)
        ),
        ...(validationWithBreakTime && {
            breakTime: arrayYup().of(
                stringYup()
                    .test("test2", FORMAT_ERROR, function (value) {
                        if (value) {
                            return value.length === 13;
                        }
                        return true;
                    })
                    .test("test3", FORMAT_ERROR, function (value) {
                        if (value) {
                            return !!value.match(timePeriodRegexp);
                        }
                        return true;
                    })
                    .nullable(true)
            ),
        }),
    });
};
