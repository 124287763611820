import { KeycloakError } from "keycloak-js";
import { useRef, useState } from "react";
import { TypeLogger } from "../../../enums/type-logger";
import { useLogger } from "../../../hooks/useLogger";
import KeycloakManager from "../../../lib/keycloak-manager";
import signOut from "../../../lib/sign-out";
import { debounceCallFunc } from "@russpass/partner-front-ui";
import { ErrorTitleWithCounter } from "../../notification/components/errorTitleWithCounter";
import {
    ERROR_CONTENT_ON_INIT,
    ERROR_NOTIFICATION_TITLE_ON_AUTH,
    ERROR_NOTIFICATION_TITLE_ON_UPDATE_TOKEN,
    SECONDS_TO_REDIRECT,
    TITLE_LOG_ON_AUTH_SUCCESS,
    TITLE_LOG_ON_ERROR_AUTH,
    TITLE_LOG_ON_ERROR_INIT,
    TITLE_LOG_ON_ERROR_TOKEN_UPDATE,
    TITLE_LOG_ON_INIT_SUCCESS,
    TITLE_LOG_ON_READY,
    TITLE_LOG_ON_TOKEN_EXPIRED,
    TITLE_LOG_ON_TOKEN_UPDATE_SUCCESS,
} from "../constants/constants";
import { useSetErrorNotification } from "./useSetErrorNotification";

export const useHandleOnEvent = () => {
    const { logger, isLoggerMode } = useLogger();
    const { setNotification } = useSetErrorNotification();

    const [isInitializedKeycloak, setIsInitializedKeycloak] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const updateTime = useRef<number>(300);

    const handleOnAuthSuccess = () => {
        const isAuthenticated = Boolean(KeycloakManager.getIsAuthenticated());
        logger({ text: TITLE_LOG_ON_AUTH_SUCCESS, isAuthenticated }, TypeLogger.Table);
        setIsAuthenticated(isAuthenticated);

        const parsedToken = KeycloakManager.getParsedAccessToken();

        if (parsedToken?.exp) {
            const now = new Date().getTime();
            updateTime.current = Math.round(parsedToken.exp - now / 1000);
        }
    };

    const handleOnReady = () => {
        logger(TITLE_LOG_ON_READY, TypeLogger.Log);
    };

    const handleOnSuccessInit = () => {
        logger(TITLE_LOG_ON_INIT_SUCCESS, TypeLogger.Log);
        setIsInitializedKeycloak(true);
    };

    const handleOnAuthRefreshSuccess = () => {
        logger({ text: TITLE_LOG_ON_TOKEN_UPDATE_SUCCESS, time: new Date() }, TypeLogger.Table);
    };

    const handleOnTokenExpired = () => {
        logger({ text: TITLE_LOG_ON_TOKEN_EXPIRED, time: new Date() }, TypeLogger.Table);
        KeycloakManager.updateToken(updateTime.current);
    };

    const handleOnErrorInit = (err: any) => {
        logger([TITLE_LOG_ON_ERROR_INIT, err], TypeLogger.Error);
        setNotification(ERROR_CONTENT_ON_INIT);
    };

    const handleOnAuthError = (errorData: KeycloakError) => {
        setNotification(
            <ErrorTitleWithCounter initialSeconds={SECONDS_TO_REDIRECT} titleError={ERROR_NOTIFICATION_TITLE_ON_AUTH} />
        );

        if (!isLoggerMode) {
            debounceCallFunc(() => KeycloakManager.login(window.location.href), SECONDS_TO_REDIRECT * 1000);
        }

        logger([TITLE_LOG_ON_ERROR_AUTH, errorData], TypeLogger.Error);
    };

    const handleOnRefreshError = () => {
        setNotification(
            <ErrorTitleWithCounter
                initialSeconds={SECONDS_TO_REDIRECT}
                titleError={ERROR_NOTIFICATION_TITLE_ON_UPDATE_TOKEN}
            />
        );

        if (!isLoggerMode) {
            debounceCallFunc(() => signOut(window.location.href), SECONDS_TO_REDIRECT * 1000);
        }

        logger(TITLE_LOG_ON_ERROR_TOKEN_UPDATE, TypeLogger.Error);
    };

    return {
        isAuthenticated,
        isInitializedKeycloak,
        handleOnAuthError,
        handleOnAuthRefreshSuccess,
        handleOnAuthSuccess,
        handleOnErrorInit,
        handleOnReady,
        handleOnRefreshError,
        handleOnSuccessInit,
        handleOnTokenExpired,
    };
};
