import React, { FC } from "react";
import { ActionButtonWrapper } from "@russpass/partner-front-ui";
import "./styles.sass";
import { ReactComponent as EyeIcon } from "../../../../assets/images/services/ic_eye.svg";
import classNames from "classnames";

type ActionsContainerProps = {
    onClickNextButton: () => void;
    onClickPreviewButton: () => void;
    nextButtonText: string;
    showPreviwButton: boolean;
};

const ActionsContainer: FC<ActionsContainerProps> = ({
    onClickNextButton,
    onClickPreviewButton,
    showPreviwButton,
    nextButtonText,
}) => {
    return (
        <div className="actions-wrapper-container">
            <ActionButtonWrapper className="actions-wrapper">
                <button
                    onClick={onClickNextButton}
                    className={classNames("button button--full-width", "button--primary")}
                >
                    {nextButtonText}
                </button>
                {showPreviwButton && (
                    <button
                        className={classNames("button button--full-width", "button--second")}
                        onClick={onClickPreviewButton}
                    >
                        {"Предпросмотр"}
                        <span className="button__icon button__icon--left">
                            <EyeIcon />
                        </span>
                    </button>
                )}
            </ActionButtonWrapper>
        </div>
    );
};

export default ActionsContainer;
