import { useCallback, useMemo } from "react";
import { DiscountBonusType, DiscountType, HotelDiscountsForm } from "../../../types/hotel-discounts";
import { FormikState } from "formik";
import { DiscountBonusTypeLabel, DiscountTypeLabel } from "../../../constants/hotel-discounts";
import { useHotelDiscountStoreContext } from "../context/discounts-store/useHotelDiscountStoreContext";

const useControlDiscountForm = (
    isDisabled: boolean,
    values: HotelDiscountsForm,
    resetForm: (nextState?: Partial<FormikState<HotelDiscountsForm>> | undefined) => void
) => {
    const { hotelRoomsData, tariffs } = useHotelDiscountStoreContext();

    const typesOptions = useMemo(
        () => [
            { value: DiscountType.DISCOUNT, label: DiscountTypeLabel[DiscountType.DISCOUNT] },
            { value: DiscountType.BONUS, label: DiscountTypeLabel[DiscountType.BONUS] },
        ],
        []
    );

    const bonusOptions = useMemo(
        () => [
            {
                value: DiscountBonusType.BONUS_BREAKFAST,
                label: DiscountBonusTypeLabel[DiscountBonusType.BONUS_BREAKFAST],
            },
            { value: DiscountBonusType.BONUS_UPGRADE, label: DiscountBonusTypeLabel[DiscountBonusType.BONUS_UPGRADE] },
        ],
        []
    );

    const offerTypeValue = useMemo(
        () => typesOptions.find((o) => o.value === values.specialOfferType) || typesOptions[0],
        [typesOptions, values.specialOfferType]
    );
    const onChangeOfferType = useCallback(
        (value: any) => {
            resetForm({
                values: {
                    id: values.id,
                    cmsHotelId: values.cmsHotelId,
                    title: values.title,
                    date: values.date,
                    dateView: values.dateView,
                    roomId: values.roomId,
                    tariffs: values.tariffs,
                    specialOfferType: value.value,
                    bonusCategory: value.value !== DiscountType.DISCOUNT ? values.bonusCategory : undefined,
                },
            });
        },
        [resetForm, values]
    );

    const bonusCategoryValue = useMemo(
        () =>
            bonusOptions.find((o) => o.value === values.bonusCategory) || {
                value: undefined,
            },
        [bonusOptions, values.bonusCategory]
    );

    const roomsOptions = useMemo(() => hotelRoomsData.map((r) => ({ value: r.id, label: r.name })), [hotelRoomsData]);

    const roomIdValue = useMemo(
        () =>
            roomsOptions.find((o) => o.value === values.roomId) || {
                value: undefined,
            },
        [roomsOptions, values.roomId]
    );

    const tariffsOptions = useMemo(() => {
        const room = hotelRoomsData.find((el) => el.id === values.roomId);
        if (!room) return [];

        const roomTariffs = tariffs.filter((tariff) => room.rates?.filter(Boolean).find((el) => el?.id === tariff.id));

        return roomTariffs.map((t) => ({ value: t.id, label: t.title }));
    }, [hotelRoomsData, tariffs, values.roomId]);

    const tariffsValue = useMemo(
        () => tariffsOptions.filter((t) => values.tariffs?.includes(t.value)) || [],
        [tariffsOptions, values.tariffs]
    );

    const roomsCategoryUpgradeOptions = useMemo(
        () => roomsOptions.filter((r) => r.value !== roomIdValue?.value) || [],
        [roomIdValue?.value, roomsOptions]
    );

    const roomsCategoryUpgrade = useMemo(
        () => roomsCategoryUpgradeOptions.find((t) => values.roomsCategoryUpgrade?.includes(t.value)) || [],
        [roomsCategoryUpgradeOptions, values.roomsCategoryUpgrade]
    );

    return {
        offerTypeValue,
        onChangeOfferType,
        bonusCategoryValue,
        roomIdValue,
        tariffsValue,
        roomsCategoryUpgrade,
        roomsCategoryUpgradeOptions,
        bonusOptions,
        roomsOptions,
        tariffsOptions,
        typesOptions,
    };
};

export default useControlDiscountForm;
