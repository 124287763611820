import React, { useCallback, useEffect, useMemo, useState } from "react";
import HotelRoom from "../hotel-room";
import { cloneDeep } from "lodash";
import { hotelRoomFormData } from "../../constants/hotel-room";
import history from "../../../../../../history";
import { useLocation } from "react-router-dom";
import useLoadData from "../../hooks/useLoadData";
import { HotelTariffData } from "../../types/hotel-tariff";
import useControlHotelRoomData from "../../hooks/useControlHotelRoomData";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../../../../constants/errors";
import { modalAlertDetails } from "./constants";
import {
    useModalAlertContext,
    StatusRequestEnum,
    CommonLayout,
    ObjectList,
    ObjectItemType,
    Card,
} from "@russpass/partner-front-ui";
import { preparedRoomsForObjectList } from "../../utils/hotel-rooms";
import { HotelDataContext, useHotelDataContext } from "../../../hotel-view/hotel-data-context";
import { HotelTariffsContext } from "../../context/hotel-tariffs-context";
import useControlHotelRoomsData from "../../hooks/useControlHotelRoomsData";
import useHotel from "../../../hotel-view/useHotel";
import { ChannelManagerCodeEnum } from "../../../../../../enums/channel-manager-code";
import { HotelSpecialOffersProvider } from "../../context/HotelSpecialOffersProvider";
import { DiscountStatus, HotelDiscountsData } from "../../types/hotel-discounts";
import { sendEvent, sendEventByObjectItemClick } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";
import { isEnableMovingOldTL } from "../../../../../../constants/feature-toggles";

const HotelRoomsWrap = () => {
    const { hotelInfoData, loadHotelInfoData, isTravelLineHotel, isNewTravelLineHotel } = useHotel({});

    const hotelDataProviderValue = useMemo(
        () => ({
            hotelInfoData,
            isNewTravelLineHotel,
            isTravelLineHotel,
            loadHotelInfoData,
        }),
        [hotelInfoData, isNewTravelLineHotel, isTravelLineHotel, loadHotelInfoData]
    );

    return (
        <HotelDataContext.Provider value={hotelDataProviderValue}>
            <HotelRoomsList />
        </HotelDataContext.Provider>
    );
};

const HotelRoomsList = () => {
    const location = useLocation();

    const [tariffs, setTariffs] = useState<HotelTariffData[]>([]);

    const [isLoadingWithContent, setIsLoadingWithContent] = useState<boolean>(false);

    const { hotelInfoData, loadHotelInfoData, isTravelLineHotel, isNewTravelLineHotel } = useHotelDataContext();

    const { hotelRoomsData, reloadRooms } = useControlHotelRoomsData();

    const {
        isViewHotelRoom,
        groupedAmenities,
        selectedHotelRoomData,
        onChangeRoom,
        onSelectRoom,
        onDeleteRoom,
        isLoadingRoom,
        isSavingRoom,
        statusHotelRoom,
        onAddRoom,
        changeIsViewHotelRoom,
        hotelResidenceTypes,
    } = useControlHotelRoomData();

    const { openModalAlert } = useModalAlertContext();

    const { onLoadTariffs, onLoadDiscounts } = useLoadData();

    const [specialOffers, setSpecialOffers] = useState<HotelDiscountsData[]>([]);
    const getRoomOffersCount = useCallback(
        (roomId: string) => specialOffers.filter((el) => el.roomId === roomId).length,
        [specialOffers]
    );

    const hotelRoomsList = useMemo(() => {
        let rooms = hotelRoomsData;
        if (isTravelLineHotel) {
            rooms = hotelInfoData.rooms.concat(rooms);
        }
        return preparedRoomsForObjectList(
            rooms,
            isNewTravelLineHotel || isTravelLineHotel,
            hotelInfoData.notPublishedRooms,
            getRoomOffersCount
        );
    }, [hotelRoomsData, hotelInfoData, isNewTravelLineHotel, isTravelLineHotel, getRoomOffersCount]);

    const loadData = useCallback(() => {
        setIsLoadingWithContent(true);
        loadHotelInfoData().then((data) => {
            if (data?.partnerSystemType === ChannelManagerCodeEnum.TravelLine && !isEnableMovingOldTL) {
                history.replace({
                    pathname: location.pathname.replace("/rooms", ""),
                    state: location?.state,
                });
            } else {
                const requests = [
                    onLoadTariffs(),
                    reloadRooms(),
                    onLoadDiscounts(data?.cmsId, DiscountStatus.PUBLISHED, 100),
                ];
                Promise.all(requests)
                    .then((values) => {
                        setTariffs(values[0]);
                        setSpecialOffers((values[2].items || []) as HotelDiscountsData[]);
                    })
                    .catch((err) => {
                        console.error(err);
                        eventDispatcher.setNotification({
                            status: StatusRequestEnum.Error,
                            body: GET_DATA_ERROR,
                        });
                    })
                    .finally(() => {
                        setIsLoadingWithContent(false);
                    });
            }
        });
    }, [loadHotelInfoData, onLoadTariffs, reloadRooms, location, onLoadDiscounts]);

    const onClose = useCallback(
        (status: boolean) => {
            if (status) {
                loadData();
            }
            changeIsViewHotelRoom(false);
        },
        [changeIsViewHotelRoom, loadData]
    );

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlerChangeHotelRoom = useCallback(
        async (roomData) => {
            await onChangeRoom(roomData, hotelRoomsData);
            reloadRooms();
        },
        [hotelRoomsData, reloadRooms, onChangeRoom]
    );

    const deleteRoom = useCallback(
        (room: ObjectItemType) => {
            if (!isLoadingWithContent) {
                setIsLoadingWithContent(true);
            }
            const isOldTravelLine = isTravelLineHotel && room.isError;
            onDeleteRoom(room, hotelInfoData.cmsId)
                .then(async () => {
                    if (!isOldTravelLine) {
                        await reloadRooms();
                    } else {
                        await loadHotelInfoData();
                    }
                })
                .then(() => setIsLoadingWithContent(false));
        },
        [isLoadingWithContent, onDeleteRoom, reloadRooms, isTravelLineHotel, hotelInfoData, loadHotelInfoData]
    );

    const handleDeleteRoomClick = useCallback(
        (room: ObjectItemType) => {
            sendEvent(AnalyticEvent.room_remove_button);
            openModalAlert(modalAlertDetails, () => deleteRoom(room));
        },
        [deleteRoom, openModalAlert]
    );

    const hotelTariffsProviderValue = useMemo(() => ({ tariffs }), [tariffs]);

    const onBackButtonClick = useCallback(() => {
        history.push({
            pathname: location.pathname.replace("/rooms", ""),
            state: Object.assign(location?.state || {}, {
                isMoveOldTL: isTravelLineHotel,
            }),
            search: isTravelLineHotel ? `isMoveOldTL=true` : "",
        });
    }, [location, isTravelLineHotel]);

    const backButton = useMemo(
        () => ({
            title: "Назад",
            onClick: onBackButtonClick,
        }),
        [onBackButtonClick]
    );

    return (
        <HotelSpecialOffersProvider hotel={hotelInfoData} offers={specialOffers}>
            <HotelTariffsContext.Provider value={hotelTariffsProviderValue}>
                <CommonLayout
                    pageTitle="Номера"
                    backButton={backButton}
                    isLoadingWithContent={isLoadingWithContent}
                    description={hotelInfoData.title}
                >
                    <ObjectList
                        list={hotelRoomsList}
                        maxRowsTitle={1}
                        onDelete={handleDeleteRoomClick}
                        onSelect={onSelectRoom}
                        notPublishedLabel="Неопубликованный номер"
                        handleBeforeSelect={sendEventByObjectItemClick}
                    >
                        <Card
                            title="Добавить номер"
                            desc="Описание, фотографии и тарифы номера"
                            data={cloneDeep(hotelRoomFormData)}
                            onClick={onSelectRoom}
                            styles={{
                                backgroundColor: "#FFFFFF",
                                boxShadow: "0 12px 16px rgba(0, 0, 0, 0.04)",
                                border: "none",
                            }}
                        />
                    </ObjectList>
                </CommonLayout>
                <HotelRoom
                    hotelInfo={hotelInfoData}
                    isView={isViewHotelRoom}
                    onChangeHotelRoom={handlerChangeHotelRoom}
                    groupedAmenities={groupedAmenities}
                    room={selectedHotelRoomData}
                    onClose={onClose}
                    onAddMore={onAddRoom}
                    isSaving={isSavingRoom}
                    isLoading={isLoadingRoom}
                    status={statusHotelRoom}
                    hotelResidenceTypes={hotelResidenceTypes}
                />
            </HotelTariffsContext.Provider>
        </HotelSpecialOffersProvider>
    );
};

export default HotelRoomsWrap;
