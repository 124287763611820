import { BaseFieldProps } from "../../../../../../types/fields";
import SelectAddress from "../../../../../../components/async-selects/address";
import React from "react";
import { useFormikContext } from "formik";

const SelectAddressField = ({ name, label, placeholder, disabled }: BaseFieldProps) => {
    const { getFieldProps, setFieldValue } = useFormikContext();

    return (
        <SelectAddress
            name={`${name}.unrestricted_value`}
            placeholder={placeholder}
            label={label}
            value={getFieldProps(name).value || ""}
            handleChange={(value) => setFieldValue(name, value)}
            triggerHandleChangeOnEmpty
            disabled={disabled}
        />
    );
};

export default SelectAddressField;
