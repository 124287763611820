import {
    HotelDiscountsCreateResponse,
    HotelDiscountsForm,
} from "../containers/hotels/add-hotel-page/new-form/types/hotel-discounts";
import FetchRequest from "./manager";
import { partnerApiServices } from "../constants/api";

export const createSpecialOffers = (data: HotelDiscountsForm): Promise<HotelDiscountsCreateResponse> =>
    FetchRequest.post(partnerApiServices.specialOffers, data, {
        isProtected: true,
        urlWithHost: true,
    });

export const deleteSpecialOffers = (id: string | number) =>
    FetchRequest.delete(`${partnerApiServices.specialOffers}/${id}`, {
        isProtected: true,
        urlWithHost: true,
    });
export const sendSpecialOffersToArchive = (id: string | number) =>
    FetchRequest.patch(`${partnerApiServices.specialOffers}/${id}/sendToArchive`, undefined, {
        isProtected: true,
        urlWithHost: true,
    });

export const updateSpecialOffers = (data: HotelDiscountsForm) =>
    FetchRequest.patch(partnerApiServices.specialOffers, data, {
        isProtected: true,
        urlWithHost: true,
    });

export const getSpecialOffers = (cmsHotelId: string, status?: string, pageSize?: number | string) =>
    FetchRequest.get(
        partnerApiServices.specialOffers,
        {
            pageSize,
            cmsHotelId,
            status,
        },
        { isProtected: true }
    );

export const getOneSpecialOffers = (id: string) =>
    FetchRequest.get(`${partnerApiServices.specialOffers}/${id}`, undefined, {
        isProtected: true,
        urlWithHost: true,
    });

export const sendSpecialOfferModeration = (id: string) =>
    FetchRequest.patch(`${partnerApiServices.specialOffers}/${id}/sendForApproval`, undefined, {
        isProtected: true,
        urlWithHost: true,
    });
