import { DiscountStatus } from "../types/hotel-discounts";

const checkDiscountStatus = (status: DiscountStatus) => {
    const check = { isActive: false, isArchive: false, isModeration: false };

    switch (status) {
        case DiscountStatus.PUBLISHED:
            check.isActive = true;
            break;
        case DiscountStatus.TO_PUBLISH:
            check.isModeration = true;
            break;
        default:
            check.isArchive = true;
    }

    return check;
};

export default checkDiscountStatus;
