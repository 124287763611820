import { BookingRejectReason, RentHousesBooking } from "../../../../../types/rentHouses";
import { closeBookingModal } from "../utils/closeBookingModal";
import { ModalWindowRight } from "@russpass/partner-front-ui";
import { parseModalTitle } from "../../helpers";
import { Header, HeaderInfo, HeaderSubtitle, HeaderTitle } from "../components/Header";
import { BaseBookingInfo } from "../components/BaseBookingInfo";
import { Container } from "../components/Container";
import { BookingAlert } from "../components/BookingAlert";
import { ReactComponent as IcAlert } from "../../../../../assets/images/icons/ic_alert_circle.svg";
import { useMemo } from "react";
import moment from "moment";
import { DOTTED_MID_DATE_FORMAT } from "../../../../../constants/dates-format";

type Props = { booking: RentHousesBooking };

export const RejectedModal = ({ booking }: Props) => {
    const subtitle = parseModalTitle(booking);

    const rejectReason = useMemo(() => {
        if (!booking.rejectReason || !booking.rejectedAt) return;

        const date = `${moment(booking.rejectedAt).format(DOTTED_MID_DATE_FORMAT)} г.`;
        return {
            [BookingRejectReason.AUTO_CANCEL_WITHOUT_PAYMENT]: `Заявка отклонена автоматически ${date}`,
            [BookingRejectReason.AUTO_CANCEL_WITHOUT_PARTNER_CONFIRMATION]: `Заявка отклонена автоматически ${date}`,
            [BookingRejectReason.USER_CANCEL]: `Гость отменил заявку ${date}`,
            [BookingRejectReason.USER_REFUND]: `Гость отменил бронирование ${date}`,
            [BookingRejectReason.USER_REFUND_BY_SUPPORT]: `Гость отменил бронирование ${date}`,
            [BookingRejectReason.USER_REPEAL_BY_ADMIN]: `Гость отменил бронирование ${date}`,
            [BookingRejectReason.PARTNER_CANCEL]: `Вы отклонили эту заявку ${date}`,
            [BookingRejectReason.PARTNER_REFUND]: `Вы отменили бронирование ${date}`,
            [BookingRejectReason.PARTNER_REFUND_BY_SUPPORT]: `Вы отменили бронирование ${date}`,
            [BookingRejectReason.PARTNER_REPEAL_BY_ADMIN]: `Вы отменили бронирование ${date}`,
        }[booking.rejectReason];
    }, [booking.rejectReason, booking.rejectedAt]);

    return (
        <ModalWindowRight
            isOpened
            onClose={closeBookingModal}
            headerComponent={
                <Header>
                    <HeaderInfo>
                        <HeaderTitle>Отклоненное бронирование</HeaderTitle>
                        <HeaderSubtitle>{subtitle}</HeaderSubtitle>
                    </HeaderInfo>
                </Header>
            }
        >
            <Container>
                {rejectReason && (
                    <BookingAlert
                        info={{
                            icon: <IcAlert />,
                            content: rejectReason,
                        }}
                    />
                )}
                <BaseBookingInfo booking={booking} />
            </Container>
        </ModalWindowRight>
    );
};
