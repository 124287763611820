import {
    Amenities,
    AmenitiesCategory,
    CreateRoomData,
    EditRoomDataRequest,
    HotelRoomData,
    HotelRoomFormData,
    IntegrationHotelRoom,
    Occupancy,
    Rate,
    ServerAmenities,
    TLOccupancyEditRequest,
} from "../types/hotel-room";
import { diffObjects, getDiffsArraysStrict } from "../../../../../utils/common";
import { getImageById } from "../../../../../api/files";
import { declensionOfNum } from "../../../../../utils/declension-number";
import { sortingAscByField } from "../../../../../utils/sorting";
import {
    deleteOccupancyForHotelRoom,
    deleteRateForHotelRoom,
    editRoom,
    saveHotelRoom,
    updateOccupancies,
} from "../../../../../api/hotels/rooms";
import { cloneDeep, isEmpty, isEqual, differenceWith } from "lodash";
import { editHotel } from "../../../../../api/hotels/hotels";
import { StatusRequestEnum, Option, OptionsGroupType, ObjectItemType } from "@russpass/partner-front-ui";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";
import uuid from "react-native-uuid";
import { HotelResidenceSlug } from "../../../../../api/dictionaries";

const cardBorderColor = "#e76d00";

const getTLOccupancies = (occupancies: Occupancy[]) => {
    const _occupancies: Occupancy[] = [];
    occupancies.forEach((occupancy) => {
        if (occupancy.adults > 0) {
            for (let i = 1; i <= occupancy.adults; i++) {
                _occupancies.push({
                    ...occupancy,
                    adults: i,
                    bedType: occupancy.isChild ? "childBandExtraBed" : "adultBed",
                });
            }
        } else _occupancies.push(occupancy);
    });
    return _occupancies;
};

const getTLOccupanciesForEditRequest = (occupancies: Occupancy[]) => {
    const _occupancies: TLOccupancyEditRequest[] = [];
    occupancies.forEach((occupancy) => {
        if (occupancy.adults > 0) {
            for (let i = 1; i <= occupancy.adults; i++) {
                _occupancies.push({
                    adults: i,
                });
            }
        } else {
            _occupancies.push({
                child: occupancy.child,
                minAge: Number(occupancy.minAge),
                maxAge: Number(occupancy.maxAge),
            });
        }
    });
    return _occupancies;
};

export const mappingHotelRoomForCreateRequest = (values: HotelRoomFormData, isTLHotel?: boolean) => {
    let occupancies = values.occupancies;
    if (isTLHotel) {
        occupancies = getTLOccupancies(occupancies);
    } else {
        occupancies = occupancies.map((el) => ({ ...el, isChild: false }));
    }

    if (values?.cmsTypeId === HotelResidenceSlug.BED) {
        occupancies = [
            {
                adults: 1,
                child: 0,
                minAge: 0,
                maxAge: 0,
                isChild: false,
                bedType: "",
            },
        ];
    }

    const data: CreateRoomData = {
        name: values.name,
        description: values.description,
        images: values.images,
        imageDetailedPageMain: [values.images[0]],
        imageExplorePreview: values.images.length > 0 ? values.images.filter((img, ind) => ind !== 0) : values.images,
        rates: values.rates.map((item) => item.id),
        amenities: values.amenities.map((item) => item.id),
        occupancies: formattingOccupanciesForRequest(occupancies),
        cmsTypeId: values?.cmsTypeId,
    };
    if (values?.cmsTypeId === HotelResidenceSlug.APARTMENT) {
        data.arrivalTime = values?.arrivalTime;
        data.departureTime = values?.departureTime;
        data.email = values?.email;
        data.phones = values?.phones;
        data.address = values?.address;
        data.coordinates = values?.coordinates;
    }

    return data;
};

const formattingOccupanciesForRequest = (values: Occupancy[]): Occupancy[] => {
    return values.map((item) => {
        item.maxAge = Number(item.maxAge);
        item.minAge = Number(item.minAge);
        delete item.id;
        delete item.isNew;
        return item;
    });
};

export const preparedRoomsForObjectList = (
    values: HotelRoomData[],
    isTravelLineHotel?: boolean,
    notPublishedRooms?: string[],
    getRoomOffersCount?: (id: string) => number
) => {
    const roomsList: ObjectItemType[] = values.map((room: HotelRoomData) => {
        const isNotPublished = notPublishedRooms?.indexOf(room.cmsRoomId) !== -1;
        let category = "";
        let isError = false;
        const message = isNotPublished ? "Неопубликованный номер" : undefined;
        const extraStyles = isNotPublished
            ? {
                  borderColor: cardBorderColor,
              }
            : undefined;
        if (!room.occupancies && !room.rates) {
            category = "Заполните информацию";
            isError = true;
        } else {
            const offersCount = getRoomOffersCount?.(room.externalId || "");
            const discountsText =
                offersCount && `${offersCount} ${declensionOfNum(offersCount, ["акция", "акции", "акций"])}`;

            const ratesText = `${room.rates?.length || 0} ${declensionOfNum(room.rates.length || 0, [
                "тариф",
                "тарифа",
                "тарифов",
            ])}`;

            let occupanciesText = "";

            if (room?.cmsTypeId === HotelResidenceSlug.BED) {
                occupanciesText = "койко-место";
            } else if (isTravelLineHotel) {
                occupanciesText = "1 вариант размещения";
            } else {
                occupanciesText = `${room.occupancies?.length || 0} ${declensionOfNum(room.occupancies?.length || 0, [
                    "вариант размещения",
                    "варианта размещения",
                    "вариантов размещения",
                ])}`;
            }

            category = [discountsText, ratesText, occupanciesText].filter(Boolean).join(", ");
        }

        return {
            id: room.id,
            imgUrl: room.images?.length ? getImageById(room.images[0]) : undefined,
            name: room.name,
            link: "",
            category,
            isError,
            isDeleting: true,
            message,
            extraStyles,
        };
    });
    return roomsList;
};

export const mappingHotelRoomFormData = (
    values: IntegrationHotelRoom,
    amenities: ServerAmenities[],
    isTLHotel?: boolean
) => {
    let occupancies = [];
    const getPreparedChildOccupancy = (occupancy: Occupancy) => {
        const minAge = typeof occupancy.minAge === "number" ? String(occupancy.minAge) : occupancy.minAge;
        const maxAge = typeof occupancy.maxAge === "number" ? String(occupancy.maxAge) : occupancy.maxAge;
        return {
            ...occupancy,
            minAge,
            maxAge,
        };
    };
    if (isTLHotel) {
        const adultOccupancy = values.occupancies.reduce((prev: Occupancy | undefined, occupancy) => {
            if (occupancy.child > 0) {
                occupancies.push(getPreparedChildOccupancy(occupancy));
                return prev;
            } else {
                if (occupancy.adults > (prev?.adults || 0)) {
                    return occupancy;
                }
                return prev;
            }
        }, undefined);
        if (adultOccupancy) {
            occupancies.unshift(adultOccupancy);
        }
    } else {
        occupancies = values.occupancies.map((occupancy) => {
            return getPreparedChildOccupancy(occupancy);
        });
    }
    const data: HotelRoomFormData = {
        ...values,
        amenities: values.amenities.reduce((prevState: Amenities[], nextValue: string) => {
            const _item = amenities.find((item) => item.id === nextValue);
            if (_item) {
                return [...prevState, _item];
            }
            return prevState;
        }, []),
        occupancies: occupancies,
    };
    return data;
};

export const mappingHotelRoomForEditRequest = (
    values: HotelRoomFormData,
    initialValues: HotelRoomFormData
): EditRoomDataRequest => {
    const data: EditRoomDataRequest = diffObjects(initialValues, values);
    if (data.amenities) {
        data.amenities = values.amenities.map((item) => item.id);
    }
    if (data.rates) {
        data.rates = values.rates.map((item) => item.id);
    }
    if (data.occupancies) {
        data.occupancies = formattingOccupanciesForRequest(values.occupancies);
    }
    return data;
};

export const getGroupedAmenities = (
    amenities: ServerAmenities[],
    amenitiesCategories: AmenitiesCategory[]
): OptionsGroupType[] => {
    const groupedAmenitiesObject: { [key: string]: Option[] } = {};
    amenities.forEach((item) => {
        const index = item.dictionary_data.category;
        const amenity: Option = {
            id: item.id,
            title: item.title,
        };
        if (!groupedAmenitiesObject[index]) {
            groupedAmenitiesObject[index] = [amenity];
        } else {
            groupedAmenitiesObject[index].push(amenity);
        }
    });
    const groupedAmenities: OptionsGroupType[] = amenitiesCategories.map((category) => {
        return {
            groupId: uuid.v4() as string,
            groupTitle: category.title,
            options: groupedAmenitiesObject[category.id]?.sort(sortingAscByField("title")),
        };
    });
    return groupedAmenities;
};

export const getDeletingRatesForRoomRequests = (
    deletedRates: Rate[],
    hotelId: string,
    roomId: string
): Promise<any>[] => {
    let requests: Promise<any>[] = [];
    if (deletedRates.length) {
        requests = requests.concat(deletedRates.map((rate) => deleteRateForHotelRoom(hotelId, roomId, rate.id)));
    }
    return requests;
};

const getDeletingOccupanciesForRoomRequests = (
    occupancies: Occupancy[],
    selectedOccupancies: Occupancy[],
    hotelId: string
): Promise<any>[] => {
    const requests: Promise<any>[] = [];
    const deletedOccupancies: Occupancy[] = getDiffsArraysStrict(selectedOccupancies, occupancies);
    if (deletedOccupancies.length) {
        deletedOccupancies.forEach((occupancy) => {
            if (occupancy.id) {
                requests.push(deleteOccupancyForHotelRoom(hotelId, occupancy.id));
            }
            return occupancy;
        });
    }
    return requests;
};

export const tryEditRoom = (
    values: HotelRoomFormData,
    selectedHotelRoomData: HotelRoomFormData,
    hotelId: string,
    isTLHotel?: boolean
): Promise<StatusRequestEnum> => {
    return new Promise<StatusRequestEnum>(async (resolve, reject) => {
        let requests: Promise<any>[] = [];
        const newRates: Rate[] = differenceWith(values.rates, selectedHotelRoomData.rates, isEqual) as Rate[];
        const deletedRates: Rate[] = differenceWith(selectedHotelRoomData.rates, values.rates, isEqual) as Rate[];
        const newOccupancies: Occupancy[] = getDiffsArraysStrict(
            values.occupancies,
            selectedHotelRoomData.occupancies
        ) as Occupancy[];
        const requestData: HotelRoomFormData = cloneDeep(values);

        requests = requests.concat(getDeletingRatesForRoomRequests(deletedRates, hotelId, selectedHotelRoomData.id));

        requestData.rates = !newRates.length ? selectedHotelRoomData.rates : newRates;

        if (!isTLHotel) {
            requests = requests.concat(
                getDeletingOccupanciesForRoomRequests(values.occupancies, selectedHotelRoomData.occupancies, hotelId)
            );
            requestData.occupancies = !newOccupancies.length ? selectedHotelRoomData.occupancies : newOccupancies;
        } else {
            const deletingOccupancies: Occupancy[] = getDiffsArraysStrict(
                selectedHotelRoomData.occupancies,
                values.occupancies
            ) as Occupancy[];

            if (newOccupancies.length || deletingOccupancies.length) {
                const occupancies = getTLOccupanciesForEditRequest(values.occupancies);
                requests.push(updateOccupancies(hotelId, selectedHotelRoomData.id, occupancies));
            }
        }
        const editDataRequest = mappingHotelRoomForEditRequest(requestData, selectedHotelRoomData);
        if (isTLHotel && editDataRequest.occupancies?.length) {
            delete editDataRequest.occupancies;
        }

        try {
            await Promise.all(requests);
            if (!isEmpty(editDataRequest)) {
                await editRoom(hotelId, requestData.id, editDataRequest);
            }
            sendEvent(AnalyticEvent.rooms_info_save_success);
            resolve(StatusRequestEnum.Success);
        } catch (err) {
            sendEvent(AnalyticEvent.rooms_info_save_error_page);
            reject(StatusRequestEnum.Error);
        }
    });
};

export const tryCreateRoom = (
    values: HotelRoomFormData,
    rooms: string[],
    hotelId: string,
    isTLHotel?: boolean
): Promise<StatusRequestEnum> => {
    return new Promise<StatusRequestEnum>(async (resolve, reject) => {
        try {
            const data = mappingHotelRoomForCreateRequest(values, isTLHotel);
            const content = await saveHotelRoom(hotelId, data);
            await editHotel(hotelId, {
                hotelRooms: [...rooms, content.cmsRoomId],
            });
            resolve(StatusRequestEnum.Success);
        } catch (error) {
            console.error(error);
            reject(StatusRequestEnum.Error);
        }
    });
};
