import { DayTimeTable } from "./types";

export const weekDays: any = {
    allDays: "allDays",
    monday: "monday",
    tuesday: "tuesday",
    wednesday: "wednesday",
    thursday: "thursday",
    friday: "friday",
    saturday: "saturday",
    sunday: "sunday",
};

export const weekDaysShort = {
    allDays: "All",
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wen",
    thursday: "Thur",
    friday: "Fri",
    saturday: "Sat",
    sunday: "Sun",
};

export const weekDaysNames = {
    allDays: "Ежедневно",
    monday: "Понедельник",
    tuesday: "Вторник",
    wednesday: "Среда",
    thursday: "Четверг",
    friday: "Пятница",
    saturday: "Суббота",
    sunday: "Воскресенье",
};

export const weekDaysShortNames: any = {
    allDays: "Ежедневно",
    monday: "Пн",
    tuesday: "Вт",
    wednesday: "Ср",
    thursday: "Чт",
    friday: "Пт",
    saturday: "Сб",
    sunday: "Вс",
};

export const timetableOptions = [
    {
        label: weekDaysNames.monday,
        value: weekDays.monday,
        shortValue: weekDaysShort.monday,
        shortName: weekDaysShortNames.monday,
    },
    {
        label: weekDaysNames.tuesday,
        shortValue: weekDaysShort.tuesday,
        value: weekDays.tuesday,
        shortName: weekDaysShortNames.tuesday,
    },
    {
        label: weekDaysNames.wednesday,
        value: weekDays.wednesday,
        shortValue: weekDaysShort.wednesday,
        shortName: weekDaysShortNames.wednesday,
    },
    {
        label: weekDaysNames.thursday,
        value: weekDays.thursday,
        shortValue: weekDaysShort.thursday,
        shortName: weekDaysShortNames.thursday,
    },
    {
        label: weekDaysNames.friday,
        value: weekDays.friday,
        shortValue: weekDaysShort.friday,
        shortName: weekDaysShortNames.friday,
    },
    {
        label: weekDaysNames.saturday,
        value: weekDays.saturday,
        shortValue: weekDaysShort.saturday,
        shortName: weekDaysShortNames.saturday,
    },
    {
        label: weekDaysNames.sunday,
        value: weekDays.sunday,
        shortValue: weekDaysShort.sunday,
        shortName: weekDaysShortNames.sunday,
    },
];

export const timetableOptionsFull: DayTimeTable[] = [
    {
        label: weekDaysNames.allDays,
        value: weekDays.allDays,
        shortValue: weekDaysShort.allDays,
        shortName: weekDaysShortNames.allDays,
    },
    ...timetableOptions,
];

export enum PlaceTimetableButtonKeys {
    WeekDays = "weekDays",
    Days = "days",
    RangeDays = "rangeDays",
}

/**
@description Местоимение - "Каждую";
*/
export const weekDayPronouns1 = {
    pronoun1: "Каждую",
    weekDays1: [weekDays.wednesday, weekDays.friday, weekDays.saturday],
};

/**
@description Местоимение - "Каждый";
*/
export const weekDayPronouns2 = {
    pronoun2: "Каждый",
    weekDays2: [weekDays.monday, weekDays.tuesday, weekDays.thursday],
};

/**
@description Местоимение - "Каждое";
*/
export const weekDayPronouns3 = {
    pronoun3: "Каждое",
    weekDays3: [weekDays.sunday],
};

export const newPlaceTimetableData = {
    workingDays: [],
    time: [""],
    breakTime: [""],
};
