import { useMemo } from "react";
import { offerTypes } from "../containers/documents/offer/company-info/constants";
import { LegalStateEnum } from "../enums/profile";
import { useSelector } from "react-redux";

const useUserInfo = () => {
    // @ts-ignore
    const userInfo = useSelector((state) => state.user.userFullInfo);
    // @ts-ignore
    const userSettings = useSelector((state) => state.user.userSettings);

    const isHotel = useMemo(() => {
        if (userInfo && userInfo.offerType) {
            return userInfo.offerType === offerTypes.hotel;
        }
        return false;
    }, [userInfo]);

    const isRestaurant = useMemo(() => {
        if (userInfo && userInfo.offerType) {
            return userInfo.offerType === offerTypes.restaurant;
        }
        return false;
    }, [userInfo]);

    const isTourOperator = useMemo(() => {
        if (userInfo && userInfo.offerType) {
            return userInfo.offerType === offerTypes.touroperator;
        }
        return false;
    }, [userInfo]);

    const isDisplayObject = useMemo(() => {
        if (userInfo && userInfo.offerType) {
            return userInfo.offerType === offerTypes.display;
        }
        return false;
    }, [userInfo]);

    const isApartment = useMemo(() => {
        return userInfo?.offerType === offerTypes.apartment;
    }, [userInfo]);

    const isGuide = useMemo(() => {
        return userInfo?.offerType === offerTypes.guide;
    }, [userInfo]);

    const isConcluded = useMemo(() => {
        return userInfo?.status?.id === 2;
    }, [userInfo]);

    const isSignedApartment = useMemo(() => {
        return isApartment && isConcluded;
    }, [isApartment, isConcluded]);

    const isSignedGuide = useMemo(() => {
        return isGuide && isConcluded;
    }, [userInfo]);

    const offerType = useMemo(() => {
        if (userInfo && userInfo.offerType && Object.values(offerTypes).includes(userInfo.offerType)) {
            return userInfo.offerType;
        }
        return null;
    }, [userInfo]);

    const isToBeAgreed = useMemo(() => {
        if (userInfo && userInfo.offerType) {
            return [1, 11].includes(userInfo.status.id);
        }
        return false;
    }, [userInfo]);

    const isReview = useMemo(() => {
        if (userInfo && userInfo.offerType) {
            return userInfo.status.id === 10;
        }
        return false;
    }, [userInfo]);

    const isSignedSimpleRegistration = useMemo(() => {
        if (userInfo && userInfo.offerType) {
            return userInfo.status.id === 23;
        }
        return false;
    }, [userInfo]);

    const isReviewLKP = useMemo(() => {
        if (userInfo && userInfo.offerType) {
            return userInfo.status.id === 14;
        }
        return false;
    }, [userInfo]);

    const isChecked = useMemo(() => {
        if (userInfo) {
            return [2, 5].includes(userInfo.status.id);
        }
        return false;
    }, [userInfo]);

    const isRevision = useMemo(() => {
        return userInfo?.status.id === 11;
    }, [userInfo]);

    const isUL = useMemo(() => {
        return (
            !!userInfo?.legalState &&
            [LegalStateEnum.LEGAL_ENTITY, LegalStateEnum.INDIVIDUAL].includes(userInfo.legalState)
        );
    }, [userInfo]);

    const isMobileVerified = useMemo(() => {
        return !!userInfo?.mobileNumberVerified;
    }, [userInfo]);

    return {
        userFullInfo: userInfo, //TODO типизировать
        userSettings,
        isToBeAgreed,
        isReview,
        isChecked,
        isHotel,
        isRestaurant,
        isTourOperator,
        isApartment,
        isGuide,
        isSignedGuide,
        isSignedApartment,
        offerType,
        isReviewLKP,
        isSignedSimpleRegistration,
        isRevision,
        isUL,
        isMobileVerified,
        isConcluded,
        isDisplayObject,
    };
};

export default useUserInfo;
