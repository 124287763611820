import { TripType } from "../../../../../types/rentHouses";
import { ViewportContextProps, DataGridColumnType } from "@russpass/partner-front-ui";
import { combineString } from "../../../../../utils/text-util";
import moment from "moment";
import { MID_DATE_FORMAT } from "../../../../../constants/dates-format";
import { RatingsStatus, RatingsStatusText } from "../../../../services/components/ratings/types";

export const getDataGridBookingColumns = ({ isMobile, isTablet }: ViewportContextProps): DataGridColumnType[] => [
    {
        label: "Оценка",
        value: "value",
        width: isMobile ? "76px" : "66px",
    },
    {
        label: (item) =>
            item.status === RatingsStatus.IN_DISPUTE
                ? RatingsStatusText.DISPUTE
                : item.status === RatingsStatus.DECLINED_FROM_DISPUTE
                ? RatingsStatusText.DECLINED
                : "На имя",
        customLabelClassName: (item) => {
            if (item.status === RatingsStatus.DECLINED_FROM_DISPUTE || item.status === RatingsStatus.IN_DISPUTE) {
                return "warning";
            }
            return "";
        },
        value: (item) =>
            combineString([item.externalUser?.lastName, item.externalUser?.firstName, item.externalUser?.middleName]),
        width: (() => {
            if (isMobile) return "220px";
            if (isTablet) return "260px";
            return "312px";
        })(),
    },
    {
        label: "Поездка",
        value: (item) => (item.order?.tripPurpose ? TripType[item.order.tripPurpose as keyof typeof TripType] : ""),
        width: (() => {
            if (isMobile) return "102px";
            if (isTablet) return "100px";
            return "130px";
        })(),
    },
    {
        label: "Дата отзыва",
        value: (item) => moment(item.creationalDate).format(`${MID_DATE_FORMAT} г.`),
        width: (() => {
            if (isTablet) return "140px";
            return "194px";
        })(),
    },
];
