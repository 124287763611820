import { getImageById } from "../api/files";

interface IStylesCard {
    [key: string]: string | number;
}

export const getStyleForCard = (minHeight: number, fileId?: string) => {
    const styles: IStylesCard = {
        minHeight: minHeight,
    };

    if (fileId) {
        styles.backgroundImage = `url(${getImageById(fileId)})`;
    }

    return styles;
};
