import { useCallback, useEffect, useRef } from "react";

export const useInterval = (callback: () => void, ms: number = 1000) => {
    const timerRef = useRef<null | NodeJS.Timeout>(null);

    const reset = useCallback(() => {
        if (!timerRef.current) return;

        clearInterval(timerRef.current);
    }, []);

    const start = useCallback(() => {
        reset();
        timerRef.current = setInterval(callback, ms);
    }, [callback, ms, reset]);

    useEffect(() => reset, [reset]);

    return {
        start,
        reset,
    };
};
