import { useCallback, useState } from "react";
import { confirmOrder, getOrder, getOrderUsers, rejectOrder } from "../../api/orders";
import { OrderExpanded } from "./types";
import { POST_DATA_ERROR, StatusRequestCardSubtitleError } from "../../constants/errors";
import eventDispatcher from "../../lib/event-dispatcher";
import { modalAlertDetails } from "./constants";
import { useModalAlertContext, StatusRequestEnum } from "@russpass/partner-front-ui";

type Props = {
    loadContent: () => Promise<void>;
};

const useOrder = ({ loadContent }: Props) => {
    const [isLoadingWithContent, setIsLoadingWithContent] = useState<boolean>(false);

    const [selectedOrder, setSelectedOrder] = useState<OrderExpanded | null>(null);
    const [isViewOrder, setIsViewOrder] = useState(false);

    const { openModalAlert } = useModalAlertContext();

    const selectOrder = useCallback(async (id: number) => {
        setIsLoadingWithContent(true);
        setSelectedOrder(null);
        let order: OrderExpanded = await getOrder(id);
        const requestPersonalData =
            order.orderLines.map(async (line, index) => {
                return new Promise(async (resolve, reject) => {
                    // TODO ошибка в методе биллинга, ждем исправления по задаче RUSSPASS-37263
                    if (line.personalDataId === 0) {
                        reject();
                    } else {
                        if (line.personalDataId !== null) {
                            const personalData = await getOrderUsers(line.personalDataId);
                            order.orderLines[index].personalData = personalData[0];
                        }
                        resolve(true);
                    }
                });
            }) || [];
        Promise.all(requestPersonalData)
            .then(async () => {
                setSelectedOrder(order);
                setIsViewOrder(true);
            })
            .catch(() => {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: StatusRequestCardSubtitleError,
                });
            })
            .finally(() => {
                setIsLoadingWithContent(false);
            });
    }, []);

    const closeViewOrder = useCallback(() => {
        setIsViewOrder(false);
    }, []);

    const onConfirmOrder = useCallback(async () => {
        setIsLoadingWithContent(true);
        closeViewOrder();
        if (selectedOrder) {
            try {
                await confirmOrder(selectedOrder.id, selectedOrder.paySvc.invoiceId);
            } catch (err) {
                console.error(err);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: POST_DATA_ERROR,
                });
            } finally {
                // Задержка потому что запрос на подтверждение уходит в биллинг и там не сразу обновляются данные по всем заявкам,
                // пока костыль - отправлено на получение инфы
                setTimeout(() => loadContent(), 1000);
                setIsLoadingWithContent(false);
            }
        }
    }, [closeViewOrder, loadContent, selectedOrder]);

    const onRejectOrder = useCallback(async () => {
        setIsLoadingWithContent(true);
        closeViewOrder();
        if (selectedOrder) {
            try {
                await rejectOrder(selectedOrder.id, selectedOrder.paySvc.invoiceId);
            } catch (err) {
                console.error(err);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: POST_DATA_ERROR,
                });
            } finally {
                // Задержка потому что запрос на подтверждение уходит в биллинг и там не сразу обновляются данные по всем заявкам,
                // пока костыль - отправлено на получение инфы
                setTimeout(() => loadContent(), 1000);
                setIsLoadingWithContent(false);
            }
        }
    }, [closeViewOrder, selectedOrder, loadContent]);

    const openModalConfirmAlert = useCallback(
        (isConfirm: boolean) =>
            isConfirm
                ? openModalAlert(modalAlertDetails.confirm, onConfirmOrder)
                : openModalAlert(modalAlertDetails.reject, onRejectOrder),
        [onConfirmOrder, onRejectOrder, openModalAlert]
    );

    return {
        isLoadingWithContent,
        selectedOrder,
        selectOrder,
        isViewOrder,
        closeViewOrder,
        openModalConfirmAlert,
    };
};

export default useOrder;
