import { useCallback, useState } from "react";
import { Booking } from "../types";

const useBookingDetails = () => {
    const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
    const [isViewDetails, setIsViewDetails] = useState<boolean>(false);

    const onOpenDetails = useCallback((booking: Booking | null) => {
        setSelectedBooking(booking);
        setIsViewDetails(true);
    }, []);

    const onCloseDetails = useCallback(() => {
        setIsViewDetails(false);
        setSelectedBooking(null);
    }, []);

    return {
        selectedBooking,
        isViewDetails,
        onOpenDetails,
        onCloseDetails,
    };
};

export default useBookingDetails;
