import { CommonLayout, EmptyList, ObjectList } from "@russpass/partner-front-ui";
import { NavbarApartmentsName } from "./constants";
import useApartments from "./useApartments";
import { useRentHousesOwnerProfile } from "../../../api/hooks/rentHouses";
import React, { useMemo } from "react";
import styles from "./style.module.sass";
import { Link } from "react-router-dom";
import routes from "../../../constants/routes";
import { sendEventByObjectItemClick } from "../../../product_analytics/analytics";
import { useDeleteObject } from "../hooks/useDeleteObject";
import { useApartmentsNavbar } from "./useApartmentsNavbar";

const ApartmentsAll = () => {
    const { activeTabKey, setActiveTabKey, addObject, list, updateList, isLoading, onSelect } = useApartments();
    const { navbarList } = useApartmentsNavbar(activeTabKey);
    const { data: ownerProfileData } = useRentHousesOwnerProfile();
    const { onDeleteObject } = useDeleteObject(updateList);

    const isEmptyProfile = !ownerProfileData?.data;

    const topRightButton = useMemo(
        () => ({
            title: !isEmptyProfile ? "Добавить объект" : undefined,
            onClick: addObject,
            isAddButton: true,
        }),
        [addObject, isEmptyProfile]
    );

    const emptyTextTitle = useMemo(() => {
        if (activeTabKey === NavbarApartmentsName.ARCHIVED) return "Объектов в архиве нет";
        return "В этом разделе пока нет объектов";
    }, [activeTabKey]);

    return (
        <CommonLayout
            pageTitle="Объекты"
            isLoadingWithoutContent={isLoading}
            navbar={navbarList}
            activeTabName={activeTabKey}
            handlerChangeActiveTab={setActiveTabKey}
            topRightButton={topRightButton}
        >
            {!list?.length && activeTabKey === NavbarApartmentsName.ALL ? (
                <EmptyList
                    onClick={!isEmptyProfile ? addObject : undefined}
                    description={
                        isEmptyProfile ? (
                            <div>
                                <div className={styles.empty__title}>В этом разделе пока нет объектов</div>
                                <div className={styles.empty__description}>
                                    Чтобы создать объекты, заполните{" "}
                                    <Link to={routes.profile} className={styles.empty__link}>
                                        Профиль
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div>
                                В этом разделе пока нет
                                <br />
                                объектов
                            </div>
                        )
                    }
                    hideImage
                    isBigContainer
                />
            ) : (
                <ObjectList
                    list={list}
                    emptyTextTitle={emptyTextTitle}
                    onSelect={onSelect}
                    onDelete={onDeleteObject}
                    handleBeforeSelect={sendEventByObjectItemClick}
                />
            )}
        </CommonLayout>
    );
};

export default ApartmentsAll;
