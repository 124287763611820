import useBookingList from "./useBookingList";
import useBookingDetails from "../details/useBookingDetails";
import { DataGrid } from "@russpass/partner-front-ui";
import { PAGE_SIZE } from "../constants";
import { declensionOfNum } from "../../../../utils/declension-number";
import { Booking } from "../types";
import BookingFilters from "./booking-filters";
import BookingDetails from "../details/details";
import React from "react";
import { getDataGridBookingColumns } from "../utils";
import "./styles.sass";

type Props = {
    isNew?: boolean;
};

const BookingList = ({ isNew }: Props) => {
    const { isLoading, data, total, filters, onChangeFilters, restaurantNameOptions, refresh, isEmpty } =
        useBookingList(isNew);

    const { onOpenDetails, onCloseDetails, selectedBooking, isViewDetails } = useBookingDetails();

    return (
        <>
            <DataGrid
                isLoading={isLoading}
                isEmpty={isEmpty}
                dataGridSetting={{
                    data: data,
                    columns: getDataGridBookingColumns(isNew),
                    total: `Всего ${total} ${declensionOfNum(total, ["заявка", "заявки", "заявок"])}`,
                    onSelect: (value) => onOpenDetails(value as Booking),
                    dataGridRowClassName: isNew ? "frame-mobile" : "",
                }}
                emptySettings={{
                    title: isNew ? "Пока нет заявок на бронирование" : "Пока нет заявок с ответами",
                    description: isNew
                        ? "Здесь будут отображаться новые заявки"
                        : "Здесь будут отображаться подтвержденные и отклоненные заявки",
                }}
                paginate={{
                    page: filters.page,
                    totalPages: Math.ceil(total / PAGE_SIZE),
                    onPageChange: (selectedPage) => {
                        onChangeFilters({
                            page: selectedPage,
                        });
                    },
                }}
                filters={
                    <BookingFilters
                        name={filters.cmsId}
                        sorting={isNew ? filters.sorting : filters.status}
                        onChange={onChangeFilters}
                        isNew={isNew}
                        restaurantNameOptions={restaurantNameOptions}
                    />
                }
            />

            <BookingDetails
                selectedBooking={selectedBooking}
                isViewDetails={isViewDetails}
                onCloseDetails={onCloseDetails}
                disabled={!isNew}
                refresh={refresh}
            />
        </>
    );
};

export default BookingList;
