import { languageRuId } from "../../../../constants/language";
import { StatusNamesServiceItem } from "../../all/types";
import { PaymentTypeEnum } from "../../enums";
import { ExcursionDataClient } from "./types";

export const initialExcursion: ExcursionDataClient = {
    title: "",
    description: "",
    region: "",
    city: "",
    facility: "",
    excursionForm: "",
    duration: "",
    price: "",
    minGroupCount: "",
    seasonStart: "",
    seasonEnd: "",
    language: [languageRuId],
    images: [],
    imagesFiles: [],
    imageExplorePreview: [],
    program: "",
    isCanBuy: false,
    route: [
        {
            events: [],
            title: "",
        },
    ],
    holdingSpace: "",
    ndsPercent: "",
    included: "",
    paidSeparately: "",
    startPointAddress: "",
    maxGroupCount: "",
    paymentType: PaymentTypeEnum.SingleStage,
    personalizedTickets: false,
    excursionView: "",
    seasonUnlimited: false,
    status: StatusNamesServiceItem.Creating,
};

export enum ExcursionHoldingSpace {
    outside = "ulichnaya",
    indoor = "v-pomeshenii",
    mixed = "smeshannaya",
}

export const initialExcursionEvent = {
    title: "",
    images: [],
    imageFiles: [],
};

export enum NavbarExcursionNamesEnum {
    "Excursion" = "excursion",
    "Tariffs" = "rates",
    "Place" = "location",
    "Program" = "program",
    "Photos" = "photos",
    "Ratings" = "reviews",
    "Comment" = "comment",
}

export const navbarExcursionLabels = {
    excursion: "Экскурсия",
    tariffs: "Тарифы",
    place: "Место проведения",
    program: "Программа",
    photos: "Фото",
    ratings: "Отзывы",
    comment: "Комментарий модератора",
};

export const navbarExcursion = [
    {
        label: navbarExcursionLabels.excursion,
        name: NavbarExcursionNamesEnum.Excursion,
    },
    {
        label: navbarExcursionLabels.tariffs,
        name: NavbarExcursionNamesEnum.Tariffs,
    },
    {
        label: navbarExcursionLabels.program,
        name: NavbarExcursionNamesEnum.Program,
    },
    {
        label: navbarExcursionLabels.photos,
        name: NavbarExcursionNamesEnum.Photos,
    },
];

export const navbarExcursionPlace = {
    label: navbarExcursionLabels.place,
    name: NavbarExcursionNamesEnum.Place,
};

export const navbarExcursionRatings = [
    {
        label: navbarExcursionLabels.ratings,
        name: NavbarExcursionNamesEnum.Ratings,
    },
];

export const navbarExcursionComment = [
    {
        label: navbarExcursionLabels.comment,
        name: NavbarExcursionNamesEnum.Comment,
    },
];

export const VIEW_TYPE_OPTIONS = [
    { value: "single", label: "Индивидуальная" },
    { value: "group", label: "Групповая" },
];
