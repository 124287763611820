import React, { useCallback, useEffect, useState } from "react";
import { ModalWindowRight, SearchInput, SelectWithoutOptionsField, Cell } from "@russpass/partner-front-ui";
import { EventType, FacilityType } from "../../types";

type Props = {
    name: string;
    value: string;
    onChange: (value: any) => void;
    placeholder: string;
    label: string;
    searchPlaceholder: string;
    options: Array<EventType | FacilityType>;
    valueField?: string;
    labelField?: string;
    disabled?: boolean;
    errorSettings?: {
        showOnChange: boolean;
    };
};

const ServiceType = ({
    onChange,
    name,
    value,
    placeholder,
    label,
    searchPlaceholder,
    options,
    disabled,
    errorSettings,
    valueField = "id",
    labelField = "name",
}: Props) => {
    const [isOpenChooseType, setIsOpenChooseType] = useState(false);
    const [searchResponse, setSearchResponse] = useState<Array<EventType | FacilityType>>(options);

    const onOpenChooseType = useCallback(() => {
        setIsOpenChooseType(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsOpenChooseType(false);
    }, []);

    useEffect(() => {
        setSearchResponse(options);
    }, [options]);

    const onSearch = useCallback(
        (str) => {
            let filterData = str
                ? options.filter((type: any) => type[labelField].toLowerCase().indexOf(str.toLowerCase()) > -1)
                : options;
            setSearchResponse(filterData);
        },
        [labelField, options]
    );

    return (
        <>
            <SelectWithoutOptionsField
                name={name}
                label={label}
                placeholder={placeholder}
                value={value}
                handleClick={onOpenChooseType}
                noHover
                errorSettings={errorSettings}
                disabled={disabled}
            />
            <ModalWindowRight isOpened={isOpenChooseType} onClose={closeModal}>
                <div className="service-type">
                    <div className="service-data__type-chooser">
                        <SearchInput
                            className={"search_input_services"}
                            handleSearch={onSearch}
                            placeholder={searchPlaceholder}
                            name={name}
                        />
                        {searchResponse.map((item: any) => (
                            <Cell
                                body={item[labelField]}
                                key={item[valueField]}
                                qaName={
                                    name === "eventType" && valueField === "id"
                                        ? `event-id-${item[valueField]}`
                                        : "cell"
                                }
                                onClick={() => {
                                    onChange(item);
                                    closeModal();
                                }}
                            />
                        ))}
                    </div>
                </div>
            </ModalWindowRight>
        </>
    );
};

export default ServiceType;
