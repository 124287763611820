import { partnerApiServices, russpassApiServices } from "../constants/api";
import fetchRequest from "./manager";
import { ErrorResponse } from "../types/api";

const path = `${russpassApiServices.partnership}${partnerApiServices.mobilePhoneVerification}`;

export const phoneVerify = (otp: string): Promise<ErrorResponse> =>
    fetchRequest.post(
        path,
        { otp },
        {
            isProtected: true,
        }
    );
