import { ModalWindowRight, TextField, useViewportContext } from "@russpass/partner-front-ui";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { saveFile } from "../../../../../../api/attach";
import { useRentHousesBookingByUid } from "../../../../../../api/hooks/rentHouses";
import { ReactComponent as MoreIcon } from "../../../../../../assets/images/icons/ic_more.svg";
import { ReactComponent as ThumbUp } from "../../../../../../assets/images/icons/thumbup.svg";
import { ReactComponent as Union } from "../../../../../../assets/images/icons/union.svg";
import { ReactComponent as Plus } from "../../../../../../assets/images/icons/ic_plus.svg";
import { ReactComponent as Minus } from "../../../../../../assets/images/icons/ic_minus.svg";
import Ticket from "../../../../../../components/ticket";
import { combineString } from "../../../../../../utils/text-util";
import { RatingsDisputeModal } from "../../../../../services/components/ratings/components/modals/rating-dispute-modal";
import { RatingModalHeader } from "../../../../../services/components/ratings/components/modals/rating-modal-header";
import { RatingsStatus, RatingsStatusText } from "../../../../../services/components/ratings/types";
import ticketStyles from "../../../../booking/details/components/BookingTicket.module.sass";
import { Container } from "../../../../booking/details/components/Container";
import { formatDateRange, formatGuestsCount, formatPrice } from "../../../../booking/helpers";
import { useRatingMark } from "../hooks/useRatingMark";
import { DisputePopupMobile } from "./DisputePopupMobile";
import styles from "./RatingMarkModal.module.sass";
import moment from "moment";
import { MID_DATE_FORMAT } from "../../../../../../constants/dates-format";
import RatingLoyalty from "../../../../../services/components/ratings/components/rating-loyalty";
import { RatingsContestation } from "../../../../../services/components/ratings/components/ratingContestation";

type Props = {
    id: number | undefined;
    resetId: () => void;
};

export const RatingMarkModal = ({ id, resetId }: Props) => {
    const { isMobile } = useViewportContext();

    const {
        data: markData,
        isLoading: isLoadingMarkData,
        isDisputed,
        setIsDisputed,
        disputeMark,
        handleUpdate,
    } = useRatingMark(id);
    const badges = useMemo(() => markData?.data.badges.sort((a, b) => (a < b ? 1 : -1)), [markData?.data.badges]);

    const { data: bookingData, isLoading: isLoadingBookingData } = useRentHousesBookingByUid(
        markData?.data.orderUid ? markData?.data.orderUid.toString() : undefined
    );

    const [isSendDispute, setIsSendDispute] = useState(false);
    const [isDisputePopupOpened, setIsDisputePopupOpened] = useState(false);

    const guestCount = useMemo(() => {
        const guestCount = bookingData?.data.guestCount;
        return guestCount ? formatGuestsCount(guestCount) : undefined;
    }, [bookingData]);

    const isLoading = useMemo(
        () => isLoadingMarkData || isLoadingBookingData,
        [isLoadingBookingData, isLoadingMarkData]
    );

    const headerComponent = useMemo(() => {
        const creationalDate = markData?.data.creationalDate;
        const nameDispute = `${markData?.data.externalUser.lastName || ""} ${markData?.data.externalUser.firstName} ${
            markData?.data.externalUser.middleName || ""
        }`.trim();
        const disputeButton = isMobile ? (
            <>
                <MoreIcon className={styles.MoreButtonMobile} onClick={() => setIsDisputePopupOpened(true)} />
                {isDisputePopupOpened && (
                    <DisputePopupMobile
                        isOpened={isDisputePopupOpened}
                        setIsOpened={setIsDisputePopupOpened}
                        onStartDispute={() => setIsDisputed(true)}
                    />
                )}
            </>
        ) : (
            <button type="button" className="button button--third" onClick={() => setIsDisputed(true)}>
                Оспорить отзыв
            </button>
        );

        return (
            <RatingModalHeader
                title={isDisputed ? "Оспаривание отзыва" : "Отзыв"}
                button={
                    markData?.data.status === RatingsStatus.PUBLISHED && !isDisputed && !markData.data.dispute
                        ? disputeButton
                        : undefined
                }
                isLoading={isLoading}
                mark={!isDisputed ? markData?.data.value : undefined}
                markColored
                markDate={!isDisputed && creationalDate ? moment(creationalDate).format(MID_DATE_FORMAT) : undefined}
                markName={isDisputed ? nameDispute : undefined}
            />
        );
    }, [markData, isMobile, isDisputePopupOpened, isDisputed, isLoading, setIsDisputed]);

    const handleClose = useCallback(() => {
        if (isDisputed) {
            setIsDisputed(false);
        } else {
            resetId();
        }
    }, [isDisputed, setIsDisputed, resetId]);

    const handleDispute = useCallback(
        async ({ description, files }: { description: string; files: File[] }) => {
            if (!id) return;
            try {
                setIsSendDispute(true);
                const fileUrls: string[] = [];
                for (const file of files) {
                    const saveResult = await saveFile(file);
                    if (!saveResult) return;
                    fileUrls.push(saveResult.uri);
                }
                await disputeMark(id, { description, files: fileUrls });
            } catch (e) {
                console.error(e);
            } finally {
                setIsSendDispute(false);
            }
        },
        [disputeMark, id]
    );

    if (isDisputed) {
        return (
            <RatingsDisputeModal
                isOpened={!!id}
                onClose={handleClose}
                headerComponent={headerComponent}
                handleDispute={handleDispute}
                isSendDispute={isSendDispute}
            />
        );
    }

    return (
        <ModalWindowRight
            isOpened={!!id}
            onClose={handleClose}
            title="Оценка"
            headerComponent={headerComponent}
            isLoadingWithoutContent={isLoading}
        >
            <Container className="ratings-card__container">
                {markData?.data?.status === RatingsStatus.DECLINED_FROM_DISPUTE ? (
                    <RatingsContestation textStatus={RatingsStatusText.DECLINED} />
                ) : (
                    markData?.data?.status === RatingsStatus.IN_DISPUTE && (
                        <RatingsContestation textStatus={RatingsStatusText.DISPUTE} />
                    )
                )}
                <div className={classNames("ratings-card", styles.RatingsCardWrapper)}>
                    <div className="ratings-card__block">
                        <div className="ratings-card__block-item">
                            <ThumbUp className="secondary" />
                            <div className="ratings-info__badges">
                                {badges?.map((badge) => (
                                    <div className="ratings-info__badge" key={badge.code}>
                                        {badge.description.replaceAll("-/n", "")}
                                    </div>
                                ))}
                            </div>
                        </div>
                        {!!markData?.data.positive && (
                            <div className="ratings-card__block-item">
                                <Plus className="secondary" />
                                {markData.data.positive}
                            </div>
                        )}

                        {!!markData?.data.negative && (
                            <div className="ratings-card__block-item">
                                <Minus className="warning" />
                                {markData.data.negative}
                            </div>
                        )}
                        {!!markData?.data.comment && (
                            <div className="ratings-card__block-item">{markData.data.comment}</div>
                        )}
                        {markData?.data?.status === RatingsStatus.PUBLISHED && (
                            <RatingLoyalty
                                id={markData?.data.id}
                                loyalityExtraAccrued={markData?.data.loyalityExtraAccrued}
                                onUpdate={handleUpdate}
                                config={{
                                    labelBefore: "Начислить бонусы",
                                    labelAfter: "Бонусы начислены",
                                    icon: <Union className="button__icon--left" />,
                                }}
                            />
                        )}
                    </div>
                </div>
                {!!(bookingData || markData) && (
                    <Ticket
                        className={classNames({ [ticketStyles.Ticket__mobile]: isMobile })}
                        hideDivider={!bookingData}
                        top={
                            !!bookingData && (
                                <div className={ticketStyles.TicketHeader}>
                                    <TextField label="Туристы" value={guestCount || "Нет данных"} />
                                    <TextField
                                        label="Сумма"
                                        value={
                                            bookingData?.data.amount
                                                ? formatPrice(Number(bookingData?.data.amount))
                                                : "Нет данных"
                                        }
                                    />
                                </div>
                            )
                        }
                        bottom={
                            <div className={classNames(ticketStyles.TicketFooter, "ratings-card__tickets")}>
                                {bookingData?.data && (
                                    <TextField label="Номер бронирования" value={bookingData?.data.uid} />
                                )}
                                {bookingData?.data?.checkinDate ? (
                                    <TextField
                                        label="Даты проживания"
                                        value={formatDateRange(
                                            bookingData.data.checkinDate,
                                            bookingData.data.checkoutDate,
                                            "extended"
                                        )}
                                    />
                                ) : markData?.data?.visitDateFrom ? (
                                    <TextField
                                        label="Даты проживания"
                                        value={formatDateRange(
                                            markData.data.visitDateFrom,
                                            markData.data.visitDateTo,
                                            "extended"
                                        )}
                                    />
                                ) : null}

                                <div className={ticketStyles.TicketUser}>
                                    <TextField
                                        label="На имя"
                                        value={combineString(
                                            !!bookingData
                                                ? [
                                                      bookingData?.data.guest.lastName,
                                                      bookingData?.data.guest.firstName,
                                                      bookingData?.data.guest.middleName,
                                                  ]
                                                : !!markData
                                                ? [
                                                      markData?.data.externalUser.lastName,
                                                      markData?.data.externalUser.firstName,
                                                      markData?.data.externalUser.middleName,
                                                  ]
                                                : ["Нет данных"]
                                        )}
                                    />
                                </div>
                            </div>
                        }
                    />
                )}
            </Container>
        </ModalWindowRight>
    );
};
