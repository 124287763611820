import { ReactNode } from "react";
import { eventNames } from "../constants/events";

export type Notification = {
    status: string;
    body: string | ReactNode;
    isAlwaysView?: boolean;
    maxWidth?: number;
    parseMode?: "html";
};

export type CounterUpdateEvent = {
    route: string;
};

const eventDispatcher = () => {
    const setNotification = (detail: Notification) => {
        document.dispatchEvent(
            new CustomEvent(eventNames.setNotification, {
                detail: detail,
            })
        );
    };

    const updateCounter = (detail: CounterUpdateEvent) => {
        document.dispatchEvent(
            new CustomEvent(eventNames.updateCounter, {
                detail: detail,
            })
        );
    };

    return {
        setNotification,
        updateCounter,
    };
};

export default eventDispatcher();
