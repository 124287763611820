import { useCallback, useEffect, useState } from "react";
import { getNotifications } from "../../../../api/notficator";
import { HotelsDataResponse } from "../../../hotels/all/types";
import { getHotelsAll } from "../../../../api/hotels/hotels";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import getCustomErrorMessage from "../../../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../../../constants/errors";

export type Setting = { hotelId: string; title: string; types: string[] };

const useSettingsList = () => {
    const [items, setItems] = useState<Setting[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const load = useCallback(async () => {
        setIsLoading(true);
        try {
            const responseHotels: HotelsDataResponse = await getHotelsAll();
            const cmsHotels = responseHotels?.cms;
            if (!cmsHotels) return;

            const notifications = await getNotifications({ pageSize: 1000 });
            const settings: Setting[] = [];
            for (const item of notifications.data.items) {
                if (!item.active) continue;
                const setting = settings.find((el) => el.hotelId === item.hotelId);
                if (setting) {
                    setting.types.push(item.title);
                } else {
                    const cmsHotel = cmsHotels.find((el) => el.cmsId === item.hotelId);
                    if (!cmsHotel) continue;

                    settings.push({ hotelId: item.hotelId, types: [item.title], title: cmsHotel.title });
                }
            }

            setItems(settings);
        } catch (e) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
            });
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        load();
    }, [load]);

    return { items, isLoading, refresh: load };
};

export default useSettingsList;
