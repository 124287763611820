import { EMAIL_REQUIRED_ERROR } from "../../../../../constants/errors";
import { yup } from "../../../../../yup";

export const defaultFormValidationSchema = () =>
    yup.object().shape({
        topic: yup.string().required(),
        details: yup.string().required(),
        name: yup.string().required(),
        email: yup.string().email().required(EMAIL_REQUIRED_ERROR),
        files: yup.array().limitFilesSizeSum(),
        agreeTrueData: yup.boolean().oneOf([true], "Согласие обязательно"),
        bookingId: yup.string().notRequired(),
        reason: yup
            .string()
            .nullable()
            .when("bookingId", {
                is: (bookingId: string | undefined) => !!bookingId,
                then: (schema) => schema.required(),
            }),
    });
