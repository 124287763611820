import { ServerResponse } from "../types/api";
import fetchRequest from "./manager";
import { russpassApiServices } from "../constants/api";
import {
    RentHousesSupportAppealCode,
    RentHousesSupportChatFile,
    RentHousesSupportChatInitiator,
} from "../types/rentHousesSupport";

export const saveRentHousesSupportFile = (file: File): Promise<ServerResponse<RentHousesSupportChatFile>> => {
    let formData = new FormData();
    formData.append("file", file, file.name);

    return fetchRequest.file(`${russpassApiServices.rentHousesSupport}/files`, formData, {
        isProtected: true,
    });
};

export const sendRentHousesSupportMessage = (data: { message?: string; files?: string[] }): Promise<ServerResponse> =>
    fetchRequest.post(
        `${russpassApiServices.rentHousesSupport}/chats/messages?initiator=${RentHousesSupportChatInitiator.PARTNER}`,
        data,
        {
            isProtected: true,
        }
    );

export const createRentHousesSupportAppeal = (data: {
    appealCode: RentHousesSupportAppealCode;
    subject: string;
    text: string;
    email?: string;
    contactPerson?: string;
    reason?: string;
    bookingId?: string;
    files?: File[];
}): Promise<ServerResponse> => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        if (key === "files" && Array.isArray(value)) {
            value.forEach((file) => formData.append(key, file, file.name));
            return;
        }

        if (typeof value !== "string") return;

        formData.append(key, value);
    });

    return fetchRequest.file(`${russpassApiServices.rentHousesSupport}/partner/appeals`, formData, {
        isProtected: true,
    });
};
