import { loadFileByUrlToCms } from "../../../../api/files";
import { NOT_FULLED_SECTIONS_ERROR, NOT_FULLED_SECTION_ERROR } from "../../../../constants/errors";
import { ChannelManagerCodeEnum } from "../../../../enums/channel-manager-code";
import { isEqualArrays, isEqualObjects } from "../../../../utils/common";
import { navbarHotelEditFormLabels } from "./constants";
import { HotelCmsData, HotelData, HotelIntegrationData } from "./types";
import { HotelInfoDataForEditRequest } from "../new-form/types/hotel-info";

export const mappingHotelCmsData = (hotelCmsData: HotelCmsData) => {
    const _hotelData: HotelData = {
        ...hotelCmsData,
        phones: hotelCmsData.phones.length
            ? hotelCmsData.phones
            : [
                  {
                      number: "",
                      name: "",
                  },
              ],
    };
    return _hotelData;
};

export const mappingHotelCmsTravelLineRequestData = (hotelData: HotelData, hotelDataOriginal: HotelData) => {
    const _hotelCmsData: HotelInfoDataForEditRequest = {};

    if (hotelData.description !== hotelDataOriginal.description) {
        _hotelCmsData.description = hotelData.description;
    }
    if (
        !hotelData.phones.every((phone, idx) => {
            return isEqualObjects(phone, hotelDataOriginal.phones[idx]);
        })
    ) {
        _hotelCmsData.phones = hotelData.phones;
    }
    if (!isEqualArrays(hotelData.meals, hotelDataOriginal.meals)) {
        _hotelCmsData.meals = hotelData.meals;
    }
    if (!isEqualArrays(hotelData.commonServices, hotelDataOriginal.commonServices)) {
        _hotelCmsData.commonServices = hotelData.commonServices;
    }
    if (!isEqualArrays(hotelData.fitnessServices, hotelDataOriginal.fitnessServices)) {
        _hotelCmsData.fitnessServices = hotelData.fitnessServices;
    }
    if (!isEqualArrays(hotelData.entertainmentServices, hotelDataOriginal.entertainmentServices)) {
        _hotelCmsData.entertainmentServices = hotelData.entertainmentServices;
    }
    if (!isEqualArrays(hotelData.beachServices, hotelDataOriginal.beachServices)) {
        _hotelCmsData.beachServices = hotelData.beachServices;
    }
    if (!isEqualArrays(hotelData.facilityServices, hotelDataOriginal.facilityServices)) {
        _hotelCmsData.facilityServices = hotelData.facilityServices;
    }
    if (hotelData.email !== hotelDataOriginal.email) {
        _hotelCmsData.email = hotelData.email;
    }
    if (hotelData.address !== hotelDataOriginal.address) {
        _hotelCmsData.address = hotelData.address;
    }
    if (hotelData.images && hotelDataOriginal.images && !isEqualArrays(hotelData.images, hotelDataOriginal.images)) {
        _hotelCmsData.images = hotelData.images;
    }
    if (
        (hotelData.images !== undefined && hotelDataOriginal.images === undefined) ||
        (hotelData.images === undefined && hotelDataOriginal.images !== undefined)
    ) {
        _hotelCmsData.images = hotelData.images;
    }
    if (
        hotelData.imageDetailedPageMain &&
        hotelDataOriginal.imageDetailedPageMain &&
        !isEqualArrays(hotelData.imageDetailedPageMain, hotelDataOriginal.imageDetailedPageMain)
    ) {
        _hotelCmsData.imageDetailedPageMain = hotelData.imageDetailedPageMain;
    }
    if (
        (hotelData.imageDetailedPageMain && !hotelDataOriginal.imageDetailedPageMain) ||
        (!hotelData.imageDetailedPageMain && hotelDataOriginal.imageDetailedPageMain)
    ) {
        _hotelCmsData.imageDetailedPageMain = hotelData.imageDetailedPageMain;
    }
    if (
        hotelData.imageExplorePreview &&
        hotelDataOriginal.imageExplorePreview &&
        !isEqualArrays(hotelData.imageExplorePreview, hotelDataOriginal.imageExplorePreview)
    ) {
        _hotelCmsData.imageExplorePreview = hotelData.imageExplorePreview;
    }
    if (
        (hotelData.imageExplorePreview !== undefined && hotelDataOriginal.imageExplorePreview === undefined) ||
        (hotelData.imageExplorePreview === undefined && hotelDataOriginal.imageExplorePreview !== undefined)
    ) {
        _hotelCmsData.imageExplorePreview = hotelData.imageExplorePreview;
    }

    return _hotelCmsData;
};

export const mappingHotelIntegrationData = (hotelIntegrationData: HotelIntegrationData) => {
    const _hotelData: HotelData = {
        ...hotelIntegrationData,
        title: hotelIntegrationData.name,
        address: hotelIntegrationData.addressLine,
        email: hotelIntegrationData.emails[0] || "",
        phones: hotelIntegrationData.phones.length
            ? hotelIntegrationData.phones.map((phone) => ({
                  number: phone,
                  name: "",
              }))
            : [
                  {
                      number: "",
                      name: "",
                  },
              ],
        meals: [],
        commonServices: [],
        fitnessServices: [],
        entertainmentServices: [],
        beachServices: [],
        facilityServices: [],
    };
    return _hotelData;
};

export const mappingHotelIntegrationRequestData = (hotelId: string, hotelData: HotelData) => {
    const _hotelIntegrationData = {
        id: hotelId,
        hotelId: hotelData.id,
        title: hotelData.title,
        description: hotelData.description,
        phones: hotelData.phones,
        email: hotelData.email,
        address: hotelData.address,
        coordinates: hotelData.coordinates,
        stars: hotelData.stars,
        meals: hotelData.meals,
        commonServices: hotelData.commonServices,
        fitnessServices: hotelData.fitnessServices,
        entertainmentServices: hotelData.entertainmentServices,
        beachServices: hotelData.beachServices,
        facilityServices: hotelData.facilityServices,
        services: hotelData.services,
        rooms: hotelData.rooms,
        images: hotelData.images,
        imageExplorePreview: hotelData.imageExplorePreview,
        partnerSystemType: ChannelManagerCodeEnum.TravelLine,
        city: hotelData.cityId,
        region: hotelData.regionId,
    };
    return _hotelIntegrationData;
};

export const getErrorForm = (state: { isValidInfoHotel: boolean; isValidPhotoHotel: boolean }) => {
    let error = undefined;

    const countErrorForm = [state.isValidInfoHotel, state.isValidPhotoHotel].reduce((sum, current) => {
        if (!current) sum++;
        return sum;
    }, 0);

    if (countErrorForm > 1) {
        error = NOT_FULLED_SECTIONS_ERROR;
    }
    if (countErrorForm === 1) {
        error = NOT_FULLED_SECTION_ERROR;
        if (!state.isValidInfoHotel) {
            error += ` ${navbarHotelEditFormLabels.hotel}`;
        }
        if (!state.isValidPhotoHotel) {
            error += ` ${navbarHotelEditFormLabels.photos}`;
        }
    }

    return error;
};

export const loadPhotosByUrl = (photos: string[]) => {
    return new Promise((resolve) => {
        let promises = photos.map(async (url) => {
            return new Promise(async (resolve) => {
                try {
                    const fileInfoCms = await loadFileByUrlToCms(url);
                    resolve(fileInfoCms.id);
                } catch (err) {
                    console.error(err);
                    resolve(undefined);
                }
            });
        });
        Promise.all(promises).then((values) => {
            resolve(values.filter((val) => !!val));
        });
    });
};
