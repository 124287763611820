import { useCallback, useMemo, useState } from "react";
import { HotelDataForm } from "../types/hotel-info";
import { HOTEL_INFO_STEP_DESC_FULL } from "../constants/hotel-info";
import { trySaveHotelInfo } from "../utils/hotel-info";
import { getStepStyles } from "../utils/common";
import { hotelInfoValidation } from "../validation-schemas/hotel-info";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { HotelStatuses } from "../../../../../enums/hotels";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";
import { useHotelDataContext } from "../../hotel-view/hotel-data-context";
import useUserInfo from "../../../../../hooks/useUserInfo";

const useControlHotelInfoData = () => {
    const { hotelInfoData, isTravelLineHotel, checkExistTravelLineHotel, loadHotelInfoData } = useHotelDataContext();

    const { userFullInfo } = useUserInfo();

    const [isViewHotelInfo, setIsViewHotelInfo] = useState<boolean>(false);

    const [isLoadingHotelInfo, setIsLoadingHotelInfo] = useState(false);

    const [statusHotelInfo, setStatusHotelInfo] = useState<StatusRequestEnum | null>(null);

    const isExistingHotel = useMemo(() => {
        return hotelInfoData.status !== HotelStatuses.New;
    }, [hotelInfoData.status]);

    const isDisabledInfoHotel = useMemo(() => {
        if (isTravelLineHotel && checkExistTravelLineHotel) {
            return true;
        }
        return hotelInfoData.status === HotelStatuses.Deleted || hotelInfoData.status === HotelStatuses.Inactive;
    }, [hotelInfoData.status, isTravelLineHotel, checkExistTravelLineHotel]);

    const isViewCopyHotelId = useMemo(
        () =>
            (hotelInfoData.status === HotelStatuses.Import || hotelInfoData.status === HotelStatuses.Active) &&
            !isTravelLineHotel,
        [hotelInfoData.status, isTravelLineHotel]
    );

    const isViewExternalLink = useMemo(() => hotelInfoData.status === HotelStatuses.Active, [hotelInfoData.status]);

    const isValidHotelInfo = useMemo(() => {
        const isValidForm = hotelInfoValidation().isValidSync(hotelInfoData);
        if (isTravelLineHotel) {
            return isValidForm && checkExistTravelLineHotel;
        }
        return isValidForm;
    }, [hotelInfoData, checkExistTravelLineHotel, isTravelLineHotel]);
    const hotelInfoStepDesc = useMemo(
        () => (isValidHotelInfo ? HOTEL_INFO_STEP_DESC_FULL : undefined),
        [isValidHotelInfo]
    );

    const hotelInfoStepStyles = useMemo(() => getStepStyles(!!isValidHotelInfo), [isValidHotelInfo]);

    const onSaveHotelInfoData = useCallback(
        async (values: HotelDataForm) => {
            sendEvent(AnalyticEvent.object_info_save_button);
            setIsLoadingHotelInfo(true);
            const status = await trySaveHotelInfo(
                !isExistingHotel,
                values,
                hotelInfoData,
                userFullInfo,
                isTravelLineHotel
            );
            setStatusHotelInfo(status);
            await loadHotelInfoData(true);
            setIsLoadingHotelInfo(false);
        },
        [isExistingHotel, loadHotelInfoData, hotelInfoData, isTravelLineHotel, userFullInfo]
    );

    const changeIsViewHotelInfo = useCallback((status: boolean) => {
        setIsViewHotelInfo(status);
        if (status) {
            setStatusHotelInfo(null);
            sendEvent(AnalyticEvent.object_info);
        }
    }, []);

    return {
        hotelInfoStepStyles,
        onSaveHotelInfoData,
        hotelInfoStepDesc,
        isLoadingHotelInfo,
        isValidHotelInfo,
        isExistingHotel,
        isViewCopyHotelId,
        isViewExternalLink,
        isDisabledInfoHotel,
        statusHotelInfo,
        isViewHotelInfo,
        changeIsViewHotelInfo,
    };
};

export default useControlHotelInfoData;
