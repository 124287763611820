import { FileType } from "./enums";
import { Offer, OfferForm, TourOfferInfo } from "./types";
import "./styles.sass";

export const ORGANIZATION_CHART = "Устав";
export const VICARIOUS_AUTHORITY = "Доверенность";

export const INN_TYPE = "INN";
export const OGRN_TYPE = "OGRN";
export const GUIDE_CERTIFICATE_TYPE = "GUIDE_CERTIFICATE";
export const EGRN_TYPE = "EGRN";
export const VICARIOUS_AUTHORITY_TYPE = "VICARIOUS_AUTHORITY";
export const CONTRACT_TYPE = "CONTRACT";
export const SELF_EMPLOYMENT_CERTIFICATE_TYPE = "SELF_EMPLOYED_STATEMENT";

export const BANK_GUARANTEE = "BANK_GUARANTEE";
export const INSURANCE_CONTRACT = "INSURANCE_CONTRACT";

export const optionsSignLegalBasis = [
    {
        label: "Устав",
        value: ORGANIZATION_CHART,
    },
    {
        label: "Доверенность",
        value: VICARIOUS_AUTHORITY,
    },
];

export const optionsEnsurance = [
    {
        label: "Банковская гарантия",
        value: BANK_GUARANTEE,
    },
    {
        label: "Договор страхования",
        value: INSURANCE_CONTRACT,
    },
];

export const offerData: Offer = {
    signatory: "",
    position: "",
    signLegalBasis: ORGANIZATION_CHART,
    ndsRate: "",
    bank: {
        bic: "",
        name: "",
        corrAccount: "",
        treasuryAccount: "",
        checkingAccount: "",
    },
    authority: {
        number: "",
        term: "",
        dateIssue: "",
    },
};

export const offerFormInitialData: OfferForm = {
    applicationAdditionData: {
        ...offerData,
    },
    filesData: {
        authority: [],
        inn: [],
        guideCertificate: [],
        ogrn: [],
        egrn: [],
        selfEmploymentCertificate: [],
    },
};

export const tourOfferFormData: {
    tourOperatorInfo: TourOfferInfo;
} = {
    tourOperatorInfo: {
        registerNumber: "",
        financialSecurityAmount: "",
        financialSecurityType: BANK_GUARANTEE,
        financialSecurityNumber: "",
        financialSecurityDate: "",
        financialSecurityTerm: "",
        financialSecurityProvider: "",
        financialSecurityProviderLegalAddress: "",
        financialSecurityProviderActualAddress: "",
    },
};

export const fileTypes: Record<FileType, string> = {
    inn: INN_TYPE,
    ogrn: OGRN_TYPE,
    guideCertificate: GUIDE_CERTIFICATE_TYPE,
    egrn: EGRN_TYPE,
    authority: VICARIOUS_AUTHORITY_TYPE,
    contract: CONTRACT_TYPE,
    selfEmploymentCertificate: SELF_EMPLOYMENT_CERTIFICATE_TYPE,
};

export const successMessage = {
    title: "Договор отправлен на проверку",
    subtitle: (email?: string) => (
        <div className="success-message">
            {"В течение 5 рабочих дней вы получите ответ на "}
            <span className="success-email">{email}</span>
        </div>
    ),
};

export const simpleRegistrationSuccessMessage = {
    title: "Вы приняли условия оферты",
    subtitle: "Теперь вы можете внести информацию о своих объектах",
};

export const errorMessage = {
    title: "Ошибка подписания оферты",
    subtitle: "Повторите или попробуйте позже",
};

export const offerTypes = Object.freeze({
    hotel: "HOTEL",
    restaurant: "RESTAURANT",
    touroperator: "TOUROPERATOR",
    display: "DISPLAY_OBJECT",
    apartment: "APARTMENT",
    guide: "GUIDE",
});

export const ACCEPT_FILES = ".jpeg, .pdf, .jpg";
