import { RentHousesBooking } from "../../../../../types/rentHouses";
import { Header, HeaderInfo, HeaderSubtitle, HeaderTitle } from "../components/Header";
import { Container } from "../components/Container";
import { ModalWindowRight, Severity, ActionButtonWrapper, StatusRequestEnum } from "@russpass/partner-front-ui";
import { parseModalTitle } from "../../helpers";
import styles from "./RejectModal.module.sass";
import classNames from "classnames";
import { Form, Formik } from "formik";
import { TextareaField } from "@russpass/partner-front-ui";
import React, { useCallback, useEffect, useState } from "react";
import { BookingAlert } from "../components/BookingAlert";
import routes from "../../../../../constants/routes";
import { ReactComponent as IcAlert } from "../../../../../assets/images/icons/ic_alert_circle.svg";
import { rejectSchema } from "../validation";
import { UploadField } from "./components/UploadField";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { createRentHousesSupportAppeal } from "../../../../../api/rentHousesSupport";
import { POST_DATA_ERROR2 } from "../../../../../constants/errors";
import { RentHousesSupportAppealCode } from "../../../../../types/rentHousesSupport";
import getCustomErrorMessage from "../../../../../utils/getCustomErrorMessage";
import { closeBookingModal } from "../utils/closeBookingModal";
import { rejectRentHousesBooking } from "../../../../../api/rentHouses";
import { useInformationPages } from "../../../../../api/hooks/cmsApi";

type Props = { booking: RentHousesBooking; close: () => void };

const FORM_ID = "REJECT_ACTIVE_BOOKING";

type Values = {
    reason?: string;
    files?: any[];
};

export const RejectModal = ({ booking, close }: Props) => {
    const { data } = useInformationPages({ language: "ru", slugs: ["rhb2b_cancel_conditions_paidbooking"] });

    const isBookingAlert = data?.items[0]?.blocks[0]?.description;

    const subtitle = parseModalTitle(booking);

    useEffect(() => {
        sendEvent(AnalyticEvent.host_cancel_reservation);
    }, []);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const submit = useCallback(
        async (values: Values) => {
            try {
                setIsSubmitting(true);
                await createRentHousesSupportAppeal({
                    appealCode: RentHousesSupportAppealCode.BOOKING_CANCELLATION,
                    subject: "Отмена бронирования",
                    text: values.reason || "",
                    bookingId: booking.id,
                    files: values.files,
                    reason: "Отмена бронирования",
                });
                await rejectRentHousesBooking(booking.id);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: "Бронирование отменено",
                });
                closeBookingModal();
            } catch (e) {
                console.error(e);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: getCustomErrorMessage(e, POST_DATA_ERROR2),
                });
            } finally {
                setIsSubmitting(false);
            }
        },
        [booking.id]
    );

    return (
        <Formik<Values>
            initialValues={{ reason: "", files: [] }}
            enableReinitialize
            validateOnMount
            validationSchema={rejectSchema}
            onSubmit={submit}
        >
            <ModalWindowRight
                isOpened
                isLoadingWithContent={isSubmitting}
                onClose={close}
                headerComponent={
                    <Header>
                        <HeaderInfo>
                            <HeaderTitle>Отмена активного бронирования</HeaderTitle>
                            <HeaderSubtitle>{subtitle}</HeaderSubtitle>
                        </HeaderInfo>
                    </Header>
                }
                ComponentFooter={
                    <ActionButtonWrapper className={styles.ActionWrapper}>
                        <span className={styles.ActionInfo}>Нажимая на кнопку, вы соглашаетесь с условиями отмены</span>
                        <button
                            type="submit"
                            form={FORM_ID}
                            onClick={() => {
                                sendEvent(AnalyticEvent.confirm_host_cancel_reservation);
                            }}
                            className={classNames("button button--primary", styles.ActionButton)}
                        >
                            Отменить бронирование
                        </button>
                    </ActionButtonWrapper>
                }
            >
                <Container className={styles.Container}>
                    {isBookingAlert && (
                        <BookingAlert
                            severity={Severity.WARNING}
                            info={{
                                icon: <IcAlert />,
                                content: (
                                    <>
                                        Пожалуйста, опишите причину, по которой вы отменяете бронирование.
                                        <br />
                                        <br />
                                        За две отмены без уважительной причины в течение полугода мы будем вынуждены
                                        заблокировать ваше объявление на один месяц. К уважительным причинам относятся
                                        форс-мажорные обстоятельства, указанные в оферте.
                                        <br />
                                        <br />
                                        <div>
                                            Если у вас есть вопросы, обратитесь в{" "}
                                            <a
                                                className="link"
                                                href={`${routes.support}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                службу поддержки
                                            </a>
                                        </div>
                                    </>
                                ),
                            }}
                        />
                    )}
                    <Form className={styles.Form} id={FORM_ID}>
                        <TextareaField
                            label="Причина отмены"
                            name="reason"
                            noHover
                            placeholder="Расскажите, что случилось"
                        />
                        <UploadField name="files" />
                    </Form>
                </Container>
            </ModalWindowRight>
        </Formik>
    );
};
