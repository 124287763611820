import { array as arrayYup, object as objectYup, string as stringYup } from "yup";
import { FORM_FIELD_REQUIRED_ERROR } from "../../../constants/errors";
import { ISO_DATE_FORMAT } from "../../../constants/dates-format";
import moment from "moment";
import { validationInputListValue } from "@russpass/partner-front-ui";

const ERROR_VALIDATION_TEXT = "Заполните или удалите пустые пункты";

export const tourInfoValidation = objectYup({
    title: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    description: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    region: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    city: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    nights: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    minGroupCount: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    minAge: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    included: stringYup()
        .test("test", ERROR_VALIDATION_TEXT, function (value) {
            return validationInputListValue(value);
        })
        .required(FORM_FIELD_REQUIRED_ERROR),
    paidSeparately: stringYup()
        .test("test1", ERROR_VALIDATION_TEXT, function (value) {
            return validationInputListValue(value);
        })
        .required(FORM_FIELD_REQUIRED_ERROR),
    seasonStart: stringYup().test("test2", FORM_FIELD_REQUIRED_ERROR, function (value) {
        let { isAllYear } = this.parent;
        return isAllYear ? true : !!value;
    }),
    seasonEnd: stringYup()
        .test("test3", "Дата начала должна быть раньше даты окончания", function (value) {
            let { seasonStart } = this.parent;
            if (value) {
                return moment(seasonStart, ISO_DATE_FORMAT).isBefore(moment(value, ISO_DATE_FORMAT));
            }
            return true;
        })
        .test("test4", FORM_FIELD_REQUIRED_ERROR, function (value) {
            let { isAllYear } = this.parent;
            return isAllYear ? true : !!value;
        }),
});

export const tourProgramValidation = objectYup().shape({
    route: arrayYup()
        .of(
            objectYup()
                .shape({
                    title: stringYup()
                        .test("test1", ERROR_VALIDATION_TEXT, function (value) {
                            return validationInputListValue(value);
                        })
                        .required(FORM_FIELD_REQUIRED_ERROR),
                    events: arrayYup()
                        .of(
                            objectYup().shape({
                                title: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
                            })
                        )
                        .min(1, FORM_FIELD_REQUIRED_ERROR),
                })
                .required(FORM_FIELD_REQUIRED_ERROR)
        )
        .required(FORM_FIELD_REQUIRED_ERROR),
});
