import TicketList from "../ticket-list";
import { ModalWindowRight } from "@russpass/partner-front-ui";
import React, { useMemo } from "react";
import { OrderExpanded } from "../../types";
import { orderStatuses, paymentTypes } from "../../constants";

type Props = {
    isViewModal: boolean;
    closeModal: () => void;
    selectedOrder: OrderExpanded | null;
    onConfirm: () => void;
    onReject: () => void;
};

const ModalOrder = ({ isViewModal, closeModal, selectedOrder, onConfirm, onReject }: Props) => {
    const ComponentFooter = useMemo(() => {
        if (
            selectedOrder?.status === orderStatuses.hold &&
            selectedOrder.confirmStatus === null &&
            selectedOrder.paymentType !== paymentTypes.singleStage
        ) {
            return (
                <>
                    <button
                        type="button"
                        className="button button--primary"
                        onClick={onConfirm}
                        data-qa-name="confirm-button"
                    >
                        Подтвердить
                    </button>
                    <button
                        type="button"
                        className="button button--second"
                        onClick={onReject}
                        data-qa-name="reject-button"
                    >
                        Отклонить
                    </button>
                </>
            );
        }
    }, [selectedOrder?.confirmStatus, selectedOrder?.status, selectedOrder?.paymentType, onConfirm, onReject]);

    return (
        <ModalWindowRight
            isOpened={isViewModal}
            onClose={closeModal}
            title="Информация о туристах"
            ComponentFooter={ComponentFooter}
        >
            <TicketList order={selectedOrder} />
        </ModalWindowRight>
    );
};

export default ModalOrder;
