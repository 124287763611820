import fetchRequest from "./manager";
import { partnerApiServices, russpassApiServices } from "../constants/api";
import { INITIAL_FILTERS, OrderFilters, OrdersSorter } from "../containers/orders/constants";

const ordersPartnerServiceUrl = `${russpassApiServices.partnership}${partnerApiServices.orders}`;
const ordersExportUrl = `${russpassApiServices.partnership}${partnerApiServices.ordersExport}`;
const ordersPartnerServiceShortUrl = `${russpassApiServices.partnership}${partnerApiServices.ordersShort}`;

export const exportOrdersInExcel = async (filter: OrderFilters = INITIAL_FILTERS, sorting: Partial<OrdersSorter>) => {
    const content = await fetchRequest.downloadFile(
        ordersExportUrl,
        {
            filter,
            sorting,
        },
        {
            isProtected: true,
            isNotJSONResponse: true,
        }
    );
    return content;
};

export const getOrdersAll = async (
    filter: OrderFilters & { page: number; size: number } = { ...INITIAL_FILTERS, page: 0, size: 10 },
    sorting?: Partial<OrdersSorter>
) => {
    const content = await fetchRequest.post(
        ordersPartnerServiceUrl,
        {
            filter,
            sorting,
        },
        {
            isProtected: true,
        }
    );
    return content;
};

export const getOrdersShort = async (
    filter: OrderFilters & { page: number; size: number } = { ...INITIAL_FILTERS, page: 0, size: 10 },
    sorting?: Partial<OrdersSorter>
) => {
    const content = await fetchRequest.post(
        ordersPartnerServiceShortUrl,
        {
            filter,
            sorting,
        },
        {
            isProtected: true,
        }
    );
    return content;
};

export const getOrder = async (id: number) => {
    const data = await fetchRequest.get(`${ordersPartnerServiceUrl}/${id}`, null, {
        isProtected: true,
    });
    return data;
};

export const getOrderUsers = async (ids: string | number) => {
    const data = await fetchRequest.get(`${ordersPartnerServiceUrl}/users?ids=${ids}`, null, {
        isProtected: true,
    });
    return data;
};

export const confirmOrder = async (billingId: string | number, invoiceId: string) => {
    const data = await fetchRequest.patch(
        `${ordersPartnerServiceUrl}/confirm`,
        {
            order: {
                id: billingId,
                invoiceId: invoiceId,
            },
        },
        {
            isProtected: true,
        }
    );
    return data;
};

export const rejectOrder = async (billingId: number, invoiceId: string) => {
    const data = await fetchRequest.patch(
        `${ordersPartnerServiceUrl}/reject`,
        {
            order: {
                id: billingId,
                invoiceId: invoiceId,
            },
        },
        {
            isProtected: true,
        }
    );
    return data;
};
