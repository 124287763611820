import { DatesFilterType, INITIAL_FILTERS, OrderFilters, OrdersSorter, sortOrdersOptions } from "../constants";
import { createContext } from "react";

type OrderFilterContextType = {
    filters: OrderFilters;
    changeFilter: (filters: Partial<OrderFilters>) => void;
    sort: OrdersSorter;
    setSort: (data: OrdersSorter) => void;
    isEmpty: boolean;
    setIsEmpty: (data: boolean) => void;
    activeTab: DatesFilterType;
    setActiveTab: (activeTab: DatesFilterType) => void;
};

export const OrderFilterContext = createContext<OrderFilterContextType>({
    filters: INITIAL_FILTERS,
    changeFilter: () => undefined,
    sort: sortOrdersOptions[0],
    setSort: () => undefined,
    isEmpty: true,
    setIsEmpty: () => undefined,
    activeTab: DatesFilterType.CREATE,
    setActiveTab: () => undefined,
});
