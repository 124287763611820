//TODO moved text-utils
export const declensionOfNum = (n: number, textForms: [string, string, string]) => {
    n = Math.abs(n) % 100;
    const mod = n % 10;
    if (n > 10 && n < 20) {
        return textForms[2];
    }
    if (mod > 1 && mod < 5) {
        return textForms[1];
    }
    if (mod === 1) {
        return textForms[0];
    }
    return textForms[2];
};
