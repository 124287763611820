import { ModalWindowRight } from "@russpass/partner-front-ui";
import styles from "./styles.module.sass";
import { Loader } from "@russpass/partner-front-ui";
import EditNotificationsForm from "../form";
import { Formik, FormikProps } from "formik";
import React from "react";
import { getNotificationsValidation } from "../form/validation-schema";
import { FormNotificationTemplate } from "../../hooks/useEditNotifications";
import { useEditNotificationsContext } from "../../context/useEditNotificationsContext";

const EditNotificationsModal = () => {
    const { isOpen, close, isNew, isLoading, data, handleSubmit } = useEditNotificationsContext();

    return (
        <Formik<{ templates: FormNotificationTemplate[] }>
            initialValues={data?.initialValues || { templates: [] }}
            onSubmit={handleSubmit}
            validationSchema={getNotificationsValidation(isNew)}
            enableReinitialize
        >
            {({ submitForm, values, dirty }: FormikProps<any>) => {
                const isShowSaveButton = !isLoading && (isNew ? values.templates.some((el: any) => el.active) : dirty);

                return (
                    <ModalWindowRight
                        isOpened={isOpen}
                        onClose={close}
                        headerComponent={
                            !isLoading ? (
                                <div className={styles.header}>
                                    <div className={styles.title} data-qa-name="notifications-modal-header">
                                        {isNew ? "Новые уведомления" : "Редактирование уведомлений"}
                                    </div>
                                    <div className={styles.subtitle} data-qa-name="notifications-modal-subheader">
                                        {isNew ? "Выберите уведомления, которые хотите получать" : data?.title}
                                    </div>
                                </div>
                            ) : undefined
                        }
                        ComponentFooter={
                            isShowSaveButton ? (
                                <button
                                    className="button button--primary"
                                    onClick={() => submitForm()}
                                    data-qa-name="notifications-save-button"
                                >
                                    Сохранить
                                </button>
                            ) : undefined
                        }
                    >
                        {isLoading ? (
                            <div className={styles.loader}>
                                <Loader width="48px" />
                            </div>
                        ) : (
                            <EditNotificationsForm />
                        )}
                    </ModalWindowRight>
                );
            }}
        </Formik>
    );
};

export default EditNotificationsModal;
