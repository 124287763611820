import moment from "moment";
import { ReactComponent as CircleIcon } from "../../../assets/images/icons/circle.svg";
import { ReactComponent as BriefcaseIcon } from "../../../assets/images/icons/briefcase.svg";
import { DataGridColumnType } from "@russpass/partner-front-ui";
import { DOTTED_SHORT_DATE_FORMAT } from "../../../constants/dates-format";
import { FeedbackStatus } from "../constants";

const getDataGridFeedBackColumns = (): DataGridColumnType[] => {
    return [
        {
            value: (item) => item.event.name,
            label: "Объект",
            dataQaNameColumn: "object-name",
            width: "317px",
            icon: (item) =>
                item.order?.id ? (
                    <BriefcaseIcon width={20} height={20} fill="#007470" />
                ) : (
                    item.status === FeedbackStatus.New && <CircleIcon width={12} height={12} fill="#FFCF08" />
                ),
            dataQaStatus: (item) => {
                let status = item.status === FeedbackStatus.New ? "new" : "read";
                status += item?.order?.id ? "reserve" : "";
                return status;
            },
        },
        {
            value: (item) => item.message,
            label: "Вопрос",
            dataQaNameColumn: "question",
            width: `${798 - 317 - 60 - 24 * 3}px`,
        },
        {
            value: (item) => moment(item.createDate).format(DOTTED_SHORT_DATE_FORMAT),
            label: "Дата",
            dataQaNameColumn: "date",
            width: "60px",
        },
    ];
};

export default getDataGridFeedBackColumns;
