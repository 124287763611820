export const ISO_DATE_FORMAT = "YYYY-MM-DD";

export const ISO_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const EXTENDED_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS";
export const EXTENDED_DATE_TIME_FORMAT_FNS = "yyyy-MM-dd'T'hh:mm:ss.SSS";
export const EXTENDED_DATE_TIME_FORMATZ = "yyyy-MM-dd'T'HH:mm:ss.SSSxx";

export const DOTTED_DATE_FORMAT = "DD.MM.YYYY";
export const DOTTED_DATE_FORMAT_FNS = "dd.MM.yyyy";
export const DOTTED_SHORT_DATE_FORMAT = "DD.MM";
export const DOTTED_MID_DATE_FORMAT = "DD.MM.YY";

export const DATE_2061 = "2061-01-31";

export const SHORT_DATE_TIME_FORMAT = "DD MMMM, HH:mm";
export const SHORT_DAY_MONTH_TIME_FORMAT = "DD.MM, HH:mm";

export const DAY_DATE_FORMAT = "DD";
export const SHORT_DAY_DATE_FORMAT = "D";
export const YEAR_DATE_FORMAT = "YYYY";

export const SHORT_DATE_FORMAT = "DD MMM YY";
export const MID_DATE_FORMAT = "DD MMM YYYY";
export const LONG_DATE_FORMAT = "DD MMMM YYYY";
export const MID_MONTH_YEAR_DATE_FORMAT = "MMM YYYY";
export const MID_DAY_MONTH_DATE_FORMAT = "DD MMM";
export const MIN_DAY_MONTH_DATE_FORMAT = "D MMM";
export const MIN_DAY_LONG_MONTH_DATE_FORMAT = "D MMMM";
export const TIME_FORMAT = "HH:mm";
export const MONTH_YEAR_DATE_FORMAT = "LLL yyy";
