import { RentHousesBooking } from "../../../../../types/rentHouses";
import { parseModalTitle } from "../../helpers";
import { ModalWindowRight, ActionButtonWrapper } from "@russpass/partner-front-ui";
import { useBookingConfirm } from "./hooks/useBookingConfirm";
import { useBookingReject } from "./hooks/useBookingReject";
import { TimeUntil } from "../../components/TimeUntil";
import styles from "./PendingModal.module.sass";
import { QuestionTooltip } from "@russpass/partner-front-ui";
import React, { useCallback, useEffect } from "react";
import { ReactComponent as IcChat } from "../../../../../assets/images/icons/ic_chat.svg";
import { expireRentHousesBooking } from "../../../../../api/rentHouses";
import { closeBookingModal } from "../utils/closeBookingModal";
import { Container } from "../components/Container";
import { BaseBookingInfo } from "../components/BaseBookingInfo";
import { Header, HeaderInfo, HeaderSubtitle, HeaderTitle } from "../components/Header";
import { DialogueModal } from "../../../dialogues/dialog/guest/DialogueModal";
import { useDialog } from "./hooks/useDialog";
import { useViewportContext } from "@russpass/partner-front-ui";
import classNames from "classnames";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";

type Props = { booking: RentHousesBooking };

export const PendingModal = ({ booking }: Props) => {
    const { isMobile } = useViewportContext();
    const subtitle = parseModalTitle(booking);
    const { openModal: openConfirmModal, isLoading: isLoadingConfirm } = useBookingConfirm(booking.id);
    const { openModal: openRejectModal, isLoading: isLoadingReject } = useBookingReject(booking.id);

    const {
        isOpen: isOpenDialog,
        dialog,
        open: openDialog,
        isLoading: isLoadingDialog,
        close: closeDialog,
    } = useDialog(booking.object.cmsId, booking.user.externalId);

    const reject = useCallback(() => {
        expireRentHousesBooking(booking.id).catch(() => null);
    }, [booking.id]);

    useEffect(() => {
        sendEvent(AnalyticEvent.request_page);
    }, []);

    if (isOpenDialog && dialog) {
        return <DialogueModal selectedDialogue={dialog} onClose={closeDialog} />;
    }

    return (
        <ModalWindowRight
            isOpened
            isLoadingWithContent={isLoadingConfirm || isLoadingReject || isLoadingDialog}
            onClose={closeBookingModal}
            headerComponent={
                <Header className={classNames({ [styles.Header__mobile]: isMobile })}>
                    <HeaderInfo>
                        <HeaderTitle className={styles.Title}>
                            <div className={styles.Placeholder}>Ожидает подтверждения</div>
                            <div className={styles.TimeUntilWrapper}>
                                <QuestionTooltip
                                    body="Время до автоотмены"
                                    icon={
                                        <TimeUntil
                                            className={styles.TimeUntil}
                                            expireIn={booking.expireIn}
                                            onExpired={reject}
                                        />
                                    }
                                    settings={{ offsetX: -1, offsetY: 18, width: "112px" }}
                                />
                            </div>
                        </HeaderTitle>
                        <HeaderSubtitle>{subtitle}</HeaderSubtitle>
                    </HeaderInfo>
                </Header>
            }
            ComponentFooter={
                <ActionButtonWrapper>
                    <button
                        type="button"
                        className="button button--primary button--full-width"
                        onClick={() => {
                            sendEvent(AnalyticEvent.confirm_click);
                            openConfirmModal();
                        }}
                    >
                        Подтвердить
                    </button>
                    <button
                        type="button"
                        className="button button--second button--full-width"
                        onClick={() => {
                            sendEvent(AnalyticEvent.reject_click);
                            openRejectModal();
                        }}
                    >
                        Отклонить
                    </button>
                </ActionButtonWrapper>
            }
        >
            <Container>
                <BaseBookingInfo booking={booking} />
                <div className={styles.ChatButtonWrapper}>
                    <button type="button" className="button button--second" onClick={openDialog}>
                        <IcChat /> <span className="ml-8">Чат с гостем</span>
                    </button>
                </div>
            </Container>
        </ModalWindowRight>
    );
};
