import { EmptyList } from "@russpass/partner-front-ui";
import styles from "./styles.module.sass";
import classNames from "classnames";
import { ReactComponent as IconRight } from "../../../../../assets/images/icons/ic_chevron_right.svg";
import { useEditNotificationsContext } from "../../../edit/context/useEditNotificationsContext";
import { useNotificationsSettingsContext } from "../../context/useNotificationsSettingsContext";

const SettingsList = () => {
    const { items } = useNotificationsSettingsContext();

    const editNotification = useEditNotificationsContext().open;

    if (!items.length)
        return (
            <EmptyList
                subtitle="У вас пока нет настроенных уведомлений"
                buttonTitle="Подключить уведомления"
                onClick={() => editNotification()}
            />
        );

    return (
        <div className={styles.list}>
            {items.map((item) => (
                <div key={item.hotelId} className={styles.item}>
                    <div className={styles.content}>
                        <div className={styles.title} data-qa-name="notifications-settings-item-title">
                            {item.title}
                        </div>
                        <div className={styles.types}>
                            {item.types.map((type) => (
                                <div
                                    key={type}
                                    className={styles.type}
                                    data-qa-name="notifications-settings-item-subtitle"
                                >
                                    {type}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <button
                            onClick={() => editNotification(item.hotelId)}
                            className={classNames("button", styles.button)}
                            data-qa-name="notifications-settings-item-button"
                        >
                            Редактировать <IconRight />
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SettingsList;
