import { useCallback, useEffect, useState } from "react";
import { useRentHousesBookingEventRatingMark } from "../../../../../../api/hooks/rentHouses";
import { postRatingsMarkDispute } from "../../../../../../api/ratings";
import { GET_DATA_ERROR } from "../../../../../../constants/errors";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { DisputeMarkData } from "../../../../../services/components/ratings/types";
import getCustomErrorMessage from "../../../../../../utils/getCustomErrorMessage";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

export const useRatingMark = (id?: number) => {
    const { data, isLoading, error, mutate } = useRentHousesBookingEventRatingMark(id, { revalidateOnFocus: false });

    const [isDisputed, setIsDisputed] = useState(false);

    useEffect(() => {
        if (error) {
            return eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: error || GET_DATA_ERROR,
            });
        }
    }, [error]);

    const handleUpdate = useCallback(async () => {
        if (id) {
            await mutate();
            setIsDisputed(false);
        }
    }, [id, mutate]);

    const disputeMark = useCallback(
        async (id: number, data: DisputeMarkData) => {
            try {
                const disputeResponse = await postRatingsMarkDispute(id, data);

                if (disputeResponse.errorCode) {
                    return eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: disputeResponse.errorCause || GET_DATA_ERROR,
                    });
                }

                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: "Заявка на оспаривание отправлена",
                });
                await handleUpdate();
            } catch (error) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: getCustomErrorMessage(error, GET_DATA_ERROR),
                });
            }
        },
        [handleUpdate]
    );

    return { data, isLoading, isDisputed, setIsDisputed, disputeMark, handleUpdate };
};
