import { useEffect, useState } from "react";

interface ICounterTimeRedirect {
    initialSecond: number;
}

const CounterTimeRedirect = ({ initialSecond }: ICounterTimeRedirect) => {
    const [second, setSecond] = useState(initialSecond);

    useEffect(() => {
        let intervalId: NodeJS.Timer;

        if (second > 0) {
            intervalId = setInterval(() => {
                if (second <= 0) {
                    clearInterval(intervalId);
                }

                setSecond(second - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [second]);

    return <span>{second}</span>;
};

export default CounterTimeRedirect;
