import { DataGrid, DataGridEmptySettings, Filter, useViewportContext } from "@russpass/partner-front-ui";
import { useCallback, useState } from "react";
import { useRentHousesBookingEventRatingMarksList } from "../../../../../api/hooks/rentHouses";
import { ReactComponent as SortIcon } from "../../../../../assets/images/icons/ic_filter.svg";
import { Sorting } from "../../../../../constants/filters";
import { declensionOfNum } from "../../../../../utils/declension-number";
import { PAGE_SIZE } from "../../../../restaurants/booking/constants";
import "../../../../services/components/ratings/ratings-v2.sass";
import { Controls } from "../../../components/Controls";
import { FiltersContainer } from "../../../components/FiltersContainer";
import { SelectFilter } from "../../../components/SelectFilter";
import { RatingMarkModal } from "./components/RatingMarkModal";
import { getDataGridBookingColumns } from "./helpers";
import { MarkSortingEnum, useRatingMarkValueFilter } from "../../../../../hooks/useRatingMarkValueFilter";
import { useSortFilter } from "../../../../../hooks/useSortFilter";
import { useLocation } from "react-router-dom";
import history from "../../../../../history";
import { NavbarApartmentsNewName } from "../../constants";

export type RatingMarksListProps = {
    externalEventId?: string;
    emptySettings: DataGridEmptySettings;
};

export const RatingMarksStep = ({ externalEventId, emptySettings }: RatingMarksListProps) => {
    const viewportContext = useViewportContext();
    const location = useLocation();

    const {
        options: ratingMarkOptions,
        valueFrom,
        valueTo,
        activeOption: activeRatingMarkOption,
        setActiveOption: setActiveRatingMarkOption,
    } = useRatingMarkValueFilter();
    const { options: sortOptions, activeOption: activeSort, setActiveOption: setActiveSort } = useSortFilter();

    const [page, onPageChange] = useState(0);
    const reviewId = new URLSearchParams(location.search).get("review");
    const [id, setId] = useState<number | undefined>(reviewId ? +reviewId : undefined);

    const resetId = useCallback(() => {
        history.push({
            pathname: location.pathname,
            search: `tab=${NavbarApartmentsNewName.RATING_MARKS}`,
            state: location?.state,
        });
        setId(undefined);
    }, []);

    const handleSetId = (id: number) => {
        history.push({
            pathname: location.pathname,
            search: `tab=${NavbarApartmentsNewName.RATING_MARKS}&review=${id}`,
            state: location?.state,
        });
        setId(id);
    };

    const {
        data: ratingMarksData,
        isLoading: isLoadingRatingMarksData,
        error,
    } = useRentHousesBookingEventRatingMarksList(
        {
            page: page + 1,
            pageSize: PAGE_SIZE,
            sort: activeSort as Sorting,
            valueFrom: valueFrom,
            valueTo: valueTo,
        },
        externalEventId
    );

    const total = ratingMarksData?.data?.total || 0;
    const totalPages = Math.ceil(total / PAGE_SIZE);

    return (
        <>
            <div className="content-wrapper content-wrapper--large">
                <DataGrid
                    isLoading={isLoadingRatingMarksData}
                    isEmpty={!total && activeRatingMarkOption === MarkSortingEnum.All}
                    dataGridSetting={{
                        data: ratingMarksData?.data?.items || [],
                        columns: getDataGridBookingColumns(viewportContext),
                        total: `Всего ${total} ${declensionOfNum(total, ["отзыв", "отзыва", "отзывов"])}`,
                        onSelect: ({ id }) => handleSetId(id),
                    }}
                    emptySettings={emptySettings}
                    paginate={{
                        page,
                        totalPages,
                        onPageChange,
                    }}
                    filters={
                        <Controls>
                            <FiltersContainer>
                                {ratingMarkOptions.length > 1 && (
                                    <SelectFilter
                                        options={ratingMarkOptions}
                                        value={activeRatingMarkOption}
                                        onChange={setActiveRatingMarkOption}
                                    />
                                )}
                                <Filter
                                    options={sortOptions}
                                    value={activeSort}
                                    onChange={setActiveSort}
                                    filterIcon={<SortIcon />}
                                    modalTitle="Сортировка"
                                />
                            </FiltersContainer>
                        </Controls>
                    }
                />
            </div>
            <RatingMarkModal id={id} resetId={resetId} />
        </>
    );
};
