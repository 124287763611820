import { yup } from "../../../../yup";
import { FILE_TOO_LARGE, FORM_FIELD_REQUIRED_ERROR } from "../../../../constants/errors";

const MAX_PHOTO_SIZE = 15;

export const validationSchema = yup.object().shape({
    name: yup.string().required().max(150),
    description: yup.string().required().max(500),
    languages: yup.array().required(),
    city: yup.string().required(),
    image: yup
        .object()
        .shape({
            file: yup
                .mixed()
                .test(
                    "is-file-too-large",
                    FILE_TOO_LARGE(MAX_PHOTO_SIZE),
                    (value: any): boolean => !value || value.size < MAX_PHOTO_SIZE * 1024 * 1024
                ),
        })
        .test("required", FORM_FIELD_REQUIRED_ERROR, (value: any) => value?.id || value?.file),
});
