import { HotelServiceData, HotelSelectedServiceData } from "./types";
import { useCallback, useMemo } from "react";
import { OptionMultiSelect } from "@russpass/partner-front-ui";
import { sendEvent } from "../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../product_analytics/constants";
import uuid from "react-native-uuid";
import { TSelectValue } from "../../../../types/dictionaries";

type Props = {
    data: HotelSelectedServiceData;
    services: HotelServiceData;
    onChange: (values: any) => void;
    isViewMeals: boolean;
};

const useHotelServices = ({ data, services, onChange, isViewMeals }: Props) => {
    const selectedServices = useMemo(() => {
        return {
            commonServices: getSelectedOptions(services.commonServices, data.commonServices),
            meals: getSelectedOptions(services.meals, data.meals),
            beachServices: getSelectedOptions(services.beachServices, data.beachServices),
            entertainmentServices: getSelectedOptions(services.entertainmentServices, data.entertainmentServices),
            fitnessServices: getSelectedOptions(services.fitnessServices, data.fitnessServices),
            facilityServices: getSelectedOptions(services.facilityServices, data.facilityServices),
        };
    }, [services, data]);

    const options = useMemo(() => {
        let groups = [
            {
                groupId: uuid.v4() as string,
                groupTitle: "Общие",
                options: services.commonServices,
            },
            {
                groupId: uuid.v4() as string,
                groupTitle: "Пляж",
                options: services.beachServices,
            },
            {
                groupId: uuid.v4() as string,
                groupTitle: "Развлечения",
                options: services.entertainmentServices,
            },
            {
                groupId: uuid.v4() as string,
                groupTitle: "Красота и здоровье",
                options: services.fitnessServices,
            },
            {
                groupId: uuid.v4() as string,
                groupTitle: "Удобства",
                options: services.facilityServices,
            },
        ];
        if (isViewMeals) {
            groups.splice(1, 0, {
                groupId: uuid.v4() as string,
                groupTitle: "Питание",
                options: services.meals,
            });
        }
        return groups;
    }, [isViewMeals, services]);

    const saveSelectServices = useCallback(
        (values) => {
            onChange({
                commonServices: getSelectedIds(services.commonServices, values),
                meals: getSelectedIds(services.meals, values),
                beachServices: getSelectedIds(services.beachServices, values),
                entertainmentServices: getSelectedIds(services.entertainmentServices, values),
                fitnessServices: getSelectedIds(services.fitnessServices, values),
                facilityServices: getSelectedIds(services.facilityServices, values),
            });
            sendEvent(AnalyticEvent.services_info_save_button);
        },
        [services, onChange]
    );

    return {
        saveSelectServices,
        selectedServices,
        options,
    };
};

export default useHotelServices;

const getSelectedOptions = (initialArray: TSelectValue[], selectedIds: string[] | undefined) => {
    let options: OptionMultiSelect[] = [];
    if (!!selectedIds) {
        initialArray.forEach((item) => {
            if (selectedIds.indexOf(item.id) > -1) {
                options.push(item);
            }
            return item;
        });
    }
    return options;
};

const getSelectedIds = (initialArray: TSelectValue[], selectedValues: TSelectValue[]) => {
    let ids: string[] = [];
    initialArray.forEach((item) => {
        if (selectedValues.find((value) => item.id === value.id)) {
            ids.push(item.id);
        }
        return item;
    });
    return ids;
};
