import classNames from "classnames";
import { useFormikContext } from "formik";
import moment from "moment";
import { MutableRefObject, useCallback, useMemo } from "react";
import { RangeModifier } from "react-day-picker";
import { ReactComponent as Question } from "../../../../../../../assets/images/icons/ic_question_small.svg";
import { QuestionTooltip } from "@russpass/partner-front-ui";
import { PlaceTimetableButtonKeys } from "../../../../../../../components/timetable/place-timetable/constants";
import { formatPrice } from "../../../../../booking/helpers";
import { Calendar } from "../../../../components/Calendar";
import { CalendarColors } from "../../index";
import styles from "./styles.module.sass";

type Props = {
    name: string;
    colorsList: MutableRefObject<{ [key in CalendarColors]: string }>;
};

enum DayIndex {
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
}

export const CalendarField = ({ name, colorsList }: Props) => {
    const { getFieldProps } = useFormikContext();
    const value = useMemo(() => getFieldProps(name).value, [getFieldProps, name]);

    const modifiers = useMemo(() => {
        let items = value
            ?.filter((item: any) => !!item.price)
            .map(({ dates, scheduleType, id }: any) => {
                const colorsKeys = Object.keys(colorsList.current) as CalendarColors[];

                let resultColor = "";
                for (const color of colorsKeys) {
                    if (colorsList.current[color] === id) {
                        resultColor = color;
                        break;
                    }
                }
                if (!resultColor) {
                    for (const color of colorsKeys) {
                        if (!colorsList.current[color]) {
                            resultColor = color;
                            break;
                        }
                    }
                }

                colorsList.current = { ...colorsList.current, [resultColor]: id };

                if (scheduleType === PlaceTimetableButtonKeys.Days) {
                    const parseDate = dates.map((date: Date) => {
                        const parseDate = new Date(date);
                        return parseDate.toDateString();
                    });
                    return { [resultColor]: (day: Date) => parseDate.includes(day.toDateString()) };
                }

                if (scheduleType === PlaceTimetableButtonKeys.WeekDays) {
                    const parseDays = dates.map((date: DayIndex) => DayIndex[date]);
                    return { [resultColor]: (day: Date) => parseDays.includes(day.getDay()) };
                }

                if (scheduleType === PlaceTimetableButtonKeys.RangeDays) {
                    return {
                        [resultColor]: (day: Date) =>
                            dates.some((range: RangeModifier) =>
                                moment(day).isBetween(moment(range.from), moment(range.to), "days", "[]")
                            ),
                    };
                }
                return {};
            });

        let newItems: any[] = [];
        items.forEach((el: any, idx: number) => {
            const key = Object.keys(el)[0];
            if (!key) return;

            const ownCheck = el[key];
            if (!ownCheck) return;

            const checks: any[] = [];

            for (let i = items.length - 1; i > idx; i--) {
                const nextItem = items[i];
                if (!nextItem) break;
                const prevKey = Object.keys(nextItem)[0];
                if (!prevKey) continue;

                checks.push(nextItem[prevKey]);
            }
            const newModifier = (day: any) => checks.every((check) => !check(day)) && ownCheck(day);
            newItems.push({ [key]: newModifier });
        });
        items = newItems;

        const baseModifiers = { hideActive: () => true };

        if (!items) return baseModifiers;

        let result = {};

        items.forEach((item: any) => {
            result = { ...result, ...item };
        });

        return { ...result, ...baseModifiers };
    }, [colorsList, value]);

    const findColorByIdx = useCallback(
        (value: string) => {
            const keys = Object.keys(colorsList.current);
            for (const key of keys) {
                const validKey = key as CalendarColors;
                if (colorsList.current[validKey] === value) {
                    return key;
                }
            }
            return undefined;
        },
        [colorsList]
    );

    return (
        <div className={styles.wrapper}>
            <Calendar modifiers={modifiers} />
            <div className={styles.price}>
                {value?.map((item: any, idx: number) => {
                    if (!item.price) return null;

                    const priceFormatted = formatPrice(+item.price.replaceAll(" ", ""));
                    const showTooltip = [PlaceTimetableButtonKeys.Days, PlaceTimetableButtonKeys.RangeDays].includes(
                        item.scheduleType
                    );

                    const itemContent = (
                        <div className={styles.price__item_content} key={idx}>
                            <div
                                className={classNames(
                                    styles.price__square,
                                    styles[`price__square--${findColorByIdx(item.id)}`]
                                )}
                            />
                            {priceFormatted}/ ночь
                            {showTooltip && (
                                <>
                                    {" "}
                                    <Question />
                                </>
                            )}
                        </div>
                    );

                    return (
                        <div key={idx} className={styles.price__item} style={{ gridArea: `item-${idx}` }}>
                            {showTooltip ? (
                                <QuestionTooltip
                                    body="Цена в определенные даты имеет приоритет над ценами по дням недели"
                                    icon={itemContent}
                                />
                            ) : (
                                itemContent
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
