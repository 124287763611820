import moment from "moment";
import "moment/locale/ru";
import { Modifier, RangeModifier } from "react-day-picker";
import { DAY_DATE_FORMAT, ISO_DATE_FORMAT, MID_DAY_MONTH_DATE_FORMAT } from "../../../constants/dates-format";
import { weekDaysSort } from "../../../constants/week-days";
import { weekDayPronouns1, weekDayPronouns2, weekDayPronouns3, weekDays, weekDaysShortNames } from "./constants";
import { DateControlValue, DayTimeTable, PlaceTimetableItemType, Range, Matcher } from "./types";

// Возвращает boolean выбраны ли дни недели в режиме работы
export const getIsWeekDays = (value: DateControlValue) => {
    return value.length && typeof value[0] === "string" && weekDays[value[0].toLowerCase()];
};

// Возвращает boolean выбраны ли отдельные дни в режиме работы
export const getIsDays = (value: DateControlValue) => {
    return value.length && typeof value[0] === "string" && !weekDays[value[0].toLowerCase()];
};

// Возвращает boolean выбран ли диапазон дат в режиме работы
export const getIsRangeDays = (value: DateControlValue) => {
    return value.length && typeof value[0] !== "string";
};

// Получение задизейбленных опций для текущего компонента date-control с учетов выбранных в нем опций
export const getDisabledOptions = (selectedOptions: DateControlValue, disabledOptions?: (string | Matcher)[]) => {
    let newDisableOptions: (string | Matcher)[] = [];
    disabledOptions?.map((option: string | Matcher) => {
        if (typeof option === typeof selectedOptions[0]) {
            if (typeof option === "string") {
                option = option.toLowerCase();
                if (selectedOptions.indexOf(option) === -1) {
                    if (!weekDays[option]) {
                        newDisableOptions.push(new Date(option));
                    }
                }
            } else {
                if (
                    !selectedOptions.find((item) => {
                        return (
                            //@ts-ignore
                            moment(new Date(item.from)).isSame(option.from) &&
                            //@ts-ignore
                            moment(new Date(item.to), ISO_DATE_FORMAT).isSame(option.to)
                        );
                    })
                ) {
                    newDisableOptions.push(option);
                }
            }
        }
        return option;
    });
    return newDisableOptions;
};

// Собирает все выбранные дни недели / даты / диапазоны дат для режима работы тарифа
export const preparedDisabledOptions = (data: PlaceTimetableItemType[], isWorkingMode?: boolean) => {
    let newDisabledOptions: (string | Modifier | DayTimeTable)[] = [];
    data.forEach((item) => {
        const workingDays = getStringArray(item.workingDays);
        if (getIsRangeDays(workingDays) && !isWorkingMode) {
            newDisabledOptions.push({
                // @ts-ignore
                from: new Date(workingDays[0].from),
                // @ts-ignore
                to: new Date(workingDays[0].to),
            });
        } else {
            newDisabledOptions = [...newDisabledOptions, ...item.workingDays];
        }
        return item;
    });
    return newDisabledOptions;
};

// Фильтрация задизейбленных опций для datepicker (возвращает только даты и диапазоны дат)
export const getDisabledDays = (disabledOptions?: (string | Matcher)[]) => {
    let newDisabledDays: Matcher[] = [];
    disabledOptions?.map((day) => {
        if (typeof day !== "string") {
            newDisabledDays.push(day as Matcher);
        }
        return day;
    });
    return newDisabledDays;
};

// Возвращает выбранное значение для отображения в компоненте date-control
export const getDisplayingValue = (value: DateControlValue, withPronoun = false) => {
    let displayingValue = "";
    const isWeekDays = getIsWeekDays(value);
    const isDays = getIsDays(value);

    if (isWeekDays || isDays) {
        displayingValue = value.reduce((prev: string, current: string | Range) => {
            const weekDay =
                isWeekDays && typeof current === "string"
                    ? dayOfTheWeekConversion(current.toLowerCase(), prev, withPronoun)
                    : moment(current as string, ISO_DATE_FORMAT)
                          .locale("ru")
                          .format(MID_DAY_MONTH_DATE_FORMAT)
                          .replace(" ", " "); // заменяем пробел на неразрывный для корректного переноса строк
            if (weekDay) {
                if (prev.length > 0) prev += ", ";
                return prev + weekDay;
            }
            return prev;
        }, displayingValue);
    }
    if (getIsRangeDays(value)) {
        value.forEach((range: string | Range, index) => {
            const rangeFrom = (range as Range)?.from;
            const rangeTo = (range as Range)?.to;
            if (!rangeFrom || !rangeTo) return;

            const isSameMonth = moment(rangeFrom).isSame(rangeTo, "month");

            const from = moment(rangeFrom).format(isSameMonth ? DAY_DATE_FORMAT : MID_DAY_MONTH_DATE_FORMAT);
            const to = moment(rangeTo).format(MID_DAY_MONTH_DATE_FORMAT);

            displayingValue += from + " - " + to;
            if (index !== value.length - 1) {
                displayingValue += ", ";
            }
        });
    }
    return displayingValue;
};

export const dayOfTheWeekConversion = (value: string, prev: string, withPronoun?: boolean): string =>
    withPronoun && prev.search("Кажд(ую|ый|ое)") === -1 ? addDayOfTheWeekPronoun(value) : weekDaysShortNames[value];

const addDayOfTheWeekPronoun = (day: string) => {
    const shortDay = weekDaysShortNames[day];
    const { pronoun1, weekDays1 } = weekDayPronouns1;
    const { pronoun2, weekDays2 } = weekDayPronouns2;
    const { pronoun3, weekDays3 } = weekDayPronouns3;

    switch (true) {
        case weekDays1.includes(day):
            return `${pronoun1} ${shortDay}`;
        case weekDays2.includes(day):
            return `${pronoun2} ${shortDay}`;
        case weekDays3.includes(day):
            return `${pronoun3} ${shortDay}`;
    }
};

//Возвращает boolean выбраны ли были отдельные дни в режиме работы тарифа
export const getIsDaysExisting = (value: DateControlValue) => {
    return typeof value[0] === "string" && !weekDays[value[0].toLowerCase()];
};

//Возвращает boolean выбраны ли были периоды в режиме работы тарифа
export const getIsRangeDaysExisting = (value: DateControlValue) => {
    // @ts-ignore
    return value.some((range: RangeModifier) => {
        if (range && range.from && range.to) {
            return range.from !== range.to;
        } else if (
            typeof range === "object" &&
            !Array.isArray(range) &&
            range !== null &&
            range.from === undefined &&
            range.to === undefined
        ) {
            return true;
        }
        return false;
    });
};

//Возвращает boolean выбраны ли были дни недели в режиме работы тарифа
export const getIsWeekDaysExisting = (value: DateControlValue) => {
    return typeof value[0] === "string" && !!weekDays[value[0].toLowerCase()];
};

//Сортировка дней недели
export const sortWeekDays = (a: string | Range, b: string | Range) => {
    if (typeof a === "string" && typeof b === "string") {
        if (weekDaysSort[a.toUpperCase()] < weekDaysSort[b.toUpperCase()]) {
            return -1;
        }
        if (weekDaysSort[a.toUpperCase()] > weekDaysSort[b.toUpperCase()]) {
            return 1;
        }
        return 0;
    }
    return 0;
};

// Сортировка дат
export const sortDays = (a: string | Range, b: string | Range) => {
    if (typeof a === "string" && typeof b === "string") {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if (dateA < dateB) {
            return -1;
        }
        if (dateA > dateB) {
            return 1;
        }
        return 0;
    }
    return 0;
};

export const getStringArray = (data: any[] | undefined) =>
    data ? data.filter((item): item is string => typeof item === "string") : [];

export const getDayTimeTableArray = (data: any[] | undefined) =>
    data ? data.filter((item): item is DayTimeTable => typeof item === "object") : [];

export const getRangeArray = (data: any[] | undefined) =>
    data
        ? data.filter(
              (item): item is Range =>
                  typeof item === "object" && item.hasOwnProperty("from") && item.hasOwnProperty("to")
          )
        : [];

export const getDateControlArray = (data: any[] | undefined) =>
    data
        ? data.filter(
              (item): item is string =>
                  typeof item === "string" || (item.hasOwnProperty("from") && item.hasOwnProperty("to"))
          )
        : [];

export const getDaysOffRow = (weekends: DayTimeTable[]) => {
    const isNotDefinedDaysOff = weekends.length === 8;
    const isNoDaysOff = weekends.length === 0 || (weekends.length === 1 && weekends[0].value === "allDays");
    if (isNotDefinedDaysOff) {
        return "Не указано";
    } else if (isNoDaysOff) {
        return "Без выходных";
    } else {
        const lastOffDay = weekends.length - 1;
        return weekends.reduce((acc, day, idx) => {
            if (day.value === "allDays") return "";
            return idx === lastOffDay ? acc + day.shortName : acc + day.shortName + ", ";
        }, "");
    }
};
