import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ReactComponent as CancelIcon } from "../../../../assets/images/icons/ic_arrow_light_left.svg";
import styles from "./ModalMenu.module.sass";
import classNames from "classnames";
import { MenuProps } from "./types";
import { ModalWindowRight, SearchInput, RadioButton } from "@russpass/partner-front-ui";

const ModalMenu = ({
    isOpen,
    close,
    placeholder,
    searchText,
    setSearchText,
    options,
    label,
    value,
    setValue,
    optionRenderer,
    saveOnClick,
}: MenuProps & { label: string }) => {
    const [tempValue, setTempValue] = useState(value);
    useEffect(() => {
        setTempValue(value);
    }, [value, isOpen]);

    const save = useCallback(
        (value?: any) => {
            if (!value) {
                setValue(tempValue);
                return;
            }

            setTempValue(value);
            setValue(value);
        },
        [setValue, tempValue]
    );

    const handleOptionClick = useCallback(
        (value: any) => () => {
            if (saveOnClick) {
                save(value);
                return;
            }
            setTempValue(value);
        },
        [save, saveOnClick]
    );

    const handleSearch = useCallback((value) => setSearchText(value || ""), [setSearchText]);

    return (
        <ModalWindowRight
            isOpened={isOpen}
            onClose={close}
            isHideCloseButton
            ComponentFooter={
                !saveOnClick && (
                    <div className={styles.Footer}>
                        <button className="button button--primary" onClick={() => save()}>
                            Сохранить
                        </button>
                    </div>
                )
            }
        >
            <div className={styles.Header}>
                <button onClick={close} className={classNames("button", styles.CloseButton)}>
                    <CancelIcon className="button__icon" />
                </button>
                {label}
            </div>
            <div className={styles.Search}>
                <SearchInput currentValue={searchText} handleSearch={handleSearch} placeholder={placeholder} />
            </div>
            <div>
                {options.map((option) => {
                    const isActive = option.value === tempValue;

                    if (optionRenderer) {
                        return (
                            <Fragment key={option.value}>
                                {optionRenderer(option, isActive, handleOptionClick(option.value))}
                            </Fragment>
                        );
                    }

                    return (
                        <RadioButton
                            key={option.value}
                            label={option.label}
                            value={option.value}
                            isActive={isActive}
                            handleClick={handleOptionClick(option.value)}
                        />
                    );
                })}
            </div>
        </ModalWindowRight>
    );
};

export default ModalMenu;
