import { AnySchema } from "yup";
import { NavbarApartmentsNewName } from "./constants";
import { objectStepDraftSchema, objectStepSchema } from "./steps/object/validationSchema";
import { detailsStepDraftSchema, detailsStepSchema } from "./steps/details/validationSchema";
import { photoStepSchema } from "./steps/photo/validationSchema";
import { yup } from "../../../yup";
import { tariffsStepDraftSchema, tariffsStepSchema } from "./steps/tariffs/validationSchema";
import { saleAndCancelStepDraftSchema, saleAndCancelStepSchema } from "./steps/sale-and-cancel/validationSchema";

export const getAddObjectSchema = (isNew: boolean, step?: string) => {
    const newSchema: Record<string, AnySchema> = {
        [NavbarApartmentsNewName.OBJECT]: yup.object().shape({
            [NavbarApartmentsNewName.OBJECT]: objectStepSchema,
        }),
        [NavbarApartmentsNewName.DETAILS]: yup.object().shape({
            [NavbarApartmentsNewName.DETAILS]: detailsStepSchema,
        }),
        [NavbarApartmentsNewName.PHOTO]: yup.object().shape({
            [NavbarApartmentsNewName.PHOTO]: photoStepSchema,
        }),
        [NavbarApartmentsNewName.TARIFFS]: yup.object().shape({
            [NavbarApartmentsNewName.TARIFFS]: tariffsStepSchema,
        }),
        [NavbarApartmentsNewName.SALE_AND_CANCEL]: yup.object().shape({
            [NavbarApartmentsNewName.OBJECT]: objectStepSchema,
            [NavbarApartmentsNewName.DETAILS]: detailsStepSchema,
            [NavbarApartmentsNewName.PHOTO]: photoStepSchema,
            [NavbarApartmentsNewName.TARIFFS]: tariffsStepSchema,
            [NavbarApartmentsNewName.SALE_AND_CANCEL]: saleAndCancelStepSchema,
        }),
    };

    return isNew ? newSchema[step as string] : newSchema[NavbarApartmentsNewName.SALE_AND_CANCEL];
};

export const draftSchema = yup.object().shape({
    [NavbarApartmentsNewName.OBJECT]: objectStepDraftSchema,
    [NavbarApartmentsNewName.DETAILS]: detailsStepDraftSchema,
    [NavbarApartmentsNewName.TARIFFS]: tariffsStepDraftSchema,
    [NavbarApartmentsNewName.SALE_AND_CANCEL]: saleAndCancelStepDraftSchema,
});
