import classNames from "classnames";
import { useState, useCallback } from "react";
import { InputField } from "@russpass/partner-front-ui";
import { maskedInputNumbers } from "../../../../../utils/validation";

import "./styles.sass";
import { IBankField } from "../../../../../types/profile";

type InfoHotelProps = {
    onBankValidate: (fieldName: IBankField, fieldValue: string) => void;
    bankErrors: { corrAccount?: string; checkingAccount?: string };
};

const InfoHotel: React.FC<InfoHotelProps> = ({ onBankValidate, bankErrors }) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const handleEditClick = useCallback(() => setIsDisabled(false), [setIsDisabled]);

    return (
        <div className={classNames(isDisabled && "info-hotel__container")}>
            <div className="info-hotel__container__title">Реквизиты объекта</div>
            <div className="info-hotel__container__description">
                Оплата за бронирования будет поступать по реквизитам из оферты. Если для этого объекта они отличаются,
                отредактируйте данные ниже
            </div>
            <InputField
                label="БИК банка"
                name="bankDetails.bic"
                noHover
                disabled={isDisabled}
                maxLength={9}
                onValidate={(e) => onBankValidate("bic", e.target.value)}
                errorSettings={{ showCustomError: true }}
                renderComponent={maskedInputNumbers}
            />
            <InputField label="Наименование банка" name="bankDetails.name" noHover disabled={isDisabled} />
            <InputField
                label="Расчетный счет"
                name="bankDetails.checkingAccount"
                noHover
                disabled={isDisabled}
                onValidate={(e) => onBankValidate("checkingAccount", e.target.value)}
                maxLength={20}
                errorSettings={{ showCustomError: true }}
                error={bankErrors.checkingAccount}
                renderComponent={maskedInputNumbers}
            />
            <InputField
                label="Корреспондентский счет"
                name="bankDetails.corrAccount"
                noHover
                disabled={isDisabled}
                onValidate={(e) => onBankValidate("corrAccount", e.target.value)}
                maxLength={20}
                errorSettings={{ showCustomError: true }}
                error={bankErrors.corrAccount}
                renderComponent={maskedInputNumbers}
            />
            {isDisabled && (
                <div className="info-hotel__container__edit-button">
                    <button type="button" className="button button--second" onClick={handleEditClick}>
                        Редактировать реквизиты
                    </button>
                </div>
            )}
        </div>
    );
};

export default InfoHotel;
