import { array as arrayYup, object as objectYup, string as stringYup } from "yup";
import { FORM_FIELD_REQUIRED_ERROR, MAX_CHATACTERS_ERROR } from "../../../../constants/errors";
import { testFilesSizeSum } from "../../../../utils/validation";

export const MAX_FILE_SIZE = 30;
export const disputeValidation = objectYup().shape({
    description: stringYup()
        .trim()
        .min(1, FORM_FIELD_REQUIRED_ERROR)
        .max(2000, MAX_CHATACTERS_ERROR)
        .required(FORM_FIELD_REQUIRED_ERROR),
    files: arrayYup().test(
        "files",
        `Общий размер файлов не должен превышать ${MAX_FILE_SIZE} мб`,
        testFilesSizeSum(MAX_FILE_SIZE)
    ),
});
