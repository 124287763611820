import { object as objectYup, string as stringYup, array as arrayYup } from "yup";
import { phoneRegexp } from "../../../../utils/validation";
import { FORM_FIELD_REQUIRED_ERROR, FORMAT_ERROR } from "../../../../constants/errors";
import { placeTimetableValidation } from "../../../../components/timetable/place-timetable/validation-schema";

export const getWorkingTimeValidation = () => arrayYup().of(placeTimetableValidation(true));

export const servicePlaceValidation = objectYup().shape({
    title: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    region: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    city: arrayYup().of(stringYup()).required(FORM_FIELD_REQUIRED_ERROR),
    addressDetail: objectYup({
        unrestricted_value: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    }),
    coordinates: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    partnerType: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    phone: stringYup().required(FORM_FIELD_REQUIRED_ERROR).matches(phoneRegexp, FORMAT_ERROR),
    workingTime: arrayYup().of(placeTimetableValidation(true)),
});
