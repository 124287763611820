import { FieldProps, useFormikContext } from "formik";
import getValue from "lodash/get";

type ErrorSettings = {
    showOnChange?: boolean;
    showCustomError?: boolean;
};

const parseFieldError = ({
    fieldError,
    fieldValue,
    fieldTouched,
    errorSettings,
    isFormChanged,
    submitCount,
}: {
    fieldError: string | undefined;
    fieldValue: any;
    fieldTouched: boolean;
    isFormChanged: boolean;
    submitCount: number;
    errorSettings?: ErrorSettings;
}) => {
    let error: boolean;
    // Бывают моменты, когда нужно показывать ошибку при вводе значений и без оглядки на первоначальные данные
    if ((errorSettings && errorSettings.showOnChange) || submitCount) {
        error = !!fieldError;
    } else if (errorSettings && errorSettings.showCustomError) {
        // если поле заполнено, но есть кастомная ошибка
        error = !!fieldError && !!fieldValue;
    } else {
        error = !!fieldError && fieldTouched && isFormChanged;
    }

    return error ? (fieldError as string) : undefined;
};

export const useFieldError = (name: string, errorSettings?: ErrorSettings) => {
    const { values, initialValues, errors, touched, submitCount } = useFormikContext();

    return parseFieldError({
        fieldError: getValue(errors, name) as string | undefined,
        fieldValue: getValue(values, name),
        fieldTouched: !!getValue(touched, name),
        isFormChanged: initialValues !== values,
        submitCount,
        errorSettings,
    });
};

export const getFieldError = (fieldProps: FieldProps, errorSettings?: ErrorSettings) => {
    const { field, form } = fieldProps;

    return parseFieldError({
        fieldError: getValue(form.errors, field.name) as string | undefined,
        fieldValue: getValue(form.values, field.name),
        fieldTouched: !!getValue(form.touched, field.name),
        isFormChanged: form.initialValues !== form.values,
        submitCount: form.submitCount,
        errorSettings,
    });
};
