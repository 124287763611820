import fetchRequest from "./manager";
import { partnerApiServices, russpassApiServices } from "../constants/api";

const refbookPartnerServiceUrl = `${russpassApiServices.partnership}${partnerApiServices.refbook}`;

export const getAgeRestriction = async () => {
    const content = await fetchRequest.get(`${refbookPartnerServiceUrl}/ageRestriction/limits`, null, {
        isProtected: true,
    });
    return content;
};
