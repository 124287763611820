import eventDispatcher from "../../../lib/event-dispatcher";
import { ErrorTitleWithCounter } from "../../notification/components/errorTitleWithCounter";
import { SECONDS_TO_REDIRECT } from "../constants/constants";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

export const useSetErrorNotification = () => {
    const setNotification = (errorTitle: string) => {
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            isAlwaysView: true,
            body: <ErrorTitleWithCounter initialSeconds={SECONDS_TO_REDIRECT} titleError={errorTitle} />,
        });
    };

    return { setNotification };
};
