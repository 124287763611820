import styles from "./sleeping-places-result.module.sass";
import { MouseEvent, useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import iconClose from "../../../../../../../../../assets/images/icons/ic_close_circle.svg";

type SleepingPlacesResultProps = {
    items: { label: string; value: string }[];
    value: { [key: string]: string };
    position: string;
};

export const SleepingPlacesResult = ({ items, value, position }: SleepingPlacesResultProps) => {
    const { setFieldValue } = useFormikContext();

    const renderItems = useMemo(
        () =>
            Object.keys(value).map((name) => ({
                title: items.find((item) => item.value === name)?.label,
                length: value[name],
                key: name,
            })),
        [items, value]
    );

    const onDelete = useCallback(
        (e: MouseEvent<HTMLElement>, key: string) => {
            e.stopPropagation();
            const newObj = { ...value };
            delete newObj[key];
            if (Object.keys(newObj).length === 0) {
                return setFieldValue(position, undefined);
            }
            setFieldValue(position, newObj);
        },
        [position, setFieldValue, value]
    );

    return (
        <div className={styles.wrapper}>
            {renderItems.map(({ title, length, key }) => {
                if (!length) return null;
                return (
                    <div className={styles.item} key={key}>
                        {title} × {length}
                        <img src={iconClose} alt="" onClick={(e) => onDelete(e, key)} className={styles.item__delete} />
                    </div>
                );
            })}
        </div>
    );
};
