import { Form, Formik, FormikProps } from "formik";
import { TextareaField, StatusRequestEnum } from "@russpass/partner-front-ui";
import { validationSchema } from "./validation-schema";
import { useRentHousesOwnerProfile } from "../../../../api/hooks/rentHouses";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { UNEXPECTED_ERROR } from "../../../../constants/errors";
import AvatarField from "../user/components/AvatarField";
import { saveRentHousesImage, updateRentHousesOwnerProfile } from "../../../../api/rentHouses";
import { RentHousesOwnerStatus } from "../../../../types/rentHouses";
import UserActionsContainer from "./components/user-actions-container";
import { ProfileMode } from "./constants";
import useUserInfo from "../../../../hooks/useUserInfo";
import ModerationInfo from "../user/components/ModerationInfo";

type FormValues = {
    image: { id?: string; file?: File };
    name: string;
    description: string;
};

const UserGuide = () => {
    const { data, error, mutate } = useRentHousesOwnerProfile();
    const { userFullInfo } = useUserInfo();

    const [profileMode, setProfileMode] = useState<ProfileMode>(ProfileMode.EDIT);

    useEffect(() => {
        if (!data?.data) {
            setProfileMode(ProfileMode.EMPTY);
        } else if (data?.data?.status !== RentHousesOwnerStatus.APPROVED) {
            setProfileMode(ProfileMode.EDIT);
        } else {
            setProfileMode(ProfileMode.EDIT_SHARE);
        }
    }, [data]);

    const initialValues = useMemo(() => {
        if (!data?.data) return {} as FormValues;

        return {
            image: data.data.image ? { id: data.data.image } : undefined,
            name: data.data.name,
            description: data.data.description,
        } as FormValues;
    }, [data]);

    const profileUrl = useMemo(() => {
        return data?.data && userFullInfo?.cmsPartnerId
            ? `${process.env.REACT_APP_RUSSPASS_URL}/provider-profile/${userFullInfo.cmsPartnerId}`
            : undefined;
    }, [data?.data, userFullInfo.cmsPartnerId]);

    useEffect(() => {
        if (!error) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [error]);

    const submit = async (values: FormValues) => {
        try {
            let imageId = values.image.id || "";
            if (values.image.file) {
                imageId = (await saveRentHousesImage(values.image.file)).data.cmsId;
            }
            await updateRentHousesOwnerProfile({
                ...values,
                image: imageId,
            });
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Success,
                body: "Информация отправлена на модерацию",
            });
            setProfileMode(ProfileMode.EDIT);
            await mutate();
        } catch (e) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: UNEXPECTED_ERROR,
            });
        }
    };

    const change = useCallback(() => {
        setProfileMode(ProfileMode.SEND_MODERATION);
    }, [initialValues]);

    const copy = useCallback(() => {
        if (profileUrl) {
            navigator?.clipboard
                ?.writeText(profileUrl)
                .then(() => {
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Ссылка на профиль скопирована",
                    });
                })
                .catch((err) => {
                    console.error("Async: Could not copy text: ", err);
                });
        }
    }, [profileUrl]);

    const edit = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            setProfileMode(ProfileMode.SEND_MODERATION);
        },
        [ProfileMode, setProfileMode]
    );

    const isPendingUpdate = useMemo(() => data?.data?.status === RentHousesOwnerStatus.PENDING_UPDATE, [data]);
    const isPending = useMemo(() => data?.data?.status === RentHousesOwnerStatus.PENDING, [data]);
    const formDisabled = useMemo(() => {
        return !(profileMode == ProfileMode.SEND_MODERATION || profileMode === ProfileMode.EMPTY);
    }, [isPendingUpdate, isPending, profileMode]);
    const isShowModerationInfo = useMemo(() => data?.data?.status === RentHousesOwnerStatus.DECLINED, [data]);

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
            validateOnMount
            onSubmit={submit}
        >
            {({ values }: FormikProps<FormValues>) => {
                return (
                    <div className="content-wrapper">
                        <div className="content-wrapper__form">
                            <Form onChange={change}>
                                {isShowModerationInfo && <ModerationInfo info={data?.data?.declineReason} />}
                                <AvatarField
                                    name="image"
                                    disabled={formDisabled}
                                    tooltipText="Добавьте вашу фотографию"
                                    hideInput={formDisabled}
                                />
                                <TextareaField
                                    name="name"
                                    label="Имя для профиля"
                                    placeholder="Введите не менее 2 символов"
                                    hint={values.name ? "" : "Отобразится на детальной странице экскурсии"}
                                    noHover
                                    disabled={formDisabled}
                                    maxLength={50}
                                    minLength={values.name ? 2 : undefined}
                                />
                                <TextareaField
                                    name="description"
                                    label="Информация о себе"
                                    placeholder="Введите не менее 50 символов"
                                    hint={
                                        values.description
                                            ? ""
                                            : "Поделитесь, как давно проводите экскурсии и чем руководствуетесь при составлении маршрутов"
                                    }
                                    noHover
                                    disabled={formDisabled}
                                    maxLength={2000}
                                    minLength={values.description ? 50 : undefined}
                                />
                                {!(profileMode === ProfileMode.EMPTY) && (
                                    <UserActionsContainer
                                        profileMode={profileMode}
                                        editButtonText="Редактировать"
                                        copyButtonText="Поделиться профилем"
                                        sendModerationButtonText="Отправить на модерацию"
                                        onClickCopyButton={copy}
                                        onClickEditButton={edit}
                                    />
                                )}
                            </Form>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default UserGuide;
