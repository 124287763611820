import { BaseFieldProps } from "./types";
import { SelectInputField } from "@russpass/partner-front-ui";
import { useFormikContext } from "formik";

const OPTIONS = [
    { code: "OWNER", caption: "Собственник жилья" },
    {
        code: "AGENT",
        caption: "Доверенное лицо",
        hint: "Обязательна нотариально заверенная доверенность",
    },
];

export const SignatoryRoleField = ({ name }: BaseFieldProps) => {
    const { setFieldValue, getFieldProps } = useFormikContext();

    const value = getFieldProps(name).value;

    return (
        <SelectInputField
            name={name}
            handleChange={(value) => {
                setFieldValue(name, value.code);
            }}
            options={OPTIONS}
            value={OPTIONS.find((el) => el.code === value) || { code: "", caption: "" }}
            valueField="code"
            labelField="caption"
            placeholder="Выбрать"
            label="Кто подписывает"
            isChecked
        />
    );
};
