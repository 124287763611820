import { useCallback, useEffect, useMemo, useState } from "react";
import { NOT_FULLED_SECTION_ERROR, NOT_FULLED_SECTIONS_ERROR, POST_DATA_ERROR2 } from "../../../constants/errors";
import { navbarTourNames, navbarTourLabels, navbarTour, ErrorEmptyPhotos, ErrorEmptyTariffs } from "./constants";
import { TourDataCreatedResponse, TourDataExpanded } from "./types";
import { Tariff } from "../components/tariffs/types";
import { tourInfoValidation, tourProgramValidation } from "./validation-schema";
import eventDispatcher from "../../../lib/event-dispatcher";
import { createTour, sendTourForApproval } from "../../../api/tour";
import { updateTariff } from "../../../api/billing";
import history from "../../../history";
import routes from "../../../constants/routes";
import moment from "moment";
import { DATE_2061, EXTENDED_DATE_TIME_FORMAT } from "../../../constants/dates-format";
import { createTariffs } from "../components/tariffs/utils";
import { processingForUpdateTariff } from "../utils";
import { cloneDeep } from "lodash";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import useUserInfo from "../../../hooks/useUserInfo";

type Props = {
    data: TourDataExpanded;
    tariffs: Tariff[];
    onChangeTariff: (tariffs: Tariff[]) => void;
    step: string | number | undefined;
};

const useSendToModerate = ({ data, tariffs, onChangeTariff, step }: Props) => {
    const [navbar, setNavbar] = useState(cloneDeep(navbarTour));

    const [isAwaitRequest, setIsAwaitRequest] = useState<boolean>(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const [tourSaved, setTourSaved] = useState<TourDataCreatedResponse | null>(null);
    const [isSaveTariff, setIsSaveTariff] = useState<boolean>(false);

    const isValidInfo = useMemo(() => tourInfoValidation.isValidSync(data), [data]);
    const isValidProgram = useMemo(() => data.route.length > 0 && tourProgramValidation.isValidSync(data), [data]);
    const isValidPhoto = useMemo(() => data.images.length > 0, [data]);
    const isValidTariff = useMemo(() => tariffs.length > 0, [tariffs]);

    const { userFullInfo } = useUserInfo();

    useEffect(() => {
        setTourSaved(null);
        setIsSaveTariff(false);
    }, [data]);

    useEffect(() => {
        setIsSaveTariff(false);
    }, [tariffs]);

    useEffect(() => {
        if (isSubmittingForm) {
            setNavbar((prev) => {
                return prev.map((item: any) => {
                    if (item.name === navbarTourNames.tour) item.isError = !isValidInfo;
                    if (item.name === navbarTourNames.program) item.isError = !isValidProgram;
                    if (item.name === navbarTourNames.photos) item.isError = !isValidPhoto;
                    if (item.name === navbarTourNames.tariffs) item.isError = !isValidTariff;
                    return item;
                });
            });
        }
    }, [data, tariffs, isSubmittingForm, isValidInfo, isValidPhoto, isValidProgram, isValidTariff]);

    const goToModerate = useCallback(async () => {
        setIsSubmittingForm(true);
        if (!isValidInfo || !isValidProgram || !isValidTariff || !isValidPhoto) {
            const error = getErrorForm({ isValidInfo, isValidPhoto, isValidProgram, isValidTariff, step });

            if (error) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: error,
                });
            }

            if (!isValidPhoto && step === navbarTourNames.photos) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: ErrorEmptyPhotos,
                });
            }

            if (!isValidTariff && step === navbarTourNames.tariffs) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: ErrorEmptyTariffs,
                });
            }
        } else {
            if (userFullInfo && userFullInfo.email) {
                setIsAwaitRequest(true);
                try {
                    const price = tariffs.reduce((acc, val) => {
                        const price = val?.itemPrices[0]?.price;
                        if (acc === "") acc = price || "";

                        if (acc) {
                            if (Number(acc) > Number(price)) {
                                acc = price;
                            }
                        }

                        return acc;
                    }, "");

                    const [, ...otherPhoto] = data.images;

                    const tourData: TourDataExpanded = {
                        ...data,
                        slug: data.title,
                        price,
                        isCanBuy: tariffs.length > 0,
                        images: [...otherPhoto],
                        seasonStart: data.isAllYear
                            ? moment(new Date()).format(EXTENDED_DATE_TIME_FORMAT)
                            : data.seasonStart,
                        seasonEnd: data.isAllYear
                            ? moment(DATE_2061).format(EXTENDED_DATE_TIME_FORMAT)
                            : data.seasonEnd,
                    };
                    delete tourData["isAllYear"];

                    const tour: TourDataCreatedResponse = tourSaved ? { ...tourSaved } : await createTour(tourData);

                    setTourSaved(tour);

                    if (tariffs.length > 0 && !isSaveTariff) {
                        await createTariffs(tariffs, tour.billingProductId);
                        setIsSaveTariff(true);
                    }

                    await sendTourForApproval(tour.cmsTourId);
                    setIsAwaitRequest(false);
                    history.push(routes.servicesAll);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Тур отправлен на модерацию",
                        isAlwaysView: true,
                    });
                } catch (err) {
                    console.error(err);
                    setIsAwaitRequest(false);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR2,
                    });
                }
            }
        }
    }, [
        data,
        tariffs,
        isValidInfo,
        isValidPhoto,
        isValidProgram,
        userFullInfo,
        isValidTariff,
        step,
        isSaveTariff,
        tourSaved,
    ]);

    const addTariffForExistingTour = useCallback(
        async (newTariffs) => {
            setIsSubmittingForm(true);
            if (userFullInfo && userFullInfo.email && newTariffs.length > 0 && data?.billingProductId) {
                try {
                    setIsAwaitRequest(true);
                    const createdTariffs = await createTariffs(newTariffs, +data?.billingProductId);
                    onChangeTariff([...tariffs, ...createdTariffs]);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Новый тариф отправлен",
                    });
                } catch (error) {
                    console.error(error);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR2,
                    });
                }
            }
        },
        [userFullInfo, data, onChangeTariff, tariffs]
    );

    const deleteTariffForExistingTour = useCallback(
        async (tariff) => {
            if (userFullInfo && userFullInfo.email) {
                setIsAwaitRequest(true);
                try {
                    await updateTariff(processingForUpdateTariff({ tariff, isDelete: true }), tariff.id);
                    setIsAwaitRequest(false);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Тариф удален",
                    });
                } catch (error) {
                    console.error(error);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR2,
                    });
                }
            }
        },
        [userFullInfo]
    );

    const updateTariffForExistingTour = useCallback(
        async (newTariff) => {
            setIsSubmittingForm(true);
            if (userFullInfo && userFullInfo.email) {
                setIsAwaitRequest(true);
                try {
                    await updateTariff(processingForUpdateTariff({ tariff: newTariff, isDelete: false }), newTariff.id);
                    setIsAwaitRequest(false);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Обновленный тариф отправлен",
                    });
                } catch (error) {
                    console.error(error);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR2,
                    });
                }
            }
        },
        [userFullInfo]
    );

    return {
        goToModerate,
        isAwaitRequest,
        isSubmittingForm,
        navbar,
        updateTariffForExistingTour,
        addTariffForExistingTour,
        deleteTariffForExistingTour,
    };
};

export default useSendToModerate;

interface IGetErrorForm {
    isValidInfo: boolean;
    isValidPhoto: boolean;
    isValidProgram: boolean;
    isValidTariff: boolean;
    step: string | number | undefined;
}

const getErrorForm = ({ isValidInfo, isValidPhoto, isValidProgram, isValidTariff, step }: IGetErrorForm) => {
    let error = undefined;

    const countErrorForm = [isValidInfo, isValidPhoto, isValidProgram, isValidTariff].reduce((sum, current) => {
        if (!current) sum++;
        return sum;
    }, 0);

    if (countErrorForm > 1) {
        error = NOT_FULLED_SECTIONS_ERROR;
    }
    if (countErrorForm === 1 && step !== navbarTourNames.photos) {
        error = NOT_FULLED_SECTION_ERROR;
        if (!isValidInfo) {
            error += ` ${navbarTourLabels.tour}`;
        }
        if (!isValidTariff) {
            error += ` ${navbarTourLabels.tariffs}`;
        }
        if (!isValidProgram) {
            error += ` ${navbarTourLabels.program}`;
        }
        if (!isValidPhoto) {
            error += ` ${navbarTourLabels.photos}`;
        }
    }

    return error;
};
