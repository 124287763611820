import { fileType } from "../containers/documents/report/types";

export const downloadMultipleFilesByUrl = (files: fileType[]) => {
    let timeoutMs = 0;

    files.forEach((file) => {
        const link = document.createElement("a");

        link.setAttribute("download", "");
        link.setAttribute("href", file.url);

        setTimeout(() => link.click(), timeoutMs);

        timeoutMs = +1000;
    });
};
