import React, { MouseEvent, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./SubmitButton.module.sass";
import { useViewportContext } from "@russpass/partner-front-ui";

type Props = PropsWithChildren<{
    isValid?: boolean;
    fixed?: boolean;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}>;

const SubmitButton = ({ children, isValid, fixed, onClick }: Props) => {
    const { isMobile } = useViewportContext();

    const buttonRender = (
        <button
            className={classNames("button button--full-width", {
                "button--second": !isValid,
                "button--primary": isValid,
            })}
            type="submit"
            onClick={onClick}
        >
            {children}
        </button>
    );

    if (fixed)
        return (
            <>
                <div className={styles.Wrapper}>
                    <div className={styles.Floating}>{buttonRender}</div>
                </div>
            </>
        );

    return (
        <>
            {isMobile && <div className={styles.Placeholder} />}
            <div className={classNames("form-item", "mt-24", styles.SubmitButton)}>{buttonRender}</div>
        </>
    );
};

export default SubmitButton;
