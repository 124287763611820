import { ISO_DATE_FORMAT } from "./../../../../constants/dates-format";

import { RangeType } from "../types";
import moment from "moment";

export function compareDates(date1: string | undefined, date2: string | undefined) {
    return (
        date1 === date2 ||
        (date1 && date2 && new Date(date1).setHours(0, 0, 0, 0) === new Date(date2).setHours(0, 0, 0, 0))
    );
}

export const getPeriodString = (startDate?: string, endDate?: string, short?: boolean) => {
    if (startDate && endDate) {
        const from = new Date(startDate);
        const to = new Date(endDate);
        if (compareDates(startDate, endDate)) {
            return new Date(startDate).toLocaleDateString("ru", {
                month: "long",
                day: "numeric",
            });
        }
        if (from.getMonth() === to.getMonth()) {
            return `${from.getDate()} – ${to.toLocaleDateString("ru", {
                month: short ? "short" : "long",
                day: "numeric",
            })}`;
        }
        return `${from.toLocaleDateString("ru", {
            month: short ? "short" : "long",
            day: "numeric",
        })} – ${to.toLocaleDateString("ru", {
            month: short ? "short" : "long",
            day: "numeric",
        })}`;
    }
    return null;
};

// Возвращает выбранное значение для отображения в компоненте date-control
export const getDisplayingValue = (value: RangeType) => {
    const displayingValue = value?.from && value?.to && getPeriodString(value?.from, value?.to, true);
    return displayingValue;
};

export const getDisabledDays = () => {
    const now = moment().format(ISO_DATE_FORMAT);
    return {
        after: new Date(now),
    };
};
