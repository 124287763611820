import { ModalWindowRight } from "@russpass/partner-front-ui";
import useSupportChatModal from "./useSupportChatModal";
import styles from "./styles.module.sass";
import { useCallback, useEffect, useRef, useState } from "react";
import SendMessageForm from "../components/send-message-form";
import MessagesContainer from "../components/messages-container";
import { saveRentHousesSupportFile, sendRentHousesSupportMessage } from "../../../../../api/rentHousesSupport";
import { DialogueMessageFormModel } from "../guest/DialogueModal";
import { ReactFilesFileWithUri } from "../../../../../components/fields/fileUpload";
import { FormikHelpers } from "formik/dist/types";
import { RentHousesSupportChatMessageCreator } from "../../../../../types/rentHousesSupport";
import Message from "../components/message";
import systemUserIcon from "../../../../../assets/images/icons/rp_circle.svg";
import userIcon from "../../../../../assets/images/icons/ic_user_40.svg";
import useSupportChatMessages from "./useSupportChatMessages";
import InfiniteScroll from "react-infinite-scroll-component";

const SCROLL_ID = "messages";

const SupportChatModal = () => {
    const { isOpen, close } = useSupportChatModal();

    const { messages, isLoadingMessages, refreshMessages, loadNextMessages, hasMoreMessages } = useSupportChatMessages(
        !isOpen
    );

    const fileRef = useRef<any>();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const sendMessage = useCallback(
        async (
            { message, answerFiles }: DialogueMessageFormModel,
            { resetForm }: FormikHelpers<DialogueMessageFormModel>
        ) => {
            if (isSubmitting) return;

            try {
                setIsSubmitting(true);

                const ids: string[] = [];

                for (const file of answerFiles as (ReactFilesFileWithUri | string)[]) {
                    if (typeof file === "string") {
                        ids.push(file as string);
                    } else {
                        const saveResult = await saveRentHousesSupportFile(file);
                        ids.push(saveResult.data.id);
                    }
                }

                await sendRentHousesSupportMessage({
                    message: message || undefined,
                    files: ids.length ? ids : undefined,
                });

                resetForm();
                await refreshMessages();
            } catch (e) {
                console.error(e);
            } finally {
                setIsSubmitting(false);
            }
        },
        [isSubmitting, refreshMessages]
    );

    useEffect(() => {
        if (!isOpen) return;

        refreshMessages();
    }, [isOpen, refreshMessages]);

    return (
        <ModalWindowRight
            isOpened={isOpen}
            onClose={close}
            title="Поддержка RUSSPASS"
            ComponentFooter={<SendMessageForm fileRef={fileRef} onSubmit={sendMessage} />}
            customClassNames={styles.modal}
            unmountContentOnClose
        >
            <MessagesContainer scrollId={SCROLL_ID} isLoading={isLoadingMessages || isSubmitting}>
                <InfiniteScroll
                    inverse
                    dataLength={messages.length}
                    next={loadNextMessages}
                    hasMore={hasMoreMessages}
                    loader={null}
                    scrollableTarget={SCROLL_ID}
                    scrollThreshold="250px"
                >
                    {messages.map((message) => {
                        const fromCurrentUser = message.creator === RentHousesSupportChatMessageCreator.USER;
                        const imagePlaceholder =
                            message.creator === RentHousesSupportChatMessageCreator.ADMIN ? systemUserIcon : userIcon;

                        return (
                            <Message
                                key={message.id}
                                image={message.avatar}
                                imagePlaceholder={imagePlaceholder}
                                content={message.message}
                                attachments={message.files}
                                date={message.createdAt}
                                showDate={message.firstInMonth}
                                fromCurrentUser={fromCurrentUser}
                                rtl={fromCurrentUser}
                            />
                        );
                    })}
                </InfiniteScroll>
            </MessagesContainer>
        </ModalWindowRight>
    );
};

export default SupportChatModal;
