import {
    InputField,
    TextareaField,
    RadioGroupField,
    Banner,
    MultiCheckedField,
    SelectInputField,
} from "@russpass/partner-front-ui";
import { DiscountBonusType, DiscountType, HotelDiscountsForm } from "../../../../../types/hotel-discounts";
import DiscountDateField from "./components/DiscountDateField";
import { Form, FormikState } from "formik";
import React, { useCallback } from "react";
import useControlDiscountForm from "../../../hooks/useControlDiscountForm";
import "./hotel-discount-form.sass";
import { DISCOUNT_TITLE_MAX } from "./hotel-discount-schema";
import { createNumberMask } from "text-mask-addons";
import MaskedInput from "react-text-mask";

type Props = {
    isDisabled: boolean;
    values: HotelDiscountsForm;
    resetForm: (nextState?: Partial<FormikState<HotelDiscountsForm>> | undefined) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    isShowAlert: boolean;
    closeAlert: () => void;
};

const HotelDiscountForm = ({ isDisabled, values, resetForm, setFieldValue, isShowAlert, closeAlert }: Props) => {
    const {
        offerTypeValue,
        onChangeOfferType,
        bonusCategoryValue,
        roomIdValue,
        tariffsValue,
        roomsCategoryUpgrade,
        roomsCategoryUpgradeOptions,
        bonusOptions,
        roomsOptions,
        tariffsOptions,
        typesOptions,
    } = useControlDiscountForm(isDisabled, values, resetForm);

    const handleDiscountValidate = useCallback(
        (event) => setFieldValue("discountSize", event.target.value.replace("%", "")),
        [setFieldValue]
    );
    const handleBonusChange = useCallback(
        (value) => {
            setFieldValue("bonusCategory", value.value);
            setFieldValue("roomsCategoryUpgrade", undefined);
        },
        [setFieldValue]
    );
    const handleRoomChange = useCallback(
        (value) => {
            setFieldValue("roomId", value.value);
            setFieldValue("tariffs", undefined);
            setFieldValue("roomsCategoryUpgrade", undefined);
        },
        [setFieldValue]
    );
    const handleTariffsChange = useCallback(
        (value) => setFieldValue("tariffs", value?.map((v: any) => v.value) || []),
        [setFieldValue]
    );
    const handleCategoryChange = useCallback(
        (value) => setFieldValue("roomsCategoryUpgrade", [value.value]),
        [setFieldValue]
    );

    return (
        <Form className="discount-form">
            {isShowAlert && (
                <Banner
                    body="Для редактирования акции завершите ее досрочно и перейдите в Архив"
                    onClose={closeAlert}
                    style={{ position: "fixed", top: "120px", left: "50%", transform: "translateX(-50%)" }}
                />
            )}
            <TextareaField
                label="Название"
                name="title"
                noHover
                placeholder="Новая акция"
                hint="Пользователь увидит его в описании тарифа"
                disabled={isDisabled}
                maxLength={DISCOUNT_TITLE_MAX}
            />
            <RadioGroupField
                name="specialOfferType"
                value={offerTypeValue}
                options={typesOptions}
                label="Тип"
                onChange={onChangeOfferType}
                disabled={isDisabled}
            />
            {values.specialOfferType === DiscountType.DISCOUNT && (
                <InputField
                    label="Процент скидки"
                    name="discountSize"
                    noHover
                    placeholder="10%"
                    disabled={isDisabled}
                    onValidate={handleDiscountValidate}
                    renderComponent={(props) => (
                        <MaskedInput
                            {...props}
                            mask={createNumberMask({
                                prefix: "",
                                includeThousandsSeparator: false,
                                suffix: "%",
                            })}
                        />
                    )}
                    transformDisabledValue={(value) => `${value}%`}
                />
            )}

            {values.specialOfferType === DiscountType.BONUS && (
                <SelectInputField
                    name="bonusCategory"
                    handleChange={handleBonusChange}
                    options={bonusOptions}
                    value={bonusCategoryValue}
                    placeholder="Выбрать"
                    label="Вид бонуса"
                    disabled={isDisabled}
                    isChecked
                />
            )}
            <DiscountDateField name="date" tabName="dateView" disabled={isDisabled} />
            <SelectInputField
                name="roomId"
                handleChange={handleRoomChange}
                options={roomsOptions}
                value={roomIdValue}
                placeholder="Выбрать"
                label="Распространяется на номер"
                disabled={isDisabled}
                hint={
                    values.bonusCategory === DiscountBonusType.BONUS_UPGRADE
                        ? "Стоимость этого номера будет применена к более дорогому"
                        : ""
                }
                isChecked
            />
            {values.roomId && (
                <MultiCheckedField
                    name="tariffs"
                    label="Распространяется на тарифы номера"
                    placeholder="Выбрать"
                    value={tariffsValue}
                    noHover
                    options={tariffsOptions}
                    disabled={isDisabled}
                    onChange={handleTariffsChange}
                />
            )}
            {values.bonusCategory === DiscountBonusType.BONUS_UPGRADE && values.roomId && (
                <SelectInputField
                    name="roomsCategoryUpgrade"
                    handleChange={handleCategoryChange}
                    options={roomsCategoryUpgradeOptions}
                    value={roomsCategoryUpgrade}
                    placeholder="Выбрать"
                    label="Повышается на номер"
                    disabled={isDisabled}
                    isChecked
                />
            )}
        </Form>
    );
};

export default HotelDiscountForm;
