import { useMemo } from "react";
import { navbarBookingNames } from "../constants";
import { useRentHousesBookings } from "../../../../api/hooks/rentHouses";
import { RentHousesBookingStatus } from "../../../../types/rentHouses";
import { ReactComponent as TooltipIcon } from "../../../../assets/images/icons/ic_clock_info.svg";
import { isShowInsuranceInfo } from "../../../../constants/feature-toggles";
import { HeaderTab } from "@russpass/partner-front-ui";

export const useBookingNavbar = (): Array<HeaderTab> => {
    const { data } = useRentHousesBookings({
        page: 1,
        pageSize: 1,
        status: [RentHousesBookingStatus.ACTIVE, RentHousesBookingStatus.STARTED],
    });

    return useMemo(
        () => [
            {
                label: "Ожидают подтверждения",
                name: navbarBookingNames.pending,
            },
            {
                label: "Активные",
                name: navbarBookingNames.active,
                tooltip:
                    isShowInsuranceInfo && data?.data?.total
                        ? {
                              content: "Все бронирования, оформленные за 14 дней до заезда, застрахованы",
                              icon: <TooltipIcon />,
                          }
                        : undefined,
            },
            {
                label: "Завершенные",
                name: navbarBookingNames.completed,
            },
            {
                label: "Отклоненные",
                name: navbarBookingNames.rejected,
            },
        ],
        [data?.data?.total]
    );
};
