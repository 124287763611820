import React from "react";
import { Formik } from "formik";
import useApartmentsNew from "./hooks/useApartmentsNew";
import ApartmentsNewContent from "./ApartmentsNewContent";
import StatusModal from "./components/StatusModal";

const ApartmentsNew = () => {
    const { step, setStep, initialProps, submit, schema, repeat, submitStatus, isSubmitting } = useApartmentsNew();

    return (
        <Formik
            initialValues={initialProps}
            validationSchema={schema}
            onSubmit={submit}
            validateOnMount
            enableReinitialize
            validateOnChange={false}
        >
            <>
                <StatusModal status={submitStatus} repeat={repeat} isLoading={isSubmitting} />
                <ApartmentsNewContent step={step} setStep={setStep} isLoading={isSubmitting} />
            </>
        </Formik>
    );
};

export default ApartmentsNew;
