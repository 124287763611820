import { useMemo } from "react";
import useUserInfo from "../../../../../hooks/useUserInfo";

export type OfferTypeStatus = {
    isHotel: boolean;
    isApartment: boolean;
    isTourOperator: boolean;
    isGuide: boolean;
};

export const useOfferTypeStatus = (): OfferTypeStatus => {
    const { isHotel, isApartment, isTourOperator, isGuide } = useUserInfo();

    return useMemo(
        () => ({
            // isHotel: true,
            isHotel: isHotel,
            // isApartment: false,
            isApartment: isApartment,
            // isTourOperator: false,
            isTourOperator: isTourOperator,
            // isGuide: false,
            isGuide: isGuide,
        }),
        [isHotel, isApartment, isTourOperator, isGuide]
    );
};
