import fetchRequest from "../manager";
import {
    AnswerRequestData,
    ReservationsQueryParams,
    ReservationsResponse,
} from "../../containers/restaurants/booking/types";
import { russpassApiServices } from "../../constants/api";
import { getParamsForRequest } from "../../utils";

const feedbackApiEventsUrl = `${russpassApiServices.feedback}/reservation`;

export const getReservations = async (params: ReservationsQueryParams): Promise<ReservationsResponse> => {
    const response = await fetchRequest.get(`${feedbackApiEventsUrl}${getParamsForRequest(params)}`, null, {
        isProtected: true,
    });
    return response.data;
};

export const acceptReservation = async (id: string, data: AnswerRequestData) => {
    const response = await fetchRequest.post(`${feedbackApiEventsUrl}/reply/${id}`, data, {
        isProtected: true,
    });
    return response;
};
