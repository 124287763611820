import React from "react";
import { Input } from "@russpass/partner-front-ui";
import styles from "./ticket-item.module.sass";
import { OrderExpanded, OrderLineExpanded, PersonalData } from "../../types";
import { defaultLanguage } from "../../../../constants/language";
import moment from "moment";
import { DOTTED_DATE_FORMAT } from "../../../../constants/dates-format";
import { maskedInputPhone } from "../../../../utils/validation";
import { ReactComponent as IsChild } from "../../../../assets/images/icons/ic_child.svg";
import { Tooltip } from "@russpass/partner-front-ui";

type Props = {
    data: OrderLineExpanded;
    order: OrderExpanded;
    index: number;
};

const TicketItem = ({ order, data, index }: Props) => {
    return (
        <div className={styles.ticket_item}>
            <div className={styles.ticket_item__wrap}>
                <div className={styles.ticket_item__header}>
                    <div className={styles.ticket_item__row}>
                        <div className={styles.ticket_item__column}>
                            <div className={styles.ticket_item__header_label}>Тариф</div>
                            <div className={styles.ticket_item__header_value} data-qa-name="tariff-caption">
                                {data.item.tariffCaption.find((tariff) => tariff.language === defaultLanguage)
                                    ?.content || ""}
                            </div>
                        </div>
                        <div className={styles.ticket_item__column}>
                            <div className={styles.ticket_item__header_label}>Цена</div>
                            <div className={styles.ticket_item__header_value} data-qa-name="tariff-amount">
                                {data.amount} ₽
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.ticket_item__content}>
                    {data.personalDataId !== null && (
                        <Input
                            inputProps={{
                                value: getFullName(data.personalData),
                                disabled: true,
                                name: "tourist-name",
                            }}
                            label={"ФИО турист " + (index + 1)}
                            noHover
                        />
                    )}
                    <Input
                        inputProps={{
                            value: order.phone || "",
                            disabled: true,
                            name: "tourist-phone",
                        }}
                        renderComponent={maskedInputPhone}
                        label="Телефон"
                        noHover
                    />
                    <Input
                        inputProps={{
                            value: order.email,
                            disabled: true,
                            name: "tourist-email",
                        }}
                        label="E-mail"
                        noHover
                    />
                    {data.personalDataId !== null && (
                        <div className={styles.ticket_item__birthday}>
                            <Input
                                inputProps={{
                                    value: moment(data.personalData.birthday).format(DOTTED_DATE_FORMAT),
                                    disabled: true,
                                    name: "tourist-birthday",
                                }}
                                label="Дата рождения"
                                noHover
                            />
                            {data.item.isChild && (
                                <div className={styles.ticket_item__isChild}>
                                    <Tooltip
                                        overlay="Проверьте подходит ли дата рождения туриста под детский тариф"
                                        tooltipWidth="203px"
                                        offset={{ X: 200, Y: 1 }}
                                    >
                                        <button className="button">
                                            <IsChild className="button__icon" />
                                        </button>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TicketItem;

const getFullName = (data: PersonalData) => {
    let name = "";
    if (data.lastName) {
        name += data.lastName + " ";
    }
    if (data.firstName) {
        name += data.firstName + " ";
    }
    if (data.middleName) {
        name += data.middleName;
    }
    return name;
};
