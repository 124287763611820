import {
    useViewportContext,
    ModalWindowRight,
    StatusRequestEnum,
    ActionButtonWrapper,
} from "@russpass/partner-front-ui";
import { Header, HeaderInfo, HeaderSubtitle, HeaderTitle } from "../components/Header";
import { Container } from "../components/Container";
import { BaseBookingInfo } from "../components/BaseBookingInfo";
import { RentHousesBooking } from "../../../../../types/rentHouses";
import { parseModalTitle } from "../../helpers";
import styles from "./GuestRatingModal.module.sass";
import classNames from "classnames";
import { useCallback, useState } from "react";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { createRentHousesBookingMark } from "../../../../../api/rentHouses";
import { POST_DATA_ERROR2 } from "../../../../../constants/errors";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";

type Props = { booking: RentHousesBooking; close: () => void };

export const GuestRatingModal = ({ booking, close }: Props) => {
    const { isMobile } = useViewportContext();

    const subtitle = parseModalTitle(booking);

    const [mark, setMark] = useState<number | null>(null);

    const [isLoading, setIsLoading] = useState(false);

    const createMark = useCallback(async () => {
        if (!mark) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: "Вы не выбрали оценку",
            });
            return;
        }
        setIsLoading(true);
        try {
            await createRentHousesBookingMark(booking.id, mark);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Success,
                body: "Спасибо за оценку!",
            });
            close();
        } catch (e) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: POST_DATA_ERROR2,
            });
        }
        setIsLoading(false);
    }, [booking.id, close, mark]);

    return (
        <ModalWindowRight
            isOpened
            onClose={close}
            isLoadingWithContent={isLoading}
            headerComponent={
                <Header>
                    <HeaderInfo>
                        <HeaderTitle>Оценка гостя</HeaderTitle>
                        <HeaderSubtitle>{subtitle}</HeaderSubtitle>
                    </HeaderInfo>
                </Header>
            }
            ComponentFooter={
                <ActionButtonWrapper>
                    <button
                        type="button"
                        className="button button--primary button--full-width"
                        onClick={() => {
                            sendEvent(AnalyticEvent.confirm_rate, {
                                rate: mark,
                            });
                            createMark();
                        }}
                    >
                        Оценить
                    </button>
                </ActionButtonWrapper>
            }
        >
            <Container>
                <div
                    className={classNames(styles.Title, {
                        [styles.Title__mobile]: isMobile,
                    })}
                >
                    Оцените гостя по шкале от 1 до 10
                </div>
                <BaseBookingInfo booking={booking} />
                <div className={styles.Rating}>
                    {[...new Array(10)].map((_, idx) => {
                        const value = idx + 1;
                        return (
                            <button
                                type="button"
                                className={classNames(styles.Item, {
                                    [styles.Item__mobile]: isMobile,
                                    [styles.Active]: value === mark,
                                    [styles.Low]: value < 5,
                                    [styles.Normal]: value >= 5 && value <= 6,
                                    [styles.High]: value > 6,
                                })}
                                onClick={() => setMark(value)}
                                key={idx}
                            >
                                {value}
                            </button>
                        );
                    })}
                </div>
            </Container>
        </ModalWindowRight>
    );
};
