import { FeedbackModel } from "../../types/FeedbackModel";
import { FeedbackAttachment } from "./FeedbackAttachments";
import { useCallback, useMemo } from "react";
import { getFileLink } from "../../../../api/attach";
import { isImageExtension } from "../../../../utils/isImageExtension";

export const useFeedbackAttachments = (feedback: FeedbackModel | undefined) => {
    const getFeedbackAttachments = useCallback(
        (files: string[]): FeedbackAttachment[] =>
            files.map((file, idx) => {
                // file - идентификатор вида file/000000000000.jpeg
                const extension: string = file?.split(".").pop() || "";
                const isImage: boolean = isImageExtension(extension);

                const fileUrl: string = getFileLink(file);

                return {
                    previewUrl: isImage ? fileUrl : undefined,
                    title: `Вложение ${idx + 1}`,
                    info: extension.toUpperCase(),
                    downloadUrl: fileUrl,
                };
            }),
        []
    );

    const answerFilesUrls = useMemo(
        () => feedback?.answerFiles?.map((file) => (typeof file === "string" ? file : file.uri || "")) || [],
        [feedback?.answerFiles]
    );

    return useMemo(() => {
        const questionAttachments = getFeedbackAttachments(feedback?.files || []);
        const answerAttachments = getFeedbackAttachments(answerFilesUrls);

        return {
            questionAttachments,
            answerAttachments,
        };
    }, [answerFilesUrls, feedback?.files, getFeedbackAttachments]);
};
