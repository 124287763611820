export const isButtonAddTariff = {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 12px 16px rgba(0, 0, 0, 0.04)",
    border: "none",
    padding: "54px 16px",
};
export const isTariffHotelStyles = {
    border: "2px solid #FAEFDB",
    padding: "24px",
    textAlign: "left",
    minHeight: "100%",
};

export const mealsRoomOnly = "RoomOnly";

export const tariffDescriptionsText = {
    freeCancel: "Возможна бесплатная отмена",
    paidСancel: "Платная отмена",
};

export const modalAlertDetailsDeleteTariff = {
    isRoomsWithTariff: {
        title: "Удаление недоступно",
        OKButton: "Закрыть",
    },
    noRoomsWithTariff: {
        title: "Вы уверены что хотите удалить тариф?",
        OKButton: "Удалить",
        cancelButton: "Отменить",
    },
};

export const TARIFF_DELETED = "Тариф удален";
