import { yup } from "../../../../yup";

export const REASON_MAX = 5000;

export const rejectSchema = yup.object().shape({
    reason: yup.string().max(REASON_MAX).required(),
    files: yup.array().limitFilesSizeSum().limitFiles(),
});

export const supportSchema = yup.object().shape({
    type: yup.string().required("Пожалуйста, выберите пункт"),
    reason: yup.string().max(REASON_MAX).required(),
    files: yup.array().limitFilesSizeSum().limitFiles(),
});
