import ReportTable from "./components/report-table";
import styles from "./report.module.sass";
import DateControl from "./date-control/date-control";
import { CommonLayout } from "@russpass/partner-front-ui";
import { useUpdateUserReports } from "../hooks/useUpdateUserReports";
import { navbarDocuments, navbarDocumentsNames } from "../constants";
import routes from "../../../constants/routes";
import history from "../../../history";
import { useMemo } from "react";

const Report = () => {
    const { isChecked, isLoading, loadReport, dataReport, isNotCompletedPeriod } = useUpdateUserReports();
    !isChecked && history.push(routes.contracts);

    const emptyTitle: string = useMemo(
        () =>
            isNotCompletedPeriod
                ? "У вас нет отчетных документов"
                : "У вас нет отчетных документов за указанный период",
        [isNotCompletedPeriod]
    );
    const emptyHint: string = useMemo(
        () => (isNotCompletedPeriod ? "Они появятся после завершения отчетного периода" : "Поменяйте отчетный период"),
        [isNotCompletedPeriod]
    );

    return (
        <CommonLayout
            pageTitle="Документы"
            navbar={isChecked ? navbarDocuments : undefined}
            isLoadingWithContent={isLoading}
            activeTabName={navbarDocumentsNames.report}
            isRouting
            handlerChangeRoute={(route) => {
                history.push(route);
            }}
        >
            <>
                {isChecked && (
                    <div className="content-wrapper content-wrapper--large">
                        {!isNotCompletedPeriod && (
                            <div className={styles.report_wrapper__filter}>
                                <DateControl onChange={loadReport} />
                            </div>
                        )}

                        {dataReport.length > 0 ? (
                            <div className={styles.report_content}>
                                {dataReport.map((report, index) => (
                                    <div key={`report-${report.year}`}>
                                        <div
                                            className={styles.report_content__title}
                                            data-qa-name="report-content-title"
                                        >{`${report.year} год`}</div>
                                        <ReportTable data={report.arrayReports} />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className={styles.report_wrapper}>
                                <div className={styles.report_empty}>
                                    <div className={styles.report_empty__title}>{emptyTitle}</div>
                                    <div className={styles.report_empty__hint}>{emptyHint}</div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </>
        </CommonLayout>
    );
};

export default Report;
