import React from "react";

type Props = {
    onClick: () => void;
    buttonText?: string;
};

const ActionForm = ({ onClick, buttonText }: Props) => {
    return (
        <>
            <div className="offer-text__desc">
                Нажимая на кнопку, вы принимаете условия публичной оферты и даете согласие на обработку
                <a
                    href="https://russpass.ru/personal-data"
                    title="персональные данные"
                    className="offer-text__link"
                    target="_blank"
                    rel="noreferrer"
                >
                    {" "}
                    персональных данных
                </a>
            </div>
            <button className="button button--primary" onClick={onClick}>
                {buttonText ? buttonText : "Принять условия оферты"}
            </button>
        </>
    );
};

export default ActionForm;
