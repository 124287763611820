import { optionsSignLegalBasis } from "../../constants";
import { RadioGroup } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";
import { BaseFieldProps } from "../../../../../../types/fields";

export const SignLegalBasisField = ({ name }: BaseFieldProps) => {
    const { setFieldValue, getFieldProps } = useFormikContext();

    return (
        <RadioGroup
            options={optionsSignLegalBasis}
            label="Юр. основание права подписи"
            value={optionsSignLegalBasis.find((option) => option.value === getFieldProps(name).value) || ""}
            onChange={(option) => {
                setFieldValue(name, option.value);
            }}
        />
    );
};
