import { RentHousesBookingItem, RentHousesBookingStatus } from "../../../../../types/rentHouses";
import styles from "./PriceCell.module.sass";
import { formatPrice } from "../../helpers";

type Props = {
    bookingItem: RentHousesBookingItem;
};

export const PriceCell = ({ bookingItem }: Props) => {
    const price = <>{formatPrice(bookingItem.amount)}</>;

    if (![RentHousesBookingStatus.ACTIVE, RentHousesBookingStatus.STARTED].includes(bookingItem.status)) return price;

    return !bookingItem.paid ? <span className={styles.Warn}>Не оплачено</span> : price;
};
