import styles from "../occupancies.module.sass";
import { maskedInputChildAge } from "../../../../../../../utils/validation";
import React, { useCallback } from "react";
import { Occupancy } from "../../../types/hotel-room";
import { ReactComponent as Trash } from "../../../../../../../assets/images/icons/ic_trash.svg";
import { InputField, CounterField, ErrorField } from "@russpass/partner-front-ui";
import classNames from "classnames";

type OccupancyItemProps = {
    value: Occupancy;
    name: string;
    onDelete: (id: string) => void;
    isViewHint: boolean;
};
const ChildOccupancy = ({ value, name, onDelete, isViewHint }: OccupancyItemProps) => {
    const handlerDelete = useCallback(() => {
        if (value.id) {
            onDelete(value.id);
        }
    }, [value, onDelete]);

    return (
        <div className={styles.occupancyTl}>
            <div
                className={classNames(styles.occupancyTl__wrapper, styles.occupancyTl__wrapper_flex, "mt-16", "mb-16")}
            >
                <div>
                    <div className="body">
                        Дополнительные места для детей <br />
                        (детская кроватка, раскладушка и др.)
                    </div>
                    {isViewHint && (
                        <div className="hint">
                            Укажите возрастной диапазон, отличный <br />
                            от первого
                        </div>
                    )}
                </div>
                <CounterField name={`${name}.child`} max={20} />
            </div>

            <div className={styles.childAge}>
                <InputField
                    label="Мин. возраст ребенка"
                    placeholder="0"
                    name={`${name}.minAge`}
                    noHover
                    maxLength={2}
                    renderComponent={maskedInputChildAge}
                />
                <InputField
                    label="Макс. возраст ребенка"
                    placeholder="17"
                    name={`${name}.maxAge`}
                    maxLength={2}
                    noHover
                    renderComponent={maskedInputChildAge}
                />
            </div>

            <div className={"mb-8 ml-24"}>
                <ErrorField
                    name={`${name}.age`}
                    errorSettings={{
                        showOnChange: true,
                    }}
                />
            </div>

            <div className={styles.occupancyTl__trash}>
                <button className="button" type="button" onClick={handlerDelete}>
                    <div className="button__icon button__icon--left">
                        <Trash />
                    </div>
                    Удалить
                </button>
            </div>
        </div>
    );
};

export default ChildOccupancy;
