import fetchRequest from "./manager";
import { russpassApiServices, partnerApiServices } from "../constants/api";
import { Facility, FacilityPatch, FacilityServer, PartnerFacilityType } from "../containers/services/types";
import { getParamsForRequest } from "../utils";

const partnershipServicePath = russpassApiServices.partnership;
const partnerPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.partner}`;
const facilitiesV2PartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.facilitiesV2}`;

export const getFacilityTypes = async () => {
    const content = await fetchRequest.get(`${partnerPartnerServiceUrl}/types`, null, {
        isProtected: true,
    });
    return content;
};

export const createFacility = async (data: FacilityServer): Promise<Pick<Facility, "id" | "cmsFacilityId">> => {
    const facility = await fetchRequest.post(facilitiesV2PartnerServiceUrl, data, {
        isProtected: true,
    });
    return facility;
};

export const updateFacility = async (data: FacilityPatch) => {
    const facility = await fetchRequest.patch(facilitiesV2PartnerServiceUrl, data, {
        isProtected: true,
    });

    return facility;
};

export const sendFacilityForApproval = async (id: string) => {
    const content = await fetchRequest.patch(`${facilitiesV2PartnerServiceUrl}/sendForApproval/${id}`, null, {
        isProtected: true,
    });
    return content;
};

export const getFacility = async (id: string) => {
    const content = await fetchRequest.get(`${facilitiesV2PartnerServiceUrl}/${id}`, null, {
        isProtected: true,
    });
    return content;
};

export const getFacilities = async (isAvailableForGuide?: boolean): Promise<PartnerFacilityType[]> => {
    const params = getParamsForRequest({
        isAvailableForGuide: isAvailableForGuide,
    });
    const content = await fetchRequest.get(`${facilitiesV2PartnerServiceUrl}${params}`, null, {
        isProtected: true,
    });
    return content;
};
