export const showcaseRusspassTitle = process.env.REACT_APP_SHOWCASE_RUSSPASS_TITLE || "";
export const showcaseMobileTitle = process.env.REACT_APP_SHOWCASE_MOBILE_TITLE || "";
export const publicationAvailable = process.env.REACT_APP_FEEDBACK_PUBLICATION_AVAILABLE || false;

export const SLIDE_TIMER = 5000;

export const classNamesStatusLabels = {
    draft: "draft",
    moderation: "moderation",
    refused: "refused",
    archive: "archive",
    published: "published",
};

export enum PartnerFacilityStatus {
    IMPORT = "import",
    ACTIVE = "active",
    DRAFT = "draft",
    DELETED = "deleted",
}
