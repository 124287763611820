import React, { useCallback, useState } from "react";
import { newTariffData } from "./mock";
import { Tariff } from "./types";
import TariffModal from "./tariff-modal";
import { cloneDeep } from "lodash";
import TariffsItem from "./tariffs-item";
import uuid from "react-native-uuid";
import { PlaceTimetableItemType, PlaceTimetableOptions } from "../../../../components/timetable/place-timetable/types";
import { Paginate, Card, CardsGrid, useModalAlertContext } from "@russpass/partner-front-ui";
import { useServiceTariffsContext } from "./tariffs-context";

type Props = {
    isExistingService: boolean;
    facilityWorkingTime?: PlaceTimetableItemType[];
    addTariffForExistingService: (tariffs: Tariff[]) => void;
    deleteTariffForExistingService: (tariff: Tariff) => void;
    updateTariffForExistingService: (tariffs: Tariff, showNotification?: boolean) => Promise<void>;
    updateDataField?: (val: string) => Promise<void>;
} & PlaceTimetableOptions;

const Tariffs = ({
    isExistingService,
    facilityWorkingTime,
    addTariffForExistingService,
    deleteTariffForExistingService,
    updateTariffForExistingService,
    updateDataField,
    isRenderValueWithPronoun = false,
    isVisiblePlaceTimetable = true,
    ...restOptions
}: Props) => {
    const { tariffs, loadTariffList, billingProductId, onChangeTariffList } = useServiceTariffsContext();
    const { openModalAlert } = useModalAlertContext();

    const [isOpenWindow, setIsOpenWindow] = useState(false);

    const [tariffData, setTariffData] = useState<Tariff>(newTariffData);

    const currentBasicTariff = tariffs.content.find((item) => item.isBasic) || null;

    const onDeleteTariff = useCallback(
        async (tariff: Tariff) => {
            if (tariff.isBasic) {
                return openModalAlert(
                    {
                        title: "Базовый тариф нельзя удалить",
                        description: `Вы можете выбрать другой тариф базовым и затем вернуться к удалению тарифа «${tariff.tariffCaption[0].content}» `,
                        OKButton: " Понятно",
                    },
                    () => {}
                );
            }

            const details = {
                title: "Вы уверены, что хотите удалить тариф?",
                OKButton: "Удалить",
                cancelButton: "Отменить",
            };

            const onOKButtonClickAction = isExistingService
                ? async () => {
                      await deleteTariffForExistingService(tariff);
                      loadTariffList(billingProductId, tariffs.number);
                  }
                : () => onChangeTariffList(tariffs.content.filter((prevTariff) => prevTariff.id !== tariff.id));

            openModalAlert(details, onOKButtonClickAction);
        },
        [
            openModalAlert,
            isExistingService,
            deleteTariffForExistingService,
            loadTariffList,
            billingProductId,
            tariffs.number,
            tariffs.content,
            onChangeTariffList,
        ]
    );

    const updatePrevBasicTariff = useCallback(async () => {
        //если новый базовый тариф, ставим прошлому isBasic = false
        if (currentBasicTariff) {
            //если это делается у существующего сервиса - запрос
            await updateTariffForExistingService(
                {
                    ...currentBasicTariff,
                    isBasic: false,
                },
                false //не показываем уведомление об обновлении прошлого базового тарифа
            );
        }
    }, [currentBasicTariff, updateTariffForExistingService]);

    const onSaveTariff = useCallback(
        async (tariff: Tariff[]) => {
            if (isExistingService) {
                if (tariff[0].isBasic && tariff[0].id !== currentBasicTariff?.id) {
                    await updatePrevBasicTariff();
                }

                await addTariffForExistingService(tariff);

                if (tariff[0].isBasic && tariff[0].itemPrices[0].price !== currentBasicTariff?.itemPrices[0].price) {
                    await updateDataField?.(tariff[0].itemPrices[0].price);
                }
                loadTariffList(billingProductId, tariffs.number);
            } else {
                let updatedTariffs = [...tariffs.content];
                const tariffIdx = updatedTariffs.findIndex((i) => i.id === tariff[0].id);
                const isNewBasic = tariff[0].isBasic && currentBasicTariff && tariff[0].id !== currentBasicTariff?.id;

                if (isNewBasic) {
                    const prevBasicTarifIdx = updatedTariffs.findIndex((i) => i.id === currentBasicTariff?.id);
                    updatedTariffs[prevBasicTarifIdx].isBasic = false;
                }

                if (tariffIdx !== -1) {
                    updatedTariffs[tariffIdx] = tariff[0];
                } else {
                    //если индекс не нашел значит это новый тариф
                    updatedTariffs = updatedTariffs.concat(tariff);
                }

                onChangeTariffList(updatedTariffs);
            }
            changeIsOpen(false);
        },
        [
            isExistingService,
            currentBasicTariff,
            addTariffForExistingService,
            loadTariffList,
            billingProductId,
            tariffs.number,
            tariffs.content,
            updatePrevBasicTariff,
            updateDataField,
            onChangeTariffList,
        ]
    );

    const onUpdateTariff = useCallback(
        async (tariff: Tariff) => {
            if (isExistingService) {
                if (tariff.isBasic && tariff.id !== currentBasicTariff?.id) {
                    await updatePrevBasicTariff();
                }
                await updateTariffForExistingService(tariff);

                if (tariff.isBasic && tariff.itemPrices[0].price !== currentBasicTariff?.itemPrices[0].price) {
                    await updateDataField?.(tariff.itemPrices[0].price);
                }

                loadTariffList(billingProductId, tariffs.number);
                changeIsOpen(false);
            }
        },
        [tariffs, isExistingService, updateTariffForExistingService, loadTariffList, billingProductId]
    );

    const onEditTariff = useCallback((tariff: Tariff) => {
        setTariffData(tariff);
        changeIsOpen(true);
    }, []);

    const onCopyTariff = useCallback(
        (tariff: Tariff) => {
            let newTariff = cloneDeep(tariff);
            newTariff.id = uuid.v4() as string;
            newTariff.tariffCaption[0].content = "Копия " + newTariff.tariffCaption[0].content;
            newTariff.isBasic = false;
            onChangeTariffList([...tariffs.content, newTariff]);
        },
        [tariffs, onChangeTariffList]
    );

    const changeIsOpen = (status: boolean) => {
        setIsOpenWindow(status);
    };

    const handleCardClick = useCallback(() => {
        onEditTariff(cloneDeep(newTariffData));
    }, [onEditTariff]);

    return (
        <>
            <CardsGrid>
                <Card
                    title="Добавить тариф"
                    desc="Укажите название и стоимость услуги"
                    onClick={handleCardClick}
                    styles={{
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0 12px 16px rgba(0, 0, 0, 0.04)",
                        border: "none",
                        padding: "24px 0",
                    }}
                />
                {tariffs.content.map((tariffItem) => {
                    return (
                        <TariffsItem
                            key={tariffItem.id}
                            data={tariffItem}
                            isDeleting={
                                isExistingService ? !(tariffs.number === 0 && tariffs.content.length === 1) : true
                            }
                            isBasic={tariffItem.isBasic}
                            isCopy={!isExistingService}
                            onDelete={onDeleteTariff}
                            onClick={onEditTariff}
                            onCopy={onCopyTariff}
                            isRenderValueWithPronoun={isRenderValueWithPronoun}
                            isVisiblePlaceTimetable={isVisiblePlaceTimetable}
                        />
                    );
                })}
            </CardsGrid>
            <div className="content-wrapper mb-0">
                <div className="mt-32">
                    <Paginate
                        page={tariffs.number}
                        totalPages={tariffs.totalPages}
                        onPageChange={(page) => {
                            loadTariffList(billingProductId, page);
                        }}
                    />
                </div>
            </div>
            <TariffModal
                isOpen={isOpenWindow}
                onClose={changeIsOpen}
                tariffData={tariffData}
                currentBasicTariffName={currentBasicTariff?.tariffCaption[0].content || null}
                onSave={onSaveTariff}
                onUpdateTariff={onUpdateTariff}
                isExistingService={isExistingService}
                isRenderValueWithPronoun={isRenderValueWithPronoun}
                isVisiblePlaceTimetable={isVisiblePlaceTimetable}
                facilityWorkingTime={facilityWorkingTime || []}
                {...restOptions}
            />
        </>
    );
};

export default Tariffs;
