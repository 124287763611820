import React from "react";
import ModeratorNotes from "../../components/moderator-notes/moderator-notes";

type Props = {
    textNotes: string;
};

const TourComment = ({ textNotes }: Props) => {
    return (
        <div className="content-wrapper">
            <ModeratorNotes textNotes={textNotes} />
        </div>
    );
};

export default TourComment;
