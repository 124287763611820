export const HOTEL_DISCOUNTS_STEP_DESC_EMPTY = "Нет акций";

export enum DiscountType {
    DISCOUNT = "DISCOUNT",
    BONUS = "BONUS",
}

export enum DiscountStatus {
    PUBLISHED = "PUBLISHED",
    DRAFT = "DRAFT",
    TO_PUBLISH = "TO_PUBLISH",
    REFUSED = "REFUSED",
    ARCHIVE = "ARCHIVE",
}

export enum DiscountBonusType {
    BONUS_BREAKFAST = "BONUS_BREAKFAST",
    BONUS_UPGRADE = "BONUS_UPGRADE",
}

export type HotelDiscountsData = {
    id: string;
    cmsHotelId: string;
    title: string;
    offerStatusCode: DiscountStatus;
    roomId: string;
    specialOfferType: DiscountType;
    bonusCategory?: DiscountBonusType;
    discountSize?: number;
    roomsCategoryUpgrade?: string[];
    bookingDateFrom?: string;
    bookingDateTo?: string;
    stayDateFrom?: string;
    stayDateTo?: string;
    createdAt?: string;
    updatedAt?: string;
    moderationComment?: string;
    tariffs?: string[];
};

export type HotelDiscountsForm = {
    title: string;
    cmsHotelId?: string;
    hotelId?: string;
    roomId: string;
    tariffs?: string[];
    specialOfferType: DiscountType;
    discountSize?: number;
    roomsCategoryUpgrade?: string[];
    bonusCategory?: DiscountBonusType;
    id?: string;
    date?: { from?: string; to?: string };
    dateView?: string;
    bookingDateFrom?: string | null;
    bookingDateTo?: string | null;
    stayDateFrom?: string | null;
    stayDateTo?: string | null;
};

export type HotelDiscountsCreateResponse = {
    errorCode: number;
    errorCause?: string;
    data: {
        id: string;
    };
};
