import { Location } from "history";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";

import { ModalAlertDetailsProps, useModalAlertContext } from "@russpass/partner-front-ui";

interface Props {
    when?: boolean;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
    details: ModalAlertDetailsProps;
    onOKButtonClickAction: () => Promise<void>;
    onCancelButtonClickAction?: () => void;
    isNavigateOnclickCancel?: boolean;
}

const RouteLeavingGuard = ({
    when,
    navigate,
    details,
    onOKButtonClickAction,
    onCancelButtonClickAction,
    isNavigateOnclickCancel,
    shouldBlockNavigation,
}: Props) => {
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const { openModalAlert } = useModalAlertContext();

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setLastLocation(nextLocation);
            openModalAlert(details, handleConfirmNavigationClick, handleCancelNavigationClick);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = async () => {
        onOKButtonClickAction()
            .then(() => {
                setConfirmedNavigation(true);
            })
            .catch(() => {});
    };

    const handleCancelNavigationClick = () => {
        onCancelButtonClickAction?.();
        if (isNavigateOnclickCancel) {
            setConfirmedNavigation(true);
        }
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
        </>
    );
};
export default RouteLeavingGuard;
