import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { FILE_FORMAT_ERROR, FILE_TOO_LARGE } from "../../../../../../constants/errors";
import { MAX_PHOTO_SIZE_BIT, MAX_PHOTO_SIZE_Mb } from "../../../../../../constants/images";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { BaseFieldProps } from "../../../../../documents/offer/company-info/components/fields/types";
import UploadFilesRenderer from "../../../../../../components/form-controls/upload-files/renderers/UploadFilesRenderer";
import {
    AvatarPreview,
    FileRejection,
    FileWithPath,
    FORMAT_DROPZONE_FOR_IMAGE,
    StatusRequestEnum,
    UploadFiles,
    UploadPhotosInput,
} from "@russpass/partner-front-ui";
import { getImageById } from "../../../../../../api/files";

type Value = { id?: string; file?: File };
type AvatarFieldProps = BaseFieldProps & {
    tooltipText: string;
    hideInput?: boolean;
};

const AvatarField = ({ name, disabled, tooltipText, hideInput }: AvatarFieldProps) => {
    const { setFieldValue, getFieldProps, errors, submitCount } = useFormikContext();

    const onDrop = useCallback(
        (acceptedFiles: FileWithPath[], rejectedFiles?: FileRejection[]) => {
            if (acceptedFiles.length && acceptedFiles[0].size > MAX_PHOTO_SIZE_BIT) {
                return eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: FILE_TOO_LARGE(MAX_PHOTO_SIZE_Mb),
                });
            }

            if (rejectedFiles?.length) {
                return eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: FILE_FORMAT_ERROR,
                });
            }

            setFieldValue(name, { file: acceptedFiles[0] });
        },
        [name, setFieldValue]
    );

    const value = (getFieldProps(name).value as Value) || undefined;

    const error = (errors as Record<string, any>)[name] || "";

    return (
        <div className="form-item">
            <UploadFiles
                preview={
                    <UploadFilesRenderer
                        preview={<AvatarPreview />}
                        styles={{ gap: "24px", flexDirection: "column-reverse" }}
                    />
                }
                files={value ? [value] : []}
                setFiles={(value) => setFieldValue(name, value[0])}
                error={submitCount ? error : undefined}
                onDrop={onDrop}
                accept={{ [FORMAT_DROPZONE_FOR_IMAGE]: [".jpg", ".jpeg", ".png"] }}
                multiple={false}
                disabled={disabled}
                input={hideInput ? undefined : <UploadPhotosInput tooltipText={tooltipText} />}
                getImageById={getImageById}
                style={{ flexDirection: "column-reverse", gap: "24px" }}
                maxFileSize={MAX_PHOTO_SIZE_Mb}
            />
        </div>
    );
};

export default AvatarField;
