import { emailRegexp } from "../../../../../utils/validation";
import { EMAIL_FORMAT_ERROR, EMAIL_REQUIRED_ERROR, FORMAT_ERROR } from "../../../../../constants/errors";
import { yup } from "../../../../../yup";

export const getNotificationsValidation = (isNew: boolean) =>
    yup.object().shape({
        templates: yup.array().of(
            yup.object().shape({
                active: yup.boolean(),
                emailEnabled: yup.boolean(),
                email: yup
                    .string()
                    .matches(emailRegexp, {
                        message: EMAIL_FORMAT_ERROR,
                    })
                    .when("emailEnabled", {
                        is: Boolean,
                        then: (schema) => schema.required(EMAIL_REQUIRED_ERROR),
                    }),
                reserveEmail: yup.string().matches(emailRegexp, {
                    message: EMAIL_FORMAT_ERROR,
                }),
                threshold: yup
                    .number()
                    .nullable()
                    .when("active", {
                        is: Boolean,
                        then: yup
                            .number()
                            .typeError(FORMAT_ERROR)
                            .required()
                            .min(Number(process.env.REACT_APP_NOTIFICATIONS_THRESHOLD) || 100)
                            .max(2147483647),
                    }),
                hotels: isNew
                    ? yup.array().nullable().when("active", {
                          is: Boolean,
                          then: yup.array().required(),
                      })
                    : yup.array().nullable(),
            })
        ),
    });
