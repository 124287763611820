import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { DateUtils } from "react-day-picker";
import { DatePicker, DatePickerInputProps } from "@russpass/partner-front-ui";
import moment from "moment";
import { DateControlValue, Matcher, Range } from "../types";
import { ISO_DATE_FORMAT } from "../../../../constants/dates-format";

type Props = {
    disabledOptions?: Matcher | Matcher[];
    value: DateControlValue;
    onSelect: (values: DateControlValue) => void;
    isExistingTariff?: boolean;
} & Pick<DatePickerInputProps, "fromMonth">;

const DaysSelect = forwardRef<any, Props>(({ value, disabledOptions, onSelect, isExistingTariff, fromMonth }, ref) => {
    const [selectedDays, setSelectedDays] = useState<DateControlValue>(value);

    const daysForDatePicker: Date[] = useMemo(() => selectedDays.map((day) => new Date(day as string)), [selectedDays]);

    useImperativeHandle(
        ref,
        () => ({
            clearSelectedValue: () => {
                setSelectedDays(value);
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleDayClick = useCallback(
        (day: Date, modifiers) => {
            const selected = modifiers.selected;
            let localSelectedDays: DateControlValue = selectedDays.concat();
            if (selected) {
                localSelectedDays = localSelectedDays.filter((selectedDay: string | Range) => {
                    return !DateUtils.isSameDay(new Date(selectedDay as string), day);
                });
            } else {
                if (isExistingTariff) {
                    localSelectedDays = [moment(day).format(ISO_DATE_FORMAT)];
                } else {
                    localSelectedDays.push(moment(day).format(ISO_DATE_FORMAT));
                }
            }
            setSelectedDays(localSelectedDays);
            onSelect(localSelectedDays);
        },
        [selectedDays, onSelect, isExistingTariff]
    );

    const disabledOptionsLocal = useMemo(() => {
        const options =
            //@ts-ignore
            disabledOptions?.filter((item) => {
                return value.indexOf(moment(item).format(ISO_DATE_FORMAT)) < 0;
            }) || [];
        options.push({
            before: new Date(),
        });
        return options;
    }, [disabledOptions, value]);

    return (
        <DatePicker
            selectedDays={daysForDatePicker}
            disabledDays={disabledOptionsLocal}
            handleChange={handleDayClick}
            fromMonth={fromMonth}
        />
    );
});

export default DaysSelect;
