import { russpassApiServices } from "../constants/api";
import fetchRequest from "./manager";
import { ErrorResponse } from "../types/api";

const ssoPath = russpassApiServices.sso;

export const generateOtp = (data: {
    // номер формата 79999999999
    mobile?: string;
    email?: string;
}): Promise<ErrorResponse & { repeatAfter?: string }> => fetchRequest.post(`${ssoPath}/generate/otp`, data);
