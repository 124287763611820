export const posthogApiKey = process.env.REACT_APP_POSTHOG_METRIKA_ID;

export const posthogApiUrl = process.env.REACT_APP_POSTHOG_METRIKA_API_URL;

export const AnalyticEvent = {
    open_conditions: "open_conditions",
    proceed_click: "proceed_click",
    upload_offer: "upload_offer",
    load_inn: "load_inn",
    load_ogrn: "load_ogrn",
    sign_the_offer: "sign_the_offer",
    sign_success: "sign_success",
    sign_fail: "sign_fail",
    repeat_sign: "repeat_sign",
    close_click: "close_click",
    add_object_click: "add_object_click",
    add_object_button: "add_object_button",
    add_object_error_page: "add_object_error_page",
    repeat_add_object: "repeat_add_object",
    add_object_error_page_close: "add_object_error_page_close",
    object_click: "object_click",
    object_info: "object_info",
    object_info_save_button: "object_info_save_button",
    without_save_button_click: "without_save_button_click",
    services_info_save_button: "services_info_save_button",
    tariff_click: "tariff_click",
    tariff_save_button: "tariff_save_button",
    add_another_tariff: "add_another_tariff",
    rooms_click: "rooms_click",
    rooms_info_save_button: "rooms_info_save_button",
    rooms_info_save_success: "rooms_info_save_success",
    rooms_info_save_error_page: "rooms_info_save_error_page",
    add_another_room: "add_another_room",
    repeat_rooms_info: "repeat_rooms_info",
    close_rooms_info_saving: "close_rooms_info_saving",
    submitting_for_moderation: "submitting_for_moderation",
    download_instruction_click: "download_instruction_click",
    close_submitting_for_moderation_page: "close_submitting_for_moderation_page",
    repeat_submitting_for_moderation: "repeat_submitting_for_moderation",
    submitting_for_moderation_success_page: "submitting_for_moderation_success_page",
    submitting_for_moderation_error_page: "submitting_for_moderation_error_page",
    moderation_failure_page: "moderation_failure_page",
    room_moderation_failure_page: "room_moderation_failure_page",
    marksPageView: "lkp_rating_marks_pageview",
    statPageView: "lkp_rating_marks_pageview",
    partners_landing_pageview: "partners_landing_pageview",
    partners_landing_become_partner: "partners_landing_become_partner",
    partners_landing_inn_check_result: "partners_landing_inn_check_result",
    partners_landing_lkp_entering: "partners_landing_lkp_entering",
    partners_landing_lkp_send_application: "partners_landing_lkp_send_application",
    partners_landing_lkp_send_application_pageview: "partners_landing_lkp_send_application_pageview",
    enter_lpk_click: "enter_lpk_click",
    forgot_password_click: "forgot_password_click",
    write_to_us_click: "write_to_us_click",
    load_object_card: "load_object_card",
    save_and_out_button_click: "save_and_out_button_click",
    tariff_remove_button: "tariff_remove_button",
    room_remove_button: "room_remove_button",
    confirmation_bookings_page: "confirmation_bookings_page",
    active_bookings_page: "active_bookings_page",
    complited_bookings_page: "complited_bookings_page",
    rejected_bookings_page: "rejected_bookings_page",
    host_renthouse_click: "host_renthouse_click",
    request_page: "request_page",
    calendar_page: "calendar_page",
    confirm_click: "confirm_click",
    reject_click: "reject_click",
    chat_with_guest: "chat_with_guest",
    confirm_page: "confirm_page",
    reject_page: "reject_page",
    confirm_request_click: "confirm_request_click",
    reject_request_click: "reject_request_click",
    host_cancel_reservation: "host_cancel_reservation",
    confirm_host_cancel_reservation: "confirm_host_cancel_reservation",
    insurance_page: "insurance_page",
    insurance_case_click: "insurance_case_click",
    rate_guest_click: "rate_guest_click",
    confirm_rate: "confirm_rate",
    support_click: "support_click",
};
