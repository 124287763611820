import { useCallback, useEffect, useMemo, useState } from "react";
import { NavbarApartmentsName } from "./constants";
import { useRentHousesObjects } from "../../../api/hooks/rentHouses";
import { RentHousesObjectStatus } from "../../../types/rentHouses";
import moment from "moment";
import { localStorageItems } from "../../../constants/cookies";

export const useApartmentsNavbar = (activeTabKey: string | number | undefined) => {
    const [isAlertDeclined, setIsAlertDeclined] = useState(false);

    const { data: objectsData } = useRentHousesObjects({
        pageSize: 100,
    });
    const checkAlertDeclined = useCallback(() => {
        if (!objectsData) return;

        const valueLastView = localStorage.getItem(localStorageItems.apartmentsRejectedLastViewDate) as string | null;

        const dateLastView = moment(valueLastView);

        const items =
            objectsData?.data.items.filter((item) => item.status === RentHousesObjectStatus.DECLINED || item.blocked) ||
            [];

        let isShowAlert = false;

        for (const item of items) {
            if (
                (item.status === RentHousesObjectStatus.DECLINED &&
                    (!valueLastView || moment(item.updatedAt).isAfter(dateLastView))) ||
                (item.blocked && (!valueLastView || moment(item.blockedFrom).isAfter(dateLastView)))
            ) {
                isShowAlert = true;
                break;
            }
        }
        setIsAlertDeclined(isShowAlert);
    }, [objectsData]);

    useEffect(() => {
        checkAlertDeclined();
    }, [checkAlertDeclined, objectsData]);

    useEffect(() => {
        if (activeTabKey === NavbarApartmentsName.DECLINED) {
            localStorage.setItem(localStorageItems.apartmentsRejectedLastViewDate, `${moment().toISOString()}`);
            setIsAlertDeclined(false);
        }
    }, [activeTabKey]);

    const navbarList = useMemo(
        () => [
            {
                label: "Все",
                name: NavbarApartmentsName.ALL,
            },
            {
                label: "Черновик",
                name: NavbarApartmentsName.DRAFT,
            },
            {
                label: "На модерации",
                name: NavbarApartmentsName.MODERATION,
            },
            {
                label: "Опубликовано",
                name: NavbarApartmentsName.PUBLISHED,
            },
            {
                label: "Отказано в публикации",
                name: NavbarApartmentsName.DECLINED,
                alert: isAlertDeclined,
            },
            {
                label: "Архив",
                name: NavbarApartmentsName.ARCHIVED,
            },
        ],
        [isAlertDeclined]
    );
    return { navbarList };
};
