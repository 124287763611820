import KeycloakManager from "./keycloak-manager";
import store from "../store";
import { setUserFullInfo } from "../store/userInfo";

const signOut = (redirectUrl?: string) => {
    KeycloakManager.logout(redirectUrl).then(() => {
        store.dispatch(setUserFullInfo(null));
    });
};

export default signOut;
