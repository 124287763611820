export enum Sorting {
    CreateDateAsc = "createDate",
    CreateDateDesc = "-createDate",
    OrderAsc = "order",
    OrderDesc = "-order",
    StatusAsc = "status",
    StatusDesc = "-status",
    CreatedAtAsc = "createdAt",
    CreatedAtDesc = "-createdAt",
    UpdatedAtAsc = "updatedAt",
    UpdatedAtDesc = "-updatedAt",
    CheckinDateAcs = "checkinDate",
    CheckinDateDesc = "-checkinDate",
    ExpireAtAcs = "expireAt",
    ExpireAtDesc = "-expireAt",
    CheckInAtAsc = "checkinAt",
    CheckInAtDesc = "-checkinAt",
    AmountAsc = "amount",
    AmountDesc = "-amount",
    TitleAsc = "title",
    TitleDesc = "-title",
}
