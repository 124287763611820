import { isAvailableTours } from "../../constants/feature-toggles";

export const navbarOrdersNames = {
    new: "NEW",
    confirmed: "CONFIRMED",
    rejected: "REJECTED",
};

export const navbarOrders = [
    {
        label: "Новые",
        name: navbarOrdersNames.new,
    },
    {
        label: "Подтвержденные",
        name: navbarOrdersNames.confirmed,
    },
    {
        label: "Отклоненные",
        name: navbarOrdersNames.rejected,
    },
];

export const orderStatuses = {
    invoice: "INVOICE",
    finished: "FINISHED",
    notInvoice: "NOT_INVOICE",
    suspendedAgeing: "SUSPENDED_AGEING",
    error: "ERROR",
    canceled: "CANCELED",
    reject: "REJECT",
    readyToRefund: "READY_TO_REFUND",
    refund: "REFUND",
    hold: "HOLD",
};

export const orderTypes = {
    tour: "TOUR",
    excursion: "EXCURSION",
    event: "EVENT",
};

export const orderTypesNames = {
    tour: "Тур",
    excursion: "Экскурсия",
    event: "Событие",
};

export const paymentTypes = {
    singleStage: "SINGLE_STAGE",
    twoStage: "TWO_STAGE",
};

export const filterKindOptions = [
    {
        label: "Все услуги",
        value: "",
    },
    {
        label: "Только события",
        value: orderTypes.event,
    },
    {
        label: "Только экскурсии",
        value: orderTypes.excursion,
    },
    ...(isAvailableTours
        ? [
              {
                  label: "Только туры",
                  value: orderTypes.tour,
              },
          ]
        : []),
];

export type DateFilters = {
    startCreateDate?: string;
    endCreateDate?: string;
    startActiveSince?: string;
    endActiveSince?: string;
};

export type OrderFilters = {
    status: string;
    type?: string;
    id?: string;
} & DateFilters;

export enum DatesFilterType {
    CREATE = "create",
    ACTIVE = "active",
}

export enum DatesFilterTitleSuffix {
    create = "Заявки",
    active = "Провед.",
}

export const INITIAL_DATE_FILTERS: DateFilters = {
    startActiveSince: undefined,
    endActiveSince: undefined,
    startCreateDate: undefined,
    endCreateDate: undefined,
};

export const INITIAL_FILTERS: OrderFilters = {
    status: orderStatuses.invoice,
    type: undefined,
    id: undefined,
    ...INITIAL_DATE_FILTERS,
};

export type OrdersSorter = {
    label: string;
    value: string;
    field?: string;
    order?: string;
};

export const sortOrdersOptions: OrdersSorter[] = [
    {
        label: "По сумме от макс.",
        value: "total-desc",
        field: "total",
        order: "desc",
    },
    {
        label: "По сумме от мин.",
        value: "total-asc",
        field: "total",
        order: "asc",
    },
    {
        label: "Проведение скоро",
        value: "activeSince-asc",
        field: "activeSince",
        order: "asc",
    },
    {
        label: "Проведение нескоро",
        value: "activeSince-desc",
        field: "activeSince",
        order: "desc",
    },
];

export const statusConfirmModalWindow = {
    confirm: "confirm",
    reject: "reject",
};

export const modalAlertDetails = {
    confirm: {
        title: "Вы уверены, что хотите подтвердить заявку?",
        OKButton: "Подтвердить",
        cancelButton: "Отмена",
    },
    reject: {
        title: "Вы уверены, что хотите отклонить заявку?",
        OKButton: "Отклонить",
        cancelButton: "Отмена",
    },
};

export const PAGINATE_SIZE = 5;
