import { ApartmentsContactFormTopic, ContactFormTopic_v3 } from "../../../../../types/support";
import { APARTMENTS_CONTACT_FORM_TOPIC, CONTACT_FORM_TOPIC_v3 } from "../../../../../constants/support";

export const contactFormTopicType_v3 = (entry: ContactFormTopic_v3 | undefined) =>
    ({
        [CONTACT_FORM_TOPIC_v3.partnership]: "Партнерство",
        [CONTACT_FORM_TOPIC_v3.other]: "Другое",
        [CONTACT_FORM_TOPIC_v3.technicalError]: "Техническая ошибка",
    }[entry as ContactFormTopic_v3] || "");

export const apartmentsContactFormTopicType = (entry: ContactFormTopic_v3 | undefined): string =>
    ({
        [APARTMENTS_CONTACT_FORM_TOPIC.rentHousesBookingProblem]: "Проблема с бронированием",
    }[entry as ApartmentsContactFormTopic] || contactFormTopicType_v3(entry));
