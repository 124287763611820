import { useCallback, useEffect, useMemo, useState } from "react";
import { navbarHotelComment, navbarHotelEditForm, navbarHotelEditFormNames } from "./constants";
import { getHotelValidation } from "./validation-schema";
import { getErrorForm } from "./utils";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { HotelData } from "./types";
import { cloneDeep } from "lodash";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

type Props = {
    hotelData: HotelData;
    onSubmit: () => void;
};

const useHotelEditForm = ({ hotelData, onSubmit }: Props) => {
    const [activeTabName, setActiveTabName] = useState<string | number | undefined>("");

    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const changeTab = useCallback((path: string | undefined | number) => {
        setActiveTabName(path);
    }, []);

    const [navbar, setNavbar] = useState(cloneDeep(navbarHotelEditForm));
    const isValidInfoHotel = useMemo(() => getHotelValidation.isValidSync(hotelData), [hotelData]);
    const isValidPhotoHotel = useMemo(
        () => !!(hotelData.imageExplorePreview && hotelData.imageExplorePreview.length > 0),
        [hotelData]
    );

    const handlerSubmit = useCallback(() => {
        setIsSubmittingForm(true);
        if (!isValidInfoHotel || !isValidPhotoHotel) {
            const error = getErrorForm({ isValidInfoHotel, isValidPhotoHotel });
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: error || "",
            });
        }
        if (isValidInfoHotel && isValidPhotoHotel) {
            onSubmit();
        }
    }, [onSubmit, isValidInfoHotel, isValidPhotoHotel]);

    const localNavbar = useMemo(() => {
        let _navbar = navbar;
        if (hotelData.isRejected) {
            return [...navbarHotelComment, ..._navbar];
        }
        return _navbar;
    }, [navbar, hotelData]);

    useEffect(() => {
        if (isSubmittingForm) {
            setNavbar((prev) => {
                return prev.map((item: any) => {
                    if (item.name === navbarHotelEditFormNames.hotel) item.isError = !isValidInfoHotel;
                    if (item.name === navbarHotelEditFormNames.photos) item.isError = !isValidPhotoHotel;
                    return item;
                });
            });
        }
    }, [hotelData, isSubmittingForm, isValidInfoHotel, isValidPhotoHotel]);

    return {
        activeTabName,
        changeTab,
        isSubmittingForm,
        navbar: localNavbar,
        handlerSubmit,
    };
};

export default useHotelEditForm;
