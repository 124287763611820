import { intersection } from "lodash";
import { FORM_FIELD_REQUIRED_ERROR } from "../../../../../constants/errors";
import { yup } from "../../../../../yup";
import moment from "moment";
import { PlaceTimetableButtonKeys } from "../../../../../components/timetable/place-timetable/constants";

const requiredDates = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

const arePeriodsIntersecting = (startA: string, endA: string, startB: string, endB: string) => {
    const momentStartA = moment(startA);
    const momentEndA = moment(endA);
    const momentStartB = moment(startB);
    const momentEndB = moment(endB);

    return momentStartA.isSameOrBefore(momentEndB) && momentEndA.isSameOrAfter(momentStartB);
};

const isDateInRange = (date: string, startDate: string, endDate: string) => {
    const momentDate = moment(date);
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    return momentDate.isSameOrAfter(momentStartDate) && momentDate.isSameOrBefore(momentEndDate);
};

export const tariffsStepSchema = yup.object().shape({
    rates: yup
        .array()
        .of(
            yup.object().shape({
                dates: yup.array().test("errorIfNoPrice", FORM_FIELD_REQUIRED_ERROR, (value, context) => {
                    if (
                        // @ts-ignore
                        context.from?.[context.from.length - 1]?.value?.tariff?.rates?.length === 1 ||
                        context.parent.price
                    ) {
                        return !!value?.length;
                    }
                    return true;
                }),
                price: yup.number().test("errorIfNoDates", FORM_FIELD_REQUIRED_ERROR, (value, context) => {
                    if (
                        // @ts-ignore
                        context.from?.[context.from.length - 1]?.value?.tariff?.rates?.length === 1 ||
                        context.parent.dates?.length
                    ) {
                        return !!value;
                    }
                    return true;
                }),
            })
        )
        .min(1)
        .test("is-all", "Создайте тарифы на все дни недели", (value) => {
            let dates: string[] = [];
            value?.forEach((item) => {
                if (item.scheduleType === PlaceTimetableButtonKeys.WeekDays) {
                    const value = (item.dates as string[]) || [];
                    dates = [...dates, ...value];
                }
            });

            const copyDates = JSON.parse(JSON.stringify(dates));

            for (const value of requiredDates) {
                const index = copyDates.indexOf(value);
                if (index === -1) {
                    return false;
                }
                copyDates.splice(index, 1);
            }

            return true;
        })
        .test("intersectionWeek", "Дни не должны пересекаться", (values = []) => {
            const onlyWeeks = values
                .filter((item) => item.scheduleType === "weekDays")
                .map((item) => (item.dates as unknown as string[]) || []);

            const mergedArrays = onlyWeeks.flatMap((arr) => arr);
            const uniqueSet = new Set(mergedArrays);
            return mergedArrays.length === uniqueSet.size;
        })
        .test("intersection", "Дни не должны пересекаться", (values = []) => {
            const onlyDates = values.filter((item) => item.scheduleType !== "weekDays");

            for (let i = 0; i < onlyDates.length; i++) {
                const item = onlyDates[i];
                if (onlyDates.length < 2) break;
                const datesWithoutItem = JSON.parse(JSON.stringify(onlyDates));
                datesWithoutItem.splice(i, 1);

                for (const withoutValue of datesWithoutItem) {
                    if (
                        withoutValue.scheduleType === "rangeDays" &&
                        withoutValue.dates?.[0]?.from &&
                        withoutValue.dates?.[0]?.to
                    ) {
                        if (item.scheduleType === "rangeDays" && item.dates?.[0]?.from && item.dates?.[0]?.from) {
                            const intersecting = arePeriodsIntersecting(
                                withoutValue.dates[0].from,
                                withoutValue.dates[0].to,
                                item.dates?.[0].from,
                                item.dates?.[0].from
                            );
                            if (intersecting) {
                                return !intersecting;
                            }
                        }
                        if (item.scheduleType === "days" && item.dates?.[0]) {
                            const dateInRange = isDateInRange(
                                item.dates[0],
                                withoutValue.dates[0].from,
                                withoutValue.dates[0].to
                            );
                            if (dateInRange) {
                                return !dateInRange;
                            }
                        }
                    }

                    if (withoutValue.scheduleType === "days" && withoutValue.dates?.[0]) {
                        if (item.scheduleType === "rangeDays" && item.dates?.[0]?.from && item.dates?.[0]?.to) {
                            for (const date of withoutValue.dates) {
                                const dateInRange = isDateInRange(date, item.dates?.[0].from, item.dates?.[0].to);
                                if (dateInRange) {
                                    return !dateInRange;
                                }
                            }
                        }

                        if (item.scheduleType === "days" && item.dates?.[0]) {
                            return !intersection(withoutValue.dates, item.dates).length;
                        }
                    }
                }
            }
            return true;
        }),
});

type DraftRatesArrayType = {
    dates?: string[] | { from: string; to: string }[];
    price?: string;
    scheduleType?: string;
}[];

export const tariffsStepDraftSchema = yup.object().shape({
    rates: yup.array().when({
        is: (value: DraftRatesArrayType) => {
            if (!value) return false;

            const lastItem = value[value.length - 1];
            return Boolean(lastItem?.dates || lastItem?.price);
        },
        then: (schema) =>
            schema.of(
                yup.object().shape({
                    dates: yup.array().required(),
                    price: yup.number().required(),
                })
            ),
        otherwise: (schema) => schema,
    }),
});
