import useRestaurantControlData from "./useRestaurantControlData";
import RestaurantInfo from "./restaurant-info";
import StatusCardCreateRestaurant from "./components/status-card-create-rest/index";
import { CommonLayout, RejectionComment } from "@russpass/partner-front-ui";
import { successTitleText } from "../../../constants/layout";
import { useCallback, useMemo } from "react";
import { noop } from "lodash";
import { navbarRestaurant, NavbarRestaurantNewName } from "./constants";
import { Ratings } from "../../services/components/ratings/ratings";
import { offerTypes } from "../../documents/offer/company-info/constants";

const Restaurant = () => {
    const {
        status,
        isExistingRestaurant,
        isLoading,
        restaurantInfoData,
        restaurantSupportInfo,
        isSubmittingForm,
        isChanged,
        dictionaryOptions,
        goBack,
        onChange,
        closeStatusCard,
        onSaveRestaurant,
        disabledRestaurantForm,
        setEnableEditRestaurant,
        handleStepChange,
    } = useRestaurantControlData();

    const { url, importDeniedReason } = restaurantSupportInfo;

    const isPublished = useMemo(() => !!url, [url]);

    const handleClickSuccess = useCallback(() => url && window.open(url), [url]);

    const buttonSuccessTitle = useMemo(() => {
        if (!isLoading && isExistingRestaurant) {
            return isPublished ? (
                <>
                    {successTitleText.externalLink.text} {successTitleText.externalLink.icon}
                </>
            ) : undefined;
        }
        return successTitleText.sendToModerate.text;
    }, [isExistingRestaurant, isLoading, isPublished]);

    const onClickSuccess = useMemo(() => {
        if (!isLoading && isExistingRestaurant) {
            return isPublished ? handleClickSuccess : noop;
        }
        return onSaveRestaurant;
    }, [onSaveRestaurant, handleClickSuccess, isExistingRestaurant, isLoading, isPublished]);

    const topRightButton = useMemo(
        () => ({
            title: buttonSuccessTitle,
            onClick: onClickSuccess,
            iconMobile: isPublished && successTitleText.externalLink.icon,
        }),
        [buttonSuccessTitle, isPublished, onClickSuccess]
    );

    const backButton = useMemo(
        () => ({
            title: "Выйти",
            onClick: goBack,
        }),
        [goBack]
    );

    return (
        <CommonLayout
            pageTitle={restaurantInfoData.name || "Новый объект"}
            backButton={backButton}
            topRightButton={topRightButton}
            isLoadingWithoutContent={isLoading}
            isHideControlPanel
            navbar={navbarRestaurant}
            handlerChangeActiveTab={handleStepChange}
        >
            <div data-name={NavbarRestaurantNewName.OBJECT}>
                <>{importDeniedReason && <RejectionComment reasons={importDeniedReason} />}</>
                <RestaurantInfo
                    data={restaurantInfoData}
                    disabled={disabledRestaurantForm}
                    isShowErrors={isSubmittingForm}
                    onChange={onChange}
                    onSaveRestaurant={onSaveRestaurant}
                    isChanged={isChanged}
                    dictionaryOptions={dictionaryOptions}
                    setEnableEditRestaurant={setEnableEditRestaurant}
                />
                <StatusCardCreateRestaurant
                    status={status}
                    name={restaurantInfoData.name}
                    closeStatusCard={closeStatusCard}
                    onSaveRestaurant={onSaveRestaurant}
                />
            </div>
            <div data-name={NavbarRestaurantNewName.RATING_MARKS}>
                <Ratings id={restaurantInfoData.cmsId} offerType={offerTypes.restaurant} />
            </div>
        </CommonLayout>
    );
};

export default Restaurant;
