import { IntegrationHotelRoom } from "../../../../types/hotel-room";
import { HotelData, HotelDataForm } from "../../../../types/hotel-info";
import { HotelTariffData } from "../../../../types/hotel-tariff";
import { createContext, Dispatch, SetStateAction } from "react";
import { HotelDiscountsData } from "../../../../types/hotel-discounts";
import { activeTabKeyType } from "@russpass/partner-front-ui";

export type HotelDiscountStore = {
    isLoadingDiscounts: boolean;
    hotelRoomsData: IntegrationHotelRoom[];
    hotelData: HotelDataForm | HotelData;
    tariffs: HotelTariffData[];
    loadDiscounts: () => void;
    activeTabKey: activeTabKeyType;
    setActiveTabKey: Dispatch<SetStateAction<activeTabKeyType>>;
    discountsData: HotelDiscountsData[];
    loadHotelData: () => Promise<void>;
    isNew: boolean;
};

export const HotelDiscountStoreContext = createContext<HotelDiscountStore | undefined>(undefined);
