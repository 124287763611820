import React from "react";
import SupportLink from "./support-link";
import { UserFullInfo } from "../../../types/user";
import AccessPersonalAccount from "./AccessPersonalAccount";
import { TextField } from "@russpass/partner-front-ui";

type Props = {
    data: UserFullInfo | null;
};

const Company = ({ data }: Props) => {
    return (
        <div className="content-wrapper">
            <div className="content-wrapper__form">
                <AccessPersonalAccount />
                <TextField value={data?.fullName} name="fullName" label="Полное название" />
                <TextField value={data?.shortName} name="shortName" label="Сокращенное название" />
                <TextField value={data?.brandName} name="brandName" label="Торговая марка" />
                <TextField
                    value={data?.productDescription}
                    name="productDescription"
                    label="Краткая информация о продуктах"
                />
                <TextField value={data?.webSite} name="webSite" label="Сайт компании" />
                <TextField value={data?.inn} name="inn" label="ИНН" />
                <TextField value={data?.ogrn} name="ogrn" label="ОГРН" />
                <TextField value={data?.kpp} name="kpp" label="КПП" />
                <TextField value={data?.address} name="address" label="Юридический адрес" />
                <SupportLink />
            </div>
        </div>
    );
};

export default Company;
