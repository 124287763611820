import { FC } from "react";
import classes from "./moderator-notes.module.sass";

interface IModeratorNotesProps {
    textNotes: string;
}

const ModeratorNotes: FC<IModeratorNotesProps> = ({ textNotes }) => {
    return (
        <div className={classes.moderatorNotes}>
            <p className={classes.moderatorNotesText}>{textNotes}</p>
        </div>
    );
};

export default ModeratorNotes;
