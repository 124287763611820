import { useCallback, useMemo, useState } from "react";
import { HotelRoomData } from "../types/hotel-room";
import { declensionOfNum } from "../../../../../utils/declension-number";
import { getStepStyles } from "../utils/common";
import useLoadData from "./useLoadData";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../../../constants/errors";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { useHotelDataContext } from "../../hotel-view/hotel-data-context";

const useControlHotelRoomsData = () => {
    const { hotelInfoData, isTravelLineHotel } = useHotelDataContext();
    const [isLoadingRooms, setIsLoadingRooms] = useState<boolean>(false);
    const [hotelRoomsData, setHotelRoomsData] = useState<HotelRoomData[]>([]);
    const { onLoadRooms } = useLoadData();

    const isValidRooms = useMemo(() => {
        if (isTravelLineHotel) {
            return hotelRoomsData.length > 0 && hotelInfoData?.rooms.length === 0;
        }
        return hotelRoomsData.length > 0;
    }, [hotelRoomsData, isTravelLineHotel, hotelInfoData]);

    const hotelRoomsStepDesc = useMemo(() => {
        if (isTravelLineHotel) {
            if (hotelInfoData && hotelInfoData.rooms.length > 0) {
                return "Внесите информацию по всем номерам";
            }
        }
        if (isValidRooms) {
            return hotelRoomsData.length + " " + declensionOfNum(hotelRoomsData.length, ["номер", "номера", "номеров"]);
        }
        return "";
    }, [hotelRoomsData, isValidRooms, isTravelLineHotel, hotelInfoData]);

    const hotelRoomsStepStyles = useMemo(() => {
        if (isTravelLineHotel) {
            if (hotelInfoData && hotelInfoData.rooms.length > 0) {
                return getStepStyles(true);
            }
        }
        return getStepStyles(isValidRooms);
    }, [isValidRooms, isTravelLineHotel, hotelInfoData]);

    const onSaveHotelRoomsData = useCallback((values: HotelRoomData) => {
        setHotelRoomsData((prevState) => [...prevState, values]);
    }, []);

    const reloadRooms = useCallback(async () => {
        setIsLoadingRooms(true);
        try {
            const { rooms } = await onLoadRooms();
            setHotelRoomsData(rooms as HotelRoomData[]);
            return rooms;
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
        } finally {
            setIsLoadingRooms(false);
        }
    }, [onLoadRooms]);

    return {
        isValidRooms,
        isLoadingRooms,
        hotelRoomsData,
        hotelRoomsStepDesc,
        hotelRoomsStepStyles,
        onSaveHotelRoomsData,
        reloadRooms,
    };
};

export default useControlHotelRoomsData;
