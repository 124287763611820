import React, { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import {
    ModalWindowRight,
    useModalAlertContext,
    StatusRequestCard,
    StatusRequestEnum,
} from "@russpass/partner-front-ui";
import { DiscountStatus, HotelDiscountsForm } from "../../../../types/hotel-discounts";
import useHotelDiscountModal from "../../hooks/useHotelDiscountModal";
import { hotelDiscountSchema } from "./hotel-discount-form/hotel-discount-schema";
import HotelDiscountForm from "./hotel-discount-form/hotel-discount-form";
import { DiscountSubmitButton } from "../../../../constants/hotel-discounts";
import HotelNotification from "../../../hotel-notification/hotel-notification";
import { useHotelDiscountModalContext } from "../../context/discount-modal/useHotelDiscountModalContext";
import { useHotelDiscountStoreContext } from "../../context/discounts-store/useHotelDiscountStoreContext";
import { sendEvent } from "../../../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../../../product_analytics/constants";

const HotelDiscountModal = () => {
    const {
        hotelData: { title: hotelTitle },
    } = useHotelDiscountStoreContext();
    const { onClose, isViewDiscount, selectedDiscountData, isShowAlert, onCloseAlert } = useHotelDiscountModalContext();

    const {
        isDisabled,
        statusRequest,
        setStatusRequest,
        isDisabledSubmit,
        onSubmitForm,
        saveInArchive,
        beforeOnClose,
        initialValues,
    } = useHotelDiscountModal();

    const { openModalAlert } = useModalAlertContext();

    const {
        hotelData: { hotelId },
    } = useHotelDiscountStoreContext();

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validateOnMount
            validationSchema={hotelDiscountSchema}
            onSubmit={onSubmitForm}
        >
            {({
                values,
                setFieldValue,
                submitForm,
                resetForm,
                dirty,
                validateForm,
                setTouched,
                setFieldError,
            }: FormikProps<HotelDiscountsForm>) => {
                const closeAlert = () => {
                    beforeOnClose();
                    onClose(false, () => {
                        resetForm({ values: undefined });
                    });
                };

                const onSave = async () => {
                    const errors: any = await validateForm();
                    if (Object.keys(errors).length) {
                        setTouched(errors);
                        return;
                    }
                    await saveInArchive(
                        { ...values, hotelId },
                        (field, message) => {
                            setFieldError(field, message);
                            setTouched({ [field]: true }, false);
                        },
                        () => {
                            resetForm({ values: undefined });
                        }
                    );
                };

                const openAlert = () =>
                    openModalAlert(
                        {
                            title: "Сохранить черновик?",
                            description: "Акция будет храниться в Архиве",
                            OKButton: "Сохранить",
                            cancelButton: "Выйти без сохранения",
                        },
                        onSave,
                        closeAlert
                    );

                const closeFormModal = () => {
                    if (!isDisabled && dirty && selectedDiscountData?.offerStatusCode !== DiscountStatus.DRAFT) {
                        openAlert();
                        return;
                    }
                    closeAlert();
                };

                return (
                    <ModalWindowRight
                        isOpened={isViewDiscount}
                        onClose={closeFormModal}
                        position={1}
                        ComponentFooter={
                            !statusRequest ? (
                                <button
                                    disabled={isDisabledSubmit}
                                    className="button button--primary"
                                    onClick={submitForm}
                                >
                                    {selectedDiscountData
                                        ? DiscountSubmitButton[selectedDiscountData.offerStatusCode]
                                        : "Отправить на модерацию"}
                                </button>
                            ) : undefined
                        }
                        headerComponent={
                            !statusRequest ? (
                                <div className="content-wrapper">
                                    <div className="hotel-info__header">
                                        <div className="hotel-info__header--title">{values.title || "Новая акция"}</div>
                                        <div className="hotel-info__header--name">{hotelTitle}</div>
                                    </div>
                                </div>
                            ) : undefined
                        }
                    >
                        <div className="content-wrapper">
                            <div className="content-wrapper__form">
                                {statusRequest ? (
                                    <StatusRequestCard
                                        status={statusRequest}
                                        title={{
                                            success: "Номер сохранен",
                                            error: "Ошибка сохранения",
                                        }}
                                        subtitle={{
                                            success: "Добавьте все существующие номера",
                                            error: "Повторите попытку или попробуйте позже",
                                        }}
                                        buttonTitle={{
                                            addMore: "Добавить еще номер",
                                        }}
                                        handleAddMore={() => {
                                            resetForm({ values: undefined });
                                            setStatusRequest(null);
                                        }}
                                        handleClose={() => {
                                            resetForm({ values: undefined });
                                            beforeOnClose(statusRequest === StatusRequestEnum.Success);
                                        }}
                                        handleBeforeClose={handleSendEvent}
                                    />
                                ) : (
                                    <>
                                        {selectedDiscountData?.moderationComment && (
                                            <HotelNotification text={selectedDiscountData?.moderationComment} />
                                        )}
                                        {isViewDiscount && (
                                            <HotelDiscountForm
                                                isDisabled={isDisabled}
                                                values={values}
                                                resetForm={resetForm}
                                                setFieldValue={setFieldValue}
                                                isShowAlert={isShowAlert}
                                                closeAlert={onCloseAlert}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </ModalWindowRight>
                );
            }}
        </Formik>
    );
};

export default HotelDiscountModal;
