import React, { useEffect, useMemo } from "react";
import { BaseFieldProps } from "../../../../../../../types/fields";
import { getIn, useFormikContext } from "formik";
import { useRentHousesDictionary } from "../../../../../../../api/hooks/rentHouses";
import eventDispatcher from "../../../../../../../lib/event-dispatcher";
import { StatusRequestEnum, SelectInputField } from "@russpass/partner-front-ui";
import { UNEXPECTED_ERROR } from "../../../../../../../constants/errors";

type Props = BaseFieldProps & { dictionary: string };

export const CheckinTypeDirectionField = ({ name, disabled, label, placeholder, dictionary }: Props) => {
    const { setFieldValue, values } = useFormikContext();

    const value = useMemo(() => getIn(values, name), [name, values]);

    const { data, error } = useRentHousesDictionary(
        { dictionary: dictionary, pageSize: 999, sort: "dictionary_data.title" },
        { revalidateOnFocus: false }
    );

    useEffect(() => {
        if (!error) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [error]);

    const options = useMemo(
        () =>
            data?.data.items.map((el) => ({
                value: el.id,
                label: el.dictionary_data?.title || "",
                hint: el.dictionary_data?.is_self_checkin ? "Самостоятельное заселение" : undefined,
            })) || [],
        [data]
    );

    return (
        <SelectInputField
            name={name}
            label={label}
            placeholder={placeholder}
            options={options}
            value={options.find((item) => item.value === value) || ""}
            handleChange={(value) => setFieldValue(name, value.value)}
            isChecked
            disabled={disabled}
        />
    );
};
