import {
    DndUploadPhotosField,
    InputField,
    ModalWindowRight,
    MultiCheckedField,
    MultiSelectWithModal,
    OptionsGroupType,
    Select,
    StatusRequestCard,
    StatusRequestEnum,
    TextareaField,
} from "@russpass/partner-front-ui";
import { Form, Formik, FormikProps } from "formik";
import { useCallback, useMemo, useState } from "react";
import { HotelResidenceSlug, HotelResidenceTypesRes } from "../../../../../../api/dictionaries";
import { getImageById, uploadPhotoToCms } from "../../../../../../api/files";
import SelectAddress from "../../../../../../components/async-selects/address";
import { StatusRequestCardSubtitleError } from "../../../../../../constants/errors";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";
import { onError, onFileTooLarge, onPhotosLimit } from "../../../../../../utils/images";
import { useHotelDataContext } from "../../../hotel-view/hotel-data-context";
import { useHotelSpecialOffersContext } from "../../context/HotelSpecialOffersProvider";
import { useHotelTariffsContext } from "../../context/hotel-tariffs-context";
import { HotelData } from "../../types/hotel-info";
import { HotelRoomData, HotelRoomFormData } from "../../types/hotel-room";
import { hotelRoomValidation } from "../../validation-schemas/hotel-room";
import RoomGeneralInfo from "./general-info/general-info";
import OccupancyChooserBNOVO from "./occupancies-bnovo/occupancy-chooser";
import OccupancyChooserTL from "./occupancies-tl/occupancy-chooser";
import SpecialOffers from "./special-offers/special-offers";
import "./styles.sass";

type HotelRoomProps = {
    hotelInfo?: Pick<HotelData, "arrivalTime" | "departureTime" | "email" | "phones">;
    room: HotelRoomFormData;
    isView: boolean;
    onClose: (status: boolean) => void;
    onChangeHotelRoom: (values: HotelRoomFormData, rooms?: HotelRoomData[]) => Promise<void>;
    groupedAmenities: OptionsGroupType[];
    onAddMore?: () => void;
    status: StatusRequestEnum | null;
    isLoading?: boolean;
    isSaving?: boolean;
    hotelResidenceTypes?: HotelResidenceTypesRes["rows"];
};

const HotelRoom = ({
    hotelInfo,
    room,
    isView,
    onClose,
    groupedAmenities,
    onAddMore,
    status,
    isLoading,
    isSaving,
    onChangeHotelRoom,
    hotelResidenceTypes = [],
}: HotelRoomProps) => {
    const { hotelInfoData, isNewTravelLineHotel, isTravelLineHotel } = useHotelDataContext();
    const { tariffs } = useHotelTariffsContext();

    const [createdName, setCreatedName] = useState<string | null>(null);

    const onSubmitForm = useCallback(
        (values: HotelRoomFormData) => {
            setCreatedName(values.name);
            onChangeHotelRoom(values);
        },
        [onChangeHotelRoom]
    );

    const beforeOnClose = useCallback(
        (status: boolean) => {
            setCreatedName(null);
            onClose(status);
        },
        [onClose]
    );

    const handleCloseCard = useCallback(() => {
        const isSuccessRequest = status === StatusRequestEnum.Success;
        if (isSuccessRequest) {
            sendEvent(AnalyticEvent.close_rooms_info_saving);
        }
        beforeOnClose(isSuccessRequest);
    }, [beforeOnClose, status]);

    const { getRoomOffers } = useHotelSpecialOffersContext();
    const specialOffers = useMemo(() => getRoomOffers(room?.externalId || ""), [getRoomOffers, room?.externalId]);

    const isNewRoom = room.id.length === 0;

    const renderCmsTypeSelect = ({
        setFieldValue,
        values: formikValues,
    }: Pick<FormikProps<HotelRoomFormData>, "setFieldValue" | "values">) => {
        const selectedResidenceType = hotelResidenceTypes.find((t) => t.slug === formikValues.cmsTypeId);
        const selectedOption = {
            value: selectedResidenceType?.slug ?? "",
            label: selectedResidenceType?.title ?? "",
        };

        return (
            <Select
                label="Вариант проживания"
                placeholder="Выбрать"
                disabled={!isNewRoom}
                isChecked
                options={hotelResidenceTypes.map((t) => ({ value: t.slug, label: t.title }))}
                value={selectedOption}
                handleChange={(option) => {
                    setFieldValue("cmsTypeId", option.value);
                }}
            />
        );
    };

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);

    const initialValues = useMemo(
        () => ({
            arrivalTime: hotelInfo?.arrivalTime ?? "",
            departureTime: hotelInfo?.departureTime ?? "",
            email: hotelInfo?.email ?? "",
            phones: Array.isArray(hotelInfo?.phones) ? hotelInfo.phones : [{ number: "", name: "" }],

            // overwrite default values from hotelInfo
            ...room,
            address: room.address ?? "",
        }),
        [room, hotelInfo]
    );

    return (
        <Formik<HotelRoomFormData>
            initialValues={initialValues}
            validationSchema={hotelRoomValidation(room.occupancies, !!(isNewTravelLineHotel || isTravelLineHotel))}
            enableReinitialize
            validateOnMount
            onSubmit={onSubmitForm}
        >
            {({ values, setFieldValue, submitForm, resetForm, errors }) => {
                const handleClick = () => {
                    submitForm();
                    sendEvent(AnalyticEvent.rooms_info_save_button);
                };

                const handleClose = () => {
                    resetForm();
                    if (status) {
                        handleCloseCard();
                    } else {
                        beforeOnClose(false);
                    }
                };

                const handleAddMore = () => {
                    if (onAddMore) {
                        resetForm();
                        onAddMore();
                        sendEvent(AnalyticEvent.add_another_room);
                    }
                };

                const handleRepeatSubmit = () => {
                    onSubmitForm(values);
                    sendEvent(AnalyticEvent.repeat_rooms_info);
                };

                const isSlugBed = values.cmsTypeId === HotelResidenceSlug.BED;
                const isApartment = values.cmsTypeId === HotelResidenceSlug.APARTMENT;

                return (
                    <ModalWindowRight
                        isOpened={isView}
                        isHideCloseButton={!!status}
                        onClose={handleClose}
                        isLoadingWithoutContent={isLoading}
                        isLoadingWithContent={isSaving}
                        position={1}
                        ComponentFooter={
                            !isLoading && !isSaving && !status ? (
                                <button className="button button--primary" onClick={handleClick}>
                                    Сохранить
                                </button>
                            ) : undefined
                        }
                        headerComponent={
                            status ? undefined : (
                                <div className="hotel-info__header">
                                    <div className="hotel-info__header--title">{values.name || "Новый номер"}</div>
                                    <div className="hotel-info__header--name">{hotelInfoData.title}</div>
                                </div>
                            )
                        }
                    >
                        {status && (
                            <StatusRequestCard
                                status={status}
                                title={{
                                    success: `Номер \u00ab${createdName}\u00bb сохранен`,
                                    error: (
                                        <>
                                            {"Не удалось сохранить номер "}
                                            <br />
                                            {`\u00ab${createdName}\u00bb`}
                                        </>
                                    ),
                                }}
                                subtitle={{
                                    success: "Добавьте все существующие номера",
                                    error: StatusRequestCardSubtitleError,
                                }}
                                buttonTitle={{
                                    addMore: "Добавить еще номер",
                                }}
                                handleAddMore={handleAddMore}
                                handleClose={handleClose}
                                handleRepeat={handleRepeatSubmit}
                                handleBeforeClose={handleSendEvent}
                            />
                        )}
                        {!status && (
                            <div className="content-wrapper">
                                <div className="content-wrapper__form">
                                    {isNewRoom && !values?.cmsTypeId ? (
                                        renderCmsTypeSelect({ setFieldValue, values })
                                    ) : (
                                        <Form>
                                            <SpecialOffers offers={specialOffers} />
                                            {renderCmsTypeSelect({ setFieldValue, values })}
                                            <InputField
                                                label="Название"
                                                name="name"
                                                noHover
                                                placeholder="Новый номер"
                                            />

                                            <TextareaField
                                                label="Описание"
                                                name="description"
                                                noHover
                                                maxRows={3}
                                                placeholder="Максимально 400 символов"
                                                maxLength={400}
                                                hint="Эта информация отразится на странице вашего объекта на сайте RUSSPASS"
                                                className={"text-field__textCursor"}
                                            />
                                            <MultiSelectWithModal
                                                data={values.amenities}
                                                options={groupedAmenities}
                                                multiMenuSettings={{
                                                    name: "amenities",
                                                    label: "Удобства",
                                                    placeholder: "Выбрать",
                                                    hint: !values.amenities.length
                                                        ? "Перечень удобств поможет туристам принять решение"
                                                        : "",
                                                }}
                                                searchSettings={{
                                                    placeholder: "Название удобств",
                                                }}
                                                position={2}
                                                onChange={(values) => {
                                                    setFieldValue("amenities", values);
                                                }}
                                            />
                                            <div style={{ marginBottom: "24px" }}>
                                                <MultiCheckedField
                                                    name="rates"
                                                    label="Тариф"
                                                    placeholder="Выбрать"
                                                    value={values.rates}
                                                    noHover
                                                    options={tariffs}
                                                    valueField="id"
                                                    labelOptionField="title"
                                                    labelValueField="title"
                                                    onChange={(value) => {
                                                        setFieldValue(`rates`, value);
                                                    }}
                                                />
                                            </div>
                                            {isApartment && (
                                                <SelectAddress
                                                    name="address"
                                                    placeholder="Укажите адрес апартаментов"
                                                    value={values.address}
                                                    labelField="value"
                                                    handleChange={(value) => {
                                                        setFieldValue("address", value.value);
                                                        if (value.data.geo_lat && value.data.geo_lon) {
                                                            setFieldValue("coordinates", [
                                                                parseFloat(value.data.geo_lat),
                                                                parseFloat(value.data.geo_lon),
                                                            ]);
                                                        }
                                                    }}
                                                />
                                            )}

                                            {(isNewTravelLineHotel || isTravelLineHotel) && !isSlugBed && (
                                                <OccupancyChooserTL
                                                    occupancies={values.occupancies}
                                                    onChange={setFieldValue}
                                                />
                                            )}

                                            {!isNewTravelLineHotel && !isTravelLineHotel && !isSlugBed && (
                                                <OccupancyChooserBNOVO
                                                    occupancies={values.occupancies}
                                                    onChange={setFieldValue}
                                                />
                                            )}
                                            {isApartment && <RoomGeneralInfo />}

                                            <DndUploadPhotosField
                                                name="images"
                                                items={values.images.map((image) => ({ image })) || []}
                                                onChange={(values) => {
                                                    setFieldValue(
                                                        "images",
                                                        values.map((item) => item.image)
                                                    );
                                                }}
                                                isAvailablePhotoGuide={false}
                                                cardHeight={122}
                                                cardsGap={{
                                                    rowGap: 24,
                                                    columnGap: 16,
                                                }}
                                                title="Добавить фото номера"
                                                getImageById={getImageById}
                                                uploadPhoto={uploadPhotoToCms}
                                                onFileTooLarge={onFileTooLarge}
                                                onPhotosLimit={onPhotosLimit}
                                                onError={onError}
                                            />
                                        </Form>
                                    )}
                                </div>
                            </div>
                        )}
                    </ModalWindowRight>
                );
            }}
        </Formik>
    );
};

export default HotelRoom;
