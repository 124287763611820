import {
    RestaurantInfoType,
    RestaurantInfoFormType,
    AddressType,
    RestaurantSupportInfoType,
    RestaurantOptionsType,
} from "./types";
import store from "../../../store";
import { TSelectValue } from "../../../types/dictionaries";
import { ColorActiveTab, HeaderTab } from "@russpass/partner-front-ui";

export type RestaurantNewStep = string | number | undefined;

export enum NavbarRestaurantNewName {
    OBJECT = "object",
    RATING_MARKS = "reviews",
}

const email = store.getState().user.userFullInfo?.email;

export const navbarRestaurantLabels = {
    object: "Объект",
    reviews: "Отзывы",
};

export const navbarRestaurant: HeaderTab[] = [
    {
        label: navbarRestaurantLabels.object,
        name: NavbarRestaurantNewName.OBJECT,
        colorActiveTab: ColorActiveTab.Secondary2,
    },
    {
        label: navbarRestaurantLabels.reviews,
        name: NavbarRestaurantNewName.RATING_MARKS,
        colorActiveTab: ColorActiveTab.Secondary2,
    },
];

const initStateRestaurant: RestaurantInfoType = {
    name: "",
    description: "",
    city: "",
    region: "",
    bill: undefined,
    coordinates: [],
    address: "",
    workingTime: [],
    phone: "",
    email: email || "",
    images: [],
    imageDetailedPageMain: [],
    imageExplorePreview: [],
    avgTime: 60,
    cuisines: [],
    restaurantTypes: [],
    restaurantCharacteristic: [],
    restaurantTags: [],
};

const region: TSelectValue = {
    id: "",
    title: "",
};

const city: TSelectValue = {
    id: "",
    title: "",
};

const address: AddressType = {
    unrestricted_value: "",
    value: "",
    data: {},
};

export const initStateRestaurantForm: RestaurantInfoFormType = {
    ...initStateRestaurant,
    address,
    region,
    city,
    canReserve: true,
};

export const initStateRestaurantSupportInfo: RestaurantSupportInfoType = {
    importDeniedReason: "",
    status: "draft",
    url: "",
};

export const initStateDictionaryOptions: RestaurantOptionsType = {
    cuisinesOptions: [],
    restaurantTypeOptions: [],
    restaurantCharacteristicOptions: [],
    restaurantTagsOptions: [],
};

export const modalAlertDetails = {
    title: "При выходе все заполненные данные пропадут",
    OKButton: "Выйти без сохранения",
    cancelButton: "Продолжить заполнение данных",
};
