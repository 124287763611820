import fetchRequest from "./manager";
import { partnerApiServices, russpassApiServices } from "../constants/api";
import { IUserContactInfo } from "../types/profile";

const partnershipServicePath = russpassApiServices.partnership;
const documentsPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.documents}`;
const profileSettingsPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.profileSettings}`;

export const getUserInfo = async (email: string, withRedirect401: boolean = true) => {
    const content = await fetchRequest.get(`${profileSettingsPartnerServiceUrl}/partner/${email}/profile`, null, {
        isProtected: true,
        withRedirect401,
    });
    return content;
};

export const getUserContactInfo = async (applicationId: string): Promise<IUserContactInfo> => {
    const content = await fetchRequest.get(`${documentsPartnerServiceUrl}/contractInfo/${applicationId}`, null, {
        isProtected: true,
    });
    return content;
};
