import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useMemo } from "react";

const useQueryState = (key: string): [Readonly<string | null>, (value: string | null) => void] => {
    const history = useHistory();
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const state = useMemo(() => params.get(key) as Readonly<string | null>, [key, params]);

    const setState = useCallback(
        (value: string | null) => {
            const newParams = new URLSearchParams(params);
            if (!value) {
                newParams.delete(key);
            } else {
                newParams.set(key, value);
            }
            history.replace({ search: `${newParams}` });
        },
        [history, key, params]
    );

    return useMemo(() => [state, setState], [setState, state]);
};

export default useQueryState;
