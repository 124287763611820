import moment from "moment";
import { FeedbackOrderModel } from "../../types/FeedbackOrderModel";
import { AccordionItem } from "@russpass/partner-front-ui";
import { ReactComponent as BriefcaseIcon } from "../../../../assets/images/icons/briefcase.svg";
import {
    DAY_DATE_FORMAT,
    MID_DATE_FORMAT,
    MID_DAY_MONTH_DATE_FORMAT,
    MID_MONTH_YEAR_DATE_FORMAT,
    YEAR_DATE_FORMAT,
} from "../../../../constants/dates-format";
import { declensionOfNum } from "../../../../utils/declension-number";

type FeedbackOrderProps = {
    order?: FeedbackOrderModel;
};

const ROOK_STAY_KIND_MEAL = "Meal";

const accordionItem = { title: "Информация по бронированию", icon: BriefcaseIcon };

export const FeedbackOrder = ({ order }: FeedbackOrderProps) => {
    if (!order) return null;

    const [orderItem] = order.items;
    const booking = orderItem.booking;

    const [roomStay] = booking.roomStays || [];
    const adultGuestCount = roomStay.guestCount.adultCount;
    const mealIncluded = roomStay.services.some(({ kind }) => kind === ROOK_STAY_KIND_MEAL);

    return (
        <div className="feedback__order-wrapper">
            <AccordionItem id={order.id} item={accordionItem}>
                <div className="feedback__order-row">
                    <div className="feedback__order-label">Даты</div>
                    {formatDateRange(booking.arrivalDateTime, booking.departureDateTime)}
                </div>
                <div className="feedback__order-row">
                    <div className="feedback__order-label">Номер</div>
                    {roomStay.roomType.name}
                </div>
                <div className="feedback__order-row">
                    <div className="feedback__order-label">Питание</div>
                    {mealIncluded ? "Включено" : "Не включено"}
                </div>
                <div className="feedback__order-row">
                    <div className="feedback__order-label">Количество гостей</div>
                    {adultGuestCount} {declensionOfNum(adultGuestCount, ["взрослый", "взрослых", "взрослых"])}
                </div>
                <div className="feedback__order-row">
                    <div className="feedback__order-label">Дата покупки</div>
                    {moment(order.createDate).format(MID_DATE_FORMAT)}
                </div>
                <div className="feedback__order-row">
                    <div className="feedback__order-label">Стоимость</div>
                    {orderItem.price.price} {orderItem.price.currency}
                </div>
                <div className="feedback__order-row">
                    <div className="feedback__order-label">Номер бронирования</div>
                    {booking.number}
                </div>
            </AccordionItem>
        </div>
    );
};

const formatDateRange = (from: string, to: string) => {
    const fromMoment = moment(from);
    const toMoment = moment(to);

    if (fromMoment.year() !== toMoment.year()) {
        return [fromMoment.format(MID_DATE_FORMAT), toMoment.format(MID_DATE_FORMAT)].join(" – ");
    }

    if (fromMoment.month() !== toMoment.month()) {
        return [
            [fromMoment.format(MID_DAY_MONTH_DATE_FORMAT), toMoment.format(MID_DAY_MONTH_DATE_FORMAT)].join(" – "),
            fromMoment.format(YEAR_DATE_FORMAT),
        ].join(" ");
    }

    return [
        [fromMoment.format(DAY_DATE_FORMAT), toMoment.format(DAY_DATE_FORMAT)].join(" – "),
        fromMoment.format(MID_MONTH_YEAR_DATE_FORMAT),
    ].join(" ");
};
