import moment from "moment";
import { SHORT_DATE_TIME_FORMAT } from "../../../../constants/dates-format";

type FeedbackModalHeaderProps = {
    title?: string;
    date?: string;
    event?: {
        externalId: string;
        name?: string;
        screen?: string;
    };
};

export const FeedbackModalHeader = ({ title, date, event }: FeedbackModalHeaderProps) => {
    const host = process.env.REACT_APP_RUSSPASS_URL;
    return (
        <div className="feedback__modal-header">
            {event?.screen ? (
                <a
                    href={`${host}/${event.screen}/${event.externalId}`}
                    className="feedback__modal-title"
                    target="_blank"
                    rel="noreferrer"
                    data-qa-name="feedback-modal-header"
                >
                    {title}
                </a>
            ) : (
                title
            )}
            {date && (
                <div
                    className="feedback__modal-subheader feedback__modal-title"
                    data-qa-name="feedback-modal-subheader"
                >
                    {moment(date).format(SHORT_DATE_TIME_FORMAT)}
                </div>
            )}
        </div>
    );
};
