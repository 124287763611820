import eventDispatcher from "../../../lib/event-dispatcher";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
    createRestaurant,
    sendForApprovalRestaurant,
    getRestaurant,
    editRestaurant as editRestaurantPatch,
} from "../../../api/restaurants";
import { COMMON_ERROR } from "../../../constants/errors";
import {
    modalAlertDetails,
    initStateRestaurantForm,
    initStateRestaurantSupportInfo,
    initStateDictionaryOptions,
    RestaurantNewStep,
    NavbarRestaurantNewName,
} from "./constants";
import { restaurantInfoValidation } from "./validation-schema";
import {
    RestaurantInfoFormType,
    RestaurantInfoSendPostType,
    RestaurantInfoGetType,
    RestaurantSupportInfoType,
    RestaurantOptionsType,
} from "./types";
import { useModalAlertContext, StatusRequestEnum } from "@russpass/partner-front-ui";
import history from "../../../history";
import routes from "../../../constants/routes";
import { useLocation, useParams } from "react-router-dom";
import {
    getCuisinesOptions,
    getRestaurantTypesOptions,
    getRestaurantCharacteristicOptions,
    getDataForSend,
    getRestaurantInitialData,
    getRestaurantSupportInfo,
    mappingRestaurantData,
    getCuisinesTagsOptions,
} from "./utils";
import { isEqual } from "lodash";
import useUnload from "../../../hooks/useUnload";
import { restaurantStatuses } from "../all/constants";
import { isEnableRestaurantNewFields } from "../../../constants/feature-toggles";
import { TSelectValue } from "../../../types/dictionaries";

const useRestaurantControlData = () => {
    // @ts-ignore
    const { restaurantId } = useParams();
    const location = useLocation<{ status: string }>();
    const [restaurantInfoInitialData, setRestaurantInfoInitialData] =
        useState<RestaurantInfoFormType>(initStateRestaurantForm);
    const [restaurantInfoData, setRestaurantInfoData] = useState<RestaurantInfoFormType>(initStateRestaurantForm);
    const [restaurantSupportInfo, setRestaurantSupportInfo] =
        useState<RestaurantSupportInfoType>(initStateRestaurantSupportInfo);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const [status, setStatus] = useState<StatusRequestEnum | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isArchiveRestaurant, setIsArchiveRestaurant] = useState<boolean>(false);
    const [dictionaryOptions, setDictionaryOptions] = useState<RestaurantOptionsType>(initStateDictionaryOptions);
    const [disabledRestaurantForm, setDisabledRestaurantForm] = useState(true);

    const [step, setStep] = useState<RestaurantNewStep>(
        new URLSearchParams(location.search).get("tab") || NavbarRestaurantNewName.OBJECT
    );

    const { openModalAlert } = useModalAlertContext();

    const isExistingRestaurant = useMemo(() => {
        return !!restaurantId;
    }, [restaurantId]);

    const isChanged = useMemo(() => {
        if (!isExistingRestaurant) {
            return !isEqual(restaurantInfoInitialData, restaurantInfoData);
        }

        const { canReserve: _, ...initialWithoutCanReserve } = restaurantInfoInitialData;
        const { canReserve: __, ...currentWithoutCanReserve } = restaurantInfoData;

        return !isEqual(initialWithoutCanReserve, currentWithoutCanReserve);
    }, [isExistingRestaurant, restaurantInfoData, restaurantInfoInitialData]);

    const onChange = useCallback((values: RestaurantInfoFormType) => {
        setRestaurantInfoData((prevState) => ({
            ...prevState,
            ...values,
        }));
    }, []);

    const handleStepChange = (newStep: RestaurantNewStep) => {
        if (newStep !== step) {
            setStep(newStep);
            const searchParams = newStep === NavbarRestaurantNewName.OBJECT ? "" : `?tab=${newStep}`;

            history.push({
                pathname: location.pathname,
                search: searchParams,
                state: location?.state,
            });
        }
    };

    const setEnableEditRestaurant = useCallback(() => {
        setDisabledRestaurantForm(false);
    }, []);

    const goToAllRestaurantsPage = useCallback(
        () =>
            history.push({
                pathname: routes.restaurantAll,
                state: location?.state,
            }),
        [location?.state]
    );

    const closeStatusCard = useCallback(() => {
        status === StatusRequestEnum.Success && goToAllRestaurantsPage();
        setStatus(null);
    }, [goToAllRestaurantsPage, status]);

    const goBack = useCallback(() => {
        !isChanged ? goToAllRestaurantsPage() : openModalAlert(modalAlertDetails, goToAllRestaurantsPage);
    }, [goToAllRestaurantsPage, isChanged, openModalAlert]);

    const loadData = useCallback(async () => {
        setIsLoading(true);
        const cuisinesOptions = await getCuisinesOptions();
        let restaurantTypeOptions: TSelectValue[] = [];
        let restaurantCharacteristicOptions: TSelectValue[] = [];
        let restaurantTagsOptions: TSelectValue[] = [];
        if (isEnableRestaurantNewFields) {
            restaurantTypeOptions = await getRestaurantTypesOptions();
            restaurantCharacteristicOptions = await getRestaurantCharacteristicOptions();
            restaurantTagsOptions = await getCuisinesTagsOptions();
        }
        setDictionaryOptions({
            cuisinesOptions,
            restaurantTypeOptions,
            restaurantCharacteristicOptions,
            restaurantTagsOptions,
        });
        if (!isExistingRestaurant) {
            setDisabledRestaurantForm(false);
            setIsLoading(false);
            return;
        }
        try {
            const dataFromServer: RestaurantInfoGetType = await getRestaurant(restaurantId);
            const mappedData = mappingRestaurantData(dataFromServer);
            const restaurantSupportInfo = getRestaurantSupportInfo(mappedData);
            const restaurantInitialData = await getRestaurantInitialData(mappedData, {
                cuisines: cuisinesOptions,
                types: restaurantTypeOptions,
                characteristics: restaurantCharacteristicOptions,
                restaurantTags: restaurantTagsOptions,
            });
            setRestaurantInfoInitialData(restaurantInitialData);
            setRestaurantInfoData(restaurantInitialData);
            setRestaurantSupportInfo(restaurantSupportInfo);
            const isArchive =
                mappedData.status === restaurantStatuses.deleted || mappedData.status === restaurantStatuses.inactive;
            if (!isArchive) {
                setDisabledRestaurantForm(false);
            } else {
                setIsArchiveRestaurant(isArchive);
            }
        } catch (error) {
            goToAllRestaurantsPage();
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: COMMON_ERROR,
            });
        } finally {
            setIsLoading(false);
        }
    }, [goToAllRestaurantsPage, restaurantId, isExistingRestaurant]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useUnload({ onBrowserBackClick: goBack, state: location?.state });

    const onSaveRestaurant = useCallback(async () => {
        setIsSubmittingForm(true);
        const isValidInfo = restaurantInfoValidation.isValidSync(restaurantInfoData);
        if (isValidInfo) {
            setIsLoading(true);
            const dataForSend: RestaurantInfoSendPostType = getDataForSend(restaurantInfoData, restaurantId);
            try {
                if (isExistingRestaurant) {
                    await editRestaurantPatch(dataForSend);
                    if (isArchiveRestaurant) {
                        await sendForApprovalRestaurant(restaurantId);
                    }
                } else {
                    const { id } = await createRestaurant(dataForSend);
                    await sendForApprovalRestaurant(id);
                }

                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: "Объект отправлен на модерацию",
                });

                setStatus(StatusRequestEnum.Success);
            } catch (error) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: COMMON_ERROR,
                });

                setStatus(StatusRequestEnum.Error);
            } finally {
                setIsLoading(false);
            }
        } else {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: "Вы заполнили не все разделы",
                isAlwaysView: false,
            });
        }
    }, [isExistingRestaurant, restaurantInfoData, restaurantId, isArchiveRestaurant]);

    return {
        restaurantInfoData,
        restaurantSupportInfo,
        isExistingRestaurant,
        isSubmittingForm,
        isLoading,
        onChange,
        goBack,
        status,
        closeStatusCard,
        onSaveRestaurant,
        isChanged,
        dictionaryOptions,
        disabledRestaurantForm,
        setEnableEditRestaurant,
        restaurantId,
        handleStepChange,
    };
};

export default useRestaurantControlData;
