import { RestaurantOptionType, RestaurantInfoFormType, RestaurantInfoGetType, WorkingTimeRest } from "./types";
import { getDayTimeTableArray, getWorkTimes } from "../../services/service/utils";
import { PlaceTimetableItemType } from "../../../components/timetable/place-timetable/types";
import { getCity, getRegion } from "../../../components/async-selects/utils";
import {
    getCuisinesTypes,
    getRestaurantTypesTypes,
    getRestaurantCharacteristicTypes,
    getRestaurantTagsTypes,
} from "../../../api/dictionaries";
import { isEnableRestaurantNewFields } from "../../../constants/feature-toggles";
import store from "../../../store";
import { prepareTimetableData } from "../../services/components/service-place/utils";

enum DaysOfTheWeek {
    monday = "mon",
    tuesday = "tue",
    wednesday = "wed",
    thursday = "thu",
    friday = "fri",
    saturday = "sat",
    sunday = "sun",
}

const getTimetableRestaurantData = (values: PlaceTimetableItemType[]): WorkingTimeRest => {
    const newData = {
        all: { closed: true },
        days: {
            mon: { closed: true },
            tue: { closed: true },
            wed: { closed: true },
            thu: { closed: true },
            fri: { closed: true },
            sat: { closed: true },
            sun: { closed: true },
        },
    };

    values.forEach((item) => {
        const itemDays = getDayTimeTableArray(item.workingDays);
        if (!itemDays.length) {
            return;
        }
        const timesData = getWorkTimes(item);

        if (itemDays[0]?.value === "allDays") {
            newData.all = { ...timesData };

            for (let key in newData.days) {
                const day = key as keyof typeof newData.days;
                newData.days[day].closed = false;
            }
            return;
        }

        itemDays.forEach((day) => {
            const _value = day.value as keyof typeof DaysOfTheWeek;
            const _day = DaysOfTheWeek[_value] as keyof typeof newData.days;
            newData.days[_day] = { ...timesData, closed: false };
        });
    });
    return newData as WorkingTimeRest;
};

const prepareTimetableRestaurantData = (data: WorkingTimeRest): PlaceTimetableItemType[] => {
    const _days: any = {};

    for (const key in data.days) {
        for (const day in DaysOfTheWeek) {
            const _key = day as keyof typeof DaysOfTheWeek;
            const _day = DaysOfTheWeek[_key];
            if (key === _day) {
                _days[day] = data.days[key];
            }
        }
    }

    const _data = { ...data, days: _days };

    return prepareTimetableData(_data, false);
};

export const mappingRestaurantData = <T extends RestaurantInfoGetType>(dataFromServer: T): T => {
    const updated = dataFromServer.updating;
    delete dataFromServer.updating;
    for (const propName in updated) {
        const propValue = updated[propName];
        if (!propValue) continue;
        if (Array.isArray(propValue) && !propValue.length) continue;
        //@ts-ignore
        dataFromServer[propName] = propValue;
    }
    return dataFromServer;
};

export const getDataForSend = (restaurantInfoData: RestaurantInfoFormType, restaurantId: string) => {
    const restaurantType = restaurantInfoData.restaurantTypes as RestaurantOptionType;
    return {
        ...restaurantInfoData,
        region: restaurantInfoData.region.id,
        city: [restaurantInfoData.city.id],
        address: restaurantInfoData.address.unrestricted_value,
        workingTime: getTimetableRestaurantData(restaurantInfoData.workingTime),
        imageExplorePreview: [restaurantInfoData.images[0]],
        imageDetailedPageMain: [restaurantInfoData.images[0]],
        images: [...restaurantInfoData.images.slice(1)],
        bill:
            typeof restaurantInfoData.bill === "undefined"
                ? restaurantInfoData.bill
                : +restaurantInfoData.bill?.replace(/\D/g, ""),
        id: restaurantId,
        cuisines: restaurantInfoData.cuisines.map((el) => el.id),
        restaurantTypes: isEnableRestaurantNewFields && restaurantType.id ? restaurantType.id : null,
        restaurantCharacteristic:
            isEnableRestaurantNewFields && restaurantInfoData.restaurantCharacteristic
                ? restaurantInfoData.restaurantCharacteristic?.map((el) => el.id)
                : null,
        restaurantTags:
            isEnableRestaurantNewFields && restaurantInfoData.restaurantTags
                ? restaurantInfoData.restaurantTags.map((el) => el.id)
                : null,
    };
};

export const getRestaurantInitialData = async (
    mappedData: RestaurantInfoGetType,
    options: {
        cuisines: RestaurantOptionType[];
        types: RestaurantOptionType[];
        characteristics: RestaurantOptionType[];
        restaurantTags: RestaurantOptionType[];
    }
) => {
    const city = await getCity(mappedData.city[0]);
    const region = await getRegion(mappedData.region);
    const name = mappedData.title;
    const images = Array.from(new Set([...mappedData.imageExplorePreview, ...mappedData.images]));
    const workingTime = prepareTimetableRestaurantData(mappedData.workingTime);
    const address = {
        unrestricted_value: mappedData.address,
        value: mappedData.address,
        data: {},
    };
    const bill = mappedData.bill?.toString();
    const email = mappedData.email || store.getState().user.userFullInfo?.email || "";
    const coordinates = mappedData.coordinates.coordinates;
    const cuisines = options.cuisines.filter((el: RestaurantOptionType) => mappedData.cuisines.includes(el.id));
    const restaurantTypes =
        options.types.find((el: RestaurantOptionType) => mappedData.restaurantTypes === el.id) ?? null;
    const restaurantCharacteristic = options.characteristics.filter((el: RestaurantOptionType) =>
        mappedData.restaurantCharacteristic?.includes(el.id)
    );
    const restaurantTags = options.restaurantTags.filter((el: RestaurantOptionType) =>
        mappedData.restaurantTags?.includes(el.id)
    );

    return {
        ...mappedData,
        name,
        region,
        city,
        images,
        address,
        workingTime,
        bill,
        email,
        coordinates,
        cuisines,
        restaurantTypes,
        restaurantCharacteristic,
        restaurantTags,
    };
};

export const getRestaurantSupportInfo = (mappedData: RestaurantInfoGetType) => {
    const url = mappedData.url;
    const status = mappedData.status;
    const importDeniedReason = mappedData.importDeniedReason;
    return { url, status, importDeniedReason };
};

export const getCuisinesOptions = async () => {
    let data: RestaurantOptionType[] = [];
    try {
        const { rows } = await getCuisinesTypes();
        data = rows;
    } catch (err) {
        console.error(err);
    }
    return data;
};

export const getRestaurantTypesOptions = async () => {
    let data: RestaurantOptionType[] = [];
    try {
        const { rows } = await getRestaurantTypesTypes();
        data = rows;
    } catch (err) {
        console.error(err);
    }
    return data;
};

export const getRestaurantCharacteristicOptions = async () => {
    let data: RestaurantOptionType[] = [];
    try {
        const { rows } = await getRestaurantCharacteristicTypes();
        data = rows;
    } catch (err) {
        console.error(err);
    }
    return data;
};

export const getCuisinesTagsOptions = async () => {
    let data: RestaurantOptionType[] = [];
    try {
        const { rows } = await getRestaurantTagsTypes();
        data = rows;
    } catch (err) {
        console.error(err);
    }
    return data;
};
