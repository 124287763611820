import React, { useMemo } from "react";
import { RejectionComment } from "@russpass/partner-front-ui";

type Props = {
    reasons: (string | null | undefined)[];
};

const ServiceComment = ({ reasons }: Props) => {
    const filteredReasons = useMemo(() => reasons.filter(Boolean), [reasons]);

    return (
        <RejectionComment
            reasons={filteredReasons.map((reason, index) => (
                <span key={`reason-${index}`}>
                    <div>{reason}</div>
                    {index !== filteredReasons.length - 1 && <br />}
                </span>
            ))}
        />
    );
};

export default ServiceComment;
