import { useCallback, useState } from "react";
import { HotelDiscountsData } from "../../../types/hotel-discounts";
import { getOneSpecialOffers } from "../../../../../../../api/specialOffers";

export const useControlDiscountModal = (loadDiscounts: () => void) => {
    const [selectedDiscountData, setSelectedDiscountData] = useState<HotelDiscountsData | undefined>(undefined);
    const [isViewDiscount, setIsViewDiscount] = useState<boolean>(false);

    const onSelectDiscount = useCallback(
        async (id: string) => {
            const discountData: HotelDiscountsData = (await getOneSpecialOffers(id))?.data;
            setSelectedDiscountData(discountData);
            setIsViewDiscount(true);
        },
        [setIsViewDiscount, setSelectedDiscountData]
    );

    const onClose = useCallback(
        (isUpdate: boolean = false, onClosed?: () => void) => {
            if (isUpdate) {
                loadDiscounts();
            }
            setIsViewDiscount(false);
            setSelectedDiscountData(undefined);
            onClosed?.();
        },
        [loadDiscounts, setIsViewDiscount, setSelectedDiscountData]
    );

    const onAddMore = useCallback(() => {
        setIsViewDiscount(true);
        setSelectedDiscountData(undefined);
    }, [setIsViewDiscount, setSelectedDiscountData]);

    return { onSelectDiscount, onClose, onAddMore, selectedDiscountData, isViewDiscount, setSelectedDiscountData };
};
