import { allStatusesHotel, navbarHotelsNames } from "./constants";
import { HotelItemCms } from "./types";

export const getIsRefusedHotel = (hotelItem: HotelItemCms) => {
    return !!hotelItem.isRejected;
};

export const getIsArchiveHotel = (hotelItem: HotelItemCms) => {
    return (
        !hotelItem.isRejected &&
        (hotelItem.status === allStatusesHotel.deleted || hotelItem.status === allStatusesHotel.inactive)
    );
};

export const getIsDraftHotel = (hotelItem: HotelItemCms) => {
    return !hotelItem.isRejected && hotelItem.status === allStatusesHotel.draft;
};

export const getIsModerationHotel = (hotelItem: HotelItemCms) => {
    return !hotelItem.isRejected && hotelItem.status === allStatusesHotel.import;
};

export const getIsPublishedHotel = (hotelItem: HotelItemCms) => {
    return !hotelItem.isRejected && hotelItem.status === allStatusesHotel.active;
};

export const getNavNameStatus = (hotelItem: HotelItemCms) => {
    if (hotelItem.isRejected) return navbarHotelsNames.refused;

    switch (hotelItem.status) {
        case allStatusesHotel.draft:
            return navbarHotelsNames.draft;
        case allStatusesHotel.import:
            return navbarHotelsNames.moderation;
        case allStatusesHotel.active:
            return navbarHotelsNames.published;
        case allStatusesHotel.deleted:
        case allStatusesHotel.inactive:
            return navbarHotelsNames.archive;
        default:
            return navbarHotelsNames.all;
    }
};
