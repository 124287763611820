import { HeaderTab } from "@russpass/partner-front-ui";

export const navbarProfileName = {
    user: "user",
    userGuide: "userGuide",
    company: "company",
    bankDetails: "bankDetails",
    contact: "contact",
};

export const navbarProfile: HeaderTab[] = [
    {
        label: "О пользователе",
        name: navbarProfileName.user,
    },
    {
        label: "О вас",
        name: navbarProfileName.userGuide,
    },
    {
        label: "Контактные данные",
        name: navbarProfileName.contact,
    },
    {
        label: "О компании",
        name: navbarProfileName.company,
    },
    {
        label: "Банковские реквизиты",
        name: navbarProfileName.bankDetails,
    },
];
