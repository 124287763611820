import {
    array as arrayYup,
    object as objectYup,
    string as stringYup,
    number as numberYup,
    boolean,
    TestContext,
} from "yup";
import { FORMAT_ERROR, FORM_FIELD_ERROR, FORM_FIELD_REQUIRED_ERROR } from "../../../../../constants/errors";
import { Occupancy } from "../types/hotel-room";
import { isArray, cloneDeep } from "lodash";
import { isEqualObjects } from "../../../../../utils/common";
import { AnyObject } from "yup/lib/types";
import { emailRegexp, phoneRegexp, timeRegexp } from "../../../../../utils/validation";
import { HotelResidenceSlug } from "../../../../../api/dictionaries";

const ERROR_COUNT_ADULT = "Укажите количество взрослых";
const NON_UNIQUE_OCCUPANCY_ERROR = "Данное размещение уже привязано к номеру";
const ERROR_AGE = "Укажите возрастной диапазон ребенка от 0 до 17";
const CROSS_AGE_ERROR = "Возраст детей в диапазонах не должен пересекаться";
const REQUIRED_CHILD = "При создании или редактировании детских размещений, количество детей должно быть больше 0";
const MIN_MAX_AGE_WITHIN_LIMIT = "Укажите возрастной диапазон ребенка от 0 до 17";
const CHECK_MAX_AGE_GREATER_MIN_AGE = "Минимальный возраст не должен превышать максимальный";

const checkRequiredMinMaxAge = function () {
    // @ts-ignore
    const parent: Occupancy = this.parent;
    if (parent.child > 0) {
        return (
            parent.minAge !== null &&
            parent.maxAge !== null &&
            parent.minAge !== undefined &&
            parent.maxAge !== undefined
        );
    }
    return true;
};

const checkMinMaxAgeWithinLimit = (_value: unknown, ctx: TestContext<AnyObject>) => {
    const { minAge, maxAge, isChild } = ctx.parent as Occupancy;
    if (isChild && !!(minAge && maxAge)) {
        return +minAge >= 0 && +maxAge <= 17;
    }
    return true;
};

const checkMaxAgeGreaterMinAge = (_value: unknown, ctx: TestContext<AnyObject>) => {
    const { minAge, maxAge, isChild } = ctx.parent as Occupancy;
    if (isChild && !!(minAge && maxAge)) {
        return +maxAge > +minAge;
    }
    return true;
};

const checkRequiredChild = (_value: number | undefined, ctx: TestContext<AnyObject>) => {
    const { isChild, child } = ctx.parent as Occupancy;
    return isChild ? !!child : true;
};

const checkCrossAge = function () {
    // @ts-ignore
    const occupancies = cloneDeep(this.parent.occupancies);
    let isError = false;
    if (isArray(occupancies) && occupancies.length > 0) {
        const findIntersection = (intervals: number[], start: number, end: number) =>
            intervals.find((interval) => start <= interval && interval <= end);
        const sortedValue = occupancies.sort((a, b) => a.minAge - b.minAge);
        for (let i = 1; i < occupancies.length; i++) {
            const current = sortedValue[i];
            const prev = sortedValue[i - 1];

            if (current.child > 0 && prev.child > 0) {
                const interSectionElem = findIntersection(
                    [current.minAge, current.maxAge],
                    Number(prev.minAge),
                    Number(prev.maxAge)
                );
                if (interSectionElem !== undefined) {
                    isError = true;
                    break;
                }
            }
        }
    }
    return !isError;
};

//TODO delete occupancies
export const hotelRoomOccupanciesBNOVOValidation = (occupancies: Occupancy[]) => {
    return arrayYup()
        .of(
            objectYup().shape({
                adults: numberYup()
                    .test("test", ERROR_COUNT_ADULT, function (value) {
                        return !!this.parent.adults && this.parent.adults > 0;
                    })
                    .test("checkNonUniqueOccupancy", NON_UNIQUE_OCCUPANCY_ERROR, function (value) {
                        // @ts-ignore
                        const { from } = this;
                        const _occupancies = from[1]?.value.occupancies || occupancies;
                        const indexOccupancy = _occupancies.findIndex((occupancy: Occupancy) =>
                            isEqualObjects(occupancy, this.parent)
                        );
                        let countOccupancy = 0;
                        for (let i = 0; i < indexOccupancy; i++) {
                            if (
                                _occupancies[i].adults === this.parent.adults &&
                                _occupancies[i].child === this.parent.child &&
                                Number(_occupancies[i].minAge) === Number(this.parent.minAge) &&
                                Number(_occupancies[i].maxAge) === Number(this.parent.maxAge) &&
                                _occupancies[i].bedType === this.parent.bedType
                            ) {
                                countOccupancy += 1;
                            }
                        }
                        return countOccupancy === 0;
                    }),
                isChild: boolean()
                    .nullable()
                    .test("checkRequiredMinMaxAge", ERROR_AGE, checkRequiredMinMaxAge)
                    .test("checkMinMaxAgeWithinLimit", MIN_MAX_AGE_WITHIN_LIMIT, checkMinMaxAgeWithinLimit)
                    .test("checkMaxAgeGreaterMinAge", CHECK_MAX_AGE_GREATER_MIN_AGE, checkMaxAgeGreaterMinAge),
            })
        )
        .required(FORM_FIELD_REQUIRED_ERROR)
        .min(1, FORM_FIELD_REQUIRED_ERROR);
};

export const tlHotelRoomOccupanciesValidation = arrayYup()
    .of(
        objectYup().shape({
            age: numberYup()
                .test("checkRequiredChild", REQUIRED_CHILD, checkRequiredChild)
                .test("checkRequiredMinMaxAge", ERROR_AGE, checkRequiredMinMaxAge)
                .test("checkMinMaxAgeWithinLimit", MIN_MAX_AGE_WITHIN_LIMIT, checkMinMaxAgeWithinLimit)
                .test("checkMaxAgeGreaterMinAge", CHECK_MAX_AGE_GREATER_MIN_AGE, checkMaxAgeGreaterMinAge),
        })
    )
    .required(FORM_FIELD_REQUIRED_ERROR)
    .min(1, FORM_FIELD_REQUIRED_ERROR);

//TODO delete occupancies
export const hotelRoomValidation = function (occupancies: Occupancy[], isNewTravelLineHotel: boolean) {
    return objectYup().shape({
        name: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
        description: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
        amenities: arrayYup().required(FORM_FIELD_REQUIRED_ERROR).min(1, FORM_FIELD_REQUIRED_ERROR),
        images: arrayYup().min(1, FORM_FIELD_REQUIRED_ERROR),
        rates: arrayYup().min(1, FORM_FIELD_REQUIRED_ERROR),
        occupancies: isNewTravelLineHotel
            ? tlHotelRoomOccupanciesValidation
            : hotelRoomOccupanciesBNOVOValidation(occupancies),
        occ: arrayYup().test("checkCrossAge", CROSS_AGE_ERROR, isNewTravelLineHotel ? checkCrossAge : () => true),

        cmsTypeId: stringYup().oneOf([
            HotelResidenceSlug.BED,
            HotelResidenceSlug.APARTMENT,
            HotelResidenceSlug.PRIVATE_ROOM,
        ]),

        address: stringYup().when("cmsTypeId", {
            is: HotelResidenceSlug.APARTMENT,
            then: (schema) => schema.required(FORM_FIELD_REQUIRED_ERROR),
            otherwise: (schema) => schema.notRequired().strip(),
        }),
        arrivalTime: stringYup().when("cmsTypeId", {
            is: HotelResidenceSlug.APARTMENT,
            then: (schema) =>
                schema.required(FORM_FIELD_REQUIRED_ERROR).matches(timeRegexp, {
                    message: FORMAT_ERROR,
                }),
            otherwise: (schema) => schema.notRequired().strip(),
        }),
        departureTime: stringYup().when("cmsTypeId", {
            is: HotelResidenceSlug.APARTMENT,
            then: (schema) =>
                schema.required(FORM_FIELD_REQUIRED_ERROR).matches(timeRegexp, {
                    message: FORMAT_ERROR,
                }),
            otherwise: (schema) => schema.notRequired().strip(),
        }),
        email: stringYup().when("cmsTypeId", {
            is: HotelResidenceSlug.APARTMENT,
            then: (schema) =>
                schema
                    .matches(emailRegexp, {
                        message: FORM_FIELD_ERROR,
                    })
                    .required(FORM_FIELD_REQUIRED_ERROR),
            otherwise: (schema) => schema.notRequired().strip(),
        }),
        phones: arrayYup()
            .of(
                objectYup().shape({
                    name: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
                    number: stringYup().required(FORM_FIELD_REQUIRED_ERROR).matches(phoneRegexp, {
                        message: FORMAT_ERROR,
                    }),
                })
            )

            .when("cmsTypeId", {
                is: HotelResidenceSlug.APARTMENT,
                then: (schema) => schema.required(FORM_FIELD_REQUIRED_ERROR).min(1, FORM_FIELD_REQUIRED_ERROR),
                otherwise: (schema) => schema.notRequired().strip(),
            }),
    });
};
