import {
    SimpleConfirmApplication,
    SimpleConfirmResponse,
    SimpleRegistrationResponse,
} from "../../containers/documents/offer/company-info/types/simple-registration";
import fetchRequest from "../manager";
import { partnerApiServices, russpassApiServices } from "../../constants/api";

const partnershipServicePath = russpassApiServices.partnership;
const applicationsPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.applications}`;
const documentsPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.documents}`;

// Получение значение переменной окружения simpleRegistration
export const getSimpleRegistration = async (): Promise<SimpleRegistrationResponse> => {
    const content = await fetchRequest.get(`${applicationsPartnerServiceUrl}/simpleRegistration`, null, {});
    return content;
};

// Согласование заявки
export const confirmApplication = async (
    applicationId: string,
    simpleRegistration: boolean
): Promise<SimpleConfirmApplication> => {
    const content = await fetchRequest.put(
        `${applicationsPartnerServiceUrl}/confirm/${applicationId}?simpleRegistration=${simpleRegistration}`,
        null,
        {}
    );
    return content;
};

// Согласование заявки сотрудниками Руководства
export const confirmApplicationByLeadership = async (applicationId: string): Promise<SimpleConfirmResponse> => {
    const content = await fetchRequest.put(
        `${applicationsPartnerServiceUrl}/confirmByLeadership/${applicationId}`,
        null,
        {}
    );
    return content;
};

// Согласование договора
export const confirmDocument = async (applicationId: string): Promise<SimpleConfirmResponse> => {
    const content = await fetchRequest.put(`${documentsPartnerServiceUrl}/confirm/${applicationId}`, null, {});
    return content;
};
