import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { Range } from "../types";
import { timetableOptions } from "../constants";
import { DateControlTabProps, DateControlValue } from "../types";
import { MultiSelectHidden, CheckboxCell } from "@russpass/partner-front-ui";

const WeekDaysSelect = forwardRef<any, DateControlTabProps>(({ value, disabledOptions, onSelect }, ref) => {
    const [selectedWeekDays, setSelectedWeekDays] = useState<DateControlValue>(value);

    const getSelectedOptions = () => {
        return value.reduce((prev: { [key: string]: boolean }, item: any) => {
            return {
                ...prev,
                [item.toLowerCase()]: true,
            };
        }, {});
    };

    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: boolean }>(getSelectedOptions());

    useImperativeHandle(
        ref,
        () => ({
            clearSelectedValue: () => {
                setSelectedOptions(getSelectedOptions());
                setSelectedWeekDays(value);
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleWeekDayClick = useCallback(
        (option) => {
            let newValues: DateControlValue = [...selectedWeekDays];
            if (!selectedOptions[option.value.toLowerCase()]) {
                newValues.push(option.value);
            } else {
                newValues = newValues.filter((day: string | Range) =>
                    typeof day === "string" ? day.toLowerCase() !== option.value.toLowerCase() : day !== option.value
                );
            }
            setSelectedWeekDays(newValues);
            setSelectedOptions((prev) => {
                return {
                    ...prev,
                    [option.value.toLowerCase()]: !prev[option.value.toLowerCase()],
                };
            });
            onSelect(newValues);
        },
        [selectedOptions, selectedWeekDays, onSelect]
    );

    const handleSelectChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const options = Array.from(e.target.options);
            const newValues = options.filter((option) => option.selected).map((option) => option.value.toLowerCase());
            let selectedOptions: { [key: string]: boolean } = {};

            options.forEach((option) => (selectedOptions[option.value.toLowerCase()] = option.selected));

            setSelectedOptions(selectedOptions);
            setSelectedWeekDays(newValues);
            onSelect(newValues);
        },
        [onSelect]
    );

    return (
        <>
            <div>
                {timetableOptions.map((option) => {
                    const isDisabled =
                        disabledOptions &&
                        disabledOptions?.indexOf(option.value) > -1 &&
                        value.indexOf(option.value) < 0;
                    return (
                        <CheckboxCell
                            key={option.value}
                            isDisabled={isDisabled}
                            isSelected={selectedOptions[option.value.toLowerCase()]}
                            label={option.label}
                            onClick={() => {
                                handleWeekDayClick(option);
                            }}
                            qaName={`weeekday-${option.value}`}
                        />
                    );
                })}
            </div>

            <MultiSelectHidden
                options={timetableOptions}
                onChange={handleSelectChange}
                dataQaName="weekday-select"
                optionKey={(option) => option.value}
                optionSelected={(option) => selectedOptions[option.value.toLowerCase()]}
                optionDisabled={(option) =>
                    (disabledOptions &&
                        disabledOptions?.indexOf(option.value) > -1 &&
                        value.indexOf(option.value) < 0) ??
                    false
                }
                optionValue={(option) => option.value}
                optionLabel={(option) => option.label}
                dataQaNameOption={(option) => `weekday-${option.value}`}
            />
        </>
    );
});

export default WeekDaysSelect;
