import { ReportItemType } from "../../types";
import ReportPeriod from "../report-period";

import styles from "./report-table.module.sass";

type ReportTableProps = {
    data: ReportItemType[];
};
const ReportTable: React.FC<ReportTableProps> = ({ data }) => {
    return (
        <div className={styles.report_content__table} data-qa-name="data-list">
            {data.length > 0 &&
                data.map((reportItem, idx) => (
                    <ReportPeriod reportPeriod={reportItem} key={`${reportItem.startDate}-${idx}`} />
                ))}
        </div>
    );
};

export default ReportTable;
