import { useCallback, useEffect, useState } from "react";
import { createHotel, getHotelInfo, sendForApprovalHotel } from "../../../../../api/hotels/hotels";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { COMMON_ERROR } from "../../../../../constants/errors";
import { useParams } from "react-router-dom";
import { HotelData } from "../types";
import { hotelData as hotelDataDefault } from "../constants";
import { loadPhotosByUrl, mappingHotelIntegrationData, mappingHotelIntegrationRequestData } from "../utils";
import history from "../../../../../history";
import routes from "../../../../../constants/routes";
import { getCitiesByParams, getRegionsByParams } from "../../../../../api/dictionaries";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

const useHotelIntegrationEditForm = () => {
    // @ts-ignore
    const { hotelId, partnerSystemId } = useParams();

    const [hotelData, setHotelData] = useState<HotelData>(hotelDataDefault);

    const [isLoadingForm, setIsLoadingForm] = useState(false);

    const loadData = useCallback(() => {
        setIsLoadingForm(true);
        let requestArray = [
            new Promise(async (resolve) => {
                try {
                    const hotelInfo = await getHotelInfo(partnerSystemId, "active");
                    setHotelData(mappingHotelIntegrationData(hotelInfo));
                    return resolve(hotelInfo);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: "Некорректно указан TravelLine id или не подключен канал RUSSPASS в TravelLine",
                    });
                }
            }),
        ];
        Promise.all(requestArray).then(() => {
            setIsLoadingForm(false);
        });
    }, [partnerSystemId]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeData = useCallback((values: any) => {
        setHotelData((prevState) => ({
            ...prevState,
            ...values,
        }));
    }, []);

    const onSubmit = useCallback(async () => {
        setIsLoadingForm(true);
        let _hotelData = {
            ...hotelData,
        };
        try {
            const regions = await getRegionsByParams({ travellineId: hotelData.regionExternalId || "" });
            const cities = await getCitiesByParams({ travellineId: hotelData.cityExternalId || "" });
            _hotelData.regionId = regions.rows[0] ? regions.rows[0].id : null;
            _hotelData.cityId = cities.rows[0] ? cities.rows[0].id : null;
            const requestRooms =
                _hotelData.rooms?.map(async (room, indexRoom) => {
                    return new Promise(async (resolve) => {
                        const roomImages = await loadPhotosByUrl(room.images);
                        if (_hotelData.rooms) {
                            //@ts-ignore
                            _hotelData.rooms[`${indexRoom}`].images = roomImages;
                        }
                        resolve(true);
                    });
                }) || [];
            Promise.all(requestRooms).then(async () => {
                try {
                    await createHotel(mappingHotelIntegrationRequestData(hotelId, _hotelData));
                    await sendForApprovalHotel(hotelId);
                    setIsLoadingForm(false);
                    history.push(routes.hotelsAll);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Объект отправлен на модерацию",
                        isAlwaysView: true,
                    });
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: COMMON_ERROR,
                    });
                    setIsLoadingForm(false);
                }
            });
        } catch (err) {
            console.error(err);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: COMMON_ERROR,
            });
            setIsLoadingForm(false);
        }
    }, [hotelData, hotelId]);

    return {
        isLoadingForm,
        onSubmit,
        hotelData,
        onChangeData,
    };
};

export default useHotelIntegrationEditForm;
