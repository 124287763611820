import { RentHousesBookingPreparedData } from "../types/types";
import { createRentHousesSupportAppeal } from "../../../../../../api/rentHousesSupport";
import { RentHousesSupportAppealCode } from "../../../../../../types/rentHousesSupport";

const rentHousesSupportSubmit = async (values: RentHousesBookingPreparedData) =>
    createRentHousesSupportAppeal({
        appealCode: RentHousesSupportAppealCode.BOOKING_TROUBLE,
        subject: "Проблема с бронированием",
        reason: values.reason,
        email: values.email,
        contactPerson: values.name,
        text: values.details[0],
        bookingId: values.bookingId,
        files: values.files,
    });

export default rentHousesSupportSubmit;
