import moment from "moment";
import { DOTTED_DATE_FORMAT, TIME_FORMAT } from "../constants/dates-format";

export const cropText = (text: string, length: number) => {
    if (text.length <= length) {
        return text;
    } else {
        return text.substring(0, length) + "...";
    }
};

export const combineString = (values: (string | null | undefined)[], separator: string = " ") => {
    return values.filter(Boolean).join(separator);
};

/*
   если date >= сегодня, то отображается только время
   если date вчера, то отображается "Вчера"
   иначе - отображается дата
*/
export const formatDate = (date: string, dateFormat?: string): string => {
    const momentDate = moment(date);
    const today = moment();
    const endOfYesterday = today.subtract(1, "day").endOf("day");
    if (momentDate > endOfYesterday) {
        return momentDate.format(TIME_FORMAT);
    }
    const startOfYesterday = endOfYesterday.subtract(1, "day");
    if (momentDate > startOfYesterday) {
        return "Вчера";
    }
    return momentDate.format(dateFormat || DOTTED_DATE_FORMAT);
};
