import { ModalWindowRight } from "@russpass/partner-front-ui";

import { ReactComponent as SuccessIcon } from "../../../../assets/images/alert/stiker-success.svg";
import { ReactComponent as ErrorIcon } from "../../../../assets/images/alert/stiker-error.svg";

import "./styles.sass";
import { CreateEventResultStatusEnum } from "./create-event-modal-enum";

type Props = {
    isOpen: boolean;
    type: CreateEventResultStatusEnum;
    title: string | React.ReactNode;
    desc: string | React.ReactNode;
    isLoading: boolean;
    errorBtnAction: () => void;
    onClose: () => void;
};

export const CreateEventResultModal = ({ isOpen, type, desc, errorBtnAction, isLoading, title, onClose }: Props) => {
    const isSuccess = type === CreateEventResultStatusEnum.Success;

    return (
        <ModalWindowRight isOpened={isOpen} isHideCloseButton isLoadingWithContent={isLoading}>
            <div className="content">
                <div className="content__icon">{isSuccess ? <SuccessIcon /> : <ErrorIcon />}</div>
                <div className="content__title">{title}</div>
                <div className="content__desc">
                    {desc || (
                        <>
                            {/* стандартный текст для ошибки */}
                            Повторите попытку или обратитесь в <span>Поддержку</span> для уточнения информации
                        </>
                    )}
                </div>

                <div className="content__btns">
                    {!isSuccess && (
                        <button type="button" className="button button--primary" onClick={errorBtnAction}>
                            Повторить
                        </button>
                    )}

                    <button type="button" className="button button--second" onClick={onClose}>
                        Закрыть
                    </button>
                </div>
            </div>
        </ModalWindowRight>
    );
};
