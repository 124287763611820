import { russpassApiServices } from "../constants/api";
import { FeedbackListResponse } from "../containers/feedback/types/FeedbackListResponse";
import { FeedbackModelResponse } from "../containers/feedback/types/FeedbackModelResponse";
import { FeedbackListRequest } from "../containers/feedback/types/FeedbackListRequest";
import { FeedbackStatus } from "../containers/feedback/constants";
import fetchRequest from "./manager";
import { FeedbackUpdateRequest } from "../containers/feedback/types/FeedbackUpdateRequest";
import { FeedbackEventListResponse } from "../containers/feedback/types/FeedbackEventListResponse";
import { FeedbackOrdersResponse } from "../containers/feedback/types/FeedbackOrdersResponse";

const feedbackServicePath = russpassApiServices.feedback;
const feedbackApiEventsUrl = `${feedbackServicePath}/events`;
const feedbackApiMessagesUrl = `${feedbackServicePath}/messages`;

export const getFeedbackCounter = async (status: FeedbackStatus): Promise<number> => {
    const response: FeedbackListResponse = await fetchRequest.get(
        feedbackApiMessagesUrl,
        { page: 1, pageSize: 1, status },
        {
            isProtected: true,
        }
    );

    return response?.data?.total || 0;
};

export const getFeedbackEvents = (): Promise<FeedbackEventListResponse> => {
    return fetchRequest.get(
        feedbackApiEventsUrl,
        {},
        {
            isProtected: true,
        }
    );
};

export const getFeedbackList = (params: FeedbackListRequest): Promise<FeedbackListResponse> => {
    return fetchRequest.get(feedbackApiMessagesUrl, params, {
        isProtected: true,
    });
};

export const getFeedback = (id: number): Promise<FeedbackModelResponse> => {
    return fetchRequest.get(
        `${feedbackApiMessagesUrl}/${id}`,
        {},
        {
            isProtected: true,
        }
    );
};

export const getFeedbackOrder = (id: number): Promise<FeedbackOrdersResponse> => {
    return fetchRequest.get(
        `${feedbackApiMessagesUrl}/${id}/order`,
        {},
        {
            isProtected: true,
        }
    );
};

export const updateFeedback = (id: number, info: FeedbackUpdateRequest): Promise<FeedbackModelResponse> => {
    return fetchRequest.put(`${feedbackApiMessagesUrl}/${id}`, info, {
        isProtected: true,
    });
};
