import { useRentHousesDictionary } from "../../../../../../../../api/hooks/rentHouses";
import { useEffect, useMemo } from "react";
import eventDispatcher from "../../../../../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { UNEXPECTED_ERROR } from "../../../../../../../../constants/errors";

export const useNewRoomDictionary = () => {
    const { data: dataRoomType, error: errorRoomType } = useRentHousesDictionary(
        { dictionary: "renthouse_room_types", pageSize: 999, sort: "dictionary_data.title" },
        { revalidateOnFocus: false }
    );

    useEffect(() => {
        if (!errorRoomType) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [errorRoomType]);

    const optionsRoomType = useMemo(
        () => dataRoomType?.data.items.map((el) => ({ value: el.id, label: el.dictionary_data?.title || "" })) || [],
        [dataRoomType]
    );

    const { data: dataBedType, error: errorBedType } = useRentHousesDictionary(
        {
            dictionary: "room_amenities_categories",
            slug: "bed_type",
        },
        { revalidateOnFocus: false, isDisabledRequest: !dataRoomType?.data.items[0].id }
    );

    useEffect(() => {
        if (!errorBedType) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [errorBedType]);

    const { data: dataBeds, error: errorBeds } = useRentHousesDictionary(
        {
            dictionary: "room_amenities",
            category: dataBedType?.data.items?.[0].id || "",
            useForRenthouses: true,
            pageSize: 999,
            sort: "dictionary_data.title",
        },
        { revalidateOnFocus: false, isDisabledRequest: !dataBedType?.data.items?.[0].id }
    );

    useEffect(() => {
        if (!errorBeds) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [errorBeds]);

    const optionsBeds = useMemo(
        () => dataBeds?.data.items.map((el) => ({ value: el.id, label: el.dictionary_data?.title || "" })) || [],
        [dataBeds]
    );

    return { optionsRoomType, optionsBeds };
};
