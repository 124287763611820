import { Divider, ModalCenter } from "@russpass/partner-front-ui";
import { EventPreviewModalProps } from "../EventPreviewModal";
import styles from "../EventPreviewModal/EventPreview/styles.module.sass";
import React from "react";
import DescriptionPreview from "../EventPreviewModal/EventPreview/DescriptionPreview";
import CheckList from "../EventPreviewModal/EventPreview/CheckList/CheckList";
import MapPreview from "../EventPreviewModal/EventPreview/MapPreview";
import HeadlinePreview from "../EventPreviewModal/EventPreview/HeadlinePreview";
import GalleryPreviewNew from "./GalleryPreviewNew";

export const EventPreviewModalNew = ({ data, isOpened, onClose }: EventPreviewModalProps) => {
    const {
        title,
        eventType,
        workingDate,
        address,
        facilityPlaceTitle,
        images,
        description,
        coordinates,
        ageRestriction,
        workingTime,
        checkList,
    } = data;

    return (
        <ModalCenter isOpened={isOpened} onClose={onClose} modalTitle="Режим предпросмотра">
            <div className={styles.event}>
                <HeadlinePreview
                    title={title}
                    eventType={eventType}
                    workingDate={workingDate}
                    address={address}
                    facilityPlaceTitle={facilityPlaceTitle}
                    ageRestriction={ageRestriction}
                    workingTime={workingTime}
                />
                <GalleryPreviewNew images={images} />
                <Divider className={styles.divider} fullWidth />
                <DescriptionPreview description={description} />
                {checkList && <CheckList {...checkList} />}
                {facilityPlaceTitle && address && coordinates && coordinates.length > 0 && (
                    <MapPreview facilityPlaceTitle={facilityPlaceTitle} address={address} coordinates={coordinates} />
                )}
            </div>
        </ModalCenter>
    );
};
