import { HotelTariffData, HotelTariffDataForRequest } from "../types/hotel-tariff";

export const mappingTariffDataForRequest = (tariff: HotelTariffData, supplierId: number) => {
    const tariffInfo: HotelTariffDataForRequest = {
        id: tariff.id,
        title: tariff.title,
        billingSupplierId: supplierId,
        foodType: {
            include: tariff.foodType.include,
            cmsId: tariff.foodType.cmsId,
        },
        cancellationPolicy: {
            freeCancellationPossible: tariff.cancellationPolicy.freeCancellationPossible,
        },
    };
    if (typeof tariff.cancellationPolicy?.freeCancellationDeadline !== "undefined") {
        tariffInfo.cancellationPolicy.freeCancellationDeadline = tariff.cancellationPolicy.freeCancellationDeadline;
    }
    if (typeof tariff.cancellationPolicy?.penaltyAmountFix !== "undefined") {
        tariffInfo.cancellationPolicy.penaltyAmountFix = tariff.cancellationPolicy.penaltyAmountFix;
    }
    if (typeof tariff.cancellationPolicy?.penaltyAmountPercent !== "undefined") {
        tariffInfo.cancellationPolicy.penaltyAmountPercent = tariff.cancellationPolicy.penaltyAmountPercent;
    }
    return tariffInfo;
};
