import { useCallback } from "react";
import eventDispatcher from "../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../constants/errors";
import { getAllTours } from "../../../api/tour";
import { packingEventsByStatus } from "./helper";
import { ListItem } from "./types";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

const useTours = () => {
    const loadTours = useCallback(async () => {
        try {
            const responseTours: ListItem[] = await getAllTours();

            const {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            } = packingEventsByStatus(responseTours);

            return {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            };
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
            return {
                draftServicesArray: [],
                moderationServicesArray: [],
                publishedServicesArray: [],
                refusedServicesArray: [],
                archiveServicesArray: [],
                allServicesArray: [],
            };
        }
    }, []);

    return {
        loadTours,
    };
};

export default useTours;
