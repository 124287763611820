import { ReactComponent as IcLink } from "../../../../../../assets/images/icons/ic_link2.svg";
import React, { useCallback, useState } from "react";
import classNames from "classnames";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import "./styles.sass";

type Props = {
    id: string;
};

const CopyLink = ({ id }: Props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onCopyId = useCallback(() => {
        navigator.clipboard.writeText(id).then(() => {
            setIsSubmitting(true);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Success,
                body: "id отеля скопирован",
            });
        });
    }, [id]);

    return (
        <>
            <button className={classNames("button", isSubmitting && "button--accent", "copy-link")} onClick={onCopyId}>
                {<IcLink className="button__icon button__icon--left" />}
                {isSubmitting ? "id отеля скопирован" : "Id отеля скопировать"}
            </button>
        </>
    );
};

export default CopyLink;
