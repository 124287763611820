import { yup } from "../../../../../yup";

export const saleAndCancelStepSchema = yup.object().shape({
    discount: yup
        .number()
        .nullable()
        .max(100, "Скидка не может быть больше 100%")
        .when("hasDiscount", { is: Boolean, then: (schema) => schema.required() }),
    rentFrom: yup
        .number()
        .nullable()
        .when("hasDiscount", { is: Boolean, then: (schema) => schema.required() }),
    cancellationPolicy: yup.string().required(),
});

export const saleAndCancelStepDraftSchema = yup.object().shape({
    discount: yup
        .number()
        .nullable()
        .max(100, "Скидка не может быть больше 100%")
        .when("hasDiscount", { is: Boolean, then: (schema) => schema.required() }),
    rentFrom: yup
        .number()
        .nullable()
        .when("hasDiscount", { is: Boolean, then: (schema) => schema.required() }),
});
