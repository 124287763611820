import { DiscountStatus } from "../types/hotel-discounts";

export const DiscountDeleteAlertTitle: Readonly<Partial<Record<DiscountStatus, string>>> = {
    [DiscountStatus.PUBLISHED]: "Вы уверены, что хотите завершить акцию досрочно?",
    [DiscountStatus.TO_PUBLISH]: "Вы уверены, что хотите снять акцию с модерации?",
    [DiscountStatus.DRAFT]: "Вы уверены, что хотите удалить черновик?",
};
export const DiscountDeleteAlertButton: Readonly<Partial<Record<DiscountStatus, string>>> = {
    [DiscountStatus.PUBLISHED]: "Завершить",
    [DiscountStatus.TO_PUBLISH]: "Снять с модерации",
};
