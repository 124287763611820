import { useCallback, useState } from "react";
import { getTariffs } from "../../../../api/billing";
import { Tariff, TariffListWithPagination } from "./types";
import { TariffList } from "./mock";

const useTariffs = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tariffs, setTariffs] = useState<TariffListWithPagination>(TariffList);

    const loadTariffList = useCallback(async (billingProductId?: number | string, page?: number, size?: number) => {
        if (billingProductId) {
            setIsLoading(true);
            let tariffs;
            do {
                tariffs = await getTariffs(billingProductId, page, 21);
                if (page) {
                    page = page - 1;
                }
            } while (page === undefined || page === -1 ? false : tariffs.content.length === 0);
            setTariffs(tariffs);
            setIsLoading(false);
        }
    }, []);

    const loadDraftTariffList = useCallback((draftTariffs: Tariff[]) => {
        setTariffs({
            ...TariffList,
            content: draftTariffs,
            size: draftTariffs.length,
        });
    }, []);

    const onChangeTariffList = useCallback((tariffs: Tariff[]) => {
        setTariffs((prev) => ({
            ...prev,
            content: tariffs,
        }));
    }, []);

    return {
        tariffs,
        loadTariffList,
        loadDraftTariffList,
        onChangeTariffList,
        isLoadingTariffList: isLoading,
    };
};

export default useTariffs;
