import { useState } from "react";

enum Sorting {
    CreationalDateDesc = "-creationalDate",
    CreationalDateAsc = "creationalDate",
    ValueDesc = "-value",
    ValueAsc = "value",
}

const options = [
    {
        label: "Сначала новые отзывы",
        value: Sorting.CreationalDateDesc,
    },
    {
        label: "Сначала старые отзывы",
        value: Sorting.CreationalDateAsc,
    },
    {
        label: "Сначала высокие отзывы",
        value: Sorting.ValueDesc,
    },
    {
        label: "Сначала низкие отзывы",
        value: Sorting.ValueAsc,
    },
];

export const useSortFilter = () => {
    const [activeOption, setActiveOption] = useState<string>(options[0].value);

    return {
        options,
        activeOption,
        setActiveOption,
    };
};
