import { useRentHousesSupportChatMessagesInfinite } from "../../../../../api/hooks/rentHousesSupport";
import { DIALOGUE_PAGE_SIZE, REFRESH_INTERVAL_MS } from "../constants";
import { Sorting } from "../../../../../constants/filters";
import { useCallback, useMemo } from "react";
import {
    RentHousesSupportChatMessage,
    RentHousesSupportChatMessageCreator,
} from "../../../../../types/rentHousesSupport";
import { orderBy, uniqBy } from "lodash";
import moment from "moment";
import { useRentHousesOwnerProfile } from "../../../../../api/hooks/rentHouses";
import { russpassApiUrl } from "../../../../../constants/api";

type Message = RentHousesSupportChatMessage & { firstInMonth?: boolean; avatar?: string };

const useSupportChatMessages = (isDisabled: boolean) => {
    const { data: ownerProfileResult } = useRentHousesOwnerProfile();

    const {
        data: messagesResults,
        size,
        setSize,
        mutate: refreshMessages,
        isLoading: isLoadingMessages,
    } = useRentHousesSupportChatMessagesInfinite(
        { pageSize: DIALOGUE_PAGE_SIZE, sort: Sorting.CreatedAtDesc },
        { isDisabledRequest: isDisabled, refreshInterval: REFRESH_INTERVAL_MS }
    );

    const hasMoreMessages = useMemo(() => {
        const total = messagesResults?.[0]?.data?.total || 0;
        if (!total) return false;

        const totalPages = Math.ceil(total / DIALOGUE_PAGE_SIZE);

        return size < totalPages;
    }, [messagesResults, size]);

    const messages = useMemo(() => {
        if (!messagesResults) return [];

        const messages = messagesResults.reduce<Message[]>(
            (messages, currentPage) => [...messages, ...currentPage.data.items],
            []
        );
        const uniqueMessages = uniqBy(messages, "id");
        const orderedMessages = orderBy(uniqueMessages, "createdAt", "asc");

        let latestDate = 0;

        orderedMessages.forEach((el) => {
            if (el.creator === RentHousesSupportChatMessageCreator.USER && ownerProfileResult?.data?.image) {
                el.avatar = `${russpassApiUrl}/partnership/files/images/${ownerProfileResult.data.image}`;
            }

            const theDate = moment(el.createdAt).startOf("D").unix();
            if (theDate === latestDate) {
                el.firstInMonth = false;
                return;
            }

            el.firstInMonth = true;
            latestDate = theDate;
        });

        return hasMoreMessages
            ? orderedMessages
            : [
                  {
                      id: "welcome",
                      creator: RentHousesSupportChatMessageCreator.ADMIN,
                      message: `Здравствуйте, ${
                          ownerProfileResult?.data?.name || "пользователь"
                      }! Напишите ваш вопрос, а мы постараемся ответить как можно скорее`,
                  } as Message,
                  ...orderedMessages,
              ];
    }, [hasMoreMessages, messagesResults, ownerProfileResult?.data]);

    const loadNextMessages = useCallback(() => {
        setSize((size) => size + 1);
    }, [setSize]);

    return {
        messages,
        isLoadingMessages,
        refreshMessages,
        loadNextMessages,
        hasMoreMessages,
    };
};

export default useSupportChatMessages;
