import { useCallback, useEffect } from "react";
import history from "../history";

type UseUnloadProps = {
    onBrowserBackClick: () => void;
    state?: { status: string };
};

const useUnload = ({ onBrowserBackClick, state }: UseUnloadProps) => {
    const onBackButtonEvent = useCallback(
        (e: PopStateEvent) => {
            e.preventDefault();

            history.push({
                pathname: window.location.pathname,
                state,
            });

            onBrowserBackClick();
        },
        [onBrowserBackClick, state]
    );

    useEffect(() => {
        history.push({
            pathname: window.location.pathname,
            state,
        });

        window.addEventListener("popstate", onBackButtonEvent);

        return () => {
            window.removeEventListener("popstate", onBackButtonEvent);
        };
    }, [onBackButtonEvent, state]);
};

export default useUnload;
