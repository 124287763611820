import styles from "./BookingAlert.module.sass";
import { ReactNode } from "react";
import { Alert, Severity } from "@russpass/partner-front-ui";

type Props = {
    severity?: Severity;
    info: { icon?: ReactNode; content: ReactNode };
};

export const BookingAlert = ({ info: { icon, content }, severity }: Props) => (
    <Alert className={styles.Alert} severity={severity}>
        {icon && <div className={styles.Icon}>{icon}</div>}

        <div className={styles.Content}>{content}</div>
    </Alert>
);
