import { useCallback } from "react";
import moment from "moment/moment";
import { ISO_DATE_FORMAT } from "../../../../../constants/dates-format";
import { RentHousesRate } from "../../../../../types/rentHouses";

export const useDatePrice = (rates: RentHousesRate[]) =>
    useCallback(
        (day: Date): number => {
            const datePrice = rates.find((rate) =>
                rate.dates?.find((date) =>
                    moment(day)
                        .startOf("day")
                        .isBetween(
                            moment(date.from, ISO_DATE_FORMAT).startOf("day"),
                            moment(date.to, ISO_DATE_FORMAT).endOf("day")
                        )
                )
            )?.price;

            if (datePrice) return datePrice;

            return (
                rates.find((el) =>
                    el.daysOfWeek?.find(
                        (dayOfWeek) => moment(day).locale("en").isoWeekday(dayOfWeek).day() === moment(day).day()
                    )
                )?.price || 0
            );
        },
        [rates]
    );
