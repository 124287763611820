import { useCallback, useEffect, useMemo, useState } from "react";
import { declensionOfNum } from "../../../../../utils/declension-number";
import { getStepStyles } from "../utils/common";
import useLoadData from "./useLoadData";
import { allStatusesHotel } from "../../../all/constants";
import { RatingsMarksList } from "../../../../services/components/ratings/types";
import { HOTEL_RATINGS_STEP_DESC_EMPTY } from "../constants/hotel-ratings";
import { useHotelDataContext } from "../../hotel-view/hotel-data-context";

const useControlHotelRatingsData = () => {
    const { hotelInfoData, isTravelLineHotel } = useHotelDataContext();
    const [isLoadingRatings, setIsLoadingRatings] = useState<boolean>(false);
    const [ratingMarks, setRatingMarks] = useState<RatingsMarksList | undefined>(undefined);

    const { onLoadRatings } = useLoadData();

    const hotelRatingsStepDesc = useMemo(() => {
        if (ratingMarks?.total) {
            return ratingMarks.total + " " + declensionOfNum(ratingMarks.total, ["отзыв", "отзыва", "отзывов"]);
        } else {
            return HOTEL_RATINGS_STEP_DESC_EMPTY;
        }
    }, [ratingMarks]);

    const loadRatings = useCallback(
        async (id) => {
            setIsLoadingRatings(true);
            try {
                const ratings = await onLoadRatings(id);
                setRatingMarks(ratings);
            } finally {
                setIsLoadingRatings(false);
            }
        },
        [onLoadRatings]
    );

    const hotelRatingsStepStyles = useMemo(() => getStepStyles(!!ratingMarks), [ratingMarks]);

    useEffect(() => {
        if (
            hotelInfoData &&
            (hotelInfoData.status === allStatusesHotel.active ||
                hotelInfoData.status === allStatusesHotel.inactive ||
                hotelInfoData.status === allStatusesHotel.deleted) &&
            !isTravelLineHotel
        ) {
            loadRatings(hotelInfoData.cmsId);
        }
    }, [hotelInfoData, loadRatings, isTravelLineHotel]);

    return {
        isValidRatings: !!ratingMarks && ratingMarks?.total > 0,
        isLoadingRatings,
        hotelRatingsStepDesc,
        hotelRatingsStepStyles,
    };
};

export default useControlHotelRatingsData;
