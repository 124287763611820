import { partnerApiServices, russpassApiServices } from "../constants/api";
import {
    ServiceDataCreated,
    ServiceDataGet,
    ServiceDataPatch,
    ServiceDataPost,
} from "../containers/services/service/helpers/types";
import fetchRequest from "./manager";

const serviceV2PartnerServiceUrl = `${russpassApiServices.partnership}${partnerApiServices.servicesV2}`;

export const getEventsAll = async () => {
    const data = await fetchRequest.get(serviceV2PartnerServiceUrl, null, {
        isProtected: true,
    });
    return data;
};

export const getService = async (id: string): Promise<ServiceDataGet> => {
    const service = await fetchRequest.get(`${serviceV2PartnerServiceUrl}/${id}`, null, {
        isProtected: true,
    });
    return service;
};

export const createService = async (data: ServiceDataPost): Promise<ServiceDataCreated> => {
    const service = await fetchRequest.post(serviceV2PartnerServiceUrl, data, {
        isProtected: true,
    });
    return service;
};

export const updateService = async (data: ServiceDataPatch): Promise<boolean> => {
    const service = await fetchRequest.patch(`${serviceV2PartnerServiceUrl}`, data, {
        isProtected: true,
    });
    return service;
};

export const sendServiceForApproval = async (id: string) => {
    const content = await fetchRequest.patch(`${serviceV2PartnerServiceUrl}/sendForApproval/${id} `, null, {
        isProtected: true,
    });
    return content;
};
