import { BaseFieldProps } from "./types";
import React, { useCallback, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { IAtolVat } from "../../../../../../types/billing";
import { getVatList } from "../../../../../../api/billing";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { StatusRequestEnum, SelectInputField } from "@russpass/partner-front-ui";
import { UNEXPECTED_ERROR } from "../../../../../../constants/errors";

export const NdsField = ({ name }: BaseFieldProps) => {
    const { setFieldValue, getFieldProps } = useFormikContext();

    const [vatList, setVatList] = useState<IAtolVat[]>([]);

    const loadData = useCallback(async () => {
        try {
            const content = await getVatList();
            setVatList(content);
        } catch (err) {
            console.error(err);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: UNEXPECTED_ERROR,
            });
        }
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const value = getFieldProps(name).value;

    return (
        <SelectInputField
            name={name}
            value={vatList.find((el) => el.code === value) || { code: "", caption: "" }}
            handleChange={(value) => {
                setFieldValue(name, value.code);
            }}
            options={vatList}
            valueField="code"
            labelField="caption"
            placeholder="Выбрать"
            label="НДС"
        />
    );
};
