import { navbarHotels } from "./constants";
import useHotels from "./useHotels";
import React, { SyntheticEvent, useCallback, useEffect, useMemo } from "react";
import { AnalyticEvent } from "../../../product_analytics/constants";
import { EmptyList, Banner, ClassNameBanner, ObjectList } from "@russpass/partner-front-ui";
import classNames from "classnames";
import history from "../../../history";
import routes from "../../../constants/routes";
import { getIsArchiveHotel } from "./utils";
import { useModalAlertContext, CommonLayout, ObjectItemType } from "@russpass/partner-front-ui";
import { isEnableMovingOldTL } from "../../../constants/feature-toggles";
import useUserInfo from "../../../hooks/useUserInfo";
import store from "../../../store";
import { setUserSettings } from "../../../store/userInfo";
import { sendEvent, sendEventByObjectItemClick } from "../../../product_analytics/analytics";

const HotelsAll = () => {
    const { isLoadingContent, hotelList, existingTLData, onClickAddHotel, activeTabKey, setActiveTabKey, isEmptyList } =
        useHotels();

    const { openModalAlert } = useModalAlertContext();

    const { userSettings } = useUserInfo();

    useEffect(() => {
        if (isLoadingContent) return;
        if (!isEnableMovingOldTL) return;
        if (!userSettings?.isHideMovingTLModalAlert && existingTLData.length) {
            const hasAlreadyMovingTL = existingTLData.some((existData) => {
                return existData.exist;
            });
            if (!hasAlreadyMovingTL) {
                openModalAlert(
                    {
                        title: "Обновление TravelLine",
                        description:
                            "Мы улучшили личный кабинет — теперь вы сможете добавлять более подробную информацию обо всех тарифах и номерах.\n" +
                            "Обновите текущую версию и заполните все разделы. Редактировать в старой версии TravelLine уже нельзя ",
                        OKButton: "Перейти к обновлению",
                    },
                    () => {
                        store.dispatch(
                            setUserSettings({
                                isHideMovingTLModalAlert: true,
                            })
                        );
                    }
                );
            }
        }
    }, [existingTLData, isLoadingContent, openModalAlert, userSettings]);

    useEffect(() => {
        activeTabKey === 5 && sendEvent(AnalyticEvent.moderation_failure_page);
    }, [activeTabKey]);

    const emptyTextSubtitle = useMemo(
        () => (activeTabKey === 2 ? "Здесь будут объекты, не отправленные на модерацию" : undefined),
        [activeTabKey]
    );

    const getActionButtonTL = useCallback(
        (item: ObjectItemType) => {
            if (!isEnableMovingOldTL) {
                return;
            }
            if (getIsArchiveHotel(item.data || {})) {
                return;
            }
            let index;
            const existingData = existingTLData.find((obj, key) => {
                if (obj.id === item.id) {
                    index = key;
                    return true;
                }
                return false;
            });
            if (existingData === undefined) {
                return;
            }

            const onClick = (event: SyntheticEvent) => {
                event.stopPropagation();
                history.push({
                    pathname: routes.hotelsView.replace(":hotelId", item.id),
                    state: {
                        isMoveOldTL: true,
                        status: activeTabKey,
                    },
                    search: `isMoveOldTL=true`,
                });
            };

            const onCloseBanner = () => {
                store.dispatch(
                    setUserSettings({
                        isHideMovingTLPrompt: true,
                    })
                );
            };
            return (
                <div className={"position-relative full-width"}>
                    <button
                        className={classNames(
                            "button button--full-width",
                            existingData.exist ? "button--second" : "button--primary"
                        )}
                        onClick={onClick}
                    >
                        {existingData.exist ? "Продолжить заполнение" : "Обновить версию TravelLine"}
                    </button>
                    {!userSettings?.isHideMovingTLPrompt && index === 0 && (
                        <Banner
                            body="Обновите информацию об отеле и внесите недостающие данные о номерах и тарифах"
                            className={ClassNameBanner.MiddleTop}
                            onClose={onCloseBanner}
                            style={{
                                position: "absolute",
                                top: "40px",
                                zIndex: 99,
                            }}
                        />
                    )}
                </div>
            );
        },
        [existingTLData, activeTabKey, userSettings]
    );

    const topRightButton = useMemo(
        () => ({
            title: "Добавить объект",
            onClick: onClickAddHotel,
            isAddButton: true,
        }),
        [onClickAddHotel]
    );

    return (
        <CommonLayout
            pageTitle="Объекты"
            isLoadingWithoutContent={isLoadingContent}
            navbar={navbarHotels}
            topRightButton={topRightButton}
            activeTabName={activeTabKey}
            handlerChangeActiveTab={setActiveTabKey}
        >
            {isEmptyList ? (
                <EmptyList onClick={onClickAddHotel} buttonClassName="button--primary" />
            ) : (
                hotelList.map(({ dataName, list }) => (
                    <div data-name={dataName} key={dataName}>
                        <ObjectList
                            list={list}
                            history={history}
                            emptyTextSubtitle={emptyTextSubtitle}
                            description={getActionButtonTL}
                            handleBeforeSelect={sendEventByObjectItemClick}
                        />
                    </div>
                ))
            )}
        </CommonLayout>
    );
};

export default HotelsAll;
