import { partnerApiServices, russpassApiServices } from "./../constants/api";
import fetchRequest from "./manager";

type getPartnersReportType = { startDate?: string; endDate?: string; applicationId: string };

const crmPartnerServiceUrl = `${russpassApiServices.partnership}${partnerApiServices.crm}`;

export const getPartnersReport = async ({
    startDate = "2000-01-01",
    endDate = "2100-01-01",
    applicationId,
}: getPartnersReportType) => {
    const report = await fetchRequest.get(
        `${crmPartnerServiceUrl}/partners/${applicationId}/documents/reportingPeriod?startDate=${startDate}&endDate=${endDate}`,
        null,
        {
            isProtected: true,
        }
    );
    return report;
};
