import { useState, useEffect } from "react";

// Для нескольких параметров
export default function useDebounceFilters<T>(values: Array<T>, delay: number) {
    const [debouncedChangedFilters, setDebouncedChangedFilters] = useState(0);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedChangedFilters((prev) => prev + 1);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [...values, delay]); // eslint-disable-line react-hooks/exhaustive-deps

    return debouncedChangedFilters;
}
