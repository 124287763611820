import { InputField, Checkbox, RadioGroupField } from "@russpass/partner-front-ui";
import { PENALTY_TYPE_FIX, PENALTY_TYPE_OPTIONS, PENALTY_TYPE_PERCENT } from "../../../constants/hotel-tariff";
import { HotelTariffData } from "../../../types/hotel-tariff";
import { useCallback } from "react";

import styles from "./hotel-tariff-reserv-cancel.module.sass";
import { maskedInputCustomNumber } from "../../../../../../../utils/validation";

type HotelTariffCancelReservProps = {
    values: HotelTariffData;
    onChange: (key: string, value: any) => void;
    errorSettings?: {
        showOnChange: boolean;
    };
    disabled?: boolean;
};

const HotelTariffCancelReserv = ({ values, onChange, errorSettings, disabled }: HotelTariffCancelReservProps) => {
    const handlePenaltyTypeChange = useCallback(
        (value) => {
            onChange("cancellationPolicy.penaltyType", value.id);
            if (value.id === PENALTY_TYPE_FIX.id) {
                onChange("cancellationPolicy.penaltyAmountPercent", undefined);
            }
            if (value.id === PENALTY_TYPE_PERCENT.id) {
                onChange("cancellationPolicy.penaltyAmountFix", undefined);
            }
        },
        [onChange]
    );

    const handlePenaltyFixChange = useCallback(
        (e) => {
            const value = e.target.value.split(" ");
            onChange("cancellationPolicy.penaltyAmountFix", value.length ? value[0] : "");
        },
        [onChange]
    );

    const handlePenaltyPercentChange = useCallback(
        (e) => {
            const value = e.target.value.split(" ");
            onChange("cancellationPolicy.penaltyAmountPercent", value.length ? value[0] : "");
        },
        [onChange]
    );

    const handlePenaltyDeadlineChange = useCallback(
        (checked) => {
            if (!checked) {
                onChange("cancellationPolicy.freeCancellationDeadline", undefined);
            }
            onChange("cancellationPolicy.freeCancellationPossible", checked);
        },
        [onChange]
    );
    return (
        <div className="content-wrapper__framed">
            <div className="section mb-24">Отмена бронирования</div>
            <div className={styles.contentReservCancelWrapper}>
                <RadioGroupField
                    name="cancellationPolicy.penaltyType"
                    value={
                        PENALTY_TYPE_OPTIONS.find((v) => v.id === values.cancellationPolicy.penaltyType) ||
                        PENALTY_TYPE_OPTIONS[0]
                    }
                    label="Штраф за отмену бронирования"
                    options={PENALTY_TYPE_OPTIONS}
                    valueField="id"
                    labelOptionField="name"
                    onChange={handlePenaltyTypeChange}
                    errorSettings={errorSettings}
                    disabled={disabled}
                />
                {values.cancellationPolicy.penaltyType === PENALTY_TYPE_FIX.id && (
                    <InputField
                        label="Штраф за отмену бронирования"
                        name="cancellationPolicy.penaltyAmountFix"
                        noHover
                        placeholder="1400 ₽"
                        errorSettings={errorSettings}
                        disabled={disabled}
                        renderComponent={maskedInputCustomNumber({
                            suffix: " ₽",
                        })}
                        onChange={handlePenaltyFixChange}
                        maxLength={8}
                    />
                )}

                {values.cancellationPolicy.penaltyType === PENALTY_TYPE_PERCENT.id && (
                    <InputField
                        label="Штраф за отмену бронирования"
                        name="cancellationPolicy.penaltyAmountPercent"
                        noHover
                        placeholder="10%"
                        errorSettings={errorSettings}
                        disabled={disabled}
                        renderComponent={maskedInputCustomNumber(
                            {
                                suffix: " %",
                            },
                            (value) => (Number(value.slice(0, -2)) <= 100 ? value : false)
                        )}
                        onChange={handlePenaltyPercentChange}
                    />
                )}

                <Checkbox
                    id="02"
                    label="Возможна бесплатная отмена бронирования"
                    handleChange={handlePenaltyDeadlineChange}
                    checked={values.cancellationPolicy.freeCancellationPossible}
                    disabled={disabled}
                />

                {values.cancellationPolicy.freeCancellationPossible && (
                    <InputField
                        label="Когда действует"
                        name="cancellationPolicy.freeCancellationDeadline"
                        noHover
                        placeholder="5"
                        hint="Укажите количество ночей до заезда, когда гость сможет отменить бронирование бесплатно"
                        errorSettings={errorSettings}
                        maxLength={5}
                        disabled={disabled}
                    />
                )}
            </div>
        </div>
    );
};
export default HotelTariffCancelReserv;
