import { eventDispatcher, StatusRequestEnum } from "@russpass/partner-front-ui";
import { FILE_TOO_LARGE, POST_DATA_ERROR2 } from "../constants/errors";
import { ErrorMaxPhoto, MAX_PHOTO_SIZE_Mb } from "../constants/images";

export const onFileTooLarge = () =>
    eventDispatcher.setNotification({
        status: StatusRequestEnum.Error,
        body: FILE_TOO_LARGE(MAX_PHOTO_SIZE_Mb),
    });

export const onPhotosLimit = () =>
    eventDispatcher.setNotification({
        status: StatusRequestEnum.Error,
        body: ErrorMaxPhoto,
    });

export const onError = () =>
    eventDispatcher.setNotification({
        status: StatusRequestEnum.Error,
        body: POST_DATA_ERROR2,
    });
