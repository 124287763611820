import { useCallback } from "react";
import { deleteRentHousesObject } from "../../../api/rentHouses";
import { StatusRequestEnum, useModalAlertContext, ObjectItemType } from "@russpass/partner-front-ui";
import eventDispatcher from "../../../lib/event-dispatcher";
import getCustomErrorMessage from "../../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../../constants/errors";
import { RentHousesObject } from "../../../types/rentHouses";
import { PaginatedListResponse } from "../../../types/api";
import { KeyedMutator } from "swr";

const modalAlertDetails = {
    title: "Вы уверены что хотите удалить объект?",
    OKButton: "Удалить",
    cancelButton: "Отменить",
};

export const useDeleteObject = (updateList: KeyedMutator<PaginatedListResponse<RentHousesObject>>) => {
    const { openModalAlert } = useModalAlertContext();

    const onDeleteRequest = useCallback(
        async (id: string) => {
            try {
                await deleteRentHousesObject(id);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: "Объект отправлен в Архив",
                });
                await updateList();
            } catch (e) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
                });
            }
        },
        [updateList]
    );

    const onDeleteObject = useCallback(
        async (item: ObjectItemType) => {
            openModalAlert(modalAlertDetails, () => onDeleteRequest(item.id));
        },
        [onDeleteRequest, openModalAlert]
    );
    return { onDeleteObject };
};
