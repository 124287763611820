import { createContext, useContext } from "react";
import { HotelTariffData } from "../types/hotel-tariff";

type HotelTariffsContextType = {
    tariffs: HotelTariffData[];
};
export const HotelTariffsContext = createContext<HotelTariffsContextType>({
    tariffs: [],
});

export const useHotelTariffsContext = () => {
    const context = useContext(HotelTariffsContext);

    if (!context) {
        throw new Error("useHotelTariffsContext must be used within a ModalInfoProvider");
    }

    return context;
};
