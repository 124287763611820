import styles from "./PopupMenu.module.sass";
import { Scrollbar } from "react-scrollbars-custom";
import React, { useRef, useCallback } from "react";
import { MenuProps } from "./types";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { useEventListener, SearchInput, RadioButton } from "@russpass/partner-front-ui";
import classNames from "classnames";

const PopupMenu = ({ isOpen, close, placeholder, options, searchText, setSearchText, value, setValue }: MenuProps) => {
    const ref = useRef(null);

    useOnClickOutside(ref, close);

    useEventListener("keydown", ({ key }: KeyboardEvent) => {
        if (key !== "Escape") return;
        close();
    });

    const handleSearch = useCallback((value) => setSearchText(value || ""), [setSearchText]);

    if (!isOpen) return null;

    return (
        <div className={styles.Wrapper}>
            <div
                className={classNames(styles.Menu, {
                    [styles.Empty]: !options.length,
                })}
                ref={ref}
            >
                <div className={styles.Search}>
                    <SearchInput
                        currentValue={searchText}
                        handleSearch={handleSearch}
                        autoFocus
                        placeholder={placeholder}
                    />
                </div>
                <div className={styles.List}>
                    <Scrollbar
                        noScrollX
                        translateContentSizesToHolder
                        contentProps={{
                            style: {
                                display: "block",
                            },
                        }}
                    >
                        {options.map((option) => (
                            <RadioButton
                                key={option.value}
                                label={option.label}
                                value={option.value}
                                isActive={option.value === value}
                                handleClick={() => setValue(option.value)}
                            />
                        ))}
                    </Scrollbar>
                </div>
            </div>
        </div>
    );
};

export default PopupMenu;
