import React, { useCallback } from "react";
import styles from "./hotel-info-phones.module.sass";
import { InputField } from "@russpass/partner-front-ui";
import { maskedInputPhone } from "../../../../../../../utils/validation";
import { ReactComponent as Trash } from "../../../../../../../assets/images/icons/ic_trash.svg";
import { ReactComponent as Plus } from "../../../../../../../assets/images/icons/ic_plus.svg";
import { HotelPhone } from "../../../types/hotel-info";
import { hotelPhone } from "../../../constants/hotel-info";
import { TextareaField } from "@russpass/partner-front-ui";

type HotelInfoPhonesProps = {
    phones: HotelPhone[];
    onChange: (values: any) => void;
    disabled?: boolean;
    errorSettings?: {
        showOnChange: boolean;
    };
};

const HotelInfoPhones = ({ phones, onChange, errorSettings, disabled }: HotelInfoPhonesProps) => {
    const addPhone = useCallback(() => {
        onChange({
            phones: [...phones, Object.assign({}, hotelPhone)],
        });
    }, [phones, onChange]);

    const deletePhone = useCallback(
        (index) => {
            let _phones: HotelPhone[] = [Object.assign({}, hotelPhone)];
            if (phones.length > 1) {
                _phones = phones.filter((phone, key) => key !== index);
            }
            onChange({
                phones: _phones,
            });
        },
        [phones, onChange]
    );

    return (
        <div className="content-wrapper__framed">
            <div className="section mb-8">Телефон</div>
            <div className={styles.hotel_info__phone__desc}>
                Добавьте номера, по которым туристы смогут связаться с объектом
            </div>
            {phones.map((phone, index) => (
                <div className={styles.hotel_info__phone} key={index}>
                    <InputField
                        label="Номер"
                        name={`phones[${index}].number`}
                        noHover
                        disabled={disabled}
                        placeholder="+ 7 (999) 999-99-99"
                        renderComponent={maskedInputPhone}
                        errorSettings={errorSettings}
                    />
                    <TextareaField
                        label="Подпись"
                        name={`phones[${index}].name`}
                        noHover
                        maxRows={3}
                        maxLength={60}
                        disabled={disabled}
                        placeholder="Администратор"
                        errorSettings={errorSettings}
                    />
                    {!disabled && phones.length !== 1 && (
                        <button type="button" onClick={() => deletePhone(index)}>
                            <Trash />
                        </button>
                    )}
                </div>
            ))}
            {!disabled && (
                <button className="button" type="button" onClick={addPhone}>
                    <div className="button__icon button__icon--left">
                        <Plus />
                    </div>
                    Добавить телефон
                </button>
            )}
        </div>
    );
};

export default HotelInfoPhones;
