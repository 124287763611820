import { useModalAlertContext, StatusRequestEnum } from "@russpass/partner-front-ui";
import { useCallback, useEffect, useState } from "react";
import { useFormik, useFormikContext } from "formik";
import { draftSchema } from "../validationSchema";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { ApartmentsNewStep } from "./useApartmentsNew";
import { AddApartmentImage } from "../types";
import { mapValuesToObjectInfo, redirectToApartmentsAll, savePhotos } from "../helpers";
import getCustomErrorMessage from "../../../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../../../constants/errors";
import { createRentHousesObject, updateRentHousesObject } from "../../../../api/rentHouses";
import { useLocation } from "react-router-dom";

export const useDraft = (step: ApartmentsNewStep, id?: string) => {
    const location = useLocation<{ status: string }>();

    const { openModalAlert } = useModalAlertContext();

    const state = useFormikContext();
    const { values, setErrors, setFormikState, submitCount } = state;

    const [showErrors, setShowErrors] = useState(false);

    const { errors, isValid } = useFormik({
        // @ts-ignore
        initialValues: values,
        validationSchema: draftSchema,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
    });

    useEffect(() => {
        if (!showErrors || submitCount !== 1) return;
        setErrors(errors);
    }, [errors, showErrors, setErrors, step, submitCount]);

    const saveDraft = useCallback(
        async (values: any) => {
            if (values.tariff?.rates) {
                values.tariff.rates = values.tariff.rates.filter(
                    (rate: { dates?: string[]; price?: number }) => rate?.dates && rate?.price
                );
            }

            let photos: AddApartmentImage[] = [];
            const result = await savePhotos(values);
            if (!result) return;
            photos = result;

            const objectInfo = mapValuesToObjectInfo({ ...values, photo: { photos } });

            try {
                if (!id) {
                    await createRentHousesObject(objectInfo);
                } else {
                    await updateRentHousesObject(id, objectInfo);
                }
                redirectToApartmentsAll(location);
            } catch (e) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
                });
                return;
            }
        },
        [id, location]
    );

    const saveAndExit = useCallback(() => {
        openModalAlert(
            {
                title: "Вы уверены, что хотите выйти?",
                description: "Остались незаполненные поля",
                OKButton: "Сохранить в Черновике",
                cancelButton: "Продолжить заполнение",
            },
            () => {
                if (!isValid) {
                    setFormikState({ ...state, submitCount: 1 });
                    setShowErrors(true);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: "Вы заполнили не все поля",
                    });
                    return;
                }
                saveDraft(values);
            }
        );
    }, [isValid, openModalAlert, saveDraft, setFormikState, state, values]);

    return {
        saveAndExit,
        errors: showErrors && submitCount === 1 ? errors : undefined,
    };
};
