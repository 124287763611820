import { array as arrayYup, object as objectYup, string as stringYup } from "yup";
import { FORM_FIELD_REQUIRED_ERROR } from "../../../../constants/errors";

export const getHotelValidation = objectYup().shape({
    title: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    description: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    address: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    email: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    phones: arrayYup()
        .of(
            objectYup().shape({
                name: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
                number: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
            })
        )
        .required(FORM_FIELD_REQUIRED_ERROR)
        .min(1, FORM_FIELD_REQUIRED_ERROR),
    services: arrayYup().test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
        let { commonServices, meals, beachServices, entertainmentServices, fitnessServices, facilityServices } =
            this.parent;
        return (
            commonServices.length ||
            meals.length ||
            beachServices.length ||
            entertainmentServices.length ||
            fitnessServices.length ||
            facilityServices.length
        );
    }),
});
