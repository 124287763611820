import React from "react";
import styles from "./styles.module.sass";
import { YaMap } from "@russpass/partner-front-ui";
import { yaMapKey } from "../../../../../../constants";
import { defaultLanguage } from "../../../../../../constants/language";

type MapPreviewProps = {
    facilityPlaceTitle: string;
    address: string;
    coordinates: number[];
};

const MapPreview = ({ facilityPlaceTitle, address, coordinates }: MapPreviewProps) => {
    const language = defaultLanguage;

    return (
        <div className={styles.container}>
            <div className={styles.location}>
                <div className={styles.location__title}>{facilityPlaceTitle}</div>
                <div className={styles.location__address}>{address}</div>
            </div>
            <YaMap
                height="100%"
                width="100%"
                key={language}
                lang={language}
                points={[
                    {
                        coordinates: coordinates,
                        type: "Point",
                    },
                ]}
                yaMapKey={yaMapKey || ""}
                placemarkType={"red"}
            />
        </div>
    );
};

export default MapPreview;
