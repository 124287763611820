import { NOT_FULLED_SECTION_ERROR, NOT_FULLED_SECTIONS_ERROR } from "../../../../constants/errors";
import { navbarExcursionLabels } from "./constants";

export const getErrorForm = (state: {
    isValidInfo: boolean;
    isValidPhoto: boolean;
    isValidProgram: boolean;
    isValidTariffs: boolean;
    isValidPlace: boolean;
}) => {
    let error = undefined;

    const countErrorForm = [
        state.isValidInfo,
        state.isValidPhoto,
        state.isValidProgram,
        state.isValidTariffs,
        state.isValidPlace,
    ].reduce((sum, current) => {
        if (!current) sum++;
        return sum;
    }, 0);

    if (countErrorForm > 1) {
        error = NOT_FULLED_SECTIONS_ERROR;
    }
    if (countErrorForm === 1) {
        error = NOT_FULLED_SECTION_ERROR;
        if (!state.isValidInfo) {
            error += ` ${navbarExcursionLabels.excursion}`;
        }
        if (!state.isValidPhoto) {
            error += ` ${navbarExcursionLabels.photos}`;
        }
        if (!state.isValidProgram) {
            error += ` ${navbarExcursionLabels.program}`;
        }
        if (!state.isValidTariffs) {
            error += ` ${navbarExcursionLabels.tariffs}`;
        }
        if (!state.isValidPlace) {
            error += ` ${navbarExcursionLabels.place}`;
        }
    }

    return error;
};
