import { useCallback } from "react";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../../../constants/errors";
import { useParams } from "react-router-dom";
import { getHotelResidenceTypes, getRoomAmenities, getRoomAmenitiesCategories } from "../../../../../api/dictionaries";
import { getRatingsMarks } from "../../../../../api/ratings";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { getIntegrationHotelRooms } from "../../../../../api/hotels/rooms";
import { getTariffsOfHotel } from "../../../../../api/hotels/tariffs";
import { HotelDiscountsData } from "../types/hotel-discounts";
import { getSpecialOffers } from "../../../../../api/specialOffers";

const useLoadData = () => {
    // @ts-ignore
    const { hotelId } = useParams();

    const onLoadTariffs = useCallback(async () => {
        try {
            const tariffsData = await getTariffsOfHotel(hotelId);
            return tariffsData;
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
        }
    }, [hotelId]);

    const onLoadRooms = useCallback(async () => {
        try {
            const content = await getIntegrationHotelRooms(hotelId);
            return content;
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
            return {
                rooms: [],
            };
        }
    }, [hotelId]);

    const onLoadAmenities = useCallback(async () => {
        let requestArray = [
            new Promise(async (resolve) => {
                try {
                    const content = await getRoomAmenitiesCategories();
                    resolve(content.rows);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const content = await getRoomAmenities();
                    return resolve(content.rows);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        ];
        return new Promise((resolve) => {
            Promise.all(requestArray).then((data) => {
                resolve({
                    categories: data[0],
                    amenities: data[1],
                });
            });
        });
    }, []);

    const onLoadRatings = useCallback(async (cmsId) => {
        try {
            const ratingMarkResponse = await getRatingsMarks(cmsId, { pageSize: 1 });
            return ratingMarkResponse.data;
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
        }
    }, []);

    const onLoadDiscounts = useCallback(
        async (
            cmsHotelId?: string,
            status?: string,
            pageSize?: number | string
        ): Promise<{ total: number; items: HotelDiscountsData[] } | undefined> => {
            if (!cmsHotelId) return;
            try {
                return (await getSpecialOffers(cmsHotelId, status, pageSize)).data;
            } catch (error) {
                console.error(error);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: GET_DATA_ERROR,
                });
            }
        },
        []
    );

    const loadHotelResidenceTypes = useCallback(async () => {
        return await getHotelResidenceTypes();
    }, []);

    return {
        onLoadTariffs,
        onLoadRooms,
        onLoadAmenities,
        onLoadRatings,
        onLoadDiscounts,
        loadHotelResidenceTypes,
    };
};

export default useLoadData;
