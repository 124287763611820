import { yup } from "../../../../../../yup";

export const validationSchema = yup.object().shape({
    message: yup
        .string()
        .trim()
        .when("answerFiles", {
            is: (files?: any[]) => !files?.length,
            then: (schema) => schema.required(),
        })
        .max(1500, "Ответ должен содержать не более 1500 символов"),
    answerFiles: yup.array().limitFilesSizeSum().limitFiles(),
});
