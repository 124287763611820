import { useState } from "react";
import moment from "moment";
import { capitalize } from "lodash";

const MONTHS = [...new Array(12)].map((_, idx) => ({
    label: capitalize(moment().month(idx).format("MMMM")),
    value: `${idx}`,
}));

export const useMonthsSelect = () => {
    const [activeMonth, setActiveMonth] = useState(`${moment().month()}`);

    return { months: MONTHS, activeMonth, setActiveMonth };
};
