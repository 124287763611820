import { FC, useEffect, useState } from "react";
import PageLoader from "../page-loader";
import { TypeLogger } from "../../enums/type-logger";
import { useLogger } from "../../hooks/useLogger";
import signOut from "../../lib/sign-out";
import KeycloakManager from "../../lib/keycloak-manager";
import { debounceCallFunc } from "@russpass/partner-front-ui";
import { useSetErrorNotification } from "./hooks/useSetErrorNotification";
import {
    ERROR_NOTIFICATION_TITLE_NO_VALID_EMAIL,
    ERROR_NOTIFICATION_TITLE_RESPONSE,
    ERROR_NOTIFICATION_TITLE_USER_NOT_FOUND,
} from "./constants/constants";
import { isNotFound } from "../../utils/errors";
import useLoadUserInfo from "../../hooks/useLoadUserInfo";

export const UserInfoProvider: FC = ({ children }) => {
    const [isLoadingUserData, setIsLoadingUserData] = useState<boolean>(true);

    const { logger, isLoggerMode } = useLogger();
    const { setNotification } = useSetErrorNotification();
    const { loadUserInfo } = useLoadUserInfo();

    useEffect(() => {
        const parsedToken: any = KeycloakManager.getParsedAccessToken();
        let timeoutId: NodeJS.Timeout;

        if (parsedToken?.email) {
            loadUserInfo(parsedToken?.email, false)
                .then(() => {
                    setIsLoadingUserData(false);
                })
                .catch((err) => {
                    if (isNotFound(err)) {
                        setNotification(ERROR_NOTIFICATION_TITLE_USER_NOT_FOUND);
                    } else {
                        setNotification(ERROR_NOTIFICATION_TITLE_RESPONSE);
                    }

                    if (!isLoggerMode) {
                        timeoutId = debounceCallFunc(() => signOut(window.location.href), 6000);
                    }

                    logger(err, TypeLogger.Error);
                });
        } else {
            setNotification(ERROR_NOTIFICATION_TITLE_NO_VALID_EMAIL);

            if (!isLoggerMode) {
                timeoutId = debounceCallFunc(() => signOut(window.location.href), 6000);
            }
        }
        return () => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoadingUserData) {
        return <PageLoader />;
    }

    return <>{children}</>;
};
