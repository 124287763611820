import React, { useEffect } from "react";

import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "../constants/routes";

import ServicesAll from "../containers/services/all";
import ViewService from "../containers/services/service";
import Support from "../containers/support";
import ProtectedRoute from "./protected";
import Profile from "../containers/profile";
import ViewExcursion from "../containers/services/excursion";
import ViewTour from "../containers/services/tour";
import AddServicePage from "../containers/services/add-service-page";
import { matchPath } from "react-router";
import history from "../history";
import CompanyInfo from "../containers/documents/offer/company-info";
import Offer from "../containers/documents/offer/offer-view";
import HotelsAll from "../containers/hotels/all";
import HotelIntEditForm from "../containers/hotels/add-hotel-page/old-form/hotel-integration";
import Orders from "../containers/orders";
import Analytics from "../containers/analytics";
import HotelNew from "../containers/hotels/hotel-new";
import HotelRoomsList from "../containers/hotels/add-hotel-page/new-form/components/hotel-rooms/hotel-rooms-list";
import HotelTariffs from "../containers/hotels/add-hotel-page/new-form/components/hotel-tariffs-list";
import HotelRatingsList from "../containers/hotels/add-hotel-page/new-form/components/hotel-ratings/hotel-ratings-list";
import HotelView from "../containers/hotels/add-hotel-page/hotel-view";
import Feedback from "../containers/feedback";
import { navbarDocumentsNames } from "../containers/documents/constants";
import Report from "../containers/documents/report";
import Contracts from "../containers/documents/offer";
import Restaurant from "../containers/restaurants/restaurant";
import RestaurantsAll from "../containers/restaurants/all";
import Booking from "../containers/restaurants/booking";
import HotelDiscounts from "../containers/hotels/add-hotel-page/new-form/components/hotel-discounts";
import ReceivedNotifications from "../containers/notifications/received";
import NotificationsSettings from "../containers/notifications/settings";
import HotelRatingsListV2 from "../containers/hotels/add-hotel-page/new-form/components/hotel-ratings/hotel-ratings-list-v2";
import ApartmentsAll from "../containers/apartments/all";
import ApartmentsNew from "../containers/apartments/new";
import Dialogues from "../containers/apartments/dialogues/list";
import ApartmentsBooking from "../containers/apartments/booking";
import { isHotelNotificationsEnabled, isRatingV2 } from "../constants/feature-toggles";

const Router = () => {
    const location = useLocation();
    // @ts-ignore
    const background = location?.state?.background;

    useEffect(() => {
        if (!background) {
            if (
                matchPath(location.pathname, {
                    path: routes.offer,
                    exact: false,
                    strict: false,
                })
            ) {
                history.replace(routes.documents);
                history.push({
                    pathname: location.pathname,
                    state: {
                        background: {
                            pathname: routes.documents,
                            search: navbarDocumentsNames.contracts,
                        },
                    },
                });
            }
            if (
                matchPath(location.pathname, {
                    path: routes.hotelsNew,
                    exact: false,
                    strict: false,
                })
            ) {
                history.replace(routes.hotelsAll);
                history.push({
                    pathname: location.pathname,
                    state: {
                        background: {
                            pathname: routes.hotelsAll,
                        },
                    },
                });
            }
            if (
                matchPath(location.pathname, {
                    path: routes.partnerInfo,
                    exact: false,
                    strict: false,
                })
            ) {
                history.replace(routes.documents);
            }
        }
    }, [location.pathname, background]);

    return (
        <>
            <Switch location={background || location}>
                <ProtectedRoute path={routes.hotels} exact>
                    <Redirect to={routes.hotelsAll} />
                </ProtectedRoute>
                <ProtectedRoute path={routes.hotelsAll} sensitive component={HotelsAll} />

                {/*Отели из CMS и BNOVO*/}
                <ProtectedRoute path={routes.hotelsView} exact sensitive component={HotelView} />
                <ProtectedRoute path={routes.hotelsViewRooms} exact sensitive component={HotelRoomsList} />
                <ProtectedRoute path={routes.hotelsViewTariffs} sensitive component={HotelTariffs} />

                {isRatingV2 ? (
                    <ProtectedRoute path={routes.hotelsViewRatings} sensitive component={HotelRatingsListV2} />
                ) : (
                    <ProtectedRoute path={routes.hotelsViewRatings} sensitive component={HotelRatingsList} />
                )}

                <ProtectedRoute path={routes.hotelsViewDiscounts} sensitive component={HotelDiscounts} />

                {/*Отели из TL*/}
                <ProtectedRoute path={routes.intHotelsView} exact sensitive component={HotelIntEditForm} />

                {/*Рестораны*/}
                <ProtectedRoute path={routes.restaurantNew} exact sensitive component={Restaurant} />
                <ProtectedRoute path={routes.restaurantView} exact sensitive component={Restaurant} />
                <ProtectedRoute path={routes.restaurantAll} exact sensitive component={RestaurantsAll} />
                <ProtectedRoute path={routes.bookingAll} exact sensitive component={Booking} />
                <ProtectedRoute path={routes.servicesAll} sensitive component={ServicesAll} />
                <ProtectedRoute path={routes.servicesNew} sensitive component={ViewService} />
                <ProtectedRoute path={routes.servicesView} sensitive component={ViewService} />
                <ProtectedRoute path={routes.excursionsNew} sensitive component={ViewExcursion} />
                <ProtectedRoute path={routes.excursionsView} sensitive component={ViewExcursion} />
                <ProtectedRoute path={routes.draftExcursionsView} sensitive component={ViewExcursion} />
                <ProtectedRoute path={routes.toursNew} sensitive component={ViewTour} />
                <ProtectedRoute path={routes.toursView} sensitive component={ViewTour} />
                <ProtectedRoute path={routes.services} exact>
                    <Redirect to={routes.servicesAll} />
                </ProtectedRoute>
                <ProtectedRoute path={routes.servicesAdd} sensitive component={AddServicePage} />
                <ProtectedRoute path={routes.support} sensitive component={Support} />
                <ProtectedRoute path={routes.profile} sensitive component={Profile} />
                <ProtectedRoute path={routes.documents} exact>
                    <Redirect to={routes.reports} />
                </ProtectedRoute>
                <ProtectedRoute path={routes.reports} exact sensitive component={Report} />
                <ProtectedRoute path={routes.contracts} exact sensitive component={Contracts} />
                <ProtectedRoute path={routes.analytics} exact sensitive component={Analytics} />
                <ProtectedRoute path={routes.orders} sensitive component={Orders} />
                <ProtectedRoute path={routes.dialogues} sensitive component={Dialogues} />
                <ProtectedRoute path={routes.feedback} sensitive component={Feedback} />

                {isHotelNotificationsEnabled && (
                    <ProtectedRoute
                        path={routes.notificationsSettings}
                        exact
                        sensitive
                        component={NotificationsSettings}
                    />
                )}
                {isHotelNotificationsEnabled && (
                    <ProtectedRoute path={routes.notifications} sensitive component={ReceivedNotifications} />
                )}

                <ProtectedRoute path={routes.apartments} exact sensitive component={RestaurantsAll} />
                <ProtectedRoute path={routes.apartmentsAll} exact sensitive component={ApartmentsAll} />
                <ProtectedRoute path={routes.apartmentsNew} exact sensitive component={ApartmentsNew} />
                <ProtectedRoute path={routes.apartmentsView} exact sensitive component={ApartmentsNew} />
                <ProtectedRoute path={routes.apartmentsBookingAll} exact sensitive component={ApartmentsBooking} />

                <ProtectedRoute path={routes.main} />
                <Route path="*">
                    <Redirect to={routes.main} />
                </Route>
            </Switch>

            {background && (
                <Switch>
                    <ProtectedRoute path={routes.offer} exact sensitive component={Offer} />
                    <ProtectedRoute path={routes.partnerInfo} exact sensitive component={CompanyInfo} />
                    <ProtectedRoute path={routes.hotelsNew} exact sensitive component={HotelNew} />
                </Switch>
            )}
        </>
    );
};

export default Router;
