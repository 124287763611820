import { PaginatedListResponse } from "./api";

export enum RentHousesSupportChatStatus {
    NEW_MESSAGE = "NEW_MESSAGE",
    READ = "READ",
}

export type RentHousesSupportChat = {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    userStatus: RentHousesSupportChatStatus;
    adminStatus: RentHousesSupportChatStatus;
    lastMessageCreatedAt?: string;
};

export enum RentHousesSupportChatInitiator {
    CLIENT = "CLIENT",
    PARTNER = "PARTNER",
}

export enum RentHousesSupportChatMessageCreator {
    USER = "USER",
    ADMIN = "ADMIN",
}

export type RentHousesSupportChatFile = { id: string; attachId: string; size: number; filename: string };

export type RentHousesSupportChatMessage = {
    id: string;
    createdAt: string;
    updatedAd: string;
    userStatus?: RentHousesSupportChatStatus;
    adminStatus?: RentHousesSupportChatStatus;
    creator: RentHousesSupportChatMessageCreator;
    message?: string;
    files?: RentHousesSupportChatFile[];
};

export type RentHousesSupportChatResponse = PaginatedListResponse<
    RentHousesSupportChatMessage,
    {
        chat?: RentHousesSupportChat;
    }
>;

export enum RentHousesSupportAppealCode {
    BOOKING_CANCELLATION = "BOOKING_CANCELLATION",
    BOOKING_TROUBLE = "BOOKING_TROUBLE",
}
