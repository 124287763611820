import { russpassApiUrl } from "./api";

// /partnership
const GRAVITEE_API_KEY_PARTNERSHIP = process.env.REACT_APP_GRAVITEE_API_KEY_PARTNERSHIP ?? "";
// /attach
const GRAVITEE_API_KEY_ATTACH = process.env.REACT_APP_GRAVITEE_API_KEY_ATTACH ?? "";
// /billingapi
const GRAVITEE_API_KEY_BILLING = process.env.REACT_APP_GRAVITEE_API_KEY_BILLING ?? "";
// /billingapi
const GRAVITEE_API_KEY_NOTIFICATION = process.env.REACT_APP_GRAVITEE_API_KEY_NOTIFICATION ?? "";
// /jirasd
const GRAVITEE_API_KEY_JSD = process.env.REACT_APP_GRAVITEE_API_KEY_JSD ?? "";
// /rating
const GRAVITEE_API_KEY_RATING = process.env.REACT_APP_GRAVITEE_API_KEY_RATING ?? "";
// /feedback
const GRAVITEE_API_KEY_FEEDBACK = process.env.REACT_APP_GRAVITEE_API_KEY_FEEDBACK ?? "";

export const graviteeApiKeys = Object.freeze({
    partnership: GRAVITEE_API_KEY_PARTNERSHIP,
    attach: GRAVITEE_API_KEY_ATTACH,
    billingapi: GRAVITEE_API_KEY_BILLING,
    notification: GRAVITEE_API_KEY_NOTIFICATION,
    jirasd: GRAVITEE_API_KEY_JSD,
    rating: GRAVITEE_API_KEY_RATING,
    feedback: GRAVITEE_API_KEY_FEEDBACK,
});

export const graviteeApiList = [russpassApiUrl];

export type GraviteeApiKeyType = keyof typeof graviteeApiKeys;
