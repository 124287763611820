import { Sorting } from "../../../constants/filters";

export enum BOOKING_STATUSES {
    new = "NEW",
    confirmed = "CONFIRMED",
    rejected = "REJECTED",
}

export enum RESOLUTION_STATUSES {
    CONFIRMED = "CONFIRMED",
    REJECTED = "REJECTED",
}

export const navbarBookingNames = {
    new: "new",
    answered: "answered",
};

export const navbarBooking = [
    {
        label: "Новые",
        name: navbarBookingNames.new,
    },
    {
        label: "Отвеченные",
        name: navbarBookingNames.answered,
    },
];

export const sortingNewOptions = [
    {
        label: "Сначала новые заявки",
        value: Sorting.CreateDateDesc,
    },
    {
        label: "Сначала старые заявки",
        value: Sorting.CreateDateAsc,
    },
];

export const sortingOptions = [
    {
        label: "Подтвержденные заявки",
        value: BOOKING_STATUSES.confirmed,
    },
    {
        label: "Отклоненные заявки",
        value: BOOKING_STATUSES.rejected,
    },
];

export const PAGE_SIZE = 10;

export const newBookingFilter = {
    cmsId: "",
    sorting: Sorting.CreateDateDesc,
    page: 0,
    status: BOOKING_STATUSES.new,
};

export const bookingFilter = {
    cmsId: "",
    sorting: Sorting.CreateDateDesc,
    page: 0,
    status: BOOKING_STATUSES.confirmed,
};
