import { useCallback, useEffect, useMemo } from "react";
import { HotelItemCms } from "../../../hotels/all/types";
import { getNotificationHotels } from "../../../../api/notficator";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import getCustomErrorMessage from "../../../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../../../constants/errors";
import useSafeState from "../../../../hooks/useSafeState";
import { HotelStatuses } from "../../../../enums/hotels";

const useHotelOptions = (cmsHotels: HotelItemCms[], disabled?: boolean) => {
    const [connectedHotelIds, setConnectedHotelIds] = useSafeState<string[]>([]);

    const loadDisabledOptions = useCallback(async () => {
        try {
            if (disabled) {
                setConnectedHotelIds([]);
                return;
            }

            const ids = (await getNotificationHotels()).data.hotels || [];
            setConnectedHotelIds(ids);
        } catch (e) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
            });
            console.error(e);
        }
    }, [disabled, setConnectedHotelIds]);

    useEffect(() => {
        loadDisabledOptions();
    }, [loadDisabledOptions]);

    return useMemo(
        () =>
            cmsHotels
                .filter((el) => el.status === HotelStatuses.Active && !connectedHotelIds.includes(el.cmsId))
                .map((el) => ({
                    id: el.cmsId,
                    label: el.title,
                })) || [],
        [cmsHotels, connectedHotelIds]
    );
};

export default useHotelOptions;
