import { useCallback, useEffect, useState } from "react";
import { navbarTypeEventNames } from "./constants";
import { SortedServiceList, PromiseSettings } from "./types";
import useDebounce from "../../../hooks/useDebounce";
import cancellablePromise from "../../../hooks/useCanceledPromise";
import useEvents from "./useEvents";
import useExcursions from "./useExcursions";
import useTours from "./useTours";
import { concatServicesData, getNavbarTypeServices } from "./helper";
import { useLocation } from "react-router-dom";
import { ActiveFilterCategory, ObjectItemType } from "@russpass/partner-front-ui";
import useUserInfo from "../../../hooks/useUserInfo";
import { isAvailableTours } from "../../../constants/feature-toggles";

const useAll = () => {
    const { isGuide } = useUserInfo();

    const location = useLocation<{ status?: string; type: number } | null>();
    const [activeTabKey, setActiveTabKey] = useState<string | number | undefined>(location.state?.status);

    const [activeCategory, setActiveCategory] = useState<ActiveFilterCategory>(() => {
        const defaultCategory = !isGuide ? getNavbarTypeServices()[0] : getNavbarTypeServices()[2];

        if (!location.state?.type) return defaultCategory;

        const category = getNavbarTypeServices().find((item) => item.name === location.state?.type);
        return category || defaultCategory;
    });
    const debouncedActiveCategory = useDebounce(activeCategory, 1000);

    const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);

    const [allServicesList, setAllServicesList] = useState<ObjectItemType[]>([]);
    const [draftServicesList, setDraftServicesList] = useState<ObjectItemType[]>([]);
    const [moderationServicesList, setModerationServicesList] = useState<ObjectItemType[]>([]);
    const [publishedServicesList, setPublishedEventssList] = useState<ObjectItemType[]>([]);
    const [refusedServicesList, setRefusedServicesList] = useState<ObjectItemType[]>([]);
    const [archiveServicesList, setArchiveServicesList] = useState<ObjectItemType[]>([]);

    const { loadEvents } = useEvents();

    const { loadExcursions } = useExcursions();

    const { loadTours } = useTours();

    const setAllServices = useCallback(
        ({
            draftServicesArray,
            moderationServicesArray,
            publishedServicesArray,
            refusedServicesArray,
            archiveServicesArray,
            allServicesArray,
        }: SortedServiceList) => {
            setDraftServicesList(draftServicesArray);
            setModerationServicesList(moderationServicesArray);
            setPublishedEventssList(publishedServicesArray);
            setRefusedServicesList(refusedServicesArray);
            setArchiveServicesList(archiveServicesArray);
            setAllServicesList(allServicesArray);
        },
        []
    );

    const [promiseSettings, setPromiseSettings] = useState<PromiseSettings>();

    const debounceChangeFilter = useCallback(
        async (value: ActiveFilterCategory) => {
            if (promiseSettings) {
                promiseSettings.cancel();
            }
            setIsLoadingContent(true);
            const newPromise = new Promise(async (resolve) => {
                let _data: SortedServiceList | undefined;

                if (value.name === navbarTypeEventNames.events) {
                    _data = await loadEvents();
                } else if (value.name === navbarTypeEventNames.excursions) {
                    _data = await loadExcursions();
                } else if (value.name === navbarTypeEventNames.tours) {
                    _data = await loadTours();
                } else {
                    const eventsData = await loadEvents();
                    const excursionsData = await loadExcursions();
                    const toursData = isAvailableTours ? await loadTours() : ({} as SortedServiceList);

                    _data = concatServicesData(eventsData, excursionsData, toursData);
                }
                resolve(_data);
            });
            const _promiseSettings: PromiseSettings = cancellablePromise(newPromise);
            setPromiseSettings(_promiseSettings);
            _promiseSettings.promise.then((data) => {
                setAllServices(data as SortedServiceList);
                setIsLoadingContent(false);
            });
        },
        [promiseSettings, setAllServices, loadExcursions, loadEvents, loadTours]
    );

    const refreshData = useCallback(() => {
        debounceChangeFilter(debouncedActiveCategory);
    }, [debouncedActiveCategory]);

    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedActiveCategory]);

    const handlerChangeFilter = useCallback((value: ActiveFilterCategory) => {
        setActiveCategory(value);
    }, []);

    return {
        isLoadingContent,
        activeCategory,
        handlerChangeFilter,
        allServicesList,
        draftServicesList,
        moderationServicesList,
        publishedServicesList,
        refusedServicesList,
        archiveServicesList,
        activeTabKey,
        setActiveTabKey,
        isGuide,
        setIsLoadingContent,
        refreshData,
    };
};

export default useAll;
