export const cookieNames = {
    token: "auth-token",
    refreshToken: "refresh-token",
    dateTokenExpired: "expired",
    authorized: "authorized",
};

export const localStorageItems = {
    userInfo: "user-info",
    userSettings: "user-settings",
    userFullInfo: "user-full-info",
    apartmentsRejectedLastViewDate: "apartments-rejected-last-view-date",
};
