import React from "react";
import styles from "./styles.module.sass";
import HeadlinePreview from "./HeadlinePreview";
import GalleryPreview from "./GalleryPreview";
import { Divider } from "@russpass/partner-front-ui";
import DescriptionPreview from "./DescriptionPreview";
import MapPreview from "./MapPreview";
import { EventPreviewDataType } from "..";
import CheckList from "./CheckList/CheckList";

type EventPreviewProps = {
    data: EventPreviewDataType;
};

const EventPreview = ({ data }: EventPreviewProps) => {
    const {
        title,
        eventType,
        workingDate,
        address,
        facilityPlaceTitle,
        images,
        description,
        coordinates,
        ageRestriction,
        workingTime,
        checkList,
    } = data;
    return (
        <div className={styles.event}>
            <HeadlinePreview
                title={title}
                eventType={eventType}
                workingDate={workingDate}
                address={address}
                facilityPlaceTitle={facilityPlaceTitle}
                ageRestriction={ageRestriction}
                workingTime={workingTime}
            />
            <GalleryPreview images={images} />
            <Divider className={styles.divider} fullWidth />
            <DescriptionPreview description={description} />
            {checkList && <CheckList {...checkList} />}
            {facilityPlaceTitle && address && coordinates && coordinates.length > 0 && (
                <MapPreview facilityPlaceTitle={facilityPlaceTitle} address={address} coordinates={coordinates} />
            )}
        </div>
    );
};

export default EventPreview;
