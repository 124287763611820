import React, { useEffect, useMemo } from "react";
import { BaseFieldProps } from "../../../../../../../types/fields";
import { getIn, useFormikContext } from "formik";
import { useRentHousesDictionary } from "../../../../../../../api/hooks/rentHouses";
import eventDispatcher from "../../../../../../../lib/event-dispatcher";
import { UNEXPECTED_ERROR } from "../../../../../../../constants/errors";
import { useViewportContext, StatusRequestEnum, MultiCheckedField } from "@russpass/partner-front-ui";
import styles from "./home-rule-direction-field.module.sass";

type Option = { value: string; label: string };
type Props = BaseFieldProps & { dictionary: string };
export const HomeRuleDirectionField = ({ name, disabled, label, placeholder, dictionary }: Props) => {
    const { isMobile } = useViewportContext();
    const { setFieldValue, values } = useFormikContext();

    const value = useMemo(() => getIn(values, name), [name, values]);

    const { data, error } = useRentHousesDictionary(
        { dictionary: dictionary, pageSize: 999, sort: "dictionary_data.title" },
        { revalidateOnFocus: false }
    );

    useEffect(() => {
        if (!error) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [error]);

    const options = useMemo(
        () => data?.data.items.map((el) => ({ value: el.id, label: el.dictionary_data?.title || "" })) || [],
        [data]
    );

    const checkedOptions = useMemo(
        () =>
            (value?.map((el: any) => options.find((option) => option.value === el)).filter(Boolean) as Option[]) || [],
        [value, options]
    );

    const displayedValue = useMemo(() => {
        return (
            <div className={styles.wrapper}>
                {checkedOptions.map(({ label }, indx) => (
                    <div className={styles.item} key={indx}>
                        {label}
                    </div>
                ))}
            </div>
        );
    }, [checkedOptions]);

    return (
        <MultiCheckedField
            name={name}
            label={label}
            placeholder={placeholder}
            value={checkedOptions}
            options={options}
            onChange={(value) => setFieldValue(name, value?.map((el: Option) => el.value) || [])}
            disabled={disabled}
            renderComponent={checkedOptions.length ? displayedValue : undefined}
            modalTitle={isMobile ? label : undefined}
            noHover
        />
    );
};
