import styles from "./SendModerationButton.module.sass";
import classNames from "classnames";
import { useFormikContext } from "formik";

const SendModerationButton = () => {
    let { isValid, dirty } = useFormikContext();

    if (!dirty) return null;
    return (
        <div className={classNames("form-item", styles.Wrapper)}>
            <button
                className={classNames("button button--full-width", {
                    "button--primary": isValid,
                    "button--second": !isValid,
                })}
            >
                Отправить на модерацию
            </button>
        </div>
    );
};

export default SendModerationButton;
