import classNames from "classnames";
import { Filter, FilterProps } from "@russpass/partner-front-ui";
import { ReactComponent as ArrowDown } from "../../../assets/images/icons/ic_chevron_down.svg";
import styles from "./SelectFilter.module.sass";
import { useCallback } from "react";

type Props = Pick<FilterProps, "options" | "value" | "onChange">;

export const SelectFilter = (props: Props) => {
    const arrowIconRender = useCallback(
        (isOpen) => <ArrowDown className={classNames(styles.ArrowSvg, { [styles.ArrowSvgUp]: isOpen })} />,
        []
    );

    return <Filter {...props} isArrow arrowIconRender={arrowIconRender} modalTitle="Сортировка" />;
};
