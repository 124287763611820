import {
    HotelTariffConditionData,
    HotelTariffData,
} from "../../containers/hotels/add-hotel-page/new-form/types/hotel-tariff";
import fetchRequest from "../manager";
import { partnerApiServices, russpassApiServices } from "../../constants/api";

const integrationPartnerServiceUrl = `${russpassApiServices.partnership}${partnerApiServices.integration}`;

export const createTariffHotels = async (hotelId: string, tariffData: HotelTariffData) => {
    const content = await fetchRequest.post(`${integrationPartnerServiceUrl}/hotels/${hotelId}/rates`, tariffData, {
        isProtected: true,
    });
    return content;
};

export const getTariffsOfHotel = async (hotelId: string) => {
    const response = await fetchRequest.get(`${integrationPartnerServiceUrl}/hotels/${hotelId}/rates`, null, {
        isProtected: true,
    });
    return response;
};

// Get hotel tariff info
export const getHotelTariffInfo = async (hotelId: string, rateId: string): Promise<HotelTariffConditionData> => {
    const content = await fetchRequest.get(`${integrationPartnerServiceUrl}/hotels/${hotelId}/rates/${rateId}`, null, {
        isProtected: true,
    });
    return content;
};

// Delete tariff
export const deleteTariff = async (hotelId: string, rateId: string): Promise<any> => {
    const content = await fetchRequest.delete(`${integrationPartnerServiceUrl}/hotels/${hotelId}/rates/${rateId}`, {
        isProtected: true,
    });
    return content;
};
