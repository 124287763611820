import { InputField, TextareaField } from "@russpass/partner-front-ui";
import { FieldArray, FormikProps } from "formik";
import { ReactComponent as Plus } from "../../../../../../../assets/images/icons/ic_plus.svg";
import { ReactComponent as Trash } from "../../../../../../../assets/images/icons/ic_trash.svg";
import { maskedInputPhone, maskedInputTime } from "../../../../../../../utils/validation";
import type { HotelRoomFormData } from "../../../types/hotel-room";
import styles from "./general-info.module.sass";

const RoomGeneralInfo = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h3 className={styles.title}>Общая информация</h3>
                <p className={styles.description}>
                    Если время заезда/выезда и контакты отличаются от данных основного объекта, отредактируйте их
                </p>
            </div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <InputField
                        label="Время заезда"
                        name="arrivalTime"
                        placeholder="14:00"
                        renderComponent={maskedInputTime}
                        noHover
                    />
                </div>
                <div className="hotel-info__col">
                    <InputField
                        label="Время выезда"
                        name="departureTime"
                        placeholder="12:00"
                        renderComponent={maskedInputTime}
                        noHover
                    />
                </div>
            </div>
            <InputField label="E-mail" name="email" noHover placeholder="name@mail.ru" />
            <FieldArray
                name="phones"
                render={({ form, remove, push }) => {
                    const { values } = form as FormikProps<HotelRoomFormData>;
                    const phones = Array.isArray(values.phones) ? values.phones : [];

                    return (
                        <div>
                            <ul className={styles.phoneList}>
                                {phones.map((_phone, index) => (
                                    <li key={`phones.${index}`}>
                                        <div className={styles.phoneRow}>
                                            <InputField
                                                label="Номер"
                                                name={`phones.${index}.number`}
                                                noHover
                                                placeholder="+ 7 (999) 999-99-99"
                                                renderComponent={maskedInputPhone}
                                            />
                                            <TextareaField
                                                label="Подпись"
                                                name={`phones.${index}.name`}
                                                noHover
                                                maxRows={3}
                                                maxLength={60}
                                                placeholder="Администратор"
                                                className={"text-field__textCursor"}
                                            />
                                            {phones.length !== 1 && (
                                                <button type="button" onClick={() => remove(index)}>
                                                    <Trash />
                                                </button>
                                            )}
                                        </div>

                                        {phones.length !== index + 1 && <hr className={styles.phoneRowDivider} />}
                                    </li>
                                ))}
                            </ul>
                            <button className="button" type="button" onClick={() => push({ number: "", name: "" })}>
                                <div className="button__icon button__icon--left">
                                    <Plus />
                                </div>
                                Добавить телефон
                            </button>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default RoomGeneralInfo;
