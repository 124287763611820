//Округляет то 2 знаков после запятой и добавляет символ ₽
export const addCurrencyString = (number: number) => {
    const intlOptions = {
        style: "currency",
        currency: "RUB",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    };
    if (typeof number !== "number") {
        number = 0;
    }
    if (number > 0) {
        intlOptions.minimumFractionDigits = 2;
        intlOptions.maximumFractionDigits = 2;
    }
    const price = new Intl.NumberFormat("ru-RU", intlOptions).format(number);
    return `${price}`.replace(",", ".");
};
//Конкатенирует кол-во и сумму
export const joinCountAndSumString = (count: number, sum: string) => {
    if (typeof count !== "number") {
        count = 0;
    }
    return `${count} шт. на ${sum}`;
};
