import { getImageById } from "../../../api/files";
import routes from "../../../constants/routes";
import { ChannelManagerCodeEnum } from "../../../enums/channel-manager-code";
import { hotelsCategoryLabel, navbarHotelsNames, statusLabelsHotels } from "./constants";
import { CheckHotelExistRequestData, HotelItemCms, HotelItemOther, HotelsDataResponse } from "./types";
import {
    getIsArchiveHotel,
    getIsDraftHotel,
    getIsModerationHotel,
    getIsPublishedHotel,
    getIsRefusedHotel,
    getNavNameStatus,
} from "./utils";
import { QuestionTooltip, ObjectItemType, ObjectItemClassNameStatus } from "@russpass/partner-front-ui";
import { checkHotelExist } from "../../../api/hotels/hotels";

const generateHotelLink = (id: string) => {
    return `${routes.hotels}/view/${id}`;
};

const generateIntegrationHotelLink = (hotelItem: HotelItemOther) => {
    switch (hotelItem.partnerSystemType) {
        case ChannelManagerCodeEnum.TravelLine:
            return routes.intHotelsView
                .replace(":partnerSystemId", hotelItem.partnerSystemId)
                .replace(":hotelId", hotelItem.id);
        case ChannelManagerCodeEnum.Bnovo:
        case ChannelManagerCodeEnum.NewTravelLine:
            return routes.hotelsView.replace(":hotelId", hotelItem.id);
        default:
            return "";
    }
};

export const packingHotelsByStatus = (dataFromServer: HotelsDataResponse) => {
    const allHotelsArray: ObjectItemType[] = [];
    const draftHotelsArray: ObjectItemType[] = [];
    const moderationHotelsArray: ObjectItemType[] = [];
    const publishedHotelsArray: ObjectItemType[] = [];
    const refusedHotelsArray: ObjectItemType[] = [];
    const archiveHotelsArray: ObjectItemType[] = [];

    if (dataFromServer.cms && dataFromServer.cms.length) {
        dataFromServer.cms.forEach((hotelItem: HotelItemCms) => {
            const status = getNavNameStatus(hotelItem);
            const pathname = generateHotelLink(hotelItem.id);
            const newItem: ObjectItemType = {
                id: hotelItem.id,
                name: hotelItem.title,
                imgUrl: hotelItem.imageExplorePreview.length
                    ? getImageById(hotelItem.imageExplorePreview[0])
                    : undefined,
                link: {
                    pathname,
                    state: {
                        status,
                    },
                },
                category: hotelsCategoryLabel.hotel,
                data: hotelItem,
            };
            if (getIsRefusedHotel(hotelItem)) {
                newItem.statusLabel = statusLabelsHotels.refused;
                newItem.classNameStatusLabel = ObjectItemClassNameStatus.refused;
                refusedHotelsArray.push(newItem);
            }
            if (getIsArchiveHotel(hotelItem)) {
                newItem.statusLabel = statusLabelsHotels.archive;
                newItem.classNameStatusLabel = ObjectItemClassNameStatus.archive;
                archiveHotelsArray.push(newItem);
            }
            if (getIsDraftHotel(hotelItem)) {
                newItem.statusLabel = (
                    <>
                        {statusLabelsHotels.draft}
                        <QuestionTooltip body="Внесите все данные и отправьте объект на модерацию" />
                    </>
                );
                newItem.classNameStatusLabel = ObjectItemClassNameStatus.draft;
                draftHotelsArray.push(newItem);
            }
            if (getIsModerationHotel(hotelItem)) {
                newItem.statusLabel = statusLabelsHotels.moderation;
                newItem.classNameStatusLabel = ObjectItemClassNameStatus.moderation;
                moderationHotelsArray.push(newItem);
            }
            if (getIsPublishedHotel(hotelItem)) {
                newItem.statusLabel = statusLabelsHotels.published;
                newItem.classNameStatusLabel = ObjectItemClassNameStatus.published;
                publishedHotelsArray.push(newItem);
            }
            allHotelsArray.push({
                ...newItem,
                link: {
                    pathname,
                    state: { status: navbarHotelsNames.all },
                },
            });
        });
    }
    if (dataFromServer.integration && dataFromServer.integration.length) {
        dataFromServer.integration.forEach((hotelItem: HotelItemOther) => {
            const pathname = generateIntegrationHotelLink(hotelItem);
            const newItem: ObjectItemType = {
                id: hotelItem.id,
                name: hotelItem.name,
                imgUrl: undefined,
                link: {
                    pathname,
                    state: {
                        status: navbarHotelsNames.draft,
                    },
                },
                statusLabel: (
                    <>
                        {statusLabelsHotels.draft}
                        <QuestionTooltip body="Внесите все данные и отправьте объект на модерацию" />
                    </>
                ),
                category: hotelsCategoryLabel.hotel,
                classNameStatusLabel: ObjectItemClassNameStatus.draft,
                data: hotelItem,
            };
            draftHotelsArray.push(newItem);
            allHotelsArray.push({
                ...newItem,
                link: {
                    pathname,
                    state: { status: navbarHotelsNames.all },
                },
            });
        });
    }

    return {
        draftHotelsArray,
        moderationHotelsArray,
        publishedHotelsArray,
        refusedHotelsArray,
        archiveHotelsArray,
        allHotelsArray,
    };
};

export const getExistingTLHotels = async (hotels: HotelsDataResponse) => {
    const hotelsTLIds: CheckHotelExistRequestData = hotels.cms
        .filter((hotel) => {
            const isArchiveHotel = getIsArchiveHotel(hotel);
            return hotel.partnerSystemType === ChannelManagerCodeEnum.TravelLine && !isArchiveHotel;
        })
        .map((hotel) => ({ id: hotel.id }));
    if (hotelsTLIds.length) {
        const checkExist = await checkHotelExist(hotelsTLIds);
        return checkExist;
    }
    return [];
};
