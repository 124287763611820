import { russpassApiUrl } from "../../constants/api";
import CustomError from "../../lib/custom-error";
import { defaultLanguage } from "../../constants/language";
import signOut from "../../lib/sign-out";
import KeycloakManager from "../../lib/keycloak-manager";
import { getGraviteeApiKey } from "../../utils/get-gravitee-api-key";
import uuid from "react-native-uuid";

// Формирование headers
const getFetchHeaders = (url: string, paramsData: any) => {
    const { isProtected, host = russpassApiUrl, isNotJSONResponse, withoutRqid, ...params } = paramsData;

    const headers = {
        ...params.headers,
        "Content-Language": defaultLanguage,
    };

    if (isProtected) {
        const token = KeycloakManager.getAccessToken();
        headers.Authorization = `Bearer ${token || ""}`;
    }

    if (!withoutRqid) {
        headers.rqid = uuid.v4();
    }

    const graviteeApiKey = getGraviteeApiKey(host, url);

    if (graviteeApiKey) {
        headers["X-Gravitee-Api-Key"] = graviteeApiKey;
    }

    return headers;
};

// Обработчик успешного ответа fetch
const handlerSuccessFetch = async (response: any, paramsData: any) => {
    const { isNotJSONResponse } = paramsData;

    if (response.status === 204) {
        return null;
    }
    if (isNotJSONResponse) {
        return response;
    }
    try {
        // Если в body лежит не json, то при response.text() мы получим ошибку, поэтому на свякий случай клонируем ответ сервера
        return await response.clone().json();
    } catch {
        console.warn("Cannot parse response as json");
        return await response.text();
    }
};

// Обработчик не успешного ответа fetch
const handlerErrorFetch = async (response: any, paramsData: any) => {
    const { withRedirect401 = true } = paramsData;

    if (response.status === 503) {
        throw new Error("Server is under maintenance");
    } else if (response.status >= 400) {
        if (withRedirect401 && response.status === 401) {
            return signOut();
        }
        let resp;
        try {
            resp = await response.text();
            // строго говоря вообще сервер не обязан отдавать какие-то описания в json
            // поэтому сильно и не расчитываем на это
            resp = JSON.parse(resp);
            resp.status = response.status;
        } catch (err) {}
        if (typeof resp == "string") {
            throw new CustomError({
                message: resp || `Unhadled error. Server status code ${response.status}`,
                status: response.status,
            });
        }
        throw new CustomError({
            resp,
            status: response.status,
        });
    }
};

/**
 * Основной метод, который отсылает запросы
 * @param url - ручка
 * @param paramsData - данные запроса
 */
export async function fetchWithTokenRequest(url: string, paramsData: any): Promise<any> {
    const { host = russpassApiUrl, ...params } = paramsData;

    const headers = getFetchHeaders(url, paramsData);
    const response = await window.fetch(`${host}${url}`, {
        ...params,
        headers,
    });

    if (response.ok) {
        return await handlerSuccessFetch(response, paramsData);
    }
    return await handlerErrorFetch(response, paramsData);
}
