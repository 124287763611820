import { ReactComponent as SortIcon } from "../../../../assets/images/icons/ic_filter.svg";
import styles from "./orders-filters.module.sass";
import { MultiMenu, RadioButton } from "@russpass/partner-front-ui";
import { sortOrdersOptions } from "../../constants";
import React, { useMemo } from "react";
import { useOrderFiltersContext } from "../../context/useOrderFiltersContext";

const OrdersSorter = () => {
    const { sort, setSort } = useOrderFiltersContext();
    const renderComponent = useMemo(() => {
        return (
            <button className="button button--second">
                <SortIcon className="button__icon--left" />
                {(sortOrdersOptions.find((option) => option.value === sort.value) || sortOrdersOptions[0]).label}
            </button>
        );
    }, [sort]);

    const changeSort = (value: string) => {
        const newSort = sortOrdersOptions.find((option) => option.value === value);
        if (!newSort) return;
        setSort(newSort);
    };

    return (
        <div className={styles.orders_filters__kind}>
            <MultiMenu noHover renderComponent={renderComponent} hideArrow>
                {sortOrdersOptions.map((option) => (
                    <RadioButton
                        key={`sorterOption-${option.value}`}
                        label={option.label}
                        value={option.value}
                        isActive={option.value === sort.value}
                        handleClick={changeSort}
                    />
                ))}
            </MultiMenu>
        </div>
    );
};

export default OrdersSorter;
