import { partnerApiServices, russpassApiServices } from "../../constants/api";
import { BankAccountData, Offer } from "../../containers/documents/offer/company-info/types";
import fetchRequest from "../manager";

const partnershipServicePath = russpassApiServices.partnership;
const crmPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.crm}`;
const documentsPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.documents}`;

export const getOffer = async (partnerId: string) => {
    const { body } = await fetchRequest.get(`${crmPartnerServiceUrl}/partners/${partnerId}/offers`, null, {
        isProtected: true,
        isNotJSONResponse: true,
    });
    return body;
};

export const savePartnerDocs = async (applicationId: string, file: File, type: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    const content = await fetchRequest.file(`${crmPartnerServiceUrl}/partners/${applicationId}/documents`, formData, {
        isProtected: true,
    });
    return content;
};

export const savePartnerInfo = async (applicationId: string, data: Offer) => {
    const content = await fetchRequest.patch(`${documentsPartnerServiceUrl}/contract/${applicationId}`, data, {
        isProtected: true,
    });
    return content;
};

export const signOfferRequest = async (applicationId: string) => {
    const content = await fetchRequest.patch(`${crmPartnerServiceUrl}/partners/${applicationId}/offers`, null, {
        isProtected: true,
    });
    return content;
};

export const validateBankData = async (data: BankAccountData) => {
    const content = await fetchRequest.post(`${russpassApiServices.partnership}/validation/account`, data, {
        isProtected: true,
    });
    return content;
};
