import { ColorActiveTab, HeaderTab } from "@russpass/partner-front-ui";

export const initialTour = {
    title: "",
    slug: "",
    description: "",
    included: "",
    paidSeparately: "",
    city: "",
    nights: undefined,
    days: undefined,
    minAge: "",
    price: "",
    minGroupCount: "",
    seasonStart: "",
    seasonEnd: "",
    route: [],
    images: [],
    imageDetailedPageMain: [],
    imageExplorePreview: [],
    region: "",
    isCanBuy: false,
};

export const initialTourEvent = {
    title: "",
    images: [],
    geoData: {
        coordinates: [],
    },
};

export const navbarTourNames = {
    comment: "comment",
    tour: "tour",
    tariffs: "rates",
    program: "program",
    photos: "photos",
    ratings: "reviews",
};

export const navbarTourLabels = {
    comment: "Комментарий модератора",
    tour: "Тур",
    tariffs: "Тарифы",
    program: "Программа",
    photos: "Фото",
    ratings: "Отзывы",
};

export const navbarTour: HeaderTab[] = [
    {
        label: navbarTourLabels.tour,
        name: navbarTourNames.tour,
    },
    {
        label: navbarTourLabels.tariffs,
        name: navbarTourNames.tariffs,
    },
    {
        label: navbarTourLabels.program,
        name: navbarTourNames.program,
    },
    {
        label: navbarTourLabels.photos,
        name: navbarTourNames.photos,
    },
];

export const navbarTourComment: HeaderTab[] = [
    {
        label: navbarTourLabels.comment,
        name: navbarTourNames.comment,
        colorActiveTab: ColorActiveTab.Secondary,
    },
];

export const navbarTourRatings = [
    {
        label: navbarTourLabels.ratings,
        name: navbarTourNames.ratings,
    },
];

export const ErrorEmptyPhotos = "Добавьте фотографию";
export const ErrorEmptyTariffs = "Добавьте тариф";
