import { Form, Formik, FormikProps } from "formik";
import { AnswerRequestData, Booking } from "../types";
import {
    ModalWindowRight,
    TextareaField,
    useViewportContext,
    StatusRequestEnum,
    Message,
    ActionButtonWrapper,
} from "@russpass/partner-front-ui";
import moment from "moment";
import {
    DOTTED_SHORT_DATE_FORMAT,
    EXTENDED_DATE_TIME_FORMAT,
    SHORT_DAY_MONTH_TIME_FORMAT,
    TIME_FORMAT,
} from "../../../../constants/dates-format";
import React, { useCallback, useEffect, useState } from "react";
import { declensionOfNum } from "../../../../utils/declension-number";
import { RESOLUTION_STATUSES } from "../constants";
import { acceptReservation } from "../../../../api/restaurants/booking";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { POST_DATA_ERROR } from "../../../../constants/errors";
import { combineString } from "../../../../utils/text-util";
import classNames from "classnames";
import { sendEvent } from "../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../product_analytics/constants";

type Props = {
    selectedBooking: Booking | null;
    isViewDetails: boolean;
    onCloseDetails: () => void;
    refresh: () => void;
    disabled?: boolean;
};

const BookingDetails = ({ isViewDetails, selectedBooking, onCloseDetails, disabled, refresh }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { isMobile } = useViewportContext();

    useEffect(() => {
        sendEvent(AnalyticEvent.request_page);
    }, []);

    const onAccept = useCallback(
        async (id: string, data: AnswerRequestData) => {
            if (data.resolution === RESOLUTION_STATUSES.CONFIRMED) {
                sendEvent(AnalyticEvent.confirm_click);
            } else if (data.resolution === RESOLUTION_STATUSES.REJECTED) {
                sendEvent(AnalyticEvent.reject_click);
            }
            setIsLoading(true);
            try {
                await acceptReservation(id, data);
                onCloseDetails();
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    isAlwaysView: true,
                    body:
                        data.resolution === RESOLUTION_STATUSES.CONFIRMED
                            ? "Заявка подтверждена и перемещена в раздел Отвеченные"
                            : "Заявка отклонена и перемещена в раздел Отвеченные",
                });
                refresh();
            } catch (err) {
                console.error(err);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: POST_DATA_ERROR,
                });
            } finally {
                setIsLoading(false);
            }
        },
        [onCloseDetails, refresh]
    );

    return (
        <Formik initialValues={selectedBooking || {}} enableReinitialize validateOnMount onSubmit={onCloseDetails}>
            {({ values }: FormikProps<Booking | null>) => {
                return (
                    <ModalWindowRight
                        isOpened={isViewDetails}
                        onClose={onCloseDetails}
                        isLoadingWithContent={isLoading}
                        headerComponent={
                            <div className="modal-window-right__header_with_name">
                                <div className="modal-window-right__header_with_name--title">
                                    {selectedBooking?.object.name}
                                </div>
                                <div className="modal-window-right__header_with_name--name">
                                    {"Заявка от " +
                                        moment(selectedBooking?.createDate, EXTENDED_DATE_TIME_FORMAT).format(
                                            SHORT_DAY_MONTH_TIME_FORMAT
                                        )}
                                </div>
                            </div>
                        }
                        ComponentFooter={
                            !disabled && (
                                <ActionButtonWrapper>
                                    <button
                                        onClick={() =>
                                            onAccept(values?.id || "", {
                                                answer: values?.answer,
                                                resolution: RESOLUTION_STATUSES.CONFIRMED,
                                            })
                                        }
                                        className={classNames("button button--full-width", "button--primary")}
                                    >
                                        {"Подтвердить"}
                                    </button>
                                    <button
                                        onClick={() =>
                                            onAccept(values?.id || "", {
                                                answer: values?.answer,
                                                resolution: RESOLUTION_STATUSES.REJECTED,
                                            })
                                        }
                                        className={classNames("button button--full-width", "button--second")}
                                    >
                                        {"Отклонить"}
                                    </button>
                                </ActionButtonWrapper>
                            )
                        }
                    >
                        <Form className={isMobile ? "px-24" : ""}>
                            <div className="content-wrapper mt-16">
                                <div className="content-wrapper__form">
                                    <Message
                                        label={
                                            selectedBooking?.author &&
                                            "Заявка от " +
                                                (selectedBooking?.author?.lastName ?? "") +
                                                " " +
                                                (selectedBooking?.author?.firstName ?? "")
                                        }
                                        value={combineString(
                                            [
                                                `${moment(
                                                    selectedBooking?.reservationDate,
                                                    EXTENDED_DATE_TIME_FORMAT
                                                ).format(DOTTED_SHORT_DATE_FORMAT)} в ${moment(
                                                    selectedBooking?.reservationDate,
                                                    EXTENDED_DATE_TIME_FORMAT
                                                ).format(TIME_FORMAT)}`,
                                                selectedBooking?.guestCount
                                                    ? `${selectedBooking?.guestCount} ${declensionOfNum(
                                                          selectedBooking?.guestCount || 0,
                                                          ["гость", "гостя", "гостей"]
                                                      )}`
                                                    : undefined,
                                                selectedBooking?.author?.phone,
                                                selectedBooking?.author?.email,
                                                selectedBooking?.comment,
                                            ],
                                            "\n"
                                        )}
                                    />

                                    {disabled ? (
                                        <Message label={"Ответ"} value={selectedBooking?.answer || ""} />
                                    ) : (
                                        <TextareaField
                                            name="answer"
                                            label="Ответ"
                                            noHover
                                            maxRows={5}
                                            placeholder={"Напишите ответ на заявку"}
                                            disabled={disabled}
                                        />
                                    )}
                                </div>
                            </div>
                        </Form>
                    </ModalWindowRight>
                );
            }}
        </Formik>
    );
};

export default BookingDetails;
