export type InformationPageBlock = { title: string; description: string; links: [{ url: string }] };

export type InformationPage = { blocks: InformationPageBlock[] };

export enum ReservationStatus {
    ACTIVE = "ACTIVE",
    CLOSED = "CLOSED",
}

export type RentHousesSupportCause = {
    slug: string;
    title: string;
    description?: string;
    use_for_user: boolean;
    use_for_partner: boolean;
    reservation_status?: ReservationStatus;
};
