import React from "react";
import { ReactComponent as DownloadIcon } from "../../../../assets/images/icons/import.svg";
import DocumentImage from "../../../../assets/images/icons/navigation/ic_page_gray.svg";
import classNames from "classnames";

export type FeedbackAttachment = {
    title: string;
    info: string;
    downloadUrl: string;
    previewUrl?: string;
};

type FeedbackAttachmentsProps = { attachments: FeedbackAttachment[] };

export const FeedbackAttachments = ({ attachments }: FeedbackAttachmentsProps) => {
    if (attachments.length === 0) return null;

    return (
        <div className="feedback__attachments">
            {attachments.map(({ previewUrl, title, info, downloadUrl }) => (
                <div className="feedback__attachment" key={downloadUrl}>
                    <div
                        className={classNames("feedback__attachment-preview", {
                            "feedback__attachment-preview-image": previewUrl,
                        })}
                        style={{ backgroundImage: `url(${previewUrl || DocumentImage})` }}
                    />
                    <div className="feedback__attachment-content">
                        <div className="feedback__attachment-header">
                            <div className="feedback__attachment-title" data-qa-name="feedback-attachment-title">
                                {title}
                            </div>
                            <a
                                href={downloadUrl}
                                download
                                target="_blank"
                                rel="noreferrer"
                                className="feedback__attachment-link button button--square-sm"
                                data-qa-name="feedback-attachment-download"
                            >
                                <DownloadIcon className="button__icon" />
                            </a>
                        </div>
                        <div className="feedback__attachment-info">{info}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};
