import React from "react";
import modalMenuFooterStyles from "../../form-fields/search-select-field/components/ModalMenu.module.sass";
import classNames from "classnames";

type ActionsComponentProps = {
    isChangedValue: boolean;
    handleSave: () => void;
    handleReset: () => void;
    isMobile: boolean;
};

const ActionsComponent: React.FC<ActionsComponentProps> = ({ isChangedValue, handleSave, handleReset, isMobile }) => {
    if (isChangedValue) {
        if (isMobile) {
            return (
                <div className={modalMenuFooterStyles.Footer}>
                    <button type="button" className={classNames("button button--primary")} onClick={handleSave}>
                        Сохранить
                    </button>
                </div>
            );
        } else {
            return (
                <>
                    <button type="button" className={classNames("button button--primary")} onClick={handleSave}>
                        Сохранить
                    </button>
                    <button type="button" className="button" onClick={handleReset}>
                        Сбросить
                    </button>
                </>
            );
        }
    }
    return <></>;
};

export default ActionsComponent;
