import { ReceivedNotification } from "../../hooks/useReceivedNotifications";
import styles from "./styles.module.sass";
import { Link } from "react-router-dom";
import { ReactComponent as Alert } from "../../../../../assets/images/icons/ic_alert_circle.svg";
import { memo, useCallback, useEffect, useRef } from "react";
import { useLazyLoad } from "../../../../../hooks/useLazyLoad";
import { readNotifications } from "../../../../../api/notficator";

type ItemProps = {
    item: ReceivedNotification;
};

const NotificationItem = ({ item }: ItemProps) => {
    const markRead = useCallback(async () => {
        try {
            await readNotifications([item.id]);
        } catch (e) {
            console.error(e);
        }
    }, [item.id]);

    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useLazyLoad(ref, {
        disableLazyLoad: item.read,
    });

    useEffect(() => {
        if (item.read || !isVisible) return;

        const timeout = setTimeout(markRead, 1000);
        return () => clearTimeout(timeout);
    }, [isVisible, item.read, markRead]);

    return (
        <div ref={ref} key={item.id} className={styles.item} data-qa-name="data-item">
            <div>
                <Alert />
            </div>
            <div className={styles.content} data-qa-name="item-content">
                <div className={styles.title} data-qa-name="notifications-item-title">
                    {item.title}
                </div>
                <div className={styles.description} data-qa-name="content-description">
                    <Link to={item.hotel.url} data-qa-name="notifications-item-link">
                        {item.hotel.title}
                    </Link>{" "}
                    не пользуется популярностью. {item.recommendation}
                </div>
            </div>
            <div className={styles.date}>{item.date}</div>
        </div>
    );
};

export default memo(NotificationItem);
