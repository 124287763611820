import { russpassApiServices } from "../../constants/api";
import { DialoguesListType } from "../../containers/apartments/dialogues/types/DialoguesListResponse";
import fetchRequest from "../manager";
import useSWR, { SWRConfiguration } from "swr";
import { DialogueObjectModel } from "../../containers/apartments/dialogues/types/DialogueObjectModel";
import { DialogueMessagesType } from "../../containers/apartments/dialogues/types/DialoguesMessagesResponse";
import { PaginatedListParams } from "../../types/api";
import { DialoguesType } from "../../containers/apartments/dialogues/constants";
import { DialogBookingStatus } from "../../containers/apartments/dialogues/types/DialogueModel";
import { Sorting } from "../../constants/filters";

const feedbackServicePath = russpassApiServices.feedback;
const dialoguesServicePath = russpassApiServices.dialogues;
const feedbackApiDialoguesUrl = `${feedbackServicePath}/dialogues`;
const feedbackApiDialoguesObjectsUrl = `${feedbackApiDialoguesUrl}/objects`;
export const feedbackApiDialogueUrl = `${feedbackApiDialoguesUrl}/messages`;
const feedbackApiDialogueMessagesUrl = `${dialoguesServicePath}/messages`;

export const useGetDialoguesList = (
    params: PaginatedListParams & { cmsObjectId?: string; type?: DialoguesType; bookingStatus?: DialogBookingStatus },
    config?: SWRConfiguration,
    disabled?: boolean
) =>
    useSWR(
        !disabled ? { url: feedbackApiDialoguesUrl, params } : undefined,
        ({ url, params }) => {
            if (params.page && params.pageSize) {
                return fetchRequest
                    .get(url, params, {
                        isProtected: true,
                    })
                    .then((res) => res?.data) as Promise<DialoguesListType>;
            }
        },
        config
    );

export const useGetDialogue = (
    cmsObjectId: string,
    externalId: number,
    params: PaginatedListParams & {
        createdAtBefore?: string;
        createdAtAfter?: string;
        sort?: Sorting;
    },
    { isDisabledRequest, ...config }: SWRConfiguration & { isDisabledRequest?: boolean } = {}
) => {
    return useSWR(
        !isDisabledRequest ? `${feedbackApiDialogueUrl}/${cmsObjectId}/${externalId}?&page=${params.page}` : null,
        () =>
            fetchRequest
                .get(`${feedbackApiDialogueUrl}/${cmsObjectId}/${externalId}`, params, {
                    isProtected: true,
                })
                .then((res) => res?.data) as Promise<DialogueMessagesType>,
        config
    );
};

export const useGetMessageCount = (
    cmsObjectId: string,
    externalId: string,
    { isDisabledRequest, ...config }: SWRConfiguration & { isDisabledRequest?: boolean } = {}
) => {
    return useSWR(
        !isDisabledRequest ? `${feedbackApiDialogueMessagesUrl}/${cmsObjectId}/${externalId}/count` : null,
        (url) =>
            fetchRequest
                .get(
                    url,
                    {},
                    {
                        isProtected: true,
                    }
                )
                .then((res) => res?.data.messagesCount) as Promise<number>,
        config
    );
};

export const useGetObjectList = (config?: SWRConfiguration) =>
    useSWR(
        { url: feedbackApiDialoguesObjectsUrl },
        ({ url }) =>
            fetchRequest
                .get(
                    url,
                    {},
                    {
                        isProtected: true,
                    }
                )
                .then((res) => res?.data.items) as Promise<DialogueObjectModel[]>,
        config
    );
