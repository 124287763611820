import { useRentHousesObject } from "../../../../api/hooks/rentHouses";
import { useCallback, useState } from "react";
import { RentHousesObjectPatchStatus, RentHousesObjectStatus } from "../../../../types/rentHouses";
import { useViewportContext, Banner } from "@russpass/partner-front-ui";

const ModerationAlert = ({ id }: { id: string | undefined }) => {
    const { data } = useRentHousesObject(id);

    const [show, setShow] = useState(true);
    const close = useCallback(() => setShow(false), []);

    const correctStatus =
        data?.data?.status === RentHousesObjectStatus.PUBLISHED &&
        data?.data?.patches?.some((el) => el.status === RentHousesObjectPatchStatus.PENDING);

    const { isMobile } = useViewportContext();

    if (!correctStatus || !show) return null;

    const baseStyle = { position: "fixed", zIndex: 101 };

    return (
        <Banner
            body="Отправим письмо на почту, когда внесенные изменения пройдут модерацию и появятся на сайте"
            onClose={close}
            style={
                isMobile
                    ? {
                          ...baseStyle,
                          top: "114px",
                          right: "16px",
                          maxWidth: "343px",
                      }
                    : { ...baseStyle, top: "100px", right: "40px" }
            }
        />
    );
};

export default ModerationAlert;
