import { useState } from "react";
import { DialogBookingStatus } from "../types/DialogueModel";

const BASE_OPTIONS = [
    {
        label: "Все сообщения",
        value: "",
    },
    {
        label: "Подтвержденные бронирования",
        value: DialogBookingStatus.ACTIVE,
    },
    {
        label: "Заявки на бронирование",
        value: DialogBookingStatus.PENDING,
    },
    {
        label: "Вопросы по объекту",
        value: DialogBookingStatus.NONE,
    },
];

export const useStatusFilter = () => {
    const [activeStatus, setActiveStatus] = useState<string>(BASE_OPTIONS[0].value);

    return {
        statusOptions: BASE_OPTIONS,
        activeStatus,
        setActiveStatus,
    };
};
