import { useCallback, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { hotelRoomFormData } from "../constants/hotel-room";
import { AmenitiesCategory, HotelRoomData, HotelRoomFormData, ServerAmenities } from "../types/hotel-room";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { GET_DATA_ERROR, POST_DATA_ERROR, POST_DATA_ERROR_SUPPORT } from "../../../../../constants/errors";
import { getGroupedAmenities, mappingHotelRoomFormData, tryCreateRoom, tryEditRoom } from "../utils/hotel-rooms";
import { useParams } from "react-router-dom";
import useLoadData from "./useLoadData";
import { StatusRequestEnum, OptionsGroupType, ObjectItemType } from "@russpass/partner-front-ui";
import { getIntegrationHotelRoom, deleteRoom, deleteTLRoom } from "../../../../../api/hotels/rooms";
import { ROOM_DELETED } from "../components/hotel-rooms/constants";
import { useHotelDataContext } from "../../hotel-view/hotel-data-context";
import { HotelResidenceTypesRes } from "../../../../../api/dictionaries";

const useControlHotelRoomData = () => {
    // @ts-ignore
    const { hotelId } = useParams();

    const { hotelInfoData, isTravelLineHotel, isNewTravelLineHotel } = useHotelDataContext();

    const [isLoadedOptions, setIsLoadedOptions] = useState(false);
    const [isLoadingRoom, setIsLoadingRoom] = useState(false);
    const [isSavingRoom, setIsSavingRoom] = useState(false);
    const [isViewHotelRoom, setIsViewHotelRoom] = useState<boolean>(false);

    const [selectedHotelRoomData, setSelectedHotelRoomData] = useState<HotelRoomFormData>(cloneDeep(hotelRoomFormData));

    const [amenitiesCategories, setAmenitiesCategories] = useState<AmenitiesCategory[]>([]);
    const [amenities, setAmenities] = useState<ServerAmenities[]>([]);
    const [hotelResidenceTypes, setHotelResidenceTypes] = useState<HotelResidenceTypesRes["rows"]>([]);

    const [statusHotelRoom, setStatusHotelRoom] = useState<StatusRequestEnum | null>(null);

    const { onLoadAmenities, loadHotelResidenceTypes } = useLoadData();

    const groupedAmenities = useMemo<OptionsGroupType[]>(
        () => getGroupedAmenities(amenities, amenitiesCategories),
        [amenitiesCategories, amenities]
    );

    const preload = useCallback(() => {
        return new Promise((resolve) => {
            const promises = [onLoadAmenities(), loadHotelResidenceTypes()];
            Promise.all(promises).then((data: any) => {
                setAmenitiesCategories(data[0]?.categories);
                setAmenities(data[0]?.amenities);
                setHotelResidenceTypes(data[1].rows);
                setIsLoadedOptions(true);
                resolve(data);
            });
        });
    }, [onLoadAmenities]);

    const getRoom = useCallback(
        async (roomId: string, amenities: ServerAmenities[]) => {
            try {
                const content = await getIntegrationHotelRoom(hotelId, roomId);
                return mappingHotelRoomFormData(content, amenities, isNewTravelLineHotel || isTravelLineHotel);
            } catch (error) {
                console.error(error);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: GET_DATA_ERROR,
                });
            }
        },
        [hotelId, isNewTravelLineHotel, isTravelLineHotel]
    );

    const onSelectRoom = useCallback(
        async (values) => {
            setIsLoadingRoom(true);
            setStatusHotelRoom(null);
            setIsViewHotelRoom(true);
            let data;
            let _amenities = amenities;
            if (!isLoadedOptions) {
                const content: any = await preload();
                if (content[0]?.amenities) {
                    _amenities = content[0]?.amenities;
                }
            }
            if (isTravelLineHotel && values.isError) {
                data = cloneDeep(hotelRoomFormData);
                const _room = hotelInfoData.rooms?.find((oldRoom) => oldRoom.id === values.id);
                if (_room) {
                    data.name = _room.name;
                    data.oldTLRoomId = _room.id;
                    data.description = _room.description;
                    data.images = _room.images;
                }
            } else {
                data = values;
                if (values.id) {
                    data = await getRoom(values.id, _amenities);
                    if (data?.occupancies?.length) {
                        data.occupancies = data.occupancies.map((occupancie) =>
                            occupancie.child ? { ...occupancie, isChild: true } : occupancie
                        );
                    }
                }
            }

            setSelectedHotelRoomData(data);
            setStatusHotelRoom(null);
            setIsLoadingRoom(false);
        },
        [isLoadedOptions, getRoom, preload, amenities, isTravelLineHotel, hotelInfoData?.rooms]
    );

    const onChangeRoom = useCallback(
        async (values, rooms?: HotelRoomData[]) => {
            setIsSavingRoom(true);
            try {
                let status = null;
                const isTLHotel = isNewTravelLineHotel || isTravelLineHotel;
                if (!!values.id) {
                    status = await tryEditRoom(values, selectedHotelRoomData, hotelId, isTLHotel);
                } else {
                    status = await tryCreateRoom(
                        values,
                        rooms?.map((item) => item.cmsRoomId) || [],
                        hotelId,
                        isTLHotel
                    );
                    if (isTLHotel && values.oldTLRoomId && hotelInfoData) {
                        await deleteTLRoom(hotelInfoData.cmsId, values.oldTLRoomId);
                    }
                }
                setStatusHotelRoom(status);
            } catch (status) {
                setStatusHotelRoom(StatusRequestEnum.Error);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: POST_DATA_ERROR_SUPPORT,
                });
            }
            setIsSavingRoom(false);
        },
        [selectedHotelRoomData, hotelId, isNewTravelLineHotel, isTravelLineHotel, hotelInfoData]
    );

    const onDeleteRoom = useCallback(
        async (room: ObjectItemType, hotelCmsId?: string) => {
            try {
                const isOldTravelLine = isTravelLineHotel && room.isError;
                let content;
                if (isOldTravelLine) {
                    if (hotelCmsId) {
                        content = await deleteTLRoom(hotelCmsId, room.id);
                    }
                } else {
                    content = await deleteRoom(hotelId, room.id);
                }
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: ROOM_DELETED,
                });
                return content;
            } catch (error) {
                console.error(error);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: POST_DATA_ERROR,
                });
            }
        },
        [hotelId, isTravelLineHotel]
    );

    const onAddRoom = useCallback(() => {
        onSelectRoom(cloneDeep(hotelRoomFormData));
    }, [onSelectRoom]);

    const changeIsViewHotelRoom = useCallback((status: boolean) => {
        setIsViewHotelRoom(status);
        if (status) {
            setStatusHotelRoom(null);
        }
    }, []);

    return {
        selectedHotelRoomData,
        amenities,
        groupedAmenities,
        onChangeRoom,
        onSelectRoom,
        onDeleteRoom,
        isLoadingRoom,
        isSavingRoom,
        statusHotelRoom,
        isViewHotelRoom,
        onAddRoom,
        changeIsViewHotelRoom,
        hotelResidenceTypes,
    };
};

export default useControlHotelRoomData;
