import useSWR, { SWRConfiguration } from "swr";
import { russpassApiServices } from "../../constants/api";
import fetchRequest from "../manager";
import { PaginatedListParams, ServerResponse } from "../../types/api";
import {
    RentHousesSupportChat,
    RentHousesSupportChatInitiator,
    RentHousesSupportChatResponse,
} from "../../types/rentHousesSupport";
import { isRentHousesSupportChatEnabled } from "../../constants/feature-toggles";
import { Sorting } from "../../constants/filters";
import useSWRInfinite from "swr/infinite";

export const useRentHousesSupportChat = ({
    isDisabledRequest = false,
    ...config
}: SWRConfiguration & { isDisabledRequest?: boolean } = {}) =>
    useSWR(
        !isDisabledRequest && isRentHousesSupportChatEnabled ? `${russpassApiServices.rentHousesSupport}/chats` : null,
        (url) =>
            fetchRequest.get(
                url,
                { initiator: RentHousesSupportChatInitiator.PARTNER },
                {
                    isProtected: true,
                }
            ) as Promise<ServerResponse<RentHousesSupportChat>>,
        config
    );

export const useRentHousesSupportChatMessagesInfinite = (
    params: Omit<PaginatedListParams, "page"> & {
        createdAtBefore?: string;
        createdAtAfter?: string;
        sort?: Sorting.CreatedAtAsc | Sorting.CreatedAtDesc;
    },
    { isDisabledRequest = false, ...config }: SWRConfiguration & { isDisabledRequest?: boolean } = {}
) =>
    useSWRInfinite<RentHousesSupportChatResponse>(
        (index) =>
            !isDisabledRequest && isRentHousesSupportChatEnabled
                ? {
                      url: `${russpassApiServices.rentHousesSupport}/chats/messages`,
                      params: { ...params, initiator: RentHousesSupportChatInitiator.PARTNER, page: index + 1 },
                  }
                : null,
        ({ url, params }) =>
            fetchRequest.get(url, params, {
                isProtected: true,
            }) as Promise<RentHousesSupportChatResponse>,
        config
    );
