import { RentHousesBooking } from "../../../../../types/rentHouses";
import { closeBookingModal } from "../utils/closeBookingModal";
import { ModalWindowRight, ActionButtonWrapper } from "@russpass/partner-front-ui";
import { parseModalTitle } from "../../helpers";
import { Container } from "../components/Container";
import { BaseBookingInfo } from "../components/BaseBookingInfo";
import { ReactComponent as ThumbUp } from "../../../../../assets/images/icons/thumbup.svg";
import { Header, HeaderInfo, HeaderSubtitle, HeaderTitle } from "../components/Header";
import { useCallback, useMemo, useState } from "react";
import { GuestRatingModal } from "./GuestRatingModal";
import { useRentHousesBookingMark } from "../../../../../api/hooks/rentHouses";
import RatingBadge from "../components/RatingBadge";
import styles from "./CompletedModal.module.sass";
import { useViewportContext } from "@russpass/partner-front-ui";
import { SupportModal } from "./SupportModal";
import { ReactComponent as IcExclamation } from "../../../../../assets/images/icons/ic_exclamation.svg";
import { InsuranceAlert } from "./components/InsuranceAlert";
import { InsuranceModal } from "./InsuranceModal";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";
import moment from "moment/moment";
import classNames from "classnames";

type Props = { booking: RentHousesBooking };

export const CompletedModal = ({ booking }: Props) => {
    const subtitle = parseModalTitle(booking);

    const { isMobile } = useViewportContext();

    const [isRating, setIsRating] = useState(false);
    const [isSupport, setIsSupport] = useState(false);
    const [isInsurance, setIsInsurance] = useState(false);

    const { data, isLoading, mutate } = useRentHousesBookingMark(booking.id);
    const mark = data?.data?.value;

    const closeRatingModal = useCallback(() => {
        mutate().finally(() => setIsRating(false));
    }, [mutate]);

    const isSupportEnabled = useMemo(() => moment().isSame(booking.checkoutDate, "day"), [booking.checkoutDate]);

    if (isRating) {
        return <GuestRatingModal booking={booking} close={closeRatingModal} />;
    }

    if (isSupport && isSupportEnabled) {
        return <SupportModal booking={booking} close={() => setIsSupport(false)} />;
    }

    if (isInsurance) {
        return <InsuranceModal booking={booking} close={() => setIsInsurance(false)} />;
    }

    return (
        <ModalWindowRight
            isOpened
            isLoadingWithoutContent={isLoading}
            onClose={closeBookingModal}
            headerComponent={
                <Header>
                    <HeaderInfo>
                        <HeaderTitle>
                            Завершенное бронирование
                            {!!mark && !isMobile && (
                                <div className={styles.Rating}>
                                    <RatingBadge value={mark} />
                                    <div>Оценка гостю</div>
                                </div>
                            )}
                        </HeaderTitle>
                        <HeaderSubtitle>{subtitle}</HeaderSubtitle>
                    </HeaderInfo>
                </Header>
            }
            ComponentFooter={
                (!mark || isSupportEnabled) && (
                    <ActionButtonWrapper>
                        {!mark && (
                            <button
                                type="button"
                                className={classNames("button button--primary button--full-width", styles.ActionButton)}
                                onClick={() => {
                                    sendEvent(AnalyticEvent.rate_guest_click);
                                    setIsRating(true);
                                }}
                            >
                                <ThumbUp />
                                <span className="ml-8">Оценить гостя</span>
                            </button>
                        )}
                        {isSupportEnabled && (
                            <button
                                type="button"
                                className={classNames("button button--second button--full-width", styles.ActionButton)}
                                onClick={() => setIsSupport(true)}
                            >
                                <IcExclamation />
                                <span className="ml-8">{isMobile ? "Проблема" : "Сообщить о проблеме"}</span>
                            </button>
                        )}
                    </ActionButtonWrapper>
                )
            }
        >
            <Container>
                {!!mark && isMobile && (
                    <div className={styles.Rating}>
                        <RatingBadge value={mark} />
                        <div>Оценка гостю</div>
                    </div>
                )}
                <InsuranceAlert onClick={() => setIsInsurance(true)} />
                <BaseBookingInfo booking={booking} />
            </Container>
        </ModalWindowRight>
    );
};
