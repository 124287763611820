import { useCallback } from "react";
import { ModalWindowRight, StatusRequestCard, StatusRequestEnum } from "@russpass/partner-front-ui";
import styles from "./status-card-create-rest.module.sass";
import { StatusRequestCardSubtitleError } from "../../../../../constants/errors";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";
import useUserInfo from "../../../../../hooks/useUserInfo";

type StatusCardCreateRestProps = {
    status: StatusRequestEnum | null;
    name: string;
    closeStatusCard: () => void;
    onSaveRestaurant: () => void;
};

const StatusCardCreateRestaurant = ({ status, name, closeStatusCard, onSaveRestaurant }: StatusCardCreateRestProps) => {
    const { userFullInfo } = useUserInfo();
    const email = userFullInfo?.email;
    const isOpened = !!status;

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);
    return (
        <ModalWindowRight isOpened={isOpened} onClose={closeStatusCard} position={1} isHideCloseButton>
            <StatusRequestCard
                title={{
                    success: `Объект \u00ab${name}\u00bb отправлен на модерацию`,
                    error: (
                        <>
                            {"Не удалось отправить на модерацию "}
                            <br />
                            {`\u00ab${name}\u00bb`}
                        </>
                    ),
                }}
                subtitle={{
                    success: (
                        <span>
                            {"В течение 5 рабочих дней вы получите ответ"}
                            <br />
                            {email && ` на `}
                            <span className={styles.contrast_text}>{email}</span>
                        </span>
                    ),
                    error: StatusRequestCardSubtitleError,
                }}
                handleClose={closeStatusCard}
                status={status}
                handleRepeat={onSaveRestaurant}
                buttonTitle={{ repeat: "Повторить" }}
                handleBeforeClose={handleSendEvent}
            />
        </ModalWindowRight>
    );
};

export default StatusCardCreateRestaurant;
