import styles from "./hotel-notification.module.sass";

type Props = {
    text: string;
};
const HotelNotification = ({ text }: Props) => {
    return <div className={styles.text}>{text}</div>;
};

export default HotelNotification;
