import routes from "../../constants/routes";

export const navbarDocumentsNames = {
    report: "REPORT",
    contracts: "CONTRACTS",
};

export const navbarDocuments = [
    {
        label: "Отчетные документы",
        name: navbarDocumentsNames.report,
        link: routes.reports,
    },
    {
        label: "Договоры",
        name: navbarDocumentsNames.contracts,
        link: routes.contracts,
    },
];
