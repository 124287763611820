import { validationSchema } from "./validation-schema";
import { DialogueMessageFormModel } from "../../guest/DialogueModal";
import { Formik } from "formik";
import SendMessageFormContent from "./SendMessageFormContent";
import { FormikHelpers } from "formik/dist/types";
import React, { useState } from "react";
import styles from "./SendMessageFormContent.module.sass";
import { ReactComponent as IconAlert } from "../../../../../../assets/images/icons/ic_alert_circle.svg";
import routes from "../../../../../../constants/routes";

type Props = {
    fileRef: React.MutableRefObject<any>;
    onSubmit: (
        values: DialogueMessageFormModel,
        formikHelpers: FormikHelpers<DialogueMessageFormModel>
    ) => void | Promise<any>;
    blocked?: boolean;
};

const INITIAL_VALUES = Object.freeze({ message: "", answerFiles: [] });

const SendMessageForm = ({ onSubmit, fileRef, blocked }: Props) => {
    const [attachmentScroll, setAttachmentScroll] = useState<number>(0);

    if (blocked) {
        return (
            <div className={styles["blockedChat"]}>
                <IconAlert />
                <span>
                    &nbsp; Этот чат заблокирован. По всем вопросам вы можете написать в{" "}
                    <a className={styles["supportLink"]} href={routes.support}>
                        Поддержку
                    </a>
                </span>
            </div>
        );
    }

    return (
        <Formik<DialogueMessageFormModel>
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            validateOnMount
            enableReinitialize
            onSubmit={onSubmit}
        >
            {({ submitForm }) => (
                <SendMessageFormContent
                    fileRef={fileRef}
                    attachmentScroll={attachmentScroll}
                    setAttachmentScroll={setAttachmentScroll}
                    submitForm={submitForm}
                />
            )}
        </Formik>
    );
};

export default SendMessageForm;
