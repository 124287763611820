import React, { useCallback } from "react";
import { InputAjaxField } from "@russpass/partner-front-ui";
import { getRegionsByParams } from "../../../api/dictionaries";
import { TSelectValue } from "../../../types/dictionaries";

type Props = {
    isShowErrors?: boolean;
    disabled?: boolean;
    name: string;
    label?: string;
    placeholder?: string;
    value: TSelectValue;
    labelField?: string;
    handleChange: (value: any) => void;
};

const Region = ({
    name,
    value = { id: "", title: "" },
    label = "Регион",
    labelField = "title",
    placeholder = "Не выбрано",
    disabled,
    isShowErrors = false,
    handleChange,
}: Props) => {
    const loadRegions = useCallback(async (regionName: string) => {
        try {
            const { rows } = await getRegionsByParams({
                regionName,
            });
            return Promise.resolve(rows);
        } catch (err) {
            return [];
        }
    }, []);

    return (
        <InputAjaxField
            name={name}
            label={label}
            placeholder={placeholder}
            value={value}
            loadOptions={loadRegions}
            disabled={disabled}
            labelField={labelField}
            handleChange={handleChange}
            dataQaName="region"
            errorSettings={{
                showOnChange: isShowErrors,
            }}
        />
    );
};

export default Region;
