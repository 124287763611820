import React, { useMemo } from "react";
import { useViewportContext, SelectInputField } from "@russpass/partner-front-ui";
import styles from "./apartments-new-rooms.module.sass";
import { SleepingPlacesField } from "../sleeping-places-field/";
import { FieldArray, useFormikContext } from "formik";
import classNames from "classnames";
import { ReactComponent as ArrowLeft } from "../../../../../../../assets/images/icons/ic_plus.svg";
import { ReactComponent as Trash } from "../../../../../../../assets/images/icons/ic_trash.svg";
import NewRoomModalSelectField from "./components/NewRoomModalSelectField";
import { useNewRoomDictionary } from "./hooks/useNewRoomDictionary";

type ApartmentsNewRoomsProps = {
    name: string;
    disabled?: boolean;
};

type FormikErrors = {
    details: {
        maxGuestCount?: number;
        bathroomCount?: number;
        arrival?: {
            start: string;
            end: string;
        };
        checkinType?: string;
        homeRules?: string;
        rooms?: [
            {
                roomType: string;
                beds: string;
            }
        ];
    };
};

export const ApartmentsNewRooms = ({ name, disabled }: ApartmentsNewRoomsProps) => {
    const { isMobile } = useViewportContext();
    const { setFieldValue, getFieldProps, errors } = useFormikContext<FormikErrors>();

    const items = useMemo(() => getFieldProps(name).value || [{}], [getFieldProps, name]);

    const oneItem = useMemo(() => items.length <= 1, [items.length]);

    const isShowAddButton = useMemo(() => {
        const lastIdx = items.length - 1;
        return !!(items[lastIdx]?.beds && items[lastIdx]?.roomType);
    }, [items]);

    const { optionsRoomType, optionsBeds } = useNewRoomDictionary();

    return (
        <div className="content-wrapper__framed">
            <div className="section mb-8">Комнаты и спальные места</div>
            <div
                className={classNames({
                    [styles.error]: errors.details?.rooms,
                    [styles.description]: !errors.details?.rooms,
                })}
            >
                Укажите количество спальных мест в каждой комнате
            </div>
            <div className={styles.fields}>
                <FieldArray
                    name={name}
                    render={({ push, remove }) => (
                        <>
                            {items?.map((item: any, idx: number) => {
                                const hasValue = item.roomType && item.beds;

                                return (
                                    <div className={styles.field} key={idx}>
                                        <div className={styles.input}>
                                            {isMobile ? (
                                                <NewRoomModalSelectField
                                                    name={`${name}[${idx}].roomType`}
                                                    label={`Комната ${idx + 1}`}
                                                    options={optionsRoomType}
                                                    disabled={disabled}
                                                />
                                            ) : (
                                                <SelectInputField
                                                    name={`${name}[${idx}].roomType`}
                                                    label={`Комната ${idx + 1}`}
                                                    placeholder="Выбрать"
                                                    options={optionsRoomType}
                                                    isChecked
                                                    handleChange={({ value }) =>
                                                        setFieldValue(`${name}[${idx}].roomType`, value)
                                                    }
                                                    value={
                                                        optionsRoomType.find((o) => o.value === item.roomType) || {
                                                            value: undefined,
                                                        }
                                                    }
                                                    disabled={disabled}
                                                />
                                            )}
                                            {(hasValue || idx > 0) && !disabled && (
                                                <Trash
                                                    className={styles.trash}
                                                    onClick={
                                                        !oneItem ? () => remove(idx) : () => setFieldValue(name, [{}])
                                                    }
                                                />
                                            )}
                                        </div>
                                        <SleepingPlacesField
                                            name="beds"
                                            parentName={name}
                                            idx={idx}
                                            label="Спальные места"
                                            placeholder="Выбрать"
                                            items={optionsBeds}
                                            modalTitle={isMobile ? "Спальные места" : undefined}
                                            noHover
                                            disabled={disabled}
                                        />
                                    </div>
                                );
                            })}
                            {isShowAddButton && (
                                <button
                                    className={classNames("button button--third", styles.addButton)}
                                    type="button"
                                    onClick={() => push({})}
                                    disabled={disabled}
                                >
                                    <ArrowLeft className="button__icon button__icon--left" />{" "}
                                    <div className={styles.header__content__back__title}>Добавить комнату</div>
                                </button>
                            )}
                        </>
                    )}
                />
            </div>
        </div>
    );
};
