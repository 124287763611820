import { HotelDiscountStore, HotelDiscountStoreContext } from "./HotelDiscountsStoreContext";
import { useContext } from "react";

export const useHotelDiscountStoreContext = (): HotelDiscountStore => {
    const context = useContext<HotelDiscountStore | undefined>(HotelDiscountStoreContext);

    if (!context) {
        throw new Error("useHotelDiscountStoreContext must be used within a HotelDiscountStoreProvider.");
    }

    return context;
};
