import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/images/icons/ic_chevron_down.svg";
import { useRequest } from "../../../../hooks/useRequest";
import { getFeedbackEvents } from "../../../../api/feedback";
import { Loader, MultiMenuRef, MultiMenu, Input, RadioButton } from "@russpass/partner-front-ui";
import useUserInfo from "../../../../hooks/useUserInfo";

type FeedbackEventFilterProps = {
    value?: string;
    onChange?: (value?: string) => void;
};

const emptyEventFilterValue = "---";

export const FeedbackEventFilter = ({ value, onChange }: FeedbackEventFilterProps) => {
    const menuRef = useRef<MultiMenuRef>(null);
    const [term, setTerm] = useState("");

    const { result, loading } = useRequest(async () => {
        const response = await getFeedbackEvents();

        return response.data?.items;
    }, []);

    const options = useMemo(
        () => result?.filter(({ name }) => name?.toLowerCase().includes(term?.toLowerCase())),
        [result, term]
    );

    const selectedOption = useMemo(() => result?.find(({ externalId }) => externalId === value), [result, value]);

    const { isHotel } = useUserInfo();

    const termInputProps = useMemo(
        () => ({
            placeholder: isHotel ? "Название объекта" : "Название услуги",
            value: term,
            onChange: (evt: ChangeEvent<HTMLInputElement>) => setTerm(evt.target.value),
            name: "feedback-event-input",
        }),
        [isHotel, term]
    );

    const handleClearTerm = useCallback(() => setTerm(""), []);

    const defaultLabelFilter = useMemo(() => (isHotel ? "Все объекты" : "Все услуги"), [isHotel]);

    const filterButton = useMemo(
        () => (
            <button className="button button--second">
                {selectedOption?.name || defaultLabelFilter}
                <ArrowDownIcon className="button__icon--right" />
            </button>
        ),
        [defaultLabelFilter, selectedOption]
    );

    const handleChange = useCallback(
        (value) => {
            menuRef.current?.closeMenu();
            onChange?.(value !== emptyEventFilterValue ? value : undefined);
        },
        [onChange]
    );

    return (
        <MultiMenu ref={menuRef} renderComponent={filterButton} noHover={true} hideArrow={true} disabled={loading}>
            {loading ? (
                <div className="feedback__loader-wrapper">
                    <Loader width="48px" />
                </div>
            ) : (
                <>
                    <Input inputProps={termInputProps} noHover width="auto" handleClear={handleClearTerm} />
                    <RadioButton
                        label={defaultLabelFilter}
                        value={emptyEventFilterValue}
                        isActive={!value}
                        handleClick={handleChange}
                    />
                    {options?.map(({ externalId, name }) => (
                        <RadioButton
                            key={`option-${externalId}`}
                            label={name}
                            value={externalId}
                            isActive={externalId === value}
                            handleClick={handleChange}
                        />
                    ))}
                </>
            )}
        </MultiMenu>
    );
};
