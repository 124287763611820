import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

// Предотвращает обновление состояния после размонтирования
function useSafeState<StateType = unknown>(initialState: StateType): [StateType, Dispatch<SetStateAction<StateType>>] {
    let isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    let [state, setState] = useState<StateType>(initialState);
    let unmountedCallCount = useRef(0);

    let wrappedSetState = useCallback(
        (update: SetStateAction<StateType>) => {
            if (isMounted.current) {
                setState(update);
            } else if (unmountedCallCount.current >= 2) {
                throw new Error(`Potential memory leak detected, setState called on unmounted component 2 or times`);
            } else {
                unmountedCallCount.current++;
            }
        },
        [setState]
    );

    return [state, wrappedSetState];
}

export default useSafeState;
