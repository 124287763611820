import { graviteeApiKeys, GraviteeApiKeyType, graviteeApiList } from "../constants/gravitee-api-keys";

export const getGraviteeApiKey = (host: string, url: string) => {
    if (!graviteeApiList.includes(host)) {
        return null;
    }

    // Проверяет только 1 слой: "/mosru/papi" -> "mosru"
    const graviteeMatchUrl = url.startsWith("/") ? url.slice(1).split("/")[0] : url.split("/")[0];
    const value = graviteeApiKeys.hasOwnProperty(graviteeMatchUrl)
        ? graviteeApiKeys[graviteeMatchUrl as GraviteeApiKeyType]
        : null;

    return value;
};
