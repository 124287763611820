import { FormNotificationTemplate } from "../hooks/useEditNotifications";
import { HotelNotification, NotificationChannel, NotificationChannelKey } from "../../types/notifications";

export const buildNotification = ({
    template,
    hotelId,
}: {
    template: FormNotificationTemplate;
    hotelId: string;
}): HotelNotification => {
    const channels: NotificationChannel[] = [
        {
            key: NotificationChannelKey.LKP,
        },
    ];
    if (template.emailEnabled) {
        if (template.reserveEmail) {
            channels.push({ key: NotificationChannelKey.EMAIL, address: template.reserveEmail });
        }
        if (template.email) {
            channels.push({ key: NotificationChannelKey.EMAIL, address: template.email });
        }
    }

    return {
        active: template.active,
        threshold: template.threshold,
        title: template.title,
        templateId: template.templateId,
        channels,
        hotelId,
    };
};
