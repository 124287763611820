import { ChannelManagerCodeEnum } from "../../../enums/channel-manager-code";
import { HotelFormData } from "./types";
import { IBank } from "../../../types/profile";

export const channelHotelNames = {
    bnovo: "Bnovo",
    travelLine: "TravelLine",
};

export const channelHotels = [
    { name: channelHotelNames.bnovo, code: ChannelManagerCodeEnum.Bnovo },
    { name: channelHotelNames.travelLine, code: ChannelManagerCodeEnum.NewTravelLine },
];

export const hotelBankDetails: IBank = {
    bic: "",
    name: "",
    checkingAccount: "",
    corrAccount: "",
};

export const initialValueForm: HotelFormData = {
    id: "",
    systemType: ChannelManagerCodeEnum.Bnovo,
    title: "",
    bankDetails: {
        ...hotelBankDetails,
    },
};

export const successSavingMessage = "Объект сохранен в статусе «Черновик»";
export const doubleErrorMessage = "Такой отель уже добавлен в раздел «Объекты»";

export const textModalAlert = {
    continue: "Продолжить заполнение данных",
    notAdded: "Вы не добавили объект",
    warning: "При выходе со страницы все заполненные данные пропадут",
    out: "Выйти без сохранения",
    cancel: "Отменить",
    warningChangeChannel: "При смене channel manager все заполненные данные пропадут",
    changeChannel: "Сменить channel manager",
};
