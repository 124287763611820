import React from "react";
import { Cell } from "@russpass/partner-front-ui";

import styles from "./supportInfo.module.sass";

const SupportInfo: React.FC = () => {
    return (
        <div className={styles.info}>
            <div className={styles.info__contacts}>
                <span className={styles.info__contacts_title}>Контакты</span>
                <div>
                    <Cell topCaption="Звонки по РФ" body="8 800 300-61-22" />
                    <Cell topCaption="Звонки для стран СНГ" body="+7 495 122-01-11" />
                    <Cell topCaption="E-mail" body="support@russpass.ru" />
                </div>
            </div>
        </div>
    );
};

export default SupportInfo;
