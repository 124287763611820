import classNames from "classnames";
import { useViewportContext } from "@russpass/partner-front-ui";
import { HTMLProps } from "react";
import styles from "./Controls.module.sass";

export const Controls = ({ className, ...divProps }: HTMLProps<HTMLDivElement>) => {
    const { isMobile } = useViewportContext();

    return (
        <div
            className={classNames(styles.Controls, className, {
                [styles.Controls__mobile]: isMobile,
            })}
            {...divProps}
        />
    );
};
