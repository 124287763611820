import { BaseFieldProps } from "./types";
import { ACCEPT_FILES } from "../../constants";
import { UploadFileField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";
import { ReactComponent as IconAlertCircle } from "../../../../../../assets/images/icons/ic_alert_circle.svg";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";

export const AuthorityFileField = ({ name }: BaseFieldProps) => {
    const { getFieldProps, setFieldValue } = useFormikContext();

    return (
        <div className="company-info__alignment-wrap mb-24">
            <UploadFileField
                name={name}
                buttonTitle="Доверенность"
                accept={ACCEPT_FILES}
                files={getFieldProps(name).value}
                onDelete={() => {
                    setFieldValue(name, []);
                }}
                hint="в формате jpg, pdf"
                onDropEvent={() => sendEvent(AnalyticEvent.upload_offer)}
            />
            <p className="company-info__field-alert">
                <IconAlertCircle />{" "}
                <span>Доверенность на предоставление интересов собственника должна быть нотариально заверена</span>
            </p>
        </div>
    );
};
