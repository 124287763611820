import {
    ModalWindowRight,
    useViewportContext,
    Switch,
    CommonLayout,
    ActionButtonWrapper,
} from "@russpass/partner-front-ui";
import { Option } from "../types";
import { Controls } from "../../components/Controls";
import { FiltersContainer } from "../../components/FiltersContainer";
import { SelectFilter } from "../../components/SelectFilter";
import MomentLocaleUtils from "react-day-picker/moment";
import DayPicker from "react-day-picker";
import styles from "./styles.module.sass";
import { useRentHousesBookings, useRentHousesObject } from "../../../../api/hooks/rentHouses";
import { RentHousesBookingStatus, RentHousesObjectStatus } from "../../../../types/rentHouses";
import { ISO_DATE_FORMAT } from "../../../../constants/dates-format";
import moment from "moment";
import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useYearsSelect } from "./hooks/useYearsSelect";
import { useMonthsSelect } from "./hooks/useMonthsSelect";
import classNames from "classnames";
import { useBookingBlocks } from "./hooks/useBookingBlocks";
import { useLocation } from "react-router-dom";
import { sendEvent } from "../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../product_analytics/constants";
import { BookingCalendarDay } from "./components/BookingCalendarDay";

type Props = {
    close: () => void;
    objectOptions: Option[];
    activeObject: string;
    setActiveObject: (object: string) => void;
};

export const Calendar = ({ close, activeObject, setActiveObject, objectOptions }: Props) => {
    const { isMobile } = useViewportContext();

    const filteredObjectOptions = useMemo(() => objectOptions.filter((el) => !!el.value), [objectOptions]);

    useEffect(() => {
        if (activeObject) return;
        setActiveObject(filteredObjectOptions[0]?.value);
    }, [activeObject, filteredObjectOptions, setActiveObject]);

    const { activeYear, setActiveYear, years } = useYearsSelect();
    const { activeMonth, setActiveMonth, months } = useMonthsSelect();

    const activeDate = moment()
        .set("year", +activeYear)
        .set("month", +activeMonth);

    const {
        data: bookingsData,
        isLoading: isLoadingBookings,
        mutate,
    } = useRentHousesBookings(
        {
            page: 1,
            pageSize: 100,
            status: [
                RentHousesBookingStatus.PENDING,
                RentHousesBookingStatus.ACTIVE,
                RentHousesBookingStatus.STARTED,
                RentHousesBookingStatus.COMPLETED,
            ],
            objectId: activeObject,
            stayDateFrom: activeDate.startOf("month").format(ISO_DATE_FORMAT),
            stayDateTo: activeDate.endOf("month").format(ISO_DATE_FORMAT),
        },
        undefined,
        !activeObject
    );

    useEffect(() => {
        sendEvent(AnalyticEvent.calendar_page);
    }, []);

    const { data: objectData, isLoading: isLoadingObject } = useRentHousesObject(activeObject);

    const { isBlocksLoading, isBlocking, enableBlocking, saveBlocks, blockedDays, toggleBlock } = useBookingBlocks(
        activeObject,
        +activeYear,
        +activeMonth + 1
    );

    // Обновление списка при закрытии карточки бронирования
    const hasId = !!new URLSearchParams(useLocation().search).get("id");
    useEffect(() => {
        if (!hasId) mutate();
    }, [hasId, mutate]);

    const isArchiveObject = useMemo(
        () => objectData?.data.status === RentHousesObjectStatus.ARCHIVED,
        [objectData?.data.status]
    );

    const renderLayout = useCallback(
        (children: ReactNode) => {
            const title = "Бронирования";
            const isLoadingWithContent = isLoadingBookings || isLoadingObject || isBlocksLoading;

            if (isMobile) {
                return (
                    <ModalWindowRight
                        isOpened
                        title={title}
                        isLoadingWithContent={isLoadingWithContent}
                        onClose={close}
                    >
                        <div className="container">{children}</div>
                    </ModalWindowRight>
                );
            }
            return (
                <CommonLayout pageTitle={title} isLoadingWithContent={isLoadingWithContent}>
                    <div className="content-wrapper content-wrapper--large">{children}</div>
                </CommonLayout>
            );
        },
        [isLoadingBookings, isLoadingObject, isBlocksLoading, isMobile, close]
    );

    return renderLayout(
        <>
            <Controls>
                <FiltersContainer>
                    <div>
                        <SelectFilter options={months} value={activeMonth} onChange={setActiveMonth} />
                    </div>
                    <div>
                        <SelectFilter options={years} value={activeYear} onChange={setActiveYear} />
                    </div>
                    {filteredObjectOptions.length > 1 && (
                        <div>
                            <SelectFilter
                                options={filteredObjectOptions}
                                value={activeObject}
                                onChange={setActiveObject}
                            />
                        </div>
                    )}
                </FiltersContainer>
                <Switch label="Календарь" value={true} name="isCalendar" onChange={close} />
            </Controls>
            <DayPicker
                className={classNames(styles.Calendar, {
                    [styles.Calendar__mobile]: isMobile,
                })}
                localeUtils={MomentLocaleUtils}
                locale="ru"
                captionElement={() => null}
                navbarElement={() => null}
                renderDay={(date) => (
                    <BookingCalendarDay
                        date={date}
                        rates={objectData?.data?.rates || []}
                        bookings={bookingsData?.data?.items || []}
                        blockedDays={blockedDays}
                        isBlocking={isBlocking}
                        toggleBlock={toggleBlock}
                        enableBlocking={enableBlocking}
                        isArchiveObject={isArchiveObject}
                    />
                )}
                fromMonth={activeDate.toDate()}
                month={activeDate.toDate()}
            />
            {isBlocking && (
                <div
                    className={classNames(styles.ActionWrapper, {
                        [styles.ActionWrapper__mobile]: isMobile,
                    })}
                >
                    <ActionButtonWrapper
                        className={classNames(styles.Action, {
                            [styles.Action__mobile]: isMobile,
                        })}
                    >
                        <span className={styles.ActionInfo}>
                            Выбранные даты будут недоступны туристам для бронирования
                        </span>

                        <button type="button" className="button button--primary" onClick={saveBlocks}>
                            Сохранить
                        </button>
                    </ActionButtonWrapper>
                </div>
            )}
        </>
    );
};
