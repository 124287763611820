import styles from "./styles.module.sass";
import { StatusRequestEnum, SwitchField } from "@russpass/partner-front-ui";
import { Link, useParams } from "react-router-dom";
import { useFormikContext } from "formik";
import { useEffect, useMemo, useRef } from "react";
import { setCanReserveRestaurant } from "../../../../../api/restaurants";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { UNEXPECTED_ERROR } from "../../../../../constants/errors";
import routes from "../../../../../constants/routes";

type ReserveSwitchProps = {
    disabled: boolean;
};

const NAME = "canReserve";

const ReserveSwitch = ({ disabled }: ReserveSwitchProps) => {
    const { restaurantId } = useParams<{ restaurantId?: string }>();

    const { getFieldProps, setFieldValue } = useFormikContext();
    const value = useMemo(() => !!getFieldProps(NAME).value, [getFieldProps]);
    const prevSavedValueRef = useRef<boolean>(value);

    useEffect(() => {
        if (!restaurantId || value === prevSavedValueRef.current) return;

        setCanReserveRestaurant(restaurantId, value)
            .then(() => {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: value ? (
                        <div>
                            <div>Онлайн-бронирование включено.</div>
                            <div>
                                Заявки отобразятся в разделе{" "}
                                <Link className={styles.link} to={routes.bookingAll}>
                                    Бронирования
                                </Link>
                            </div>
                        </div>
                    ) : (
                        "Онлайн-бронирование отключено. Информация об объекте будет доступна только для просмотра"
                    ),
                });
                prevSavedValueRef.current = value;
            })
            .catch(() => {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: UNEXPECTED_ERROR,
                });
                setFieldValue(NAME, prevSavedValueRef.current);
            });
    }, [restaurantId, setFieldValue, value]);

    return (
        <div className={styles.switch_wrapper}>
            <SwitchField
                name={NAME}
                disabled={disabled}
                label="Бронирование на RUSSPASS"
                hint="Пользователи сайта смогут забронировать столик онлайн"
            />
        </div>
    );
};

export default ReserveSwitch;
