import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";

type MaskProps = {
    [arbitrary: string]: any;
};

export const inputMaskWithPercent = (props: MaskProps) => {
    return (
        <MaskedInput
            {...props}
            guide={false}
            mask={createNumberMask({
                prefix: "",
                allowDecimal: true,
                includeThousandsSeparator: false,
                suffix: " %",
            })}
        />
    );
};

export const inputMaskWithNights = (props: MaskProps) => (
    <MaskedInput
        {...props}
        guide={false}
        mask={createNumberMask({
            prefix: "",
            includeThousandsSeparator: false,
            suffix: " ночей",
        })}
    />
);
