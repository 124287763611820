import { Form, Formik, FormikProps } from "formik";
import { useCallback, useEffect, useState } from "react";
import {
    Input,
    InputField,
    ModalWindowRight,
    Tooltip,
    StatusRequestCard,
    StatusRequestEnum,
} from "@russpass/partner-front-ui";
import { HotelTariffData, OptionTypeFood } from "../../types/hotel-tariff";
import { hotelTariffValidation } from "../../validation-schemas/hotel-tariff";
import HotelTariffFood from "./hotel-tariff-food";
import HotelTariffCancelReserv from "./hotel-tariff-reserv-cancel";

import "./styles.sass";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";
import { HotelDataContext } from "../../../hotel-view/hotel-data-context";
import { StatusRequestCardSubtitleError } from "../../../../../../constants/errors";

type HotelTariffProps = {
    hotelTariffData: HotelTariffData;
    isView: boolean;
    onClose: (isSaveStatus?: boolean) => void;
    onSave: (values: HotelTariffData) => Promise<string>;
    optionsTypeFood: OptionTypeFood[];
    isDisabled?: boolean;
    status: StatusRequestEnum | null;
    setStatus: React.Dispatch<React.SetStateAction<StatusRequestEnum | null>>;
    isLoading?: boolean;
};

const HotelTariff = ({
    hotelTariffData,
    isView,
    onClose,
    onSave,
    optionsTypeFood,
    isDisabled,
    status,
    setStatus,
    isLoading,
}: HotelTariffProps) => {
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const [isDisabledForm, setIsDisabledForm] = useState<boolean>(false);

    const [createdName, setCreatedName] = useState<string | null>(null);

    const onSubmitForm = useCallback(
        (values: HotelTariffData) => {
            setIsSubmittingForm(true);
            setIsDisabledForm(true);
            setCreatedName(values.title);
            onSave(values)
                .then(() => {
                    setStatus(StatusRequestEnum.Success);
                })
                .catch((err) => {
                    setStatus(StatusRequestEnum.Error);
                })
                .finally(() => {
                    setIsDisabledForm(false);
                });
        },
        [onSave, setStatus]
    );

    useEffect(() => {
        if (isView) {
            setIsSubmittingForm(false);
            setStatus(null);
        }
    }, [isView, setStatus]);

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);

    return (
        <HotelDataContext.Consumer>
            {({ hotelInfoData }) => (
                <Formik
                    initialValues={hotelTariffData}
                    validationSchema={hotelTariffValidation}
                    enableReinitialize
                    validateOnMount
                    onSubmit={onSubmitForm}
                >
                    {({ values, setFieldValue, submitForm, resetForm }: FormikProps<HotelTariffData>) => {
                        const handleClick = () => {
                            submitForm();
                            sendEvent(AnalyticEvent.tariff_save_button);
                        };
                        return (
                            <ModalWindowRight
                                isOpened={isView}
                                onClose={() => {
                                    resetForm();
                                    onClose();
                                }}
                                isLoadingWithoutContent={isLoading}
                                position={1}
                                ComponentFooter={
                                    !!status || isDisabled ? undefined : (
                                        <button
                                            className="button button--primary"
                                            disabled={isDisabledForm}
                                            onClick={handleClick}
                                        >
                                            Сохранить
                                        </button>
                                    )
                                }
                                isHideCloseButton={!!status}
                                headerComponent={
                                    !!status ? undefined : (
                                        <div className="hotel-tariff__header">
                                            <div className="hotel-tariff--title">{values.title || "Новый тариф"} </div>
                                            <div className="hotel-tariff--name">
                                                {hotelInfoData.title || "Название объекта"}
                                            </div>
                                        </div>
                                    )
                                }
                            >
                                {!status ? (
                                    <div className="content-wrapper">
                                        <div className="content-wrapper__form">
                                            <Tooltip
                                                tooltipWidth={"266px"}
                                                offset={{ X: -250, Y: -450 }}
                                                overlay="Редактирование недоступно. Если вы хотите изменить данные, удалите этот тариф и создайте новый"
                                                disabled={!isDisabled}
                                                isInteractive={true}
                                                followCursor={true}
                                            >
                                                <Form>
                                                    <InputField
                                                        label="Название"
                                                        name="title"
                                                        noHover
                                                        placeholder="Максимально 250 символов"
                                                        errorSettings={{
                                                            showOnChange: isSubmittingForm,
                                                        }}
                                                        maxLength={250}
                                                        disabled={isDisabled}
                                                        hint="Отразится только в вашем личном кабинете"
                                                    />
                                                    <Input
                                                        label="Условия бронирования"
                                                        hint="На данный момент для гостей бронирование доступно только при 100% оплате на сайте"
                                                        noHover
                                                        inputProps={{
                                                            placeholder: "Полная предоплата",
                                                            disabled: true,
                                                            value: "Полная предоплата",
                                                        }}
                                                    />
                                                    <HotelTariffFood
                                                        values={values}
                                                        onChange={(key, newValue) => {
                                                            setFieldValue(key, newValue);
                                                        }}
                                                        errorSettings={{
                                                            showOnChange: isSubmittingForm,
                                                        }}
                                                        optionsTypeFood={optionsTypeFood}
                                                        disabled={isDisabled}
                                                    />
                                                    <HotelTariffCancelReserv
                                                        values={values}
                                                        onChange={(key, newValue) => {
                                                            setFieldValue(key, newValue);
                                                        }}
                                                        errorSettings={{
                                                            showOnChange: isSubmittingForm,
                                                        }}
                                                        disabled={isDisabled}
                                                    />
                                                </Form>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ) : (
                                    <StatusRequestCard
                                        title={{
                                            success: `Тариф \u00ab${createdName}\u00bb сохранен`,
                                            error: (
                                                <>
                                                    {"Не удалось сохранить тариф "}
                                                    <br />
                                                    {`\u00ab${createdName}\u00bb`}
                                                </>
                                            ),
                                        }}
                                        subtitle={{
                                            success: (
                                                <>
                                                    Добавьте все существующие тарифы,
                                                    <br />
                                                    они понадобятся при создании номеров
                                                </>
                                            ),
                                            error: StatusRequestCardSubtitleError,
                                        }}
                                        handleClose={() => {
                                            const isSuccessRequest = status === StatusRequestEnum.Success;
                                            if (isSuccessRequest) {
                                                onClose(isSuccessRequest);
                                            } else {
                                                setStatus(null);
                                            }
                                            setCreatedName(null);
                                        }}
                                        status={status}
                                        handleRepeat={() => {
                                            onSubmitForm(values);
                                        }}
                                        buttonTitle={{ addMore: "Добавить еще тариф" }}
                                        handleAddMore={() => {
                                            resetForm();
                                            setStatus(null);
                                            setIsSubmittingForm(false);
                                            setCreatedName(null);
                                        }}
                                        handleBeforeClose={handleSendEvent}
                                    />
                                )}
                            </ModalWindowRight>
                        );
                    }}
                </Formik>
            )}
        </HotelDataContext.Consumer>
    );
};

export default HotelTariff;
