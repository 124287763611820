import { UploadFilesType } from "@russpass/partner-front-ui";
import FormWrapper from "../../components/FormWrapper";
import UploadPhotosField from "./fields/UploadPhotosField";
import styles from "./styles.module.sass";
import { Form, useFormikContext } from "formik";
import React from "react";
import { NavbarApartmentsNewName } from "../../constants";
import SubmitButton from "../../components/SubmitButton";
import { BaseStepProps } from "../../types";
import { ArchiveButton } from "../../components/ArchiveButton";

const withNamespace = (name: string) => `${NavbarApartmentsNewName.PHOTO}.${name}`;

export const PhotoStep = ({
    isNew,
    isArchive,
    isBlockedFields,
    isValid,
    isDirty,
    submit,
    onEditArchive,
    isEditArchive,
    setStep,
}: BaseStepProps) => {
    const { getFieldProps } = useFormikContext();

    const value = (getFieldProps(withNamespace("photos")).value as UploadFilesType[]) || undefined;

    return (
        <FormWrapper className={styles.Wrapper}>
            <Form>
                <UploadPhotosField name={withNamespace("photos")} disabled={isBlockedFields} />
                {isNew && (
                    <SubmitButton isValid={!!value?.length} onClick={() => setStep(NavbarApartmentsNewName.TARIFFS)}>
                        Далее
                    </SubmitButton>
                )}
                {((!isNew && isDirty) || isEditArchive) && (
                    <SubmitButton isValid={isValid} fixed={!isEditArchive} onClick={submit}>
                        Отправить на модерацию
                    </SubmitButton>
                )}
                {isArchive && <ArchiveButton onEditArchive={onEditArchive} />}
            </Form>
        </FormWrapper>
    );
};
