import { Header, HeaderInfo, HeaderSubtitle, HeaderTitle } from "../components/Header";
import { Container } from "../components/Container";
import { ModalWindowRight, ActionButtonWrapper, useViewportContext } from "@russpass/partner-front-ui";
import { RentHousesBooking } from "../../../../../types/rentHouses";
import styles from "./InsuranceModal.module.sass";
import { useInformationPages } from "../../../../../api/hooks/cmsApi";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";
import { useEffect } from "react";
import classNames from "classnames";

type Props = { booking: RentHousesBooking; close: () => void };

export const InsuranceModal = ({ booking, close }: Props) => {
    const { data, isLoading } = useInformationPages({ language: "ru", slugs: ["renthouse_b2b_insurance"] });

    useEffect(() => {
        sendEvent(AnalyticEvent.insurance_page);
    }, []);

    const { isMobile } = useViewportContext();
    const description = data?.items[0]?.blocks?.[0]?.description || "";
    const insuranceLink = data?.items[0]?.blocks?.[0].links[0].url || "";

    return (
        <ModalWindowRight
            isOpened
            onClose={close}
            isLoadingWithoutContent={isLoading}
            headerComponent={
                <Header>
                    <HeaderInfo>
                        <HeaderTitle>Страхование жилья</HeaderTitle>
                        <HeaderSubtitle>{booking.object.title}</HeaderSubtitle>
                    </HeaderInfo>
                </Header>
            }
            ComponentFooter={
                <ActionButtonWrapper>
                    <button
                        type="button"
                        className="button button--primary button--full-width"
                        onClick={() => {
                            sendEvent(AnalyticEvent.insurance_case_click);
                            window.open(insuranceLink);
                        }}
                    >
                        Перейти на сайт страховой
                    </button>
                </ActionButtonWrapper>
            }
        >
            <Container
                className={classNames(styles.Content, {
                    [styles.Mobile]: isMobile,
                })}
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            />
        </ModalWindowRight>
    );
};
