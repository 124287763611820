import useRestaurants from "./useRestaurants";
import { navbarRestaurantsAll } from "./constants";
import { EmptyList, CommonLayout, ObjectList } from "@russpass/partner-front-ui";
import "./styles.sass";
import { useMemo } from "react";
import { sendEventByObjectItemClick } from "../../../product_analytics/analytics";
import history from "../../../history";

const RestaurantsAll = () => {
    const {
        restaurantsList,
        isEmptyTotal,
        isLoading,
        activeTabKey,
        setActiveTabKey,
        onClickAddRestaurant,
        onClickDeleteRestaurant,
    } = useRestaurants();

    const topRightButton = useMemo(
        () => ({
            title: "Добавить объект",
            onClick: onClickAddRestaurant,
            isAddButton: true,
        }),
        [onClickAddRestaurant]
    );

    return (
        <CommonLayout
            pageTitle="Объекты"
            isLoadingWithoutContent={isLoading}
            navbar={navbarRestaurantsAll}
            activeTabName={activeTabKey}
            handlerChangeActiveTab={setActiveTabKey}
            topRightButton={topRightButton}
        >
            {isEmptyTotal ? (
                <EmptyList onClick={onClickAddRestaurant} buttonClassName="button--primary" />
            ) : (
                restaurantsList.map(({ dataName, list }) => (
                    <div className="object_cards" data-name={dataName} key={dataName}>
                        <ObjectList
                            list={list}
                            history={history}
                            onDelete={onClickDeleteRestaurant}
                            handleBeforeSelect={sendEventByObjectItemClick}
                        />
                    </div>
                ))
            )}
        </CommonLayout>
    );
};

export default RestaurantsAll;
