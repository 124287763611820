import useSWR, { SWRConfiguration } from "swr";
import { russpassApiServices } from "../../constants/api";
import { Sorting } from "../../constants/filters";
import { RatingsResponse, RatingsStatus } from "../../containers/services/components/ratings/types";
import { PaginatedListParams, PaginatedListResponse, ServerResponse } from "../../types/api";
import {
    RentHousesBooking,
    RentHousesBookingByUid,
    RentHousesBookingEventMark,
    RentHousesBookingEventMarkDetails,
    RentHousesBookingItem,
    RentHousesBookingMark,
    RentHousesBookingStatus,
    RentHousesCancellationPolicyItem,
    RentHousesDictionaryItem,
    RentHousesObject,
    RentHousesObjectFullInfo,
    RentHousesObjectStatus,
    RentHousesOwnerProfile,
} from "../../types/rentHouses";
import fetchRequest from "../manager";

export const useRentHousesDictionary = (
    params: PaginatedListParams & {
        dictionary: string;
        useForRenthouses?: boolean;
        sort?: string;
        slug?: string;
        category?: string;
        id?: string;
        term?: string;
    },
    { isDisabledRequest = false, ...config }: SWRConfiguration & { isDisabledRequest?: boolean } = {}
) =>
    useSWR(
        !isDisabledRequest ? { url: `${russpassApiServices.rentHouses}/dictionary`, params } : null,
        ({ url, params }) => fetchRequest.get(url, params) as Promise<PaginatedListResponse<RentHousesDictionaryItem>>,
        config
    );

export const useRentHousesOwnerProfile = (config?: SWRConfiguration, disabled?: boolean) =>
    useSWR(
        !disabled ? { url: `${russpassApiServices.rentHouses}/partner/owner-profile` } : null,
        ({ url }) =>
            fetchRequest.get(url, null, {
                isProtected: true,
            }) as Promise<ServerResponse<RentHousesOwnerProfile>>,
        config
    );

export const useRentHousesObjects = (
    params: PaginatedListParams & {
        status?: RentHousesObjectStatus | RentHousesObjectStatus[];
        sort?: Sorting;
        blocked?: boolean;
    },
    config?: SWRConfiguration,
    disabled?: boolean
) =>
    useSWR(
        !disabled ? { url: `${russpassApiServices.rentHouses}/partner/objects`, params } : null,
        ({ url, params }) =>
            fetchRequest.get(
                url,
                params,
                {
                    isProtected: true,
                },
                { arrayFormat: "comma" }
            ) as Promise<PaginatedListResponse<RentHousesObject>>,
        config
    );

export const useRentHousesObject = (id: string | undefined, config?: SWRConfiguration) =>
    useSWR(
        id ? { url: `${russpassApiServices.rentHouses}/partner/objects/${id}` } : undefined,
        ({ url }) =>
            fetchRequest.get(url, undefined, {
                isProtected: true,
            }) as Promise<ServerResponse<RentHousesObjectFullInfo>>,
        config
    );

export const useRentHousesCancellationPolicy = (params: PaginatedListParams, config?: SWRConfiguration) =>
    useSWR(
        { url: `${russpassApiServices.rentHouses}/cancellation-policy`, params },
        ({ url, params }) =>
            fetchRequest.get(url, params, {
                isProtected: true,
            }) as Promise<PaginatedListResponse<RentHousesCancellationPolicyItem>>,
        config
    );

export const useRentHousesBookings = (
    params: PaginatedListParams & {
        stayDateFrom?: string;
        stayDateTo?: string;
        objectId?: string;
        status?: RentHousesBookingStatus | RentHousesBookingStatus[];
        sort?: Sorting;
    },
    config?: SWRConfiguration,
    disabled?: boolean
) =>
    useSWR(
        !disabled ? { url: `${russpassApiServices.rentHouses}/partner/bookings`, params } : undefined,
        ({ url, params }) =>
            fetchRequest.get(
                url,
                params,
                {
                    isProtected: true,
                },
                { arrayFormat: "comma" }
            ) as Promise<PaginatedListResponse<RentHousesBookingItem>>,
        config
    );

export const useRentHousesBooking = (id: string | undefined, config?: SWRConfiguration) =>
    useSWR(
        id ? { url: `${russpassApiServices.rentHouses}/partner/bookings/${id}` } : undefined,
        ({ url }) =>
            fetchRequest.get(url, undefined, {
                isProtected: true,
            }) as Promise<ServerResponse<RentHousesBooking>>,
        config
    );

export const useRentHousesBookingMark = (id: string | undefined, config?: SWRConfiguration) =>
    useSWR(
        id ? { url: `${russpassApiServices.rentHouses}/partner/bookings/${id}/marks` } : undefined,
        ({ url }) =>
            fetchRequest.get(url, undefined, {
                isProtected: true,
            }) as Promise<ServerResponse<RentHousesBookingMark>>,
        config
    );

export const useRentHousesBookingUnavailableDays = (
    { id, year, month }: { id?: string; year?: number; month?: number },
    config?: SWRConfiguration
) => {
    const monthStr = month && month < 10 ? `0${month}` : `${month}`;

    return useSWR(
        id && year && month
            ? { url: `${russpassApiServices.rentHouses}/partner/objects/${id}/unavailable/${year}/${monthStr}` }
            : undefined,
        ({ url }) =>
            fetchRequest.get(url, undefined, {
                isProtected: true,
            }) as Promise<ServerResponse<{ days: string[] }>>,
        config
    );
};

export const useRentHousesBookingEventRating = (externalEventId?: string, config?: SWRConfiguration) =>
    useSWR(
        externalEventId ? { url: `${russpassApiServices.rating}/events/${externalEventId}` } : undefined,
        ({ url }) =>
            fetchRequest.get(url, undefined, {
                isProtected: true,
            }) as Promise<RatingsResponse>,
        config
    );

export const useRentHousesBookingEventRatingMarksList = (
    params: PaginatedListParams & {
        status?: RatingsStatus | RatingsStatus[];
        sort?: Sorting;
        valueFrom?: number;
        valueTo?: number;
    },
    externalEventId?: string,
    config?: SWRConfiguration
) =>
    useSWR(
        externalEventId ? { url: `${russpassApiServices.rating}/events/${externalEventId}/marks`, params } : undefined,
        ({ url, params }) =>
            fetchRequest.get(
                url,
                params,
                {
                    isProtected: true,
                },
                { arrayFormat: "comma" }
            ) as Promise<PaginatedListResponse<RentHousesBookingEventMark>>,
        config
    );

export const useRentHousesBookingEventRatingMark = (id?: number, config?: SWRConfiguration) =>
    useSWR(
        id ? { url: `${russpassApiServices.rating}/marks/${id}` } : undefined,
        ({ url }) =>
            fetchRequest.get(url, undefined, {
                isProtected: true,
            }) as Promise<ServerResponse<RentHousesBookingEventMarkDetails>>,
        config
    );

export const useRentHousesBookingByUid = (uid?: string, config?: SWRConfiguration) =>
    useSWR(
        uid ? { url: `${russpassApiServices.rentHouses}/partner/bookings/by-order-uid/${uid}` } : undefined,
        ({ url }) =>
            fetchRequest.get(url, undefined, {
                isProtected: true,
            }) as Promise<ServerResponse<RentHousesBookingByUid>>,
        config
    );
