import keycloak from "../config/keycloak";

export default class KeycloakManager {
    static getAccessToken = () => {
        return keycloak.token;
    };

    static getParsedAccessToken = () => {
        return keycloak.tokenParsed;
    };

    static getRefreshToken = () => {
        return keycloak.refreshToken;
    };

    static updateToken = (second: number) => {
        return keycloak.updateToken(second);
    };

    static login = (redirectUri?: string) => {
        return keycloak.login({
            redirectUri,
        });
    };

    static logout = (redirectUri?: string) => {
        return keycloak.logout({
            redirectUri,
        });
    };

    static getIsAuthenticated = () => {
        return keycloak.authenticated;
    };
}
