import { BaseFieldProps } from "./types";
import { InputField } from "@russpass/partner-front-ui";
import React from "react";
import { maskedInputNumbers } from "../../../../../../utils/validation";

type Props = BaseFieldProps;

export const BankTreasuryAccountField = ({ name }: Props) => (
    <InputField
        label="Казначейский счет"
        name={name}
        noHover
        placeholder="30101810400000000225"
        hint="При наличии"
        errorSettings={{ showCustomError: true }}
        renderComponent={maskedInputNumbers}
        maxLength={20}
    />
);
