import { ListData } from "../../services/components/ratings/types";
import { ErrorResponse, ServerResponse } from "../../../types/api";

export type NotificationsTemplateListItem = {
    templateId: string;
    title: string;
};

export type NotificationsTemplateListResponse = ErrorResponse & {
    items: NotificationsTemplateListItem[];
};

export type HotelNotificationItem = {
    id: string;
    title: string;
    hotelId: string;
    threshold: number;
    recommendation: string;
    active: boolean;
    lastFireDate: string;
    read?: boolean;
    channels: NotificationChannel[];
    description?: string;
    hotelTitle?: string;
    templateId: string;
};

export type HotelNotificationsListResponse = ServerResponse<ListData<HotelNotificationItem[]>>;

export type NotificationsTemplate = {
    templateId: string;
    title: string;
    description: string;
    recommendation: string;
    hint: string;
    events: {
        eventId: string;
        eventName: string;
    }[];
};

export type HotelNotificationsTemplateResponse = ServerResponse<NotificationsTemplate>;

export enum NotificationChannelKey {
    LKP = "LKP",
    EMAIL = "EMAIL",
}

export type NotificationChannel = {
    key: NotificationChannelKey;
    address?: string;
    name?: string;
    enabled?: boolean;
};

export type HotelNotification = {
    active: boolean;
    id?: string;
    title: string;
    hotelId?: string;
    templateId: string;
    threshold?: number;
    channels: NotificationChannel[];
};

export type HotelNotificatorChannel = {
    key: NotificationChannelKey;
    name: string;
};

export type HotelNotificatorChannelsResponse = ErrorResponse & {
    items: HotelNotificatorChannel[];
};

export type NotificatorHotelsResponse = ServerResponse<{
    hotels: string[];
}>;
