import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useRentHousesOwnerProfile } from "../../../../../api/hooks/rentHouses";
import { ModalWindowRight, ReactFilesFileWithUri, StatusRequestEnum } from "@russpass/partner-front-ui";
import { russpassApiUrl } from "../../../../../constants/api";
import { DialoguesModalHeader } from "./DialoguesModalHeader";
import { DialogueMessage, DialogueModel } from "../../types/DialogueModel";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import getCustomErrorMessage from "../../../../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../../../../constants/errors";
import { Creator } from "../../constants";
import { useGetDialogue, useGetMessageCount } from "../../../../../api/hooks/dialogues";
import Message from "../components/message";
import { orderBy, uniqBy } from "lodash";
import moment from "moment";
import { updateDialogue } from "../../../../../api/dialogues";
import { DialogueMessagesType } from "../../types/DialoguesMessagesResponse";
import { uploadFile } from "../../../../../api/attach";
import { Sorting } from "../../../../../constants/filters";
import routes from "../../../../../constants/routes";
import userIcon from "../../../../../assets/images/icons/ic_user_40.svg";
import systemUserIcon from "../../../../../assets/images/icons/rp_circle.svg";
import { getMessageText } from "../../utils/getMessageText";
import SendMessageForm from "../components/send-message-form";
import MessagesContainer from "../components/messages-container";
import { DIALOGUE_PAGE_SIZE, REFRESH_INTERVAL_MS, SAFE_PAGE_SIZE } from "../constants";

type DialogueModalProps = {
    selectedDialogue: DialogueModel | undefined;
    onClose: () => void;
};

export type DialogueMessageFormModel = {
    message: string;
    answerFiles: ReactFilesFileWithUri[];
};

export const DialogueModal = ({ selectedDialogue, onClose }: DialogueModalProps) => {
    const fileRef = useRef<any>();
    const scrollingRef = useRef<any>();
    const previousScrollHeightRef = useRef<number>(0);
    const previousMessageCountRef = useRef<number>(0);
    const debounceTimeoutRef = useRef<number>();

    const [newPage, setNewPage] = useState<number>(0);
    const [pageSizeUp, setPageSizeUp] = useState<number>(DIALOGUE_PAGE_SIZE);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dialogue, setDialogue] = useState<DialogueModel | undefined>();
    const [items, setItems] = useState<DialogueMessage[]>([]);
    const [domItems, setDomItems] = useState<ReactElement[]>([]);

    const { data: messagesCount } = useGetMessageCount(
        selectedDialogue?.object?.cmsId || "",
        `${selectedDialogue?.user?.externalId}`,
        { refreshInterval: REFRESH_INTERVAL_MS, isDisabledRequest: !selectedDialogue }
    );

    const {
        data: dialogueDataUp,
        error: errorUp,
        isLoading: loadingUp,
        mutate: mutateUp,
    } = useGetDialogue(
        selectedDialogue?.object?.cmsId || "",
        selectedDialogue?.user?.externalId || 0,
        {
            page: 1,
            pageSize: pageSizeUp + SAFE_PAGE_SIZE,
            sort: Sorting.CreatedAtDesc,
        },
        {
            revalidateIfStale: true,
            revalidateOnFocus: false,
            revalidateOnReconnect: true,
            isDisabledRequest: !selectedDialogue,
        }
    );

    const {
        data: dialogueDataDown,
        error: errorDown,
        isLoading: loadingDown,
        mutate: mutateDown,
    } = useGetDialogue(
        selectedDialogue?.object?.cmsId || "",
        selectedDialogue?.user?.externalId || 0,
        {
            page: newPage,
            pageSize: DIALOGUE_PAGE_SIZE,
            sort: Sorting.CreatedAtDesc,
        },
        {
            revalidateIfStale: true,
            revalidateOnFocus: false,
            revalidateOnReconnect: true,
            isPaused: () => newPage === 0,
            isDisabledRequest: !selectedDialogue,
        }
    );

    const { data: ownerProfileData } = useRentHousesOwnerProfile();

    useEffect(() => {
        if (!dialogueDataUp) return;

        if (dialogueDataUp.dialogue.id !== dialogue?.id) {
            setDialogue(dialogueDataUp?.dialogue);
        }
        if (scrollingRef.current) {
            previousScrollHeightRef.current = (scrollingRef.current as HTMLDivElement).scrollHeight;
        }
        addItems(dialogueDataUp);
        scrollToBottom();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogueDataUp]);

    useEffect(() => {
        if (!dialogueDataDown) return;

        if (dialogueDataDown.dialogue.id !== dialogue?.id) {
            setDialogue(dialogueDataDown?.dialogue);
        }
        if (scrollingRef.current) {
            previousScrollHeightRef.current = (scrollingRef.current as HTMLDivElement).scrollHeight;
        }
        addItems(dialogueDataDown);
        scrollToBottom(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogueDataDown]);

    useEffect(() => {
        if (!messagesCount || messagesCount <= previousMessageCountRef.current) return;

        if (previousMessageCountRef.current === 0) {
            previousMessageCountRef.current = messagesCount;
            return;
        }

        const newPageSize = messagesCount - previousMessageCountRef.current;
        setPageSizeUp(newPageSize);
        previousMessageCountRef.current = messagesCount;
        mutateUp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messagesCount]);

    const onScroll = ({ target }: { target: EventTarget }) => {
        const elem = target as HTMLDivElement;
        const leftToTop = elem.scrollTop + elem.scrollHeight - elem.clientHeight;
        if (!messagesCount || leftToTop !== 0) return;

        clearTimeout(debounceTimeoutRef.current);
        debounceTimeoutRef.current = window.setTimeout(() => {
            if (items.length >= messagesCount) return;

            const pages = Math.floor(items.length / DIALOGUE_PAGE_SIZE);
            const page = pages + 1;
            setNewPage(page);
            mutateDown();
        });
    };

    const addItems = (data?: DialogueMessagesType) => {
        const newItems = data?.items;
        if (!newItems?.length) return;

        const allItems = orderBy(uniqBy([...items, ...newItems], "id"), "createdAt", "asc");

        let latestDate = 0;
        const domItems = allItems.map((item) => {
            const theDate = moment(item.createdAt).startOf("D").unix();
            const showDate = theDate !== latestDate;
            if (showDate) {
                latestDate = theDate;
            }

            const image = {
                [Creator.CLIENT]: data?.dialogue.user?.image,
                [Creator.PARTNER]: ownerProfileData?.data.image
                    ? `${russpassApiUrl}/partnership/files/images/${ownerProfileData.data.image}`
                    : undefined,
            }[item.creator as string];

            const fromCurrentUser = item.creator === Creator.PARTNER;

            return (
                <Message
                    key={item.id}
                    image={image}
                    imagePlaceholder={item.creator === Creator.SYSTEM ? systemUserIcon : userIcon}
                    content={getMessageText(item.message)}
                    attachments={item.files}
                    date={item.createdAt}
                    showDate={showDate}
                    fromCurrentUser={fromCurrentUser}
                    rtl={fromCurrentUser}
                />
            );
        });
        setItems(allItems);
        setDomItems(domItems);
    };

    const handleSubmit = useCallback(
        async ({ message, answerFiles }, { resetForm }) => {
            setIsSubmitting(true);
            try {
                const answerFileUrls: string[] = [];

                for (const answerFile of answerFiles) {
                    if (typeof answerFile === "string") {
                        answerFileUrls.push(answerFile);
                    } else {
                        const saveResult = await uploadFile(answerFile);
                        if (!saveResult) return;

                        answerFileUrls.push(saveResult.data.id);
                    }
                }
                const res = await updateDialogue(
                    selectedDialogue?.object?.cmsId || "",
                    selectedDialogue?.user?.externalId || 0,
                    {
                        message: !!message ? message : undefined,
                        files: answerFileUrls,
                    }
                );
                if (res) {
                    answerFiles.forEach((file: ReactFilesFileWithUri) => {
                        fileRef.current.removeFile(file);
                    });
                    resetForm();
                    (document.querySelector("textarea[name=message]") as HTMLTextAreaElement)?.focus();
                }
            } catch (e) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
                });
            } finally {
                setIsSubmitting(false);
                mutateUp();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dialogue, onClose]
    );

    useEffect(() => {
        const error = errorUp || errorDown;
        if (!error) return;

        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: getCustomErrorMessage(error, COMMON_RELOAD_ERROR),
        });

        close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorUp, errorDown]);

    const scrollToBottom = (keepPosition?: boolean) => {
        const scrollElement = scrollingRef.current;
        if (!scrollElement) return;

        const currentScrollHeight = keepPosition ? previousScrollHeightRef.current : 0;
        setTimeout(() => {
            const scrollTo = scrollElement.scrollHeight - currentScrollHeight;
            if (scrollTo === 0) return;

            // On taking the data from buffer the method can't catch the right measuring moment
            // so we prevent this case by the condition
            scrollElement.scrollTop = scrollElement.scrollHeight - currentScrollHeight;
        });
    };

    const close = () => {
        previousScrollHeightRef.current = 0;
        previousMessageCountRef.current = 0;
        setNewPage(0);
        setPageSizeUp(DIALOGUE_PAGE_SIZE);
        setDialogue(undefined);
        setItems([]);
        setDomItems([]);
        onClose();
        eventDispatcher.updateCounter({ route: routes.dialogues });
    };

    return (
        <ModalWindowRight
            customClassNames="dialogues"
            isOpened={!!selectedDialogue}
            onClose={close}
            headerComponent={
                dialogue ? (
                    <DialoguesModalHeader
                        user={dialogue.user}
                        object={dialogue.object}
                        booking={selectedDialogue?.booking}
                    />
                ) : undefined
            }
            ComponentFooter={<SendMessageForm fileRef={fileRef} blocked={dialogue?.blocked} onSubmit={handleSubmit} />}
            unmountContentOnClose
        >
            <MessagesContainer
                ref={scrollingRef}
                isLoading={loadingUp || loadingDown || isSubmitting}
                onScroll={onScroll}
            >
                {domItems}
            </MessagesContainer>
        </ModalWindowRight>
    );
};
