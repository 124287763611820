export enum SignLevelBasicEnum {
    Сharter = "Устав",
    PowerOfAttorney = "Доверенность",
}

export enum NdsRateEnum {
    WithoutNDS = "Без НДС",
    ZeroPercent = "0%",
    TenPercent = "10%",
    TwentyPercent = "20%",
}

export enum CodeNdsRateEnum {
    WithoutNDS = "no_vat",
    ZeroPercent = "vat_0",
    TenPercent = "vat_10",
    TwentyPercent = "vat_20",
}

export enum LegalStateEnum {
    SIMPLE_PERSON = "SIMPLE_PERSON",
    SELF_EMPLOYED = "SELF_EMPLOYED",
    INDIVIDUAL = "INDIVIDUAL",
    LEGAL_ENTITY = "LEGAL_ENTITY",
}

export enum SignatoryRoleEnum {
    OWNER = "OWNER",
    AGENT = "AGENT",
}

export enum FinancialSecurityTypeEnum {
    BANK_GUARANTEE = "BANK_GUARANTEE",
    INSURANCE_CONTRACT = "INSURANCE_CONTRACT",
}
