import { ServerResponse } from "../../../../types/api";

export type RatingsBadge = {
    code: string;
    icon?: string;
    description: string;
    count: number;
};

export type RatingsData = {
    mark: {
        generalRating: number;
        badges: RatingsBadge[];
    };
};

export type RatingsResponse = ServerResponse<RatingsData>;

export type ListData<T> = {
    total: number;
    page: number;
    pageSize: number;
    items: T;
};

export type ExternalUser = {
    id: number;
    firstName: string;
    lastName: string;
    middleName?: string;
    email: string;
    phone?: string;
    age?: number;
    gender?: Gender;
};

export type RatingsMarkBase = {
    id: number;
    creationalDate: string;
    updateDate: string;
    value: number;
    badges: number[];
    status: RatingsStatus;
    externalUser: ExternalUser;
};

export type RatingsMark = RatingsMarkBase & {
    externalUser: ExternalUser;
    order: {
        uid: string;
        createDate: string;
        region?: string;
        category: Category;
        tripPurpose?: TripPurpose;
    };
};

export type RatingsMarkSuggestion = {
    id: number;
    externalUser: ExternalUser;
    mark?: RatingsMarkBase;
    markVersionDto?: {
        badges: string[];
        creationalDate: string;
        id: number;
        status: RatingsStatus.PUBLISHED | RatingsStatus.IN_DISPUTE;
        value: number;
    };
    orderUid: string;
};
export type RatingsMarksList = ListData<RatingsMark[]>;
export type RatingsMarkSuggestionsList = ListData<RatingsMarkSuggestion[]>;
export type RatingsMarkResponse = ServerResponse<RatingsMarksList>;
export type RatingsMarkSuggestionsResponse = ServerResponse<RatingsMarkSuggestionsList>;

export type RatingsMarkCard = {
    id: number;
    creationalDate: string;
    dispute?: boolean;
    updateDate: string;
    eventExternalId: string;
    value: number;
    badges: RatingsBadge[];
    status: string;
    loyalityExtraAccrued: boolean;
    externalUser: Omit<ExternalUser, "id"> & { externalId: number };
    orderId: number;
    visitDateFrom: string;
    visitDateTo: string;
    positive: string;
    negative: string;
    comment: string;
};

export type RatingsMarkCardV2 = {
    id: number;
    mark?: Omit<RatingsMark, "badges"> & {
        badges: RatingsBadge[];
        eventExternalId: string;
        rateCandidateId: number;
        loyalityExtraAccrued: boolean;
    };
    markVersions: {
        badges: RatingsBadge[];
        creationalDate: string;
        id: number;
        isCurrent: boolean;
        status: RatingsStatus.PUBLISHED | RatingsStatus.IN_DISPUTE;
        value: number;
    }[];
    externalUser: ExternalUser;
    order: {
        uid: string;
        createDate: string;
        region?: {
            geo: {
                country?: {
                    names: {
                        ru: string;
                    };
                };
                subdivisions?: {
                    names: {
                        ru: string;
                    };
                }[];
                city?: {
                    names: {
                        ru: string;
                    };
                };
            };
        };
        category: Category;
        tripPurpose?: TripPurpose;
    };
};

export type RatingsMarkItemResponse = ServerResponse<RatingsMarkCard>;

export type RatingsMarkItemV2Response = ServerResponse<RatingsMarkCardV2>;

export type DisputeMarkData = { description: string; files: string[] };

export enum RatingsStatus {
    ALL = "ALL",
    PUBLISHED = "PUBLISHED",
    IN_DISPUTE = "IN_DISPUTE",
    DECLINED_FROM_DISPUTE = "DECLINED_FROM_DISPUTE",
}

export enum RatingsStatusText {
    DISPUTE = "Отзыв на оспаривании",
    DECLINED = "Отзыв снят с публикации",
}

export enum Gender {
    MALE = "MALE",
    FEMALE = "FEMALE",
}

export enum Category {
    GROUP = "GROUP",
    SINGLE = "SINGLE",
    CHILDREN = "CHILDREN",
}

export enum TripPurpose {
    REST = "REST",
    BUSINESS = "BUSINESS",
}
