export enum PartnerStatus {
    NEW_MESSAGE = "NEW_MESSAGE",
    READ = "READ",
    ARCHIVED = "ARCHIVED",
}
export enum DialogueMessageStatus {
    NEW = "NEW",
    READ = "READ",
}
export enum Creator {
    CLIENT = "CLIENT",
    PARTNER = "PARTNER",
    SYSTEM = "SYSTEM",
}
export enum DialoguesType {
    GUESTS = "GUESTS",
    SUPPORT = "SUPPORT",
}

export enum DialogueMessageText {
    AUTO_CANCEL_WITHOUT_PARTNER_CONFIRMATION = "AUTO_CANCEL_WITHOUT_PARTNER_CONFIRMATION",
    AUTO_CANCEL_WITHOUT_PAYMENT = "AUTO_CANCEL_WITHOUT_PAYMENT",
    BOOKING_CONFIRMED_BY_OWNER = "BOOKING_CONFIRMED_BY_OWNER",
    PARTNER_CANCEL = "PARTNER_CANCEL",
    PARTNER_REFUND = "PARTNER_REFUND",
    PARTNER_REFUND_BY_SUPPORT = "PARTNER_REFUND_BY_SUPPORT",
    PARTNER_REPEAL_BY_ADMIN = "PARTNER_REPEAL_BY_ADMIN",
    USER_CANCEL = "USER_CANCEL",
    USER_REFUND = "USER_REFUND",
    USER_REFUND_BY_SUPPORT = "USER_REFUND_BY_SUPPORT",
    USER_REPEAL_BY_ADMIN = "USER_REPEAL_BY_ADMIN",
}
