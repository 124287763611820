import React, { useCallback } from "react";
import styles from "./styles.module.sass";
import classNames from "classnames";
import { EventPreviewDataType } from "../utils";
import { getImageById } from "../../../../../../api/files";

const GalleryPreview = ({ images }: Pick<EventPreviewDataType, "images">) => {
    const getCardStyle = useCallback((id: string) => (id ? { backgroundImage: `url(${getImageById(id)})` } : {}), []);
    const restPhotoCount = images.length - 4;
    return (
        <div className={styles.container}>
            <div className={styles.item} style={getCardStyle(images[0]?.id)} />
            <div className={styles.item} style={getCardStyle(images[1]?.id)} />
            <div className={classNames(styles.item, styles.item__small)} style={getCardStyle(images[2]?.id)} />
            <div
                className={classNames(styles.item, styles.item__small, {
                    [styles.item__small__blackout]: restPhotoCount > 0,
                })}
            >
                <div
                    className={classNames(styles.item, styles.item__small__lastImage, {
                        [styles.item__small__lastImage__blackout]: restPhotoCount > 0,
                    })}
                    style={getCardStyle(images[3]?.id)}
                />
                {restPhotoCount > 0 && (
                    <div className={styles.item__small__text}>
                        {restPhotoCount}
                        <p>{" фото"}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GalleryPreview;
