import React, { useMemo, useState, useCallback } from "react";
import { CommonLayout, StatusRequestEnum, UploadPhotosItem, useModalAlertContext } from "@russpass/partner-front-ui";
import { navbarServiceComment, NavbarServiceNamesEnum, navbarServiceRatings } from "./helpers/constants";
import ServiceInfo from "./components/service-info";
import "./styles.sass";
import { NotificationProps } from "../../../components/notification";
import useService from "./hooks/useService";
import history from "../../../history";
import { useServiceActions } from "./hooks/useServiceActions";
import eventDispatcher from "../../../lib/event-dispatcher";
import ServiceComment from "../components/moderation-comment/service-comment";
import Tariffs from "../components/tariffs/tariffs";
import { useLocation } from "react-router-dom";
import routes from "../../../constants/routes";
import { navbarTypeEventNames } from "../all/constants";
import { Ratings } from "../components/ratings/ratings";
import { ServiceTariffsContext } from "../components/tariffs/tariffs-context";
import useTariffs from "../components/tariffs/useTariffs";
import ActionsContainer from "../components/actions-container";
import Photos from "../components/photos";
import ServicePlace from "../components/service-place";
import useFacility from "../components/service-place/useFacility";
import { StatusNamesServiceItem } from "../all/types";
import { alertConfirmSaveText, alertNoBasicTariffText, backButtonText } from "./helpers/modalTexts";
import { EventPreviewModal } from "../components/EventPreviewModal";
import { getServiceEventPreviewData } from "./utils";

import { OnlineSaleSwitch } from "../components/OnlineSaleSwitch";
import { ReactComponent as IcExternalLink } from "../../../assets/images/icons/ic_external_link.svg";

const ServiceComponent = () => {
    const [isOpenPreviewWindow, setIsOpenPreviewWindow] = useState(false);
    const [isEditMode, setEditMode] = useState(false);

    const openServicePreviewWindow = useCallback(() => setIsOpenPreviewWindow(true), [setIsOpenPreviewWindow]);
    const closeServicePreviewWindow = useCallback(() => setIsOpenPreviewWindow(false), [setIsOpenPreviewWindow]);

    const { tariffs, loadTariffList, onChangeTariffList, isLoadingTariffList } = useTariffs();
    const { openModalAlert } = useModalAlertContext();

    const location = useLocation<{ status: string }>();

    const callNotification = (body: NotificationProps) => {
        eventDispatcher.setNotification({
            ...body,
        });
    };

    const {
        data,
        initialData,
        step,
        optionsEventTypes,
        optionsAgeRestriction,
        optionsNdsRate,
        changeTab,
        onChangeData,
        reloadServiceData,
        initialLoading,
        hasChangesExceptBilling,
        hasBillingChanges,
    } = useService({ callNotification, loadTariffList });

    const {
        facility,
        city,
        region,
        selectedPartnerFacility,
        isExistingPartnerFacility,
        optionsPlaceTypes,
        optionsFacilityPlaces,
        onChangeFacility,
        onChangeCity,
        onChangeRegion,
        hasChangesFacility,
        changePartnerFacility,
        reloadFacilityData,
        isLoadingFacility,
    } = useFacility({ callNotification, facilityId: data.facility });

    const reloadData = () => {
        reloadServiceData();
        reloadFacilityData();
    };

    const {
        sendServiceToModerate,
        sendServiceUpdate,
        isValidFacilityWorkingTime,
        isSubmittingForm,
        isFormSending,
        navbar,
        addTariffForExistingService,
        deleteTariffForExistingService,
        updateTariffForExistingService,
        updateServiceFields,
    } = useServiceActions({
        data,
        initialData,
        tariffs: tariffs.content,
        facility,
        selectedPartnerFacility,
        isExistingPartnerFacility,
        onChangeTariff: onChangeTariffList,
        setEditMode,
        onChangeData,
        reloadData,
    });
    const isCreateMode = data.status === StatusNamesServiceItem.Creating;
    const isViewMode = data.status !== StatusNamesServiceItem.Creating;

    const isViewComment = !!data.isRejected || !!facility.isRejected;
    const hasBasicTariff = tariffs.content.length > 0 && tariffs.content.find((i) => i.isBasic);

    const back = () => {
        history.push({
            pathname: routes.servicesAll,
            state: { ...location?.state, type: navbarTypeEventNames.events },
        });
    };

    const localNavbar = useMemo(() => {
        let _navbar = navbar;
        if (isViewComment) {
            _navbar = navbarServiceComment.concat(_navbar);
        }
        if (isViewMode) {
            _navbar = _navbar.concat(navbarServiceRatings);
        }

        return _navbar;
    }, [navbar, isViewComment, isViewMode]);

    const currentTabIndex = localNavbar.findIndex((tab) => tab.name === step);

    const alertNoBasicTariff = () => {
        changeTab(NavbarServiceNamesEnum.Tariff);
        openModalAlert(alertNoBasicTariffText, () => {});
    };

    const alertConfirmSave = () => {
        openModalAlert(
            alertConfirmSaveText,
            () => sendServiceUpdate(hasBillingChanges, hasChangesExceptBilling),
            () => {}
        );
    };

    const backButton = {
        title: "Назад",
        onClick: () => {
            if (hasBillingChanges && isEditMode) {
                openModalAlert(
                    backButtonText,
                    () => sendServiceUpdate(hasBillingChanges, hasChangesExceptBilling),
                    back
                );
                return;
            }
            back();
        },
    };

    const isLastTab = step === localNavbar[localNavbar.length - 1].name;
    const isTariffsTab = step === NavbarServiceNamesEnum.Tariff;
    const isRatingsTab = step === NavbarServiceNamesEnum.Ratings;

    const actionsObj = {
        setEdit: {
            text: "Редактировать",
            action: () => setEditMode(true),
        },
        sendModerate: {
            text: "Отправить на модерацию",
            action: () => {
                if (!hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                !isFormSending && sendServiceToModerate();
            },
        },
        next: {
            text: "Далее",
            action: () => {
                if (step === NavbarServiceNamesEnum.Tariff && !hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                changeTab(localNavbar[currentTabIndex + 1].name);
            },
        },
        save: {
            text: "Сохранить",
            action: () => (hasBillingChanges ? alertConfirmSave() : setEditMode(false)),
        },
        sendServiceUpdate: {
            text: "Отправить на модерацию",
            action: () => {
                if (!hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                !isFormSending && sendServiceUpdate(hasBillingChanges, hasChangesExceptBilling);
            },
        },
    };

    const nextButton = () => {
        //при обычном просмотре
        if (isViewMode && !isEditMode) {
            return actionsObj["setEdit"];
        }
        //при создании
        if (!isViewMode && !isEditMode) {
            return isLastTab ? actionsObj["sendModerate"] : actionsObj["next"];
        }
        //при редактировании
        if (isEditMode) {
            //если изменили все остальные любые поля
            if (hasChangesExceptBilling || hasChangesFacility) {
                return actionsObj["sendServiceUpdate"];
            }
            // если изменили поля билинга
            if (!hasChangesExceptBilling) {
                return actionsObj["save"];
            }
            // по стандарту
            return actionsObj["save"];
        }
        return { action: () => {}, text: "" };
    };

    const tariffsProviderValue = useMemo(
        () => ({
            tariffs,
            loadTariffList,
            billingProductId: data.billingProductId,
            onChangeTariffList,
        }),
        [data.billingProductId, loadTariffList, onChangeTariffList, tariffs]
    );

    const images = useMemo(
        () => data.servicePhotoList?.map((item) => ({ image: item.id })) || [],
        [data.servicePhotoList]
    );

    const onChangeImage = useCallback(
        (values: UploadPhotosItem[]) =>
            onChangeData({ ...data, servicePhotoList: values.map((item) => ({ id: item.image })) }),
        [data, onChangeData]
    );

    const handleToggleIsCanBuySwitch = async () => {
        if (isViewMode) {
            await updateServiceFields({
                isCanBuy: !data.isCanBuy,
            });
        } else {
            onChangeData({ ...data, isCanBuy: !data.isCanBuy });
            callNotification({
                status: StatusRequestEnum.Success,
                body: data.isCanBuy
                    ? "Продажа на RUSSPASS отключена. Пользователи сайта смогут только ознакомиться с информацией"
                    : "Продажа на RUSSPASS включена",
            });
        }
    };

    const disabledFields = isViewMode && !isEditMode;

    const showSwitch = tariffs.content.length > 0;

    const isVisibleActions = useMemo(() => {
        if ((isViewMode && isTariffsTab) || isRatingsTab) {
            return false;
        } else {
            return isEditMode ? true : !initialLoading && !isOpenPreviewWindow;
        }
    }, [initialLoading, isEditMode, isOpenPreviewWindow, isTariffsTab, isViewMode, isRatingsTab]);

    const isDisabledFacilityForm = useMemo(() => {
        if (isCreateMode) {
            return facility.cmsFacilityId && facility.cmsFacilityId === selectedPartnerFacility?.cmsFacilityId;
        }
        return !isEditMode;
    }, [isCreateMode, facility, selectedPartnerFacility, isEditMode]);

    const topRightButton = useMemo(
        () =>
            data.status === StatusNamesServiceItem.Active
                ? {
                      title: (
                          <>
                              Открыть на RUSSPASS <IcExternalLink className="button__icon button__icon--right" />
                          </>
                      ),
                      onClick: () => {
                          window.open(`${data?.url}`);
                      },
                      iconMobile: <IcExternalLink className="button__icon button__icon--right" />,
                  }
                : undefined,
        [data]
    );

    return (
        <ServiceTariffsContext.Provider value={tariffsProviderValue}>
            <CommonLayout
                pageTitle={data.title || "Новая услуга"}
                backButton={backButton}
                activeTabName={step}
                handlerChangeActiveTab={changeTab as any} //props has bad types that reason why using any
                isLoadingWithoutContent={initialLoading}
                isLoadingWithContent={isFormSending || isLoadingTariffList || isLoadingFacility}
                navbar={localNavbar}
                fullHeight={step === NavbarServiceNamesEnum.Ratings}
                isHideControlPanel
                topRightButton={topRightButton}
            >
                <div data-name={NavbarServiceNamesEnum.Comment} data-hide={!isViewComment}>
                    <ServiceComment reasons={[data.rejectionReason, facility.rejectionReason]} />
                </div>
                <div data-name={NavbarServiceNamesEnum.Service}>
                    <ServiceInfo
                        data={data}
                        optionsType={optionsEventTypes}
                        optionsAgeRestriction={optionsAgeRestriction}
                        optionsNdsRate={optionsNdsRate}
                        onChange={onChangeData}
                        isShowErrors={isSubmittingForm}
                        disabled={disabledFields}
                    />
                </div>
                <div data-name={NavbarServiceNamesEnum.Place}>
                    <ServicePlace
                        facility={facility}
                        isDisabledChooserFacility={isViewMode && !isEditMode}
                        isDisabledFacilityForm={isDisabledFacilityForm || undefined}
                        optionsPlaceTypes={optionsPlaceTypes}
                        onChangeFacility={onChangeFacility}
                        isShowErrors={isSubmittingForm}
                        region={region}
                        onChangeRegion={onChangeRegion}
                        city={city}
                        onChangeCity={onChangeCity}
                        optionsFacilityPlaces={optionsFacilityPlaces}
                        selectedPartnerFacility={selectedPartnerFacility}
                        changePartnerFacility={changePartnerFacility}
                    />
                </div>
                <div data-name={NavbarServiceNamesEnum.Photo}>
                    <Photos items={images} disabled={disabledFields} onChange={onChangeImage} />
                </div>
                <div data-name={NavbarServiceNamesEnum.Tariff}>
                    {showSwitch && <OnlineSaleSwitch onToggle={handleToggleIsCanBuySwitch} value={data.isCanBuy} />}
                    <Tariffs
                        isExistingService={isViewMode}
                        addTariffForExistingService={addTariffForExistingService}
                        deleteTariffForExistingService={deleteTariffForExistingService}
                        updateTariffForExistingService={updateTariffForExistingService}
                        isShowValiditySwitcher={isValidFacilityWorkingTime && facility.workingTime.length > 0}
                        facilityWorkingTime={facility.workingTime}
                        updateDataField={(val) => updateServiceFields({ ticketPrice: val })}
                    />
                </div>
                <div data-name={NavbarServiceNamesEnum.Ratings}>
                    <Ratings id={data.cmsEventId} />
                </div>
                <>
                    {isVisibleActions && (
                        <ActionsContainer
                            onClickNextButton={nextButton().action}
                            nextButtonText={nextButton().text}
                            onClickPreviewButton={openServicePreviewWindow}
                            showPreviwButton={isCreateMode || isEditMode}
                        />
                    )}
                </>
            </CommonLayout>
            <EventPreviewModal
                isOpened={isOpenPreviewWindow}
                onClose={closeServicePreviewWindow}
                data={getServiceEventPreviewData(data, facility, optionsEventTypes)}
            />
        </ServiceTariffsContext.Provider>
    );
};

export default ServiceComponent;
