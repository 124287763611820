import {
    DependencyList,
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";

type UseRequestOptions<T> = {
    refreshInterval?: number;
    initialResult?: T;
    initialLoading?: boolean;
    layoutEffect?: boolean;
};

type UseRequestResponse<T = unknown, E extends Error = Error> = {
    result?: T;
    error?: E;
    loading: boolean;
    refresh: () => void;
    mutate: Dispatch<SetStateAction<T | undefined>>;
};

export const useRequest = <T = unknown, E extends Error = Error>(
    factory: () => T | Promise<T>,
    deps: DependencyList,
    options: UseRequestOptions<T> = {}
): UseRequestResponse<T, E> => {
    const initialized = useRef(false);
    const [refreshCounter, setRefreshCounter] = useState(Date.now());
    const [loading, setLoading] = useState<boolean>([undefined, true].includes(options.initialLoading));
    const [result, setResult] = useState<T | undefined>(options.initialResult);
    const [error, setError] = useState<E>();
    const effectHook = options.layoutEffect ? useLayoutEffect : useEffect;

    const handleRefresh = useCallback(() => setRefreshCounter(Date.now()), []);

    effectHook(() => {
        let cancel = false;
        let refreshTimer: NodeJS.Timeout;

        if (options.refreshInterval) {
            refreshTimer = setInterval(() => setRefreshCounter(Date.now()), options.refreshInterval);
        }

        if (options.initialLoading === false && !initialized.current) {
            initialized.current = true;
            return;
        }

        (async () => {
            setLoading(true);

            try {
                const result = await factory();

                if (!cancel) {
                    setResult(result);
                    setError(undefined);
                    setLoading(false);
                }
            } catch (e) {
                if (!cancel) {
                    setResult(undefined);
                    setError(e);
                    setLoading(false);
                }

                cancel = true;
            } finally {
                initialized.current = true;
            }
        })();

        return () => {
            cancel = true;
            clearInterval(refreshTimer);
        };
    }, [...deps, refreshCounter]);

    return {
        result,
        error,
        loading,
        refresh: handleRefresh,
        mutate: setResult,
    };
};
