import styles from "./BookingBlock.module.sass";
import { ReactComponent as Calendar } from "../../../../../assets/images/icons/ic_calendar_cross.svg";

type Props = {
    onClick: () => void;
};

export const BookingBlock = ({ onClick }: Props) => (
    <div className={styles.Block} onClick={onClick}>
        <Calendar />
        Отметить недоступные для бронирования даты
    </div>
);
