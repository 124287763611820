import React from "react";
import moment from "moment/moment";
import { DOTTED_SHORT_DATE_FORMAT, EXTENDED_DATE_TIME_FORMAT } from "../../../../constants/dates-format";
import { ReactComponent as CircleIcon } from "../../../../assets/images/icons/circle.svg";
import { RentHousesBookingItem, RentHousesBookingStatus } from "../../../../types/rentHouses";
import { TimeUntil } from "../components/TimeUntil";
import { formatDateRange } from "../helpers";
import { expireRentHousesBooking } from "../../../../api/rentHouses";
import { PriceCell } from "./components/PriceCell";
import { ViewportContextProps, DataGridColumnType } from "@russpass/partner-front-ui";

const expireRentHousesBookingRequest = (id: string) => () => {
    expireRentHousesBooking(id).catch(() => null);
};
export const getDataGridBookingColumns = (
    status: RentHousesBookingStatus[],
    { isTablet, isMobile }: ViewportContextProps
): DataGridColumnType[] => {
    const columns: DataGridColumnType[] = [
        {
            label: "Объект",
            value: "object.title",
            width: (() => {
                if (isMobile) return "100%";
                if (isTablet) return `${694 - 104 - 60 - 128 - 24 * 4 - 8}px`;
                return `${798 - 104 - 60 - 142 - 24 * 4 - 8}px`;
            })(),
            icon: (item) => (!item.lookedByPartner ? <CircleIcon width={12} height={12} fill="#FFCF08" /> : null),
        },
        {
            label: "Сумма",
            value: (item) => <PriceCell bookingItem={item as RentHousesBookingItem} />,
            width: isMobile ? "calc(55% - 4px)" : "104px",
        },
        {
            label: "Гости",
            value: "guestCount",
            width: isMobile ? "calc(45% - 4px)" : "60px",
        },
        {
            label: "Даты проживания",
            value: ({ checkinDate, checkoutDate }) => formatDateRange(checkinDate, checkoutDate),
            width: (() => {
                if (isMobile) return "100%";
                if (isTablet) return "128px";
                return "142px";
            })(),
        },
    ];

    if (status.includes(RentHousesBookingStatus.PENDING)) {
        // Объект
        columns[0].width = (() => {
            if (isMobile) return "100%";
            if (isTablet) return `${694 - 104 - 156 - 60 - 128 - 24 * 5 - 8}px`;
            return `${798 - 156 - 104 - 60 - 142 - 24 * 5 - 8}px`;
        })();
        // Сумма
        columns[1].width = isMobile ? "calc(40% - 4px)" : "104px";
        // Даты проживания
        columns[3].width = (() => {
            if (isMobile) return "calc(60% - 4px)";
            if (isTablet) return "128px";
            return "142px";
        })();

        if (isMobile) {
            // меняем местами колонки "Сумма" и "Гости"
            [columns[1], columns[2]] = [columns[2], columns[1]];
        }

        columns.splice(1, 0, {
            value: (item) => <TimeUntil expireIn={item.expireIn} onExpired={expireRentHousesBookingRequest(item.id)} />,
            label: "Время до автоотмены",
            width: isMobile ? "calc(45% - 4px)" : "156px",
        });

        return columns;
    }

    if (status.includes(RentHousesBookingStatus.REJECTED)) {
        // Объект
        columns[0].width = (() => {
            if (isMobile) return "100%";
            if (isTablet) return `${694 - 104 - 60 - 104 - 24 * 4 - 8}px`;
            return `${798 - 104 - 60 - 142 - 24 * 4 - 8}px`;
        })();
        // "Даты проживания" -> "Дата отмены"
        columns[3] = {
            value: ({ updatedAt }) => moment(updatedAt, EXTENDED_DATE_TIME_FORMAT).format(DOTTED_SHORT_DATE_FORMAT),
            label: "Дата отмены",
            width: (() => {
                if (isMobile) return "100%";
                if (isTablet) return "104px";
                return "142px";
            })(),
        };
    }
    return columns;
};
