import { savePartnerDocs, savePartnerInfo, signOfferRequest, validateBankData } from "../../../../api/offer";
import { fileTypes } from "./constants";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { COMMON_ERROR } from "../../../../constants/errors";
import { FilesData, Offer, OfferForm } from "./types";
import { FileType } from "./enums";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import {
    confirmApplication,
    confirmApplicationByLeadership,
    confirmDocument,
    getSimpleRegistration,
} from "../../../../api/offer/simple-registration";
import { IBank, IBankField } from "../../../../types/profile";
import { CodeNdsRateEnum } from "../../../../enums/profile";
import { LegalStateStatus } from "./hooks/useLegalStateStatus";

const getSaveDocsPromise = (applicationId: string, file: File, type: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const rows = await savePartnerDocs(applicationId, file, type);
            return resolve(rows);
        } catch (err) {
            console.error(err);
            reject(null);
        }
    });
};

const formatFilesData = (values: OfferForm): FilesData[] => {
    const formattedData = [] as FilesData[];

    Object.keys(values.filesData).forEach((key: string) => {
        if (key === FileType.contract && values.applicationAdditionData?.tourOperatorInfo) {
            formattedData.push({
                file: values.filesData[key as FileType] as File[],
                type: values.applicationAdditionData.tourOperatorInfo.financialSecurityType,
            });
            return;
        }
        if (values.filesData[key as FileType]?.[0]) {
            formattedData.push({
                file: values.filesData[key as FileType] as File[],
                type: fileTypes[key as FileType],
            });
        }
    });

    return formattedData;
};

export const saveFiles = (applicationId: string, values: OfferForm) => {
    return new Promise(async (resolve, reject) => {
        try {
            const filesData = formatFilesData(values);
            const requestArray = filesData.map((fileData) =>
                getSaveDocsPromise(applicationId, fileData.file[0], fileData.type)
            );

            Promise.all(requestArray)
                .then(() => {
                    resolve(true);
                })
                .catch((err) => {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: COMMON_ERROR,
                    });
                    reject(null);
                });
        } catch (err) {
            console.error(err);
            reject(null);
        }
    });
};

export const signOffer = async (
    applicationId: string,
    values: OfferForm,
    isHotel: boolean,
    legalStatus?: LegalStateStatus
) => {
    await saveFiles(applicationId, values);

    const partnerData: Offer = {
        ...values.applicationAdditionData,
    };
    // @ts-ignore
    if (partnerData.registrationAddress?.unrestricted_value) {
        // @ts-ignore
        delete partnerData.registrationAddress.unrestricted_value;
    }
    if (!partnerData.ndsRate) {
        partnerData.ndsRate = CodeNdsRateEnum.WithoutNDS;
    }
    if (partnerData.authority && !values.filesData.authority?.[0]) {
        // @ts-ignore
        delete partnerData.authority;
    }
    if (partnerData.identityNumber) {
        partnerData.identityNumber = partnerData.identityNumber.replaceAll(" ", "");
    }
    if (!legalStatus?.isUL) {
        delete partnerData.signLegalBasis;
    }

    Object.keys(partnerData.bank).forEach(
        (
            key //  удаляем пустые поля банк. реквизитов перед отправкой
        ) => !partnerData?.bank[key as keyof IBank] && delete partnerData?.bank[key as keyof IBank]
    );

    await savePartnerInfo(applicationId, partnerData);

    let simpleRegistration = false;
    if (isHotel) {
        const { simpleRegistration: simpleReg } = await getSimpleRegistration();
        simpleRegistration = simpleReg;

        if (simpleRegistration) {
            await confirmApplication(applicationId, simpleRegistration);
            await confirmApplicationByLeadership(applicationId);
            await confirmDocument(applicationId);
        }
    }
    await signOfferRequest(applicationId);

    return simpleRegistration;
};

export const validateBankAccount = async ({
    partnerId,
    bank,
    fieldType,
}: {
    partnerId: string;
    bank: IBank;
    fieldType: IBankField;
}) => {
    const { bic, corrAccount, checkingAccount } = bank;
    const hasCorrectBic = bic && bic.length === 9;
    const hasCorrectCorrAccount = corrAccount && corrAccount.length === 20;
    const hasCorrectCheckingAccount = checkingAccount && checkingAccount.length === 20;
    // Для запроса должен быть БИК и хотя бы один из счетов
    if ((!hasCorrectCheckingAccount && !hasCorrectCorrAccount) || !hasCorrectBic) return;

    // Если поле не заполнено до конца - не отправлять
    if (fieldType === "corrAccount" && !hasCorrectCorrAccount) return;
    if (fieldType === "checkingAccount" && !hasCorrectCheckingAccount) return;

    try {
        const response = await validateBankData({
            partnerId,
            corrAccount: hasCorrectCorrAccount ? corrAccount : undefined,
            checkingAccount: hasCorrectCheckingAccount ? checkingAccount : undefined,
            bic,
        });
        if (response.checkStatus) {
            const { corrAccount: corrPassedValidation, checkingAccount: checkingPassedValidation } =
                response.checkStatus;
            const errorText = "Номер счета не соответствует БИК банка";
            return {
                corrAccount: hasCorrectCorrAccount && !corrPassedValidation ? errorText : undefined,
                checkingAccount: hasCorrectCheckingAccount && !checkingPassedValidation ? errorText : undefined,
            };
        }
    } catch (err) {
        console.error(err);
    }
};
