import fetchRequest from "./manager";
import { russpassApiServices } from "../constants/api";
import {
    DisputeMarkData,
    RatingsMarkItemResponse,
    RatingsMarkItemV2Response,
    RatingsMarkResponse,
    RatingsResponse,
    RatingsStatus,
} from "../containers/services/components/ratings/types";
import { ErrorResponse, PaginatedListParams } from "../types/api";
import { Sorting } from "../constants/filters";

const ratingServicePath = russpassApiServices.rating;
const ratingApiEventsUrl = `${ratingServicePath}/events`;
const ratingApiMarksUrl = `${ratingServicePath}/marks`;
const ratingApiMarkSuggestionsUrl = `${ratingServicePath}/suggestions`;

export type GetRatingMarksParams = PaginatedListParams & {
    status?: RatingsStatus | RatingsStatus[];
    sort?: Sorting;
    valueFrom?: number;
    valueTo?: number;
};

export const getRatings = async (id: string) => {
    const ratings: RatingsResponse = await fetchRequest.get(`${ratingApiEventsUrl}/${id}`, null, {
        isProtected: true,
    });

    return ratings;
};

export const getRatingsMarks = async (id: string, params: GetRatingMarksParams) => {
    const ratingsMarks: RatingsMarkResponse = await fetchRequest.get(`${ratingApiEventsUrl}/${id}/marks`, params, {
        isProtected: true,
    });

    return ratingsMarks;
};

export const getRatingsMarkSuggestions = async (id: string, status: string[], page: number, pageSize?: number) => {
    const data = { page, pageSize: pageSize || 10 } as any;
    if (status.length) {
        data.status = status.join(",");
    }
    const ratingsMarkSuggestions: RatingsMarkResponse = await fetchRequest.get(
        ratingApiMarkSuggestionsUrl,
        { ...data, externalEventId: id },
        {
            isProtected: true,
        }
    );

    return ratingsMarkSuggestions;
};

export const getRatingsMark = async (id: number) => {
    const ratingsMark: RatingsMarkItemResponse = await fetchRequest.get(`${ratingApiMarksUrl}/${id}`, null, {
        isProtected: true,
    });

    return ratingsMark;
};

export const getRatingsMarkV2 = async (id: number) => {
    const ratingsMark: RatingsMarkItemV2Response = await fetchRequest.get(
        `${ratingApiMarkSuggestionsUrl}/${id}`,
        null,
        {
            isProtected: true,
        }
    );

    return ratingsMark;
};

export const postRatingsMarkDispute = async (id: number, data: DisputeMarkData) => {
    const dispute: ErrorResponse = await fetchRequest.post(`${ratingApiMarksUrl}/${id}/dispute`, data, {
        isProtected: true,
    });

    return dispute;
};

export const patchExtraBonus = async (id: number) => {
    return await fetchRequest.patch(`${ratingApiMarksUrl}/${id}/extra-bonus`, null, {
        isProtected: true,
    });
};
