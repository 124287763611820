import { useMemo } from "react";
import { FeedbackFormAction } from "../../constants/FeedbackFormAction";

type FeedbackModalSubmitButtonProps = {
    action: FeedbackFormAction;
    onClick?: () => void;
    disabled?: boolean;
};

export const FeedbackModalSubmitButton = ({ action, onClick, disabled }: FeedbackModalSubmitButtonProps) => {
    const label = useMemo(() => getActionLabel(action), [action]);

    return (
        <button onClick={onClick} className="button button--primary" disabled={disabled}>
            {label}
        </button>
    );
};

const getActionLabel = (action: FeedbackFormAction) => {
    switch (action) {
        case FeedbackFormAction.Submit:
            return "Отправить ответ туристу";
        case FeedbackFormAction.Publish:
            return "Опубликовать ответ";
        case FeedbackFormAction.Unpublish:
            return "Убрать ответ";
    }
};
