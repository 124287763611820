import history from "../../../history";
import routes from "../../../constants/routes";

export const addTour = {
    title: "Добавить тур",
    desc: "Готовое путешествие",
    qaName: "tour",
    onClickCard: () => history.push(routes.toursNew),
};

export const addServicePages = [
    {
        title: "Добавить событие",
        desc: "Самостоятельное посещение музея, выставки, мероприятия",
        qaName: "place",
        onClickCard: () => history.push(routes.servicesNew),
    },
    {
        title: "Добавить экскурсию",
        desc: "Включает услуги гида",
        qaName: "excursion",
        onClickCard: () => history.push(routes.excursionsNew),
    },
];
