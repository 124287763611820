import { createContext } from "react";
import { Setting } from "../hooks/useSettingsList";

type NotificationsSettingsContextType = {
    items: Setting[];
    isLoading: boolean;
    refresh: () => void;
};

export const NotificationsSettingsContext = createContext<NotificationsSettingsContextType>({
    items: [],
    isLoading: false,
    refresh: () => undefined,
});
