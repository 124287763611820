import SubmitButton from "./SubmitButton";
import React from "react";

type Props = {
    onEditArchive: () => void;
};

export const ArchiveButton = ({ onEditArchive }: Props) => (
    <SubmitButton
        isValid={false}
        onClick={(e) => {
            e.preventDefault();
            onEditArchive();
        }}
    >
        Редактировать для публикации
    </SubmitButton>
);
