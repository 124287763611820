import { useCallback, useState } from "react";
import { useModalAlertContext, StatusRequestEnum } from "@russpass/partner-front-ui";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { rejectRentHousesBooking } from "../../../../../../api/rentHouses";
import { POST_DATA_ERROR } from "../../../../../../constants/errors";
import { closeBookingModal } from "../../utils/closeBookingModal";
import routes from "../../../../../../constants/routes";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";

export const useBookingReject = (id: string) => {
    const [isLoading, setIsLoading] = useState(false);

    const rejectRequest = useCallback(
        async (noErrors?: boolean) => {
            setIsLoading(true);
            sendEvent(AnalyticEvent.reject_request_click);
            try {
                await rejectRentHousesBooking(id);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: "Заявка отклонена",
                });
                sendEvent(AnalyticEvent.reject_page);
                closeBookingModal();
                eventDispatcher.updateCounter({ route: routes.apartmentsBookingAll });
            } catch (e) {
                if (!noErrors) {
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR,
                    });
                }
            }
            setIsLoading(false);
        },
        [id]
    );

    const { openModalAlert } = useModalAlertContext();

    const openModal = useCallback(() => {
        openModalAlert(
            {
                title: "Вы уверены, что хотите отклонить заявку?",
                OKButton: "Отклонить заявку",
                cancelButton: "Вернуться назад",
            },
            rejectRequest
        );
    }, [rejectRequest, openModalAlert]);

    return {
        openModal,
        isLoading,
    };
};
