import { ReactNode } from "react";
import eventDispatcher from "../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

export const useSetErrorNotification = () => {
    const setNotification = (content: ReactNode | string) => {
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            isAlwaysView: true,
            body: content,
        });
    };

    return { setNotification };
};
