import {
    ModalWindowRight,
    TextareaField,
    useViewportContext,
    ActionButtonWrapper,
    FileUploadField,
} from "@russpass/partner-front-ui";
import classNames from "classnames";
import { Form, Formik } from "formik";
import { ReactNode } from "react";
import { MAX_FILES_COUNT, MAX_FILES_SIZE_SUM } from "../../../../../../constants/validation";
import { setFileUploadError } from "../../../../../../utils/react-files";
import { disputeValidation } from "../../validation-schema";
import styles from "./rating-dispute-modal.module.sass";
import { Container } from "../../../../../apartments/booking/details/components/Container";

type Props = {
    isOpened: boolean;
    onClose: () => void;
    headerComponent: ReactNode;
    handleDispute: ({ description, files }: { description: string; files: File[] }) => Promise<void>;
    isSendDispute: boolean;
};

export const RatingsDisputeModal = ({ isOpened, onClose, headerComponent, handleDispute, isSendDispute }: Props) => {
    const { isMobile } = useViewportContext();
    return (
        <Formik
            initialValues={{ description: "", files: [] }}
            enableReinitialize
            validationSchema={disputeValidation}
            onSubmit={() => {}}
        >
            {({ isValid, submitForm, values, dirty, setFieldValue, setFieldTouched, errors }) => {
                const submitButton = (
                    <button
                        type="submit"
                        className="button button--primary button--full-width"
                        disabled={isSendDispute || !isValid || !dirty}
                        onClick={() => {
                            submitForm().then(() => {
                                if (!isValid) return;
                                handleDispute(values).then(null);
                            });
                        }}
                    >
                        Отправить
                    </button>
                );

                return (
                    <ModalWindowRight
                        isOpened={isOpened}
                        onClose={onClose}
                        title="Оценка"
                        headerComponent={headerComponent}
                        ComponentFooter={
                            isMobile ? <ActionButtonWrapper>{submitButton}</ActionButtonWrapper> : submitButton
                        }
                    >
                        <Container className={classNames(styles.RatingCardDisputeContainer, "ratings-card__container")}>
                            <div className="ratings-card">
                                <Form>
                                    <div className="mb-40">
                                        <div className="content-wrapper__form">
                                            <TextareaField
                                                label="Причина оспаривания"
                                                name="description"
                                                noHover
                                                placeholder="Напишите подробное пояснение"
                                                errorSettings={{
                                                    showOnChange: true,
                                                }}
                                                className={classNames("text-field__textCursor", styles.textFiled)}
                                            />
                                            <div className={classNames({ "mt-32": !isMobile })}>
                                                <FileUploadField
                                                    className={classNames({
                                                        [styles.FieldFileUploadWrapper]: isMobile,
                                                    })}
                                                    name="files"
                                                    buttonTitle="Загрузить файлы"
                                                    onChange={(files) => {
                                                        setFieldTouched("files");
                                                        setFieldValue("files", files);
                                                    }}
                                                    maxFileSize={MAX_FILES_SIZE_SUM}
                                                    maxFiles={MAX_FILES_COUNT}
                                                    accepts={[".pdf", ".jpg"]}
                                                    error={errors.files as string}
                                                    hint={`Не более ${MAX_FILES_COUNT} файлов общим размером ${MAX_FILES_SIZE_SUM} мб`}
                                                    onError={setFileUploadError}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Container>
                    </ModalWindowRight>
                );
            }}
        </Formik>
    );
};
