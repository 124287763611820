import { FC } from "react";
import classes from "./date-control-notification.module.sass";

interface INotificationDateProps {
    isOpen: boolean;
}

export const NotificationDate: FC<INotificationDateProps> = ({ isOpen }) => (
    <>
        {isOpen && (
            <div className={classes.notificationContainer}>
                <p className={classes.notificationContent}>При переходе в другой режим указанные дни не сохранятся</p>
            </div>
        )}
    </>
);
