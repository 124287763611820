import { useState } from "react";
import { Sorting } from "../../../../../constants/filters";
import { RentHousesBookingStatus } from "../../../../../types/rentHouses";

const PENDING_OPTIONS = [
    {
        label: "Время до автоотмены",
        value: Sorting.ExpireAtAcs,
    },
    {
        label: "Даты проживания",
        value: Sorting.CheckInAtAsc,
    },
];

const ACTIVE_OPTIONS = [
    {
        label: "Сначала ближайшие",
        value: Sorting.CheckInAtAsc,
    },
    {
        label: "Сначала более поздние",
        value: Sorting.CheckInAtDesc,
    },
    {
        label: "Сначала миним. сумма",
        value: Sorting.AmountAsc,
    },
    {
        label: "Сначала максим. сумма",
        value: Sorting.AmountDesc,
    },
];

const COMPLETED_OPTIONS = [
    {
        label: "Сначала ближайшие",
        value: Sorting.CheckInAtDesc,
    },
    {
        label: "Сначала самые давние",
        value: Sorting.CheckInAtAsc,
    },
    {
        label: "Сначала миним. сумма",
        value: Sorting.AmountAsc,
    },
    {
        label: "Сначала максим. сумма",
        value: Sorting.AmountDesc,
    },
];

const REJECTED_OPTIONS = [
    {
        label: "Отклонено недавно",
        value: Sorting.UpdatedAtDesc,
    },
    {
        label: "Отклонено давно",
        value: Sorting.UpdatedAtAsc,
    },
];

export const useSortFilter = (status: RentHousesBookingStatus[]) => {
    let options = ACTIVE_OPTIONS;

    if (status.includes(RentHousesBookingStatus.PENDING)) {
        options = PENDING_OPTIONS;
    }
    if (status.includes(RentHousesBookingStatus.ACTIVE) || status.includes(RentHousesBookingStatus.STARTED)) {
        options = ACTIVE_OPTIONS;
    }
    if (status.includes(RentHousesBookingStatus.COMPLETED)) {
        options = COMPLETED_OPTIONS;
    }
    if (status.includes(RentHousesBookingStatus.REJECTED)) {
        options = REJECTED_OPTIONS;
    }

    const [activeOption, setActiveOption] = useState<string>(options[0].value);

    return {
        options,
        activeOption,
        setActiveOption,
    };
};
