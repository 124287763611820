import React from "react";
import { HotelSelectedServiceData, HotelServiceData } from "./types";
import useHotelServices from "./useHotelServices";
import { modalAlertDetails } from "./constants";
import { MultiSelectWithModal } from "@russpass/partner-front-ui";

type SelectProps = {
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    hint?: string;
    errorSettings?: {
        showOnChange: boolean;
    };
};

type Props = {
    data: HotelSelectedServiceData;
    services: HotelServiceData;
    onChange: (values: any) => void;
    isViewMeals?: boolean;
};

const HotelServices = ({
    data,
    services,
    onChange,
    label = "Услуги",
    placeholder = "Выбрать",
    hint,
    errorSettings,
    disabled,
    isViewMeals = true,
}: Props & SelectProps) => {
    const { selectedServices, options, saveSelectServices } = useHotelServices({
        data,
        services,
        onChange,
        isViewMeals,
    });

    return (
        <MultiSelectWithModal
            data={[
                ...selectedServices.commonServices,
                ...selectedServices.meals,
                ...selectedServices.beachServices,
                ...selectedServices.entertainmentServices,
                ...selectedServices.fitnessServices,
                ...selectedServices.facilityServices,
            ]}
            isRequiredSave
            alertSettings={modalAlertDetails}
            options={options}
            multiMenuSettings={{
                name: "services",
                label: label,
                placeholder: placeholder,
                hint: hint,
                disabled: disabled,
                errorSettings: errorSettings,
            }}
            onChange={saveSelectServices}
            searchSettings={{
                placeholder: "Название услуги",
            }}
            position={2}
        />
    );
};

export default HotelServices;
