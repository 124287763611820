import { useCallback, useEffect, useState } from "react";
import { getNotifications } from "../../../../api/notficator";
import { HotelItemCms, HotelsDataResponse } from "../../../hotels/all/types";
import { getHotelsAll } from "../../../../api/hotels/hotels";
import routes from "../../../../constants/routes";
import moment from "moment/moment";
import { DOTTED_DATE_FORMAT } from "../../../../constants/dates-format";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import getCustomErrorMessage from "../../../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../../../constants/errors";

export type ReceivedNotification = {
    id: string;
    title: string;
    date: string;
    read: boolean;
    hotel: {
        title: string;
        url: string;
    };
    recommendation: string;
};

const PAGE_SIZE = 10;

const useReceivedNotifications = () => {
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);

    const [cmsHotels, setCmsHotels] = useState<HotelItemCms[]>([]);
    const [isCmsHotelsLoaded, setIsCmsHotelsLoaded] = useState(false);

    const [items, setItems] = useState<ReceivedNotification[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstLoading, setIsFirstLoading] = useState(true);

    const load = useCallback(async () => {
        setIsLoading(true);
        try {
            if (!isCmsHotelsLoaded) {
                const responseHotels: HotelsDataResponse = await getHotelsAll();
                setCmsHotels(responseHotels?.cms);
                setIsCmsHotelsLoaded(true);
                return;
            }

            const {
                data: { total, items },
            } = await getNotifications({ page, fired: true, pageSize: PAGE_SIZE });

            setPagesCount(Math.ceil(total / PAGE_SIZE));

            let receivedNotifications: ReceivedNotification[] = [];
            for (const item of items) {
                const hotel = cmsHotels.find((hotel) => hotel.cmsId === item.hotelId);
                if (!hotel) continue;

                receivedNotifications.push({
                    id: item.id,
                    title: item.title,
                    date: moment(item.lastFireDate).format(DOTTED_DATE_FORMAT),
                    read: !!item.read,
                    recommendation: item.recommendation,
                    hotel: {
                        title: hotel.title,
                        url: routes.hotelsView.replace(":hotelId", hotel.id),
                    },
                });
            }

            setItems(receivedNotifications);
            setIsFirstLoading(false);
        } catch (e) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
            });
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, [cmsHotels, isCmsHotelsLoaded, page]);

    useEffect(() => {
        load();
    }, [load]);

    return {
        items,
        page,
        setPage,
        pagesCount,
        isLoading,
        isFirstLoading,
        refresh: load,
    };
};

export default useReceivedNotifications;
