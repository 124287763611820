import { array as arrayYup, object as yupObject, string as yupString } from "yup";
import { testFilesSizeSum } from "../../../../utils/validation";
import { MAX_FILES_SIZE_SUM } from "../../../../constants/validation";

export const FeedbackFormSchema = yupObject().shape({
    answer: yupString()
        .required("Обязательно для заполнения")
        .max(1500, "Ответ должен содержать не более 1500 символов"),
    answerFiles: arrayYup().test(
        "answerFiles",
        `Общий размер файлов не должен превышать ${MAX_FILES_SIZE_SUM} мб`,
        testFilesSizeSum(MAX_FILES_SIZE_SUM)
    ),
});
