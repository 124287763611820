import { createSlice } from "@reduxjs/toolkit";
import { UserFullInfo, UserSettings } from "../types/user";

type UserInfo = {
    userFullInfo: UserFullInfo | null;
    userSettings: UserSettings | null;
};

const initialState: UserInfo = {
    userFullInfo: null,
    userSettings: null,
};

export const userInfo = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserFullInfo: (state, action) => {
            return {
                ...state,
                userFullInfo: action.payload,
            };
        },
        setUserSettings: (state, action) => {
            return {
                ...state,
                userSettings: {
                    ...state.userSettings,
                    ...action.payload,
                },
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUserFullInfo, setUserSettings } = userInfo.actions;

export default userInfo.reducer;
