import { useFormikContext } from "formik";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRentHousesObject } from "../../../api/hooks/rentHouses";
import { successTitleText } from "../../../constants/layout";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import eventDispatcher from "../../../lib/event-dispatcher";
import { RentHousesObjectStatus } from "../../../types/rentHouses";
import ModerationAlert from "./components/ModerationAlert";
import { NavbarApartmentsNewName } from "./constants";
import { checkDirtyValues, redirectToApartmentsAll } from "./helpers";
import { ApartmentsNewStep } from "./hooks/useApartmentsNew";
import { useCommentModeration } from "./hooks/useCommentModeration";
import { useDraft } from "./hooks/useDraft";
import { useFullFormValidation } from "./hooks/useFullFormValidation";
import { useNavbar } from "./hooks/useNavbar";
import { RejectionComment, CommonLayout } from "@russpass/partner-front-ui";
import { DetailsStep } from "./steps/details";
import { ObjectStep } from "./steps/object";
import { PhotoStep } from "./steps/photo";
import { RatingMarksStep } from "./steps/rating-marks";
import { SaleAndCancelStep } from "./steps/sale-and-cancel";
import { TariffsStep } from "./steps/tariffs";
import { AddApartmentValues, BaseStepProps } from "./types";
import { useExternalLink } from "../../../hooks/useExternalLink";

type Props = {
    step: ApartmentsNewStep;
    setStep: (step: ApartmentsNewStep) => void;
    isLoading: boolean;
};

const ApartmentsNewContent = ({ setStep, step, isLoading }: Props) => {
    const location = useLocation<{ status: string }>();

    const { apartmentId } = useParams<{ apartmentId?: string }>();

    const { isLoading: isLoadingObject, data } = useRentHousesObject(apartmentId, { revalidateOnFocus: false });

    const rentHousesData = useMemo(() => data?.data, [data]);

    const cmsId = rentHousesData?.cmsId;

    const isNew = useMemo(
        () => !apartmentId || rentHousesData?.status === RentHousesObjectStatus.DRAFT,
        [apartmentId, rentHousesData]
    );

    const [isEditArchive, setIsEditArchive] = useState(false);
    const isArchive = useMemo(
        () => rentHousesData?.status === RentHousesObjectStatus.ARCHIVED && !isEditArchive,
        [isEditArchive, rentHousesData?.status]
    );

    const isBlockedFields = useMemo(() => isArchive || !!rentHousesData?.blocked, [isArchive, rentHousesData?.blocked]);

    const showMarks = useMemo(
        () =>
            [RentHousesObjectStatus.PUBLISHED, RentHousesObjectStatus.ARCHIVED].includes(
                rentHousesData?.status as RentHousesObjectStatus
            ),
        [rentHousesData]
    );

    const { saveAndExit, errors: draftErrors } = useDraft(step, apartmentId);

    const { isHasCommentModeration, commentModeration } = useCommentModeration(rentHousesData, setStep);

    const { getFieldProps, values, initialValues } = useFormikContext();
    const title = getFieldProps("object.name").value || "Новый объект";

    const isDirty = useMemo(
        () => checkDirtyValues(initialValues as AddApartmentValues, values as AddApartmentValues),
        [initialValues, values]
    );

    const { errors: allErrors, isValid } = useFullFormValidation(step, isNew, isDirty);

    const navbar = useNavbar(isLoadingObject, isHasCommentModeration, showMarks, draftErrors || allErrors);

    const submit = useCallback(() => {
        if (isValid) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: "Вы заполнили не все разделы",
            isAlwaysView: false,
        });
    }, [isValid]);

    const stepProps: BaseStepProps = {
        isNew,
        isArchive,
        isBlockedFields,
        isEditArchive,
        onEditArchive: () => setIsEditArchive(true),
        isDirty,
        isValid,
        submit,
        setStep,
    };

    const { enabled: showRusspassLink, open: russpassOpen } = useExternalLink(
        `/housing/renthouse/${rentHousesData?.cmsId}`,
        rentHousesData?.status ?? "",
        !!rentHousesData?.blocked
    );

    const showSaveAndExit = (isNew || rentHousesData?.status === RentHousesObjectStatus.DRAFT) && isDirty;

    const topRightButton = useMemo(
        () =>
            showRusspassLink
                ? {
                      title: (
                          <>
                              {successTitleText.externalLink.text} {successTitleText.externalLink.icon}
                          </>
                      ),
                      onClick: russpassOpen,
                      iconMobile: successTitleText.externalLink.icon,
                  }
                : undefined,
        [russpassOpen, showRusspassLink]
    );

    const backButton = useMemo(
        () => ({
            title: showSaveAndExit ? "Сохранить и выйти" : "Выйти",
            onClick: showSaveAndExit ? saveAndExit : () => redirectToApartmentsAll(location),
        }),
        [location, saveAndExit, showSaveAndExit]
    );

    return (
        <>
            <ModerationAlert id={apartmentId} />
            <CommonLayout
                pageTitle={isLoadingObject ? "" : title}
                backButton={backButton}
                navbar={navbar}
                handlerChangeActiveTab={setStep}
                isLoadingWithoutContent={isLoadingObject}
                isLoadingWithContent={isLoading}
                isHideControlPanel
                topRightButton={topRightButton}
                activeTabName={step}
            >
                <div data-name={NavbarApartmentsNewName.COMMENT}>
                    {!!commentModeration && <RejectionComment reasons={commentModeration} />}
                </div>
                <div data-name={NavbarApartmentsNewName.OBJECT}>
                    <ObjectStep {...stepProps} />
                </div>
                <div data-name={NavbarApartmentsNewName.DETAILS}>
                    <DetailsStep {...stepProps} />
                </div>
                <div data-name={NavbarApartmentsNewName.PHOTO}>
                    <PhotoStep {...stepProps} />
                </div>
                <div data-name={NavbarApartmentsNewName.TARIFFS}>
                    <TariffsStep {...stepProps} />
                </div>
                <div data-name={NavbarApartmentsNewName.SALE_AND_CANCEL}>
                    <SaleAndCancelStep {...stepProps} />
                </div>
                <div data-name={NavbarApartmentsNewName.RATING_MARKS}>
                    {showMarks && !!cmsId && (
                        <RatingMarksStep
                            externalEventId={cmsId}
                            emptySettings={{ title: "Пока никто не оценил ваш объект" }}
                        />
                    )}
                </div>
            </CommonLayout>
        </>
    );
};

export default ApartmentsNewContent;
