import { useCallback, useMemo, useState } from "react";
import { HotelData, HotelDataForm } from "../new-form/types/hotel-info";
import { ChannelManagerCodeEnum } from "../../../../enums/channel-manager-code";
import { checkTravelLineHotel, loadHotelData } from "../new-form/utils/hotel-info";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { GET_DATA_ERROR } from "../../../../constants/errors";
import { cloneDeep } from "lodash";
import { hotelDataForm } from "../new-form/constants/hotel-info";
import { useParams } from "react-router-dom";

type Props = {
    isMoveOldTL?: boolean;
};

const useHotel = ({ isMoveOldTL }: Props) => {
    const { hotelId } = useParams<{ hotelId: string }>();

    const [isLoading, setIsLoading] = useState(false);
    const [hotelInfoData, setHotelInfoData] = useState<HotelDataForm>(cloneDeep(hotelDataForm));
    const [checkExistTL, setCheckExistTL] = useState<boolean | undefined>();

    const isTravelLineHotel = useMemo(
        () => hotelInfoData.partnerSystemType === ChannelManagerCodeEnum.TravelLine,
        [hotelInfoData]
    );

    const isNewTravelLineHotel = useMemo(
        () => hotelInfoData.partnerSystemType === ChannelManagerCodeEnum.NewTravelLine,
        [hotelInfoData]
    );

    const loadHotelInfoData = useCallback(
        async (isFullLoadData?: boolean): Promise<HotelData | HotelDataForm | null> => {
            setIsLoading(true);
            try {
                const data = await loadHotelData(hotelId, isFullLoadData);
                setHotelInfoData(data as HotelDataForm);
                if (isMoveOldTL) {
                    const check = await checkTravelLineHotel(data as HotelDataForm);
                    setCheckExistTL(check);
                }
                setIsLoading(false);
                return data;
            } catch (error) {
                console.error(error);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: GET_DATA_ERROR,
                });
                return null;
            }
        },
        [isMoveOldTL, hotelId]
    );

    return {
        isLoading,
        hotelInfoData,
        checkExistTL,
        loadHotelInfoData,
        isTravelLineHotel,
        isNewTravelLineHotel,
    };
};

export default useHotel;
