import { servicePlaceValidation } from "../../components/service-place/validation-schema";
import { Tariff } from "../../components/tariffs/types";
import { Facility } from "../../types";
import { ExcursionDataClient } from "./types";
import { draftExcursionInfoValidation, excursionInfoValidation, excursionProgramValidation } from "./validation-schema";

type Props = {
    data: ExcursionDataClient;
    facility: Facility;
    tariffs: Tariff[];
    isShowServicePlace: boolean;
    isSubmittingForm: boolean;
};

export const checkValidExursionFields = ({ data, facility, tariffs, isShowServicePlace, isSubmittingForm }: Props) => {
    const isValidInfo = isSubmittingForm
        ? excursionInfoValidation.isValidSync(data)
        : draftExcursionInfoValidation.isValidSync(data);
    const isValidProgram = isSubmittingForm ? excursionProgramValidation.isValidSync(data) : true;
    const isValidPhoto = isSubmittingForm ? !!data.imagesFiles && data.imagesFiles.length > 0 : true;
    const isValidTariffs = isSubmittingForm ? !!tariffs && tariffs.length > 0 : true;
    const isValidPlace = isSubmittingForm
        ? isShowServicePlace
            ? servicePlaceValidation.isValidSync(facility)
            : true
        : true;

    return {
        isValidInfo,
        isValidPlace,
        isValidPhoto,
        isValidTariffs,
        isValidProgram,
    };
};
