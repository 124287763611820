import { useMemo } from "react";
import { DiscountType, HotelDiscountsData } from "../../../types/hotel-discounts";
import { DiscountBonusTypeLabel } from "../../../constants/hotel-discounts";
import moment from "moment/moment";
import { IntegrationHotelRoom } from "../../../types/hotel-room";
import { declensionOfNum } from "../../../../../../../utils/declension-number";
import { DOTTED_SHORT_DATE_FORMAT } from "../../../../../../../constants/dates-format";

const useControlDiscountItem = (hotelData: HotelDiscountsData, roomData: IntegrationHotelRoom[]) => {
    const descriptionDiscount = useMemo(() => {
        if (hotelData.specialOfferType === DiscountType.DISCOUNT && hotelData.discountSize) {
            return `Скидка ${hotelData.discountSize}%`;
        }
        if (hotelData.specialOfferType === DiscountType.BONUS && hotelData.bonusCategory) {
            return `Бонус. ${DiscountBonusTypeLabel[hotelData.bonusCategory]}`;
        }
        return "Не заполнено";
    }, [hotelData]);

    const dateDiscount = useMemo(() => {
        const formatDate = (date: string) => moment(new Date(date)).format(DOTTED_SHORT_DATE_FORMAT);

        const { stayDateFrom, stayDateTo, bookingDateFrom, bookingDateTo } = hotelData;

        if (stayDateFrom && stayDateTo) {
            return `При проживании ${formatDate(stayDateFrom)} — ${formatDate(stayDateTo)}`;
        }
        if (bookingDateFrom && bookingDateTo) {
            return `При бронировании ${formatDate(bookingDateFrom)} — ${formatDate(bookingDateTo)}`;
        }
        return "Не заполнено";
    }, [hotelData]);

    const roomTariffs = useMemo(() => {
        const tariffsCount = hotelData?.tariffs?.length;
        const roomTitle = roomData.find((el) => el.id === hotelData.roomId)?.name;
        if (!tariffsCount || !roomTitle) return;

        return `На ${tariffsCount} ${declensionOfNum(tariffsCount, ["тариф", "тарифа", "тарифов"])} «${roomTitle}»`;
    }, [hotelData, roomData]);

    return {
        descriptionDiscount,
        dateDiscount,
        roomTariffs,
    };
};

export default useControlDiscountItem;
