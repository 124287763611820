import { getExistingTLHotels, packingHotelsByStatus } from "./helper";
import { CheckHotelExistResponse, HotelsDataResponse } from "./types";
import { useState, useCallback, useEffect, useMemo } from "react";
import { getHotelsAll } from "../../../api/hotels/hotels";
import eventDispatcher from "../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../constants/errors";
import { StatusRequestEnum, ObjectItemType } from "@russpass/partner-front-ui";
import { useLocation } from "react-router-dom";
import { navbarHotelsNames } from "./constants";
import history from "../../../history";
import routes from "../../../constants/routes";
import { sendEvent } from "../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../product_analytics/constants";
import { isEnableMovingOldTL } from "../../../constants/feature-toggles";

const useHotels = () => {
    const location = useLocation<{ background: Location; status?: number } | null>();
    const background = location.state?.background || location;

    const [activeTabKey, setActiveTabKey] = useState<string | number | undefined>(location.state?.status);

    const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);

    const [allHotelsList, setAllHotelsList] = useState<ObjectItemType[]>([]);
    const [draftHotelsList, setDraftHotelsList] = useState<ObjectItemType[]>([]);
    const [moderationHotelsList, setModerationHotelsList] = useState<ObjectItemType[]>([]);
    const [publishedHotelsList, setPublishedEventssList] = useState<ObjectItemType[]>([]);
    const [refusedHotelsList, setRefusedHotelsList] = useState<ObjectItemType[]>([]);
    const [archiveHotelsList, setArchiveHotelsList] = useState<ObjectItemType[]>([]);

    const [existingTLData, setExistingTLData] = useState<CheckHotelExistResponse>([]);

    const loadHotels = useCallback(async () => {
        try {
            const responseHotels: HotelsDataResponse = await getHotelsAll();

            const {
                draftHotelsArray,
                moderationHotelsArray,
                publishedHotelsArray,
                refusedHotelsArray,
                archiveHotelsArray,
                allHotelsArray,
            } = packingHotelsByStatus(responseHotels);

            if (isEnableMovingOldTL) {
                const existingData = await getExistingTLHotels(responseHotels);
                setExistingTLData(existingData);
            }

            setDraftHotelsList(draftHotelsArray);
            setModerationHotelsList(moderationHotelsArray);
            setPublishedEventssList(publishedHotelsArray);
            setRefusedHotelsList(refusedHotelsArray);
            setArchiveHotelsList(archiveHotelsArray);
            setAllHotelsList(allHotelsArray);
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
        } finally {
            setIsLoadingContent(false);
        }
    }, []);

    const hotelList = useMemo(
        () => [
            { dataName: navbarHotelsNames.all, list: allHotelsList },
            { dataName: navbarHotelsNames.draft, list: draftHotelsList },
            { dataName: navbarHotelsNames.moderation, list: moderationHotelsList },
            { dataName: navbarHotelsNames.published, list: publishedHotelsList },
            { dataName: navbarHotelsNames.refused, list: refusedHotelsList },
            { dataName: navbarHotelsNames.archive, list: archiveHotelsList },
        ],
        [
            allHotelsList,
            archiveHotelsList,
            draftHotelsList,
            moderationHotelsList,
            publishedHotelsList,
            refusedHotelsList,
        ]
    );

    const onClickAddHotel = useCallback(() => {
        history.push({
            pathname: routes.hotelsNew,
            state: {
                background,
            },
        });
        sendEvent(AnalyticEvent.add_object_click);
    }, [background]);

    useEffect(() => {
        loadHotels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoadingContent,
        activeTabKey,
        setActiveTabKey,
        hotelList,
        isEmptyList: !allHotelsList.length,
        onClickAddHotel,
        existingTLData,
    };
};

export default useHotels;
