import React, { useCallback } from "react";
import { FeedbackSortFilter } from "./FeedbackSortFilter";
import { FeedbackFiltersModel } from "../../types/FeedbackFiltersModel";
import { FeedbackSort } from "../../constants/FeedbackSort";
import { FeedbackEventFilter } from "./FeedbackEventFilter";

type FeedbackFiltersProps = {
    value: FeedbackFiltersModel;
    onChange?: (value: FeedbackFiltersModel) => void;
};

export const FeedbackFilters = ({ value, onChange }: FeedbackFiltersProps) => {
    const handleSortChange = useCallback(
        (sort: FeedbackSort) => {
            if (value.sort === sort) return;

            onChange?.({ ...value, sort });
        },
        [value, onChange]
    );

    const handleExternalEventIdChange = useCallback(
        (externalEventId?: string) => {
            if (value.externalEventId === externalEventId) return;

            onChange?.({ ...value, externalEventId });
        },
        [value, onChange]
    );

    return (
        <div className="feedback__filters">
            <FeedbackEventFilter value={value.externalEventId} onChange={handleExternalEventIdChange} />
            <FeedbackSortFilter activeValue={value.sort} onChange={handleSortChange} />
        </div>
    );
};
