import { useCallback, useState } from "react";
import { useGetDialogue } from "../../../../../../api/hooks/dialogues";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";

export const useDialog = (cmsId: string, externalId: number) => {
    const [isOpen, setIsOpen] = useState(false);
    const { data: dialogData, isLoading, mutate } = useGetDialogue(cmsId, externalId, {});
    const open = useCallback(() => {
        sendEvent(AnalyticEvent.chat_with_guest);
        mutate().then(() => setIsOpen(true));
    }, [mutate]);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    return { dialog: dialogData?.dialogue, isLoading, isOpen, open, close };
};
