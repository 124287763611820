import { useEffect } from "react";
import { initOptions } from "../config/init-options";
import keycloak from "../../../config/keycloak";
import { useHandleOnEvent } from "./useHandleOnEvent";

export const useAuth = () => {
    const {
        handleOnAuthError,
        handleOnAuthRefreshSuccess,
        handleOnAuthSuccess,
        handleOnErrorInit,
        handleOnReady,
        handleOnRefreshError,
        handleOnSuccessInit,
        handleOnTokenExpired,
        isAuthenticated,
        isInitializedKeycloak,
    } = useHandleOnEvent();

    useEffect(() => {
        keycloak.onReady = handleOnReady;

        keycloak.onAuthSuccess = handleOnAuthSuccess;
        keycloak.onAuthRefreshSuccess = handleOnAuthRefreshSuccess;

        keycloak.onTokenExpired = handleOnTokenExpired;

        keycloak.onAuthError = handleOnAuthError;
        keycloak.onAuthRefreshError = handleOnRefreshError;

        keycloak
            .init(initOptions)
            .then(() => {
                handleOnSuccessInit();
            })
            .catch((err) => {
                handleOnErrorInit(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isInitializedKeycloak, isAuthenticated };
};
