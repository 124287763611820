import {
    HotelAddress,
    HotelBankDetails,
    HotelCity,
    HotelData,
    HotelDataForm,
    HotelDataForSaveRequest,
    HotelDataUpdating,
    HotelRegion,
} from "../types/hotel-info";
import {
    checkHotelExist,
    createHotel,
    editHotel,
    getHotelBankDetails,
    getHotelData,
    saveExistingHotel,
    sendHotelToMSBilling,
} from "../../../../../api/hotels/hotels";
import { cloneDeep } from "lodash";
import { hotelAddress, hotelCity, hotelData as defaultHotelData, hotelRegion } from "../constants/hotel-info";
import { getCity, getRegion } from "../../../../../components/async-selects/utils";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { POST_DATA_ERROR_SUPPORT } from "../../../../../constants/errors";
import { ChannelManagerCodeEnum } from "../../../../../enums/channel-manager-code";
import { UserContractInfo, UserFullInfo } from "../../../../../types/user";
import { getUserContactInfo } from "../../../../../api/profile";

export const checkTravelLineHotel = async (hotel: HotelDataForm) => {
    if (hotel.partnerSystemType === ChannelManagerCodeEnum.TravelLine && hotel.id) {
        const data = await checkHotelExist([{ id: hotel.id }]);
        if (data.length) {
            return data[0]?.exist;
        }
    }
    return;
};

export const mappingHotelInfoDataForCreateRequest = (values: HotelDataForm, hotelId: string) => {
    const data: HotelData = {
        ...values,
        id: hotelId,
        address: values.address.unrestricted_value,
        region: values.region.id,
        city: values.city.id,
    };
    delete data.hotelType;
    delete data.notPublishedRooms;
    return data;
};

export const mappingHotelInfoDataForEditRequest = (values: HotelDataForm) => {
    const data: HotelData = {
        ...values,
        region: values.region.id,
        city: values.city.id,
        address: values.address.unrestricted_value,
    };
    delete data.hotelType;
    delete data.notPublishedRooms;
    return data;
};

export const loadHotelData = (hotelId: string, isFullLoadData?: boolean): Promise<HotelData | HotelDataForm> => {
    return new Promise<HotelData | HotelDataForm>(async (resolve, reject) => {
        try {
            const content: HotelDataUpdating = await getHotelData(hotelId);
            const hotelData: HotelData = mappingHotelData(content);
            if (isFullLoadData) {
                getHotelDataForm(hotelData).then((data) => {
                    resolve(data);
                });
            } else {
                resolve(hotelData);
            }
        } catch (error) {
            console.error(error);
            reject();
        }
    });
};

export const getRegionWithTZ = async (regionId: string) => {
    let region: HotelRegion = cloneDeep(hotelRegion);
    const content = await getRegion(regionId);
    region = {
        ...region,
        ...content,
    };
    const data = {
        region,
        timeZone: "",
    };
    if (content.timeZone.length === 1) {
        data.timeZone = content.timeZone[0].id;
    }
    return data;
};

export const getHotelDataForm = (hotelData: HotelData): Promise<HotelDataForm> => {
    return new Promise<HotelDataForm>(async (resolve, reject) => {
        try {
            let region: HotelRegion = cloneDeep(hotelRegion);
            let city: HotelCity = cloneDeep(hotelCity);
            let address: HotelAddress = cloneDeep(hotelAddress);
            let timeZone: string = cloneDeep(hotelData.timeZone);

            if (hotelData.region) {
                const data = await getRegionWithTZ(hotelData.region);
                region = data.region;
                if (!timeZone.length) {
                    timeZone = data.timeZone;
                }
            }

            if (hotelData.city) {
                city = await getCity(hotelData.city);
            }

            if (hotelData.address) {
                address = {
                    unrestricted_value: hotelData.address,
                    value: hotelData.address,
                };
            }

            resolve({
                ...hotelData,
                region,
                city,
                address,
                timeZone,
            });
        } catch (error) {
            console.error(error);
            reject();
        }
    });
};

const mappingHotelData = (hotelData: HotelDataUpdating): HotelData => {
    let data: HotelData = cloneDeep(defaultHotelData);
    for (let prop in data) {
        let value;
        if (hotelData.updating) {
            // @ts-ignore
            value = hotelData.updating[prop];
        }
        if (!value) {
            // @ts-ignore
            value = hotelData[prop];
        }
        if (!value) {
            // @ts-ignore
            value = data[prop];
        }
        // @ts-ignore
        data[prop] = value;
    }
    data.notPublishedRooms =
        hotelData.updating?.hotelRooms?.filter((roomId) => hotelData.hotelRooms?.indexOf(roomId) === -1) || [];
    return data;
};

export const trySaveHotelInfo = async (
    isNew: boolean,
    values: HotelDataForm,
    hotelInfo: HotelDataForm,
    userData: UserFullInfo | null,
    isTravelLineHotel?: boolean
) => {
    const _data = isNew
        ? mappingHotelInfoDataForCreateRequest(values, hotelInfo.id)
        : mappingHotelInfoDataForEditRequest(values);

    try {
        if (isNew) {
            await createHotel(_data);
        } else {
            await editHotel(values.id, _data);
        }
        if (isTravelLineHotel) {
            if (userData?.applicationId) {
                const userContractInfo: UserContractInfo = await getUserContactInfo(userData?.applicationId);
                const bankDetails: HotelBankDetails = await getHotelBankDetails(
                    hotelInfo.id,
                    hotelInfo.partnerSystemType || ""
                );
                const saveData: HotelDataForSaveRequest = {
                    systemType: ChannelManagerCodeEnum.NewTravelLine,
                    title: hotelInfo.title,
                    bankDetails: bankDetails,
                    vat: userContractInfo?.ndsRate,
                };
                await saveExistingHotel(hotelInfo.id, saveData);
                await sendHotelToMSBilling(hotelInfo.id, {
                    title: hotelInfo.title,
                    partnerSystemType: ChannelManagerCodeEnum.NewTravelLine,
                });
            }
        }
        return StatusRequestEnum.Success;
    } catch {
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: POST_DATA_ERROR_SUPPORT,
        });
        return StatusRequestEnum.Error;
    }
};
