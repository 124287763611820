import { russpassApiServices, russpassApiUrl } from "../constants/api";
import fetchRequest from "./manager";

const attachServicePath = russpassApiServices.attach;
const fileServicePath = russpassApiServices.feedbackFiles;

export const saveImage = async (file: File | null) => {
    if (!file) {
        return null;
    }
    let formData = new FormData();

    formData.append("file", file, file.name);
    const info: { size: number; url: string } | null = await fetchRequest.file(`${attachServicePath}/image`, formData, {
        isProtected: true,
    });
    if (info) return info;
    throw new Error("Не удалось сохранить картинку");
};

export const saveFile = async (file: File | null) => {
    if (!file) {
        return null;
    }
    let formData = new FormData();

    formData.append("file", file, file.name);
    const info: { size: number; uri: string } = await fetchRequest.file(`${attachServicePath}/file`, formData);
    if (info) return info;
    throw new Error("Не удалось сохранить файл");
};

export const uploadFile = async (file: File | null) => {
    if (!file) {
        return null;
    }
    let formData = new FormData();

    formData.append("file", file, file.name);
    const info: { data: { id: string; attachId: string; size: number; filename: string } } = await fetchRequest.file(
        fileServicePath,
        formData
    );
    if (info) return info;
    throw new Error("Не удалось сохранить файл");
};

export const getFileLink = (file: string) => `${russpassApiUrl}${attachServicePath}/file?file=${file}`;
