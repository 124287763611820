import { useLocation } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { TypeLogger } from "../enums/type-logger";
import eventDispatcher from "../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

const queryParamLogerName = "logger";

export const useLogger = () => {
    const { search } = useLocation();

    const getIsLoggerMode = useCallback(() => {
        const queryParams = new URLSearchParams(search);
        const loggerParam = queryParams.get(queryParamLogerName);
        return Number(loggerParam) === 1;
    }, [search]);

    const isLoggerMode = getIsLoggerMode();

    useEffect(() => {
        if (isLoggerMode) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Success,
                isAlwaysView: true,
                body: "Вы находитесь в режиме отладки.",
            });
        }
    }, [isLoggerMode]);

    const logger = (content: any, typeConsole: TypeLogger) => {
        if (isLoggerMode) {
            console[typeConsole](content);
        }
    };

    return { logger, isLoggerMode };
};
