import HotelDiscountsList from "./hotel-discounts-list";
import { HotelDiscountStoreProvider } from "./context/discounts-store/HotelDiscountStoreProvider";
import { HotelDiscountModalProvider } from "./context/discount-modal/HotelDiscountModalProvider";
import useHotel from "../../../hotel-view/useHotel";
import { useMemo } from "react";
import { HotelDataContext } from "../../../hotel-view/hotel-data-context";

const HotelDiscounts = () => {
    const { hotelInfoData, loadHotelInfoData, isTravelLineHotel, isNewTravelLineHotel } = useHotel({});

    const hotelDataProviderValue = useMemo(
        () => ({
            hotelInfoData,
            isNewTravelLineHotel,
            isTravelLineHotel,
            loadHotelInfoData,
        }),
        [hotelInfoData, isNewTravelLineHotel, isTravelLineHotel, loadHotelInfoData]
    );

    return (
        <HotelDataContext.Provider value={hotelDataProviderValue}>
            <HotelDiscountStoreProvider>
                <HotelDiscountModalProvider>
                    <HotelDiscountsList />
                </HotelDiscountModalProvider>
            </HotelDiscountStoreProvider>
        </HotelDataContext.Provider>
    );
};

export default HotelDiscounts;
