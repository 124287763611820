import { APARTMENTS_CONTACT_FORM_TOPIC } from "../../../../../../constants/support";
import { apartmentsContactFormTopicType } from "../../dictionary";

const getApartmentTopicOptions = () =>
    Object.values(APARTMENTS_CONTACT_FORM_TOPIC).map((item) => ({
        value: item,
        label: apartmentsContactFormTopicType(item),
    }));

export default getApartmentTopicOptions;
