import React, { useCallback, useMemo, useRef, useState } from "react";
import {
    DateControlValue,
    isEqualObjects,
    MultiMenu,
    MultiMenuRef,
    RangeDate,
    RangeDaysSelect,
    useViewportContext,
} from "@russpass/partner-front-ui";
import { ReactComponent as CalendarIcon } from "../../../../assets/images/icons/navigation/ic_calendar.svg";
import classNames from "classnames";
import SwitchButton from "../../../../components/timetable/place-timetable/date-control/switch-button";
import {
    DatesFilterTitleSuffix,
    DatesFilterType,
    DateFilters as TDateFilter,
    INITIAL_DATE_FILTERS,
} from "../../constants";
import ActionsComponent from "../../../../components/timetable/place-timetable/action-buttons";
import moment from "moment";
import {
    MID_DAY_MONTH_DATE_FORMAT,
    MONTH_YEAR_DATE_FORMAT,
    SHORT_DATE_FORMAT,
} from "../../../../constants/dates-format";
import { useOrderFiltersContext } from "../../context/useOrderFiltersContext";
import styles from "./orders-filters.module.sass";

const DEFAULT_DISPLAYING_VALUE = "Выбрать дату";
const INITIAL_SELECTED_VALUE = [{ from: undefined, to: undefined }];

const prepareDate = (value: DateControlValue, activeTab: DatesFilterType) => {
    const date = value[0] as RangeDate;
    const range: TDateFilter = { ...INITIAL_DATE_FILTERS };

    if (!date.from) {
        return range;
    }

    const endDate = !date.to ? undefined : moment(date.to).endOf("day").toISOString(true);

    if (activeTab === DatesFilterType.CREATE) {
        range.startCreateDate = date.from;
        range.endCreateDate = endDate;
    } else {
        range.startActiveSince = date.from;
        range.endActiveSince = endDate;
    }

    return range;
};

const DatesFilter = () => {
    const {
        filters,
        filters: { startActiveSince, endActiveSince, startCreateDate, endCreateDate },
        changeFilter,
        activeTab,
        setActiveTab,
    } = useOrderFiltersContext();

    const initialFilter = {
        startCreateDate,
        endCreateDate,
        startActiveSince,
        endActiveSince,
    };

    const RangeDaysSelectRef = useRef<any>(null);
    const multiMenuRef = useRef<MultiMenuRef>(null);
    const title = useRef<string>(DEFAULT_DISPLAYING_VALUE);
    const { isMobile } = useViewportContext();

    const [selectedValue, setSelectedValue] = useState<RangeDate[]>(INITIAL_SELECTED_VALUE);

    const isChangedValue = useMemo(() => {
        return !isEqualObjects(initialFilter, selectedValue);
    }, [initialFilter, selectedValue]);

    const setTitle = useCallback(
        (isReset?: boolean) => {
            if (isReset || !selectedValue[0].from) {
                return (title.current = DEFAULT_DISPLAYING_VALUE);
            }

            const fromDate = moment(selectedValue[0].from);
            const toDate = selectedValue[0].to ? moment(selectedValue[0].to) : null;

            let from = fromDate.format(SHORT_DATE_FORMAT);
            let to = toDate ? ` - ${toDate.format(SHORT_DATE_FORMAT)}` : "";

            if (toDate && fromDate.year() === toDate.year()) {
                from = fromDate.format(MID_DAY_MONTH_DATE_FORMAT);
            }

            return (title.current = `${DatesFilterTitleSuffix[activeTab]} ${from}${to}`);
        },
        [selectedValue, activeTab]
    );

    const calendarButton = useMemo(
        () => (
            <button className="button button--second">
                <CalendarIcon className="button__icon--left" />
                {title.current}
            </button>
        ),
        [title.current]
    );

    const handleReset = () => {
        setSelectedValue(INITIAL_SELECTED_VALUE);
        changeFilter(INITIAL_DATE_FILTERS);
        setTitle(true);
        multiMenuRef.current?.closeMenu();
    };

    const handleSave = () => {
        const preparedDate = prepareDate(selectedValue, activeTab);
        changeFilter(preparedDate);
        setTitle();
        multiMenuRef.current?.closeMenu();
    };

    // Метод и значение выбраных дат для календаря имеют разные типы.
    // DateControlValue - обьеденение ддвух типов. Наш календарь всегда возвращает занчения аналогичные типу RangeDate
    // Преобразовал тут, для чистоты и меньших преобразований в форматирование строки для кнопки
    const handleSelect = (values: DateControlValue) => {
        setSelectedValue(values as RangeDate[]);
    };

    return (
        <MultiMenu
            ref={multiMenuRef}
            classes={{ content: styles.date_filter }}
            noHover
            renderComponent={calendarButton}
            hideArrow
            actionsComponent={
                <ActionsComponent
                    isChangedValue={isChangedValue}
                    handleSave={handleSave}
                    handleReset={handleReset}
                    isMobile={isMobile}
                />
            }
        >
            <div
                className={classNames("date-control", {
                    "date-control__mobile": isMobile,
                })}
            >
                <SwitchButton
                    isMobile={isMobile}
                    activeTab={activeTab}
                    tabName={DatesFilterType.CREATE}
                    changeActiveTab={setActiveTab}
                    title="Дата заявки"
                />
                <SwitchButton
                    isMobile={isMobile}
                    activeTab={activeTab}
                    tabName={DatesFilterType.ACTIVE}
                    changeActiveTab={setActiveTab}
                    title="Дата проведения"
                />
            </div>
            <RangeDaysSelect
                ref={RangeDaysSelectRef}
                value={selectedValue}
                onSelect={handleSelect}
                numberOfMonths={2}
                customFormat={MONTH_YEAR_DATE_FORMAT}
            />
        </MultiMenu>
    );
};

export default DatesFilter;
