import { HTMLProps } from "react";
import classNames from "classnames";
import { useViewportContext } from "@russpass/partner-front-ui";
import styles from "./Header.module.sass";

export const Header = ({ className, ...divProps }: HTMLProps<HTMLDivElement>) => (
    <div className={classNames(styles.Header, className)} {...divProps} />
);

export const HeaderInfo = ({ className, ...divProps }: HTMLProps<HTMLDivElement>) => (
    <div className={classNames(styles.Info, className)} {...divProps} />
);

export const HeaderTitle = ({ className, ...divProps }: HTMLProps<HTMLDivElement>) => {
    const { isMobile } = useViewportContext();

    return (
        <div
            className={classNames(styles.Title, className, {
                [styles.Title__mobile]: isMobile,
            })}
            {...divProps}
        />
    );
};

export const HeaderSubtitle = ({ className, ...divProps }: HTMLProps<HTMLDivElement>) => {
    const { isMobile } = useViewportContext();

    return (
        <div
            className={classNames(styles.Subtitle, className, {
                [styles.Subtitle__mobile]: isMobile,
            })}
            {...divProps}
        />
    );
};
