import fetchRequest from "../manager";
import { partnerApiServices, russpassApiServices } from "../../constants/api";
import {
    CreateRoomData,
    EditRoomDataRequest,
    IntegrationHotelRoom,
    IntegrationHotelRoomsResponse,
    TLOccupancyEditRequest,
} from "../../containers/hotels/add-hotel-page/new-form/types/hotel-room";

const partnershipServicePath = russpassApiServices.partnership;
const hotelsPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.hotels}`;
const integrationPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.integration}`;

// Получение данных о комнатах отеля из мс hotels
export const getIntegrationHotelRooms = async (hotelId: string): Promise<IntegrationHotelRoomsResponse> => {
    const content = await fetchRequest.get(`${integrationPartnerServiceUrl}/hotels/${hotelId}/rooms`, null, {
        isProtected: true,
    });
    return content;
};

// Получение данных о комнатах отеля из мс hotels
export const getIntegrationHotelRoom = async (hotelId: string, roomId: string): Promise<IntegrationHotelRoom> => {
    const content = await fetchRequest.get(`${integrationPartnerServiceUrl}/hotels/${hotelId}/rooms/${roomId}`, null, {
        isProtected: true,
    });
    return content;
};

// Удаление тарифа для категории номера
export const deleteRateForHotelRoom = async (
    hotelId: string,
    roomId: string,
    rateId: string
): Promise<IntegrationHotelRoom> => {
    const content = await fetchRequest.delete(
        `${integrationPartnerServiceUrl}/hotels/${hotelId}/rooms/${roomId}/rates/${rateId}`,
        {
            isProtected: true,
        }
    );
    return content;
};

// Удаление размещения для категории номера
export const deleteOccupancyForHotelRoom = async (
    hotelId: string,
    occupancyId: string
): Promise<IntegrationHotelRoom> => {
    const content = await fetchRequest.delete(
        `${integrationPartnerServiceUrl}/hotels/${hotelId}/occupancies/${occupancyId}`,
        {
            isProtected: true,
        }
    );
    return content;
};

// Сохранение номеров и размещений в мс hotels
export const saveHotelRoom = async (hotelId: string, roomData: CreateRoomData): Promise<IntegrationHotelRoom> => {
    const content = await fetchRequest.post(`${integrationPartnerServiceUrl}/hotels/${hotelId}/rooms`, roomData, {
        isProtected: true,
    });
    return content;
};

// Обновление размещений для категории номера из ЛКП
export const updateOccupancies = async (
    hotelId: string,
    roomId: string,
    occupancies: TLOccupancyEditRequest[]
): Promise<IntegrationHotelRoom> => {
    const content = await fetchRequest.patch(
        `${integrationPartnerServiceUrl}/hotels/${hotelId}/rooms/${roomId}/occupancies`,
        occupancies,
        {
            isProtected: true,
        }
    );
    return content;
};

// Создание комнаты отеля в CMS
export const createRoom = async (roomData: CreateRoomData) => {
    const content = await fetchRequest.post(`${hotelsPartnerServiceUrl}/rooms`, roomData, {
        isProtected: true,
    });
    return content;
};

// Редактирование комнаты отеля в CMS
export const editRoom = async (hotelId: string, roomId: string, roomData: EditRoomDataRequest) => {
    const content = await fetchRequest.patch(
        `${integrationPartnerServiceUrl}/hotels/${hotelId}/rooms/${roomId}`,
        roomData,
        {
            isProtected: true,
        }
    );
    return content;
};

// Удаление комнаты отеля в CMS
export const deleteRoom = async (hotelId: string, roomId: string): Promise<any> => {
    const content = await fetchRequest.delete(`${integrationPartnerServiceUrl}/hotels/${hotelId}/rooms/${roomId}`, {
        isProtected: true,
    });
    return content;
};

// Удаление комнаты старого TravelLine
export const deleteTLRoom = async (hotelCmsId: string, roomId: string): Promise<any> => {
    const content = await fetchRequest.delete(`${partnershipServicePath}/hotels/${hotelCmsId}/rooms/${roomId}`, {
        isProtected: true,
    });
    return content;
};
