import classNames from "classnames";
import { RentHousesBookingStatus } from "../../../../types/rentHouses";
import {
    useViewportContext,
    Switch,
    StatusRequestEnum,
    Filter,
    DataGrid,
    DataGridEmptySettings,
} from "@russpass/partner-front-ui";
import { getDataGridBookingColumns } from "./helpers";
import styles from "./styles.module.sass";
import { useRentHousesBookings } from "../../../../api/hooks/rentHouses";
import { useEffect } from "react";
import { ReactComponent as SortIcon } from "../../../../assets/images/icons/ic_filter.svg";
import { useSortFilter } from "./hooks/useSortFilter";
import { Sorting } from "../../../../constants/filters";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../../constants/errors";
import history from "../../../../history";
import routes from "../../../../constants/routes";
import { useLocation } from "react-router-dom";
import { SelectFilter } from "../../components/SelectFilter";
import { FiltersContainer } from "../../components/FiltersContainer";
import { Option } from "../types";
import { Controls } from "../../components/Controls";
import { sendEvent } from "../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../product_analytics/constants";

export type BookingListProps = {
    status: RentHousesBookingStatus[];

    page: number;
    onPageChange: (page: number) => void;

    objectOptions: Option[];
    isLoadingObjects: boolean;
    activeObject: string;
    setActiveObject: (object: string) => void;
    emptySettings: DataGridEmptySettings;
    openCalendar: () => void;
};

const PAGE_SIZE = 10;

const ApartmentsBookingList = ({
    status,
    page,
    onPageChange,
    objectOptions,
    isLoadingObjects,
    activeObject,
    setActiveObject,
    openCalendar,
    emptySettings,
}: BookingListProps) => {
    const viewportContext = useViewportContext();
    const { isMobile } = viewportContext;

    const { options: sortOptions, activeOption: activeSort, setActiveOption: setActiveSort } = useSortFilter(status);

    const { data, isLoading, error, mutate } = useRentHousesBookings({
        page: page + 1,
        pageSize: PAGE_SIZE,
        status,
        sort: activeSort as Sorting,
        objectId: activeObject ? activeObject : undefined,
    });

    // Обновление списка при закрытии карточки бронирования
    const hasId = !!new URLSearchParams(useLocation().search).get("id");
    useEffect(() => {
        if (!hasId) mutate();
    }, [hasId, mutate]);

    useEffect(() => {
        if (!error) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: GET_DATA_ERROR,
        });
    }, [error]);

    const total = data?.data?.total || 0;
    const totalPages = Math.ceil(total / PAGE_SIZE);

    return (
        <DataGrid
            className={classNames(styles.List, {
                [styles.List__filtersHidden]: !data?.data?.total && !activeObject,
            })}
            isLoading={isLoading || isLoadingObjects}
            dataGridSetting={{
                data: data?.data?.items || [],
                columns: getDataGridBookingColumns(status, viewportContext),
                onSelect: ({ id, object }) => {
                    sendEvent(AnalyticEvent.host_renthouse_click, { cms_id: object.cmsId });
                    history.push({ pathname: routes.apartmentsBookingAll, search: `id=${id}` });
                },
                dataGridRowClassName: (item) =>
                    classNames({ [styles.FrameMobile]: isMobile && !item?.lookedByPartner }),
            }}
            emptySettings={emptySettings}
            paginate={{
                page,
                totalPages,
                onPageChange,
            }}
            filters={
                <Controls>
                    <FiltersContainer>
                        {objectOptions.length > 1 && (
                            <SelectFilter options={objectOptions} value={activeObject} onChange={setActiveObject} />
                        )}

                        <Filter
                            options={sortOptions}
                            value={activeSort}
                            onChange={setActiveSort}
                            filterIcon={<SortIcon />}
                            modalTitle="Сортировка"
                        />
                    </FiltersContainer>
                    <Switch label="Календарь" value={false} name="isCalendar" onChange={openCalendar} />
                </Controls>
            }
        />
    );
};

export default ApartmentsBookingList;
