import { createContext, useContext } from "react";
import { cloneDeep } from "lodash";
//TODO move
import { HotelData, HotelDataForm } from "../new-form/types/hotel-info";
import { hotelDataForm } from "../new-form/constants/hotel-info";

type HotelDataContextType = {
    isLoadingHotelInfo?: boolean;
    hotelInfoData: HotelDataForm;
    isNewTravelLineHotel?: boolean;
    isTravelLineHotel?: boolean;
    checkExistTravelLineHotel?: boolean;
    loadHotelInfoData: (isFullLoadData?: boolean) => Promise<HotelData | HotelDataForm | null>;
};
const HotelDataContext = createContext<HotelDataContextType>({
    hotelInfoData: cloneDeep(hotelDataForm),
    loadHotelInfoData: async () => {
        return cloneDeep(hotelDataForm);
    },
});

const useHotelDataContext = () => {
    const context = useContext(HotelDataContext);
    if (!context) {
        throw new Error("useHotelDataContext must be used within a ModalInfoProvider");
    }
    return context;
};
export { useHotelDataContext, HotelDataContext };
