import { useCallback } from "react";
import { deleteSpecialOffers, sendSpecialOffersToArchive } from "../../../../../../../api/specialOffers";
import eventDispatcher from "../../../../../../../lib/event-dispatcher";
import { COMMON_RELOAD_ERROR } from "../../../../../../../constants/errors";
import { DiscountStatus } from "../../../types/hotel-discounts";
import checkDiscountStatus from "../../../utils/checkDiscountStatus";
import { useHotelDiscountStoreContext } from "../context/discounts-store/useHotelDiscountStoreContext";
import { useModalAlertContext, StatusRequestEnum } from "@russpass/partner-front-ui";
import { DiscountDeleteAlertButton, DiscountDeleteAlertTitle } from "../../../constants/hotel-discounts-delete-alert";

const useDeleteDiscount = () => {
    const { openModalAlert } = useModalAlertContext();

    const { loadDiscounts } = useHotelDiscountStoreContext();

    const onDeleteSpecialOffers = useCallback(
        async (id: string | number, status: DiscountStatus, onSuccess?: () => void) => {
            try {
                const { isArchive } = checkDiscountStatus(status);
                if (isArchive) {
                    await deleteSpecialOffers(id);
                } else {
                    await sendSpecialOffersToArchive(id);
                }

                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: isArchive ? "Акция удалена" : "Акция отправлена в Архив",
                });

                await loadDiscounts();
                onSuccess?.();
            } catch (e) {
                console.error(e);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: COMMON_RELOAD_ERROR,
                });
            }
        },
        [loadDiscounts]
    );

    return (id: number | string, status: DiscountStatus, onSuccess?: () => void) => {
        const title = (status && DiscountDeleteAlertTitle[status]) || "Вы уверены, что хотите удалить акцию?";
        const OKButton = (status && DiscountDeleteAlertButton[status]) || "Удалить";

        openModalAlert(
            {
                title,
                OKButton,
                cancelButton: "Отменить",
            },
            () => onDeleteSpecialOffers(id, status, onSuccess)
        );
    };
};

export default useDeleteDiscount;
