import { useCallback, useEffect, useState } from "react";
import { Restaurant, RestaurantsListItem } from "./types";
import { deleteRestaurant, getRestaurantsAll } from "../../../api/restaurants";
import { useLocation } from "react-router-dom";
import history from "../../../history";
import routes from "../../../constants/routes";
import { preparedRestaurantToObject } from "./utils";
import eventDispatcher from "../../../lib/event-dispatcher";
import { GET_DATA_ERROR, POST_DATA_ERROR } from "../../../constants/errors";
import { useModalAlertContext, StatusRequestEnum, ObjectItemType } from "@russpass/partner-front-ui";
import { modalAlertDetails } from "./constants";

const useRestaurants = () => {
    const location = useLocation<{ status?: number } | null>();

    const { openModalAlert } = useModalAlertContext();

    const [activeTabKey, setActiveTabKey] = useState<string | number | undefined>(location.state?.status);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [restaurantsList, setRestaurantsList] = useState<RestaurantsListItem[]>([]);

    const onClickAddRestaurant = () => {
        history.push({
            pathname: routes.restaurantNew,
            state: location?.state,
        });
    };

    const onGetRestaurantsAll = useCallback(() => {
        setIsLoading(true);
        getRestaurantsAll()
            .then((data) => {
                setRestaurants(data);
                setRestaurantsList(preparedRestaurantToObject(data));
            })
            .catch((err) => {
                console.error(err);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: GET_DATA_ERROR,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const onDeleteRestaurant = useCallback(
        (id: string) => {
            setIsLoading(true);
            deleteRestaurant(id)
                .then(() => {
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Объект отправлен в Архив",
                    });
                })
                .then(onGetRestaurantsAll)
                .catch((error) => {
                    console.error(error);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR,
                    });
                })
                .finally(() => setIsLoading(false));
        },
        [onGetRestaurantsAll]
    );

    const onClickDeleteRestaurant = useCallback(
        (data: ObjectItemType) => openModalAlert(modalAlertDetails, () => onDeleteRestaurant(data.id)),
        [onDeleteRestaurant, openModalAlert]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onGetRestaurantsAll(), []);

    return {
        restaurantsList,
        isEmptyTotal: !restaurants.length,
        isLoading,
        activeTabKey,
        setActiveTabKey,
        onClickAddRestaurant,
        onClickDeleteRestaurant,
    };
};

export default useRestaurants;
