import { Form, Formik } from "formik";
import { InputField, TextareaField, StatusRequestEnum } from "@russpass/partner-front-ui";
import SelectLanguagesField from "./components/SelectLanguagesField";
import SendModerationButton from "./components/SendModerationButton";
import { validationSchema } from "./validation-schema";
import { useRentHousesOwnerProfile } from "../../../../api/hooks/rentHouses";
import React, { useEffect, useMemo } from "react";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { UNEXPECTED_ERROR } from "../../../../constants/errors";
import ModerationInfo from "./components/ModerationInfo";
import AvatarField from "./components/AvatarField";
import { saveRentHousesImage, updateRentHousesOwnerProfile } from "../../../../api/rentHouses";
import { RentHousesOwnerStatus } from "../../../../types/rentHouses";
import RentHousesDictionaryField from "../../../../components/form-fields/renthouses-dictionary-field";

type FormValues = {
    image: { id?: string; file?: File };
    name: string;
    description: string;
    languages: string[];
    city: string;
};

const User = () => {
    const { data, error, mutate } = useRentHousesOwnerProfile();
    useEffect(() => {
        if (!error) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [error]);

    const initialValues = useMemo(() => {
        if (!data?.data) return {} as FormValues;

        return {
            image: data.data.image ? { id: data.data.image } : undefined,
            name: data.data.name,
            description: data.data.description,
            languages: data.data.languages,
            city: data.data.city,
        } as FormValues;
    }, [data]);

    const submit = async (values: FormValues) => {
        try {
            let imageId = values.image.id || "";
            if (values.image.file) {
                imageId = (await saveRentHousesImage(values.image.file)).data.cmsId;
            }
            await updateRentHousesOwnerProfile({
                ...values,
                image: imageId,
            });
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Success,
                body: "Информация отправлена на модерацию",
            });
            await mutate();
        } catch (e) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: UNEXPECTED_ERROR,
            });
        }
    };

    const isPendingUpdate = data?.data?.status === RentHousesOwnerStatus.PENDING_UPDATE;
    const isPending = data?.data?.status === RentHousesOwnerStatus.PENDING;
    const formDisabled = isPendingUpdate || isPending;
    const isShowModerationInfo = data?.data?.status === RentHousesOwnerStatus.DECLINED;

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
            validateOnMount
            onSubmit={submit}
        >
            <div className="content-wrapper">
                <div className="content-wrapper__form">
                    <Form>
                        {isShowModerationInfo && <ModerationInfo info={data?.data?.declineReason} />}
                        <AvatarField
                            name="image"
                            disabled={formDisabled}
                            tooltipText="Добавьте вашу фотографию или логотип компании, сдающей жилье"
                        />
                        <InputField
                            name="name"
                            label="Имя для профиля"
                            placeholder="Короткое или полное имя"
                            noHover
                            disabled={formDisabled}
                        />
                        <TextareaField
                            name="description"
                            label="Информация"
                            placeholder="Расскажите о себе"
                            hint="Гостям интересно, у кого они останавливаются"
                            noHover
                            disabled={formDisabled}
                        />
                        <SelectLanguagesField name="languages" disabled={formDisabled} />
                        <RentHousesDictionaryField
                            name="city"
                            dictionary="cities"
                            disabled={formDisabled}
                            label="Город проживания"
                            searchPlaceholder="Название города"
                        />
                        {!formDisabled && <SendModerationButton />}
                    </Form>
                </div>
            </div>
        </Formik>
    );
};

export default User;
