export const SECONDS_TO_REDIRECT = 6;

export const ERROR_CONTENT_ON_INIT = "Ошибка инициализации авторизации, обновите страницу.";

//Keycloak status
export const TITLE_LOG_ON_AUTH_SUCCESS = "Auth is success!";
export const TITLE_LOG_ON_INIT_SUCCESS = "Init keycloak is success!";
export const TITLE_LOG_ON_TOKEN_UPDATE_SUCCESS = "Token is success update!";

export const TITLE_LOG_ON_READY = "Ready!";

export const TITLE_LOG_ON_TOKEN_EXPIRED = "Token is expired!";

export const TITLE_LOG_ON_ERROR_INIT = "Init keycloak is rejected!";
export const TITLE_LOG_ON_ERROR_AUTH = "Auth is rejected!";
export const TITLE_LOG_ON_ERROR_TOKEN_UPDATE = "Token update is rejected!";

export const ERROR_NOTIFICATION_TITLE_ON_AUTH = "Ошибка при авторизации.";
export const ERROR_NOTIFICATION_TITLE_ON_UPDATE_TOKEN = "Ошибка обновления токена.";
