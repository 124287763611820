import { useEventListener } from "@russpass/partner-front-ui";
import { useCallback, useRef } from "react";
import { ReactComponent as CrossIcon } from "../../../../../../assets/images/icons/ic_cross_bold_red.svg";
import useOnClickOutside from "../../../../../../hooks/useOnClickOutside";
import styles from "./DisputePopupMobile.module.sass";

type Props = {
    isOpened: boolean;
    setIsOpened: (isOpened: boolean) => void;
    onStartDispute: () => void;
};

export const DisputePopupMobile = ({ isOpened, setIsOpened, onStartDispute }: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    useEventListener("keydown", ({ key }: KeyboardEvent) => {
        if (key !== "Escape") return;
        if (isOpened) setIsOpened(false);
    });

    const handleClose = useCallback(() => {
        setIsOpened(false);
    }, [setIsOpened]);

    useOnClickOutside(ref, handleClose);

    return (
        <div ref={ref} className={styles.DisputePopupMobile}>
            <button
                type="button"
                onClick={() => {
                    onStartDispute();
                    handleClose();
                }}
            >
                <CrossIcon /> <span>Оспорить оценку</span>
            </button>
        </div>
    );
};
