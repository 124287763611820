import { Creator, DialogueMessageStatus, PartnerStatus } from "../constants";

export type DialogueFile = {
    id: string;
    attachId: string;
    size: number;
    filename: string;
};

export enum DialogBookingStatus {
    PENDING = "PENDING",
    ACTIVE = "ACTIVE",
    REJECTED = "REJECTED",
    REJECTED_PAID = "REJECTED_PAID",
    NONE = "NONE",
}

export type DialogueMessageCommon = {
    id: string;
    createdAt: string;
    updatedAt: string;
    creator: Creator;
    partnerStatus: DialogueMessageStatus;
    message?: string;
    booking?: {
        id: string;
        checkinDate: string;
        checkoutDate: string;
        status: DialogBookingStatus;
    };
    files?: DialogueFile[];
};

export type DialogueMessage = DialogueMessageCommon & {
    userStatus: DialogueMessageStatus;
};

type User = {
    externalId: number;
    firstName: string;
    lastName: string;
    middleName?: string;
    image?: string;
};

export type DialogueModel = {
    id: string;
    createdAt: string;
    updatedAt: string;
    partnerStatus: PartnerStatus;
    blocked: boolean;
    user?: User;
    object?: {
        cmsId: string;
        title: string;
        type: string;
        image: string;
    };
    booking?: {
        id: string;
        status: string;
        checkinDate: string;
        checkoutDate: string;
    };

    lastMessage?: DialogueMessageCommon;
};
