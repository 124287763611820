import moment from "moment";
import { TIME_FORMAT } from "../../../../constants/dates-format";
import styles from "./TimeUntil.module.sass";
import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";

type Props = {
    expireIn?: number;
    onExpired?: () => void;
    className?: string;
};

export const TimeUntil = ({ expireIn, onExpired, className }: Props) => {
    const [diff, setDiff] = useState(moment.duration(expireIn || 0));

    const isSendExpired = useRef(false);

    useEffect(() => {
        const timer = setInterval(() => {
            const diff = moment.duration(expireIn || 0);
            if (!diff.asMilliseconds() && !isSendExpired.current) {
                onExpired?.();
                isSendExpired.current = true;
            }
            setDiff(diff);
        }, 1000);
        return () => clearInterval(timer);
    }, [expireIn, onExpired]);

    const isExpired = useMemo(() => diff.asMilliseconds() != null && diff.asMilliseconds() <= 0, [diff]);

    return (
        <span className={classNames({ [styles.Alarm]: diff.asHours() < 3 }, className)}>
            {!isExpired ? moment.utc(diff.asMilliseconds()).format(TIME_FORMAT) : "00:00"}
        </span>
    );
};
