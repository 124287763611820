import React from "react";
import StepOneForm from "./components/stepOne/form";
import StepTwoForm from "./components/stepTwo/form";
import useCompanyInfoForm from "./hooks/useCompanyInfoForm";
import FormManagerProvider from "./context/form-manager";

const CompanyInfo = () => {
    const {
        statusSigningOffer,
        isTourOperator,
        handleSignOffer,
        isLoadingForm,
        onCloseOffer,
        handleStepChange,
        objectData,
        step,
        simpleRegistration,
        handleValidateBankAccount,
        bankErrors,
    } = useCompanyInfoForm();

    return (
        <FormManagerProvider
            statusSigningOffer={statusSigningOffer}
            isLoadingForm={isLoadingForm}
            onCloseOffer={onCloseOffer}
            simpleRegistration={simpleRegistration}
        >
            {(!isTourOperator || (isTourOperator && step === 1)) && (
                <StepOneForm
                    initialValues={objectData}
                    onStepChange={handleStepChange}
                    hasNextStep={isTourOperator}
                    handleSignOffer={handleSignOffer}
                    onValidateBankAccount={handleValidateBankAccount}
                    bankErrors={bankErrors}
                />
            )}
            {isTourOperator && step === 2 && objectData && (
                <StepTwoForm initialValues={objectData} handleSignOffer={handleSignOffer} />
            )}
        </FormManagerProvider>
    );
};
export default CompanyInfo;
