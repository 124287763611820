import { RestaurantInfoSendPostType } from "../../containers/restaurants/restaurant/types";
import fetchRequest from "../manager";
import { partnerApiServices, russpassApiServices } from "../../constants/api";
import { Restaurant } from "../../containers/restaurants/all/types";

const partnershipServicePath = russpassApiServices.partnership;
const restPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.restaurants}`;

export const createRestaurant = async (restData: RestaurantInfoSendPostType) => {
    const response = await fetchRequest.post(restPartnerServiceUrl, restData, {
        isProtected: true,
    });
    return response;
};

export const editRestaurant = async (restaurantData: RestaurantInfoSendPostType) => {
    const response = await fetchRequest.patch(`${restPartnerServiceUrl}/`, restaurantData, {
        isProtected: true,
    });
    return response;
};

export const sendForApprovalRestaurant = async (id: string) => {
    const response = await fetchRequest.patch(`${restPartnerServiceUrl}/sendForApproval/${id}`, null, {
        isProtected: true,
    });
    return response;
};

export const getRestaurantsAll = async (): Promise<Restaurant[]> => {
    const response = await fetchRequest.get(`${restPartnerServiceUrl}`, null, {
        isProtected: true,
    });
    return response;
};

export const getRestaurant = async (id: string) => {
    const response = await fetchRequest.get(`${restPartnerServiceUrl}/${id}`, null, {
        isProtected: true,
    });
    return response;
};

export const deleteRestaurant = async (id: string) => {
    const response = await fetchRequest.delete(`${restPartnerServiceUrl}/${id}`, {
        isProtected: true,
    });
    return response;
};

export const setCanReserveRestaurant = (id: string, canReserve: boolean) =>
    fetchRequest.patch(
        `${restPartnerServiceUrl}/${id}/canReserve`,
        {
            canReserve,
        },
        {
            isProtected: true,
        }
    );
