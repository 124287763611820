import { russpassApiServices } from "../constants/api";
import fetchRequest from "./manager";
import { DraftExcursionDataPost } from "../containers/services/excursion/helpers/types";

const filesDraftServiceUrl = `${russpassApiServices.draft}`;

export const saveExcursionDraft = async (externalPartnerId: string, draft: DraftExcursionDataPost) => {
    const data = await fetchRequest.post(
        `${filesDraftServiceUrl}/excursions`,
        {
            externalPartnerId,
            typeSource: "LKP",
            draft,
        },
        {
            isProtected: true,
        }
    );
    return data;
};

export const patchExcursionDraft = async (id: string, draft: DraftExcursionDataPost) => {
    const data = await fetchRequest.patch(
        `${filesDraftServiceUrl}/excursions`,
        {
            id,
            draft,
        },
        {
            isProtected: true,
        }
    );
    return data;
};

export const deleteExcursionDraft = async (id: string) => {
    const data = await fetchRequest.delete(`${filesDraftServiceUrl}/excursions/${id}`, {
        isProtected: true,
    });
    return data;
};

export const getExcursionsDraft = async (externalPartnerId: string) => {
    const data = await fetchRequest.get(
        `${filesDraftServiceUrl}/excursions`,
        {
            externalPartnerId,
        },
        {
            isProtected: true,
        }
    );
    return data;
};

export const getDraftExcursion = async (id: string) => {
    const data = await fetchRequest.get(`${filesDraftServiceUrl}/excursions/${id}`, null, {
        isProtected: true,
    });
    return data;
};
