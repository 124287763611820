import { useLocation } from "react-router-dom";
import { ModalWindowRight, StatusRequestEnum } from "@russpass/partner-front-ui";
import { useRentHousesBooking } from "../../../../api/hooks/rentHouses";
import { RentHousesBookingStatus } from "../../../../types/rentHouses";
import { PendingModal } from "./modals/PendingModal";
import { closeBookingModal } from "./utils/closeBookingModal";
import { useEffect } from "react";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../../constants/errors";
import { ActiveModal } from "./modals/ActiveModal";
import { CompletedModal } from "./modals/CompletedModal";
import { RejectedModal } from "./modals/RejectedModal";

const ApartmentsBookingDetails = () => {
    const id = new URLSearchParams(useLocation().search).get("id");
    const { data, isLoading, error } = useRentHousesBooking(id || undefined);
    let booking = data?.data;

    useEffect(() => {
        if (!error) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: GET_DATA_ERROR,
        });
        if (!data) closeBookingModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    if (isLoading || !booking || error) {
        return <ModalWindowRight isOpened={!!id} isLoadingWithoutContent onClose={closeBookingModal} />;
    }

    return (
        {
            [RentHousesBookingStatus.PENDING]: <PendingModal booking={booking} />,
            [RentHousesBookingStatus.ACTIVE]: <ActiveModal booking={booking} />,
            [RentHousesBookingStatus.STARTED]: <ActiveModal booking={booking} />,
            [RentHousesBookingStatus.COMPLETED]: <CompletedModal booking={booking} />,
            [RentHousesBookingStatus.REJECTED]: <RejectedModal booking={booking} />,
            [RentHousesBookingStatus.WAITING_TO_REJECT]: <RejectedModal booking={booking} />,
        }[booking.status] || null
    );
};

export default ApartmentsBookingDetails;
