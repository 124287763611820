export const partnerFacilityDefaultValue = {
    cmsFacilityId: "",
    title: "Добавление нового места",
    status: "",
    isRejected: false,
    creationDt: "",
    archivedDt: "",
};

export const facilityData = {
    title: "",
    description: "",
    partnerType: "",
    partner: "",
    address: "",
    addressDetail: {
        country: "",
        postal_code: "",
        region_with_type: "",
        city_with_type: "",
        street_with_type: "",
        house: "",
        room: "",
        unrestricted_value: "",
        cms_region_id: "",
    },
    phones: [],
    workingTime: [
        {
            uuid: "",
            workingDays: [],
            time: [""],
            breakTime: [""],
        },
    ],
};
