import React, { useEffect } from "react";
import { CommonLayout } from "@russpass/partner-front-ui";
import ContactingSupport from "./components/contactingSupport";

import styles from "./support.module.sass";
import { sendEvent } from "../../product_analytics/analytics";
import { AnalyticEvent } from "../../product_analytics/constants";

const Support: React.FC = () => {
    useEffect(() => {
        sendEvent(AnalyticEvent.support_click);
    }, []);
    return (
        <CommonLayout pageTitle="Поддержка">
            <div className={styles.support}>
                <div className={styles.support__container}>
                    <ContactingSupport />
                </div>
            </div>
        </CommonLayout>
    );
};

export default Support;
