import { IntegrationHotelRoom } from "../../../../types/hotel-room";
import "./styles.sass";

type AlertDetailsTextProps = {
    sameRooms: IntegrationHotelRoom[];
    onNameClick: (room: IntegrationHotelRoom) => void;
};

const AlertDetailsText = ({ sameRooms, onNameClick }: AlertDetailsTextProps) => {
    return (
        <>
            <span>{"Тариф привязан к номерам: "}</span>
            <br />
            <span>
                {sameRooms.map((room, indx) => (
                    <span className="name" key={`roomId-${room.id}`} onClick={() => onNameClick(room)}>
                        {`\u00ab${room.name}\u00bb`}
                        <span>{indx !== sameRooms.length - 1 ? ", " : ". "}</span>
                    </span>
                ))}
                {"Отвяжите тариф во всех номерах, тогда его можно будет удалить."}
            </span>
        </>
    );
};
export default AlertDetailsText;
