import React, { FC, useCallback, useEffect } from "react";
import { TourRouteEvent, TourRoute, ITourData, ITourDataPost } from "../types";
import { Form, Formik, FormikProps } from "formik";
import { tourProgramValidation } from "../validation-schema";
import _ from "lodash";
import classes from "./tour.module.sass";
import { getStyleForCard } from "../../../../utils/get-style-for-card";
import {
    InputListField,
    TextareaField,
    AutoSubmit,
    Card,
    CardsGrid,
    UploadPhotosWrapper,
    UploadPhotosItem,
} from "@russpass/partner-front-ui";
import { navbarTourNames } from "../constants";
import { uploadPhotoToCms } from "../../../../api/files";
import { onError, onFileTooLarge, onPhotosLimit } from "../../../../utils/images";

type TourProgramProps = {
    data: ITourData;
    onChange: (values: ITourDataPost) => void;
    onSubmit: (path: string) => void;
    isShowErrors: boolean;
    disabled: boolean;
};

const TourProgram: FC<TourProgramProps> = ({ data, onChange, onSubmit, isShowErrors, disabled }) => {
    const renderRoute = () => {
        const route = [];
        while (Number(data.nights) + 1 !== route.length) {
            if (data.route.length) {
                const item = data.route.shift();
                if (item) {
                    route.push(item);
                }
            } else {
                route.push({ title: "", events: [] });
            }
        }

        return route;
    };

    useEffect(() => {
        if (data.nights) {
            onChange({
                ...data,
                route: renderRoute(),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.nights]);

    const handleChange = useCallback(
        (values: TourRouteEvent[], changeIndex: number) => {
            const route = data.route.reduce<TourRoute[]>((acc, val, index) => {
                acc.push(index === changeIndex ? { title: val.title, events: values } : val);
                return acc;
            }, []);

            onChange({
                ...data,
                route: route,
            });
        },
        [onChange, data]
    );

    const onDeleteImg = useCallback(
        (file, changeIndex: number) => {
            handleChange(
                data.route[changeIndex]?.events.filter((prevEvent) => prevEvent.images[0] !== file),
                changeIndex
            );
        },
        [data, handleChange]
    );

    const uploadPhotos = useCallback(
        (values: UploadPhotosItem[], day) => {
            handleChange(
                values.map((value, index) => ({
                    ...data.route[day]?.events[index],
                    images: [value.image],
                    geoData: { coordinates: [] },
                })),
                day
            );
        },
        [data, handleChange]
    );

    const renderProgramDay = () => {
        if (data?.nights !== undefined) {
            const countDay = _.range(0, Number(data.nights) + 1);

            return countDay.map((day) => (
                <div key={day} className={classes.dayItem}>
                    <div className="mb-40">
                        <div className={classes.day}>
                            <span className={classes.daysNum}>{day < 9 ? `0${day + 1}` : day + 1}</span>
                            <span className={classes.dayName}> день</span>
                        </div>
                        <div className="content-wrapper__form">
                            <InputListField
                                label="Программа"
                                name={`route[${day}].title`}
                                placeholderItems={["Опишите мероприятия дня по пунктам"]}
                                errorSettings={{
                                    showOnChange: isShowErrors,
                                }}
                                uniquePrefixId={`route${day}`}
                                disabled={disabled}
                                hint="Пишите каждый новый пункт с новой строчки"
                            />
                        </div>
                    </div>
                    <div className="mb-48">
                        <UploadPhotosWrapper
                            items={
                                data?.route && data?.route.length
                                    ? data?.route[day]?.events?.reduce((prev: UploadPhotosItem[], current) => {
                                          if (current.images) {
                                              return [...prev, { image: current.images[0] }];
                                          }
                                          return prev;
                                      }, [])
                                    : []
                            }
                            title="Добавить фото объектов тура"
                            onChange={(values) => {
                                uploadPhotos(values, day);
                            }}
                            disabled={disabled}
                            uploadPhoto={uploadPhotoToCms}
                            onFileTooLarge={onFileTooLarge}
                            onPhotosLimit={onPhotosLimit}
                            onError={onError}
                        >
                            <CardsGrid
                                gap={{
                                    rowGap: 24,
                                    columnGap: 24,
                                }}
                            >
                                {data?.route[day]?.events?.map((event, index) => {
                                    return (
                                        <div key={index}>
                                            <Card
                                                key={event.images[0]}
                                                data={event.images[0]}
                                                onDelete={disabled ? undefined : (item) => onDeleteImg(item, day)}
                                                styles={getStyleForCard(316, event.images[0])}
                                            />
                                            <div className={classes.tourProgram__desc}>
                                                <TextareaField
                                                    label=""
                                                    name={`route[${day}].events[${index}].title`}
                                                    noHover
                                                    maxRows={2}
                                                    disabled={disabled}
                                                    errorSettings={{
                                                        showOnChange: isShowErrors,
                                                    }}
                                                    placeholder="Добавить описание"
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </CardsGrid>
                        </UploadPhotosWrapper>
                    </div>
                </div>
            ));
        }

        return null;
    };

    const handleFormSubmit = useCallback(() => {
        onSubmit(navbarTourNames.photos);
    }, [onSubmit]);

    return (
        <div className="content-wrapper">
            <Formik
                key="formik-programm"
                initialValues={data}
                enableReinitialize
                validateOnMount
                validationSchema={tourProgramValidation}
                onSubmit={handleFormSubmit}
            >
                {({ isValid }: FormikProps<ITourDataPost>) => {
                    return (
                        <Form>
                            <AutoSubmit onChange={onChange} />
                            {renderProgramDay()}
                            {isValid && !disabled && (
                                <div className="mt-32">
                                    <button
                                        type="submit"
                                        className="button button--second button--full-width"
                                        data-qa-name="next-button"
                                    >
                                        Далее
                                    </button>
                                </div>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default TourProgram;
