import fetchRequest from "./manager";
import { partnerApiServices, russpassApiServices } from "../constants/api";
import { getParamsForRequest } from "../utils";
import { HotelType } from "../containers/hotels/hotel-new/types";
import { TSelectValue } from "../types/dictionaries";

const partnershipServicePath = russpassApiServices.partnership;
const dictionaryCmsPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.dictionaryCms}`;
const dictionaryPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.dictionary}`;
const restaurantTypesPath = `${partnershipServicePath}${partnerApiServices.dictionaryCms}${russpassApiServices.restaurantTypes}`;
const restaurantCharacteristicPath = `${partnershipServicePath}${partnerApiServices.dictionaryCms}${russpassApiServices.restaurantCharacteristic}`;

type AddressType = {
    data: {
        geo_lat: string;
        geo_lon: string;
    };
    unrestricted_value: string;
    value: string;
};

type DictionaryRawDataType = {
    rows: TSelectValue[];
};

export const getRegionsByParams = async (params: {
    regionName?: string;
    travellineId?: string;
    pageSize?: number;
    page?: number;
}) => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/regions${getParamsForRequest(params)}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getRegionName = async (id: string) => {
    const content = await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/regions/${id}`, null, {
        isProtected: true,
    });
    return content;
};

export const getAddress = async (str: string) => {
    const content: AddressType[] = await fetchRequest.get(
        `${dictionaryPartnerServiceUrl}/addresses?query=${str}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getCitiesByParams = async (params: {
    travellineId?: string;
    pageSize?: number;
    page?: number;
    cityName?: string;
}) => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/cities${getParamsForRequest(params)}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getCityName = async (id: string) => {
    const { title } = await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/cities/${id}`, null, {
        isProtected: true,
    });
    return title;
};

export const getExcursionForms = async (): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/excursionForms`, null, {
        isProtected: true,
    });
    return content;
};

export const getExcursionViewOptions = async (): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/excursionView`, null, {
        isProtected: true,
    });
    return content;
};

export const getShowcases = async (page = 1, pageSize = 150): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/showcases?page=${page}&pageSize=${pageSize}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getCommonServices = async (page = 1, pageSize = 150): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/commonServices?page=${page}&pageSize=${pageSize}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getMealsServices = async (page = 1, pageSize = 150): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/meals?page=${page}&pageSize=${pageSize}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getBeachServices = async (page = 1, pageSize = 150): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/beachServices?page=${page}&pageSize=${pageSize}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getEntertainmentServices = async (page = 1, pageSize = 150): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/entertainmentServices?page=${page}&pageSize=${pageSize}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getFitnessServices = async (page = 1, pageSize = 150): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/fitnessServices?page=${page}&pageSize=${pageSize}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getFacilityServices = async (page = 1, pageSize = 150): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/facilityServices?page=${page}&pageSize=${pageSize}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getRoomAmenitiesCategories = async (page = 1, pageSize = 151): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/roomAmenitiesCategories?page=${page}&pageSize=${pageSize}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getRoomAmenities = async (page = 1, pageSize = 900): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(
        `${dictionaryCmsPartnerServiceUrl}/roomAmenities?page=${page}&pageSize=${pageSize}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getHotelTypes = async (): Promise<HotelType[]> => {
    const content = await fetchRequest.get(`${dictionaryPartnerServiceUrl}/hotelTypes`);

    return content;
};

export const getEventTypes = async () => {
    const content = await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/eventTypes`, null, {
        isProtected: true,
    });
    return content;
};

export enum HotelResidenceSlug {
    BED = "bed",
    PRIVATE_ROOM = "private_room",
    APARTMENT = "apartment",
}

export type HotelResidenceTypesRes = {
    rows: Array<{
        id: string;
        title: string;
        slug: HotelResidenceSlug;
        status: string;
    }>;
};
export const getHotelResidenceTypes = async (): Promise<HotelResidenceTypesRes> => {
    return await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/hotelResidenceTypes`, null, {
        isProtected: true,
    });
};

// TODO depricated
export const getRoomBedTypes = async () => {
    const content = await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/roomBedTypes`, null, {
        isProtected: true,
    });
    return content;
};

type HousingTypesReq = {
    useForHotels: boolean;
    useForRenthouses: boolean;
    page?: number;
    pageSize?: number;
};

export type HousingTypesRes = {
    page: number;
    pageSize: number;
    rows: Array<{
        id: string;
        title: string;
        slug: string;
        status: string;
    }>;
    total: number;
    totalPages: number;
};

export const getHousingTypesDictionary = async (params: HousingTypesReq) => {
    const data: HousingTypesRes = await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/housingTypes`, params, {
        isProtected: true,
    });
    return data;
};

export const getCuisinesTypes = async (): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/cuisines`, null, {
        isProtected: true,
    });
    return content;
};

export const getRestaurantTypesTypes = async (): Promise<DictionaryRawDataType> => {
    const response = await fetchRequest.get(restaurantTypesPath, null, {
        isProtected: true,
    });
    return response;
};

export const getRestaurantCharacteristicTypes = async (): Promise<DictionaryRawDataType> => {
    const response = await fetchRequest.get(restaurantCharacteristicPath, null, {
        isProtected: true,
    });
    return response;
};

export const getHoldingSpace = async (): Promise<DictionaryRawDataType> => {
    const response = await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/holdingSpace`, null, {
        isProtected: true,
    });
    return response;
};

export const getRestaurantTagsTypes = async (): Promise<DictionaryRawDataType> => {
    const content = await fetchRequest.get(`${dictionaryCmsPartnerServiceUrl}/restaurantTags`, null, {
        isProtected: true,
    });
    return content;
};
