import { useCallback, useState } from "react";

export type Modal = {
    isOpen: boolean;
    open: () => unknown;
    close: () => unknown;
};

const useModal = (isDefaultOpen: boolean = false): Modal => {
    const [isOpen, setIsOpen] = useState(isDefaultOpen);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    const open = useCallback(() => {
        setIsOpen(true);
    }, []);

    return {
        isOpen,
        close,
        open,
    };
};

export default useModal;
