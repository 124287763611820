import { DefaultFormData } from "./defaultForm/types/types";

export const prepareDefaultValues = (defaultFormValues: DefaultFormData) => {
    const { agreePersonalData, agreeTrueData, details, ...data } = defaultFormValues;
    return { details: [details], ...data };
};

export const prepareJiraDescription = (data: string[]) => {
    const symbol = `
        `;
    let details = "";
    data.forEach((item, i) => {
        if (i > 0) {
            details += symbol;
        }
        details += item;
    });
    return details;
};

export const prepareEmailDescription = (data: string[]) => {
    let details = "";
    data.forEach((item) => {
        details += `<p>${item}</p>`;
    });
    return details;
};
