import posthog from "posthog-js";
import { posthogApiKey, posthogApiUrl } from "./constants";

export const startSendAnalytics = () => {
    typeof posthogApiKey === "string" &&
        posthog.init(posthogApiKey, {
            api_host: posthogApiUrl,
            autocapture: false,
            persistence: "localStorage",
        });
};
