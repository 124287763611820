import { useMemo, useState } from "react";

export enum MarkSortingEnum {
    All = "All",
    Low = "Low",
    Medium = "Medium",
    High = "High",
}

const markSortingValue = {
    [MarkSortingEnum.All]: { valueFrom: undefined, valueTo: undefined },
    [MarkSortingEnum.Low]: { valueFrom: 1, valueTo: 4 },
    [MarkSortingEnum.Medium]: { valueFrom: 5, valueTo: 6 },
    [MarkSortingEnum.High]: { valueFrom: 7, valueTo: 10 },
};

const options = [
    {
        label: "Все отзывы",
        value: MarkSortingEnum.All,
    },
    {
        label: "Низкие 1 - 4",
        value: MarkSortingEnum.Low,
    },
    {
        label: "Средние 5 - 6",
        value: MarkSortingEnum.Medium,
    },
    {
        label: "Высокие 7 - 10",
        value: MarkSortingEnum.High,
    },
];

export const useRatingMarkValueFilter = () => {
    const [activeOption, setActiveOption] = useState<string>(MarkSortingEnum.All);

    const { valueFrom, valueTo } = useMemo(() => {
        return markSortingValue[activeOption as MarkSortingEnum];
    }, [activeOption]);

    return {
        options,
        valueFrom,
        valueTo,
        activeOption,
        setActiveOption,
    };
};
