import { CommonLayout } from "@russpass/partner-front-ui";
import { navbarBookingNames } from "./constants";
import { RentHousesBookingStatus } from "../../../types/rentHouses";
import ApartmentsBookingList, { BookingListProps } from "./list";
import ApartmentsBookingDetails from "./details";
import { useObjectsFilter } from "../hooks/useObjectsFilter";
import { useCallback, useEffect, useState } from "react";
import { Calendar } from "./calendar";
import { sendEvent } from "../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../product_analytics/constants";
import { useBookingNavbar } from "./hooks/useBookingNavbar";

const ApartmentsBooking = () => {
    const [page, onPageChange] = useState(0);
    const {
        objectOptions,
        isLoading: isLoadingObjects,
        activeObject,
        setActiveObject: setActiveObjectOption,
    } = useObjectsFilter();

    const setActiveObject = useCallback(
        (object: string) => {
            onPageChange(0);
            setActiveObjectOption(object);
        },
        [setActiveObjectOption]
    );

    const [isCalendar, setIsCalendar] = useState(false);

    const baseListProps: Omit<BookingListProps, "status"> = {
        page,
        onPageChange,
        objectOptions,
        isLoadingObjects,
        activeObject,
        setActiveObject,
        emptySettings: { title: "", description: "" },
        openCalendar: () => setIsCalendar(true),
    };

    const navbarBooking = useBookingNavbar();

    const [activeTab, setActiveTab] = useState(navbarBooking[0].name);

    useEffect(() => {
        if (activeTab === navbarBooking[0].name) {
            sendEvent(AnalyticEvent.confirmation_bookings_page);
        } else if (activeTab === navbarBooking[1].name) {
            sendEvent(AnalyticEvent.active_bookings_page);
        } else if (activeTab === navbarBooking[2].name) {
            sendEvent(AnalyticEvent.complited_bookings_page);
        } else if (activeTab === navbarBooking[3].name) {
            sendEvent(AnalyticEvent.rejected_bookings_page);
        }
    }, [activeTab, navbarBooking]);

    return (
        <>
            {isCalendar ? (
                <Calendar
                    close={() => setIsCalendar(false)}
                    activeObject={activeObject}
                    objectOptions={objectOptions}
                    setActiveObject={setActiveObject}
                />
            ) : (
                <CommonLayout
                    pageTitle="Бронирования"
                    navbar={navbarBooking}
                    activeTabName={activeTab}
                    handlerChangeActiveTab={(name) => setActiveTab(name as string)}
                >
                    <div data-name={navbarBookingNames.pending}>
                        <ApartmentsBookingList
                            {...baseListProps}
                            status={[RentHousesBookingStatus.PENDING]}
                            emptySettings={{
                                title: "Пока нет заявок на бронирование",
                                description: "На подтверждение заявок будет 24 часа",
                            }}
                        />
                    </div>
                    <div data-name={navbarBookingNames.active}>
                        <ApartmentsBookingList
                            {...baseListProps}
                            status={[RentHousesBookingStatus.ACTIVE, RentHousesBookingStatus.STARTED]}
                            emptySettings={{ title: "Пока нет активных бронирований" }}
                        />
                    </div>
                    <div data-name={navbarBookingNames.completed}>
                        <ApartmentsBookingList
                            {...baseListProps}
                            status={[RentHousesBookingStatus.COMPLETED]}
                            emptySettings={{ title: "Пока нет завершенных бронирований" }}
                        />
                    </div>
                    <div data-name={navbarBookingNames.rejected}>
                        <ApartmentsBookingList
                            {...baseListProps}
                            status={[RentHousesBookingStatus.REJECTED, RentHousesBookingStatus.WAITING_TO_REJECT]}
                            emptySettings={{
                                title: "Пока нет отклоненных бронирований",
                                description: "Здесь будут неподтвержденные заявки и отмененные бронирования",
                            }}
                        />
                    </div>
                </CommonLayout>
            )}

            <ApartmentsBookingDetails />
        </>
    );
};

export default ApartmentsBooking;
