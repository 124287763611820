import { yup } from "../../../../yup";
import {
    FILE_TOO_LARGE,
    FORM_FIELD_REQUIRED_ERROR,
    FORM_MAX_ERROR,
    FORM_MIN_ERROR,
} from "../../../../constants/errors";

const MAX_PHOTO_SIZE = 15;

export const validationSchema = yup.object().shape({
    name: yup
        .string()
        .required()
        .min(2, FORM_MIN_ERROR({ min: 2 }))
        .max(50, FORM_MAX_ERROR({ max: 50 })),
    description: yup
        .string()
        .required()
        .min(50, FORM_MIN_ERROR({ min: 50 }))
        .max(2000, FORM_MAX_ERROR({ max: 2000 })),
    image: yup
        .object()
        .shape({
            file: yup
                .mixed()
                .test(
                    "is-file-too-large",
                    FILE_TOO_LARGE(MAX_PHOTO_SIZE),
                    (value: any): boolean => !value || value.size < MAX_PHOTO_SIZE * 1024 * 1024
                ),
        })
        .test("required", FORM_FIELD_REQUIRED_ERROR, (value: any) => value?.id || value?.file),
});
