import { FeedbackStatus } from "./FeedbackStatus";

export const FeedbackNavbar = [
    {
        label: "Новые",
        name: FeedbackStatus.New,
    },
    {
        label: "Отвеченные",
        name: FeedbackStatus.Resolved,
    },
];
