import { useFormikContext } from "formik";
import { RadioButton } from "@russpass/partner-front-ui";
import { useFieldError } from "../../../../../../utils/form";
import React, { useMemo } from "react";
import styles from "./SupportTypesField.module.sass";
import { useRentHousesSupportCauses } from "../../../../../../api/hooks/cmsApi";
import { RentHousesBookingStatus } from "../../../../../../types/rentHouses";
import { ReservationStatus } from "../../../../../../types/cmsApi";

type Props = { name: string; bookingStatus: RentHousesBookingStatus };

export const SupportTypesField = ({ name, bookingStatus }: Props) => {
    const { setFieldValue, getFieldProps } = useFormikContext();

    const value = getFieldProps(name).value;
    const error = useFieldError(name);

    const { data } = useRentHousesSupportCauses({
        reservationStatus: [RentHousesBookingStatus.STARTED, RentHousesBookingStatus.ACTIVE].includes(bookingStatus)
            ? ReservationStatus.ACTIVE
            : ReservationStatus.CLOSED,
    });

    const options = useMemo(() => data?.items.map((el) => ({ label: el.title, value: el.title })) || [], [data?.items]);

    return (
        <div>
            {options.map((el, indx) => (
                <div key={indx}>
                    <RadioButton
                        isActive={value === el.value}
                        value={el.value}
                        label={el.label}
                        handleClick={(val) => setFieldValue(name, val)}
                    />
                </div>
            ))}
            {error && <div className={styles.Error}>{error}</div>}
        </div>
    );
};
