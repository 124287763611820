import { useMemo } from "react";
import { Tooltip } from "@russpass/partner-front-ui";
import { ExtendedTariffData, TagType } from "../../../../types/hotel-tariff";
import { ReactComponent as DeleteIcon } from "../../../../../../../../assets/images/icons/ic_close_circle.svg";
import "./styles.sass";

type TariffCardItemPtops = {
    data: ExtendedTariffData;
    onClick?: (tariff: ExtendedTariffData) => void;
    handleDeleteTariff: (ev: React.MouseEvent, tariffId: string) => void;
};
const TariffCardItem = ({ data, onClick, handleDeleteTariff }: TariffCardItemPtops) => {
    const handleCardClick = useMemo(
        () =>
            onClick
                ? () => {
                      onClick(data);
                  }
                : undefined,
        [data, onClick]
    );

    return (
        <div className="card" onClick={handleCardClick}>
            <Tooltip overlay="Удалить" tooltipWidth="max-content" offset={{ X: 440, Y: -60 }}>
                <div className="card__delete_button" onClick={(ev) => handleDeleteTariff(ev, data.id)}>
                    <DeleteIcon />
                </div>
            </Tooltip>
            <div className="card__content">
                <div className="card__title">{data.title}</div>
                <div className="card__desc">
                    {data.tags.map((tag: TagType, idx: number) => (
                        <div key={`${tag.description}-${idx}`} className={`tag mb-8 ${tag.classStyle}`}>
                            {tag.description}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TariffCardItem;
