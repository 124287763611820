import React, { useEffect, useMemo } from "react";
import { ChannelManagerCodeEnum } from "../../../../enums/channel-manager-code";
import AddHotelPage from "../new-form";
import HotelCmsTravelLine from "../old-form/hotel-cms";
import { useLocation } from "react-router-dom";
import useHotel from "./useHotel";
import { HotelDataContext } from "./hotel-data-context";
import { isEnableMovingOldTL } from "../../../../constants/feature-toggles";

const HotelView = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location?.search);
    const isMoveOldTL = Boolean(searchParams.get("isMoveOldTL"));

    const { isLoading, checkExistTL, hotelInfoData, loadHotelInfoData, isTravelLineHotel, isNewTravelLineHotel } =
        useHotel({
            isMoveOldTL,
        });

    useEffect(() => {
        loadHotelInfoData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hotelDataProviderValue = useMemo(
        () => ({
            hotelInfoData,
            isNewTravelLineHotel,
            isTravelLineHotel,
            checkExistTravelLineHotel: checkExistTL,
            loadHotelInfoData,
            isLoadingHotelInfo: isLoading,
        }),
        [hotelInfoData, isNewTravelLineHotel, isTravelLineHotel, checkExistTL, loadHotelInfoData, isLoading]
    );

    return (
        <HotelDataContext.Provider value={hotelDataProviderValue}>
            {(hotelInfoData.partnerSystemType === ChannelManagerCodeEnum.Bnovo ||
                isNewTravelLineHotel ||
                (isTravelLineHotel && isMoveOldTL && isEnableMovingOldTL)) && <AddHotelPage />}
            {isTravelLineHotel && !isMoveOldTL && <HotelCmsTravelLine />}
        </HotelDataContext.Provider>
    );
};

export default HotelView;
