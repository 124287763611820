import classNames from "classnames";
import { noop } from "lodash";
import React, { ReactNode, useCallback, useRef, useState } from "react";
import {
    useViewportContext,
    MultiSelectField,
    MultiSelectHidden,
    MultiMenuField,
    ActionButtonWrapper,
    CheckboxCell,
} from "@russpass/partner-front-ui";
import { RestaurantOptionType } from "../../types";
import "./styles.sass";

type ActionsComponentProps = {
    save: () => void;
    reset: () => void;
};

const ActionsComponent: React.FC<ActionsComponentProps> = ({ save, reset }) => {
    const { isMobile } = useViewportContext();
    return (
        <ActionButtonWrapper>
            <button
                onClick={save}
                className={classNames("button button--full-width", "button--primary")}
                data-qa-name="submit-button"
            >
                {"Сохранить"}
            </button>

            {!isMobile && (
                <button onClick={reset} className="button" data-qa-name="submit-button">
                    {"Сбросить"}
                </button>
            )}
        </ActionButtonWrapper>
    );
};

type ChooserProps = {
    name: string;
    selectFieldName: string;
    label: string | ReactNode;
    modalLabel: string;
    data: RestaurantOptionType[];
    options: RestaurantOptionType[];
    handleChange: (field: string, values: RestaurantOptionType[]) => void;
    disabled: boolean;
    errorSettings: {
        showOnChange: boolean;
    };
};

const Chooser: React.FC<ChooserProps> = ({
    name,
    selectFieldName,
    label,
    modalLabel,
    data,
    options,
    handleChange,
    disabled,
    errorSettings,
}) => {
    const [localValue, setLocalValue] = useState<RestaurantOptionType[]>([]);

    const MenuRef = useRef(null);

    const handleClickCell = useCallback(
        (e: React.MouseEvent, option: RestaurantOptionType) => {
            e.stopPropagation();
            const isAdd = !!localValue.find((el) => el.id === option.id);
            if (isAdd) {
                setLocalValue(localValue.filter((el) => el.id !== option.id));
            } else {
                setLocalValue([...localValue, option]);
            }
        },
        [localValue]
    );

    const handleSelectChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectOptions = Array.from(e.target.options).map((option) => ({
            selected: option.selected,
            value: JSON.parse(option.value),
        }));
        const newValues = selectOptions.filter((option) => option.selected).map((option: any) => option.value);

        setLocalValue(newValues);
    }, []);

    const handleSave = useCallback(() => {
        handleChange(name, localValue);
        closeMenu();
    }, [handleChange, localValue, name]);

    const handleDelete = useCallback(
        (option: RestaurantOptionType) => {
            setLocalValue(localValue.filter((el) => el.id !== option.id));
            handleChange(
                name,
                data.filter((el) => el.id !== option.id)
            );
        },
        [data, handleChange, localValue, name]
    );

    const handlerClose = () => setLocalValue(data);
    //@ts-ignore
    const closeMenu = () => MenuRef?.current?.closeMenu();
    return (
        <MultiMenuField
            name={name}
            ref={MenuRef}
            label={label}
            noHover
            modalTitle={modalLabel}
            handlerClose={handlerClose}
            disabled={disabled}
            hideArrow={disabled}
            errorSettings={errorSettings}
            displayingValue={data?.[0]?.id}
            actionsComponent={!!localValue.length && <ActionsComponent save={handleSave} reset={closeMenu} />}
            renderComponent={
                <MultiSelectField
                    value={data}
                    handleClick={noop}
                    name={selectFieldName}
                    noHover
                    handleDelete={handleDelete}
                    placeholder="Выбрать"
                    hideArrow
                    className={"multi-select"}
                />
            }
        >
            {!options.length && <div className="ml-16 body">{"Нет опций"}</div>}
            {options.map((option) => {
                const isSelected = !!localValue.find((el) => option.id === el.id);
                return (
                    <CheckboxCell
                        key={`option-${option.id}`}
                        isSelected={isSelected}
                        label={option.title}
                        onClick={(e) => handleClickCell(e, option)}
                    />
                );
            })}

            <MultiSelectHidden
                options={options}
                onChange={handleSelectChange}
                dataQaName="characteristic-select"
                optionKey={(option) => option.id}
                optionSelected={(option) => !!localValue.find((el) => option.id === el.id)}
                optionValue={(option) => JSON.stringify(option)}
                optionLabel={(option) => option.title}
                dataQaNameOption={(option) => `characteristic-option-${option.id}`}
            />
        </MultiMenuField>
    );
};

export default Chooser;
