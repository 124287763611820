import { RentHousesBookingItem, RentHousesRate } from "../../../../../types/rentHouses";
import classNames from "classnames";
import styles from "../styles.module.sass";
import { Tooltip } from "./Tooltip";
import { BookingsList } from "./BookingsList";
import { BookingBlock } from "./BookingBlock";
import { BookingQuestionTooltip } from "./BookingQuestionTooltip";
import React from "react";
import { useBookingCalendarDay } from "../hooks/useBookingCalendarDay";
import { useViewportContext, Checkbox } from "@russpass/partner-front-ui";

type BookingCalendarDayProps = {
    date: Date;
    rates: RentHousesRate[];
    bookings: RentHousesBookingItem[];
    blockedDays: number[];
    isBlocking: boolean;
    toggleBlock: (day: number) => void;
    enableBlocking: () => void;
    isArchiveObject?: boolean;
};

export const BookingCalendarDay = ({
    date,
    rates,
    bookings,
    blockedDays,
    toggleBlock,
    isBlocking,
    enableBlocking,
    isArchiveObject,
}: BookingCalendarDayProps) => {
    const { isMobile } = useViewportContext();

    const { view, info, onClick, tooltip, dateBookings } = useBookingCalendarDay({
        date,
        rates,
        allBookings: bookings,
        blockedDays,
        isBlocking,
        toggleBlock,
        isArchiveObject,
    });

    const { monthDayNumber, price, canBlock, isBlocked, multipleBookings, multiplePendingBookings, hasBookings } = info;

    return (
        <div
            className={classNames(styles.Day, {
                [styles.interactive]: !!onClick,
                [styles.priority]: tooltip.isOpen,
                [styles[`background_${view.background}`]]: view.background,
                [styles[`border_${view.border}`]]: view.border,
                [styles["connector"]]: view.connector?.background || view.connector?.border,
                [styles["connector_background"]]: view.connector?.background,
                [styles["connector_border"]]: view.connector?.border,
                [styles.disabled]: info.isPast || (isArchiveObject && !hasBookings),
            })}
            onClick={onClick}
        >
            <div className={styles.DayNumber}>{monthDayNumber}</div>
            {!isMobile && <div className={styles.DayPrice}>{price}</div>}
            {(!isArchiveObject || hasBookings) && (
                <>
                    <Tooltip isOpen={tooltip.isOpen} close={tooltip.close}>
                        {multipleBookings && (
                            <BookingsList
                                bookings={dateBookings}
                                description={
                                    multiplePendingBookings
                                        ? "Убедитесь, что между выездом и заселением достаточно времени для уборки жилья"
                                        : undefined
                                }
                            />
                        )}
                        {!isBlocking && canBlock && (
                            <BookingBlock
                                onClick={() => {
                                    tooltip.close();
                                    enableBlocking();
                                }}
                            />
                        )}
                    </Tooltip>

                    <div className={styles.QuestionTooltip}>
                        <BookingQuestionTooltip info={info} isBlocking={isBlocking} />
                    </div>

                    {isBlocking && canBlock && (
                        <div
                            className={classNames({
                                [styles.Blocking__desktop]: !isMobile,
                                [styles.Blocking__mobile]: isMobile,
                                [styles.Blocking__mobile__active]: isMobile && isBlocked,
                            })}
                        >
                            <Checkbox
                                checked={isBlocked}
                                id={`check-${monthDayNumber}`}
                                handleChange={() => undefined}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
