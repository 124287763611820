import queryString from "query-string";
import { fetchWithTokenRequest } from "./settings";

const FetchRequest = () => {
    const get = async (
        path: string,
        data?: any,
        options: any = {},
        seriailizeOptions: queryString.StringifyOptions = { arrayFormat: "bracket" }
    ) => {
        let serializedData = "";
        if (data) {
            serializedData += `?${queryString.stringify(data, seriailizeOptions)}`;
        }
        return await fetchWithTokenRequest(`${path}${serializedData}`, options);
    };

    const post = async (path: string, data?: any, options: any = {}) => {
        return await fetchWithTokenRequest(path, {
            method: "POST",
            body: JSON.stringify(data),
            ...options,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                ...options.headers,
            },
        });
    };

    const patch = async (path: string, data?: any, options: any = {}) => {
        return await fetchWithTokenRequest(path, {
            method: "PATCH",
            body: JSON.stringify(data),
            ...options,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                ...options.headers,
            },
        });
    };

    const put = async (path: string, data: any, options: any = {}) => {
        return await fetchWithTokenRequest(path, {
            method: "PUT",
            body: JSON.stringify(data),
            ...options,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                ...options.headers,
            },
        });
    };

    const deleteMethod = async (path: string, options: any = {}) => {
        return await fetchWithTokenRequest(path, {
            method: "DELETE",
            ...options,
        });
    };

    const postFormData = async (path: string, data?: any, options: any = {}) => {
        return await fetchWithTokenRequest(path, {
            method: "POST",
            body: data,
            ...options,
        });
    };

    const postFormUrlencoded = async (path: string, data?: any, options: any = {}) => {
        return await fetchWithTokenRequest(path, {
            method: "POST",
            body: new URLSearchParams(data),
            ...options,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                ...options.headers,
            },
        });
    };

    const downloadFile = async (path: string, data?: any, options: any = {}, defaultFileName = "file") => {
        const response = await fetchWithTokenRequest(path, {
            method: "POST",
            body: JSON.stringify(data),
            ...options,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                ...options.headers,
            },
        });

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers.get("content-disposition");
        let fileName = defaultFileName;

        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=("(.+)"|(.+))/);
            if (fileNameMatch?.[1]) {
                fileName = fileNameMatch[1];
            }
        }

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
    };

    return {
        get,
        post,
        patch,
        put,
        delete: deleteMethod,
        file: postFormData,
        postFormData,
        postFormUrlencoded,
        downloadFile,
    };
};

const fetchRequest = FetchRequest();

export default fetchRequest;
