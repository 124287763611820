import styles from "../occupancies.module.sass";
import { InputField, Counter, ErrorField, MultiMenuField } from "@russpass/partner-front-ui";
import { maskedInputChildAge } from "../../../../../../../utils/validation";
import classNames from "classnames";
import { useCallback, useMemo, useRef, useState } from "react";
import { declensionOfNum } from "../../../../../../../utils/declension-number";
import { Occupancy } from "../../../types/hotel-room";
import { isEqualObjects } from "../../../../../../../utils/common";
import { ReactComponent as Trash } from "../../../../../../../assets/images/icons/ic_trash.svg";
import { HotelDataContext } from "../../../../hotel-view/hotel-data-context";

type OccupancyItemBNOVOProps = {
    value: Occupancy;
    disabled?: boolean;
    onChange: (value: Occupancy) => void;
    onDelete: () => void;
    isDeleted?: boolean;
    errorSettings?: {
        showOnChange: boolean;
    };
    index: number;
};

type ActionsComponentProps = {
    isChangedValue: boolean;
    handleSave: () => void;
    handleReset: () => void;
};

const ActionsComponent: React.FC<ActionsComponentProps> = ({ isChangedValue, handleSave, handleReset }) => {
    return (
        <>
            {isChangedValue && (
                <>
                    <div className={classNames("button button--primary")} onClick={handleSave}>
                        Сохранить
                    </div>
                    <div className="button" onClick={handleReset}>
                        Сбросить
                    </div>
                </>
            )}
        </>
    );
};

const OccupancyItemBNOVO = ({
    value,
    index,
    onChange,
    disabled,
    errorSettings,
    isDeleted,
    onDelete,
}: OccupancyItemBNOVOProps) => {
    const MenuRef = useRef(null);

    const [selectedValue, setSelectedValue] = useState<Occupancy>(value);

    const isChangedValue = useMemo(() => !isEqualObjects(value, selectedValue), [value, selectedValue]);

    const handleReset = useCallback(() => {
        setSelectedValue(value);
        //@ts-ignore
        MenuRef?.current?.closeMenu();
    }, [value]);

    const handleSave = useCallback(() => {
        onChange(selectedValue);
        //@ts-ignore
        MenuRef?.current?.closeMenu();
    }, [onChange, selectedValue]);

    const displayingValue = useMemo(() => {
        let _displayingValue = "";
        if (value.adults > 0) {
            _displayingValue +=
                value.adults + " " + declensionOfNum(value.adults, ["взрослый", "взрослых", "взрослых"]);
        }
        if (value.child > 0) {
            if (_displayingValue.length > 0) {
                _displayingValue += " + ";
            }
            _displayingValue += value.child + " " + declensionOfNum(value.child, ["ребенок", "ребенка", "детей"]);
        }
        return _displayingValue;
    }, [value]);

    const name = `occupancies[${index}]`;

    const handleAdultsCountChange = useCallback(
        (value) => {
            setSelectedValue((prev) => ({
                ...prev,
                adults: value,
            }));
        },
        [setSelectedValue]
    );

    const handleChildrenCountChange = useCallback(
        (value) => {
            setSelectedValue((prev) => ({
                ...prev,
                child: value,
                isChild: value > 0,
            }));
        },
        [setSelectedValue]
    );

    return (
        <HotelDataContext.Consumer>
            {({ isNewTravelLineHotel }) => (
                <>
                    <div className={styles.occupancy}>
                        <MultiMenuField
                            ref={MenuRef}
                            name={`${name}.adults`}
                            label={`${index + 1} вариант`}
                            placeholder="Выберите"
                            displayingValue={displayingValue}
                            noHover
                            disabled={disabled}
                            actionsComponent={
                                <ActionsComponent
                                    isChangedValue={isChangedValue}
                                    handleSave={handleSave}
                                    handleReset={handleReset}
                                />
                            }
                            errorSettings={errorSettings}
                        >
                            <div className={styles.occupancy_modal__body}>
                                <div className="mb-32">
                                    <Counter
                                        name="Взрослые"
                                        label="Взрослые"
                                        value={selectedValue.adults}
                                        onChange={handleAdultsCountChange}
                                    />
                                </div>
                                <Counter
                                    name="Дети"
                                    label="Дети"
                                    value={selectedValue.child}
                                    onChange={handleChildrenCountChange}
                                />
                            </div>
                        </MultiMenuField>
                        {isDeleted && (
                            <div className={styles.occupancy__trash}>
                                <button type="button" onClick={onDelete}>
                                    <Trash />
                                </button>
                            </div>
                        )}
                    </div>

                    {value.child > 0 && (
                        <>
                            <div className={styles.childAge}>
                                <InputField
                                    label="Мин. возраст ребенка"
                                    name={`${name}.minAge`}
                                    noHover
                                    disabled={disabled}
                                    placeholder="2"
                                    renderComponent={maskedInputChildAge}
                                    maxLength={2}
                                />
                                <InputField
                                    label="Макс. возраст ребенка"
                                    name={`${name}.maxAge`}
                                    noHover
                                    disabled={disabled}
                                    placeholder="17"
                                    renderComponent={maskedInputChildAge}
                                    maxLength={2}
                                />
                            </div>
                            <div className={"mb-8 ml-24"}>
                                <ErrorField
                                    name={`${name}.isChild`}
                                    errorSettings={{
                                        showOnChange: true,
                                    }}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </HotelDataContext.Consumer>
    );
};

export default OccupancyItemBNOVO;
