import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/app.sass";
import App from "./app";
import { startSendAnalytics } from "../src/product_analytics/config";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_DSN, appVersion, appTimestamp } from "./constants";
import store from "./store";
import { Provider as ReduxProvider } from "react-redux";

startSendAnalytics();

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: appVersion + " " + appTimestamp,
    environment: process.env.REACT_APP_SENTRY_ENV,
});

ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <App />
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
