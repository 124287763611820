import { useCallback } from "react";
import { RentHousesBookingStatus, RentHousesObjectStatus } from "../types/rentHouses";

export const useExternalLink = (url: string, status: RentHousesObjectStatus | string, isBlocked?: boolean) => {
    const origin = process.env.REACT_APP_RUSSPASS_URL || null;
    let newUrl: URL | string | null = null;

    if (url.startsWith("http")) newUrl = new URL(url);

    if (url.startsWith(`/`) && url.length > 2) newUrl = url;

    return {
        enabled:
            !isBlocked &&
            status &&
            (status === RentHousesObjectStatus.PUBLISHED || status.toUpperCase() === RentHousesBookingStatus.ACTIVE),
        open: useCallback(() => {
            if (!origin || !newUrl) return;
            newUrl instanceof URL
                ? window.open(origin + newUrl.pathname + newUrl.search)
                : window.open(origin + newUrl);
        }, [newUrl, origin]),
    };
};
