import { CommonLayout } from "@russpass/partner-front-ui";
import history from "../../../history";
import routes from "../../../constants/routes";
import { NotificationsSettingsProvider } from "./context/NotificationsSettingsProvider";
import SettingsList from "./components/list";
import { EditNotificationsProvider } from "../edit/context/EditNotificationsProvider";
import styles from "./styles.module.sass";
import { useEditNotificationsContext } from "../edit/context/useEditNotificationsContext";
import { useNotificationsSettingsContext } from "./context/useNotificationsSettingsContext";
import { useMemo } from "react";

const NotificationsSettingsContent = () => {
    const { items, isLoading } = useNotificationsSettingsContext();

    const addNotification = useEditNotificationsContext().open;

    const hasNotifications = !!items.length;

    const topRightButton = useMemo(
        () =>
            hasNotifications
                ? {
                      title: "Добавить уведомление",
                      onClick: addNotification,
                      isAddButton: true,
                  }
                : undefined,
        [addNotification, hasNotifications]
    );

    const backButton = useMemo(
        () => ({
            title: "Назад",
            onClick: () => history.push(routes.notifications),
        }),
        []
    );

    return (
        <CommonLayout
            pageTitle="Настройки уведомлений"
            backButton={backButton}
            topRightButton={topRightButton}
            description={
                <span className={styles.subtitle} data-qa-name="header-subtitle">
                    Подключенные уведомления об отсутствии интереса к объектам
                </span>
            }
            isLoadingWithoutContent={isLoading}
            isHideControlPanel
        >
            <SettingsList />
        </CommonLayout>
    );
};

const NotificationsSettings = () => (
    <NotificationsSettingsProvider>
        <EditNotificationsProvider>
            <NotificationsSettingsContent />
        </EditNotificationsProvider>
    </NotificationsSettingsProvider>
);

export default NotificationsSettings;
