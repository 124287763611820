import { useCallback, useMemo, useState } from "react";
import history from "../../../history";
import routes from "../../../constants/routes";
import { NavbarApartmentsName, STATUS_LABEL_APARTMENTS } from "./constants";
import { useRentHousesDictionary, useRentHousesObjects } from "../../../api/hooks/rentHouses";
import { RentHousesObjectPatchStatus, RentHousesObjectStatus } from "../../../types/rentHouses";
import { getImageById } from "../../../api/files";
import { useLocation } from "react-router-dom";
import { ObjectItemClassNameStatus, ObjectItemType } from "@russpass/partner-front-ui";
import moment from "moment";
import { DOTTED_DATE_FORMAT } from "../../../constants/dates-format";

const cardBorderColor = "#e76d00";

const useApartments = () => {
    const location = useLocation<{ background: Location; status?: number } | null>();

    const [activeTabKey, setActiveTabKey] = useState<string | number | undefined>(
        location.state?.status || NavbarApartmentsName.ALL
    );

    const addObject = useCallback(() => {
        history.push({
            pathname: routes.apartmentsNew,
            state: { status: activeTabKey },
        });
    }, [activeTabKey]);

    const {
        data: objectsData,
        isLoading,
        mutate: updateList,
    } = useRentHousesObjects({
        pageSize: 100,
        status: activeTabKey !== NavbarApartmentsName.ALL ? (activeTabKey as RentHousesObjectStatus) : undefined,
        blocked: activeTabKey !== NavbarApartmentsName.ALL ? false : undefined,
    });

    const { data: objectsDataBlocked, isLoading: isLoadingBlocked } = useRentHousesObjects(
        {
            pageSize: 100,
            blocked: true,
        },
        {},
        activeTabKey !== NavbarApartmentsName.DECLINED
    );

    const { data: dictionaryData } = useRentHousesDictionary(
        { dictionary: "housing_types", sort: "dictionary_data.title" },
        { revalidateOnFocus: false }
    );

    const list: ObjectItemType[] = useMemo(() => {
        const items = objectsData?.data.items || [];
        const itemsBlocked = objectsDataBlocked?.data.items || [];
        return (
            [...items, ...itemsBlocked].map((el): ObjectItemType => {
                const hasComments =
                    el.status === RentHousesObjectStatus.DECLINED ||
                    (el.status === RentHousesObjectStatus.PUBLISHED &&
                        el.patches?.[0]?.status === RentHousesObjectPatchStatus.DECLINED);
                const isBlocked = el.blocked;

                const statusLabel = !isBlocked
                    ? STATUS_LABEL_APARTMENTS[el.status] ?? ""
                    : `Снято с публикации до ${moment(el.blockedUntil).format(DOTTED_DATE_FORMAT)}`;

                const classNameStatusLabel =
                    ObjectItemClassNameStatus[
                        !isBlocked
                            ? (el.status.toLowerCase() as ObjectItemClassNameStatus)
                            : ObjectItemClassNameStatus.declined
                    ];
                return {
                    id: el.id,
                    link: routes.apartmentsView.replace(":apartmentId", el.id),
                    imgUrl: el.mainImage ? getImageById(el.mainImage) : undefined,
                    category: dictionaryData?.data.items.find((item) => item.id === el.housingType)?.title ?? "",
                    name: el.title || "",
                    statusLabel,
                    classNameStatusLabel,
                    hasComments,
                    isDeleting: el.status !== RentHousesObjectStatus.ARCHIVED,
                    extraStyles: hasComments
                        ? {
                              borderColor: cardBorderColor,
                          }
                        : undefined,
                };
            }) || []
        );
    }, [objectsData, objectsDataBlocked, dictionaryData]);

    const onSelect = useCallback(
        (data: ObjectItemType) => {
            history.push({ pathname: data.link as string, state: { status: activeTabKey } });
        },
        [activeTabKey]
    );

    return {
        activeTabKey,
        setActiveTabKey,
        addObject,
        list,
        updateList,
        isLoading: isLoading || isLoadingBlocked,
        onSelect,
    };
};

export default useApartments;
