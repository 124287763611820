import { NOT_FULLED_SECTION_ERROR, NOT_FULLED_SECTIONS_ERROR } from "../../../../constants/errors";
import { navbarServiceLabels } from "./constants";

export const getErrorForm = (state: {
    isValidInfo: boolean;
    isValidPlace: boolean;
    isValidPhoto: boolean;
    isValidTariffs: boolean;
}) => {
    let error = undefined;

    const countErrorForm = [state.isValidInfo, state.isValidPlace, state.isValidPhoto, state.isValidTariffs].reduce(
        (sum, current) => {
            if (!current) sum++;
            return sum;
        },
        0
    );

    if (countErrorForm > 1) {
        error = NOT_FULLED_SECTIONS_ERROR;
    }
    if (countErrorForm === 1) {
        error = NOT_FULLED_SECTION_ERROR;
        if (!state.isValidInfo) {
            error += ` ${navbarServiceLabels.service}`;
        }
        if (!state.isValidPlace) {
            error += ` ${navbarServiceLabels.place}`;
        }
        if (!state.isValidPhoto) {
            error += ` ${navbarServiceLabels.photo}`;
        }
        if (!state.isValidTariffs) {
            error += ` ${navbarServiceLabels.tariff}`;
        }
    }

    return error;
};
