import { HTMLProps } from "react";
import classNames from "classnames";
import styles from "./Card.module.sass";

export const Card = ({ className, ...divProps }: HTMLProps<HTMLDivElement>) => (
    <div className={classNames(styles.Card, className)} {...divProps} />
);

export const CardTitle = ({ className, ...divProps }: HTMLProps<HTMLDivElement>) => (
    <div className={classNames(styles.Title, className)} {...divProps} />
);
