import { getNotificatorTemplates, getNotificatorTemplate } from "../../../../api/notficator";

const loadTemplates = async () => {
    const notificatorTemplates = await getNotificatorTemplates();
    const templatesItems = notificatorTemplates?.items;
    const promises = templatesItems.map(async (item) => (await getNotificatorTemplate(item.templateId)).data);

    return await Promise.all(promises);
};

export default loadTemplates;
