import React, { useEffect, useMemo } from "react";
import { BaseFieldProps } from "../../../../../../types/fields";
import { useFormikContext } from "formik";
import { useRentHousesDictionary } from "../../../../../../api/hooks/rentHouses";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { UNEXPECTED_ERROR } from "../../../../../../constants/errors";
import {
    ModalAlertDetailsProps,
    StatusRequestEnum,
    MultiSelectWithModal,
    OptionsGroupType,
} from "@russpass/partner-front-ui";

type SelectProps = BaseFieldProps & {
    searchPlaceholder?: string;
    alertSettings?: ModalAlertDetailsProps;
};

const SelectAmenitiesField = ({
    name,
    label = "Удобства",
    placeholder = "Выбрать",
    searchPlaceholder = "Название удобства",
    hint,
    disabled,
    alertSettings,
}: SelectProps) => {
    const { data: categories, error: categoriesError } = useRentHousesDictionary({
        dictionary: "room_amenities_categories",
        pageSize: 999,
        sort: "dictionary_data.title",
    });

    const { data: amenities, error: amenitiesError } = useRentHousesDictionary({
        dictionary: "room_amenities",
        pageSize: 999,
        useForRenthouses: true,
        sort: "dictionary_data.title",
    });

    useEffect(() => {
        if (!categoriesError && !amenitiesError) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [categoriesError, amenitiesError]);

    const options = useMemo(
        () =>
            amenities?.data.items.map((el) => ({
                id: el.id,
                title: el.dictionary_data?.title || "",
                category: el.dictionary_data?.category || "",
            })) || [],
        [amenities]
    );

    const groups: OptionsGroupType[] = useMemo(
        () =>
            categories?.data?.items?.map((category) => ({
                groupId: category.id,
                groupTitle: category.dictionary_data?.title || "",
                options: options.filter((option) => option.category === category.id),
            })) || [],
        [categories, options]
    );

    const { getFieldProps, setFieldValue } = useFormikContext();

    const value = useMemo(() => getFieldProps(name).value || [], [getFieldProps, name]);
    const selectedOptions = useMemo(() => options.filter((option) => value.includes(option.id)), [options, value]);

    return (
        <MultiSelectWithModal
            data={selectedOptions}
            isRequiredSave
            alertSettings={alertSettings}
            options={groups}
            multiMenuSettings={{
                name,
                label,
                placeholder,
                hint,
                disabled,
            }}
            onChange={(values) =>
                setFieldValue(
                    name,
                    values.map((el: any) => el.id)
                )
            }
            searchSettings={{
                placeholder: searchPlaceholder,
            }}
            position={2}
            modalTitle={label}
        />
    );
};

export default SelectAmenitiesField;
