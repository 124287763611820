import { BaseFieldProps } from "../../../../../../../types/fields";
import styles from "./sleeping-places-field.module.sass";
import modalMenuStyles from "../../../../../../../components/form-fields/search-select-field/components/ModalMenu.module.sass";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getIn, useFormikContext } from "formik";
import { useViewportContext, Counter, MultiMenu } from "@russpass/partner-front-ui";
import { SleepingPlacesResult } from "./components/sleeping-places-result";
import classNames from "classnames";

type SleepingPlacesFieldProps = {
    items: { label: string; value: string }[];
    noHover?: boolean;
    parentName: string;
    idx: number;
    modalTitle?: string;
} & BaseFieldProps;

type LocalValueType = {
    [key: string]: number;
};

export const SleepingPlacesField = ({
    name,
    parentName,
    idx,
    label,
    placeholder,
    items,
    noHover,
    modalTitle,
    disabled,
}: SleepingPlacesFieldProps) => {
    const MenuRef = useRef(null);
    const { isMobile } = useViewportContext();
    const [localValue, setLocalValue] = useState<LocalValueType>({});

    const { setFieldValue, values, errors, submitCount } = useFormikContext();

    const position = `${parentName}.${idx}.${name}`;

    const value = useMemo(() => getIn(values, position) || {}, [position, values]);
    const error = useMemo(() => (submitCount ? getIn(errors, position) : undefined), [errors, position, submitCount]);

    //@ts-ignore
    const closeMenu = () => MenuRef?.current?.closeMenu();

    const [isChanged, setIsChanged] = useState(false);

    const onSave = useCallback(() => {
        setFieldValue(position, localValue);
        closeMenu();
        setIsChanged(false);
    }, [localValue, position, setFieldValue]);

    const onReset = useCallback(() => {
        setFieldValue(position, undefined);
        setLocalValue({});
    }, [position, setFieldValue]);

    const onUpdate = useCallback(() => {
        const cloneValue = { ...(value as LocalValueType) };
        setLocalValue(cloneValue);
    }, [value]);

    const hasValue = useMemo(() => {
        for (const key in value) {
            if (value[key] > 0) {
                return true;
            }
        }
        return false;
    }, [value]);

    useEffect(() => {
        onUpdate();
    }, [onUpdate, value]);

    return (
        <MultiMenu
            classes={{ content: classNames({ [styles.hasValue]: hasValue }) }}
            ref={MenuRef}
            label={label}
            placeholder={placeholder}
            noHover={noHover}
            handlerClose={onUpdate}
            error={error}
            displayingValue={hasValue ? " " : undefined}
            renderComponent={
                hasValue ? <SleepingPlacesResult items={items} value={value} position={position} /> : undefined
            }
            actionsComponent={
                isChanged ? (
                    <>
                        {isMobile ? (
                            <div className={modalMenuStyles.Footer}>
                                <div className="button button--primary full-width" onClick={onSave}>
                                    Сохранить
                                </div>
                            </div>
                        ) : (
                            <div className="button button--primary" onClick={onSave}>
                                Сохранить
                            </div>
                        )}
                        {!isMobile && (
                            <div className="button" onClick={onReset}>
                                Сбросить
                            </div>
                        )}
                    </>
                ) : undefined
            }
            modalTitle={modalTitle}
            disabled={disabled}
        >
            <div className={styles.wrapper}>
                {items.map(({ label, value: itemValue }) => (
                    <div className={styles.item} key={itemValue}>
                        <div className={styles.item__title}>{label}</div>
                        <div className={styles.item__counter}>
                            <Counter
                                min={0}
                                max={9999}
                                name={`${name}.${itemValue}`}
                                value={localValue[itemValue]}
                                onChange={(value) => {
                                    setIsChanged(true);
                                    return setLocalValue({ ...localValue, [itemValue]: value });
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </MultiMenu>
    );
};
