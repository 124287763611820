import { HTMLProps } from "react";
import classNames from "classnames";
import styles from "./RatingBadge.module.sass";

type Props = Omit<HTMLProps<HTMLDivElement>, "children"> & { value: number };

const RatingBadge = ({ className, value, ...divProps }: Props) => {
    const rating = value.toFixed(1);
    return (
        <div
            className={classNames(styles.Rating, className, {
                [styles.Low]: +rating < 5,
                [styles.High]: +rating >= 7,
            })}
            {...divProps}
        >
            {+rating >= 10 ? 10 : rating}
        </div>
    );
};

export default RatingBadge;
