import { yup } from "../../../../../yup";

export const OBJECT_STEP_DESCRIPTION_MAX = 800;

export const objectStepSchema = yup.object().shape({
    name: yup.string().required().max(100),
    description: yup.string().required().max(OBJECT_STEP_DESCRIPTION_MAX),
    type: yup.string().required(),
    area: yup.number().required().min(1).max(9999),
    amenities: yup.array().required(),
    region: yup.string().required(),
    city: yup.string().required(),
    timeZone: yup.string().required(),
    address: yup.object().shape({
        unrestricted_value: yup.string().required(),
    }),
});

export const objectStepDraftSchema = yup.object().shape({
    name: yup.string().required().max(100),
});
