import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import {
    ModalWindowRight,
    Loader,
    TextareaField,
    CheckboxField,
    StatusRequestEnum,
    MessageField,
    FileUploadField,
} from "@russpass/partner-front-ui";
import { useRequest } from "../../../../hooks/useRequest";
import { getFeedback, getFeedbackOrder, updateFeedback } from "../../../../api/feedback";
import { FeedbackModalSubmitButton } from "./FeedbackModalSubmitButton";
import { FeedbackModalHeader } from "./FeedbackModalHeader";
import { FeedbackFormAction } from "../../constants/FeedbackFormAction";
import { FeedbackModel } from "../../types/FeedbackModel";
import eventDispatcher from "../../../../lib/event-dispatcher";
import getCustomErrorMessage from "../../../../utils/getCustomErrorMessage";
import { FeedbackFormSchema } from "./FeedbackFormSchema";
import { FeedbackStatus } from "../../constants";
import { COMMON_RELOAD_ERROR } from "../../../../constants/errors";
import { combineString } from "../../../../utils/text-util";
import { FeedbackOrder } from "./FeedbackOrder";
import { publicationAvailable } from "../../../../constants/services";
import classNames from "classnames";
import { FeedbackAttachments } from "./FeedbackAttachments";
import { useFeedbackAttachments } from "./useFeedbackAttachments";
import { saveFile } from "../../../../api/attach";
import { MAX_FILES_COUNT, MAX_FILES_SIZE_SUM } from "../../../../constants/validation";
import useUserInfo from "../../../../hooks/useUserInfo";
import { setFileUploadError } from "../../../../utils/react-files";

type FeedbackModalProps = {
    id?: number;
    onClose: (action?: FeedbackFormAction, publishAtPortal?: boolean) => void;
    refresh?: () => void;
};

export const FeedbackModal = ({ id, onClose, refresh }: FeedbackModalProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        result: { feedback, order } = {},
        loading,
        error,
    } = useRequest(async () => {
        if (!id) return undefined;

        const feedback = (await getFeedback(id)).data;

        if (!feedback?.order?.id) return { feedback };

        const order = (await getFeedbackOrder(id)).orders?.[0];

        return { feedback, order };
    }, [id]);

    const formAction = useMemo(() => {
        if (!feedback) return undefined;
        if (!feedback?.answer) return FeedbackFormAction.Submit;
        if (!feedback?.publishAtPortal) return FeedbackFormAction.Publish;

        return FeedbackFormAction.Unpublish;
    }, [feedback]);

    const messageFieldLabel = useMemo(() => {
        return (
            combineString([feedback?.author.firstName, feedback?.author.middleName, feedback?.author.lastName]) ||
            "Вопрос"
        );
    }, [feedback]);

    const handleSubmit = useCallback(
        async ({ answer, publishAtPortal, answerFiles: formAnswerFiles = [] }: FeedbackModel) => {
            if (!feedback) return;

            try {
                const answerFileUrls: string[] = [];

                for (const answerFile of formAnswerFiles) {
                    setIsSubmitting(true);
                    if (typeof answerFile === "string") {
                        answerFileUrls.push(answerFile);
                    } else {
                        const saveResult = await saveFile(answerFile);
                        if (!saveResult) return;

                        answerFileUrls.push(saveResult.uri);
                    }
                }

                await updateFeedback(
                    feedback.id,
                    feedback.status === FeedbackStatus.Resolved
                        ? { publishAtPortal, answerFiles: answerFileUrls }
                        : { answer, publishAtPortal, answerFiles: answerFileUrls }
                );

                onClose(formAction, publishAtPortal);
                refresh?.();
            } catch (e) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
                });
            } finally {
                setIsSubmitting(false);
            }
        },
        [feedback, formAction, onClose, refresh]
    );

    useEffect(() => {
        if (!error) return;

        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: getCustomErrorMessage(error, COMMON_RELOAD_ERROR),
        });

        onClose();
    }, [error, onClose]);

    const { isHotel } = useUserInfo();
    const isNew = useMemo(() => feedback?.status === "NEW", [feedback]);
    const isSubmitAction = useMemo(() => formAction === FeedbackFormAction.Submit, [formAction]);

    const { questionAttachments, answerAttachments } = useFeedbackAttachments(feedback);

    return (
        <Formik<FeedbackModel>
            initialValues={feedback!}
            validationSchema={FeedbackFormSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={handleSubmit}
        >
            {({ submitForm, values, isValid, setFieldTouched, setFieldValue }) => {
                const canSubmit = (() => {
                    switch (formAction) {
                        case FeedbackFormAction.Submit:
                            return isValid;
                        case FeedbackFormAction.Publish:
                            return isValid && !!values?.publishAtPortal;
                        case FeedbackFormAction.Unpublish:
                            return isValid && !values?.publishAtPortal;

                        default:
                            return false;
                    }
                })();

                return (
                    <ModalWindowRight
                        isOpened={!!id}
                        onClose={onClose}
                        headerComponent={
                            id ? (
                                <FeedbackModalHeader
                                    title={feedback?.event.name}
                                    date={feedback?.createDate}
                                    event={feedback?.event}
                                />
                            ) : undefined
                        }
                        ComponentFooter={
                            formAction && canSubmit && !isSubmitting ? (
                                <FeedbackModalSubmitButton action={formAction} onClick={submitForm} />
                            ) : undefined
                        }
                    >
                        {loading || isSubmitting ? (
                            <div className="feedback__loader-wrapper">
                                <Loader width="48px" />
                            </div>
                        ) : (
                            <div className="content-wrapper">
                                <FeedbackOrder order={order} />
                                <Form>
                                    <div
                                        className={classNames("feedback__message", {
                                            "feedback__message-with-attachments": !!questionAttachments.length,
                                        })}
                                    >
                                        <MessageField
                                            name="message"
                                            label={`Вопрос от ${messageFieldLabel}`}
                                            noPadding
                                        />
                                        <FeedbackAttachments attachments={questionAttachments} />
                                    </div>
                                    <div
                                        className={classNames("feedback__message", {
                                            "feedback__message-with-attachments":
                                                !!answerAttachments.length && !isSubmitAction,
                                        })}
                                    >
                                        {isNew ? (
                                            <TextareaField
                                                name="answer"
                                                label="Ответ"
                                                className="feedback__message-textarea"
                                                placeholder="Напишите подробное пояснение"
                                                maxLength={1500}
                                                disabled={!isSubmitAction}
                                            />
                                        ) : (
                                            <MessageField name="answer" label={"Ответ"} maxRows={15} noPadding />
                                        )}
                                        {!isSubmitAction && <FeedbackAttachments attachments={answerAttachments} />}
                                    </div>
                                    {isSubmitAction && (
                                        <FileUploadField
                                            name="answerFiles"
                                            maxFileSize={MAX_FILES_SIZE_SUM}
                                            maxFiles={MAX_FILES_COUNT}
                                            accepts={[".jpeg", ".jpg", ".png", ".pdf"]}
                                            onChange={(files) => {
                                                setFieldTouched("answerFiles");
                                                setFieldValue("answerFiles", files);
                                            }}
                                            buttonTitle="Загрузить файл"
                                            className="feedback__file-upload"
                                            hint={`Не более ${MAX_FILES_COUNT} файлов общим объемом ${MAX_FILES_SIZE_SUM} мб`}
                                            onError={setFileUploadError}
                                        />
                                    )}
                                    {publicationAvailable && (
                                        <CheckboxField
                                            name="publishAtPortal"
                                            label={
                                                isHotel
                                                    ? "Опубликовать ответ на странице объекта"
                                                    : "Опубликовать ответ на странице услуги"
                                            }
                                            classNameModifier="feedback__checkbox"
                                        />
                                    )}
                                </Form>
                            </div>
                        )}
                    </ModalWindowRight>
                );
            }}
        </Formik>
    );
};
