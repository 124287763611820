import { object as objectYup, string as stringYup, boolean as booleanYup } from "yup";
import { checkIsStrIsTime } from "../../utils";
import { SERVICE_MIN_DESCRIPTION_LENGTH } from "../../constants";
import { FORM_FIELD_REQUIRED_ERROR, FORM_MIN_ERROR, FORMAT_ERROR } from "../../../../constants/errors";

export const serviceInfoValidation = objectYup().shape({
    title: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    eventType: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    description: stringYup().required(FORM_FIELD_REQUIRED_ERROR).min(SERVICE_MIN_DESCRIPTION_LENGTH, FORM_MIN_ERROR),
    duration: stringYup().test("checkIsTime", FORMAT_ERROR, checkIsStrIsTime).required(FORM_FIELD_REQUIRED_ERROR),
    ageRestriction: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    ndsPercent: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    paymentType: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    personalizedTickets: booleanYup().required(FORM_FIELD_REQUIRED_ERROR),
    seasonUnlimited: booleanYup().required(),
    seasonStart: stringYup().test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
        const { seasonUnlimited } = this.parent;
        if (seasonUnlimited) return true;

        return !!value;
    }),
    seasonEnd: stringYup().test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
        const { seasonUnlimited, seasonStart } = this.parent;
        if (seasonUnlimited || seasonStart) return true;

        return !!value;
    }),
});
