import React, { useCallback, useMemo, useRef } from "react";
import { FeedbackSort } from "../../constants/FeedbackSort";
import { MultiMenu, MultiMenuRef, RadioButton } from "@russpass/partner-front-ui";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/images/icons/ic_chevron_down.svg";
import { ReactComponent as SortIcon } from "../../../../assets/images/icons/ic_filter.svg";
import useUserInfo from "../../../../hooks/useUserInfo";

type FeedbackSortButtonProps = {
    activeValue?: FeedbackSort;
    onChange?: (value: FeedbackSort) => void;
};

const EMPTY_EVENT_FILTER_VALUE = "---";

const BASE_OPTIONS = [
    {
        name: "Сначала новые",
        value: FeedbackSort.CreateDateDesc,
    },
    {
        name: "Сначала старые",
        value: FeedbackSort.CreateDateAsc,
    },
];

const HOTEL_OPTIONS = [
    {
        name: "Сначала с бронированием",
        value: FeedbackSort.OrderAsc,
    },
    {
        name: "Сначала без бронирования",
        value: FeedbackSort.OrderDesc,
    },
];

export const FeedbackSortFilter = ({ activeValue, onChange }: FeedbackSortButtonProps) => {
    const menuRef = useRef<MultiMenuRef>(null);

    const { isHotel } = useUserInfo();

    const options = useMemo(() => [...BASE_OPTIONS, ...(isHotel ? HOTEL_OPTIONS : [])], [isHotel]);

    const selectedOption = useMemo(() => options.find(({ value }) => value === activeValue), [options, activeValue]);

    const filterButton = useMemo(
        () => (
            <button className="button button--second">
                <SortIcon className="button__icon--left" />
                {selectedOption?.name}
                <ArrowDownIcon className="button__icon--right" />
            </button>
        ),
        [selectedOption]
    );

    const handleChange = useCallback(
        (value) => {
            menuRef.current?.closeMenu();
            onChange?.(value !== EMPTY_EVENT_FILTER_VALUE ? value : undefined);
        },
        [onChange]
    );

    return (
        <MultiMenu ref={menuRef} renderComponent={filterButton} noHover hideArrow>
            <>
                {options?.map(({ value, name }) => (
                    <RadioButton
                        key={value}
                        label={name}
                        value={value}
                        isActive={value === activeValue}
                        handleClick={handleChange}
                    />
                ))}
            </>
        </MultiMenu>
    );
};
