import { useContext } from "react";
import { EditNotificationsContext } from "./EditNotificationsContext";

export const useEditNotificationsContext = () => {
    const context = useContext(EditNotificationsContext);

    if (!context) {
        throw new Error("useEditNotificationsContext must be used within a EditNotificationsProvider.");
    }

    return context;
};
