import fetchRequest from "./manager";
import { russpassApiServices } from "../constants/api";
import { ErrorResponse, ServerResponse } from "../types/api";
import {
    RentHousesBookingRequestStatus,
    RentHousesObjectInfo,
    RentHousesObjectRequestStatus,
    RentHousesProfileUpdateInfo,
} from "../types/rentHouses";

export const saveRentHousesImage = (file: File): Promise<ServerResponse<{ cmsId: string }>> => {
    let formData = new FormData();
    formData.append("file", file, file.name);

    return fetchRequest.file(`${russpassApiServices.rentHouses}/partner/images`, formData, {
        isProtected: true,
    });
};

export const updateRentHousesOwnerProfile = (info: RentHousesProfileUpdateInfo): Promise<ServerResponse> =>
    fetchRequest.post(`${russpassApiServices.rentHouses}/partner/owner-profile`, info, {
        isProtected: true,
    });

export const createRentHousesObject = (
    info: RentHousesObjectInfo
): Promise<ServerResponse<RentHousesObjectRequestStatus>> =>
    fetchRequest.post(`${russpassApiServices.rentHouses}/partner/objects`, info, {
        isProtected: true,
    });

export const updateRentHousesObject = (
    id: string,
    info: Partial<RentHousesObjectInfo>
): Promise<ServerResponse<RentHousesObjectRequestStatus & { patch?: RentHousesObjectRequestStatus }>> =>
    fetchRequest.put(`${russpassApiServices.rentHouses}/partner/objects/${id}`, info, {
        isProtected: true,
    });

export const deleteRentHousesObject = (id: string): Promise<ErrorResponse> =>
    fetchRequest.delete(`${russpassApiServices.rentHouses}/partner/objects/${id}`, {
        isProtected: true,
    });

export const sendForModerationRentHousesObject = (id: string): Promise<ServerResponse<RentHousesObjectRequestStatus>> =>
    fetchRequest.patch(`${russpassApiServices.rentHouses}/partner/objects/${id}/send-for-moderation`, undefined, {
        isProtected: true,
    });

export const confirmRentHousesBooking = (id: string): Promise<ServerResponse<RentHousesBookingRequestStatus>> =>
    fetchRequest.patch(`${russpassApiServices.rentHouses}/partner/bookings/${id}/confirm`, undefined, {
        isProtected: true,
    });

export const rejectRentHousesBooking = (id: string): Promise<ServerResponse<RentHousesBookingRequestStatus>> =>
    fetchRequest.patch(`${russpassApiServices.rentHouses}/partner/bookings/${id}/reject`, undefined, {
        isProtected: true,
    });

export const expireRentHousesBooking = (id: string): Promise<ServerResponse<RentHousesBookingRequestStatus>> =>
    fetchRequest.patch(`${russpassApiServices.rentHouses}/partner/bookings/${id}/expired`, undefined, {
        isProtected: true,
    });

export const createRentHousesBookingMark = (id: string, value: number): Promise<ServerResponse<void>> =>
    fetchRequest.post(
        `${russpassApiServices.rentHouses}/partner/bookings/${id}/marks`,
        { value },
        {
            isProtected: true,
        }
    );

export const setRentHousesBookingUnavailableDays = ({
    id,
    year,
    days,
    month,
}: {
    id: string;
    year: number;
    month: number;
    days: number[];
}): Promise<ServerResponse<void>> => {
    const monthStr = month && month < 10 ? `0${month}` : `${month}`;
    const data = days.length ? { days } : {};

    return fetchRequest.patch(
        `${russpassApiServices.rentHouses}/partner/objects/${id}/unavailable/${year}/${monthStr}`,
        data,
        {
            isProtected: true,
        }
    );
};
