import CustomError from "../lib/custom-error";

const getCustomErrorMessage = (error: any, defaultValue: string) => {
    if (!(error instanceof CustomError)) {
        return defaultValue;
    }

    const response = (error.data.resp as typeof error.data) || error.data;

    return /^2\d{3}$/.test(`${response.errorCode}`) ? (response.errorCause as string) : defaultValue;
};

export default getCustomErrorMessage;
