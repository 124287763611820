import { DataGridColumnType } from "@russpass/partner-front-ui";
import { ReactComponent as CircleIcon } from "../../../assets/images/icons/circle.svg";
import React from "react";
import { RestaurantNameFilter } from "./types";
import { Restaurant } from "../all/types";
import moment from "moment";
import {
    DOTTED_SHORT_DATE_FORMAT,
    EXTENDED_DATE_TIME_FORMAT,
    SHORT_DAY_MONTH_TIME_FORMAT,
} from "../../../constants/dates-format";
import { declensionOfNum } from "../../../utils/declension-number";

export const getDataGridBookingColumns = (isNew?: boolean): DataGridColumnType[] => {
    return [
        {
            value: (item) => {
                return `${moment(item.reservationDate, EXTENDED_DATE_TIME_FORMAT).format(SHORT_DAY_MONTH_TIME_FORMAT)}, 
                        ${item.guestCount} ${declensionOfNum(item.guestCount, ["гость", "гостя", "гостей"])}${
                    item.author ? "," : ""
                } 
                        ${item.author?.lastName ?? ""} ${item.author?.firstName ?? ""}`;
            },
            label: "Информация по бронированию",
            width: "370px",
            icon: isNew
                ? (item) => {
                      return <CircleIcon width={12} height={12} fill="#FFCF08" />;
                  }
                : undefined,
        },
        {
            value: "object.name",
            label: "Ресторан",
            width: "254px",
        },
        {
            value: (item) => moment(item.createDate, EXTENDED_DATE_TIME_FORMAT).format(DOTTED_SHORT_DATE_FORMAT),
            label: "Дата заявки",
            width: "86px",
        },
    ];
};

export const getNameFilterOptions = (data: Restaurant[]): RestaurantNameFilter[] => {
    const options: RestaurantNameFilter[] = [];
    options.push({
        value: "",
        label: "Все объекты",
    });
    data.forEach((restaurant) => {
        options.push({
            value: restaurant.cmsId,
            label: restaurant.title,
        });
        return restaurant;
    });
    return options;
};
