import shortid from "shortid";
import * as jiraSDApi from "../api/jira-sd";

export async function getJiraUserToken(name: string) {
    try {
        const newPassword = shortid.generate();
        await jiraSDApi.changeUserPassword(name, newPassword);
        return btoa(`${name}:${newPassword}`);
    } catch (err) {
        console.error("err");
        return "";
    }
}
