import { CommonLayout } from "@russpass/partner-front-ui";
import React, { useEffect, useMemo } from "react";
import history from "../../../../../../history";
import { useLocation } from "react-router-dom";
import { HotelDataContext, useHotelDataContext } from "../../../hotel-view/hotel-data-context";
import useHotel from "../../../hotel-view/useHotel";
import { Ratings } from "../../../../../services/components/ratings/ratings";

import styles from "../hotel-tariffs-list/add-tariff-page.module.sass";

const HotelRatingsListContent = () => {
    const location = useLocation();

    const { hotelInfoData, loadHotelInfoData, isLoadingHotelInfo: isLoading } = useHotelDataContext();

    useEffect(() => {
        loadHotelInfoData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const backButton = useMemo(
        () => ({
            title: "Назад",
            onClick: () => history.push(location.pathname.replace("/ratings", "")),
        }),
        [location.pathname]
    );

    return (
        <CommonLayout
            pageTitle="Отзывы"
            backButton={backButton}
            isLoadingWithoutContent={isLoading}
            description={hotelInfoData?.title}
            fullHeight
            classNameTitle={styles.title}
        >
            <div>{hotelInfoData?.cmsId ? <Ratings id={hotelInfoData.cmsId} /> : null}</div>
        </CommonLayout>
    );
};

const HotelRatingsListV2 = () => {
    const { hotelInfoData, loadHotelInfoData, isTravelLineHotel, isNewTravelLineHotel } = useHotel({});

    const hotelDataProviderValue = useMemo(
        () => ({
            hotelInfoData,
            isNewTravelLineHotel,
            isTravelLineHotel,
            loadHotelInfoData,
        }),
        [hotelInfoData, isNewTravelLineHotel, isTravelLineHotel, loadHotelInfoData]
    );

    return (
        <HotelDataContext.Provider value={hotelDataProviderValue}>
            <HotelRatingsListContent />
        </HotelDataContext.Provider>
    );
};

export default HotelRatingsListV2;
