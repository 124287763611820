import React, { useMemo } from "react";
import classNames from "classnames";
import navbarStyles from "./navbar.module.sass";

type SwitchButtonProps<T> = {
    onMouseLeave?: () => void;
    isMobile: boolean;
    activeTab: T;
    tabName: T;
    changeActiveTab: (activeTabKey: T) => void;
    title: string;
};

const SwitchButton = <T extends unknown>({
    title,
    tabName,
    isMobile,
    activeTab,
    onMouseLeave,
    changeActiveTab,
}: SwitchButtonProps<T>) => {
    const className = useMemo(
        () =>
            classNames("date-control__switch-button", {
                button: !isMobile,
                "button--second": activeTab === tabName && !isMobile,
                [navbarStyles.navbar__link]: isMobile,
                [navbarStyles.navbar__link_active_main]: activeTab === tabName && isMobile,
                "date-control__switch-button__active": activeTab === tabName && !isMobile,
                "date-control__switch-button__not-active": activeTab !== tabName && !isMobile,
            }),
        [activeTab, isMobile, tabName]
    );
    return (
        <button
            onMouseLeave={onMouseLeave}
            className={className}
            type="button"
            onClick={() => changeActiveTab(tabName)}
            data-qa-name="date-control-item"
        >
            {title}
        </button>
    );
};

export default SwitchButton;
