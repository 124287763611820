import { OrderFilterContext } from "./OrderFilterContext";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { DatesFilterType, INITIAL_FILTERS, OrderFilters, OrdersSorter, sortOrdersOptions } from "../constants";

export const OrderFiltersProvider = ({ children }: PropsWithChildren<any>) => {
    const [filters, setFilters] = useState<OrderFilters>(INITIAL_FILTERS);
    const [sort, setSort] = useState<OrdersSorter>(sortOrdersOptions[0]);
    const [isEmpty, setIsEmpty] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState<DatesFilterType>(DatesFilterType.CREATE);

    const changeFilter = useCallback(
        (newFilters: Partial<OrderFilters>) => {
            setFilters((prev) => ({ ...prev, ...newFilters }));
        },
        [filters]
    );

    const value = useMemo(
        () => ({
            filters,
            changeFilter,
            sort,
            setSort,
            isEmpty,
            setIsEmpty,
            activeTab,
            setActiveTab,
        }),
        [filters, sort, changeFilter, isEmpty, activeTab]
    );

    return <OrderFilterContext.Provider value={value}>{children}</OrderFilterContext.Provider>;
};
