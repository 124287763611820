import React from "react";
import { ReactComponent as IconAlert } from "../../../../../assets/images/icons/ic_alert_circle.svg";
import styles from "./styles.module.sass";

const ChangeBasicTariffHint = () => (
    <div className={styles.container}>
        <div>
            <IconAlert />
        </div>
        <div className={styles.text}>
            {
                "Чтобы поменять базовый тариф, откройте нужный тариф и поставьте галочку в соответствующем поле для замены"
            }
        </div>
    </div>
);

export default ChangeBasicTariffHint;
