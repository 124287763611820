import { useEffect } from "react";

export const useOnClickOutside = (ref: any, handler: (event: Event) => void) => {
    useEffect(() => {
        const listener = async (event: Event) => {
            if (!ref.current || ref.current.contains(event.target)) return;
            setTimeout(() => handler(event));
        };

        document.addEventListener("mouseup", listener);
        document.addEventListener("touchend", listener);

        return () => {
            document.removeEventListener("mouseup", listener);
            document.removeEventListener("touchend", listener);
        };
    }, [ref, handler]);
};

export default useOnClickOutside;
