import { createContext, FC, ReactNode, useCallback, useContext, useMemo } from "react";
import { HotelDataForm } from "../types/hotel-info";
import { useRequest } from "../../../../../hooks/useRequest";
import { getSpecialOffers } from "../../../../../api/specialOffers";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { GET_DATA_ERROR } from "../../../../../constants/errors";
import { DiscountBonusType, DiscountStatus, DiscountType, HotelDiscountsData } from "../types/hotel-discounts";
import routes from "../../../../../constants/routes";
import { HotelStatuses } from "../../../../../enums/hotels";

type RoomOffersView = {
    id: string;
    title: string;
    url: string;
}[];

type HotelSpecialOffersContextType = {
    specialOffers: HotelDiscountsData[];
    getRoomOffers: (roomId: string) => RoomOffersView;
};

export const HotelSpecialOffersContext = createContext<HotelSpecialOffersContextType>({
    specialOffers: [],
    getRoomOffers: () => [],
});

type Props = {
    children?: ReactNode;
    hotel?: HotelDataForm;
    offers?: HotelDiscountsData[];
};

export const HotelSpecialOffersProvider: FC<Props> = ({ children, hotel, offers }) => {
    const { result: specialOffers } = useRequest(async () => {
        if (hotel?.status !== HotelStatuses.Active) return [];
        if (offers) return offers;

        try {
            return ((await getSpecialOffers(hotel.cmsId, DiscountStatus.PUBLISHED, 100))?.data?.items ||
                []) as HotelDiscountsData[];
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
        }

        return [];
    }, [hotel?.id, offers]);

    const getRoomOffers = useCallback(
        (roomId: string): RoomOffersView => {
            if (!specialOffers || !hotel) return [];

            const getTitle = (offer: HotelDiscountsData) => {
                if (offer.specialOfferType === DiscountType.DISCOUNT) {
                    return `${offer.title}. Скидка ${offer.discountSize}%`;
                }
                if (offer.specialOfferType === DiscountType.BONUS) {
                    if (offer.bonusCategory === DiscountBonusType.BONUS_BREAKFAST) {
                        return `${offer.title}. Бесплатный завтрак`;
                    }
                    if (offer.bonusCategory === DiscountBonusType.BONUS_UPGRADE) {
                        return `${offer.title}. Повышение категории номера`;
                    }
                }
                return offer.title;
            };

            return specialOffers
                .filter((el) => el.roomId === roomId)
                .map((el) => ({
                    id: el.id,
                    title: getTitle(el),
                    url: routes.hotelsViewDiscounts.replace(":hotelId", hotel.id),
                }));
        },
        [hotel, specialOffers]
    );

    const providerValue = useMemo(
        () => ({
            getRoomOffers,
            specialOffers: specialOffers || [],
        }),
        [getRoomOffers, specialOffers]
    );

    return <HotelSpecialOffersContext.Provider value={providerValue}>{children}</HotelSpecialOffersContext.Provider>;
};

export const useHotelSpecialOffersContext = () => useContext(HotelSpecialOffersContext);
