import { difference, differenceWith, isEqual, reduce } from "lodash";

// Сравнивает два массива равны ли они между собой
export const isEqualArrays = (a: any[], b: any[]) => {
    let diff = [];
    if (a.length > b.length) {
        diff = difference(a, b);
    } else {
        diff = difference(b, a);
    }
    return diff.length === 0;
};

// Возвращает разницу массива
export const diffArrays = (a: any[], b: any[]) => {
    if (a.length > b.length) {
        return difference(a, b);
    } else {
        return difference(b, a);
    }
};

// Возвращает разницу массива строгий режим
export const getDiffsArraysStrict = (a: any[], b: any[]) => {
    return differenceWith(a, b, isEqual);
};

// Сравнивает два объекта равны ли они между собой
export const isEqualObjects = (a: {}, b: {}) => {
    return isEqual(a, b);
};

// Возвращает разницу свойств объектов
export const diffObjects = (a: any, b: any) => {
    return reduce(
        b,
        function (result, value, key) {
            return isEqual(value, a[key])
                ? result
                : {
                      ...result,
                      [key]: b[key],
                  };
        },
        {}
    );
};
