export const FORMAT_DROPZONE_FOR_IMAGE = "image/*";

export const PHOTOS_LIMIT = 15;

export const MAX_PHOTO_SIZE_Mb = 15;
export const MAX_PHOTO_SIZE_BIT = MAX_PHOTO_SIZE_Mb * 10 ** 6;

export const ErrorMaxPhoto = "Максимум 15 фотографий";

export const LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS =
    Number(process.env.REACT_APP_LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS) || Infinity;
