import { useCallback, useEffect, useState } from "react";
import useLoadUserInfo from "../../../hooks/useLoadUserInfo";
import signOut from "../../../lib/sign-out";
import { isNotFound } from "../../../utils/errors";
import { reportsByYearType } from "../report/types";
import { getPartnersReport } from "../../../api/documents";
import { separateReportByYear } from "../report/utils/separate-by-year";
import eventDispatcher from "../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { GET_DATA_ERROR } from "../../../constants/errors";
import useUserInfo from "../../../hooks/useUserInfo";

const defaultStartDate = "2000-01-01";
const defaultEndDate = "2100-01-01";

export const useUpdateUserReports = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataReport, setReportData] = useState<reportsByYearType[]>([]);
    const [isNotCompletedPeriod, setIsNotCompletedPeriod] = useState<boolean>(false);

    const { userFullInfo, isChecked } = useUserInfo();

    const { loadUserInfo } = useLoadUserInfo();

    const updateUserInfo = useCallback(() => {
        setIsLoading(true);
        if (!userFullInfo) return;
        loadUserInfo(userFullInfo.email)
            .then(() => {
                setIsLoading(false);
            })
            .catch((err) => {
                if (isNotFound(err)) {
                    signOut();
                    console.error(err);
                }
            });
    }, [loadUserInfo, userFullInfo]);

    const loadReport = useCallback(
        async (startDate = defaultStartDate, endDate = defaultEndDate) => {
            if (!userFullInfo?.applicationId) return;
            setIsLoading(true);
            try {
                const responseReportData = await getPartnersReport({
                    startDate,
                    endDate,
                    applicationId: userFullInfo.applicationId,
                });
                const resultData = separateReportByYear(responseReportData);

                setReportData(resultData);

                const initLoad = startDate === defaultStartDate && endDate === defaultEndDate;

                if (initLoad && !resultData.length) {
                    setIsNotCompletedPeriod(true);
                }
            } catch (err) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: GET_DATA_ERROR,
                });
            } finally {
                setIsLoading(false);
            }
        },
        [setIsLoading, userFullInfo]
    );

    useEffect(() => {
        loadReport();
    }, [loadReport]);

    return {
        isLoading,
        setIsLoading,
        updateUserInfo,
        isChecked,
        loadReport,
        dataReport,
        isNotCompletedPeriod,
    };
};
