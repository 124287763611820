import { QuestionTooltip, useViewportContext } from "@russpass/partner-front-ui";
import React from "react";
import { BookingsDateInfo } from "../hooks/useBookingCalendarDay";

const Tooltip = ({ body }: { body: string }) => (
    <QuestionTooltip
        body={body}
        settings={{ width: "max-content", offsetX: 160, offsetY: -35 }}
        tooltipStyle={{ height: "38px" }}
    />
);

type BookingQuestionTooltipProps = {
    info: BookingsDateInfo;
    isBlocking: boolean;
};

export const BookingQuestionTooltip = ({ info, isBlocking }: BookingQuestionTooltipProps) => {
    const { isActive, isBlocked, isCompleted, isPending, multiplePendingBookings } = info;

    const { isMobile } = useViewportContext();

    if (isMobile) return null;

    if (isCompleted) {
        return <Tooltip body="Завершенное бронирование" />;
    }
    if (multiplePendingBookings || (isPending && isActive)) {
        return <Tooltip body="Пересечение нескольких бронирований" />;
    }
    if (isPending) {
        return <Tooltip body="Бронирование ожидает подтверждения" />;
    }
    if (isActive) {
        return <Tooltip body="Бронирование подтверждено" />;
    }
    if (!isBlocking && isBlocked) {
        return <Tooltip body="Дата недоступна для бронирования" />;
    }

    return null;
};
