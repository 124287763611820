import { useRentHousesBookingUnavailableDays } from "../../../../../api/hooks/rentHouses";
import { useCallback, useEffect, useMemo, useState } from "react";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { setRentHousesBookingUnavailableDays } from "../../../../../api/rentHouses";
import { POST_DATA_ERROR2 } from "../../../../../constants/errors";

export const useBookingBlocks = (activeObject: string, activeYear: number, activeMonth: number) => {
    const {
        data,
        isLoading: isBlocksLoading,
        mutate,
    } = useRentHousesBookingUnavailableDays({
        id: activeObject,
        month: activeMonth,
        year: activeYear,
    });

    const unavailableDays = useMemo(
        () => data?.data?.days?.map((el) => new Date(el).getDate()) || [],
        [data?.data?.days]
    );

    const [isBlocking, setIsBlocking] = useState(false);

    const [blockedDays, setBlockedDays] = useState<number[]>([]);

    const toggleBlock = useCallback(
        (day) => {
            const isBlocked = blockedDays.includes(day);
            if (isBlocked) {
                setBlockedDays((days) => days.filter((el) => el !== day));
            } else {
                setBlockedDays((days) => [...days, day]);
            }
        },
        [blockedDays]
    );

    const resetDays = useCallback(() => setBlockedDays(unavailableDays), [unavailableDays]);
    useEffect(() => {
        resetDays();
    }, [resetDays]);

    const enable = useCallback(() => setIsBlocking(true), []);
    const disable = useCallback(() => setIsBlocking(false), []);

    const saveBlocks = useCallback(async () => {
        try {
            await setRentHousesBookingUnavailableDays({
                id: activeObject,
                year: activeYear,
                month: activeMonth,
                days: blockedDays,
            });
            await mutate();
            disable();
        } catch (e) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: POST_DATA_ERROR2,
            });
        }
    }, [activeMonth, activeObject, activeYear, blockedDays, disable, mutate]);

    useEffect(() => {
        disable();
    }, [activeObject, activeMonth, activeYear, disable]);

    return { isBlocksLoading, isBlocking, enableBlocking: enable, saveBlocks, blockedDays, toggleBlock };
};
