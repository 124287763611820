import DayPicker, { Modifiers } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import classNames from "classnames";
import "./datepicker.sass";
import styles from "./Calendar.module.sass";
import React from "react";

type Props = {
    locale?: string;
    numberOfMonths?: number;
    modifiers?: Partial<Modifiers>;
};

export const Calendar = ({ locale = "ru", numberOfMonths = 1, modifiers }: Props) => {
    return (
        <div className="datepicker">
            <DayPicker
                className={classNames("datepicker__calendar", styles.Calendar, {
                    "DayPicker__calendar-one": numberOfMonths === 1,
                    "DayPicker__calendar-one--mobile": numberOfMonths === 1,
                })}
                localeUtils={MomentLocaleUtils}
                locale={locale}
                modifiers={modifiers}
                renderDay={(date) => {
                    const currentDate = new Date();

                    return (
                        <div className="datepicker-day">
                            <div
                                className={classNames("datepicker-day__inner", {
                                    [styles.CurrentDay]: date.toDateString() === currentDate.toDateString(),
                                })}
                            >
                                {date.getDate()}
                            </div>
                        </div>
                    );
                }}
                disabledDays={{ before: new Date() }}
                fromMonth={new Date()}
            />
        </div>
    );
};
