import { RentHousesBookingItem, RentHousesBookingStatus } from "../../../../../types/rentHouses";
import styles from "./BookingsList.module.sass";
import React, { ReactNode, useMemo } from "react";
import moment from "moment";
import {
    DOTTED_SHORT_DATE_FORMAT,
    MIN_DAY_MONTH_DATE_FORMAT,
    SHORT_DAY_DATE_FORMAT,
} from "../../../../../constants/dates-format";
import { formatPrice } from "../../helpers";
import { ReactComponent as Close } from "../../../../../assets/images/icons/ic_chevron.svg";
import history from "../../../../../history";
import routes from "../../../../../constants/routes";

type Props = {
    bookings: RentHousesBookingItem[];
    description?: ReactNode;
};

const getTitle = (booking: RentHousesBookingItem) => {
    const start = moment(booking.checkinDate);
    const end = moment(booking.checkoutDate);

    const isSameMonth = start.isSame(end, "month");

    const period = isSameMonth
        ? `${start.format(SHORT_DAY_DATE_FORMAT)} – ${end.format(MIN_DAY_MONTH_DATE_FORMAT)}`
        : `${start.format(DOTTED_SHORT_DATE_FORMAT)} – ${end.format(DOTTED_SHORT_DATE_FORMAT)}`;

    return `${period} на ${formatPrice(booking.amount)}`;
};

export const BookingsList = ({ bookings, description }: Props) => {
    const hasPending = useMemo(
        () => bookings.some((booking) => booking.status === RentHousesBookingStatus.PENDING),
        [bookings]
    );
    return (
        <div className={styles.List}>
            <div className={styles.Title}>
                {hasPending ? "На эту дату есть несколько заявок" : "На эту дату есть несколько бронирований"}
            </div>
            {description && <div className={styles.Description}>{description}</div>}
            {bookings.map((el) => (
                <div
                    key={el.id}
                    className={styles.Item}
                    onClick={() => history.push({ pathname: routes.apartmentsBookingAll, search: `id=${el.id}` })}
                >
                    {getTitle(el)}
                    <Close />
                </div>
            ))}
        </div>
    );
};
