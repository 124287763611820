import React, { useCallback, useEffect, useState } from "react";
import styles from "./notification.module.sass";
import classNames from "classnames";
import { createPortal } from "react-dom";
import uuid from "react-native-uuid";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/ic_cross.svg";
import { eventNames } from "../../constants/events";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

export type Notification = {
    uuid: string;
    status: string;
    body: string;
    isAlwaysView?: boolean;
    maxWidth?: number;
    parseMode?: "html";
};

export type NotificationProps = {
    status: string;
    body: string;
    isAlwaysView?: boolean;
};

const NotificationComp = () => {
    const [params, setParams] = useState<Notification[]>([]);

    const [isViewNotifications, setIsViewNotifications] = useState<string[]>([]);

    const onClose = useCallback((uuid) => {
        setIsViewNotifications((prev) => prev.filter((item) => item !== uuid));
        setTimeout(() => {
            setParams((prev) => prev.filter((item) => item.uuid !== uuid));
        }, 300);
    }, []);

    const viewNotification = useCallback(
        (props) => {
            const _uuid = uuid.v4() as string;
            setParams((prev) => [
                ...prev,
                {
                    uuid: _uuid,
                    status: props.detail.status || "",
                    body: props.detail.body || "",
                    maxWidth: props.detail.maxWidth || "",
                    parseMode: props.detail.parseMode,
                },
            ]);
            setTimeout(() => {
                setIsViewNotifications((prev) => [...prev, _uuid]);
            }, 30);
            if (!props.detail.isAlwaysView) {
                setTimeout(() => onClose(_uuid), 5000);
            }
        },
        [onClose]
    );

    useEffect(() => {
        document.addEventListener(eventNames.setNotification, viewNotification);
        return () => document.removeEventListener(eventNames.setNotification, viewNotification);
    }, [viewNotification]);

    return createPortal(
        <div className={classNames(styles.notification__wrapper)}>
            {params.length > 0 &&
                params.map((notification) => (
                    <div
                        key={notification.uuid}
                        className={classNames(
                            styles.notification,
                            isViewNotifications.indexOf(notification.uuid) > -1 ? styles.notification__view : "",
                            notification.status === StatusRequestEnum.Error && styles.notification__error,
                            notification.status === StatusRequestEnum.Success && styles.notification__success
                        )}
                    >
                        <div className={styles.notification__content} style={{ maxWidth: notification.maxWidth }}>
                            {notification.parseMode === "html" ? (
                                <div
                                    className={styles.notification__body}
                                    dangerouslySetInnerHTML={{ __html: notification.body }}
                                />
                            ) : (
                                <div className={styles.notification__body}>{notification.body}</div>
                            )}
                            <button
                                title="Закрыть"
                                className={styles.notification__action}
                                onClick={() => onClose(notification.uuid)}
                            >
                                <CrossIcon />
                            </button>
                        </div>
                    </div>
                ))}
        </div>,
        document.body
    );
};

export default NotificationComp;
