import React from "react";
import ServiceType from "../../components/service-type/service-type";
import { AgeRestriction, ServiceDataClient } from "../helpers/types";
import { Formik, Form, FormikProps } from "formik";
import { serviceInfoValidation } from "../helpers/validation-schema";
import { maskedInputTimeInHoursAndMinutes } from "../../../../utils/validation";
import { PAYMENT_TYPE_OPTIONS, PERSONALIZED_TICKET_OPTIONS, SERVICE_MIN_DESCRIPTION_LENGTH } from "../../constants";
import {
    InputField,
    AutoSubmit,
    SelectInputField,
    TextareaField,
    RadioGroup,
    RangeDatePickerField,
} from "@russpass/partner-front-ui";
import { IAtolVat } from "../../../../types/billing";
import { noop } from "lodash";
import { EventType } from "../../types";

type Props = {
    data: ServiceDataClient;
    optionsType: EventType[];
    optionsAgeRestriction: AgeRestriction[];
    optionsNdsRate: IAtolVat[];
    onChange: (values: ServiceDataClient) => void;
    isShowErrors: boolean;
    disabled: boolean;
};

const ServiceInfo = ({
    data,
    optionsType,
    optionsAgeRestriction,
    optionsNdsRate,
    onChange,
    isShowErrors,
    disabled,
}: Props) => {
    return (
        <div className="content-wrapper">
            <Formik
                initialValues={data}
                validationSchema={serviceInfoValidation}
                enableReinitialize
                validateOnMount
                onSubmit={noop}
            >
                {({ setFieldValue, values }: FormikProps<ServiceDataClient>) => {
                    return (
                        <Form>
                            <div className="content-wrapper__form">
                                <AutoSubmit onChange={onChange} />
                                <TextareaField
                                    name="title"
                                    disabled={disabled}
                                    noHover
                                    maxLength={100}
                                    maxRows={3}
                                    label={"Название"}
                                    placeholder={"Выставка Импрессионизма"}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                />
                                <SelectInputField
                                    name="paymentType"
                                    handleChange={(value) => {
                                        setFieldValue("paymentType", value.value);
                                    }}
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    options={PAYMENT_TYPE_OPTIONS}
                                    value={PAYMENT_TYPE_OPTIONS.find((v) => v.value === values.paymentType)}
                                    valueField="value"
                                    labelField="label"
                                    placeholder="Выбрать"
                                    isChecked
                                    label="Способ покупки"
                                />
                                <RadioGroup
                                    options={PERSONALIZED_TICKET_OPTIONS}
                                    label="Именные билеты"
                                    disabled={disabled}
                                    hint="Запросим только ФИО покупателя"
                                    value={PERSONALIZED_TICKET_OPTIONS.find(
                                        (option) => option.value === values.personalizedTickets
                                    )}
                                    onChange={(option) => {
                                        setFieldValue("personalizedTickets", option.value);
                                    }}
                                />
                                <ServiceType
                                    value={optionsType.find((v) => v.id === values.eventType)?.title || ""}
                                    options={optionsType}
                                    onChange={(value) => {
                                        setFieldValue("eventType", value.id);
                                    }}
                                    name="eventType"
                                    placeholder="Выберите"
                                    label="Тип"
                                    searchPlaceholder="Тип услуги"
                                    disabled={disabled}
                                    labelField={"title"}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                />
                                <TextareaField
                                    label="Описание"
                                    name="description"
                                    noHover
                                    placeholder={`От ${SERVICE_MIN_DESCRIPTION_LENGTH} до 1000 символов`}
                                    minLength={SERVICE_MIN_DESCRIPTION_LENGTH}
                                    maxLength={1000}
                                    maxRows={15}
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                />

                                <InputField
                                    label="Продолжительность"
                                    noHover
                                    name="duration"
                                    placeholder="02 ч. 05 мин."
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    renderComponent={maskedInputTimeInHoursAndMinutes}
                                />
                                <RangeDatePickerField
                                    label="Период проведения"
                                    placeholder="Выбрать"
                                    nameFrom="seasonStart"
                                    nameTo="seasonEnd"
                                    disabled={disabled}
                                    isShowAllYearCheckbox
                                    allYearCheckboxValue={values.seasonUnlimited}
                                    allYearCheckboxChange={(val) => {
                                        setFieldValue("seasonUnlimited", val);
                                    }}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    hint="Если ваше событие проходит несколько раз, выберите первую и последнюю даты"
                                />
                                <SelectInputField
                                    name="ageRestriction"
                                    handleChange={(value) => {
                                        setFieldValue("ageRestriction", value.name);
                                    }}
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    options={optionsAgeRestriction}
                                    value={
                                        optionsAgeRestriction.find((v) => v.name === values.ageRestriction) || {
                                            id: "",
                                            name: "",
                                        }
                                    }
                                    valueField="id"
                                    labelField="name"
                                    placeholder="Выберите"
                                    isChecked
                                    label="Возрастное ограничение"
                                />
                                <SelectInputField
                                    name="ndsPercent"
                                    value={
                                        optionsNdsRate.find((el) => el.caption === values.ndsPercent) || {
                                            code: "",
                                            caption: "",
                                        }
                                    }
                                    options={optionsNdsRate}
                                    disabled={disabled}
                                    labelField="caption"
                                    placeholder="Выбрать"
                                    valueField="code"
                                    label="НДС"
                                    isChecked
                                    handleChange={(value) => {
                                        setFieldValue("ndsPercent", value.caption);
                                    }}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ServiceInfo;
