import React from "react";
import MaskedInput, { Mask } from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { ReactFilesFile } from "@russpass/partner-front-ui";
import { convertToBytes } from "./format-bytes";
import { MAX_FILES_SIZE_SUM } from "../constants/validation";

type MaskProps = {
    [arbitrary: string]: any;
};

/* eslint-disable no-useless-escape */
export const emailRegexp =
    /^[a-zA-Z0-9\_\.\%\+\-]{1,256}\@[a-zA-Z0-9\-]{1,255}\.?([a-zA-Z0-9\-]{1,255}\.)[A-Za-z]{2,64}$/;

export const onlyNumbersRegexp = /^\d+$/;

export const rangeNumbersRegexp = [/^(\d+|-)$/];

export const phoneRegexp = /^(?:[0-9]{11}|\+7[0-9]{10}|\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2})$/;

export const timeRegexp = /^([01]\d|2[0-3]):?([0-5]\d)$/;

export const timePeriodRegexp = /^([01]\d|2[0-3]):?([0-5]\d) - ([01]\d|2[0-3]):?([0-5]\d)$/;

export const timeMask = [/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/];
const coordinatesMask = [
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ",",
    " ",
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export const mobileMask = (startsWith: RegExp | string, eightChecker: RegExp | " "): Mask => [
    "+",
    startsWith,
    eightChecker,
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
];

const onlyNumbersMask = (s: string): RegExp[] => Array.from(s).map(() => onlyNumbersRegexp);

const priceMask = createNumberMask({
    prefix: "",
    includeThousandsSeparator: false,
    allowDecimal: true,
});

export const passportMask = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
];

const separateMask = createNumberMask({
    prefix: "",
    suffix: "",
    thousandsSeparatorSymbol: " ",
});

const pipePhone = (conformedValue: string): string => {
    return conformedValue[2] === "8" ? conformedValue.slice(0, 2) + " " + conformedValue.slice(3) : conformedValue;
};

const mask = (
    props: MaskProps,
    typeofMask: Mask | ((value: string) => Mask),
    guide: boolean = false
): React.ReactNode => {
    return <MaskedInput {...props} guide={guide} mask={typeofMask} />;
};

export const maskedInputTime = (props: MaskProps) => mask(props, timeMask);
export const maskedInputPrice = (props: MaskProps) => mask(props, priceMask);
export const maskedSeparatePrice = (props: MaskProps) => mask(props, separateMask);
export const maskedInputNumbers = (props: MaskProps) => mask(props, onlyNumbersMask);
export const maskedInputCoordinates = (props: MaskProps) => mask(props, coordinatesMask);
export const maskedInputRangeNumbers = (props: MaskProps) => mask(props, rangeNumbersRegexp);
export const maskedInputPassport = (props: MaskProps) => mask(props, passportMask);

export const testFilesSizeSum = (maxSize?: number) => (value: ReactFilesFile[] | undefined) => {
    const totalSize = value?.reduce((prev, curr) => prev + curr?.size || 0, 0) || 0;
    return totalSize <= convertToBytes(maxSize || MAX_FILES_SIZE_SUM);
};

export const maskedInputPhone = (props: MaskProps) =>
    mask(
        {
            ...props,
            pipe: pipePhone,
        },
        (value: string) => {
            switch (value[0]) {
                case "8":
                    return mobileMask("7", /[8]/);
                case "9":
                    return mobileMask("7", " ");
                default:
                    return mobileMask(/[7]/, " ");
            }
        }
    );

export const maskedInputCustomNumber =
    (settings: MaskProps, pipe?: (value: string) => string | boolean) => (props: MaskProps) =>
        mask(
            { ...props, pipe },
            createNumberMask({
                prefix: "",
                includeThousandsSeparator: false,
                allowDecimal: true,
                ...settings,
            }),
            !!Number(props.value) // динамическое для случаев когда есть pipe, чтобы в input не попадал огрызок guide "_"
        );

//TODO переписать использую статичную маску
export const maskedInputTimePeriod = (props: MaskProps) =>
    mask(props, (value: string) => {
        const startWithTwo1 = value[0] === "2";
        const startWithTwo2 = value[8] === "2";
        const mask = [
            /[0-2]/,
            startWithTwo1 ? /[0-3]/ : /[0-9]/,
            ":",
            /[0-5]/,
            /[0-9]/,
            " ",
            "-",
            " ",
            /[0-2]/,
            startWithTwo2 ? /[0-3]/ : /[0-9]/,
            ":",
            /[0-5]/,
            /[0-9]/,
        ];
        return mask;
    });

export const maskedInputChildAge = (props: MaskProps) =>
    mask(props, (value: string) => {
        const startWithOne = value[0] === "1";
        const mask = [
            /[0-9]/,
            // eslint-disable-next-line no-empty-character-class
            startWithOne ? /[0-7]/ : /[]/,
        ];
        return mask;
    });

export const maskedInputTimeInHoursAndMinutes = (props: MaskProps) =>
    mask(
        props,
        (value: string) => {
            const startWithTwo = value[0] === "2";
            const mask = [/[0-2]/, startWithTwo ? /[0-3]/ : /[0-9]/, " ", "ч", ".", " ", /[0-5]/, /[0-9]/, " ", "мин."];
            return mask;
        },
        true
    );
