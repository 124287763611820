import { DialoguesUpdateRequest } from "../containers/apartments/dialogues/types/DialoguesUpdateRequest";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import eventDispatcher from "../lib/event-dispatcher";
import { ErrorResponse } from "../types/api";
import { feedbackApiDialogueUrl } from "./hooks/dialogues";
import fetchRequest from "./manager";

export const updateDialogue = async (
    cmsObjectId: string,
    externalId: number,
    info: DialoguesUpdateRequest
): Promise<boolean> => {
    try {
        const response: ErrorResponse = await fetchRequest.post(
            `${feedbackApiDialogueUrl}/${cmsObjectId}/${externalId}`,
            info,
            {
                isProtected: true,
            }
        );
        return response.errorCode === 0;
    } catch {
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: "В целях безопасности мы не отправляем сообщения с личными контактами. Наши специалисты не смогут помочь в спорных ситуациях, если договоренности были достигнуты устно или в других чатах",
            maxWidth: 622,
        });
        return false;
    }
};
