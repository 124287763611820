/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { createFacility, sendFacilityForApproval, updateFacility } from "../../../../api/facility";
import { createService, sendServiceForApproval, updateService } from "../../../../api/service";
import { updateTariff } from "../../../../api/billing";
import { POST_DATA_ERROR, StatusRequestCardSubtitleError } from "../../../../constants/errors";
import { navbarService, NavbarServiceNamesEnum } from "../helpers/constants";
import {
    ServiceDataClient,
    ServiceDataCreated,
    ServiceDataGet,
    ServiceDataPatch,
    ServiceDataPost,
} from "../helpers/types";
import { getWorkingTimeValidation } from "../../components/service-place/validation-schema";

import { NotificationProps } from "../../../../components/notification";

import { Tariff } from "../../components/tariffs/types";
import { cloneDeep } from "lodash";

import { processingForUpdateTariff } from "../../utils";
import { createTariffs } from "../../components/tariffs/utils";
import moment from "moment";
import { DATE_2061, eventDispatcher, StatusRequestEnum, extendedDateFormat } from "@russpass/partner-front-ui";
import { getTimetableData } from "../utils";
import useUserInfo from "../../../../hooks/useUserInfo";
import { Facility, PartnerFacilityType } from "../../types";
import { useCreateEventModalContext } from "../../components/contexts/createEventStatusModal";
import { CreateEventResultStatusEnum } from "../../components/CreateEventResultModal/create-event-modal-enum";
import history from "../../../../history";
import routes from "../../../../constants/routes";
import { checkValidServiceFields } from "../helpers/checkValidServiceFields";
import { getErrorForm } from "../helpers/getErrorForm";

type Props = {
    data: ServiceDataClient;
    initialData?: ServiceDataGet;
    tariffs: Tariff[];
    facility: Facility;
    selectedPartnerFacility: PartnerFacilityType | null;
    isExistingPartnerFacility: boolean;
    onChangeTariff: (tariffs: Tariff[]) => void;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    onChangeData: (values: ServiceDataClient) => void;
    reloadData: () => void;
};

export const useServiceActions = ({
    data,
    initialData,
    tariffs,
    facility,
    selectedPartnerFacility,
    isExistingPartnerFacility,
    onChangeTariff,
    setEditMode,
    onChangeData,
    reloadData,
}: Props) => {
    const [navbar, setNavbar] = useState(cloneDeep(navbarService));

    const [isFormSending, setIsFormSending] = useState(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const [facilitySaved, setFacilitySaved] = useState<Pick<Facility, "id" | "cmsFacilityId"> | null>(null);
    const [serviceSaved, setServiceSaved] = useState<ServiceDataCreated | null>(null);
    const [tariffsAreSaved, setTariffsAreSaved] = useState(false);
    const [facilitiesAreApproved, setFacilitiesAreApproved] = useState(false);

    const isValidFacilityWorkingTime = useMemo(
        () => getWorkingTimeValidation().isValidSync(facility.workingTime),
        [facility.workingTime]
    );

    const callNotification = (body: NotificationProps) => {
        eventDispatcher.setNotification({
            ...body,
        });
    };

    const { userFullInfo } = useUserInfo();
    const { openEventStatusModal } = useCreateEventModalContext();

    const checkIsValid = () => {
        const { isValidInfo, isValidPlace, isValidPhoto, isValidTariffs } = checkValidServiceFields({
            data,
            facility,
            tariffs,
        });

        setNavbar((prev) => {
            return prev.map((item: any) => {
                if (item.name === NavbarServiceNamesEnum.Service) item.isError = !isValidInfo;
                if (item.name === NavbarServiceNamesEnum.Place) item.isError = !isValidPlace;
                if (item.name === NavbarServiceNamesEnum.Photo) item.isError = !isValidPhoto;
                if (item.name === NavbarServiceNamesEnum.Tariff) item.isError = !isValidTariffs;
                return item;
            });
        });

        if (!isValidInfo || !isValidPlace || !isValidPhoto || !isValidTariffs) {
            const error = getErrorForm({
                isValidInfo,
                isValidPlace,
                isValidPhoto,
                isValidTariffs,
            });

            callNotification({
                status: StatusRequestEnum.Error,
                body: error || "",
            });
        }

        return isValidInfo && isValidPlace && isValidPhoto && isValidTariffs;
    };

    useEffect(() => {
        setFacilitySaved(null);
        setServiceSaved(null);
        setTariffsAreSaved(false);
        setFacilitiesAreApproved(false);
    }, [data]);

    const sendServiceToModerate = async () => {
        if ((!userFullInfo && !userFullInfo.email) || isFormSending) return;

        setIsSubmittingForm(true);
        if (!checkIsValid()) return;

        setIsFormSending(true);

        try {
            const facilityData = {
                ...facility,
                phones: facility.phone ? [facility.phone] : [],
                address: facility.addressDetail?.unrestricted_value || "",
                geoData: facility.coordinates ? facility.coordinates.split(",").map((v) => +v) : [],
                workingTime: getTimetableData(facility.workingTime),
                isAvailableForGuide: false,
            };
            delete facilityData.phone;
            delete facilityData.addressDetail;
            delete facilityData.coordinates;

            const facilityCreated = !isExistingPartnerFacility
                ? facilitySaved
                    ? { ...facilitySaved }
                    : await createFacility(facilityData)
                : selectedPartnerFacility;
            setFacilitySaved(facilityCreated);

            const duration = data.duration.split(" ");
            const hours = duration[0];
            const minutes = duration[2];

            const ticketPrice = tariffs.find((i) => i.isBasic)?.itemPrices[0].price || "";
            const seasonStart = data.seasonUnlimited
                ? moment(new Date()).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
                : data.seasonStart;
            const seasonEnd = data.seasonUnlimited
                ? moment(DATE_2061).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
                : data.seasonEnd;

            const serviceData: ServiceDataPost = {
                ...data,
                facility: facilityCreated?.cmsFacilityId || "",
                images: data.servicePhotoList?.length ? data.servicePhotoList.map((image) => image.id).slice(1) : [],
                imageExplorePreview: data.servicePhotoList?.length ? [data.servicePhotoList[0].id] : [],
                imageDetailedPageMain: data.servicePhotoList?.length ? [data.servicePhotoList[0].id] : [],
                city: facilityData?.city?.[0] || "",
                region: facility.region || "",
                duration: {
                    hours,
                    minutes,
                },
                ticketPrice,
                seasonStart,
                seasonEnd,
            };

            const serviceCreated: ServiceDataCreated = serviceSaved
                ? { ...serviceSaved }
                : await createService({
                      ...serviceData,
                  });

            setServiceSaved(serviceCreated);

            if (tariffs.length > 0 && !tariffsAreSaved) {
                await createTariffs(tariffs, +serviceCreated.billingProductId);
                setTariffsAreSaved(true);
            }

            if (!facilitiesAreApproved && !isExistingPartnerFacility && facilityCreated?.cmsFacilityId) {
                await sendFacilityForApproval(facilityCreated?.cmsFacilityId);
                setFacilitiesAreApproved(true);
            }

            await sendServiceForApproval(serviceCreated.cmsEventId);

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Success,
                title: `Услуга «${data.title}» отправлена на модерацию.`,
                desc: (
                    <>
                        В течение 5 рабочих дней вы получите ответ на{" "}
                        <span className="footnote">{userFullInfo.email}</span>
                    </>
                ),
                closeBtnAction: () => history.push(routes.servicesAll),
            });
        } catch (err) {
            console.error(err);

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Fail,
                title: `Не удалось отправить на модерацию «${data.title}».`,
                desc: StatusRequestCardSubtitleError,
                errorBtnAction: sendServiceToModerate,
                closeBtnAction: () => history.push(routes.servicesAll),
            });
        } finally {
            setIsFormSending(false);
        }
    };

    const sendServiceUpdate = async (sendToBilling: boolean, sendToCms: boolean) => {
        if (!data.id || isFormSending) return;

        //TODO при редактировании услуги не меняется data.facility, поэтому вх параметр sendToCms = false, хотя нужен true - разобраться при рефакторинге
        const sendToCms_ = sendToCms || facility.cmsFacilityId !== data.facility;

        setIsSubmittingForm(true);
        if (!checkIsValid()) return;

        setIsFormSending(true);

        const duration = data.duration.split(" ");
        const hours = duration[0];
        const minutes = duration[2];

        const ticketPrice = tariffs.find((i) => i.isBasic)?.itemPrices[0].price || "";

        let newCmsFacilityId;
        if (facility.id !== data.facility) {
            if (facility.id) {
                //TODO вызывать не всегда, а только если данные площадки изменились от той, что уже есть в цмс
                await updateFacility({
                    address: facility.address,
                    city: facility.city || [],
                    geoData: facility.geoData || [],
                    id: facility.id,
                    isAvailableForGuide: !!facility.isAvailableForGuide,
                    partnerType: facility.partnerType,
                    phones: facility.phones,
                    region: facility.region || "",
                    title: facility.title,
                    workingTime: getTimetableData(facility.workingTime),
                });
            } else {
                const facilityData = {
                    ...facility,
                    phones: facility.phone ? [facility.phone] : [],
                    address: facility.addressDetail?.unrestricted_value || "",
                    geoData: facility.coordinates ? facility.coordinates.split(",").map((v) => +v) : [],
                    workingTime: getTimetableData(facility.workingTime),
                    isAvailableForGuide: false,
                };
                delete facilityData.phone;
                delete facilityData.addressDetail;
                delete facilityData.coordinates;

                newCmsFacilityId = (await createFacility(facilityData)).cmsFacilityId;
                await sendFacilityForApproval(newCmsFacilityId);
            }
        }

        try {
            await updateService({
                ...data,
                id: data.id,
                duration: {
                    hours,
                    minutes,
                },
                region: facility.region || "",
                city: facility.city?.[0] || "",
                facility: newCmsFacilityId || facility.cmsFacilityId || "",
                ticketPrice,
                images: data.servicePhotoList?.length ? data.servicePhotoList.map((image) => image.id).slice(1) : [],
                imageExplorePreview: data.servicePhotoList?.length ? [data.servicePhotoList[0].id] : [],
                imageDetailedPageMain: data.servicePhotoList?.length ? [data.servicePhotoList[0].id] : [],
                seasonStart: data.seasonUnlimited
                    ? moment(new Date()).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
                    : data.seasonStart,
                seasonEnd: data.seasonUnlimited
                    ? moment(DATE_2061).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
                    : data.seasonEnd,
                sendToBilling: sendToBilling,
                sendToCms: sendToCms_,
                patchMode: sendToCms_,
            });

            reloadData();

            setEditMode(false);

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Success,
                ...(sendToCms_
                    ? {
                          title: `Услуга «${data.title}» отправлена на модерацию.`,
                          desc: (
                              <>
                                  В течение 5 рабочих дней вы получите ответ на{" "}
                                  <span className="footnote">{userFullInfo.email}</span>
                              </>
                          ),
                      }
                    : { title: `Услуга «${data.title}» сохранена.`, desc: "Изменения опубликованы на сайте" }),
                closeBtnAction: () => history.push(routes.servicesAll),
            });
        } catch {
            openEventStatusModal({
                type: CreateEventResultStatusEnum.Fail,
                ...(sendToCms_
                    ? {
                          title: `Не удалось отправить на модерацию «${data.title}».`,
                          desc: StatusRequestCardSubtitleError,
                      }
                    : { desc: StatusRequestCardSubtitleError, title: `Не удалось сохранить услугу ${data.title}` }),
                errorBtnAction: () => sendServiceUpdate(sendToBilling, sendToCms_),
                closeBtnAction: () => history.push(routes.servicesAll),
            });
        } finally {
            setIsFormSending(false);
        }
    };

    const addTariffForExistingService = useCallback(
        async (newTariffs) => {
            setIsFormSending(true);

            if (userFullInfo && userFullInfo.email && newTariffs.length > 0 && data.billingProductId) {
                try {
                    setIsFormSending(true);
                    const createdTariffs = await createTariffs(newTariffs, +data.billingProductId);
                    onChangeTariff([...tariffs, ...createdTariffs]);
                    callNotification({
                        status: StatusRequestEnum.Success,
                        body: "Новый тариф отправлен",
                    });
                } catch (error) {
                    console.error(error);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR,
                    });
                } finally {
                    setIsFormSending(false);
                }
            }
        },
        [userFullInfo, data, tariffs]
    );

    const deleteTariffForExistingService = useCallback(
        async (tariff) => {
            if (userFullInfo && userFullInfo.email) {
                setIsFormSending(true);
                try {
                    await updateTariff(processingForUpdateTariff({ tariff, isDelete: true }), tariff.id);
                    setIsFormSending(false);
                    callNotification({
                        status: StatusRequestEnum.Success,
                        body: "Тариф удален",
                    });
                } catch (error) {
                    console.error(error);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR,
                    });
                }
            }
        },
        [userFullInfo, data]
    );

    const updateTariffForExistingService = async (newTariff: Tariff, showNotification = true) => {
        setIsFormSending(true);

        if (userFullInfo && userFullInfo.email && newTariff.id) {
            setIsFormSending(true);
            try {
                await updateTariff(processingForUpdateTariff({ tariff: newTariff, isDelete: false }), newTariff.id);
                setIsFormSending(false);
                if (showNotification) {
                    callNotification({
                        status: StatusRequestEnum.Success,
                        body: "Обновленный тариф отправлен",
                    });
                }
            } catch (error) {
                console.error(error);
                callNotification({
                    status: StatusRequestEnum.Error,
                    body: POST_DATA_ERROR,
                });
            }
        }
    };

    //функция только для апдейта базовой цены/онлайн продажи
    const updateServiceFields = async (updateFields: { [x in keyof ServiceDataPatch]?: any }) => {
        if (!initialData) return;

        setIsFormSending(true);

        const additionalFields: Pick<
            ServiceDataPatch,
            | "personalizedTickets"
            | "imageDetailedPageMain"
            | "ndsPercent"
            | "ticketPrice"
            | "sendToBilling"
            | "sendToCms"
            | "patchMode"
        > = {
            personalizedTickets: initialData.personalizedTickets ?? false, // если значение не указано - заполняем false
            // TODO какие-то разногласия с бэком разобраться
            //1. почему может приходить пустой imageDetailedPageMain, но просит обязательно что бы было там
            //2. ndsPercents -> ndsPercent
            imageDetailedPageMain:
                data.imageDetailedPageMain.length > 0 ? data.imageDetailedPageMain : [data.servicePhotoList[0].id],
            ndsPercent: initialData?.ndsPercents || "",
            ticketPrice: tariffs.find((i) => i.isBasic)?.itemPrices[0].price || "",
            //это стандартные поля
            sendToBilling: false,
            sendToCms: true,
            patchMode: false,
            ...updateFields,
        };

        try {
            await updateService({
                ...initialData,
                ...additionalFields,
            });

            if (updateFields.hasOwnProperty("isCanBuy")) {
                callNotification({
                    status: StatusRequestEnum.Success,
                    body: data.isCanBuy
                        ? "Продажа на RUSSPASS отключена. Пользователи сайта смогут только ознакомиться с информацией"
                        : "Продажа на RUSSPASS включена",
                });
            }

            onChangeData({
                ...data,
                ...updateFields,
            });
        } catch (err) {
            console.error(err);

            callNotification({
                status: StatusRequestEnum.Error,
                body: POST_DATA_ERROR,
            });
        } finally {
            setIsFormSending(false);
        }

        setIsFormSending(false);
    };

    return {
        sendServiceToModerate,
        sendServiceUpdate,
        isValidFacilityWorkingTime,
        isFormSending,
        navbar,
        isSubmittingForm,
        addTariffForExistingService,
        deleteTariffForExistingService,
        updateTariffForExistingService,
        updateServiceFields,
    };
};
