import { object as objectYup, string as stringYup } from "yup";
import { FORMAT_ERROR, FORM_FIELD_REQUIRED_ERROR } from "../../../constants/errors";
import { onlyNumbersRegexp } from "../../../utils/validation";

export const validationForm = objectYup().shape({
    systemType: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    id: stringYup()
        .matches(onlyNumbersRegexp, {
            message: FORMAT_ERROR,
        })
        .required(FORM_FIELD_REQUIRED_ERROR),
    title: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    bankDetails: objectYup({
        bic: stringYup()
            .matches(onlyNumbersRegexp, {
                message: FORMAT_ERROR,
            })
            .test("test1", FORMAT_ERROR, function (value) {
                if (value) {
                    return value.length === 9;
                }
                return true;
            })
            .required(FORM_FIELD_REQUIRED_ERROR),
        name: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
        corrAccount: stringYup()
            .matches(onlyNumbersRegexp, {
                message: FORMAT_ERROR,
            })
            .test("test1", FORMAT_ERROR, function (value) {
                if (value) {
                    return value.length === 20;
                }
                return true;
            })
            .required(FORM_FIELD_REQUIRED_ERROR),
        checkingAccount: stringYup()
            .matches(onlyNumbersRegexp, {
                message: FORMAT_ERROR,
            })
            .test("test1", FORMAT_ERROR, function (value) {
                if (value) {
                    return value.length === 20;
                }
                return true;
            })
            .required(FORM_FIELD_REQUIRED_ERROR),
    }),
});
