import { RentHousesBooking } from "../../../../../types/rentHouses";
import { parseModalTitle } from "../../helpers";
import React, { useCallback, useState } from "react";
import { Form, Formik } from "formik";
import { supportSchema } from "../validation";
import { Header, HeaderInfo, HeaderSubtitle, HeaderTitle } from "../components/Header";
import styles from "./SupportModal.module.sass";
import { Container } from "../components/Container";
import { ActionButtonWrapper, ModalWindowRight, StatusRequestEnum, TextareaField } from "@russpass/partner-front-ui";
import { UploadField } from "./components/UploadField";
import { SupportTypesField } from "./components/SupportTypesField";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { POST_DATA_ERROR2 } from "../../../../../constants/errors";
import { createRentHousesSupportAppeal } from "../../../../../api/rentHousesSupport";
import { closeBookingModal } from "../utils/closeBookingModal";
import { RentHousesSupportAppealCode } from "../../../../../types/rentHousesSupport";
import getCustomErrorMessage from "../../../../../utils/getCustomErrorMessage";

type Props = { booking: RentHousesBooking; close: () => void };

const FORM_ID = "SUPPORT_BOOKING";

type Values = {
    type?: string;
    reason?: string;
    files?: any[];
};

export const SupportModal = ({ booking, close }: Props) => {
    const subtitle = parseModalTitle(booking);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const submit = useCallback(
        async (values: Values) => {
            try {
                setIsSubmitting(true);
                await createRentHousesSupportAppeal({
                    appealCode: RentHousesSupportAppealCode.BOOKING_TROUBLE,
                    subject: "Проблема с бронированием",
                    reason: values.type,
                    text: values.reason || "",
                    bookingId: booking.id,
                    files: values.files,
                });
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: "Поддержка свяжется с вами для выяснения ситуации",
                });
                closeBookingModal();
            } catch (e) {
                console.error(e);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: getCustomErrorMessage(e, POST_DATA_ERROR2),
                });
            } finally {
                setIsSubmitting(false);
            }
        },
        [booking.id]
    );

    return (
        <Formik<Values>
            initialValues={{ type: "", reason: "", files: [] }}
            enableReinitialize
            validateOnMount
            validationSchema={supportSchema}
            onSubmit={submit}
        >
            <ModalWindowRight
                isOpened
                isLoadingWithContent={isSubmitting}
                onClose={close}
                headerComponent={
                    <Header className={styles.Header}>
                        <HeaderInfo>
                            <HeaderTitle>Обращение в поддержку</HeaderTitle>
                            <HeaderSubtitle>{subtitle}</HeaderSubtitle>
                        </HeaderInfo>
                    </Header>
                }
                ComponentFooter={
                    <ActionButtonWrapper>
                        <button type="submit" form={FORM_ID} className="button button--primary button--full-width">
                            Написать в Поддержку
                        </button>
                    </ActionButtonWrapper>
                }
            >
                <Container className={styles.Container}>
                    <Form className={styles.Form} id={FORM_ID}>
                        <SupportTypesField name="type" bookingStatus={booking.status} />
                        <TextareaField
                            label="Причина обращения"
                            name="reason"
                            noHover
                            placeholder="Расскажите, что случилось"
                            className={styles.Reason}
                        />
                        <UploadField name="files" />
                    </Form>
                </Container>
            </ModalWindowRight>
        </Formik>
    );
};
