import { Form, useFormikContext } from "formik";
import styles from "./styles.module.sass";
import { InputField, CheckboxField, SwitchField, MultiCheckedField } from "@russpass/partner-front-ui";
import { ReactComponent as Trash } from "../../../../../assets/images/icons/ic_trash.svg";
import { ReactComponent as Plus } from "../../../../../assets/images/icons/ic_plus.svg";
import React, { useEffect } from "react";
import { maskedInputNumbers } from "../../../../../utils/validation";
import { FormNotificationTemplate } from "../../hooks/useEditNotifications";
import useHotelOptions from "../../hooks/useHotelOptions";
import classNames from "classnames";
import { useEditNotificationsContext } from "../../context/useEditNotificationsContext";

const EditNotificationsForm = () => {
    const { data, isNew, isOpen } = useEditNotificationsContext();

    const { values, setFieldValue, resetForm } = useFormikContext<{ templates: FormNotificationTemplate[] }>();

    const hotelOptions = useHotelOptions(data?.cmsHotels || [], !isNew || !isOpen);

    useEffect(() => {
        return () => resetForm();
    }, [resetForm]);

    if (!data) return null;

    return (
        <Form className={styles.form}>
            {values.templates.map((el: FormNotificationTemplate, idx: number) => {
                const baseName = `templates.${idx}`;

                return (
                    <div className={styles.subform} key={el.templateId}>
                        <div className="section mb-8" data-qa-name="notifications-edit-title">
                            {el.title}
                        </div>
                        <div className={styles.description} data-qa-name="notifications-edit-description">
                            {el.description}
                        </div>
                        <SwitchField name={`${baseName}.active`} label="Получать об этом уведомления" />
                        {el.active && (
                            <>
                                <div className={styles.description}>
                                    Все уведомления будут в личном кабинете.{" "}
                                    {data?.hasEmailChannel && "Также вы можете получать их на электронную почту"}
                                </div>
                                {data?.hasEmailChannel && (
                                    <div className={styles.field}>
                                        <CheckboxField
                                            name={`${baseName}.emailEnabled`}
                                            label="Получать на почту"
                                            classNameModifier="checkbox-group--items-start"
                                            showErrorText
                                        />
                                    </div>
                                )}

                                {data?.hasEmailChannel && el.emailEnabled && (
                                    <>
                                        <div className={styles.field}>
                                            <div className={styles.email}>
                                                <InputField
                                                    label="E-mail"
                                                    name={`${baseName}.email`}
                                                    noHover
                                                    placeholder="name@mail.ru"
                                                />
                                                {el.reserveEmail && (
                                                    <span>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    `templates.${idx}.email`,
                                                                    el.reserveEmail
                                                                );
                                                                setFieldValue(
                                                                    `templates.${idx}.reserveEmail`,
                                                                    undefined
                                                                );
                                                            }}
                                                            data-qa-name="notifications-edit-email-trash"
                                                        >
                                                            <Trash />
                                                        </button>
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        {el.reserveEmail != null ? (
                                            <div className={styles.field}>
                                                <div className={classNames(styles.email, styles.reserve_email)}>
                                                    <InputField
                                                        label="E-mail"
                                                        name={`${baseName}.reserveEmail`}
                                                        noHover
                                                        placeholder="name@mail.ru"
                                                    />
                                                    <span>
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    `templates.${idx}.reserveEmail`,
                                                                    undefined
                                                                )
                                                            }
                                                            data-qa-name="notifications-edit-email-trash"
                                                        >
                                                            <Trash />
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button
                                                type="button"
                                                onClick={() => setFieldValue(`${baseName}.reserveEmail`, "")}
                                                className={styles.add_email}
                                            >
                                                <Plus /> Добавить еще почту
                                            </button>
                                        )}
                                    </>
                                )}

                                {isNew && (
                                    <div className={styles.field}>
                                        <MultiCheckedField
                                            name={`${baseName}.hotels`}
                                            label="Объект"
                                            placeholder="Выбрать"
                                            value={el.hotels || []}
                                            noHover
                                            options={hotelOptions}
                                            valueField="id"
                                            labelValueField="label"
                                            onChange={(value) => setFieldValue(`templates.${idx}.hotels`, value || [])}
                                            noOptionsText="Подходящих объектов не найдено"
                                        />
                                    </div>
                                )}

                                <div className={styles.field}>
                                    <InputField
                                        label="Количество поисковых выдач"
                                        name={`${baseName}.threshold`}
                                        noHover
                                        placeholder="10"
                                        hint={el.hint}
                                        renderComponent={maskedInputNumbers}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                );
            })}
        </Form>
    );
};

export default EditNotificationsForm;
