import React, { useMemo } from "react";
import { CommonLayout, Card, CardsGrid } from "@russpass/partner-front-ui";
import history from "../../../history";
import routes from "../../../constants/routes";
import { addServicePages, addTour } from "./pages";
import { cloneDeep } from "lodash";
import { isAvailableTours } from "../../../constants/feature-toggles";

const cardStyles = {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 12px 16px rgba(0, 0, 0, 0.04)",
    border: "none",
    padding: "24px",
};

const AddServicePage = () => {
    const getMenu = () => {
        const menu = cloneDeep(addServicePages);
        if (isAvailableTours) {
            menu.push(addTour);
        }
        return menu;
    };

    const backButton = useMemo(
        () => ({
            title: "Назад",
            onClick: () => history.push(routes.servicesAll),
        }),
        []
    );

    return (
        <CommonLayout pageTitle="Услуги" backButton={backButton}>
            <CardsGrid>
                {getMenu().map(({ title, desc, qaName, onClickCard }) => (
                    <Card
                        key={title}
                        title={title}
                        desc={desc}
                        onClick={onClickCard}
                        styles={cardStyles}
                        qaName={qaName}
                    />
                ))}
            </CardsGrid>
        </CommonLayout>
    );
};

export default AddServicePage;
