import React, { useCallback } from "react";

import { StatusRequestCard } from "@russpass/partner-front-ui";
import SupportInfo from "../supportInfo";
import DefaultForm from "./defaultForm";
import { DefaultFormData } from "./defaultForm/types/types";
import { prepareDefaultValues } from "./helper";
import useContactingSupport from "./useContactingSupport";
import { sendEvent } from "../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../product_analytics/constants";

import { COMMON_ERROR } from "../../../../constants/errors";
import styles from "./contactingSupport.module.sass";

const ContactingSupport: React.FC = () => {
    const { profile, isLoading, submitForm, handleToggleStatus, status, email, isViewStatus, handleRepeatSubmit } =
        useContactingSupport();

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);

    return (
        <>
            {isViewStatus ? (
                <div className={styles.contacting_support__status}>
                    <StatusRequestCard
                        title={{
                            success: "Ваше обращение принято",
                            error: "Ошибка",
                        }}
                        subtitle={{
                            success: `Мы ответим на почту ${email}`,
                            error: COMMON_ERROR,
                        }}
                        handleClose={handleToggleStatus}
                        status={status}
                        handleRepeat={handleRepeatSubmit}
                        isLoading={isLoading}
                        handleBeforeClose={handleSendEvent}
                    />
                </div>
            ) : (
                <>
                    <SupportInfo />
                    <div className={styles.contacting_support}>
                        <div className={styles.contacting_support__title}>Обращение в службу поддержки</div>
                        <DefaultForm
                            submitForm={(values: DefaultFormData) => submitForm(prepareDefaultValues(values))}
                            profile={profile}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default ContactingSupport;
