import { ApartmentsNewStep } from "./useApartmentsNew";
import { NavbarApartmentsNewName } from "../constants";
import { useEffect, useState } from "react";
import { useFormik, useFormikContext } from "formik";
import { getAddObjectSchema } from "../validationSchema";

const LAST_STEP = NavbarApartmentsNewName.SALE_AND_CANCEL;

export const useFullFormValidation = (step: ApartmentsNewStep, isNew: boolean, isDirty: boolean) => {
    const { values, isValidating, setErrors } = useFormikContext();

    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        if (isValidating && ((!isNew && isDirty) || step === LAST_STEP)) {
            setEnabled(true);
        }
    }, [step, isValidating, isNew, isDirty]);

    const { errors, isValid } = useFormik({
        // @ts-ignore
        initialValues: values,
        validationSchema: getAddObjectSchema(false),
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
    });

    useEffect(() => {
        if (!enabled) return;
        setErrors(errors);
    }, [errors, enabled, setErrors]);

    return { enabled, errors: enabled ? errors : undefined, isValid };
};
