import { ActiveFilterCategory, Select } from "@russpass/partner-front-ui";
import styles from "./filter-type-services.module.sass";
import { useCallback } from "react";

type Props = {
    filterOptions?: ActiveFilterCategory[];
    onClickFilter?: (value: any) => void;
    defaultFilterValue?: ActiveFilterCategory;
};

const FilterTypeServices = ({ filterOptions, onClickFilter, defaultFilterValue }: Props) => {
    const handleChange = useCallback(
        (value) => {
            onClickFilter && onClickFilter(value);
        },
        [onClickFilter]
    );

    return (
        <>
            {filterOptions && onClickFilter && (
                <div className={styles.filter}>
                    <Select
                        handleChange={handleChange}
                        options={filterOptions}
                        labelField="label"
                        valueField="name"
                        defaultValue={defaultFilterValue}
                        isChecked={true}
                        value=""
                        isFilter={true}
                    />
                </div>
            )}
        </>
    );
};

export default FilterTypeServices;
