import React, { useCallback, useMemo } from "react";
import { maskedInputTimePeriod } from "../../../utils/validation";
import { FieldProps, Field } from "formik";
import { InputField, MultiCheckedField } from "@russpass/partner-front-ui";
import DateControl from "./date-control/date-control";
import { DayTimeTable, PlaceTimetableItemType, TKeysTabs } from "./types";
import { Modifier } from "react-day-picker";
import { getFieldError } from "../../../utils/form";
import { timetableOptionsFull, weekDays } from "./constants";
import classNames from "classnames";
import { getDateControlArray, getStringArray } from "./utils";
import { Divider } from "@russpass/partner-front-ui";

type Props = {
    data: PlaceTimetableItemType;
    handleChange: (values: PlaceTimetableItemType) => void;
    errorSettings?: {
        showOnChange: boolean;
    };
    disabledOptions?: (string | Modifier | DayTimeTable)[];
    isExistingTariff?: boolean;
    isWorkingMode?: boolean;
    isShowBreakTime?: boolean;
    modalTitle?: string;
    disabled?: boolean;
    index: number;
    isVisiblePlaceTimetable?: boolean;
    isRenderValueWithPronoun?: boolean;
    keysVisibleTabs?: TKeysTabs;
    divider: boolean;
};

const PlaceTimetableItem = ({
    data,
    handleChange,
    errorSettings,
    disabledOptions,
    isExistingTariff,
    isWorkingMode,
    isShowBreakTime,
    modalTitle,
    disabled,
    index,
    isVisiblePlaceTimetable = true,
    isRenderValueWithPronoun = false,
    keysVisibleTabs,
    divider,
}: Props) => {
    const isTimeFilled = useMemo(() => data.time[0]?.length !== 13, [data.time]);

    const handleChangeWorkingDays = useCallback(
        (values: any) =>
            handleChange({
                ...data,
                workingDays: [...values],
            }),
        [handleChange, data]
    );

    const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>, key: number) => {
        const value = e.target.value;
        let isEmptyTime = false;
        let newTime = data.time.map((time, index) => {
            if (key === index) {
                time = value;
            }
            if (time.length === 0) {
                isEmptyTime = true;
            }
            return time;
        });
        if (value.length === 0) {
            newTime = newTime.filter((item) => item.length !== 0);
        }
        if ((!isWorkingMode && value.length === 13 && !isEmptyTime && !isExistingTariff) || value.length === 0) {
            newTime.push("");
        }
        handleChange({
            ...data,
            time: newTime,
        });
    };

    const handleChangeBreakTime = (e: React.ChangeEvent<HTMLInputElement>) =>
        handleChange({
            ...data,
            breakTime: [e.target.value],
        });

    const name = `workingTime[${index}].workingDays`;
    return (
        <>
            <div className="place-timetable__working_mode_row">
                {isWorkingMode ? (
                    <MultiCheckedField
                        name={name}
                        modalTitle={modalTitle || ""}
                        placeholder="Выбрать дни"
                        labelValueField="shortName"
                        value={data.workingDays}
                        options={timetableOptionsFull}
                        disabledOptions={disabledOptions}
                        allCheckOption={weekDays.allDays}
                        onChange={handleChangeWorkingDays}
                        errorSettings={errorSettings}
                        noHover
                        disabled={disabled}
                        isDisabledAllCheckedOption
                    />
                ) : (
                    <Field name={name}>
                        {(fieldProps: FieldProps) => (
                            <DateControl
                                name={name}
                                isRenderValueWithPronoun={isRenderValueWithPronoun}
                                keysVisibleTabs={keysVisibleTabs}
                                value={getDateControlArray(data.workingDays)}
                                disabledOptions={getStringArray(disabledOptions)}
                                handlerChange={handleChangeWorkingDays}
                                error={getFieldError(fieldProps, errorSettings)}
                                isExistingTariff={isExistingTariff}
                                isWorkingMode={isWorkingMode}
                                disabled={disabled}
                            />
                        )}
                    </Field>
                )}
            </div>
            {isVisiblePlaceTimetable && (
                <div className={classNames("place-timetable__row", isWorkingMode && "place-timetable__row__splitted")}>
                    {data.time.map((_, key) => (
                        <div key={key}>
                            <InputField
                                label={isWorkingMode ? "Время" : "Время (Сеанс)"}
                                name={`workingTime[${index}].time[${key}]`}
                                disabled={!data.workingDays.length || disabled}
                                placeholder="--:-- – --:--"
                                onChange={(e) => handleChangeTime(e, key)}
                                renderComponent={maskedInputTimePeriod}
                                errorSettings={errorSettings}
                                noHover
                            />
                        </div>
                    ))}
                    {isShowBreakTime && (
                        <InputField
                            label="Перерыв"
                            name={`workingTime[${index}].breakTime[0]`}
                            disabled={isTimeFilled || disabled}
                            placeholder="Без перерыва"
                            renderComponent={maskedInputTimePeriod}
                            errorSettings={errorSettings}
                            noHover
                            onChange={handleChangeBreakTime}
                        />
                    )}
                </div>
            )}
            {divider && <Divider />}
        </>
    );
};

export default PlaceTimetableItem;
