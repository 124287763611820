import { ReactComponent as SearchIcon } from "../../../../assets/images/icons/search.svg";
import { SearchInput } from "@russpass/partner-front-ui";
import styles from "./orders-filters.module.sass";
import React from "react";
import { useOrderFiltersContext } from "../../context/useOrderFiltersContext";
import { maskedInputNumbers } from "../../../../utils/validation";

const OrderNumberFilter = () => {
    const { changeFilter, filters } = useOrderFiltersContext();

    const handleSearch = (value: string | null) => {
        changeFilter({ id: value ?? "" });
    };

    return (
        <SearchInput
            renderComponent={maskedInputNumbers}
            currentValue={filters.id}
            placeholder="Номер заявки"
            handleSearch={handleSearch}
            isSingle
            className={styles.search_input}
            Icon={SearchIcon}
        />
    );
};

export default OrderNumberFilter;
