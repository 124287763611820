import { CommonLayout } from "@russpass/partner-front-ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import history from "../../../../../../history";
import { useLocation } from "react-router-dom";
import { Ratings } from "../../../../../services/components/ratings/ratings";
import useLoadData from "../../hooks/useLoadData";
import { RatingsMarksList } from "../../../../../services/components/ratings/types";
import useHotel from "../../../hotel-view/useHotel";

const HotelRatingsList = () => {
    const location = useLocation();
    const [ratingMarks, setRatingMarks] = useState<RatingsMarksList | undefined>(undefined);

    const [isLoading, setIsLoading] = useState(false);

    const { hotelInfoData, loadHotelInfoData } = useHotel({});

    const { onLoadRatings } = useLoadData();

    const loadData = useCallback(async () => {
        setIsLoading(true);
        try {
            const hotelData = await loadHotelInfoData();
            const ratingsData = await onLoadRatings(hotelData?.cmsId);
            setRatingMarks(ratingsData);
        } finally {
            setIsLoading(false);
        }
    }, [loadHotelInfoData, onLoadRatings]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const backButton = useMemo(
        () => ({
            title: "Назад",
            onClick: () => history.push(location.pathname.replace("/ratings", "")),
        }),
        [location.pathname]
    );

    return (
        <>
            <CommonLayout
                pageTitle="Отзывы"
                backButton={backButton}
                isLoadingWithoutContent={isLoading}
                description={hotelInfoData?.title}
                fullHeight
            >
                <div>{hotelInfoData?.cmsId && ratingMarks ? <Ratings id={hotelInfoData.cmsId} /> : null}</div>
            </CommonLayout>
        </>
    );
};

export default HotelRatingsList;
