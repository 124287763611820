import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as CancelIcon } from "../../../../../../../../assets/images/icons/ic_arrow_light_left.svg";
import modalMenuStyles from "../../../../../../../../components/form-fields/search-select-field/components/ModalMenu.module.sass";
import { ModalWindowRight, SelectWithoutOptionsField, RadioButton } from "@russpass/partner-front-ui";
import styles from "./NewRoomModalSelectField.module.sass";

export type SearchSelectFieldProps = {
    name: string;
    label: string;
    options: { value: string; label: string }[];
    placeholder?: string;
    disabled?: boolean;
};

const NewRoomModalSelectField = ({
    name,
    label,
    placeholder = "Выбрать",
    options,
    disabled,
}: SearchSelectFieldProps) => {
    const { getFieldProps, setFieldValue } = useFormikContext();
    const value = getFieldProps(name).value;

    const [isOpen, setIsOpen] = useState(false);
    const [tempValue, setTempValue] = useState(value);

    const save = useCallback(() => {
        setFieldValue(name, tempValue);
        setIsOpen(false);
    }, [name, setFieldValue, tempValue]);

    const activeOption = options.find((el) => el.value === value);

    useEffect(() => {
        setTempValue(value);
    }, [value]);

    return (
        <div className={styles.NewRoomModalSelectField}>
            <SelectWithoutOptionsField
                name={name}
                handleClick={() => {
                    setIsOpen(true);
                }}
                label={label}
                placeholder={placeholder}
                noHover
                arrowType="bottom"
                value={activeOption?.label || ""}
                disabled={disabled}
            />
            <ModalWindowRight
                isOpened={isOpen}
                onClose={() => setIsOpen(false)}
                isHideCloseButton
                ComponentFooter={
                    <div className={modalMenuStyles.Footer}>
                        <button className="button button--primary" onClick={save}>
                            Сохранить
                        </button>
                    </div>
                }
            >
                <div className={modalMenuStyles.Header}>
                    <button
                        onClick={() => setIsOpen(false)}
                        className={classNames("button", modalMenuStyles.CloseButton)}
                    >
                        <CancelIcon className="button__icon" />
                    </button>
                    {label}
                </div>
                <div>
                    {options.map((option) => (
                        <RadioButton
                            key={option.value}
                            label={option.label}
                            value={option.value}
                            isActive={option.value === tempValue}
                            handleClick={() => setTempValue(option.value)}
                        />
                    ))}
                </div>
            </ModalWindowRight>
        </div>
    );
};

export default NewRoomModalSelectField;
