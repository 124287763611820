import { forwardRef, PropsWithChildren, UIEventHandler } from "react";
import { Loader } from "@russpass/partner-front-ui";
import styles from "./styles.module.sass";
import classNames from "classnames";

type Props = PropsWithChildren<{
    scrollId?: string;
    className?: string;
    isLoading?: boolean;
    onScroll?: UIEventHandler<HTMLDivElement>;
}>;

const MessagesContainer = forwardRef<HTMLDivElement, Props>(
    ({ children, scrollId, className, isLoading, onScroll }, ref) => (
        <div ref={ref} className={classNames(styles.container, className)}>
            <div className={styles.inner} id={scrollId} onScroll={onScroll}>
                {isLoading && (
                    <div className={styles.loader}>
                        <Loader width="48px" />
                    </div>
                )}
                <div
                    className={classNames(styles.messages, {
                        [styles.loading]: isLoading,
                    })}
                >
                    {children}
                </div>
            </div>
        </div>
    )
);

export default MessagesContainer;
