import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getMealsServices } from "../../../../../api/dictionaries";
import { GET_DATA_ERROR, POST_DATA_ERROR, POST_DATA_ERROR_SUPPORT } from "../../../../../constants/errors";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { hotelTariffData as hotelTariffDataDefault } from "../constants/hotel-tariff";
import { mappingTariffDataForRequest } from "../utils/hotel-tariffs";
import { HotelTariffData, HotelTariffDataForRequest, OptionTypeFood } from "../types/hotel-tariff";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { createTariffHotels, getHotelTariffInfo, deleteTariff } from "../../../../../api/hotels/tariffs";
import { TARIFF_DELETED } from "../components/hotel-tariffs-list/constants";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";
import useUserInfo from "../../../../../hooks/useUserInfo";

type Props = {
    isPreloadDelay?: boolean;
};
const useControlHotelTariffData = ({ isPreloadDelay }: Props) => {
    // @ts-ignore
    const { hotelId } = useParams();
    const { userFullInfo } = useUserInfo();

    const [isLoadingTariff, setIsLoadingTariff] = useState(false);
    const [isLoadedContent, setIsLoadedContent] = useState(false);
    const [isViewHotelTariff, setIsViewHotelTariff] = useState<boolean>(false);

    const [hotelTariffData, setHotelTariffData] = useState<HotelTariffData>(cloneDeep(hotelTariffDataDefault));

    const [optionsTypeFood, setOptionsTypeFood] = useState<OptionTypeFood[]>([]);

    const [statusHotelTariff, setStatusHotelTariff] = useState<StatusRequestEnum | null>(null);

    const isDisabledTariff = useMemo(() => {
        return !!hotelTariffData.id;
    }, [hotelTariffData]);

    const loadData = useCallback(async () => {
        if (!isLoadedContent) {
            try {
                const content = await getMealsServices();
                setOptionsTypeFood(content.rows);
            } catch (error) {
                console.error(error);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: GET_DATA_ERROR,
                });
            } finally {
                setIsLoadedContent(true);
            }
        }
    }, [isLoadedContent]);

    useEffect(() => {
        if (isPreloadDelay) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreloadDelay]);

    const onSaveHotelTariffData = useCallback(
        async (tariff: HotelTariffData) => {
            return new Promise<string>(async (resolve, reject) => {
                setHotelTariffData(tariff);

                if (userFullInfo?.supplierId && hotelId) {
                    try {
                        setIsLoadingTariff(true);
                        const valuesForRequest: HotelTariffDataForRequest = mappingTariffDataForRequest(
                            tariff,
                            userFullInfo.supplierId
                        );
                        await createTariffHotels(hotelId, valuesForRequest);
                        resolve(StatusRequestEnum.Success);
                    } catch (error) {
                        console.error(error);
                        eventDispatcher.setNotification({
                            status: StatusRequestEnum.Error,
                            body: POST_DATA_ERROR_SUPPORT,
                        });
                        reject(StatusRequestEnum.Error);
                    } finally {
                        setIsLoadingTariff(false);
                        setHotelTariffData(cloneDeep(hotelTariffDataDefault));
                    }
                }
            });
        },
        [hotelId, userFullInfo?.supplierId]
    );

    const onGetTariff = useCallback(
        async (tariffId: string) => {
            let tariff: HotelTariffData = cloneDeep(hotelTariffDataDefault);
            if (tariffId) {
                try {
                    tariff = (await getHotelTariffInfo(hotelId, tariffId)) as HotelTariffData;
                } catch (error) {
                    console.error(error);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR,
                    });
                }
            }
            return tariff;
        },
        [hotelId]
    );

    const onSelectTariff = useCallback(
        async (value?: HotelTariffData) => {
            setIsLoadingTariff(true);
            await loadData();

            let tariff: HotelTariffData;

            if (value) {
                tariff = value;
            } else {
                tariff = cloneDeep(hotelTariffDataDefault);
                sendEvent(AnalyticEvent.add_another_tariff);
            }

            setIsViewHotelTariff(true);
            setHotelTariffData(tariff);
            setIsLoadingTariff(false);
        },
        [loadData]
    );

    const onCloseTariffWindow = () => {
        setIsViewHotelTariff(false);
    };

    const onDeleteTariff = useCallback(
        async (tariffId: string) => {
            try {
                const content = await deleteTariff(hotelId, tariffId);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: TARIFF_DELETED,
                });
                return content;
            } catch (error) {
                console.error(error);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: POST_DATA_ERROR,
                });
            }
        },
        [hotelId]
    );

    return {
        hotelTariffData,
        onSaveHotelTariffData,
        optionsTypeFood,
        isLoadingTariff,
        onDeleteTariff,
        statusHotelTariff,
        onSelectTariff,
        setStatusHotelTariff,
        isDisabledTariff,
        isViewHotelTariff,
        onCloseTariffWindow,
        onGetTariff,
    };
};

export default useControlHotelTariffData;
