import { FC } from "react";
import EditNotificationsModal from "../components/modal";
import useEditNotifications from "../hooks/useEditNotifications";
import { EditNotificationsContext } from "./EditNotificationsContext";

export const EditNotificationsProvider: FC = ({ children }) => (
    <EditNotificationsContext.Provider value={useEditNotifications()}>
        <EditNotificationsModal />
        {children}
    </EditNotificationsContext.Provider>
);
