import styles from "./styles.module.sass";
import { useEffect, useRef, useState } from "react";
import KeycloakManager from "../../lib/keycloak-manager";
import classNames from "classnames";
import { Loader } from "@russpass/partner-front-ui";
import useUserInfo from "../../hooks/useUserInfo";

type MosruIframeProps = {
    url: string;
    title?: string;
    className?: string;
};

const FRAME_MESSAGE_NAME = "goodt/post-keycloak-init-config";

const MosruIframe = ({ url, title, className }: MosruIframeProps) => {
    const [loading, setLoading] = useState(true);

    const mosruBiFrame = useRef(null);

    const { userFullInfo } = useUserInfo();

    useEffect(() => {
        if (!userFullInfo) return;

        const access_token = KeycloakManager.getAccessToken();
        const refresh_token = KeycloakManager.getRefreshToken();

        // @ts-ignore
        window.onmessage = ({ name }) => {
            if (name !== FRAME_MESSAGE_NAME) {
                return;
            }
            // @ts-ignore
            mosruBiFrame?.current?.contentWindow?.postMessage(
                {
                    name: FRAME_MESSAGE_NAME,
                    details: { token: access_token, refreshToken: refresh_token },
                },
                window.location.origin
            );
        };
    }, [mosruBiFrame, userFullInfo]);

    return (
        <>
            {loading && (
                <div className={styles.loaderWrapper}>
                    <Loader width="48px" />
                </div>
            )}
            <iframe
                sandbox="allow-scripts allow-same-origin"
                onLoadStart={() => setLoading(true)}
                onLoad={() => setLoading(false)}
                onError={() => setLoading(false)}
                title={title}
                src={url}
                className={classNames(styles.frame, className)}
                ref={mosruBiFrame}
                referrerPolicy="no-referrer"
            />
        </>
    );
};

export default MosruIframe;
