import { Input } from "@russpass/partner-front-ui";
import { ReactComponent as DownloadIcon } from "../../../../../assets/images/icons/download.svg";
import { ReportItemType } from "../../types";

import styles from "./report-period.module.sass";
import useReportPeriod from "./useReportPeriod";
import { Tooltip } from "@russpass/partner-front-ui";
import { useCallback } from "react";
import { downloadMultipleFilesByUrl } from "../../../../../utils/download-multiple-files-by-url";

type ReportItemProps = {
    reportPeriod: ReportItemType;
};

const ReportPeriod: React.FC<ReportItemProps> = ({ reportPeriod }) => {
    const { period, sum, reservation, refund, commission } = useReportPeriod(reportPeriod);
    const onClick = useCallback(() => downloadMultipleFilesByUrl(reportPeriod.files), [reportPeriod.files]);
    return (
        <div className={styles.wrapper}>
            <div className={styles.reportRow} data-qa-name="data-item">
                <Input
                    label="Период"
                    noHover
                    inputProps={{
                        disabled: true,
                        value: period,
                        name: "report-period-period",
                    }}
                />
                <Input
                    label="Сумма"
                    noHover
                    inputProps={{
                        disabled: true,
                        value: sum,
                        name: "report-period-sum",
                    }}
                />
                <Input
                    label="Бронирования"
                    noHover
                    inputProps={{
                        disabled: true,
                        value: reservation,
                        name: "report-period-reservation",
                    }}
                />
                <Input
                    label="Возвраты"
                    noHover
                    inputProps={{
                        disabled: true,
                        value: refund,
                        name: "report-period-refund",
                    }}
                />
                <Input
                    label="Комиссия"
                    noHover
                    inputProps={{
                        disabled: true,
                        value: commission,
                        name: "report-period-comission",
                    }}
                />
                <Tooltip overlay="Скачать" offset={{ X: 85, Y: 1 }}>
                    <DownloadIcon onClick={onClick} data-qa-name="report-period-download" />
                </Tooltip>
            </div>
        </div>
    );
};

export default ReportPeriod;
