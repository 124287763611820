import { Form, useFormikContext } from "formik";
import React, { KeyboardEvent, useCallback, useEffect } from "react";
// @ts-ignore
import Files from "react-files";
import { ReactComponent as IconArrow } from "../../../../../../assets/images/icons/arrow-left.svg";
import { ReactComponent as IconClose } from "../../../../../../assets/images/icons/controls/ic_close_circle.svg";
import { ReactComponent as IconFile } from "../../../../../../assets/images/icons/ic_page.svg";
import { ReactComponent as SendIcon } from "../../../../../../assets/images/icons/ic_send.svg";
import { ReactComponent as PaperclipIcon } from "../../../../../../assets/images/icons/paperclip.svg";
import {
    ReactFilesFileWithUri,
    StatusRequestEnum,
    TextareaField,
    useViewportContext,
} from "@russpass/partner-front-ui";
import { MAX_FILES_SIZE_SUM } from "../../../../../../constants/validation";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { ReactFilesError } from "../../../../../../types/react-files";
import { convertToBytes } from "../../../../../../utils/format-bytes";
import getCustomErrorMessage from "../../../../../../utils/getCustomErrorMessage";
import { DialogueMessageFormModel } from "../../guest/DialogueModal";
import styles from "./SendMessageFormContent.module.sass";
import classNames from "classnames";

type DialogueModalFooterProps = {
    fileRef: React.MutableRefObject<any>;
    attachmentScroll: number;
    setAttachmentScroll: (attachmentScroll: number) => void;
    submitForm: () => void;
};

const NOTIFICATION_ERROR_MESSAGE = `Загрузите файлы jpeg, jpg, png или pdf объемом до ${MAX_FILES_SIZE_SUM} мб`;
const MAX_FILE_SIZE_BYTES = convertToBytes(MAX_FILES_SIZE_SUM);
const FILE_LIST_SCROLL_STEP = 192;

const SendMessageFormContent = ({
    fileRef,
    attachmentScroll,
    setAttachmentScroll,
    submitForm,
}: DialogueModalFooterProps) => {
    const { values, errors, setFieldTouched, setFieldValue, submitCount } =
        useFormikContext<DialogueMessageFormModel>();

    const scrollAttachment = useCallback(
        (formValues: any, value: number) => {
            const attachmentItemsLength = formValues.answerFiles?.length || 0;
            const maxAttachmentScroll = -(attachmentItemsLength - 3) * FILE_LIST_SCROLL_STEP;
            const newAttachmentScroll = attachmentScroll + value;
            if (newAttachmentScroll >= 0) {
                setAttachmentScroll(0);
            } else if (newAttachmentScroll <= maxAttachmentScroll) {
                setAttachmentScroll(maxAttachmentScroll);
            } else {
                setAttachmentScroll(newAttachmentScroll);
            }
        },
        [attachmentScroll, setAttachmentScroll]
    );

    const removeFile = useCallback(
        (file: ReactFilesFileWithUri, formValues: any) => {
            // @ts-ignore
            fileRef?.current.removeFile(file);
            scrollAttachment(formValues, FILE_LIST_SCROLL_STEP);
        },
        [fileRef, scrollAttachment]
    );

    const filesOnError = useCallback(
        (error: ReactFilesError) => {
            if (![1, 2, 3, 4].includes(error.code)) return;

            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: getCustomErrorMessage(error, NOTIFICATION_ERROR_MESSAGE),
            });
        },
        [eventDispatcher, getCustomErrorMessage]
    );

    useEffect(() => {
        if (!errors.answerFiles) return;

        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: NOTIFICATION_ERROR_MESSAGE,
        });
    }, [submitCount, errors]);

    const onSubmitEnter = useCallback(
        (event: KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key !== "Enter") return;

            event.preventDefault();
            submitForm();
        },
        [submitForm]
    );

    const { isMobile } = useViewportContext();

    return (
        <Form>
            <div className={styles.content}>
                <TextareaField
                    className={styles.textarea}
                    name="message"
                    noHover
                    maxRows={3}
                    placeholder={"Напишите сообщение"}
                    onKeyDown={onSubmitEnter}
                />
                <div className="file-upload__control">
                    <div className="file-upload__btn">
                        <Files
                            ref={fileRef}
                            name="answerFiles"
                            onChange={(files: ReactFilesFileWithUri[]) => {
                                setFieldTouched("answerFiles");
                                setFieldValue("answerFiles", files);
                            }}
                            onError={filesOnError}
                            multiple
                            maxFileSize={MAX_FILE_SIZE_BYTES}
                            minFileSize={1}
                            className="files-dropzone"
                            accepts={[".jpeg", ".jpg", ".png", ".pdf"]}
                        >
                            <button
                                type="button"
                                className={classNames("button button--one-icon button--square-sm", {
                                    "button--second": !isMobile,
                                })}
                            >
                                <PaperclipIcon className="button__icon" />
                            </button>
                        </Files>
                    </div>
                </div>
                <button
                    type="submit"
                    className={classNames(
                        "button button--primary button--one-icon button--square-sm",
                        styles.send_button
                    )}
                >
                    <SendIcon className="button__icon" />
                </button>
            </div>
            {!!values["answerFiles"].length && (
                <div className={styles.attachments_wrapper}>
                    <div className={styles.attachments}>
                        {values["answerFiles"].map((file: ReactFilesFileWithUri) => (
                            <div key={file.id} className={styles.attachments__file} style={{ left: attachmentScroll }}>
                                <div className={styles.attachments__file_prefix}>
                                    <IconFile />
                                </div>
                                <span>{file.name}</span>
                                <IconClose onClick={() => removeFile(file, values)} className="button__icon" />
                            </div>
                        ))}
                    </div>
                    {values["answerFiles"].length > 3 && (
                        <>
                            <button
                                onClick={() => scrollAttachment(values, FILE_LIST_SCROLL_STEP)}
                                type="button"
                                className={classNames("button__icon", styles.attachments__btn)}
                            >
                                <IconArrow />
                            </button>
                            <button
                                onClick={() => scrollAttachment(values, -FILE_LIST_SCROLL_STEP)}
                                type="button"
                                className={classNames(
                                    "button__icon",
                                    styles.attachments__btn,
                                    styles["attachments__btn--right"]
                                )}
                            >
                                <IconArrow />
                            </button>
                        </>
                    )}
                </div>
            )}
        </Form>
    );
};

export default SendMessageFormContent;
