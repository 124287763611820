import { isRatingV2 } from "../../../../constants/feature-toggles";

export enum NavbarServiceNamesEnum {
    "Service" = "service",
    "Place" = "location",
    "Photo" = "photos",
    "Tariff" = "rates",
    "Comment" = "comment",
    "Settings" = "settings",
    "Ratings" = "reviews",
    "Statistics" = "statistics",
}

export const navbarServiceLabels = {
    service: "Услуга",
    place: "Место проведения",
    photo: "Фото",
    tariff: "Тарифы",
    comment: "Комментарий модератора",
    settings: "Настройки",
    ratings: "Отзывы",
    // statistics: "Статистика",
};

export const navbarService = [
    {
        label: navbarServiceLabels.service,
        name: NavbarServiceNamesEnum.Service,
    },

    {
        label: navbarServiceLabels.place,
        name: NavbarServiceNamesEnum.Place,
    },
    {
        label: navbarServiceLabels.tariff,
        name: NavbarServiceNamesEnum.Tariff,
    },
    {
        label: navbarServiceLabels.photo,
        name: NavbarServiceNamesEnum.Photo,
    },
];

export const navbarServiceComment = [
    {
        label: navbarServiceLabels.comment,
        name: NavbarServiceNamesEnum.Comment,
    },
];

const navbarServiceRatingsBase = [
    {
        label: navbarServiceLabels.ratings,
        name: NavbarServiceNamesEnum.Ratings,
    },
];

export const navbarServiceRatings = isRatingV2
    ? [
          ...navbarServiceRatingsBase,
          // {
          //     label: navbarServiceLabels.statistics,
          //     name: NavbarServiceNamesEnum.Statistics,
          // },
      ]
    : navbarServiceRatingsBase;
