import { useCallback, useState } from "react";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { GET_DATA_ERROR } from "../../../../../constants/errors";
import { getHotelTypes } from "../../../../../api/dictionaries";
import { HotelType } from "../../../hotel-new/types";

const useHotelTypes = () => {
    const [isLoadingHotelTypes, setIsLoadingHotelTypes] = useState(false);
    const [isLoadedHotelTypes, setIsLoadedHotelTypes] = useState(false);
    const [hotelTypes, setHotelTypes] = useState<HotelType[]>([]);

    const onLoadHotelTypes = useCallback(async () => {
        if (isLoadedHotelTypes) {
            return;
        }
        setIsLoadingHotelTypes(true);
        try {
            const content = await getHotelTypes();
            setHotelTypes(content);
            setIsLoadedHotelTypes(true);
            return content;
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
            return {
                rooms: [],
            };
        } finally {
            setIsLoadingHotelTypes(false);
        }
    }, [isLoadedHotelTypes]);

    return {
        isLoadingHotelTypes,
        hotelTypes,
        onLoadHotelTypes,
    };
};

export default useHotelTypes;
