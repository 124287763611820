import { ReactComponent as AlertIcon } from "../../../../../assets/images/icons/ic_alert_circle.svg";
import styles from "./ModerationInfo.module.sass";

type Props = {
    info?: string;
};

const ModerationInfo = ({ info }: Props) => {
    if (!info) return null;
    return (
        <div className="form-item">
            <div className={styles.ModerationInfo}>
                <AlertIcon />
                {info}
            </div>
        </div>
    );
};

export default ModerationInfo;
