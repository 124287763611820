import React from "react";
import styles from "./account.module.sass";
import { ReactComponent as Clock } from "../../../assets/images/icons/controls/ic_clock_circle.svg";
import useUserInfo from "../../../hooks/useUserInfo";

const AccessPersonalAccount = () => {
    const { isReviewLKP } = useUserInfo();
    return isReviewLKP ? (
        <div className={styles.userStatus}>
            <Clock />
            <div>Заявка на доступ в личный кабинет на рассмотрении</div>
        </div>
    ) : null;
};

export default AccessPersonalAccount;
