import classNames from "classnames";
import { Form, getIn, useFormikContext } from "formik";
import React from "react";
import { InputField, useViewportContext, CheckboxField } from "@russpass/partner-front-ui";
import FormWrapper from "../../components/FormWrapper";
import SelectCancellationReasonField from "./fields/SelectCancellationReasonField";
import { inputMaskWithNights, inputMaskWithPercent } from "./helper";
import styles from "./styles.module.sass";
import { NavbarApartmentsNewName } from "../../constants";
import SubmitButton from "../../components/SubmitButton";
import { BaseStepProps } from "../../types";
import { ArchiveButton } from "../../components/ArchiveButton";

export type SaleAndCancelStepFormikFields = {
    discount?: number;
    rentFrom?: number;
    cancellationPolicy: string;
};

export const withNamespace = (name: string) => `${NavbarApartmentsNewName.SALE_AND_CANCEL}.${name}`;

export const SaleAndCancelStep = ({
    isNew,
    isArchive,
    isBlockedFields,
    isValid,
    isDirty,
    submit,
    onEditArchive,
    isEditArchive,
}: BaseStepProps) => {
    const { isMobile } = useViewportContext();
    const { setFieldValue, values } = useFormikContext();

    return (
        <FormWrapper>
            <Form>
                <div
                    className={classNames(styles.discountFramed, {
                        [styles.discountFramed__mobile]: isMobile,
                    })}
                >
                    <CheckboxField
                        name={withNamespace("hasDiscount")}
                        label="Скидка за длительное проживание"
                        onChange={(val) => {
                            setFieldValue(withNamespace("hasDiscount"), val);
                            setFieldValue(withNamespace("discount"), undefined);
                            setFieldValue(withNamespace("rentFrom"), undefined);
                        }}
                        disabled={isBlockedFields}
                    />
                    {getIn(values, withNamespace("hasDiscount")) && (
                        <>
                            <InputField
                                name={withNamespace("discount")}
                                label="Скидка"
                                placeholder="10%"
                                noHover
                                renderComponent={inputMaskWithPercent}
                                onValidate={(e) =>
                                    setFieldValue(withNamespace("discount"), +e.target.value.replace(" %", ""))
                                }
                                disabled={isBlockedFields}
                            />
                            <InputField
                                name={withNamespace("rentFrom")}
                                label="При бронировании от"
                                placeholder="5 ночей"
                                noHover
                                renderComponent={inputMaskWithNights}
                                onValidate={(e) =>
                                    setFieldValue(withNamespace("rentFrom"), +e.target.value.replace(" ночей", ""))
                                }
                                disabled={isBlockedFields}
                            />
                        </>
                    )}
                </div>
                <SelectCancellationReasonField
                    name={withNamespace("cancellationPolicy")}
                    label="Условия отмены бронирования"
                    disabled={isBlockedFields}
                />
                {isNew && (
                    <SubmitButton isValid={isValid} onClick={submit}>
                        Отправить на модерацию
                    </SubmitButton>
                )}
                {((!isNew && isDirty) || isEditArchive) && (
                    <SubmitButton isValid={isValid} fixed={!isEditArchive} onClick={submit}>
                        Отправить на модерацию
                    </SubmitButton>
                )}
                {isArchive && <ArchiveButton onEditArchive={onEditArchive} />}
            </Form>
        </FormWrapper>
    );
};
