import { useCallback, useEffect, useMemo, useState } from "react";
import { DiscountStatus, HOTEL_DISCOUNTS_STEP_DESC_EMPTY, HotelDiscountsData } from "../types/hotel-discounts";
import { declensionOfNum } from "../../../../../utils/declension-number";
import { getStepStyles } from "../utils/common";
import useLoadData from "./useLoadData";
import { HotelDataForm } from "../types/hotel-info";
import { allStatusesHotel } from "../../../all/constants";

const useControlDiscountsData = (hotelInfoData: HotelDataForm) => {
    const [isLoadingDiscounts, setIsLoadingDiscounts] = useState<boolean>(false);
    const [discountsData, setDiscountsData] = useState<HotelDiscountsData[] | undefined>(undefined);
    const [totalDiscounts, setTotalDiscounts] = useState(0);
    const { onLoadDiscounts } = useLoadData();

    const getDiscounts = useCallback(async () => {
        if (!hotelInfoData.cmsId) return;
        setIsLoadingDiscounts(true);
        const discounts = await onLoadDiscounts(hotelInfoData.cmsId, DiscountStatus.PUBLISHED, 1);
        setDiscountsData(discounts?.items);
        setTotalDiscounts(discounts?.total || 0);
        setIsLoadingDiscounts(false);
    }, [hotelInfoData.cmsId, onLoadDiscounts]);

    useEffect(() => {
        if (hotelInfoData && hotelInfoData.status === allStatusesHotel.active) {
            getDiscounts();
        }
    }, [getDiscounts, hotelInfoData]);

    const discountsStepDesc = useMemo(() => {
        if (totalDiscounts) {
            return (
                totalDiscounts +
                " " +
                declensionOfNum(totalDiscounts, ["действующая акция", "действующих акций", "действующих акции"])
            );
        } else {
            return HOTEL_DISCOUNTS_STEP_DESC_EMPTY;
        }
    }, [totalDiscounts]);

    const discountsStepStyles = useMemo(() => getStepStyles(!!discountsData), [discountsData]);

    return {
        isLoadingDiscounts,
        isActiveHotel: hotelInfoData.status === allStatusesHotel.active,
        isEmptyDiscounts: !!discountsData?.length,
        discountsStepDesc,
        discountsStepStyles,
    };
};

export default useControlDiscountsData;
