import { InputAjaxField, InputAjaxProps } from "@russpass/partner-front-ui";
import { useCallback } from "react";
import { getAddress } from "../../../api/dictionaries";
import { AddressType } from "../../../containers/restaurants/restaurant/types";

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    value?: string | Partial<AddressType>;
    disabled?: boolean;
    labelField?: string;
    errorSettings?: {
        showOnChange: boolean;
    };
    handleChange: (value: AddressType) => void;
} & Pick<InputAjaxProps, "triggerHandleChangeOnEmpty">;

const SelectAddress = ({
    name,
    label = "Адрес",
    placeholder = "Москва, Тверская, 5А",
    value,
    disabled,
    labelField = "unrestricted_value",
    errorSettings,
    handleChange,
    ...inputAjaxProps
}: Props) => {
    const promiseOptions = useCallback(async (inputValue: string) => {
        const content = await getAddress(inputValue);
        return Promise.resolve(content);
    }, []);

    const inputValue = typeof value === "string" || !value ? { [labelField]: value ?? "" } : value;

    return (
        <InputAjaxField
            name={name}
            label={label}
            placeholder={placeholder}
            value={inputValue}
            disabled={disabled}
            loadOptions={promiseOptions}
            labelField={labelField}
            handleChange={handleChange}
            errorSettings={errorSettings}
            dataQaName="address"
            {...inputAjaxProps}
        />
    );
};

export default SelectAddress;
