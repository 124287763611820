import { getRegionName, getCityName } from "../../api/dictionaries";

export const getRegion = async (regionId: string) => {
    const { title, timeZone } = await getRegionName(regionId);
    return {
        id: regionId,
        title,
        timeZone,
    };
};

export const getCity = async (cityId: string) => {
    const cityTitle = await getCityName(cityId);
    return {
        id: cityId,
        title: cityTitle,
    };
};
