export const debugMode = process.env.NODE_ENV === "development";

// Ключ яндекс карт
export const yaMapKey = process.env.REACT_APP_MAP_KEY;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || "";

export const appVersion = `${process.env.REACT_APP_VERSION}${
    !!process.env.REACT_APP_BUILD_VERSION ? `.${process.env.REACT_APP_BUILD_VERSION}` : ""
}`;

export const appTimestamp = process.env.REACT_APP_VERSION_TIMESTAMP || "";
