import React from "react";
import SupportLink from "./support-link";
import { IUserContactInfo } from "../../../types/profile";
import AccessPersonalAccount from "./AccessPersonalAccount";
import { TextField } from "@russpass/partner-front-ui";

type Props = {
    data: IUserContactInfo | null;
};

const BankDetails = ({ data }: Props) => {
    return (
        <div className="content-wrapper">
            <div className="content-wrapper__form">
                <AccessPersonalAccount />
                <TextField value={data?.bank ? data?.bank.bic : "-"} name="bic" label="БИК" />
                <TextField value={data?.bank ? data?.bank.name : "-"} name="bankName" label="Наименование банка" />
                <TextField
                    value={data?.bank ? data?.bank.checkingAccount : "-"}
                    name="checkingAccount"
                    label="Расчетный счет"
                />
                <TextField value={data?.bank?.corrAccount ?? "-"} name="corrAccount" label="Корреспондентский счет" />
                <TextField
                    value={data?.bank?.treasuryAccount ?? "-"}
                    name="treasuryAccount"
                    label="Казначейский счет"
                />
                <SupportLink />
            </div>
        </div>
    );
};

export default BankDetails;
