import { useCallback } from "react";
import { ListExcursionDraftItem, ListItem } from "./types";
import { getExcursionsAll } from "../../../api/excursion";
import { modifyExcursionDraftList, packingEventsByStatus } from "./helper";
import eventDispatcher from "../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../constants/errors";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { getExcursionsDraft } from "../../../api/draft";
import useUserInfo from "../../../hooks/useUserInfo";

const useExcursions = () => {
    const { userFullInfo } = useUserInfo();
    const loadExcursions = useCallback(async () => {
        try {
            const draftExcursions: ListExcursionDraftItem[] = await getExcursionsDraft(userFullInfo?.applicationId);
            const modifiedDraftExcursions = await modifyExcursionDraftList(draftExcursions);
            const responseExcursions: ListItem[] = await getExcursionsAll();

            const {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            } = packingEventsByStatus(modifiedDraftExcursions.concat(responseExcursions));

            return {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            };
        } catch (error) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
            return {
                draftServicesArray: [],
                moderationServicesArray: [],
                publishedServicesArray: [],
                refusedServicesArray: [],
                archiveServicesArray: [],
                allServicesArray: [],
            };
        }
    }, []);

    return {
        loadExcursions,
    };
};

export default useExcursions;
