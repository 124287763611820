import { BaseFieldProps } from "./types";
import { maskedInputNumbers } from "../../../../../../utils/validation";
import { InputField, InputFieldProps } from "@russpass/partner-front-ui";
import React from "react";

type Props = BaseFieldProps & Pick<InputFieldProps, "onValidate">;

export const BankBicField = ({ name, onValidate }: Props) => (
    <InputField
        label="БИК банка"
        name={name}
        noHover
        placeholder="040349736"
        maxLength={9}
        errorSettings={{ showCustomError: true }}
        onValidate={onValidate}
        renderComponent={maskedInputNumbers}
    />
);
