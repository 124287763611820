import { RefObject, useCallback, useEffect, useState } from "react";

const config = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
};

type lazyOptions = {
    disableLazyLoad: boolean;
};

function useLazyLoad(lazyLoadElementRef: RefObject<Element>, options?: lazyOptions): boolean {
    const [isVisible, setIsVisible] = useState(false);

    const { disableLazyLoad = false } = options || {};

    const callbackFunc = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const [entry] = entries;
            if (!isVisible && entry.isIntersecting) {
                setIsVisible(entry.isIntersecting);
            }
        },
        [isVisible]
    );

    useEffect(() => {
        if (disableLazyLoad) {
            setIsVisible(true);
            return;
        }
        const observer = new IntersectionObserver(callbackFunc, config);
        if (lazyLoadElementRef.current) observer.observe(lazyLoadElementRef.current);
        return () => {
            if (lazyLoadElementRef.current) observer.unobserve(lazyLoadElementRef.current);
        };
    }, [callbackFunc, disableLazyLoad, lazyLoadElementRef]);

    return isVisible;
}

export { useLazyLoad };
