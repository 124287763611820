import moment from "moment";
import { useCallback, useMemo, useRef, useState } from "react";
import { Modifier } from "react-day-picker";
import { ISO_DATE_FORMAT } from "../../../../constants/dates-format";
import { RangeType, RangeModifier } from "../types";
import { getDisabledDays, getDisplayingValue } from "../utils/period-string";

const useDateRange = () => {
    const [rangeDates, setRangeDates] = useState<RangeType>({ from: undefined, to: undefined });

    const [selectedRangeDates, setSelectedRangeDates] = useState<RangeModifier>({ from: undefined, to: undefined });

    const MenuRef = useRef<any>(null);

    const displayingValue = useMemo(() => getDisplayingValue(rangeDates) || "Отчетный период: все", [rangeDates]);

    const disabledDays: Modifier = useMemo(() => getDisabledDays(), []);

    const isChangedValue = useMemo(() => !!(selectedRangeDates.from && selectedRangeDates.to), [selectedRangeDates]);

    const handleSelect = useCallback((value: RangeModifier) => {
        setSelectedRangeDates(value);
    }, []);

    const onCloseMenu = useCallback(() => {
        MenuRef?.current?.closeMenu();
    }, [MenuRef]);

    const onOpenMenu = useCallback(() => {
        MenuRef?.current?.openMenu();
    }, [MenuRef]);

    const handleClickButton = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            onOpenMenu();
        },
        [onOpenMenu]
    );

    const handleSave = useCallback(async () => {
        setRangeDates({
            from: selectedRangeDates.from ? moment(selectedRangeDates.from).format(ISO_DATE_FORMAT) : undefined,
            to: selectedRangeDates.to ? moment(selectedRangeDates.to).format(ISO_DATE_FORMAT) : undefined,
        });
        onCloseMenu();
    }, [selectedRangeDates, onCloseMenu]);

    const handleReset = useCallback(async () => {
        setRangeDates({ from: undefined, to: undefined });
        onCloseMenu();
    }, [onCloseMenu]);

    return {
        MenuRef,
        displayingValue,
        rangeDates,
        handleSave,
        handleReset,
        isChangedValue,
        handleSelect,
        handleClickButton,
        disabledDays,
        selectedRangeDates,
    };
};

export default useDateRange;
