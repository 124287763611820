/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { FacilityType, Facility, FacilityServer, PartnerFacilityType } from "../../types";
import { facilityData, partnerFacilityDefaultValue } from "./mock";
import { getFacility, getFacilityTypes, getFacilities } from "../../../../api/facility";
import { prepareTimetableData } from "./utils";
import { NotificationProps } from "../../../../components/notification";
import { GET_DATA_ERROR } from "../../../../constants/errors";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import routes from "../../../../constants/routes";
import { getCity, getRegion } from "../../../../components/async-selects/utils";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { PartnerFacilityStatus } from "../../../../constants/services";
import { TSelectValue } from "../../../../types/dictionaries";
import _isEqual from "lodash/isEqual";
import useUserInfo from "../../../../hooks/useUserInfo";

type Props = {
    callNotification: (body: NotificationProps) => void;
    facilityId?: string;
    draftFacilityData?: Facility;
};

const useFacility = (params: Props) => {
    const { callNotification, draftFacilityData } = params;
    const location = useLocation();
    const { isGuide } = useUserInfo();

    const match = matchPath(location.pathname, {
        path: [routes.servicesView, routes.excursionsView],
        exact: true,
        strict: false,
    });

    // @ts-ignore
    const serviceId = match?.params?.id ?? match?.params?.excursionId;

    const [isLoadingFacility, setIsLoadingFacility] = useState<boolean>(true);

    const [selectedPartnerFacility, setSelectedPartnerFacility] = useState<PartnerFacilityType | null>(null);

    const [initialFacility, setInitialFacility] = useState<Facility>(facilityData); //храним два объекта для сравнения
    const [facility, setFacility] = useState<Facility>(facilityData);

    const [optionsPlaceTypes, setOptionsPlaceTypes] = useState<FacilityType[]>([]);
    const [optionsFacilityPlaces, setOptionsFacilityPlaces] = useState<PartnerFacilityType[]>([]);

    const isExistingPartnerFacility = useMemo(
        () =>
            !!selectedPartnerFacility?.cmsFacilityId &&
            selectedPartnerFacility?.cmsFacilityId !== partnerFacilityDefaultValue.cmsFacilityId,
        [selectedPartnerFacility]
    );

    const getInitialFacility = async () => {
        setIsLoadingFacility(true);
        let facility, facilityOwner;
        if (draftFacilityData) {
            facility = draftFacilityData;
            facilityOwner = optionsFacilityPlaces.find(
                (option) => option.cmsFacilityId === facility.cmsFacilityId
            )?.owner;
        }
        if (!draftFacilityData && params.facilityId) {
            facility = await getFacility(params.facilityId);
        }

        if (facility) {
            if (facility.region) {
                setRegion(await getRegion(facility.region));
            }

            if (facility.city?.length) {
                setCity(await getCity(facility.city[0]));
            }

            const setData = {
                ...facility,
                coordinates: facility.geoData?.join(", "),
                phone: facility.phones[0],
                addressDetail: {
                    unrestricted_value: facility.address,
                },
                workingTime: prepareTimetableData(facility.workingTime, true),
            };

            setFacility(setData);
            setInitialFacility(setData);
            setSelectedPartnerFacility({
                ...facility,
                owner: facilityOwner,
            });
        }
        setIsLoadingFacility(false);
    };

    const fillExistingFacility = useCallback(async (id: string) => {
        const facility: FacilityServer = await getFacility(id);
        if (facility.region) {
            setRegion(await getRegion(facility.region));
        }

        if (facility.city?.length) {
            setCity(await getCity(facility.city[0]));
        }

        setFacility({
            ...facility,
            coordinates: facility.geoData?.join(", "),
            phone: facility.phones[0],
            addressDetail: {
                unrestricted_value: facility.address,
            },
            workingTime: prepareTimetableData(facility.workingTime, true),
        });

        return facility;
    }, []);

    const loadData = useCallback(async () => {
        setIsLoadingFacility(true);
        let requestArray = [
            new Promise(async (resolve) => {
                try {
                    // TODO не всегда надо вызывать этот метод при загрузке (Место проведения есть только у Смешанной или экскурсии в помещении)
                    const facilityTypes = await getFacilityTypes();
                    setOptionsPlaceTypes(facilityTypes);
                    return resolve(facilityTypes);
                } catch (err) {
                    console.error(err);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const response = await getFacilities(isGuide || undefined);
                    if (response?.length) {
                        const activeFacilities = response.filter(
                            (facility) =>
                                facility.status === PartnerFacilityStatus.ACTIVE ||
                                facility.status === PartnerFacilityStatus.IMPORT
                        );
                        setOptionsFacilityPlaces(activeFacilities);
                    }
                    return resolve(response);
                } catch (err) {
                    console.error(err);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        ];

        Promise.all(requestArray).then(() => {
            setIsLoadingFacility(false);
        });
    }, [callNotification, serviceId, isGuide]);

    const [region, setRegion] = useState<any>({});
    const onChangeRegion = useCallback((newRegion: any) => {
        setRegion(newRegion);
    }, []);

    const [city, setCity] = useState<TSelectValue>({
        id: "",
        title: "",
    });
    const onChangeCity = useCallback((city: TSelectValue) => {
        setCity(city);
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    const loadInitialFacility = async () => {
        await getInitialFacility();
    };

    useEffect(() => {
        loadInitialFacility();
    }, [params.facilityId, draftFacilityData]);

    const onChangeFacility = useCallback((values: Partial<Facility>) => {
        setFacility((prevState) => ({
            ...prevState,
            ...values,
        }));
    }, []);

    const changePartnerFacility = useCallback(
        async (facility: PartnerFacilityType) => {
            if (facility.cmsFacilityId !== selectedPartnerFacility?.cmsFacilityId) {
                if (facility.cmsFacilityId !== partnerFacilityDefaultValue.cmsFacilityId) {
                    setIsLoadingFacility(true);
                    try {
                        await fillExistingFacility(facility.cmsFacilityId);
                        setIsLoadingFacility(false);
                    } catch (err) {
                        console.error(err);
                        callNotification({
                            status: StatusRequestEnum.Error,
                            body: GET_DATA_ERROR,
                        });
                        return setIsLoadingFacility(false);
                    }
                } else {
                    setFacility(facilityData);
                    setRegion({});
                    setCity({ id: "", title: "" });
                }
                setSelectedPartnerFacility(facility);
            }
        },
        [selectedPartnerFacility]
    );

    const hasChangesFacility = !_isEqual(initialFacility, facility);

    return {
        facility,
        optionsPlaceTypes,
        onChangeFacility,
        region,
        onChangeRegion,
        city,
        onChangeCity,
        optionsFacilityPlaces,
        selectedPartnerFacility,
        changePartnerFacility,
        isExistingPartnerFacility,
        isLoadingFacility,
        fillExistingFacility,
        hasChangesFacility,
        reloadFacilityData: loadInitialFacility,
    };
};

export default useFacility;
