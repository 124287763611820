import { BaseFieldProps } from "./types";
import React, { useCallback, useEffect } from "react";
import { useFormikContext } from "formik";
import SelectAddress from "../../../../../../components/async-selects/address";

export const RegistrationAddressField = ({ name }: BaseFieldProps) => {
    const { setFieldValue, getFieldProps } = useFormikContext();

    const value = getFieldProps(name).value;

    const onChangeAddress = useCallback(
        (value, setFieldValue) => {
            if (!value) setFieldValue(name, {});

            setFieldValue(`${name}.postalCode`, value.data?.postal_code);
            setFieldValue(`${name}.country`, value.data?.country);
            setFieldValue(`${name}.federalDistrict`, value.data?.federal_district);
            setFieldValue(`${name}.regionType`, value.data?.region_type);
            setFieldValue(`${name}.region`, value.data?.region);
            setFieldValue(`${name}.cityType`, value.data?.city_type);
            setFieldValue(`${name}.city`, value.data?.city);
            setFieldValue(`${name}.cityDistrictType`, value.data?.city_district_type);
            setFieldValue(`${name}.cityDistrict`, value.data?.city_district);
            setFieldValue(`${name}.settlementType`, value.data?.settlement_type);
            setFieldValue(`${name}.settlement`, value.data?.settlement);
            setFieldValue(`${name}.streetType`, value.data?.street_type);
            setFieldValue(`${name}.street`, value.data?.street);
            setFieldValue(`${name}.houseType`, value.data?.house_type);
            setFieldValue(`${name}.house`, value.data?.house);
            setFieldValue(`${name}.blockType`, value.data?.block_type);
            setFieldValue(`${name}.block`, value.data?.block);
            setFieldValue(`${name}.flatType`, value.data?.flat_type);
            setFieldValue(`${name}.flat`, value.data?.flat);
            setFieldValue(`${name}.roomType`, value.data?.room_type);
            setFieldValue(`${name}.room`, value.data?.room);
            setFieldValue(`${name}.unrestrictedValue`, value.unrestricted_value);
        },
        [name]
    );

    useEffect(() => {
        if (!value) return;
        setFieldValue(`${name}.unrestricted_value`, value.unrestrictedValue);
    }, [name, setFieldValue, value]);

    return (
        <SelectAddress
            name={`${name}.unrestrictedValue`}
            placeholder="г Москва, ул. Дмитровская, д.7, кв 129"
            label="Адрес регистрации"
            value={value || ""}
            handleChange={(value) => onChangeAddress(value, setFieldValue)}
            triggerHandleChangeOnEmpty
        />
    );
};
