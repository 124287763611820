import React, { FC, useMemo } from "react";
import { ActionButtonWrapper } from "@russpass/partner-front-ui";
import "./styles.sass";
import { ReactComponent as CopyImg } from "../../../../../../assets/images/icons/ic_copy.svg";
import classNames from "classnames";
import { ProfileMode } from "../../constants";
import { useFormikContext } from "formik";

type UserActionsContainerProps = {
    profileMode: ProfileMode;
    editButtonText: string;
    copyButtonText: string;
    sendModerationButtonText: string;
    onClickEditButton: (event: React.MouseEvent<HTMLElement>) => void;
    onClickCopyButton: () => void;
};

const UserActionsContainer: FC<UserActionsContainerProps> = ({
    profileMode,
    editButtonText,
    copyButtonText,
    sendModerationButtonText,
    onClickEditButton,
    onClickCopyButton,
}) => {
    let { isValid, dirty } = useFormikContext();

    const displayButton = useMemo(() => {
        if (profileMode === ProfileMode.EDIT || profileMode === ProfileMode.EDIT_SHARE) {
            return (
                <>
                    <button
                        onClick={onClickEditButton}
                        className={classNames("button button--full-width", "button--primary")}
                        type="button"
                    >
                        {editButtonText}
                    </button>
                    {profileMode === ProfileMode.EDIT_SHARE && (
                        <button
                            className={classNames("button button--full-width", "button--second")}
                            onClick={onClickCopyButton}
                            type="button"
                        >
                            <span className="button__icon button__icon--left">
                                <CopyImg />
                            </span>
                            {copyButtonText}
                        </button>
                    )}
                </>
            );
        } else if (dirty) {
            return (
                <button
                    className={classNames("button button--full-width", {
                        "button--primary": isValid,
                        "button--second": !isValid,
                    })}
                >
                    {sendModerationButtonText}
                </button>
            );
        }
        return null;
    }, [
        profileMode,
        editButtonText,
        copyButtonText,
        sendModerationButtonText,
        dirty,
        isValid,
        onClickCopyButton,
        onClickEditButton,
    ]);

    return (
        displayButton && (
            <div className="actions-wrapper-container">
                <ActionButtonWrapper className="actions-wrapper">{displayButton}</ActionButtonWrapper>
            </div>
        )
    );
};

export default UserActionsContainer;
