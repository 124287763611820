import { PropsWithChildren } from "react";
import styles from "./FormWrapper.module.sass";
import classNames from "classnames";

const FormWrapper = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
    return (
        <div className={classNames("content-wrapper", className)}>
            <div className={styles.Wrapper}>{children}</div>
        </div>
    );
};

export default FormWrapper;
