import { HotelDiscountModalContext, HotelDiscountModalType } from "./HotelDiscountContext";
import { useContext } from "react";

export const useHotelDiscountModalContext = (): HotelDiscountModalType => {
    const context = useContext<HotelDiscountModalType | undefined>(HotelDiscountModalContext);

    if (!context) {
        throw new Error("useHotelDiscountModalContext must be used within a HotelDiscountModalProvider.");
    }

    return context;
};
