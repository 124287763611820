import { Tariff, TariffListWithPagination } from "./types";
import { defaultLanguage } from "../../../../constants/language";
import "./styles.sass";
import { Tooltip } from "@russpass/partner-front-ui";

export let newTariffData: Tariff = {
    id: null,
    tariffCaption: [
        {
            content: "",
            language: defaultLanguage,
        },
    ],
    tariffNote: [
        {
            content: "",
            language: defaultLanguage,
        },
    ],
    itemType: [
        {
            id: null,
        },
    ],
    itemPrices: [
        {
            currency: {
                id: "110",
                symbol: "RUB",
                code: "RUB",
                countryCode: "RU",
            },
            price: "",
        },
    ],
    workingDays: [],
    itemBenefitType: {
        id: 1,
        caption: "Взрослый",
    },
    isChild: false,
    activeSince: "",
    activeUntil: "",
    openedSince: "",
    openedUntil: "",
    workingTime: [],
    isBasic: false,
};

export const optionsSupport = [
    {
        label: "Нет",
        value: false,
    },
    {
        label: (
            <Tooltip
                tooltipWidth={"192px"}
                overlay={"Только для детских тарифов"}
                style={{ fontSize: "16px", lineHeight: "22px" }}
                offset={{ X: 200, Y: 0 }}
            >
                <span>{"Да"}</span>
            </Tooltip>
        ),
        value: true,
    },
];

export const TariffList: TariffListWithPagination = {
    totalElements: 0,
    totalPages: 0,
    content: [],
    number: 0,
    size: 20,
};
