import { RentHousesObjectStatus } from "../../../types/rentHouses";

export enum NavbarApartmentsName {
    ALL = "ALL",
    DRAFT = "DRAFT",
    MODERATION = "MODERATION",
    PUBLISHED = "PUBLISHED",
    DECLINED = "DECLINED",
    ARCHIVED = "ARCHIVED",
}

export const STATUS_LABEL_APARTMENTS: Partial<Record<RentHousesObjectStatus, string>> = {
    [RentHousesObjectStatus.DRAFT]: "Черновик",
    [RentHousesObjectStatus.MODERATION]: "На модерации",
    [RentHousesObjectStatus.PUBLISHED]: "Опубликовано",
    [RentHousesObjectStatus.DECLINED]: "Отказано",
    [RentHousesObjectStatus.ARCHIVED]: "В архиве",
};
