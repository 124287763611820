import styles from "./profile-status.module.sass";
import classNames from "classnames";
import { RentHousesOwnerStatus } from "../../../types/rentHouses";
import { useRentHousesOwnerProfile } from "../../../api/hooks/rentHouses";
import { QuestionTooltip } from "@russpass/partner-front-ui";
import React from "react";

const ProfileStatus = () => {
    const { data: ownerData } = useRentHousesOwnerProfile();

    const isPendingUpdate = ownerData?.data?.status === RentHousesOwnerStatus.PENDING_UPDATE;
    const isPending = ownerData?.data?.status === RentHousesOwnerStatus.PENDING;
    const isDeclined = ownerData?.data?.status === RentHousesOwnerStatus.DECLINED;

    return (
        <div
            className={classNames(styles.Status, {
                [styles.Warning]: isDeclined,
            })}
        >
            {(isPending || isPendingUpdate) && (
                <div>
                    На модерации
                    <QuestionTooltip
                        body={
                            isPending
                                ? "Модерация занимает от 3‑х дней. Ответ отправим вам на почту"
                                : "Отправим письмо на почту, когда внесенные изменения пройдут модерацию и появятся на сайте"
                        }
                    />
                </div>
            )}
            {isDeclined && "Не прошел модерацию"}
        </div>
    );
};

export default ProfileStatus;
