import { Loader } from "@russpass/partner-front-ui";
import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./rating-modal-header.module.sass";

type FeedbackModalHeaderProps = {
    isLoading: boolean;
    mark?: number;
    markColored?: boolean;
    markDate?: string;
    markName?: string;
    button?: ReactNode;
    title?: string;
};

export const RatingModalHeader = ({
    isLoading,
    mark,
    markColored,
    markDate,
    markName,
    button,
    title = "Отзыв",
}: FeedbackModalHeaderProps) => (
    <div className="modal-rating-header">
        {isLoading ? (
            <div className="content-wrapper--center mt-32 mb-32">
                <Loader width="48px" />
            </div>
        ) : (
            <>
                <div className="modal-rating-header__title">
                    <div className="d-flex flex-column gap-1">
                        {title}
                        {markDate && <div className={styles.HeaderDescription}>от {markDate}</div>}
                        {markName && <div className={styles.HeaderDescription}>{markName}</div>}
                    </div>
                    {!!mark && markColored && (
                        <div
                            className={classNames("mark", {
                                "mark__colored--low": mark < 5,
                                "mark__colored--high": mark >= 7,
                            })}
                        >
                            {mark}
                        </div>
                    )}
                </div>
                {button}
            </>
        )}
    </div>
);
