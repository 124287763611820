import { useEffect, useMemo, useState, useCallback } from "react";
import { useViewportContext, SelectWithoutOptionsField } from "@russpass/partner-front-ui";
import ModalMenu from "./components/ModalMenu";
import PopupMenu from "./components/PopupMenu";
import { MenuProps, SelectOption } from "./components/types";
import { useFormikContext } from "formik";

export type SearchSelectFieldProps = {
    name: string;
    label: string;
    options: SelectOption[];
    selectPlaceholder?: string;
    searchPlaceholder?: string;
    disabled?: boolean;
    onChange?: (option: SelectOption | undefined) => void;
    onSearchTextChange?: (searchText: string) => void;
    hideArrow?: boolean;
} & Pick<MenuProps, "optionRenderer" | "saveOnClick">;

const SearchSelectField = ({
    name,
    label,
    selectPlaceholder = "Выбрать",
    searchPlaceholder = "Поиск",
    options,
    disabled,
    onChange,
    onSearchTextChange,
    hideArrow,
    ...menuProps
}: SearchSelectFieldProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const { isMobile } = useViewportContext();

    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        if (isOpen) return;
        setSearchText("");
    }, [isOpen]);

    const finalOptions = useMemo(
        () => options.sort().filter((el) => el.label.toLowerCase().includes(searchText.toLowerCase())),
        [options, searchText]
    );

    const Menu = isMobile ? ModalMenu : PopupMenu;

    const { getFieldProps, setFieldValue } = useFormikContext();

    const value = getFieldProps(name).value;

    const activeOption = options.find((el) => el.value === value);

    useEffect(() => {
        onChange?.(activeOption);
    }, [onChange, activeOption]);

    const handleClick = useCallback(() => {
        setIsOpen((isOpen) => !isOpen);
    }, [setIsOpen]);

    const handleCloseMenu = useCallback(() => setIsOpen(false), [setIsOpen]);

    const handleSetValue = useCallback(
        (value: any) => {
            setFieldValue(name, value);
            setSearchText(value);
            setIsOpen(false);
        },
        [name, setFieldValue, setSearchText, setIsOpen]
    );

    useEffect(() => {
        onSearchTextChange?.(searchText);
    }, [onSearchTextChange, searchText]);

    return (
        <div style={{ position: "relative" }}>
            <SelectWithoutOptionsField
                name={name}
                handleClick={handleClick}
                label={label}
                placeholder={selectPlaceholder}
                noHover
                arrowType="bottom"
                hideArrow={hideArrow}
                value={activeOption?.label || ""}
                disabled={disabled}
            />
            <Menu
                isOpen={isOpen}
                close={handleCloseMenu}
                placeholder={searchPlaceholder}
                searchText={searchText}
                setSearchText={setSearchText}
                options={finalOptions}
                label={label}
                value={value}
                setValue={handleSetValue}
                {...menuProps}
            />
        </div>
    );
};

export default SearchSelectField;
