import Ticket from "../../../../../components/ticket";
import styles from "./BookingTicket.module.sass";
import { TextField } from "@russpass/partner-front-ui";
import { formatGuestsCount, formatPrice, parseFullName } from "../../helpers";
import { RentHousesBooking, TripType } from "../../../../../types/rentHouses";
import RatingBadge from "./RatingBadge";
import { useViewportContext } from "@russpass/partner-front-ui";
import classNames from "classnames";

type Props = {
    booking: RentHousesBooking;
};

export const BookingTicket = ({ booking }: Props) => {
    const guests = formatGuestsCount(booking.guestCount);
    const { isMobile } = useViewportContext();

    return (
        <Ticket
            className={classNames({ [styles.Ticket__mobile]: isMobile })}
            top={
                <div className={styles.TicketHeader}>
                    <TextField label="Гости" value={guests} />
                    <TextField label="Сумма" value={formatPrice(booking.amount)} />
                </div>
            }
            bottom={
                <div className={styles.TicketFooter}>
                    {!!booking.plannedCheckinAt && <TextField label="Номер бронирования" value={booking.uid} />}
                    <div className={styles.TicketUser}>
                        <TextField label="На имя" value={parseFullName(booking.guest)} />
                        {!!booking.user.rating && <RatingBadge className={styles.Rating} value={booking.user.rating} />}
                    </div>
                    {!!booking.plannedCheckinAt && (
                        <TextField label="Желаемое время заселения" value={booking.plannedCheckinAt} />
                    )}
                    {!!booking.tripPurpose && <TextField label="Поездка" value={TripType[booking.tripPurpose]} />}
                </div>
            }
        />
    );
};
