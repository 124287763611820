import { ModalCenter, ModalCenterProps } from "@russpass/partner-front-ui";
import EventPreview from "./EventPreview";
import { Photo } from "../../../../types/react-files";

export type CheckListType = {
    program: string[];
    schedule: string[];
    included: string[];
    paidSeparately: string[];
    startPointAddress: string;
    startPointAdditInfo: string;
};

export type EventPreviewDataType = {
    title: string;
    eventType: string;
    workingDate?: string;
    address?: string;
    facilityPlaceTitle?: string;
    images: Photo[];
    description: string;
    coordinates?: number[];
    ageRestriction?: string;
    workingTime: {
        emptyValue: string;
        value: string;
        isShowArrow?: boolean;
    };
    checkList?: CheckListType;
};

export type EventPreviewModalProps = {
    data: EventPreviewDataType;
} & Omit<ModalCenterProps, "fixBody">;

// TODO Deprecated перейти на пост загрузку EventPreviewModalNew
export const EventPreviewModal = ({ data, isOpened, onClose }: EventPreviewModalProps) => (
    <ModalCenter isOpened={isOpened} onClose={onClose} modalTitle="Режим предпросмотра">
        <EventPreview data={data} />
    </ModalCenter>
);
