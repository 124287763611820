import { useCallback, useEffect, useState } from "react";
import { useInterval } from "./useInterval";

export const useCountDown = (intervalMs: number = 1000) => {
    const [counter, setCounter] = useState(0);
    const updateTime = useCallback(() => setCounter((time) => time - 1), []);

    const { start, reset } = useInterval(updateTime, intervalMs);

    useEffect(() => {
        if (counter > 0) return;
        reset();
    }, [reset, counter]);

    const startCountDown = useCallback(
        (value: number) => {
            setCounter(value < 0 ? 0 : value);
            start();
        },
        [start]
    );

    return {
        isActive: counter > 0,
        start: startCountDown,
        counter,
    };
};
