import moment from "moment";
import { DOTTED_DATE_FORMAT } from "./dates-format";
import routes from "./routes";

export const FORM_FIELD_ERROR = "Недопустимый формат данных";

export const FORM_FIELD_REQUIRED_ERROR = "Пожалуйста, заполните поле";

export const FORM_MAX_ERROR = ({ max }: { max: number }) => `Максимальное количество символов: ${max}`;

export const FORM_MIN_ERROR = ({ min }: { min: number }) => `Пожалуйста, введите не менее ${min} символов`;

export const NOT_FULLED_SECTIONS_ERROR = "Вы заполнили не все разделы";

export const NOT_FULLED_SECTION_ERROR = "Заполните информацию в разделе ";

export const FORMAT_ERROR = "Неверный формат";

export const GET_DATA_ERROR = "Ошибка получения данных";

export const UNEXPECTED_ERROR = "Возникла непредвиденная ошибка. Попробуйте обновить страницу";

export const OTP_ERROR = "Этот код неверный или у него истек срок действия. Запросите новый код";

export const POST_DATA_ERROR = "Что-то пошло не так";

export const POST_DATA_ERROR2 = "Что-то пошло не так, попробуйте еще раз";

export const POST_DATA_ERROR_SUPPORT = (
    <>
        {"Что-то пошло не так, попробуйте еще раз или обратитесь в "}
        <a className="link-secondary" href={`${routes.support}`} target="_blank" rel="noreferrer">
            {"Поддержку"}
        </a>
    </>
);

export const COMMON_ERROR = "Произошла ошибка, попробуйте позже";

export const FILE_FORMAT_ERROR = "Недопустимый формат файла";

export const COMMON_RELOAD_ERROR = "Произошла ошибка, обновите страницу";

export const MAX_CHATACTERS_ERROR = ({ max }: { max: number }) => `Максимальное количество символов: ${max}`;

export const MAX_DATE = ({ max }: { max: string | Date }) =>
    `Выбранная дата должна быть не позже ${moment(max).format(DOTTED_DATE_FORMAT)}`;

export const FILE_TOO_LARGE = (limitInMB: number) => `Размер файла не должен превышать ${limitInMB} мб`;

export const FILE_TOO_LARGE_DROPZONE = "file-too-large";

export const MIN_COUNT_PHOTOS_ERROR = "Загрузите не менее 2 фотографий";

export const MAX_VALUE_NUMBER = ({ max }: { max: number }) => `Максимальное значение: ${max}`;

export const MIN_VALUE_NUMBER = ({ min }: { min: number }) => `Минимальное значение: ${min}`;

export const FILL_INFORMATION = "Заполните информацию";

export const ONLY_INTEGER = "Возможен ввод только целого числа";

export const EMAIL_FORMAT_ERROR = "Указан неверный адрес эл. почты";

export const EMAIL_REQUIRED_ERROR = "Введите эл. почту";

export const StatusRequestCardSubtitleError = (
    <>
        {"Повторите попытку или обратитесь"}
        <br />
        {" в "}
        {
            <a className="link" href={`${routes.support}`} target="_blank" rel="noreferrer">
                {"Поддержку"}
            </a>
        }
        {" для уточнения информации"}
    </>
);

export const LENGTH_EXCEEDS_MAXIMUM_ERROR = "Весь текст не помещается, пожалуйста, сократите его";
