import { useCallback, useMemo, useState } from "react";
import { RentHousesBookingItem, RentHousesBookingStatus, RentHousesRate } from "../../../../../types/rentHouses";
import { useDatePrice } from "./useDatePrice";
import { formatPrice } from "../../helpers";
import moment from "moment/moment";
import history from "../../../../../history";
import routes from "../../../../../constants/routes";

enum Background {
    BLOCKED = "blocked",
    COMPLETED = "completed",
    ACTIVE = "active",
}
enum Border {
    PENDING = "pending",
    MULTIPLE_PENDING = "multiple_pending",
}

type ViewBase = { background?: Background; border?: Border };
type Connector = { background: boolean; border: boolean };

type DayView = ViewBase & { connector?: Connector };

export type BookingsDateInfo = {
    monthDayNumber: number;
    price: string;

    hasBookings: boolean;
    multipleBookings: boolean;
    multiplePendingBookings: boolean;

    isCompleted: boolean;
    isActive: boolean;
    isPending: boolean;

    canBlock: boolean;
    isBlocked: boolean;
    isPast: boolean;
};

export const useBookingCalendarDay = ({
    date,
    rates,
    allBookings,
    blockedDays,
    isBlocking,
    toggleBlock,
    isArchiveObject,
}: {
    date: Date;
    rates: RentHousesRate[];
    allBookings: RentHousesBookingItem[];
    blockedDays: number[];
    isBlocking: boolean;
    toggleBlock: (day: number) => void;
    isArchiveObject?: boolean;
}) => {
    const getDatePrice = useDatePrice(rates || []);

    const getBookingsByDate = useCallback(
        (date: Date) => {
            const dateBookings =
                allBookings?.filter((booking) =>
                    moment(date).isBetween(
                        moment(booking.checkinDate).startOf("day"),
                        moment(booking.checkoutDate).endOf("day")
                    )
                ) || [];

            const hasBookings = !!dateBookings.length;

            const activeDateBookings = dateBookings.filter((el) =>
                [RentHousesBookingStatus.ACTIVE, RentHousesBookingStatus.STARTED].includes(el.status)
            );

            const pendingDateBookings = dateBookings.filter((el) =>
                [RentHousesBookingStatus.PENDING].includes(el.status)
            );

            const completedDateBookings = dateBookings.filter((el) =>
                [RentHousesBookingStatus.COMPLETED].includes(el.status)
            );

            const isPast = moment(date).isBefore(moment().startOf("day"));

            const monthDayNumber = date.getDate();
            const weekDayNumber = moment(date).day() + 1; // 1 - 7, 1 - воскресенье
            const isBlocked = !isPast && blockedDays.includes(monthDayNumber) && !isArchiveObject;
            const canBlock = !hasBookings && !isPast && !isArchiveObject;

            return {
                monthDayNumber,
                weekDayNumber,
                dateBookings,
                hasBookings,
                multipleBookings: dateBookings.length > 1,
                activeDateBookings,
                hasActiveBookings: !!activeDateBookings.length,
                pendingDateBookings,
                hasPendingBookings: !!pendingDateBookings.length,
                multiplePendingBookings: pendingDateBookings.length > 1,
                completedDateBookings,
                isBlocked,
                canBlock,
                isPast,
            };
        },
        [allBookings, blockedDays, isArchiveObject]
    );

    const [tooltipDay, setTooltipDay] = useState<number | null>(null);

    const getDayView = useCallback(
        (
            currentDayBookings: ReturnType<typeof getBookingsByDate>,
            prevDayBookings?: ReturnType<typeof getBookingsByDate>
        ): DayView => {
            const getViewBackground = (bookings: ReturnType<typeof getBookingsByDate>): Background | undefined => {
                if (isBlocking && bookings.canBlock) return;
                if (bookings.isBlocked) return Background.BLOCKED;
                if (bookings.hasBookings && bookings.completedDateBookings.length === bookings.dateBookings.length)
                    return Background.COMPLETED;
                if (bookings.hasActiveBookings) return Background.ACTIVE;
            };

            const currentViewBackground = getViewBackground(currentDayBookings);

            const getViewBorder = (bookings: ReturnType<typeof getBookingsByDate>, viewBackground?: Background) => {
                if (viewBackground === Background.BLOCKED) return;
                if (bookings.pendingDateBookings.length === 1) return Border.PENDING;
                if (bookings.pendingDateBookings.length === 2) {
                    const first = bookings.pendingDateBookings[0];
                    const second = bookings.pendingDateBookings[1];
                    if (first.checkinDate === second.checkoutDate || first.checkoutDate === second.checkinDate) {
                        return Border.PENDING;
                    }
                }
                if (bookings.pendingDateBookings.length > 1) return Border.MULTIPLE_PENDING;
            };

            const currentViewBorder = getViewBorder(currentDayBookings, currentViewBackground);

            const getConnector = (): Connector | undefined => {
                if (!prevDayBookings) return;

                const prevViewBackground = getViewBackground(prevDayBookings);
                const prevViewBorder = getViewBorder(prevDayBookings, prevViewBackground);

                const getBackgroundConnector = (): boolean => {
                    if (
                        !currentViewBackground ||
                        !prevViewBackground ||
                        currentViewBackground !== prevViewBackground ||
                        currentDayBookings.multipleBookings
                    ) {
                        return false;
                    }
                    if (currentViewBackground === Background.BLOCKED) {
                        return true;
                    }
                    if (currentViewBackground === Background.COMPLETED) {
                        return !(
                            prevDayBookings.completedDateBookings.length > 1 ||
                            currentDayBookings.completedDateBookings.length > 1
                        );
                    }
                    if (currentViewBackground === Background.ACTIVE) {
                        return !(
                            prevDayBookings.activeDateBookings.length > 1 ||
                            currentDayBookings.activeDateBookings.length > 1
                        );
                    }

                    return false;
                };

                const getBorderConnector = (): boolean => {
                    if (
                        !currentViewBorder ||
                        !prevViewBorder ||
                        currentViewBorder !== prevViewBorder ||
                        currentViewBackground === Background.BLOCKED ||
                        currentViewBackground !== prevViewBackground ||
                        currentViewBorder === Border.MULTIPLE_PENDING
                    ) {
                        return false;
                    }

                    if (currentViewBorder === Border.PENDING) {
                        return !(
                            prevDayBookings.pendingDateBookings.length > 1 ||
                            currentDayBookings.pendingDateBookings.length > 1
                        );
                    }

                    return false;
                };

                return { background: getBackgroundConnector(), border: getBorderConnector() };
            };

            return { background: currentViewBackground, border: currentViewBorder, connector: getConnector() };
        },
        [isBlocking]
    );

    return useMemo(() => {
        const currentDateBookings = getBookingsByDate(date);

        const {
            monthDayNumber,
            weekDayNumber,
            dateBookings,
            multipleBookings,
            hasActiveBookings,
            hasPendingBookings,
            multiplePendingBookings,
            completedDateBookings,
            hasBookings,
            isBlocked,
            canBlock,
            isPast,
        } = currentDateBookings;

        let prevDateBookings: ReturnType<typeof getBookingsByDate> | undefined;
        if (weekDayNumber !== 2 && monthDayNumber !== 1) {
            const prevDate = moment(date).subtract(1, "day");
            prevDateBookings = getBookingsByDate(prevDate.toDate());
        }

        const isCompleted = hasBookings && completedDateBookings.length === dateBookings.length;

        let onClick: undefined | (() => void);
        if (!tooltipDay) {
            if (isBlocking && canBlock) {
                onClick = () => toggleBlock(monthDayNumber);
            }
            if (!isBlocking && (hasBookings || !isArchiveObject)) {
                if (hasBookings && !multipleBookings) {
                    onClick = () =>
                        history.push({ pathname: routes.apartmentsBookingAll, search: `id=${dateBookings[0].id}` });
                }
                if (multipleBookings || canBlock) {
                    onClick = () => setTooltipDay(monthDayNumber);
                }
            }
        }

        return {
            info: {
                monthDayNumber,
                price: formatPrice(getDatePrice(date)),
                multipleBookings,
                multiplePendingBookings,
                isCompleted,
                isActive: hasActiveBookings,
                isPending: hasPendingBookings,
                canBlock,
                isBlocked,
                hasBookings,
                isPast,
            } as BookingsDateInfo,
            view: getDayView(currentDateBookings, prevDateBookings),
            onClick,
            tooltip: {
                isOpen: tooltipDay === monthDayNumber,
                close: () => setTooltipDay(null),
            },
            dateBookings,
        };
    }, [date, getBookingsByDate, getDatePrice, getDayView, isArchiveObject, isBlocking, toggleBlock, tooltipDay]);
};
