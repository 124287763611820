import { SelectInputField } from "@russpass/partner-front-ui";
import { foodTypeOptions, slugNameRoomOnly } from "../../../constants/hotel-tariff";
import { HotelTariffData, OptionTypeFood } from "../../../types/hotel-tariff";
import { useCallback } from "react";

import styles from "./hotel-tariff-food.module.sass";

type HotelTariffFoodProps = {
    values: HotelTariffData;
    onChange: (key: string, value: any) => void;
    errorSettings?: {
        showOnChange: boolean;
    };
    optionsTypeFood: OptionTypeFood[];
    disabled?: boolean;
};

const HotelTariffFood = ({ values, onChange, errorSettings, optionsTypeFood, disabled }: HotelTariffFoodProps) => {
    const handleTypeChange = useCallback(
        (value) => {
            onChange("foodType.include", value.value);
        },
        [onChange]
    );

    const handleIdChange = useCallback(
        (value) => {
            if (value.slug === slugNameRoomOnly) {
                onChange("foodType.include", false);
            }
            onChange("foodType.cmsId", value.id);
        },
        [onChange]
    );

    return (
        <div className="content-wrapper__framed">
            <div className="section mb-24">Питание</div>
            <div className={styles.contentFoodWrapper}>
                <SelectInputField
                    name="foodType.include"
                    errorSettings={errorSettings}
                    options={foodTypeOptions}
                    value={foodTypeOptions.find((option) => option.value === values.foodType.include)}
                    handleChange={handleTypeChange}
                    placeholder="Выбрать"
                    isChecked
                    label="Тип"
                    disabled={disabled}
                />

                {values.foodType.include && (
                    <SelectInputField
                        name="foodType.cmsId"
                        errorSettings={errorSettings}
                        options={optionsTypeFood}
                        value={
                            optionsTypeFood.find((option) => option.id === values.foodType.cmsId) || {
                                id: "",
                                title: "",
                            }
                        }
                        handleChange={handleIdChange}
                        valueField="id"
                        labelField="title"
                        placeholder="Выбрать"
                        isChecked
                        label="Вид"
                        disabled={disabled}
                    />
                )}
            </div>
        </div>
    );
};
export default HotelTariffFood;
