import React from "react";
import styles from "./styles.module.sass";
import { ReactComponent as Heart } from "../../../../../../assets/images/icons/heart.svg";
import { ReactComponent as Flag } from "../../../../../../assets/images/icons/flag-01.svg";
import { ReactComponent as Calendar } from "../../../../../../assets/images/icons/calendar.svg";
import { ReactComponent as Marker } from "../../../../../../assets/images/icons/marker-pin-01.svg";
import { ReactComponent as Map } from "../../../../../../assets/images/icons/map-01.svg";
import { ReactComponent as Chevron } from "../../../../../../assets/images/icons/chevron-right.svg";
import { ReactComponent as Clock } from "../../../../../../assets/images/icons/clock.svg";
import { EventPreviewDataType } from "../..";
import classNames from "classnames";

const HeadlinePreview = ({
    title,
    eventType,
    workingDate,
    address,
    facilityPlaceTitle,
    ageRestriction,
    workingTime,
}: Partial<EventPreviewDataType>) => {
    return (
        <div className={styles.headline}>
            <div className={styles.headline__top}>
                <div className={styles.headline__top__title}>{title}</div>
                <div className={styles.mockHeader_bottom}>
                    <div className={styles.mockHeader_bottom__button}>
                        <button className="button button--primary">{"Перейти к билетам"}</button>
                    </div>
                    <div className={styles.mockHeader_bottom__heart}>
                        <button className="button">
                            <Heart className={"button__icon"} />
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.headline__top__details}>
                <div className={styles.headline__top__details__itemContainer}>
                    <div className={styles.headline__top__details__itemContainer__item}>
                        <Flag />
                    </div>
                    <div className={styles.headline__top__details__itemContainer__item}>{eventType || "Тип"}</div>
                </div>
                <div className={styles.headline__top__details__itemContainer}>
                    <div className={styles.headline__top__details__itemContainer__item}>
                        <Calendar />
                    </div>
                    <div className={styles.headline__top__details__itemContainer__item}>{workingDate || "Даты"}</div>
                </div>
                {address && (
                    <div className={styles.headline__top__details__itemContainer}>
                        <div className={styles.headline__top__details__itemContainer__item}>
                            <Marker />
                        </div>
                        <div className={styles.headline__top__details__itemContainer__item}>{address || "Адрес"}</div>
                    </div>
                )}
                {typeof facilityPlaceTitle === "string" && (
                    <div className={styles.headline__top__details__itemContainerHalfMR}>
                        <div className={styles.headline__top__details__itemContainer__item}>
                            <Map />
                        </div>
                        <div className={styles.headline__top__details__itemContainer__item}>
                            {facilityPlaceTitle || "Место проведения"}
                        </div>
                        <div className={styles.headline__top__details__itemContainer__item}>
                            <Chevron />
                        </div>
                    </div>
                )}
                <div className={styles.headline__top__details__itemContainerHalfMR}>
                    <div className={styles.headline__top__details__itemContainer__item}>
                        <Clock />
                    </div>
                    <div className={styles.headline__top__details__itemContainer__item}>
                        {workingTime?.value || workingTime?.emptyValue}
                    </div>
                    {workingTime?.isShowArrow && (
                        <div className={classNames(styles.headline__top__details__itemContainer__item, styles.rotated)}>
                            <Chevron />
                        </div>
                    )}
                </div>
                {ageRestriction && (
                    <div className={styles.headline__top__details__itemContainer}>
                        <div
                            className={classNames(styles.headline__top__details__itemContainer__item, styles.bordered)}
                        >
                            {ageRestriction}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeadlinePreview;
