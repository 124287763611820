import React, { useState, createContext, useContext, PropsWithChildren, useMemo, useCallback } from "react";
import { CreateEventResultStatusEnum } from "../CreateEventResultModal/create-event-modal-enum";
import { CreateEventResultModal } from "../CreateEventResultModal";
const DEFAULT_VALUE = {
    openEventStatusModal: () => {},
};

type CreateEventModalSettings = {
    type: CreateEventResultStatusEnum;
    title: string | React.ReactNode;
    desc?: string | React.ReactNode;
    errorBtnAction?: () => Promise<void>;
    closeBtnAction: () => void;
};

type CreateEventModalContextType = {
    openEventStatusModal: (value: CreateEventModalSettings) => void;
};

export const CreateEventModalContext = createContext<CreateEventModalContextType>(DEFAULT_VALUE);

export const CreateEventModalContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [modalSettings, setModalSettings] = useState<CreateEventModalSettings>();
    const [isLoading, setIsLoading] = useState(false);

    const openEventStatusModal = useCallback((settings: CreateEventModalSettings) => {
        setModalSettings(settings);
    }, []);

    const closeModal = () => {
        modalSettings?.closeBtnAction?.();
        setModalSettings(undefined);
    };

    const errorBtnAction = async () => {
        setIsLoading(true);
        await modalSettings?.errorBtnAction?.();
        setIsLoading(false);
    };

    const value = useMemo(
        () => ({
            openEventStatusModal,
        }),
        [openEventStatusModal]
    );

    return (
        <CreateEventModalContext.Provider value={value}>
            {children}
            <CreateEventResultModal
                isOpen={!!modalSettings}
                type={modalSettings?.type || CreateEventResultStatusEnum.Success}
                title={modalSettings?.title}
                desc={modalSettings?.desc}
                isLoading={isLoading}
                errorBtnAction={errorBtnAction}
                onClose={closeModal}
            />
        </CreateEventModalContext.Provider>
    );
};

export const useCreateEventModalContext = () => useContext(CreateEventModalContext);
