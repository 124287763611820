import { useCallback, useMemo, useState } from "react";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { GET_DATA_ERROR } from "../../../../../constants/errors";
import useLoadData from "./useLoadData";
import { HotelTariffData } from "../types/hotel-tariff";
import { declensionOfNum } from "../../../../../utils/declension-number";
import { getStepStyles } from "../utils/common";

export const useControlHotelTariffsData = () => {
    const [isLoadingTariffs, setIsLoadingTariffs] = useState(false);
    const [tariffsData, setTariffsData] = useState<HotelTariffData[]>([]);

    const isValidTariffs = useMemo(() => {
        return tariffsData.length > 0;
    }, [tariffsData]);

    const hotelTariffStepDesc = useMemo(() => {
        return isValidTariffs
            ? `${tariffsData.length} ${declensionOfNum(tariffsData.length, ["тариф", "тарифа", "тарифов"])}`
            : undefined;
    }, [tariffsData, isValidTariffs]);

    const hotelTariffStepStyles = useMemo(() => getStepStyles(isValidTariffs), [isValidTariffs]);

    const { onLoadTariffs } = useLoadData();

    const reloadTariffs = useCallback(async () => {
        setIsLoadingTariffs(true);
        try {
            const tariffs = await onLoadTariffs();
            setTariffsData(tariffs);
            return tariffs;
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
            return [];
        } finally {
            setIsLoadingTariffs(false);
        }
    }, [onLoadTariffs]);

    return {
        isLoadingTariffs,
        tariffs: tariffsData,
        reloadTariffs,
        isValidTariffs,
        hotelTariffStepDesc,
        hotelTariffStepStyles,
    };
};
