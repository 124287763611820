import classNames from "classnames";
import React, { useMemo, useCallback, useEffect, useState } from "react";
import {
    useEventListener,
    useViewportContext,
    MultiMenu,
    MultiMenuPlaceholderColor,
    RangeDaysSelect,
    RangeDaysSelectProps,
    RangeDaysMultipleSelect,
} from "@russpass/partner-front-ui";
import modalMenuFooterStyles from "../../../form-fields/search-select-field/components/ModalMenu.module.sass";
import navbarStyles from "./navbar.module.sass";
import { PlaceTimetableButtonKeys } from "../constants";
import { DateControlProps, TKeysTabs } from "../types";
import { NotificationDate } from "./date-control-notification";
import DaysSelect from "./days-select";
import "./styles.sass";
import useDateControl from "./useDateControl";
import WeekDaysSelect from "./week-days-select";
import SwitchButton from "./switch-button";
import ActionsComponent from "../action-buttons";

type Props = {
    name: string;
    error?: string;
    isExistingTariff?: boolean;
    keysVisibleTabs?: TKeysTabs;
    isRenderValueWithPronoun?: boolean;
    label?: string;
    placeholder?: string;
    placeholderColor?: MultiMenuPlaceholderColor;
    disabledWeekDays?: string[];
    minWidth?: number;
    modalTitle?: string;
    multipleRangeSelect?: boolean;
    disabled?: boolean;
} & DateControlProps &
    Pick<RangeDaysSelectProps, "fromMonth">;

const defaultVisibleTabs = [
    PlaceTimetableButtonKeys.Days,
    PlaceTimetableButtonKeys.RangeDays,
    PlaceTimetableButtonKeys.WeekDays,
];

const DateControl = (props: Props) => {
    const {
        keysVisibleTabs = defaultVisibleTabs,
        label,
        placeholder = "Выбрать дни",
        placeholderColor,
        disabledWeekDays,
        minWidth,
        modalTitle,
        fromMonth,
        multipleRangeSelect = false,
        name,
        disabled,
        ...rest
    } = props;

    const { isMobile } = useViewportContext();

    const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);

    const {
        isChangedValue,
        handleReset,
        handleSave,
        MenuRef,
        handleSelect,
        weekDaysValue,
        daysValue,
        rangeDaysValue,
        displayingValue,
        disabledOptions,
        disabledDays,
        isDaysExisting,
        isRangeDaysExisting,
        isWeekDaysExisting,
        selectedValue,
        setSelectedValue,
        WeekDaysSelectRef,
        DaysSelectRef,
        RangeDaysSelectRef,
    } = useDateControl(rest);

    const definitionInitialValue = () => {
        if (isDaysExisting) {
            return PlaceTimetableButtonKeys.Days;
        } else if (isRangeDaysExisting) {
            return PlaceTimetableButtonKeys.RangeDays;
        } else if (isWeekDaysExisting) {
            return PlaceTimetableButtonKeys.WeekDays;
        }
        return PlaceTimetableButtonKeys.WeekDays;
    };

    const [activeTab, setActiveTab] = useState<PlaceTimetableButtonKeys>(() => definitionInitialValue());

    const getSelectedValue = () => {
        switch (true) {
            case isDaysExisting:
                setSelectedValue(daysValue);
                break;
            case isRangeDaysExisting:
                setSelectedValue(rangeDaysValue);
                break;
            case isWeekDaysExisting:
                setSelectedValue(weekDaysValue);
                break;
            default:
                setSelectedValue([]);
                break;
        }
    };

    const changeActiveTab = (activeTabKey: PlaceTimetableButtonKeys) => {
        if (activeTabKey !== activeTab) {
            if (!selectedValue.length) {
                setActiveTab(activeTabKey);
                return;
            }

            const days = selectedValue.filter((val: any) => !daysValue.includes(val));
            const weekDays = selectedValue.filter((val: any) => !weekDaysValue.includes(val));

            if (isDaysExisting && selectedValue.length === daysValue.length && !days.length) {
                setActiveTab(activeTabKey);
                return;
            }

            if (isWeekDaysExisting && selectedValue.length === weekDaysValue.length && !weekDays.length) {
                setActiveTab(activeTabKey);
                return;
            }

            if (isRangeDaysExisting) {
                setActiveTab(activeTabKey);
                return;
            }

            if (!isOpenNotification) {
                setIsOpenNotification(true);
            } else {
                getSelectedValue();
                setIsOpenNotification(false);
                setActiveTab(activeTabKey);
            }
        }
    };

    useEffect(() => {
        setActiveTab(definitionInitialValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const onMouseLeave = () => {
        if (isOpenNotification) {
            setIsOpenNotification(false);
        }
    };

    const toggleQA = useCallback(
        (e?: Event) => handleSave(activeTab, (e as CustomEvent).detail),
        [activeTab, handleSave]
    );

    const targetQaName = useMemo(() => `target-qa-${name}`, [name]);

    useEventListener(
        "datePickerToggleQA",
        toggleQA,
        document.querySelector(`[data-qa-name="${targetQaName}"]`) as HTMLElement
    );

    return (
        <div data-qa-name={targetQaName}>
            <MultiMenu
                placeholderColor={placeholderColor || MultiMenuPlaceholderColor.Black}
                ref={MenuRef}
                placeholder={placeholder}
                displayingValue={displayingValue}
                noHover
                error={props.error}
                actionsComponent={
                    isChangedValue ? (
                        <ActionsComponent
                            isChangedValue={isChangedValue}
                            handleSave={() => handleSave(activeTab, selectedValue)}
                            handleReset={handleReset}
                            isMobile={isMobile}
                        />
                    ) : undefined
                }
                eventBubbling
                handlerOnOpen={getSelectedValue}
                label={label}
                minWidth={minWidth || 353}
                modalTitle={modalTitle}
                disabled={disabled}
            >
                <>
                    <div className="date-control__notification">
                        <NotificationDate isOpen={isOpenNotification} />
                    </div>
                    <div
                        className={classNames("date-control", {
                            "date-control__mobile": isMobile,
                        })}
                    >
                        {keysVisibleTabs.includes(PlaceTimetableButtonKeys.WeekDays) && (
                            <SwitchButton
                                onMouseLeave={onMouseLeave}
                                isMobile={isMobile}
                                activeTab={activeTab}
                                tabName={PlaceTimetableButtonKeys.WeekDays}
                                changeActiveTab={changeActiveTab}
                                title="Дни недели"
                            />
                        )}

                        {keysVisibleTabs.includes(PlaceTimetableButtonKeys.Days) && (
                            <SwitchButton
                                onMouseLeave={onMouseLeave}
                                isMobile={isMobile}
                                activeTab={activeTab}
                                tabName={PlaceTimetableButtonKeys.Days}
                                changeActiveTab={changeActiveTab}
                                title="Дата"
                            />
                        )}
                        {keysVisibleTabs.includes(PlaceTimetableButtonKeys.RangeDays) && (
                            <SwitchButton
                                onMouseLeave={onMouseLeave}
                                isMobile={isMobile}
                                activeTab={activeTab}
                                tabName={PlaceTimetableButtonKeys.RangeDays}
                                changeActiveTab={changeActiveTab}
                                title="Диапазон дат"
                            />
                        )}
                    </div>

                    {activeTab === PlaceTimetableButtonKeys.WeekDays && (
                        <WeekDaysSelect
                            ref={WeekDaysSelectRef}
                            value={weekDaysValue}
                            disabledOptions={disabledWeekDays || disabledOptions}
                            onSelect={handleSelect}
                        />
                    )}
                    {activeTab === PlaceTimetableButtonKeys.Days && (
                        <DaysSelect
                            ref={DaysSelectRef}
                            value={daysValue}
                            disabledOptions={disabledDays}
                            onSelect={handleSelect}
                            isExistingTariff={props.isExistingTariff}
                            fromMonth={fromMonth}
                        />
                    )}
                    {activeTab === PlaceTimetableButtonKeys.RangeDays && multipleRangeSelect && (
                        <RangeDaysMultipleSelect
                            ref={RangeDaysSelectRef}
                            value={rangeDaysValue}
                            disabledOptions={disabledDays}
                            onSelect={handleSelect}
                            numberOfMonths={1}
                            fromMonth={fromMonth}
                        />
                    )}
                    {activeTab === PlaceTimetableButtonKeys.RangeDays && !multipleRangeSelect && (
                        <RangeDaysSelect
                            ref={RangeDaysSelectRef}
                            value={rangeDaysValue}
                            disabledOptions={disabledDays}
                            onSelect={handleSelect}
                            numberOfMonths={1}
                            fromMonth={fromMonth}
                        />
                    )}
                </>
            </MultiMenu>
        </div>
    );
};

export default DateControl;
