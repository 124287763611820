import fetchRequest from "./manager";
import { russpassApiServices } from "../constants/api";
import {
    HotelNotification,
    HotelNotificationsListResponse,
    HotelNotificationsTemplateResponse,
    HotelNotificatorChannelsResponse,
    NotificationsTemplateListResponse,
    NotificatorHotelsResponse,
} from "../containers/notifications/types/notifications";
import queryString from "query-string";
import { ErrorResponse } from "../types/api";

const notificatorPath = russpassApiServices.notificator;

export const getNotificatorTemplates = async () =>
    (await fetchRequest.get(`${notificatorPath}/template`, null, {
        isProtected: true,
    })) as NotificationsTemplateListResponse;

export const getNotificatorChannels = async () =>
    (await fetchRequest.get(`${notificatorPath}/channels`, null, {
        isProtected: true,
    })) as HotelNotificatorChannelsResponse;

export const getNotificatorTemplate = async (templateId: string) =>
    (await fetchRequest.get(`${notificatorPath}/template/${templateId}`, null, {
        isProtected: true,
    })) as HotelNotificationsTemplateResponse;

export const createOrUpdateHotelNotification = (data: HotelNotification): Promise<ErrorResponse> =>
    fetchRequest.post(`${notificatorPath}/notification`, data, {
        isProtected: true,
    });

export const getNotifications = (params?: {
    fired?: boolean;
    page?: number;
    pageSize?: number;
    sort?: string;
    read?: boolean;
    hotelId?: string;
}): Promise<HotelNotificationsListResponse> =>
    fetchRequest.get(`${notificatorPath}/notification`, params, { isProtected: true });

// получение списка отелей, для которых подключены уведомления
export const getNotificationHotels = (): Promise<NotificatorHotelsResponse> =>
    fetchRequest.get(`${notificatorPath}/hotels?isActive=true`, undefined, { isProtected: true });

export const readNotifications = (ids: string[]) =>
    fetchRequest.patch(
        `${notificatorPath}/notification/read?${queryString.stringify({ ids }, { arrayFormat: "comma" })}`,
        undefined,
        {
            isProtected: true,
        }
    );
