import { RentHousesBooking } from "../../../../../types/rentHouses";
import { closeBookingModal } from "../utils/closeBookingModal";
import { ModalWindowRight, Severity, ActionButtonWrapper, useViewportContext } from "@russpass/partner-front-ui";
import { parseModalTitle } from "../../helpers";
import { Container } from "../components/Container";
import { BookingAlert } from "../components/BookingAlert";
import { ReactComponent as IcAlert } from "../../../../../assets/images/icons/ic_alert_circle.svg";
import { ReactComponent as IcCheck } from "../../../../../assets/images/icons/ic_check_circle_24.svg";
import { BaseBookingInfo } from "../components/BaseBookingInfo";
import { Header, HeaderInfo, HeaderSubtitle, HeaderTitle } from "../components/Header";
import React, { useState } from "react";
import { RejectModal } from "./RejectModal";
import { SupportModal } from "./SupportModal";
import { InsuranceAlert } from "./components/InsuranceAlert";
import { InsuranceModal } from "./InsuranceModal";
import { useDialog } from "./hooks/useDialog";
import { DialogueModal } from "../../../dialogues/dialog/guest/DialogueModal";
import moment from "moment";
import { TIME_FORMAT } from "../../../../../constants/dates-format";
import { ReactComponent as IcExclamation } from "../../../../../assets/images/icons/ic_exclamation.svg";
import styles from "./ActiveModal.module.sass";
import classNames from "classnames";
import { useInformationPages } from "../../../../../api/hooks/cmsApi";

type Props = { booking: RentHousesBooking };

export const ActiveModal = ({ booking }: Props) => {
    const subtitle = parseModalTitle(booking);

    const { isMobile } = useViewportContext();

    const [isReject, setIsReject] = useState(false);
    const [isSupport, setIsSupport] = useState(false);
    const [isInsurance, setIsInsurance] = useState(false);

    const { data } = useInformationPages({ language: "ru", slugs: ["renthouse_b2b_insurance"] });

    const isBookingAlert = data?.items[0]?.blocks[0]?.description;

    const {
        isOpen: isOpenDialog,
        dialog,
        open: openDialog,
        isLoading: isLoadingDialog,
        close: closeDialog,
    } = useDialog(booking.object.cmsId, booking.user.externalId);

    if (isOpenDialog && dialog) {
        return <DialogueModal selectedDialogue={dialog} onClose={closeDialog} />;
    }

    if (isSupport) {
        return <SupportModal booking={booking} close={() => setIsSupport(false)} />;
    }

    if (isReject) {
        return <RejectModal booking={booking} close={() => setIsReject(false)} bookingAlert={!!isBookingAlert} />;
    }

    if (isInsurance) {
        return <InsuranceModal booking={booking} close={() => setIsInsurance(false)} />;
    }

    return (
        <ModalWindowRight
            isOpened
            onClose={closeBookingModal}
            isLoadingWithContent={isLoadingDialog}
            headerComponent={
                <Header>
                    <HeaderInfo>
                        <HeaderTitle>Активное бронирование</HeaderTitle>
                        <HeaderSubtitle>{subtitle}</HeaderSubtitle>
                    </HeaderInfo>
                </Header>
            }
            ComponentFooter={
                <ActionButtonWrapper>
                    <button type="button" className="button button--primary button--full-width" onClick={openDialog}>
                        Чат с гостем
                    </button>
                    {!booking.availableToCancellation ? (
                        <button
                            type="button"
                            className={classNames("button button--second button--full-width", styles.ActionButton)}
                            onClick={() => setIsSupport(true)}
                        >
                            <IcExclamation />
                            <span className="ml-8">{isMobile ? "Проблема" : "Сообщить о проблеме"}</span>
                        </button>
                    ) : (
                        <button
                            type="button"
                            className={classNames("button button--second button--full-width", styles.ActionButton)}
                            onClick={() => setIsReject(true)}
                        >
                            Отменить бронирование
                        </button>
                    )}
                </ActionButtonWrapper>
            }
        >
            <Container>
                {booking.paid ? (
                    <BookingAlert
                        severity={Severity.SUCCESS}
                        info={{
                            icon: <IcCheck />,
                            content: "Гость оплатил бронирование. Обсудить детали по заселению можно в чате",
                        }}
                    />
                ) : (
                    <BookingAlert
                        severity={Severity.WARNING}
                        info={{
                            icon: <IcAlert />,
                            content: `Ожидаем оплату от гостя${
                                booking.payUntil ? ` до ${moment(booking.payUntil).format(TIME_FORMAT)}` : ""
                            }. Если она не поступит в срок, бронирование будет автоматически отменено`,
                        }}
                    />
                )}
                <InsuranceAlert onClick={() => setIsInsurance(true)} />
                <BaseBookingInfo booking={booking} />
            </Container>
        </ModalWindowRight>
    );
};
