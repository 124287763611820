import { useFormikContext } from "formik";
import { APARTMENTS_CONTACT_FORM_TOPIC } from "../../../../../constants/support";
import { useRentHousesBookings } from "../../../../../api/hooks/rentHouses";
import moment from "moment";
import { DOTTED_SHORT_DATE_FORMAT, ISO_DATE_FORMAT } from "../../../../../constants/dates-format";
import { RentHousesBookingStatus } from "../../../../../types/rentHouses";
import { Sorting } from "../../../../../constants/filters";
import { useEffect, useMemo } from "react";
import { useRentHousesSupportCauses } from "../../../../../api/hooks/cmsApi";
import { ReservationStatus } from "../../../../../types/cmsApi";
import { SelectInputField } from "@russpass/partner-front-ui";
import { RentHousesBookingFormData } from "./types/types";

const ApartmentFields = () => {
    const { values, setFieldValue } = useFormikContext<RentHousesBookingFormData>();

    const isBookingProblem = values.topic === APARTMENTS_CONTACT_FORM_TOPIC.rentHousesBookingProblem;

    const { data: bookingsResponse } = useRentHousesBookings({
        pageSize: 100,
        stayDateFrom: moment().subtract(14, "d").format(ISO_DATE_FORMAT),
        stayDateTo: moment().format(ISO_DATE_FORMAT),
        status: [RentHousesBookingStatus.ACTIVE, RentHousesBookingStatus.STARTED, RentHousesBookingStatus.COMPLETED],
        sort: Sorting.CheckinDateAcs,
    });

    const bookingOptions = useMemo(
        () =>
            bookingsResponse?.data?.items.map((el) => {
                const from = moment(el.checkinDate).format(DOTTED_SHORT_DATE_FORMAT);
                const to = moment(el.checkoutDate).format(DOTTED_SHORT_DATE_FORMAT);
                const title = el.object.title;

                const label = `${from} - ${to} ${title}`;
                const optionTitle = `${from} - ${to}`;

                return { value: el.id, label, optionTitle, hint: title };
            }) || [],
        [bookingsResponse?.data?.items]
    );

    const activeBookingId = values.bookingId;
    const selectedBooking = useMemo(
        () => bookingsResponse?.data?.items.find((el) => el.id === activeBookingId),
        [activeBookingId, bookingsResponse?.data?.items]
    );
    const selectedBookingOptions = useMemo(
        () => bookingOptions.find((el) => el.value === selectedBooking?.id),
        [bookingOptions, selectedBooking?.id]
    );

    const { data: causesResponse } = useRentHousesSupportCauses(
        {
            reservationStatus:
                selectedBooking?.status === RentHousesBookingStatus.COMPLETED
                    ? ReservationStatus.CLOSED
                    : ReservationStatus.ACTIVE,
        },
        { isDisabledRequest: !selectedBooking }
    );

    const reasonOptions = useMemo(
        () => causesResponse?.items.map((el) => ({ value: el.title, label: el.title })) || [],
        [causesResponse?.items]
    );

    const selectedReasonOption = useMemo(
        () => reasonOptions.find((el) => el.value === values.reason) || {},
        [reasonOptions, values.reason]
    );

    useEffect(() => {
        if (isBookingProblem) return;
        setFieldValue("bookingId", "");
    }, [isBookingProblem, setFieldValue]);

    useEffect(() => {
        // сброс поля Проблема при изменении выбранного бронирования
        setFieldValue("reason", "");
    }, [activeBookingId, setFieldValue]);
    if (!isBookingProblem || !bookingOptions.length) return null;
    return (
        <>
            <SelectInputField
                name="bookingId"
                options={bookingOptions}
                value={selectedBookingOptions}
                handleChange={(value) => setFieldValue("bookingId", value.value)}
                label="Бронирование"
                placeholder="Выбрать"
                valueField="value"
                labelField="label"
                hintField="hint"
                optionTitleField="optionTitle"
            />
            {!!activeBookingId && reasonOptions.length > 0 && (
                <SelectInputField
                    name="reason"
                    options={reasonOptions}
                    value={selectedReasonOption}
                    handleChange={(value) => setFieldValue("reason", value.value)}
                    label="Проблема"
                    placeholder="Выбрать"
                    valueField="value"
                    labelField="label"
                />
            )}
        </>
    );
};

export default ApartmentFields;
