import fetchRequest from "./manager";
import { partnerApiServices, russpassApiServices } from "../constants/api";
import { Tariff, TariffListWithPagination } from "../containers/services/components/tariffs/types";
import { IAtolVat } from "../types/billing";
import { getParamsForRequest } from "../utils";

let billingAccessToken: string;

const billingPartnerServiceUrl = `${russpassApiServices.partnership}${partnerApiServices.billing}`;
const billingServicePath = russpassApiServices.billing;

export const getBillingToken = async () => {
    const { access_token } = await fetchRequest.get(`${billingPartnerServiceUrl}/token`, null, {});
    return access_token;
};

const getAccessToken = async () => {
    if (!billingAccessToken) {
        billingAccessToken = await getBillingToken();
    }
    return billingAccessToken;
};

export const saveTariff = async (data: Tariff) => {
    const res = await fetchRequest.post(`${billingPartnerServiceUrl}/item`, data, {
        isProtected: true,
    });
    return res;
};

export const updateTariff = async (data: Tariff, tariffId: string) => {
    const response = await fetchRequest.put(`${billingPartnerServiceUrl}/item/${tariffId}`, data, {
        isProtected: true,
    });
    return response;
};

export const getTariffs = async (
    id: string | number,
    page?: number,
    size?: number
): Promise<TariffListWithPagination> => {
    const params = getParamsForRequest({
        page: page,
        size: size,
        deleted: 0,
    });
    const content = await fetchRequest.get(`${billingPartnerServiceUrl}/itemType/${id}/item/${params}`, null, {
        isProtected: true,
    });
    return content;
};

export const getVatList = async (): Promise<IAtolVat[]> => {
    const access_token = await getAccessToken();
    const { content } = await fetchRequest.get(`${billingServicePath}/data/immutable/atolVat`, null, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return content;
};
