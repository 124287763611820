import { ReactComponent as IcExternalLink } from "../assets/images/icons/ic_external_link.svg";
import { ReactComponent as IconGear } from "../assets/images/icons/ic_gear.svg";

export const successTitleText = {
    sendToModerate: {
        text: "Отправить на модерацию",
    },
    externalLink: {
        text: "Открыть на RUSSPASS",
        icon: <IcExternalLink className="button__icon button__icon--right" />,
    },
    profileExit: {
        icon: "Выйти",
    },
    settings: {
        text: "Настройки",
        icon: <IconGear className="button__icon button__icon--left" />,
    },
};
