import React, { Children, ReactElement } from "react";
import { ReactComponent as Plus } from "../../../assets/images/icons/ic_plus.svg";
import "./styles.sass";
import classNames from "classnames";
import { ReactComponent as Trash } from "../../../assets/images/icons/ic_trash.svg";
import ValiditySwitcher from "../place-timetable/ValiditySwitcher/ValiditySwitcher";

type Props = {
    label?: string;
    handleDelete?: (uuid: string) => void;
    handleAdd?: () => void;
    disabled?: boolean;
    isShowAdd?: boolean;
    error?: string;
    children: ReactElement[] | ReactElement;
    btnAddItemTitle?: string;
    isShowValiditySwitcher?: boolean;
    validitySwitcherValue?: boolean;
    changeValiditySwitcherValue?: (value: boolean) => void;
};

const Timetable = ({
    label,
    children,
    disabled,
    handleAdd,
    isShowAdd,
    error,
    handleDelete,
    btnAddItemTitle,
    isShowValiditySwitcher,
    validitySwitcherValue,
    changeValiditySwitcherValue,
}: Props) => {
    return (
        <>
            <div className={classNames("timetable")} data-qa-name="timetable">
                <div className="timetable__item">
                    {label && (
                        <div className="timetable__item-title" data-qa-name="timetable-title">
                            {label}
                        </div>
                    )}
                    {isShowValiditySwitcher && (
                        <ValiditySwitcher value={validitySwitcherValue} onChange={changeValiditySwitcherValue} />
                    )}
                    {Children.map(children, (child, key) => {
                        return (
                            <div className="timetable__item-content" key={`item-${key}`}>
                                {!disabled && handleDelete && (
                                    <div className="timetable__item-delete">
                                        <button
                                            type="button"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleDelete(child.props["data-key"]);
                                            }}
                                            data-qa-name="delete-timetable-item-button"
                                        >
                                            <Trash />
                                        </button>
                                    </div>
                                )}
                                {child}
                            </div>
                        );
                    })}
                    {!disabled && isShowAdd && handleAdd && (
                        <div className="timetable__add">
                            <button
                                className="button"
                                type="button"
                                onClick={handleAdd}
                                data-qa-name="add-timetable-item-button"
                            >
                                <div className="button__icon button__icon--left">
                                    <Plus />
                                </div>
                                {btnAddItemTitle}
                            </button>
                        </div>
                    )}
                    {error && <span className="timetable__item-error">{error}</span>}
                </div>
            </div>
        </>
    );
};

export default Timetable;
