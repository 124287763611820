import { createContext, useContext } from "react";
import { Tariff, TariffListWithPagination } from "./types";
import { TariffList } from "./mock";

type ServiceTariffsContextType = {
    tariffs: TariffListWithPagination;
    loadTariffList: (billingProductId?: number | string, page?: number, size?: number) => void;
    isLoadingTariffList?: boolean;
    billingProductId?: number | string;
    onChangeTariffList: (tariffs: Tariff[]) => void;
};
export const ServiceTariffsContext = createContext<ServiceTariffsContextType>({
    tariffs: TariffList,
    loadTariffList: () => {},
    onChangeTariffList: () => {},
    isLoadingTariffList: false,
    billingProductId: undefined,
});

export const useServiceTariffsContext = () => {
    const context = useContext(ServiceTariffsContext);

    if (!context) {
        throw new Error("useHotelTariffsContext must be used within a ModalInfoProvider");
    }

    return context;
};
