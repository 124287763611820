import { useCallback } from "react";
import { getUserInfo } from "../api/profile";
import { UserFullInfo } from "../types/user";
import store from "../store";
import { setUserFullInfo } from "../store/userInfo";

const useLoadUserInfo = () => {
    const loadUserInfo = useCallback(async (email: string, withRedirect401?: boolean): Promise<UserFullInfo | null> => {
        return new Promise(async (resolve, reject) => {
            try {
                const userFullInfo = await getUserInfo(email, withRedirect401);
                store.dispatch(setUserFullInfo(userFullInfo));
                resolve(userFullInfo);
            } catch (error) {
                console.error(error);
                reject(null);
            }
        });
    }, []);

    return {
        loadUserInfo,
    };
};

export default useLoadUserInfo;
