import { DialogueMessageText } from "../constants";

export const getMessageText = (message?: string) => {
    switch (message as DialogueMessageText) {
        case DialogueMessageText.BOOKING_CONFIRMED_BY_OWNER:
            return "Заявка подтверждена хозяином";

        case DialogueMessageText.AUTO_CANCEL_WITHOUT_PAYMENT:
            return "Заявка на бронирование отклонена";

        case DialogueMessageText.AUTO_CANCEL_WITHOUT_PARTNER_CONFIRMATION:
            return "Бронирование отменено";

        case DialogueMessageText.USER_CANCEL:
            return "Заявка отклонена гостем";

        case DialogueMessageText.USER_REFUND:
        case DialogueMessageText.USER_REFUND_BY_SUPPORT:
        case DialogueMessageText.USER_REPEAL_BY_ADMIN:
            return "Бронирование отменено гостем";

        case DialogueMessageText.PARTNER_CANCEL:
            return "Заявка отклонена хозяином";

        case DialogueMessageText.PARTNER_REFUND:
        case DialogueMessageText.PARTNER_REFUND_BY_SUPPORT:
        case DialogueMessageText.PARTNER_REPEAL_BY_ADMIN:
            return "Бронирование отменено хозяином";

        default:
            return message;
    }
};
