import { HotelDiscountsData } from "../../../../types/hotel-discounts";
import { createContext, Dispatch, SetStateAction } from "react";

export type HotelDiscountModalType = {
    onSelectDiscount: (id: string) => Promise<void>;
    onClose: (isUpdate?: boolean, onClosed?: () => void) => void;
    onAddMore: () => void;
    isViewDiscount: boolean;
    selectedDiscountData?: HotelDiscountsData;
    setSelectedDiscountData: Dispatch<SetStateAction<HotelDiscountsData | undefined>>;
    isShowAlert: boolean;
    onCloseAlert: () => void;
};

export const HotelDiscountModalContext = createContext<HotelDiscountModalType | undefined>(undefined);
