import styles from "./special-offers.module.sass";
import { Link } from "react-router-dom";

import { ReactComponent as IconRight } from "../../../../../../../assets/images/icons/ic_chevron.svg";

type Props = {
    offers: { id: string; title: string; url: string }[];
};

const SpecialOffers = ({ offers }: Props) => {
    if (!offers.length) return null;
    return (
        <div className={styles.container}>
            {offers.map((el) => (
                <Link to={{ pathname: el.url, state: { discountId: el.id } }} className={styles.offer} key={el.id}>
                    <span className={styles.title}>{el.title}</span>
                    <span className={styles.icon}>
                        <IconRight />
                    </span>
                </Link>
            ))}
        </div>
    );
};

export default SpecialOffers;
