import React, { useCallback, useMemo } from "react";
import history from "../../../history";
import routes from "../../../constants/routes";
import { useLocation } from "react-router-dom";
import { sendEvent } from "../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../product_analytics/constants";
import { CommonLayout, Card, CardsGrid } from "@russpass/partner-front-ui";
import { navbarDocuments, navbarDocumentsNames } from "../constants";
import { useUpdateUserReports } from "../hooks/useUpdateUserReports";
import useUserInfo from "../../../hooks/useUserInfo";

const Contracts = () => {
    const location = useLocation<{ background: Location }>();
    const background = location?.state?.background || location;

    const { isToBeAgreed, isReview, isRevision, isSignedSimpleRegistration } = useUserInfo();

    const { isChecked, isLoading } = useUpdateUserReports();

    const desc = useMemo(() => {
        if (isRevision) {
            sendEvent(AnalyticEvent.room_moderation_failure_page);
            return "Есть замечания";
        }
        if (isToBeAgreed) {
            return "Ознакомиться и принять условия сотрудничества";
        }
        if (isReview || isSignedSimpleRegistration) {
            return "На проверке";
        }
        if (isChecked) {
            return "Подписано";
        }
    }, [isToBeAgreed, isReview, isChecked, isSignedSimpleRegistration, isRevision]);

    const styles = useMemo(() => {
        if (isToBeAgreed) {
            return {
                backgroundColor: "#FFFFFF",
                boxShadow: "0 12px 16px rgba(0, 0, 0, 0.04)",
                border: "none",
            };
        }
        if (isReview) {
            return {
                cursor: "default",
            };
        }
        return {};
    }, [isReview, isToBeAgreed]);

    const stylesDesc = useMemo(() => {
        return {
            color: isChecked ? "#007470" : "#E76D00",
            cursor: isReview && "default",
        };
    }, [isChecked, isReview]);

    const onClick = useCallback(() => {
        if (isToBeAgreed || isChecked) {
            history.push({
                pathname: routes.offer,
                state: {
                    background,
                },
            });
        }
        sendEvent(AnalyticEvent.open_conditions);
        if (isReview) {
            return;
        }
    }, [background, isToBeAgreed, isReview, isChecked]);

    return (
        <CommonLayout
            pageTitle="Документы"
            navbar={isChecked ? navbarDocuments : undefined}
            isLoadingWithoutContent={isLoading}
            activeTabName={navbarDocumentsNames.contracts}
            isRouting
            handlerChangeRoute={(route) => {
                history.push(route);
            }}
        >
            <CardsGrid>
                <Card title="Оферта" desc={desc} onClick={onClick} styles={styles} stylesDesc={stylesDesc} />
            </CardsGrid>
        </CommonLayout>
    );
};

export default Contracts;
