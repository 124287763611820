import { russpassApiServices } from "../constants/api";
import { jiraSD } from "../constants/jiraSD";
import { Customer, Issue, AttachedFile, JiraUser, IssueRequest_v2 } from "../types/jira-sd";
import queryString from "query-string";

import fetchRequest from "./manager";
import CustomError from "../lib/custom-error";

const jirasdServicePath = russpassApiServices.jirasd;

/**
 * Создание клиента
 * @param data - данные о клиенте
 */
export const createCustomer = async (data: { email: string; fullName: string }) => {
    const customer: Customer = await fetchRequest.post(`${jirasdServicePath}/servicedeskapi/customer`, data, {
        headers: {
            "X-ExperimentalApi": "opt-in",
            Authorization: `Basic ${jiraSD.token}`,
        },
    });
    if (customer) {
        return customer;
    }
    throw new CustomError({
        message: "Ошибка создания клиента",
    });
};

export const getUser = async (username: string) => {
    const user: JiraUser = await fetchRequest.get(
        `${jirasdServicePath}/api/2/user`,
        {
            username,
        },
        {
            headers: {
                Authorization: `Basic ${jiraSD.token}`,
            },
        }
    );
    if (user) {
        return user;
    }
    throw new CustomError({
        message: "Ошибка получения пользователя",
    });
};

export const updateUserName = async (username: string, displayName: string) => {
    const user: any = await fetchRequest.put(
        `${jirasdServicePath}/api/2/user?username=${username}`,
        {
            displayName,
        },
        {
            headers: {
                Authorization: `Basic ${jiraSD.token}`,
            },
        }
    );

    if (user) {
        return user;
    }
    throw new CustomError({
        message: "Ошибка обновления данных пользователя",
    });
};

export const createIssue_v2 = async (request: IssueRequest_v2) => {
    const issue: Issue = await fetchRequest.post(`${jirasdServicePath}/servicedeskapi/request`, request, {
        headers: {
            Authorization: `Basic ${jiraSD.token}`,
        },
    });
    if (issue) {
        return issue;
    }
    throw new CustomError({
        message: "Ошибка создания обращения",
    });
};

export const setIssueReporter = async (issueId: string, username: string) =>
    fetchRequest.put(
        `${jirasdServicePath}/api/2/issue/${issueId}`,
        {
            fields: {
                reporter: {
                    name: username,
                },
            },
        },
        {
            headers: {
                Authorization: `Basic ${jiraSD.token}`,
            },
        }
    );

export const attachTempFiles = async (files: File[], userToken?: string) => {
    let formData = new FormData();
    files.forEach((file) => formData.append("file", file));

    const attachedFiles: {
        temporaryAttachments: AttachedFile[];
    } = await fetchRequest.file(
        `${jirasdServicePath}/servicedeskapi/servicedesk/${jiraSD.id}/attachTemporaryFile`,
        formData,
        {
            headers: {
                "X-Atlassian-Token": "no-check",
                "X-ExperimentalApi": "opt-in",
                Authorization: `Basic ${userToken ?? jiraSD.token}`,
            },
        }
    );
    return attachedFiles.temporaryAttachments;
};

export const attachFilesToIssue = async (files: AttachedFile[], issueId: string, userToken?: string) => {
    const issueAttachments = {
        temporaryAttachmentIds: files.map((item) => item.temporaryAttachmentId),
        public: true,
    };
    return await fetchRequest.post(
        `${jirasdServicePath}/servicedeskapi/request/${issueId}/attachment`,
        issueAttachments,
        {
            headers: {
                "X-ExperimentalApi": "opt-in",
                Authorization: `Basic ${userToken ?? jiraSD.token}`,
            },
        }
    );
};

/**
 * Изменение пароля пользователя
 * @param username - имя пользователя (почта)
 * @param newPassword - новый пароль
 */
export const changeUserPassword = (username: string, newPassword: string) =>
    fetchRequest.put(
        queryString.stringifyUrl({
            url: `${jirasdServicePath}/api/2/user/password`,
            query: {
                username,
            },
        }),
        {
            password: newPassword,
        },
        {
            headers: {
                "X-ExperimentalApi": "opt-in",
                Authorization: `Basic ${jiraSD.token}`,
            },
        }
    );
