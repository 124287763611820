import { MultiMenu } from "@russpass/partner-front-ui";
import classNames from "classnames";
import { ReactComponent as IconCalendar } from "../../../../assets/images/icons/navigation/ic_calendar.svg";
import useDateRange from "./useDateRange";
import { useCallback, useEffect } from "react";
import { DatePicker } from "@russpass/partner-front-ui";
import { DateUtils } from "react-day-picker";
import { useEventListener } from "@russpass/partner-front-ui";

const targetQaName = "target-qa-reports";

type DateControlProps = {
    onChange: (startDate?: string, endDate?: string) => void;
};

type ActionsComponentProps = {
    isChangedValue?: boolean;
    handleSave: () => Promise<void>;
    handleReset: () => Promise<void>;
};

type ButtonSelectDatesProps = {
    displayingValue: string;
    handleClickButton: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

const ActionsComponent: React.FC<ActionsComponentProps> = ({ isChangedValue, handleSave, handleReset }) => {
    return (
        <>
            {isChangedValue && (
                <div className="actions-wrapper">
                    <div className={classNames("button button--primary")} onClick={handleSave}>
                        Сохранить
                    </div>
                    <div className="button" onClick={handleReset}>
                        Сбросить
                    </div>
                </div>
            )}
        </>
    );
};

const ButtonSelectDates: React.FC<ButtonSelectDatesProps> = ({ displayingValue, handleClickButton }) => {
    return (
        <button className="button button--second" onClick={handleClickButton} data-qa-name="select-date-button">
            <span className="button__icon button__icon--left">
                <IconCalendar />
            </span>
            {displayingValue}
        </button>
    );
};

const DateControl = ({ onChange }: DateControlProps) => {
    const {
        handleClickButton,
        handleSelect,
        isChangedValue,
        handleSave,
        handleReset,
        rangeDates,
        MenuRef,
        displayingValue,
        disabledDays,
        selectedRangeDates,
    } = useDateRange();

    useEffect(() => {
        rangeDates.from && rangeDates.to && onChange(rangeDates.from, rangeDates.to);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeDates]);

    const toggleQA = useCallback((e?: Event) => handleSave(), [handleSave]);

    useEventListener(
        "datePickerToggleQA",
        toggleQA,
        document.querySelector(`[data-qa-name="${targetQaName}"]`) as HTMLElement
    );

    return (
        <div data-qa-name={targetQaName}>
            <MultiMenu
                ref={MenuRef}
                noHover
                actionsComponent={
                    <ActionsComponent
                        isChangedValue={isChangedValue}
                        handleSave={handleSave}
                        handleReset={handleReset}
                    />
                }
                hideArrow={true}
                renderComponent={() => (
                    <ButtonSelectDates displayingValue={displayingValue} handleClickButton={handleClickButton} />
                )}
            >
                {/*TODO привести к единым типам ЛКП и DatePicker*/}
                <DatePicker
                    //@ts-ignore
                    selectedDays={[selectedRangeDates]}
                    disabledDays={disabledDays}
                    handleChange={(day: Date) => handleSelect(DateUtils.addDayToRange(day, selectedRangeDates))}
                    numberOfMonths={2}
                    toMonth={new Date()}
                />
            </MultiMenu>
        </div>
    );
};

export default DateControl;
