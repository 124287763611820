import { ReactComponent as ArrowDown } from "../../../../assets/images/icons/ic_chevron_down.svg";
import styles from "./orders-filters.module.sass";
import { MultiMenu, RadioButton } from "@russpass/partner-front-ui";
import { filterKindOptions } from "../../constants";
import React, { useMemo } from "react";
import { useOrderFiltersContext } from "../../context/useOrderFiltersContext";

const TypesFilter = () => {
    const { changeFilter, filters } = useOrderFiltersContext();

    const renderComponent = useMemo(() => {
        return (
            <button className="button button--second">
                {(filterKindOptions.find((option) => option.value === filters.type) || filterKindOptions[0]).label}
                <ArrowDown className="button__icon--right" />
            </button>
        );
    }, [filters.type]);

    return (
        <div className={styles.orders_filters__kind}>
            <MultiMenu noHover renderComponent={renderComponent} hideArrow>
                {filterKindOptions.map((option) => (
                    <RadioButton
                        key={`filterKindOptions-${option.value}`}
                        label={option.label}
                        value={option.value}
                        isActive={option.value === filters.type}
                        handleClick={(value: any) => changeFilter({ type: value })}
                    />
                ))}
            </MultiMenu>
        </div>
    );
};

export default TypesFilter;
