import history from "../../../history";
import routes from "../../../constants/routes";
import { CommonLayout } from "@russpass/partner-front-ui";
import NotificationsList from "./components/list";
import "./styles.sass";
import { ReceivedNotificationsProvider } from "./context/ReceivedNotificationsProvider";
import { EditNotificationsProvider } from "../edit/context/EditNotificationsProvider";
import { successTitleText } from "../../../constants/layout";
import { useReceivedNotificationsContext } from "./context/useReceivedNotificationsContext";
import { useMemo } from "react";

const ReceivedNotificationsContent = () => {
    const { isFirstLoading } = useReceivedNotificationsContext();
    const topRightButton = useMemo(
        () =>
            !isFirstLoading
                ? {
                      title: (
                          <>
                              {successTitleText.settings.text} {successTitleText.settings.icon}
                          </>
                      ),
                      onClick: () => history.push(routes.notificationsSettings),
                      iconMobile: successTitleText.settings.icon,
                  }
                : undefined,
        [isFirstLoading]
    );
    return (
        <CommonLayout pageTitle="Уведомления" topRightButton={topRightButton} isLoadingWithoutContent={isFirstLoading}>
            <NotificationsList />
        </CommonLayout>
    );
};

const ReceivedNotifications = () => (
    <ReceivedNotificationsProvider>
        <EditNotificationsProvider>
            <ReceivedNotificationsContent />
        </EditNotificationsProvider>
    </ReceivedNotificationsProvider>
);

export default ReceivedNotifications;
