import React from "react";
import { Form, Formik, FormikProps } from "formik";
import { VICARIOUS_AUTHORITY } from "../../constants";
import { OfferForm } from "../../types";
import "../../styles.sass";
import StepWrap from "../stepWrap";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";
import { IBank, IBankField } from "../../../../../../types/profile";
import { useStepOneForm } from "./hooks/useStepOneForm";
import {
    AuthorityDateIssueField,
    AuthorityFileField,
    AuthorityNumberField,
    AuthorityTermField,
    BankBicField,
    BankCheckingAccountField,
    BankCorrAccountField,
    BankNameField,
    BankTreasuryAccountField,
    EgrnFileField,
    GuideCertificate,
    InnFileField,
    NdsField,
    OgrnFileField,
    PassportField,
    RegistrationAddressField,
    SESertificateFileField,
    SignatoryFioField,
    SignatoryPositionField,
    SignatoryRoleField,
    SignLegalBasisField,
} from "../fields";
import { SignatoryRoleEnum } from "../../../../../../enums/profile";
import { Alert, Severity } from "@russpass/partner-front-ui";
import styles from "./styles.module.sass";

type Props = {
    onStepChange: (data: OfferForm, step: number) => void;
    hasNextStep: boolean;
    initialValues: OfferForm | null;
    handleSignOffer: (values: OfferForm) => void;
    onValidateBankAccount: (data: IBank, fieldType: IBankField) => void;
    bankErrors: { corrAccount?: string; checkingAccount?: string };
};

const StepOneForm = ({
    onStepChange,
    hasNextStep,
    initialValues,
    handleSignOffer,
    onValidateBankAccount,
    bankErrors,
}: Props) => {
    const {
        initValues,
        schema,
        offerTypeStatus: { isHotel, isApartment, isGuide },
        legalStateStatus: { isUL, isIP, isSE },
        moderationText,
    } = useStepOneForm({ initialValues });

    return (
        <Formik
            initialValues={initValues}
            validationSchema={schema}
            enableReinitialize
            validateOnMount
            onSubmit={() => {}}
        >
            {({ isValid, values, submitForm }: FormikProps<OfferForm>) => {
                const showAuthority =
                    values.applicationAdditionData.signLegalBasis === VICARIOUS_AUTHORITY ||
                    values.applicationAdditionData.signatoryRole === SignatoryRoleEnum.AGENT;

                return (
                    <StepWrap
                        buttonText={hasNextStep ? "Продолжить" : undefined}
                        onSubmit={() => {
                            submitForm().then(() => {
                                if (!isValid || bankErrors.checkingAccount || bankErrors.corrAccount) return;
                                if (hasNextStep) {
                                    onStepChange(values, 2);
                                } else {
                                    handleSignOffer(values);
                                    sendEvent(AnalyticEvent.sign_the_offer, {
                                        nds: values.applicationAdditionData.ndsRate || "",
                                        bik_bank: values.applicationAdditionData.bank.bic || "",
                                        bank_name: values.applicationAdditionData.bank.name || "",
                                        corr_account: values.applicationAdditionData.bank.corrAccount || "",
                                        sing_person_fio: values.applicationAdditionData.signatory || "",
                                        checking_account: values.applicationAdditionData.bank.checkingAccount || "",
                                        sign_person_position: values.applicationAdditionData.position || "",
                                        legal_authority_to_sign: values.applicationAdditionData.signLevelBasis || "",
                                    });
                                }
                            });
                        }}
                        onRepeat={() => {
                            handleSignOffer(values);
                            sendEvent(AnalyticEvent.repeat_sign);
                        }}
                    >
                        <div className="content-wrapper">
                            <div className="company-info__form content-wrapper__form">
                                <Form>
                                    {moderationText && (
                                        <div className={styles.ModerationAlertWrapper}>
                                            <Alert severity={Severity.WARNING}>{moderationText}</Alert>
                                        </div>
                                    )}
                                    <div className="company-info__title">Основная информация</div>

                                    <div className="mb-32">
                                        <SignatoryFioField name="applicationAdditionData.signatory" />
                                        {isUL && <SignatoryPositionField name="applicationAdditionData.position" />}
                                        {((isUL && (isHotel || isApartment)) || isIP) && (
                                            <NdsField name="applicationAdditionData.ndsRate" />
                                        )}
                                        {isUL && <SignLegalBasisField name="applicationAdditionData.signLegalBasis" />}
                                        {!isUL && isApartment && (
                                            <SignatoryRoleField name="applicationAdditionData.signatoryRole" />
                                        )}
                                        {showAuthority && (
                                            <>
                                                <AuthorityNumberField name="applicationAdditionData.authority.number" />
                                                <AuthorityDateIssueField name="applicationAdditionData.authority.dateIssue" />
                                                <AuthorityTermField name="applicationAdditionData.authority.term" />
                                            </>
                                        )}
                                    </div>

                                    {!(isUL || isIP) && (
                                        <>
                                            <div className="company-info__title">Паспортные данные</div>
                                            <div className="mb-32">
                                                <PassportField name="applicationAdditionData.identityNumber" />
                                                <RegistrationAddressField name="applicationAdditionData.registrationAddress" />
                                            </div>
                                        </>
                                    )}

                                    <div className="company-info__title">Банковские реквизиты</div>
                                    <BankBicField
                                        name="applicationAdditionData.bank.bic"
                                        onValidate={(e) => {
                                            onValidateBankAccount(
                                                { ...values.applicationAdditionData.bank, bic: e.target.value },
                                                "bic"
                                            );
                                        }}
                                    />
                                    <BankNameField name="applicationAdditionData.bank.name" />
                                    <BankCorrAccountField
                                        name="applicationAdditionData.bank.corrAccount"
                                        onValidate={(e) => {
                                            onValidateBankAccount(
                                                {
                                                    ...values.applicationAdditionData.bank,
                                                    corrAccount: e.target.value,
                                                },
                                                "corrAccount"
                                            );
                                        }}
                                        error={bankErrors.corrAccount}
                                    />
                                    <BankCheckingAccountField
                                        name="applicationAdditionData.bank.checkingAccount"
                                        onValidate={(e) => {
                                            onValidateBankAccount(
                                                {
                                                    ...values.applicationAdditionData.bank,
                                                    checkingAccount: e.target.value,
                                                },
                                                "checkingAccount"
                                            );
                                        }}
                                        error={bankErrors?.checkingAccount}
                                    />
                                    <BankTreasuryAccountField name="applicationAdditionData.bank.treasuryAccount" />

                                    {(isHotel || isApartment || isGuide) && <InnFileField name="filesData.inn" />}
                                    {(isHotel || isApartment || isGuide) && (isIP || isUL) && (
                                        <OgrnFileField name="filesData.ogrn" title={!isIP ? "ОГРН" : "ОГРНИП"} />
                                    )}
                                    {isGuide && <GuideCertificate name="filesData.guideCertificate" />}
                                    {isApartment && <EgrnFileField name="filesData.egrn" />}
                                    {showAuthority && <AuthorityFileField name="filesData.authority" />}
                                    {isSE && <SESertificateFileField name="filesData.selfEmploymentCertificate" />}
                                </Form>
                            </div>
                        </div>
                    </StepWrap>
                );
            }}
        </Formik>
    );
};

export default StepOneForm;
