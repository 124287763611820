import React, { useCallback, useMemo, useState } from "react";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { HotelStatuses } from "../../../../../enums/hotels";
import history from "../../../../../history";
import { useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import { progressBarHotel, progressBarIds } from "../constants/common";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";
import CopyLink from "../components/copy-link";
import { useHotelDataContext } from "../../hotel-view/hotel-data-context";
import useUserInfo from "../../../../../hooks/useUserInfo";

type Props = {
    isValidHotelInfo?: boolean;
    isValidTariffs?: boolean;
    isValidRooms?: boolean;
    isViewCopyHotelId?: boolean;
    isLoading?: boolean;
};

const useControlAddHotelPage = ({
    isValidTariffs,
    isValidRooms,
    isValidHotelInfo,
    isViewCopyHotelId,
    isLoading,
}: Props) => {
    const location = useLocation<{ status: string }>();

    const { hotelInfoData, isTravelLineHotel, checkExistTravelLineHotel } = useHotelDataContext();
    const { isSignedSimpleRegistration } = useUserInfo();

    const [statusRequest, setStatusRequest] = useState<StatusRequestEnum | null>(null);
    const [isViewBanner, setIsViewBanner] = useState<boolean>(false);

    const changeIsViewStatusRequest = useCallback((status: StatusRequestEnum | null) => {
        setStatusRequest(status);
    }, []);

    const progressBar = useMemo(() => {
        if (isViewCopyHotelId || isLoading) {
            return;
        }
        let _progressBar = cloneDeep(progressBarHotel);
        _progressBar = _progressBar.map((item) => {
            if (item.id === progressBarIds.info && isValidHotelInfo) {
                item.isFullFilled = true;
            }
            if (item.id === progressBarIds.tariffs && isValidTariffs) {
                item.isFullFilled = true;
            }
            if (item.id === progressBarIds.rooms && isValidRooms) {
                item.isFullFilled = true;
            }
            return item;
        });
        return _progressBar;
    }, [isValidHotelInfo, isValidRooms, isValidTariffs, isViewCopyHotelId, isLoading]);

    const descriptionPage = useMemo(() => {
        if (!isLoading && isViewCopyHotelId && hotelInfoData?.hotelId && !isTravelLineHotel) {
            return <CopyLink id={hotelInfoData.hotelId} />;
        }
    }, [isLoading, isViewCopyHotelId, hotelInfoData?.hotelId, isTravelLineHotel]);

    const getActionButtonSettings = useCallback(
        (onSubmitModeration: () => void, onChangeInfo: () => void) => {
            let defaultTitle = "Отправить на модерацию";
            if (isSignedSimpleRegistration) {
                defaultTitle = "Опубликовать объект";
            }
            if (isTravelLineHotel) {
                defaultTitle = "Завершить обновление";
            }
            const defaultSettings = {
                title: defaultTitle,
                action: onSubmitModeration,
            };

            const goHotelInfoSettings = {
                title: "Перейти к Информации об объекте",
                action: onChangeInfo,
            };

            const getSettings = () => {
                if (!isValidTariffs) {
                    return {
                        title: "Перейти к Тарифам",
                        action: () => {
                            history.push({
                                pathname: `${location.pathname}/tariffs`,
                                state: location?.state,
                            });
                        },
                    };
                }
                if (!isValidRooms) {
                    return {
                        title: "Перейти к Номерам",
                        action: () => {
                            history.push({
                                pathname: `${location.pathname}/rooms`,
                                state: location?.state,
                            });
                        },
                    };
                }
                return defaultSettings;
            };

            if (isTravelLineHotel) {
                if (!checkExistTravelLineHotel) {
                    return goHotelInfoSettings;
                } else {
                    return getSettings();
                }
            } else {
                switch (hotelInfoData?.status) {
                    case HotelStatuses.New:
                        return goHotelInfoSettings;
                    case HotelStatuses.Draft:
                        return getSettings();
                    case HotelStatuses.Import:
                    case HotelStatuses.Active:
                    case HotelStatuses.Inactive:
                    case HotelStatuses.Deleted:
                        return {
                            title: null,
                            action: null,
                        };
                    default:
                        return defaultSettings;
                }
            }
        },
        [
            isValidRooms,
            isValidTariffs,
            location,
            isTravelLineHotel,
            hotelInfoData,
            checkExistTravelLineHotel,
            isSignedSimpleRegistration,
        ]
    );

    const handlerCloseStatusWindow = useCallback(() => {
        return new Promise((resolve, reject) => {
            changeIsViewStatusRequest(null);
            sendEvent(AnalyticEvent.close_submitting_for_moderation_page);
            if (statusRequest === StatusRequestEnum.Success) {
                setIsViewBanner(true);
                resolve(true);
            }
            reject(true);
        });
    }, [statusRequest, changeIsViewStatusRequest]);

    return {
        statusRequest,
        changeIsViewStatusRequest,
        isViewBanner,
        getActionButtonSettings,
        progressBar,
        handlerCloseStatusWindow,
        descriptionPage,
    };
};

export default useControlAddHotelPage;
