import React from "react";
import useHotelIntegrationEditForm from "./useHotelIntegrationEditForm";
import EditFormLayout from "../components/edit-form-layout";
import useServicesData from "../../../components/hotel-services/useServicesData";

const HotelIntegration = () => {
    const { isLoadingForm, hotelData, onChangeData, onSubmit } = useHotelIntegrationEditForm();

    const { isLoadingServices, services } = useServicesData();

    return (
        <EditFormLayout
            hotelData={hotelData}
            services={services}
            onChangeHotelData={onChangeData}
            isLoadingForm={isLoadingForm || isLoadingServices}
            onSubmit={onSubmit}
        />
    );
};

export default HotelIntegration;
