import { combineReducers } from "redux";
import userInfo from "./userInfo";
import { configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
    user: userInfo,
});

const store = configureStore({
    reducer: rootReducer,
});

export default store;
