/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { AgeRestriction, ServiceDataClient, ServiceDataGet } from "../helpers/types";
import { INITIAL_SERVICE_DATA } from "../helpers/mock";
import { getAgeRestriction } from "../../../../api/refbook";
import { NotificationProps } from "../../../../components/notification";
import { GET_DATA_ERROR } from "../../../../constants/errors";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import routes from "../../../../constants/routes";
import { getService } from "../../../../api/service";
import { getVatList } from "../../../../api/billing";
import { getImageById } from "../../../../api/files";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { getEventTypes } from "../../../../api/dictionaries";
import { sortingAscByField } from "../../../../utils/sorting";
import { IAtolVat } from "../../../../types/billing";
import { EventType } from "../../types";
import { navbarService, NavbarServiceNamesEnum } from "../helpers/constants";
import _isEqual from "lodash/isEqual";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
import { getStringFromHoursMinutes } from "../../utils";
import history from "../../../../history";

type Props = {
    callNotification: (body: NotificationProps) => void;
    loadTariffList: (billingProductId: string, page?: number, size?: number) => void;
};

const useService = (params: Props) => {
    const { callNotification, loadTariffList } = params;
    const location = useLocation();

    const match = matchPath(location.pathname, {
        path: routes.servicesView,
        exact: true,
        strict: false,
    });

    // @ts-ignore
    const serviceId = match?.params?.id;

    const [initialData, setInitialData] = useState<ServiceDataGet>();

    // тут храним изначальное состояние что бы сравнивать были ли изменения
    const [compareData, setCompareData] = useState(INITIAL_SERVICE_DATA);
    const [data, setData] = useState(INITIAL_SERVICE_DATA); // тут данные из формы

    const [initialLoading, setInitialLoading] = useState<boolean>(true);

    const [step, setStep] = useState<NavbarServiceNamesEnum>(navbarService[0].name);

    const [optionsEventTypes, setOptionsEventTypes] = useState<EventType[]>([]);
    const [optionsAgeRestriction, setOptionsAgeRestriction] = useState<AgeRestriction[]>([]);
    const [optionsNdsRate, setOptionsNdsRate] = useState<IAtolVat[]>([]);

    const getServiceDataRequest = useCallback(
        () =>
            new Promise(async (resolve) => {
                try {
                    const service = await getService(serviceId);
                    const servicePhotoList = [...service.imageExplorePreview, ...service.images].map((img) => {
                        return {
                            url: getImageById(img),
                            id: img,
                        };
                    });

                    const formattedServiceData = {
                        ...service,
                        servicePhotoList,
                        duration: getStringFromHoursMinutes(
                            Number(service.duration.hours),
                            Number(service.duration.minutes)
                        ),
                        ndsPercent: service.ndsPercents, //TODO backend task remove "S"
                    };

                    setInitialData(service);
                    setData(formattedServiceData);
                    setCompareData(formattedServiceData);

                    if (service.billingProductId) {
                        await loadTariffList(service.billingProductId);
                    }

                    return resolve(service);
                } catch (err) {
                    console.error(err);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        []
    );

    const reloadServiceData = () => {
        if (serviceId) {
            setInitialLoading(true);
            getServiceDataRequest().then(() => setInitialLoading(false));
        }
    };

    const loadData = () => {
        setInitialLoading(true);

        let requestArray = [
            new Promise(async (resolve) => {
                try {
                    const data = await getEventTypes();
                    const eventTypes: EventType[] = [...data.rows]
                        .filter((eventType) => eventType.status === "active")
                        .map((eventType) => {
                            return { id: eventType.id, title: eventType.title };
                        })
                        .sort(sortingAscByField("title"));
                    setOptionsEventTypes(eventTypes);
                    return resolve(eventTypes);
                } catch (err) {
                    console.error(err);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const ageRestrictions = await getAgeRestriction();
                    setOptionsAgeRestriction(ageRestrictions);
                    return resolve(ageRestrictions);
                } catch (err) {
                    console.error(err);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const response = await getVatList();
                    setOptionsNdsRate(response);
                    return resolve(response);
                } catch (err) {
                    console.error(err);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        ];
        if (serviceId) {
            requestArray.push(getServiceDataRequest());
        }
        Promise.all(requestArray).then(() => {
            setInitialLoading(false);
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    const onChangeData = useCallback(
        (values: ServiceDataClient) =>
            setData((prevState) => ({
                ...prevState,
                ...values,
            })),
        []
    );

    const changeTab = (newStep: NavbarServiceNamesEnum) => {
        if (newStep !== step) {
            setStep(newStep);
            const searchParams = newStep === NavbarServiceNamesEnum["Service"] ? "" : `?tab=${newStep}`;

            history.push({
                pathname: location.pathname,
                search: searchParams,
                state: location?.state,
            });
        }
    };

    const BILLING_FIELDS = ["personalizedTickets", "paymentType", "ndsPercent"] as Array<keyof ServiceDataClient>;

    const hasChangesExceptBilling = !_isEqual(_omit(data, BILLING_FIELDS), _omit(compareData, BILLING_FIELDS));
    const hasBillingChanges = !_isEqual(_pick(data, BILLING_FIELDS), _pick(compareData, BILLING_FIELDS));

    return {
        data,
        initialData,
        step,
        optionsEventTypes,
        optionsAgeRestriction,
        optionsNdsRate,
        onChangeData,
        reloadServiceData,
        changeTab,
        initialLoading,
        hasChangesExceptBilling,
        hasBillingChanges,
    };
};

export default useService;
