import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DialogueModal } from "../dialog/guest/DialogueModal";
import eventDispatcher from "../../../../lib/event-dispatcher";
import routes from "../../../../constants/routes";
import { useGetDialoguesList } from "../../../../api/hooks/dialogues";
import getCustomErrorMessage from "../../../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../../../constants/errors";
import getDataGridDialoguesColumns from "./getDataGridDialoguesColumns";
import { DialogBookingStatus, DialogueModel } from "../types/DialogueModel";
import "./styles.sass";
import { FiltersContainer } from "../../components/FiltersContainer";
import { useObjectsFilter } from "../../hooks/useObjectsFilter";
import { SelectFilter } from "../../components/SelectFilter";
import { useStatusFilter } from "../hooks/useStatusFilter";
import { ReactComponent as SortIcon } from "../../../../assets/images/icons/ic_filter.svg";
import { DialogueMessageStatus } from "../constants";
import classNames from "classnames";
import { useNavbar } from "../hooks/useNavbar";
import SupportChatModal from "../dialog/support";
import { isRentHousesSupportChatEnabled } from "../../../../constants/feature-toggles";
import { CommonLayout, DataGrid, Filter, StatusRequestEnum } from "@russpass/partner-front-ui";

type DialoguesItemRouteParams = {
    id: string;
};

const Dialogues = () => {
    const history = useHistory();
    const itemRouteMatch = useRouteMatch<DialoguesItemRouteParams>(routes.dialoguesItem);
    const selectedItemId = useMemo(() => itemRouteMatch?.params.id, [itemRouteMatch]);

    const [selectedItem, setSelectedItem] = useState<DialogueModel>();

    const [listKey, setListKey] = useState(Date.now());

    const { objectOptions, activeObject, setActiveObject: setActiveObjectOption } = useObjectsFilter(true);
    const { statusOptions, activeStatus, setActiveStatus: setActiveStatusOption } = useStatusFilter();

    const [page, setPage] = useState(1);
    const handlePageChange = useCallback((page: number) => setPage(page + 1), []);

    const setActiveStatus = useCallback(
        (status: string) => {
            setPage(1);
            setActiveStatusOption(status);
        },
        [setActiveStatusOption]
    );

    const setActiveObject = useCallback(
        (object: string) => {
            setPage(1);
            setActiveObjectOption(object);
        },
        [setActiveObjectOption]
    );

    const handleItemClick = useCallback(
        (item: DialogueModel) => {
            setSelectedItem(item);

            history.push(routes.dialoguesItem.replace(":id", `${item.id}`));
        },
        [history]
    );

    const {
        data: response,
        isLoading: loading,
        error,
        mutate,
    } = useGetDialoguesList({
        bookingStatus: (activeStatus || undefined) as DialogBookingStatus,
        cmsObjectId: activeObject ? activeObject : undefined,
        page,
        pageSize: 10,
    });

    const handleModalClose = useCallback(() => {
        setSelectedItem(undefined);
        history.push(routes.dialogues);
        setListKey(Date.now());
        eventDispatcher.updateCounter({ route: routes.dialogues });
        void mutate();
    }, [history, mutate]);

    useEffect(() => {
        void mutate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, activeStatus, activeObject]);

    const totalPages = useMemo(() => {
        return response ? Math.ceil(response.total / response.pageSize) : 0;
    }, [response]);

    useEffect(() => {
        if (!selectedItemId || !response?.items.length) return;

        const item = response?.items.find((dialogue) => dialogue.id === selectedItemId);
        setSelectedItem(item);
    }, [selectedItemId, response?.items]);

    useEffect(() => {
        if (!error) return;

        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: getCustomErrorMessage(error, COMMON_RELOAD_ERROR),
        });
    }, [error]);

    const navbar = useNavbar();

    return (
        <CommonLayout pageTitle="Сообщения" navbar={navbar}>
            <DataGrid
                key={listKey}
                isLoading={loading}
                isEmpty={!response?.total && !activeObject && !activeStatus}
                dataGridSetting={{
                    data: response?.items || [],
                    columns: getDataGridDialoguesColumns(),
                    onSelect: (value) => handleItemClick(value as DialogueModel),
                    dataGridRowClassName: (item) =>
                        classNames({
                            dialogues__row__unread:
                                (item as DialogueModel).lastMessage?.partnerStatus === DialogueMessageStatus.NEW,
                        }),
                }}
                emptySettings={{ title: "Нет сообщений" }}
                paginate={{
                    page: page - 1,
                    totalPages,
                    onPageChange: handlePageChange,
                }}
                filters={
                    <FiltersContainer>
                        {objectOptions.length > 1 && (
                            <SelectFilter options={objectOptions} value={activeObject} onChange={setActiveObject} />
                        )}
                        <Filter
                            options={statusOptions}
                            value={activeStatus}
                            onChange={setActiveStatus}
                            filterIcon={<SortIcon />}
                            modalTitle="Сортировка"
                        />
                    </FiltersContainer>
                }
            />
            <>
                <DialogueModal selectedDialogue={selectedItem} onClose={handleModalClose} />
                {isRentHousesSupportChatEnabled && <SupportChatModal />}
            </>
        </CommonLayout>
    );
};

export default Dialogues;
