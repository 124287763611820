import useModal from "../../../../hooks/useModal";
import { useCallback, useEffect, useState } from "react";
import { getHotelsAll } from "../../../../api/hotels/hotels";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import getCustomErrorMessage from "../../../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../../../constants/errors";
import { HotelItemCms } from "../../../hotels/all/types";
import loadTemplates from "../utils/loadTemplates";
import { NotificationChannelKey, NotificationsTemplate } from "../../types/notifications";
import { cloneDeep } from "lodash";
import history from "../../../../history";
import routes from "../../../../constants/routes";
import { createOrUpdateHotelNotification, getNotifications } from "../../../../api/notficator";
import { buildNotification } from "../utils/buildNotification";
import { useLocation } from "react-router-dom";
import { loadHasEmailChannel } from "../utils/loadHasEmailChannel";
import { useNotificationsSettingsContext } from "../../settings/context/useNotificationsSettingsContext";
import useUserInfo from "../../../../hooks/useUserInfo";

export type NotificationFormValues = { templates: FormNotificationTemplate[] };

export type FormNotificationTemplate = NotificationsTemplate & {
    active: boolean;
    emailEnabled: boolean;
    email?: string;
    reserveEmail?: string;
    threshold?: number;
    hotels?: { id: string; label: string }[];
    notificationId?: string;
};

const useEditNotifications = () => {
    const { isOpen, open: openModal, close } = useModal();

    const [hotelId, setHotelId] = useState<string | undefined>(undefined);
    const isNew = !hotelId;

    const [isLoading, setIsLoading] = useState(false);

    const open = useCallback(
        (hotelId?: string) => {
            setHotelId(hotelId);
            openModal();
        },
        [openModal]
    );

    const [data, setData] = useState<
        | { initialValues: NotificationFormValues; cmsHotels: HotelItemCms[]; title?: string; hasEmailChannel: boolean }
        | undefined
    >();

    const { userFullInfo } = useUserInfo();

    const load = useCallback(
        async (hotelId?: string) => {
            try {
                setData(undefined);
                setIsLoading(true);
                if (!userFullInfo) return;

                const templates = await loadTemplates();
                if (!templates.length) return;

                const cmsHotels: HotelItemCms[] = (await getHotelsAll()).cms || [];

                const hasEmailChannel = await loadHasEmailChannel();

                if (!hotelId) {
                    setData({
                        initialValues: {
                            templates: templates.map((el) => ({
                                ...el,
                                active: false,
                                emailEnabled: false,
                                email: userFullInfo.email,
                            })),
                        },
                        cmsHotels,
                        hasEmailChannel,
                    });
                    return;
                }

                const hotelNotifications = (await getNotifications({ hotelId })).data.items;

                const hotel = cmsHotels.find((el) => el.cmsId === hotelId);
                if (!hotel) return;

                const formTemplates = templates.map((el) => {
                    const notification = hotelNotifications.find(
                        (notification) => notification.templateId === el.templateId
                    );

                    if (!notification?.active) {
                        return {
                            ...el,
                            active: false,
                            emailEnabled: false,
                            email: userFullInfo.email,
                        };
                    }

                    const [channel1, channel2] = notification.channels.filter(
                        (el) => el.key === NotificationChannelKey.EMAIL
                    );

                    const { address: email } = channel1 || {};
                    const { address: reserveEmail } = channel2 || {};

                    const template: FormNotificationTemplate = {
                        ...el,
                        active: notification.active,
                        emailEnabled: hasEmailChannel && !!email,
                        email: email || userFullInfo.email,
                        threshold: notification.threshold,
                        notificationId: notification.id,
                    };

                    if (reserveEmail) {
                        template.reserveEmail = reserveEmail;
                    }

                    return template;
                });

                setData({
                    initialValues: {
                        templates: formTemplates,
                    },
                    cmsHotels,
                    title: hotel.title,
                    hasEmailChannel,
                });
            } catch (e) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
                });
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        },
        [userFullInfo]
    );

    const { refresh: refreshSettings } = useNotificationsSettingsContext();

    const isSettingsPage = useLocation().pathname === routes.notificationsSettings;

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = useCallback(
        async (values: { templates: FormNotificationTemplate[] }) => {
            if (isSubmitting) return;

            setIsSubmitting(true);

            const initialTemplates = data?.initialValues?.templates;
            if (!initialTemplates) return;

            const templates = cloneDeep(values.templates);

            try {
                if (hotelId) {
                    for (const template of templates) {
                        const initialTemplate = initialTemplates.find((el) => el.templateId === template.templateId);
                        if (!initialTemplate || (!initialTemplate.active && !template.active)) continue;

                        await createOrUpdateHotelNotification(buildNotification({ template, hotelId }));
                    }
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Успешно сохранено",
                    });
                } else {
                    const activeTemplates = templates.filter((el) => el.active);

                    for (const activeTemplate of activeTemplates) {
                        if (!activeTemplate.hotels) continue;
                        for (const hotel of activeTemplate.hotels) {
                            await createOrUpdateHotelNotification(
                                buildNotification({ template: activeTemplate, hotelId: hotel.id })
                            );
                        }
                    }

                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Успешно сохранено",
                    });
                }

                if (isSettingsPage) {
                    refreshSettings();
                    close();
                } else {
                    history.push(routes.notificationsSettings);
                }
            } catch (e) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
                });
                console.error(e);
                load();
            } finally {
                setIsSubmitting(false);
            }
        },
        [close, data?.initialValues?.templates, hotelId, isSettingsPage, isSubmitting, load, refreshSettings]
    );

    useEffect(() => {
        if (!isOpen) return;

        load(hotelId);
    }, [hotelId, isOpen, load]);

    return {
        hotelId,
        isNew,
        isOpen,
        isLoading,
        close,
        data,
        handleSubmit,
        open,
    };
};

export default useEditNotifications;
