import { CommonLayout, Card, CardsGrid } from "@russpass/partner-front-ui";
import React, { useEffect, useMemo } from "react";

import history from "../../../../../../history";
import { useLocation } from "react-router-dom";
import "./hotel-discounts.sass";
import HotelDiscountsItem from "./hotel-discounts-item";
import { navbarDiscounts, navbarDiscountsNames } from "../../constants/hotel-discounts";
import { useHotelDiscountModalContext } from "./context/discount-modal/useHotelDiscountModalContext";
import { useHotelDiscountStoreContext } from "./context/discounts-store/useHotelDiscountStoreContext";

const HotelDiscountsList = () => {
    const location = useLocation();

    const {
        isLoadingDiscounts,
        hotelRoomsData,
        hotelData,
        activeTabKey,
        setActiveTabKey,
        discountsData,
        loadHotelData,
        isNew,
    } = useHotelDiscountStoreContext();

    const { onSelectDiscount, onAddMore } = useHotelDiscountModalContext();

    useEffect(() => {
        loadHotelData().then(() => {
            if (isNew) onAddMore();
        });
    }, [isNew, loadHotelData, onAddMore]);

    const tabsList = useMemo(
        () => [
            {
                dataName: navbarDiscountsNames.active,
            },
            {
                dataName: navbarDiscountsNames.moderation,
                emptyText: "Акций на модерации нет",
            },
            {
                dataName: navbarDiscountsNames.archive,
                emptyText: "Акций в архиве нет",
            },
        ],
        []
    );

    const topRightButton = useMemo(
        () => ({
            title: "Добавить акцию",
            onClick: onAddMore,
            isAddButton: true,
        }),
        [onAddMore]
    );

    const backButton = useMemo(
        () => ({
            title: "Назад",
            onClick: () => history.push(location.pathname.replace("/discounts", "")),
        }),
        [location.pathname]
    );

    return (
        <CommonLayout
            pageTitle="Акции"
            backButton={backButton}
            topRightButton={topRightButton}
            isLoadingWithoutContent={isLoadingDiscounts}
            description={hotelData.title}
            navbar={navbarDiscounts}
            activeTabName={activeTabKey}
            handlerChangeActiveTab={setActiveTabKey}
        >
            {tabsList.map(({ dataName, emptyText }) => {
                const emptyTextElem = emptyText ? <p className="discounts-list__empty">{emptyText}</p> : undefined;
                const isActiveTab = dataName === navbarDiscountsNames.active;
                return (
                    <div data-name={dataName} key={dataName}>
                        <CardsGrid>
                            {isActiveTab && (
                                <Card
                                    title="Добавить акцию"
                                    onClick={onAddMore}
                                    styles={{
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "0 12px 16px rgba(0, 0, 0, 0.04)",
                                        border: "none",
                                    }}
                                />
                            )}
                            {discountsData.length
                                ? discountsData.map((discount) => (
                                      <HotelDiscountsItem
                                          onSelectDiscount={onSelectDiscount}
                                          data={discount}
                                          roomData={hotelRoomsData}
                                          key={discount.id}
                                      />
                                  ))
                                : emptyTextElem}
                        </CardsGrid>
                    </div>
                );
            })}
        </CommonLayout>
    );
};

export default HotelDiscountsList;
