import { PaginatedListParams } from "../../types/api";
import useSWR, { SWRConfiguration } from "swr";
import { russpassApiServices } from "../../constants/api";
import fetchRequest from "../manager";
import { InformationPage, RentHousesSupportCause, ReservationStatus } from "../../types/cmsApi";

export const useInformationPages = (
    params: PaginatedListParams & { slugs?: string[]; language?: string },
    config?: SWRConfiguration
) =>
    useSWR(
        { url: `${russpassApiServices.cmsApi}/information_pages`, params },
        ({ url, params }) => fetchRequest.get(url, params) as Promise<{ items: InformationPage[] }>,
        config
    );

export const useRentHousesSupportCauses = (
    params: {
        reservationStatus: ReservationStatus;
    },
    { isDisabledRequest, ...config }: SWRConfiguration & { isDisabledRequest?: boolean } = {}
) =>
    useSWR(
        !isDisabledRequest ? { url: `${russpassApiServices.cmsApi}/renthouse_support_causes`, params } : null,
        ({ url, params }) =>
            fetchRequest.get(url, { ...params, forPartner: true }) as Promise<{
                items: RentHousesSupportCause[];
            }>,
        config
    );
