export const navbarRestaurantNames = {
    all: "ALL",
    draft: "DRAFT",
    moderation: "MODERATION",
    published: "PUBLISHED",
    archive: "ARCHIVED",
    rejected: "DECLINED",
};

export const navbarRestaurantsAll = [
    {
        label: "Все",
        name: navbarRestaurantNames.all,
    },
    {
        label: "Черновик",
        name: navbarRestaurantNames.draft,
    },
    {
        label: "На модерации",
        name: navbarRestaurantNames.moderation,
    },
    {
        label: "Опубликовано",
        name: navbarRestaurantNames.published,
    },
    {
        label: "Отказано в публикации",
        name: navbarRestaurantNames.rejected,
    },
    {
        label: "Архив",
        name: navbarRestaurantNames.archive,
    },
];

export const restaurantStatuses = {
    deleted: "deleted",
    inactive: "inactive",
    draft: "draft",
    import: "import",
    active: "active",
};

export const restaurantStatusesLabels = {
    active: "Опубликовано",
    draft: "Черновик",
    import: "На модерации",
    archive: "В архиве",
    rejected: "Отказано",
};

export const modalAlertDetails = {
    title: "Вы уверены, что хотите удалить объект?",
    OKButton: "Удалить",
    cancelButton: "Отменить",
};
