import React, { useEffect, useState } from "react";
import { SelectInputField } from "@russpass/partner-front-ui";
import { getHousingTypesDictionary, HousingTypesRes } from "../../../../../../../../api/dictionaries";
import { useFormikContext } from "formik";

export const HousingTypeSelect: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<{ housingType: string }>();
    const [options, setOptions] = useState<HousingTypesRes["rows"]>([]);

    useEffect(() => {
        const getOptions = async () => {
            const { rows } = await getHousingTypesDictionary({
                useForHotels: true,
                useForRenthouses: false,
                page: 1,
                pageSize: 20,
            });
            setOptions(rows);
        };

        void getOptions();
    }, []);

    return (
        <SelectInputField
            name="housingType"
            label="Тип объекта"
            placeholder="Выбрать"
            options={options}
            value={options.find((el) => el.id === values.housingType)}
            valueField="id"
            labelField="title"
            handleChange={(value) => {
                setFieldValue("housingType", value.id);
            }}
        />
    );
};
