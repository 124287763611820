import styles from "./Tooltip.module.sass";
import { PropsWithChildren, useRef } from "react";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";

type Props = PropsWithChildren<{
    isOpen: boolean;
    close: () => void;
}>;

export const Tooltip = ({ children, isOpen, close }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, close);

    if (!isOpen) return null;
    return (
        <div className={styles.Tooltip} ref={ref}>
            {children}
        </div>
    );
};
