import React from "react";
import styles from "./orders-filters.module.sass";
import TypesFilter from "./types-filter";
import DatesFilter from "./dates-filter";
import OrdersSorter from "./orders-sorter";
import OrderNumberFilter from "./OrderNumberFilter";

type Props = {
    isShowFiltersType: boolean;
};

const OrdersFilters = ({ isShowFiltersType }: Props) => (
    <div className={styles.orders_filters}>
        <OrderNumberFilter />
        {isShowFiltersType && <TypesFilter />}
        <OrdersSorter />
        <DatesFilter />
    </div>
);

export default OrdersFilters;
