import React, { useCallback, useEffect, useState } from "react";
import { ModalWindowRight } from "@russpass/partner-front-ui";
import history from "../../../../history";
import routes from "../../../../constants/routes";
import mammoth from "mammoth";
import "./styles.sass";
import { Loader, StatusRequestEnum, NotFound } from "@russpass/partner-front-ui";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../../constants/errors";
import { getOffer } from "../../../../api/offer";
import ActionForm from "../action-form";
import { useLocation } from "react-router-dom";
import { sendEvent } from "../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../product_analytics/constants";
import useUserInfo from "../../../../hooks/useUserInfo";

type Message = {
    type: string;
    message: string;
};

type ConvertedResult = {
    messages: Message[];
    value: string;
};

const Offer = () => {
    const location = useLocation<{ background: Location }>();
    const background = location?.state?.background || location;
    const { isChecked } = useUserInfo();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [offerHTML, setOfferHTML] = useState<string>("");
    const [isErrorViewOffer, setIsErrorViewOffer] = useState<boolean>(false);

    const submitOffer = useCallback(() => {
        history.push(routes.partnerInfo, {
            background,
        });
        sendEvent(AnalyticEvent.proceed_click);
    }, [background]);

    const getSubmitComponent = () => {
        if (isLoading || isErrorViewOffer || isChecked) {
            return;
        }
        return <ActionForm onClick={submitOffer} buttonText="Продолжить" />;
    };

    const { userFullInfo } = useUserInfo();

    useEffect(() => {
        setIsLoading(true);
        setIsErrorViewOffer(false);
        setOfferHTML("");
        if (!userFullInfo) return;
        getOffer(userFullInfo.applicationId)
            .then((response: ReadableStream) => {
                new Response(response).arrayBuffer().then(function (buffer) {
                    mammoth.convertToHtml({ arrayBuffer: buffer }).then((result: ConvertedResult) => {
                        result.messages.forEach((message: Message) => {
                            console.warn(`${message.type}: ${message.message}`);
                            return message;
                        });
                        setOfferHTML(result.value);
                        setIsLoading(false);
                    });
                });
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                setIsErrorViewOffer(true);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: GET_DATA_ERROR,
                });
            });
    }, [userFullInfo]);

    return (
        <ModalWindowRight
            isOpened={true}
            onClose={() => {
                history.push(routes.contracts);
            }}
            ComponentFooter={getSubmitComponent()}
        >
            {isLoading && (
                <div className="offer-loader">
                    <div className="offer-loader__content">
                        <Loader width="60px" />
                    </div>
                </div>
            )}

            {!isLoading && (
                <div className="content-wrapper">
                    <div className="content-wrapper__form">
                        <div className="offer-text" dangerouslySetInnerHTML={{ __html: offerHTML }} />
                    </div>
                </div>
            )}

            {!isLoading && isErrorViewOffer && <NotFound history={history} />}
        </ModalWindowRight>
    );
};

export default Offer;
