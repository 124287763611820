import { Loader } from "@russpass/partner-front-ui";
import classes from "./page-loader.module.sass";

const PageLoader = () => (
    <div className={classes.loaderPageWrapper}>
        <Loader />
    </div>
);

export default PageLoader;
