import { HotelRoomData, HotelRoomFormData, Occupancy } from "../types/hotel-room";
import { cloneDeep } from "lodash";
import uuid from "react-native-uuid";

export const hotelRoomOccupancy = (): Occupancy => ({
    id: uuid.v4() as string,
    adults: 2,
    child: 0,
    minAge: null,
    maxAge: null,
    isChild: false,
    isNew: true,
    bedType: "",
});

export const hotelRoomData: HotelRoomData = {
    id: "",
    name: "",
    description: "",
    amenities: [],
    rates: [],
    occupancies: [cloneDeep(hotelRoomOccupancy())],
    images: [],
    cmsRoomId: "",
    oldTLRoomId: null,
};

export const hotelRoomFormData: HotelRoomFormData = {
    ...cloneDeep(hotelRoomData),
    amenities: [],
    rates: [],
};

export const getChildOccupancyTL = (): Occupancy => {
    return {
        id: uuid.v4() as string,
        adults: 0,
        child: 1,
        minAge: null,
        maxAge: null,
        isChild: true,
        bedType: "childBandExtraBed",
    };
};
