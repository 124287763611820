import React, { useCallback } from "react";
import { RadioButton } from "@russpass/partner-front-ui";
import styles from "./styles.module.sass";

type ValiditySwitcherProps = {
    value?: boolean;
    onChange?: (newValue: boolean) => void;
};

const ValiditySwitcher = ({ value, onChange }: ValiditySwitcherProps) => {
    const handleChange = useCallback(
        (newValue: number) => {
            onChange && onChange(!!newValue);
        },
        [onChange]
    );
    return (
        <div className={styles.container}>
            <RadioButton
                isActive={!!value}
                value={1}
                label={"По режиму работы места проведения"}
                handleClick={handleChange}
            />
            <RadioButton isActive={!value} value={0} label={"Задать свой период действия"} handleClick={handleChange} />
        </div>
    );
};

export default ValiditySwitcher;
