import { HotelData } from "../types";
import styles from "../hotel-edit-form.module.sass";

type Props = {
    data: HotelData;
};

const EditFormComment = ({ data }: Props) => {
    return (
        <div className="content-wrapper">
            <div className={styles.hotel_edit_form__comment}>{data.rejectionReason}</div>
        </div>
    );
};

export default EditFormComment;
