import React, { useCallback, useContext, useMemo } from "react";
import { ModalWindowRight, StatusRequestCard, StatusRequestEnum } from "@russpass/partner-front-ui";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";
import { HotelDataContext } from "../../../hotel-view/hotel-data-context";
import { BNOVO_LK_URL, BNOVO_MANUAL_URL, TL_LK_URL, TL_MANUAL_URL } from "../../../../../../constants/hotels";
import { StatusRequestCardSubtitleError } from "../../../../../../constants/errors";
import useUserInfo from "../../../../../../hooks/useUserInfo";

type Props = {
    status: StatusRequestEnum | null;
    onClose: () => void;
    externalId: string;
    handleRepeat: () => void;
};
const HotelRequestStatus = ({ onClose, externalId, status, handleRepeat }: Props) => {
    const { hotelInfoData, isNewTravelLineHotel, isTravelLineHotel } = useContext(HotelDataContext);

    const { isSignedSimpleRegistration } = useUserInfo();

    const handleAddMore = useCallback(() => {
        window.open(isNewTravelLineHotel || isTravelLineHotel ? TL_MANUAL_URL : BNOVO_MANUAL_URL);
        sendEvent(AnalyticEvent.download_instruction_click);
    }, [isNewTravelLineHotel, isTravelLineHotel]);

    const LKLink = useMemo(() => {
        const href = isNewTravelLineHotel || isTravelLineHotel ? TL_LK_URL : BNOVO_LK_URL;
        return (
            <a className="link" href={href} target="_blank" rel="noreferrer">
                {isNewTravelLineHotel || isTravelLineHotel ? "в TravelLine" : "кабинете Bnovo"}
            </a>
        );
    }, [isNewTravelLineHotel, isTravelLineHotel]);

    const title = useMemo(() => {
        if (isTravelLineHotel) {
            return {
                success: "Обновление завершено",
                error: (
                    <>
                        "Не удалось завершить обновление"
                        <br />
                        {`\u00ab${hotelInfoData.title}\u00bb`}
                    </>
                ),
            };
        }
        return {
            success: (
                <>
                    {`Объект \u00ab${hotelInfoData.title}\u00bb отправлен `}
                    <br />
                    {isSignedSimpleRegistration ? "на публикацию" : "на модерацию"}
                </>
            ),
            error: (
                <>
                    {isSignedSimpleRegistration
                        ? "Не удалось отправить на публикацию"
                        : "Не удалось отправить на модерацию"}
                    <br />
                    {`\u00ab${hotelInfoData.title}\u00bb`}
                </>
            ),
        };
    }, [isTravelLineHotel, isSignedSimpleRegistration, hotelInfoData]);

    const subTitle = useMemo(() => {
        let success = (
            <>
                {"Ему присвоен ID в системе RUSSPASS:"}
                <br />
                <span className="footnote">{externalId}</span>
                <br />
                {"Введите ID в "}
                {LKLink}
                {" для синхронизации данных"}
            </>
        );
        if (isTravelLineHotel) {
            success = (
                <>
                    {"Скопируйте RUSSPASS ID отеля"}
                    <br />
                    <span className="footnote">{externalId}</span>
                    <br />
                    {"и введите его в "}
                    {LKLink}
                    {" для синхронизации данных"}
                </>
            );
        }
        return {
            success: success,
            error: StatusRequestCardSubtitleError,
        };
    }, [externalId, LKLink, isTravelLineHotel]);

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);

    return (
        <ModalWindowRight isOpened={!!status} onClose={onClose} position={1} isHideCloseButton>
            <StatusRequestCard
                status={status}
                title={title}
                subtitle={subTitle}
                buttonTitle={{
                    addMore: "Скачать инструкцию",
                }}
                addMoreClassName="button--second"
                handleAddMore={handleAddMore}
                handleClose={onClose}
                handleRepeat={handleRepeat}
                handleBeforeClose={handleSendEvent}
            />
        </ModalWindowRight>
    );
};

export default HotelRequestStatus;
