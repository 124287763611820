import { ModalWindowRight, StatusRequestCard } from "@russpass/partner-front-ui";
import ActionForm from "../../../action-form";
import { Loader } from "@russpass/partner-front-ui";
import { errorMessage, simpleRegistrationSuccessMessage, successMessage } from "../../constants";
import React, { useCallback } from "react";
import { FormManagerContext } from "../../context/form-manager";
import history from "../../../../../../history";
import routes from "../../../../../../constants/routes";
import useLoadUserInfo from "../../../../../../hooks/useLoadUserInfo";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";
import useUserInfo from "../../../../../../hooks/useUserInfo";

type Props = {
    onSubmit: () => void;
    onRepeat: () => void;
    buttonText?: string;
};

const StepWrap: React.FC<Props> = ({ onSubmit, onRepeat, buttonText, children }) => {
    const { loadUserInfo } = useLoadUserInfo();
    const { userFullInfo } = useUserInfo();

    const handleAddMore = useCallback(() => {
        if (!userFullInfo) return;
        loadUserInfo(userFullInfo.email).then(() => {
            history.push({
                pathname: routes.hotelsNew,
                state: {
                    background: {
                        pathname: routes.hotelsAll,
                    },
                },
            });
        });
    }, [loadUserInfo, userFullInfo]);

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);

    return (
        <FormManagerContext.Consumer>
            {({ isLoadingForm, onCloseOffer, simpleRegistration, statusSigningOffer }) => {
                const handleCloseOffer = () => {
                    onCloseOffer();
                    sendEvent(AnalyticEvent.close_click);
                };
                return (
                    <ModalWindowRight
                        isOpened={true}
                        title={!statusSigningOffer ? "Оферта" : ""}
                        onClose={onCloseOffer}
                        ComponentFooter={
                            !statusSigningOffer ? <ActionForm onClick={onSubmit} buttonText={buttonText} /> : undefined
                        }
                    >
                        {isLoadingForm && (
                            <div className="offer-loader">
                                <div className="offer-loader__content">
                                    <Loader width="60px" />
                                </div>
                            </div>
                        )}
                        {statusSigningOffer && statusSigningOffer.length && (
                            <StatusRequestCard
                                status={statusSigningOffer}
                                title={{
                                    success: simpleRegistration
                                        ? simpleRegistrationSuccessMessage.title
                                        : successMessage.title,
                                    error: errorMessage.title,
                                }}
                                subtitle={{
                                    success: simpleRegistration
                                        ? simpleRegistrationSuccessMessage.subtitle
                                        : successMessage.subtitle(userFullInfo?.email),
                                    error: errorMessage.subtitle,
                                }}
                                handleRepeat={onRepeat}
                                handleClose={handleCloseOffer}
                                buttonTitle={{
                                    addMore: "Добавить отель",
                                }}
                                handleAddMore={simpleRegistration ? handleAddMore : undefined}
                                handleBeforeClose={handleSendEvent}
                            />
                        )}
                        {(!statusSigningOffer || !statusSigningOffer.length) && children}
                    </ModalWindowRight>
                );
            }}
        </FormManagerContext.Consumer>
    );
};

export default StepWrap;
