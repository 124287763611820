import posthog from "posthog-js";
import { AnalyticEvent } from "./constants";

export type EnumLiteralsOf<T extends object> = T[keyof T];

export type AnalyticEventType = EnumLiteralsOf<typeof AnalyticEvent>;

export const sendEvent = (event: AnalyticEventType, ...params: any[]): void => {
    if (!event) {
        return;
    } else {
        const eventFunction = eventsHashTable[event];
        typeof eventFunction === "function" && eventFunction(...params);
    }
};

// posthog
const phcapture = (eventName: any, fields?: any) => posthog.capture(eventName, { ...fields });

export const eventsHashTable: { [K in string]: (params?: any[]) => void } = {
    [AnalyticEvent.open_conditions]: (params: any) => {
        phcapture(AnalyticEvent.open_conditions, params);
    },
    [AnalyticEvent.proceed_click]: (params: any) => {
        phcapture(AnalyticEvent.proceed_click, params);
    },
    [AnalyticEvent.upload_offer]: (params: any) => {
        phcapture(AnalyticEvent.upload_offer, params);
    },
    [AnalyticEvent.load_inn]: (params: any) => {
        phcapture(AnalyticEvent.load_inn, params);
    },
    [AnalyticEvent.load_ogrn]: (params: any) => {
        phcapture(AnalyticEvent.load_ogrn, params);
    },
    [AnalyticEvent.sign_the_offer]: (params: any) => {
        phcapture(AnalyticEvent.sign_the_offer, params);
    },
    [AnalyticEvent.sign_success]: (params: any) => {
        phcapture(AnalyticEvent.sign_success, params);
    },
    [AnalyticEvent.sign_fail]: (params: any) => {
        phcapture(AnalyticEvent.sign_fail, params);
    },
    [AnalyticEvent.repeat_sign]: (params: any) => {
        phcapture(AnalyticEvent.repeat_sign, params);
    },
    [AnalyticEvent.close_click]: (params: any) => {
        phcapture(AnalyticEvent.close_click, params);
    },
    [AnalyticEvent.add_object_click]: (params: any) => {
        phcapture(AnalyticEvent.add_object_click, params);
    },
    [AnalyticEvent.add_object_button]: (params: any) => {
        phcapture(AnalyticEvent.add_object_button, params);
    },
    [AnalyticEvent.add_object_error_page]: (params: any) => {
        phcapture(AnalyticEvent.add_object_error_page, params);
    },
    [AnalyticEvent.repeat_add_object]: (params: any) => {
        phcapture(AnalyticEvent.repeat_add_object, params);
    },
    [AnalyticEvent.add_object_error_page_close]: (params: any) => {
        phcapture(AnalyticEvent.add_object_error_page_close, params);
    },
    [AnalyticEvent.object_click]: (params: any) => {
        phcapture(AnalyticEvent.object_click, params);
    },
    [AnalyticEvent.object_info]: (params: any) => {
        phcapture(AnalyticEvent.object_info, params);
    },
    [AnalyticEvent.object_info_save_button]: (params: any) => {
        phcapture(AnalyticEvent.object_info_save_button, params);
    },
    [AnalyticEvent.without_save_button_click]: (params: any) => {
        phcapture(AnalyticEvent.without_save_button_click, params);
    },
    [AnalyticEvent.services_info_save_button]: (params: any) => {
        phcapture(AnalyticEvent.services_info_save_button, params);
    },
    [AnalyticEvent.tariff_click]: (params: any) => {
        phcapture(AnalyticEvent.tariff_click, params);
    },
    [AnalyticEvent.tariff_save_button]: (params: any) => {
        phcapture(AnalyticEvent.tariff_save_button, params);
    },
    [AnalyticEvent.add_another_tariff]: (params: any) => {
        phcapture(AnalyticEvent.add_another_tariff, params);
    },
    [AnalyticEvent.rooms_click]: (params: any) => {
        phcapture(AnalyticEvent.rooms_click, params);
    },
    [AnalyticEvent.rooms_info_save_button]: (params: any) => {
        phcapture(AnalyticEvent.rooms_info_save_button, params);
    },
    [AnalyticEvent.rooms_info_save_success]: (params: any) => {
        phcapture(AnalyticEvent.rooms_info_save_success, params);
    },
    [AnalyticEvent.rooms_info_save_error_page]: (params: any) => {
        phcapture(AnalyticEvent.rooms_info_save_error_page, params);
    },
    [AnalyticEvent.add_another_room]: (params: any) => {
        phcapture(AnalyticEvent.add_another_room, params);
    },
    [AnalyticEvent.repeat_rooms_info]: (params: any) => {
        phcapture(AnalyticEvent.repeat_rooms_info, params);
    },
    [AnalyticEvent.close_rooms_info_saving]: (params: any) => {
        phcapture(AnalyticEvent.close_rooms_info_saving, params);
    },
    [AnalyticEvent.submitting_for_moderation]: (params: any) => {
        phcapture(AnalyticEvent.submitting_for_moderation, params);
    },
    [AnalyticEvent.download_instruction_click]: (params: any) => {
        phcapture(AnalyticEvent.download_instruction_click, params);
    },
    [AnalyticEvent.close_submitting_for_moderation_page]: (params: any) => {
        phcapture(AnalyticEvent.close_submitting_for_moderation_page, params);
    },
    [AnalyticEvent.repeat_submitting_for_moderation]: (params: any) => {
        phcapture(AnalyticEvent.repeat_submitting_for_moderation, params);
    },
    [AnalyticEvent.submitting_for_moderation_success_page]: (params: any) => {
        phcapture(AnalyticEvent.submitting_for_moderation_success_page, params);
    },
    [AnalyticEvent.submitting_for_moderation_error_page]: (params: any) => {
        phcapture(AnalyticEvent.submitting_for_moderation_error_page, params);
    },
    [AnalyticEvent.moderation_failure_page]: (params: any) => {
        phcapture(AnalyticEvent.moderation_failure_page, params);
    },
    [AnalyticEvent.room_moderation_failure_page]: (params: any) => {
        phcapture(AnalyticEvent.room_moderation_failure_page, params);
    },
    [AnalyticEvent.partners_landing_pageview]: (params: any) => {
        phcapture(AnalyticEvent.partners_landing_pageview, params);
    },
    [AnalyticEvent.partners_landing_become_partner]: (params: any) => {
        phcapture(AnalyticEvent.partners_landing_become_partner, params);
    },
    [AnalyticEvent.partners_landing_inn_check_result]: (params: any) => {
        phcapture(AnalyticEvent.partners_landing_inn_check_result, params);
    },
    [AnalyticEvent.partners_landing_lkp_entering]: (params: any) => {
        phcapture(AnalyticEvent.partners_landing_lkp_entering, params);
    },
    [AnalyticEvent.partners_landing_lkp_send_application]: (params: any) => {
        phcapture(AnalyticEvent.partners_landing_lkp_send_application, params);
    },
    [AnalyticEvent.partners_landing_lkp_send_application_pageview]: (params: any) => {
        phcapture(AnalyticEvent.partners_landing_lkp_send_application_pageview, params);
    },
    [AnalyticEvent.enter_lpk_click]: (params: any) => {
        phcapture(AnalyticEvent.enter_lpk_click, params);
    },
    [AnalyticEvent.forgot_password_click]: (params: any) => {
        phcapture(AnalyticEvent.forgot_password_click, params);
    },
    [AnalyticEvent.write_to_us_click]: (params: any) => {
        phcapture(AnalyticEvent.write_to_us_click, params);
    },
    [AnalyticEvent.load_object_card]: (params: any) => {
        phcapture(AnalyticEvent.load_object_card, params);
    },
    [AnalyticEvent.save_and_out_button_click]: (params: any) => {
        phcapture(AnalyticEvent.save_and_out_button_click, params);
    },
    [AnalyticEvent.tariff_remove_button]: (params: any) => {
        phcapture(AnalyticEvent.tariff_remove_button, params);
    },
    [AnalyticEvent.room_remove_button]: (params: any) => {
        phcapture(AnalyticEvent.room_remove_button, params);
    },
    [AnalyticEvent.confirmation_bookings_page]: (params: any) => {
        phcapture(AnalyticEvent.confirmation_bookings_page, params);
    },
    [AnalyticEvent.active_bookings_page]: (params: any) => {
        phcapture(AnalyticEvent.active_bookings_page, params);
    },
    [AnalyticEvent.complited_bookings_page]: (params: any) => {
        phcapture(AnalyticEvent.complited_bookings_page, params);
    },
    [AnalyticEvent.rejected_bookings_page]: (params: any) => {
        phcapture(AnalyticEvent.rejected_bookings_page, params);
    },
    [AnalyticEvent.host_renthouse_click]: (params: any) => {
        phcapture(AnalyticEvent.host_renthouse_click, params);
    },
    [AnalyticEvent.request_page]: (params: any) => {
        phcapture(AnalyticEvent.request_page, params);
    },
    [AnalyticEvent.calendar_page]: (params: any) => {
        phcapture(AnalyticEvent.calendar_page, params);
    },
    [AnalyticEvent.confirm_click]: (params: any) => {
        phcapture(AnalyticEvent.confirm_click, params);
    },
    [AnalyticEvent.reject_click]: (params: any) => {
        phcapture(AnalyticEvent.reject_click, params);
    },
    [AnalyticEvent.chat_with_guest]: (params: any) => {
        phcapture(AnalyticEvent.chat_with_guest, params);
    },
    [AnalyticEvent.confirm_page]: (params: any) => {
        phcapture(AnalyticEvent.confirm_page, params);
    },
    [AnalyticEvent.reject_page]: (params: any) => {
        phcapture(AnalyticEvent.reject_page, params);
    },
    [AnalyticEvent.confirm_request_click]: (params: any) => {
        phcapture(AnalyticEvent.confirm_request_click, params);
    },
    [AnalyticEvent.reject_request_click]: (params: any) => {
        phcapture(AnalyticEvent.reject_request_click, params);
    },
    [AnalyticEvent.host_cancel_reservation]: (params: any) => {
        phcapture(AnalyticEvent.host_cancel_reservation, params);
    },
    [AnalyticEvent.confirm_host_cancel_reservation]: (params: any) => {
        phcapture(AnalyticEvent.confirm_host_cancel_reservation, params);
    },
    [AnalyticEvent.insurance_page]: (params: any) => {
        phcapture(AnalyticEvent.insurance_page, params);
    },
    [AnalyticEvent.insurance_case_click]: (params: any) => {
        phcapture(AnalyticEvent.insurance_case_click, params);
    },
    [AnalyticEvent.rate_guest_click]: (params: any) => {
        phcapture(AnalyticEvent.rate_guest_click, params);
    },
    [AnalyticEvent.confirm_rate]: (params: any) => {
        phcapture(AnalyticEvent.confirm_rate, params);
    },
    [AnalyticEvent.support_click]: (params: any) => {
        phcapture(AnalyticEvent.support_click, params);
    },
};

export const sendEventByOKButton = () => sendEvent(AnalyticEvent.save_and_out_button_click);

export const sendEventByCancelButton = () => sendEvent(AnalyticEvent.without_save_button_click);

export const sendEventByObjectItemClick = () => sendEvent(AnalyticEvent.object_click);
