import { ModalWindowRight, StatusRequestCard, StatusRequestEnum } from "@russpass/partner-front-ui";
import React, { useCallback } from "react";
import routes from "../../../../constants/routes";
import { redirectToApartmentsAll } from "../helpers";
import { getIn, useFormikContext } from "formik";
import styles from "./StatusModal.module.sass";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { useLocation } from "react-router-dom";
import { sendEvent } from "../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../product_analytics/constants";
import useUserInfo from "../../../../hooks/useUserInfo";

type Props = {
    status: StatusRequestEnum | null;
    repeat: () => void;
    isLoading: boolean;
};

const StatusModal = ({ status, repeat, isLoading }: Props) => {
    const location = useLocation<{ status: string }>();

    const { values } = useFormikContext();
    const { userFullInfo } = useUserInfo();

    const title = getIn(values, "object.name") || "";

    const email = userFullInfo?.email || "почту";

    const close = useCallback(() => {
        if (status === StatusRequestEnum.Success) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Success,
                body: "Объект отправлен на модерацию",
            });
        }
        redirectToApartmentsAll(location);
    }, [location, status]);

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);

    return (
        <ModalWindowRight isOpened={!!status} isHideCloseButton isLoadingWithContent={isLoading}>
            <StatusRequestCard
                status={status}
                title={{
                    success: (
                        <>
                            Объект «{title}»<br />
                            отправлен на модерацию
                        </>
                    ),
                    error: (
                        <>
                            Не удалось отправить на модерацию объект
                            <br />«{title}»
                        </>
                    ),
                }}
                subtitle={{
                    success: (
                        <>
                            В течение 5 рабочих дней вы получите ответ
                            <br />
                            на <span className={styles.Email}>{email}</span>
                        </>
                    ),
                    error: (
                        <>
                            <>
                                Повторите попытку или обратитесь
                                <br />
                                {" в "}
                                <a className="link" href={`${routes.support}`} target="_blank" rel="noreferrer">
                                    {"Поддержку"}
                                </a>{" "}
                                для уточнения информации
                            </>
                        </>
                    ),
                }}
                handleRepeat={repeat}
                handleClose={close}
                handleBeforeClose={handleSendEvent}
            />
        </ModalWindowRight>
    );
};

export default StatusModal;
