import React, { useCallback } from "react";
import { Filter } from "@russpass/partner-front-ui";
import { Sorting } from "../../../../constants/filters";
import { BOOKING_STATUSES, sortingNewOptions, sortingOptions } from "../constants";
import { RestaurantNameFilter } from "../types";
import { ReactComponent as SortIcon } from "../../../../assets/images/icons/ic_filter.svg";
import { ReactComponent as FilterIcon } from "../../../../assets/images/icons/ic_filter_2.svg";

type Props = {
    name: string;
    sorting: Sorting | BOOKING_STATUSES;
    onChange: (filters: { [key: string]: string | number }) => void;
    restaurantNameOptions: RestaurantNameFilter[];
    isNew?: boolean;
};

const BookingFilters = ({ name, sorting, onChange, restaurantNameOptions, isNew }: Props) => {
    const handleNameChange = useCallback(
        (value) => {
            onChange({
                cmsId: value,
                page: 0,
            });
        },
        [onChange]
    );

    const handleSortChange = useCallback(
        (value) => {
            onChange(
                isNew
                    ? {
                          sorting: value as Sorting,
                      }
                    : {
                          status: value as BOOKING_STATUSES,
                      }
            );
        },
        [onChange, isNew]
    );

    return (
        <>
            <Filter
                options={restaurantNameOptions}
                value={name}
                onChange={handleNameChange}
                isSearch={true}
                searchPlaceholder={"Название объекта"}
                modalTitle="Выбор объекта"
            />
            <Filter
                options={isNew ? sortingNewOptions : sortingOptions}
                value={sorting}
                onChange={handleSortChange}
                filterIcon={isNew ? <SortIcon /> : <FilterIcon />}
                modalTitle="Сортировка"
            />
        </>
    );
};

export default BookingFilters;
