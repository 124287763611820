import uuid from "react-native-uuid";
import moment from "moment";
import { WorkingDay } from "../../types";
import { DayTimeTable, PlaceTimetableItemType } from "../../../../components/timetable/place-timetable/types";
import { timetableOptionsFull, weekDays } from "../../../../components/timetable/place-timetable/constants";

const formatTime = (time: string) => moment(time, "HH:mm:ss").format("HH:mm") || "";

const getTimetableItem = (day: DayTimeTable, daysData: WorkingDay) => {
    const defaultTimetableItem = {
        uuid: uuid.v4() as string,
        workingDays: [],
        time: [""],
        breakTime: [""],
    };

    const option: PlaceTimetableItemType = defaultTimetableItem;
    option.workingDays.push(day as unknown as string);
    option.time[0] = formatTime(daysData.startTime) + " - " + formatTime(daysData.endTime);
    option.breakTime[0] = daysData.breaks?.length
        ? formatTime(daysData.breaks[0].startTime) + " - " + formatTime(daysData.breaks[0].endTime)
        : "";
    return option;
};

export const prepareTimetableData = (data: { days: any; all?: WorkingDay | undefined }, upperCase: boolean) => {
    const newData: PlaceTimetableItemType[] = [];
    const timetableArray: PlaceTimetableItemType[] = [];
    const isAllDay = data.all?.startTime;

    Object.keys(weekDays).forEach((weekDay) => {
        const name = upperCase ? weekDay.toLocaleUpperCase() : (weekDay as keyof typeof data.days);
        const daysData = isAllDay ? data.all : data.days[name];
        const isActiveDay = daysData?.startTime && daysData?.endTime;

        if (isActiveDay) {
            const day = timetableOptionsFull.find((facilityOption) => facilityOption.value === weekDay);
            if (day) {
                timetableArray.push(getTimetableItem(day, daysData));
            }
        }
    });

    timetableArray.forEach((timetableItem) => {
        const indx = newData.findIndex((newDataItem) => {
            const isEqualsTime = timetableItem.time[0] === newDataItem.time[0];
            const isEqualsBreakTime = timetableItem.breakTime[0] === newDataItem.breakTime[0];
            return isEqualsTime && isEqualsBreakTime;
        });

        if (indx === -1) {
            newData.push(timetableItem);
        } else {
            newData[indx].workingDays.push(timetableItem.workingDays[0]);
        }
    });

    return newData;
};
