import React from "react";
import { Occupancy } from "../../../types/hotel-room";
import styles from "../occupancies.module.sass";
import { hotelRoomOccupanciesBNOVOValidation } from "../../../validation-schemas/hotel-room";
import { cloneDeep } from "lodash";
import { hotelRoomOccupancy } from "../../../constants/hotel-room";
import { ReactComponent as Plus } from "../../../../../../../assets/images/icons/ic_plus.svg";
import OccupancyItemBNOVO from "./occupancy-item";

type OccupancyChooserBNOVOProps = {
    occupancies: Occupancy[];
    onChange: (field: string, values: Occupancy[] | Occupancy) => void;
};

const OccupancyChooserBNOVO = ({ occupancies, onChange }: OccupancyChooserBNOVOProps) => {
    return (
        <div className={styles.occupancies__wrapper}>
            <div className={styles.occupancies__title}>
                Варианты размещения гостей
                <div className={styles.occupancies__title__hint}>
                    Укажите все варианты, чтобы номер отображался пользователям с разными запросами.
                    <br />
                    Пример: 2 взрослых, 1 взрослый + 1 ребенок
                </div>
            </div>

            {occupancies.map((occupancy, index) => (
                <OccupancyItemBNOVO
                    key={occupancy.id}
                    value={occupancy}
                    isDeleted={occupancies.length > 1}
                    index={index}
                    onDelete={() => {
                        onChange(
                            `occupancies`,
                            occupancies.filter((item, ind) => {
                                return ind !== index;
                            })
                        );
                    }}
                    disabled={!occupancy.isNew}
                    onChange={(value) => {
                        onChange(`occupancies[${index}]`, value);
                    }}
                    errorSettings={{
                        showOnChange: true,
                    }}
                />
            ))}

            {(!occupancies.length || hotelRoomOccupanciesBNOVOValidation(occupancies).isValidSync(occupancies)) && (
                <button
                    className="button"
                    type="button"
                    onClick={() => {
                        onChange("occupancies", [...occupancies, cloneDeep(hotelRoomOccupancy())]);
                    }}
                >
                    <div className="button__icon button__icon--left">
                        <Plus />
                    </div>
                    Добавить вариант размещения
                </button>
            )}
        </div>
    );
};

export default OccupancyChooserBNOVO;
