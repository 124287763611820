import { CommonLayout } from "@russpass/partner-front-ui";
import { navbarBooking, navbarBookingNames } from "./constants";
import BookingList from "./booking-list";

const Booking = () => {
    return (
        <CommonLayout pageTitle="Бронирования" navbar={navbarBooking}>
            <div data-name={navbarBookingNames.new}>
                <BookingList isNew={true} />
            </div>
            <div data-name={navbarBookingNames.answered}>
                <BookingList />
            </div>
        </CommonLayout>
    );
};

export default Booking;
