export const isImageExtension = (extension: string) => {
    switch (extension.toLowerCase()) {
        case "jpeg":
        case "jpg":
        case "png":
        case "tiff":
        case "psd":
        case "gif":
        case "bmp":
            return true;
        default:
            return false;
    }
};
