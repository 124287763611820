import { HotelFormData } from "./types";

export const getAddObjectEventParams = (values: HotelFormData) => {
    return {
        chanel_manager: values.systemType || "",
        bik: values.bankDetails.bic || "",
        system_id: values.id || "",
        bank_name: values.bankDetails.name || "",
        object_name: values.title || "",
        corr_account: values.bankDetails.corrAccount || "",
        checking_account: values.bankDetails.checkingAccount || "",
    };
};
