import React, { useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { InputField, RadioGroup, DatePickerField, UploadFileField, dateFnsFormat } from "@russpass/partner-front-ui";
import { DOTTED_DATE_FORMAT_FNS } from "../../../../../../constants/dates-format";
import { tourOfferFormData, optionsEnsurance, ACCEPT_FILES } from "../../constants";
import { OfferForm } from "../../types";
import "../../styles.sass";
import { tourCompanyInfoValidation } from "../../validation-schema";
import { TextareaField } from "@russpass/partner-front-ui";
import StepWrap from "../stepWrap";

type Props = {
    initialValues: OfferForm;
    handleSignOffer: (values: OfferForm) => void;
};

const StepTwoForm = ({ initialValues, handleSignOffer }: Props) => {
    const [data] = useState<OfferForm>({
        applicationAdditionData: { ...initialValues.applicationAdditionData, ...tourOfferFormData },
        filesData: { ...initialValues.filesData, contract: [] },
    });

    return (
        <Formik
            initialValues={data}
            validationSchema={tourCompanyInfoValidation}
            enableReinitialize
            validateOnMount
            onSubmit={() => {}}
        >
            {({ isValid, setFieldValue, values, submitForm }: FormikProps<OfferForm>) => {
                return (
                    <StepWrap
                        onSubmit={() => {
                            submitForm().then(() => {
                                if (!isValid) {
                                    return;
                                } else {
                                    handleSignOffer(values);
                                }
                            });
                        }}
                        onRepeat={() => {
                            handleSignOffer(values);
                        }}
                    >
                        <div className="content-wrapper">
                            <div className="content-wrapper__form">
                                <Form>
                                    <div className="content-wrapper__form">
                                        <div className="company-info__title">Информация о туроператоре</div>
                                        <div className="mb-32">
                                            <InputField
                                                label="Номер туроператора в Едином федеральном реестре туроператоров"
                                                name="applicationAdditionData.tourOperatorInfo.registerNumber"
                                                noHover
                                                placeholder="РТО 022131"
                                                maxLength={50}
                                            />
                                            <div className="input-wrapper__finance">
                                                <InputField
                                                    label="Размер финансового обеспечения ответственности туроператора"
                                                    name="applicationAdditionData.tourOperatorInfo.financialSecurityAmount"
                                                    noHover
                                                    placeholder="500 000 ₽"
                                                    maxLength={10}
                                                    Icon={
                                                        values.applicationAdditionData?.tourOperatorInfo
                                                            ?.financialSecurityAmount
                                                            ? () => "₽"
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                            <RadioGroup
                                                options={optionsEnsurance}
                                                label="Тип обеспечения"
                                                value={
                                                    optionsEnsurance.find(
                                                        (option) =>
                                                            option.value ===
                                                            values.applicationAdditionData?.tourOperatorInfo
                                                                ?.financialSecurityType
                                                    ) || ""
                                                }
                                                onChange={(option) => {
                                                    setFieldValue(
                                                        "applicationAdditionData.tourOperatorInfo.financialSecurityType",
                                                        option.value
                                                    );
                                                }}
                                            />

                                            <InputField
                                                label="Номер"
                                                name="applicationAdditionData.tourOperatorInfo.financialSecurityNumber"
                                                noHover
                                                placeholder="6749675"
                                                maxLength={50}
                                            />
                                            <DatePickerField
                                                label="Дата"
                                                name="applicationAdditionData.tourOperatorInfo.financialSecurityDate"
                                                placeholder="15.03.2021"
                                                handleChange={(date) => {
                                                    setFieldValue(
                                                        "applicationAdditionData.tourOperatorInfo.financialSecurityDate",
                                                        date
                                                    );
                                                }}
                                                emptyValue=""
                                                valueFormat={dateFnsFormat.ISO_DATE_FORMAT}
                                                inputFormat={DOTTED_DATE_FORMAT_FNS}
                                                noHover
                                                numberOfMonths={1}
                                                isClearable={false}
                                                value={
                                                    values.applicationAdditionData?.tourOperatorInfo
                                                        ?.financialSecurityDate
                                                }
                                                disabledDays={{ after: new Date() }}
                                            />
                                            <InputField
                                                label="Срок действия"
                                                name="applicationAdditionData.tourOperatorInfo.financialSecurityTerm"
                                                noHover
                                                placeholder="1 год"
                                                maxLength={100}
                                            />

                                            <div className="company-info__alignment-wrap">
                                                <UploadFileField
                                                    name="filesData.contract"
                                                    buttonTitle="Загрузить договор"
                                                    accept={ACCEPT_FILES}
                                                    files={values.filesData.contract}
                                                    onDelete={() => {
                                                        setFieldValue("filesData.contract", []);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="company-info__title">
                                            Информация об организации, предоставившей финансовое обеспечение
                                            ответственности Туроператора
                                        </div>

                                        <InputField
                                            label="Наименование"
                                            name="applicationAdditionData.tourOperatorInfo.financialSecurityProvider"
                                            noHover
                                            placeholder="Наименование организации"
                                            maxLength={300}
                                        />
                                        <TextareaField
                                            label="Юридический адрес"
                                            name="applicationAdditionData.tourOperatorInfo.financialSecurityProviderLegalAddress"
                                            noHover
                                            placeholder="119071, ГОРОД МОСКВА, УЛИЦА ОРДЖОНИКИДЗЕ, ДОМ 12, СТРОЕНИЕ 4, ЭТ/КОМН 5/13"
                                            maxLength={300}
                                        />
                                        <TextareaField
                                            label="Физический адрес"
                                            name="applicationAdditionData.tourOperatorInfo.financialSecurityProviderActualAddress"
                                            noHover
                                            placeholder="119071, ГОРОД МОСКВА, УЛИЦА ОРДЖОНИКИДЗЕ, ДОМ 12, СТРОЕНИЕ 4, ЭТ/КОМН 5/13"
                                            maxLength={300}
                                        />
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </StepWrap>
                );
            }}
        </Formik>
    );
};

export default StepTwoForm;
