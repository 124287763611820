import { Form, Formik, FormikProps } from "formik";
import { InputField, AutoSubmit } from "@russpass/partner-front-ui";
import { navbarHotelEditFormNames } from "../constants";
import { HotelData } from "../types";
import { maskedInputPhone } from "../../../../../utils/validation";
import styles from "../hotel-edit-form.module.sass";
import { getHotelValidation } from "../validation-schema";
import { TextareaField } from "@russpass/partner-front-ui";
import { HotelServiceData } from "../../../components/hotel-services/types";
import HotelServices from "../../../components/hotel-services/hotel-services";

type Props = {
    data: HotelData;
    services: HotelServiceData;
    onChange: (values: any) => void;
    onSubmit: (path: string) => void;
    isShowErrors: boolean;
    disabled: boolean;
};

const EditFormHotel = ({ data, services, onSubmit, onChange, isShowErrors, disabled }: Props) => {
    return (
        <div className="content-wrapper">
            <Formik
                initialValues={data}
                validationSchema={getHotelValidation}
                enableReinitialize
                validateOnMount
                onSubmit={() => {
                    onSubmit(navbarHotelEditFormNames.photos);
                }}
            >
                {({ values }: FormikProps<HotelData>) => {
                    return (
                        <Form>
                            <AutoSubmit onChange={onChange} />
                            <div className="content-wrapper__form">
                                <AutoSubmit onChange={onChange} />
                                <div className={styles.hotel_edit_form__description}>
                                    <TextareaField
                                        label="Описание"
                                        name="description"
                                        noHover
                                        maxRows={6}
                                        placeholder="Максимально 400 символов"
                                        maxLength={400}
                                        errorSettings={{
                                            showOnChange: isShowErrors,
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                                <HotelServices
                                    data={data}
                                    services={services}
                                    onChange={onChange}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    disabled={disabled}
                                />
                                <InputField
                                    label="Адрес"
                                    name="address"
                                    noHover
                                    placeholder="Город, улица, дом"
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    disabled={disabled}
                                />
                                <InputField
                                    label="E-mail"
                                    name="email"
                                    noHover
                                    placeholder="name@mail.ru"
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    disabled={disabled}
                                />
                                <div className="content-wrapper__framed">
                                    <div className="section">Телефон</div>
                                    {values.phones.map((phone, index) => (
                                        <div className={styles.hotel_edit_form__phone} key={index}>
                                            <InputField
                                                label="Номер"
                                                name={`phones[${index}.number]`}
                                                noHover
                                                placeholder="+ 7 (999) 999-99-99"
                                                errorSettings={{
                                                    showOnChange: isShowErrors,
                                                }}
                                                renderComponent={maskedInputPhone}
                                                disabled={disabled}
                                            />
                                            <InputField
                                                label="Подпись"
                                                name={`phones[${index}.name]`}
                                                noHover
                                                placeholder="Чей номер"
                                                errorSettings={{
                                                    showOnChange: isShowErrors,
                                                }}
                                                disabled={disabled}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default EditFormHotel;
