import { yup } from "../../../../../yup";
import { FORMAT_ERROR } from "../../../../../constants/errors";
import { timeRegexp } from "../../../../../utils/validation";
import moment from "moment";
import { TIME_FORMAT } from "../../../../../constants/dates-format";

const validateTime = (value: string | undefined) => {
    if (!value) return false;
    const cleanedValue = value.replace(/[^\d:]/g, "");
    const hours = parseInt(cleanedValue.substring(0, 2), 10);
    return hours >= 0 && hours <= 23;
};

export const detailsStepSchema = yup.object().shape({
    maxGuestCount: yup.number().required().min(1).max(9999),
    bathroomCount: yup.number().required().min(1).max(9999),
    arrival: yup.object().shape({
        start: yup
            .string()
            .required()
            .matches(timeRegexp, {
                message: FORMAT_ERROR,
            })
            .test("valid-time-start", FORMAT_ERROR, validateTime),
        end: yup
            .string()
            .required()
            .matches(timeRegexp, {
                message: FORMAT_ERROR,
            })
            .test("valid-time-end", FORMAT_ERROR, validateTime)
            .test("before-start", "Время выезда должно быть меньше времени заезда", function (end) {
                // @ts-ignore
                const { from } = this;
                const start = from?.[0]?.value?.start;
                return !start || !end || moment(end, TIME_FORMAT).isBefore(moment(start, TIME_FORMAT));
            }),
    }),
    checkinType: yup.string().required(),
    homeRules: yup.array().required(),
    rooms: yup
        .array()
        .of(
            yup.object().shape({
                roomType: yup.string().required(),
                beds: yup.object().required(),
            })
        )
        .min(1)
        .required(),
});

type DraftRoomsArrayType = {
    roomType: string;
    beds: Record<string, number>;
}[];

export const detailsStepDraftSchema = yup.object().shape({
    rooms: yup.array().when({
        is: (value: DraftRoomsArrayType) => !!(value?.[0]?.roomType || value?.[0]?.beds),
        then: yup
            .array()
            .of(
                yup.object().shape({
                    roomType: yup.string().required(),
                    beds: yup.object().required(),
                })
            )
            .min(1)
            .required(),
        otherwise: yup.array().notRequired(),
    }),
});
