import React, { useCallback } from "react";
import { SelectOption } from "../../../../../../components/form-fields/search-select-field/components/types";
import RentHousesDictionaryField from "../../../../../../components/form-fields/renthouses-dictionary-field";
import { BaseFieldProps } from "../../../../../../types/fields";
import { useFormikContext } from "formik";

type Props = BaseFieldProps;

export const SelectTimezoneField = ({ name, label = "Часовой пояс", disabled }: Props) => {
    const { setFieldValue } = useFormikContext();

    const handleChange = useCallback(
        (option: (SelectOption & { utc?: string }) | undefined) => {
            setFieldValue(`${name}Value`, option?.utc || "");
        },
        [name, setFieldValue]
    );

    return (
        <RentHousesDictionaryField
            name={name}
            label={label}
            dictionary="time_zone"
            hideArrow
            onChange={handleChange}
            disabled={disabled}
        />
    );
};
