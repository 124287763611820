import { OfferForm } from "../../../types";
import { offerFormInitialData } from "../../../constants";
import { getStepOneSchema } from "../../../validation-schema";
import { useLegalStateStatus } from "../../../hooks/useLegalStateStatus";
import { useOfferTypeStatus } from "../../../hooks/useOfferTypeStatus";
import { useMemo } from "react";
import useUserInfo from "../../../../../../../hooks/useUserInfo";

export const useStepOneForm = ({ initialValues }: { initialValues: OfferForm | null }) => {
    const { userFullInfo } = useUserInfo();
    const legalState = userFullInfo?.legalState;
    const legalStateStatus = useLegalStateStatus(legalState);
    const offerTypeStatus = useOfferTypeStatus();

    const initValues = useMemo(() => {
        let values = initialValues || offerFormInitialData;
        if (!values.applicationAdditionData.signatory && userFullInfo?.contactPerson) {
            values.applicationAdditionData.signatory = userFullInfo.contactPerson;
        }

        return values;
    }, [initialValues, userFullInfo?.contactPerson]);

    const moderationText = userFullInfo?.status?.id === 11 ? userFullInfo?.reworkRequirements : undefined;

    return {
        legalState,
        offerTypeStatus,
        legalStateStatus,
        initValues,
        schema: getStepOneSchema(offerTypeStatus, legalStateStatus),
        moderationText,
    };
};
