import { partnerApiServices, russpassApiServices, russpassApiUrl } from "../constants/api";
import fetchRequest from "./manager";

const filesPartnerServiceUrl = `${russpassApiServices.partnership}${partnerApiServices.files}`;
const filesDraftServiceUrl = `${russpassApiServices.draft}`;

export const getImageById = (id: string) => `${russpassApiUrl}${filesPartnerServiceUrl}/images/${id}`;

export const uploadPhotoToCms = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    const content: { id: string } = await fetchRequest.file(`${filesPartnerServiceUrl}/cms`, formData, {
        isProtected: true,
    });
    return content;
};

export const loadFileByUrlToCms = async (url: string) => {
    const data = await fetchRequest.post(`${filesPartnerServiceUrl}/cms/byUrl?url=${url}`, null, {
        isProtected: true,
    });
    return data;
};

export const attachDraftFile = async (file: File, partnerId: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("externalPartnerId", partnerId);
    formData.append("typeSource", "LKP");
    const data = await fetchRequest.file(`${filesDraftServiceUrl}/files/attach2/draft`, formData, {
        isProtected: true,
    });
    return data;
};

export const deleteDraftFile = async (fileName: string) => {
    const data = await fetchRequest.delete(`${filesDraftServiceUrl}/files/attach2/draft?fileName=${fileName}`, {
        isProtected: true,
    });
    return data;
};

export const getDraftImageByURL = async (fileName: string) => {
    const data = await fetchRequest.get(`${filesDraftServiceUrl}/files/attach2/draft?fileName=${fileName}`, null, {
        isProtected: true,
        isNotJSONResponse: true,
    });
    return data.blob();
};
