import { getPeriodString } from "../../utils/period-string";
import { addCurrencyString, joinCountAndSumString } from "../../utils/add-currency-string";
import { ReportItemType } from "../../types";

const useReportPeriod = (reportPeriod: ReportItemType) => {
    const period = getPeriodString(reportPeriod.startDate, reportPeriod.endDate, true) || "";
    const sum = addCurrencyString(reportPeriod.sum);
    const reservation = joinCountAndSumString(reportPeriod.bookingsCount, addCurrencyString(reportPeriod.bookingsSum));
    const refund = joinCountAndSumString(reportPeriod.returnsCount, addCurrencyString(reportPeriod.returnsSum));
    const commission = addCurrencyString(reportPeriod.commission);

    return { period, sum, reservation, refund, commission };
};
export default useReportPeriod;
