import { useCallback, useEffect, useState } from "react";
import eventDispatcher from "../../../../../../../lib/event-dispatcher";
import { activeTabKeyType, StatusRequestEnum } from "@russpass/partner-front-ui";
import { GET_DATA_ERROR } from "../../../../../../../constants/errors";
import useLoadData from "../../../hooks/useLoadData";
import { IntegrationHotelRoom } from "../../../types/hotel-room";
import { HotelTariffData } from "../../../types/hotel-tariff";
import { HotelDiscountsData } from "../../../types/hotel-discounts";
import { useLocation } from "react-router-dom";
import { useHotelDataContext } from "../../../../hotel-view/hotel-data-context";
import { getPartnerHotel } from "../../../../../../../api/hotels/hotels";
import { navbarDiscountsNames } from "../../../constants/hotel-discounts";

const useLoadDiscount = () => {
    const { state } = useLocation<{ isNew?: boolean }>();
    const { hotelInfoData, loadHotelInfoData } = useHotelDataContext();
    const { onLoadRooms, onLoadTariffs, onLoadDiscounts } = useLoadData();

    const [isLoadingDiscounts, setIsLoadingDiscounts] = useState<boolean>(true);

    const [hotelRoomsData, setHotelRoomsData] = useState<IntegrationHotelRoom[]>([]);
    const [tariffs, setTariffs] = useState<HotelTariffData[]>([]);

    const [discountsData, setDiscountsData] = useState<HotelDiscountsData[]>([]);
    const [activeTabKey, setActiveTabKey] = useState<activeTabKeyType | undefined>(navbarDiscountsNames.active);

    const loadHotelData = useCallback(async () => {
        try {
            const hotelData = await loadHotelInfoData();
            if (!hotelData) throw new Error();

            const tariffs = await onLoadTariffs();
            setTariffs(tariffs);

            const roomsData = await onLoadRooms();
            const partnerHotelRooms: { cmsRoomId: string; id: string }[] =
                (await getPartnerHotel(hotelData.hotelId))?.hotel?.rooms?.filter(
                    (el: { cmsRoomId?: string; id?: string }) => el.cmsRoomId && el.id
                ) || [];

            const rooms = roomsData.rooms
                .map((room) => ({
                    ...room,
                    id: partnerHotelRooms.find((hotelRoom) => hotelRoom.cmsRoomId === room.cmsRoomId)?.id || "",
                }))
                .filter((el) => el.id);

            setHotelRoomsData(rooms);
        } catch (e) {
            console.error(e);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
        }
    }, [onLoadRooms, onLoadTariffs, loadHotelInfoData]);

    const loadDiscounts = useCallback(async () => {
        try {
            const cmsId = hotelInfoData?.cmsId;
            if (!cmsId) return;

            setIsLoadingDiscounts(true);
            const discounts = await onLoadDiscounts(cmsId, activeTabKey as string, 100);
            if (discounts) {
                setDiscountsData(discounts.items);
            }
            setIsLoadingDiscounts(false);
        } catch (e) {
            console.error(e);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
        }
    }, [activeTabKey, onLoadDiscounts, hotelInfoData]);

    useEffect(() => {
        loadDiscounts();
    }, [activeTabKey, loadDiscounts, hotelInfoData?.cmsId]);

    return {
        isLoadingDiscounts,
        hotelRoomsData,
        hotelData: hotelInfoData,
        tariffs,
        loadDiscounts,
        activeTabKey,
        setActiveTabKey,
        discountsData,
        loadHotelData,
        isNew: !!state?.isNew,
    };
};

export default useLoadDiscount;
