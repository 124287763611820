import { Severity } from "@russpass/partner-front-ui";
import { BookingAlert } from "../../components/BookingAlert";
import { ReactComponent as IcShield } from "../../../../../../assets/images/icons/ic_shield_check.svg";

type Props = {
    onClick?: () => void;
};

export const InsuranceAlert = ({ onClick }: Props) => (
    <BookingAlert
        severity={Severity.INFO}
        info={{
            icon: <IcShield />,
            content: (
                <span className="link" onClick={onClick}>
                    Страхование жилья
                </span>
            ),
        }}
    />
);
