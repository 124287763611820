import classNames from "classnames";
import { getIn, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRentHousesCancellationPolicy } from "../../../../../../api/hooks/rentHouses";
import { ModalWindowRight, SelectWithoutOptions } from "@russpass/partner-front-ui";
import { UNEXPECTED_ERROR } from "../../../../../../constants/errors";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { BaseFieldProps } from "../../../../../../types/fields";
import { useViewportContext, StatusRequestEnum } from "@russpass/partner-front-ui";
import { SaleAndCancelStepFormikFields } from "../index";
import styles from "./styles.module.sass";
import modalMenuStyles from "../../../../../../components/form-fields/search-select-field/components/ModalMenu.module.sass";

const SelectCancellationReasonField = ({
    name,
    label = "Условия отмены бронирования",
    placeholder = "Выбрать",
    hint,
    disabled,
}: BaseFieldProps) => {
    const { isMobile } = useViewportContext();
    const { data, error: dataError } = useRentHousesCancellationPolicy({ pageSize: 999 });
    const { setFieldValue, errors, getFieldProps } = useFormikContext<SaleAndCancelStepFormikFields>();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [active, setActive] = useState<{
        id: string;
        title: string;
        description?: string;
    }>();

    const handleRadioButtonClick = useCallback((item) => {
        setActive(item);
    }, []);

    const options = useMemo(
        () =>
            data?.data.items.map((item) => ({
                id: item.id,
                title: item.title,
                description: item.description,
            })) || [],
        [data]
    );

    const value = getFieldProps(name).value;

    useEffect(() => {
        setActive(options.find((el) => el.id === value));
    }, [options, value]);

    const close = useCallback(() => {
        setIsModalOpen(false);
        setActive(options.find((el) => el.id === value));
    }, [options, value]);

    const save = useCallback(() => {
        setFieldValue(name, active?.id);
        close();
    }, [active?.id, close, name, setFieldValue]);

    useEffect(() => {
        if (!dataError) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [dataError]);

    return (
        <>
            <SelectWithoutOptions
                value={options.find((el) => el.id === value)?.title}
                label={label}
                placeholder={placeholder}
                hint={hint}
                disabled={disabled}
                handleClick={() => setIsModalOpen((prev) => !prev)}
                noHover
                error={getIn(errors, name)}
                name={""}
                arrowType={isMobile ? "bottom" : "right"}
            />
            <ModalWindowRight
                isOpened={isModalOpen}
                title={label}
                ComponentFooter={
                    isMobile ? (
                        <div className={modalMenuStyles.Footer}>
                            <button type="button" className="button button--primary" onClick={save}>
                                Сохранить
                            </button>
                        </div>
                    ) : (
                        <button type="button" className="button button--primary" onClick={save}>
                            Сохранить
                        </button>
                    )
                }
                onClose={close}
            >
                {options.map((item) => (
                    <div
                        key={item.id}
                        className={classNames("radio-button__item", styles.listFootnote, {
                            "radio-button__item-active": item.id === active?.id,
                        })}
                        onClick={() => handleRadioButtonClick(item)}
                    >
                        <button className="radio-button__circle" />
                        <div>
                            <div className={classNames("body", styles.listTitle)}>{item.title}</div>
                            <div className="footnote" dangerouslySetInnerHTML={{ __html: item.description || "" }} />
                        </div>
                    </div>
                ))}
            </ModalWindowRight>
        </>
    );
};

export default SelectCancellationReasonField;
