import { BaseFieldProps } from "./types";
import { InputField, InputFieldProps } from "@russpass/partner-front-ui";
import React from "react";
import { maskedInputNumbers } from "../../../../../../utils/validation";

type Props = BaseFieldProps & Pick<InputFieldProps, "onValidate" | "error">;

export const BankCheckingAccountField = ({ name, onValidate, error }: Props) => (
    <InputField
        label="Расчетный счет"
        name={name}
        noHover
        placeholder="18792567008761452817"
        onValidate={onValidate}
        error={error}
        errorSettings={{ showCustomError: true }}
        renderComponent={maskedInputNumbers}
        maxLength={20}
    />
);
