import { Restaurant, RestaurantsListItem } from "./types";
import routes from "../../../constants/routes";
import { navbarRestaurantNames, restaurantStatuses, restaurantStatusesLabels } from "./constants";
import { getImageById } from "../../../api/files";
import { QuestionTooltip, ObjectItemType, ObjectItemClassNameStatus } from "@russpass/partner-front-ui";

export const getNavNameStatus = (restaurant: Restaurant) => {
    if (restaurant.isRejected) return navbarRestaurantNames.rejected;

    switch (restaurant.status) {
        case restaurantStatuses.draft:
            return navbarRestaurantNames.draft;
        case restaurantStatuses.import:
            return navbarRestaurantNames.moderation;
        case restaurantStatuses.active:
            return navbarRestaurantNames.published;
        case restaurantStatuses.deleted:
        case restaurantStatuses.inactive:
            return navbarRestaurantNames.archive;
        default:
            return navbarRestaurantNames.all;
    }
};

export const preparedRestaurantToObject = (data: Restaurant[]) => {
    const objectAll: ObjectItemType[] = [];
    const objectDraft: ObjectItemType[] = [];
    const objectModeration: ObjectItemType[] = [];
    const objectPublished: ObjectItemType[] = [];
    const objectRejected: ObjectItemType[] = [];
    const objectArchive: ObjectItemType[] = [];

    data.forEach((restaurant) => {
        const pathname = routes.restaurantView.replace(":restaurantId", restaurant.id);
        const status = getNavNameStatus(restaurant);
        const item: ObjectItemType = {
            id: restaurant.id,
            name: restaurant.title,
            imgUrl: restaurant.imageExplorePreview.length ? getImageById(restaurant.imageExplorePreview[0]) : undefined,
            category: "Ресторан",
            statusLabel: "",
            classNameStatusLabel: ObjectItemClassNameStatus.draft,
            link: {
                pathname,
                state: {
                    status,
                },
            },
            isDeleting: true,
        };
        if (restaurant.isRejected) {
            item.statusLabel = restaurantStatusesLabels.rejected;
            item.classNameStatusLabel = ObjectItemClassNameStatus.refused;
            objectRejected.push(item);
        } else {
            switch (restaurant.status) {
                case restaurantStatuses.deleted:
                case restaurantStatuses.inactive:
                    item.statusLabel = restaurantStatusesLabels.archive;
                    item.isDeleting = false;
                    objectArchive.push(item);
                    break;

                case restaurantStatuses.import:
                    item.statusLabel = (
                        <>
                            {restaurantStatusesLabels.import}
                            <QuestionTooltip body="В течение 5 рабочих дней вы получите ответ на почту" />
                        </>
                    );
                    item.classNameStatusLabel = ObjectItemClassNameStatus.moderation;
                    objectModeration.push(item);
                    break;

                case restaurantStatuses.active:
                    item.statusLabel = restaurantStatusesLabels.active;
                    item.classNameStatusLabel = ObjectItemClassNameStatus.moderation;
                    objectPublished.push(item);
                    break;

                default:
                    item.statusLabel = restaurantStatusesLabels.draft;
                    objectDraft.push(item);
                    break;
            }
        }
        objectAll.push({
            ...item,
            link: {
                pathname,
                state: {
                    status: navbarRestaurantNames.all,
                },
            },
        });
        return restaurant;
    });

    const restaurantList: RestaurantsListItem[] = [
        {
            dataName: navbarRestaurantNames.all,
            list: objectAll,
        },
        {
            dataName: navbarRestaurantNames.draft,
            list: objectDraft,
        },
        {
            dataName: navbarRestaurantNames.moderation,
            list: objectModeration,
        },
        {
            dataName: navbarRestaurantNames.published,
            list: objectPublished,
        },
        {
            dataName: navbarRestaurantNames.archive,
            list: objectArchive,
        },
        {
            dataName: navbarRestaurantNames.rejected,
            list: objectRejected,
        },
    ];

    return restaurantList;
};
