import routes from "../../../../constants/routes";
import history from "../../../../history";
import HotelInfo from "./components/hotel-info";
import useControlAddHotelPage from "./hooks/useControlAddHotelPage";
import useControlHotelInfoData from "./hooks/useControlHotelInfoData";
import HotelRoom from "./components/hotel-room";
import useControlHotelRoomsData from "./hooks/useControlHotelRoomsData";
import { useLocation, useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import { hotelRoomFormData } from "./constants/hotel-room";
import useControlHotelTariffData from "./hooks/useControlHotelTariffData";
import HotelTariff from "./components/hotel-tariff";
import React, { useCallback, useEffect, useMemo } from "react";
import { progressBarNames } from "./constants/common";
import useControlHotelRoomData from "./hooks/useControlHotelRoomData";
import HotelRequestStatus from "./components/hotel-request-status";
import { sendForApprovalHotel, switchNewTravelline } from "../../../../api/hotels/hotels";
import useControlHotelRatingsData from "./hooks/useControlHotelRatingsData";
import { declensionOfNum } from "../../../../utils/declension-number";
import { allStatusesHotel } from "../../all/constants";
import { sendEvent } from "../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../product_analytics/constants";
import { successTitleText } from "../../../../constants/layout";
import {
    Banner,
    ClassNameBanner,
    RejectionComment,
    useModalAlertContext,
    StatusRequestEnum,
    CommonLayout,
    Card,
    CardsGrid,
} from "@russpass/partner-front-ui";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { POST_DATA_ERROR_SUPPORT } from "../../../../constants/errors";
import { HotelTariffsContext } from "./context/hotel-tariffs-context";
import { useControlHotelTariffsData } from "./hooks/useControlHotelTariffsData";
import { useHotelDataContext } from "../hotel-view/hotel-data-context";
import useControlDiscountsData from "./hooks/useControlHotelDiscountsData";
import { isEnableMovingOldTL } from "../../../../constants/feature-toggles";
import { useExternalLink } from "../../../../hooks/useExternalLink";

const AddHotelPage = () => {
    // @ts-ignore
    const { hotelId } = useParams();
    const location = useLocation<{ status: string }>();

    const { isLoadingHotelInfo, hotelInfoData, isTravelLineHotel, loadHotelInfoData, checkExistTravelLineHotel } =
        useHotelDataContext();

    const {
        isViewHotelInfo,
        changeIsViewHotelInfo,
        isValidHotelInfo,
        hotelInfoStepStyles,
        onSaveHotelInfoData,
        hotelInfoStepDesc,
        isExistingHotel,
        isDisabledInfoHotel,
        isViewCopyHotelId,
        isViewExternalLink,
        statusHotelInfo,
    } = useControlHotelInfoData();

    const {
        groupedAmenities,
        onChangeRoom,
        onSelectRoom,
        selectedHotelRoomData,
        isLoadingRoom,
        isSavingRoom,
        isViewHotelRoom,
        statusHotelRoom,
        onAddRoom,
        changeIsViewHotelRoom,
        hotelResidenceTypes,
    } = useControlHotelRoomData();

    const { isValidRatings, isLoadingRatings, hotelRatingsStepDesc, hotelRatingsStepStyles } =
        useControlHotelRatingsData();

    const { isValidRooms, hotelRoomsStepDesc, hotelRoomsStepStyles, isLoadingRooms, reloadRooms, hotelRoomsData } =
        useControlHotelRoomsData();

    const { isValidTariffs, tariffs, hotelTariffStepStyles, hotelTariffStepDesc, isLoadingTariffs, reloadTariffs } =
        useControlHotelTariffsData();

    const {
        hotelTariffData,
        onSaveHotelTariffData,
        optionsTypeFood,
        onSelectTariff,
        isLoadingTariff,
        statusHotelTariff,
        setStatusHotelTariff,
        isViewHotelTariff,
        onCloseTariffWindow,
    } = useControlHotelTariffData({});

    const { isLoadingDiscounts, isActiveHotel, isEmptyDiscounts, discountsStepDesc, discountsStepStyles } =
        useControlDiscountsData(hotelInfoData);

    const isLoading = useMemo(
        () => isLoadingHotelInfo || isLoadingTariffs || isLoadingRooms || isLoadingRatings || isLoadingDiscounts,
        [isLoadingHotelInfo, isLoadingTariffs, isLoadingRooms, isLoadingRatings, isLoadingDiscounts]
    );

    const {
        statusRequest,
        changeIsViewStatusRequest,
        isViewBanner,
        getActionButtonSettings,
        progressBar,
        handlerCloseStatusWindow,
        descriptionPage,
    } = useControlAddHotelPage({
        isValidHotelInfo,
        isValidTariffs,
        isValidRooms,
        isLoading,
        isViewCopyHotelId,
    });

    const notPublishedRoomsCount = useMemo(() => {
        if (hotelInfoData.status !== allStatusesHotel.draft) {
            return hotelInfoData.notPublishedRooms?.length || 0;
        }
        return 0;
    }, [hotelInfoData]);

    const roomsCardDescColor = useMemo(() => {
        if (isTravelLineHotel) {
            if (hotelInfoData && hotelInfoData.rooms.length > 0) {
                return "#DF2A00";
            }
        }
        if (notPublishedRoomsCount !== 0) {
            return "#E76D00";
        }
        return undefined;
    }, [notPublishedRoomsCount, isTravelLineHotel, hotelInfoData]);

    const onSubmit = useCallback(async () => {
        if (isValidHotelInfo && isValidTariffs && isValidRooms) {
            if (isTravelLineHotel) {
                switchNewTravelline(hotelId).then(() => {
                    changeIsViewStatusRequest(StatusRequestEnum.Success);
                });
            } else {
                sendForApprovalHotel(hotelId)
                    .then(() => {
                        changeIsViewStatusRequest(StatusRequestEnum.Success);
                        sendEvent(AnalyticEvent.submitting_for_moderation_success_page);
                    })
                    .catch(() => {
                        changeIsViewStatusRequest(StatusRequestEnum.Error);
                        eventDispatcher.setNotification({
                            status: StatusRequestEnum.Error,
                            body: POST_DATA_ERROR_SUPPORT,
                        });
                        sendEvent(AnalyticEvent.submitting_for_moderation_error_page);
                    });
                sendEvent(AnalyticEvent.submitting_for_moderation);
            }
        }
    }, [isValidHotelInfo, isValidTariffs, isValidRooms, hotelId, changeIsViewStatusRequest, isTravelLineHotel]);

    const reloadPage = useCallback(() => {
        loadHotelInfoData(true);
        reloadTariffs();
        reloadRooms();
    }, [loadHotelInfoData, reloadTariffs, reloadRooms]);

    const onChangeHotelInfo = useCallback(() => {
        sendEvent(AnalyticEvent.object_info);
        changeIsViewHotelInfo(true);
    }, [changeIsViewHotelInfo]);

    const actionButtonSettings = useMemo(
        () => getActionButtonSettings(onSubmit, onChangeHotelInfo),
        [onSubmit, onChangeHotelInfo, getActionButtonSettings]
    );

    const handleRepeatClick = () => {
        onSubmit();
        sendEvent(AnalyticEvent.repeat_submitting_for_moderation);
    };

    const { open: handleClickSuccess } = useExternalLink(hotelInfoData.url, hotelInfoData.status);

    const handlerTariffsClick = useCallback(() => {
        if (isValidTariffs) {
            history.push({
                pathname: `${location.pathname}/tariffs`,
                state: location?.state,
            });
        } else {
            onSelectTariff();
        }
        sendEvent(AnalyticEvent.tariff_click);
    }, [onSelectTariff, isValidTariffs, location]);

    const handlerRoomsClick = useCallback(() => {
        if (!isValidRooms && !isTravelLineHotel) {
            onSelectRoom(cloneDeep(hotelRoomFormData));
        } else {
            history.push({
                pathname: `${location.pathname}/rooms`,
                state: location?.state,
            });
        }
        sendEvent(AnalyticEvent.rooms_click);
    }, [isValidRooms, location, onSelectRoom, isTravelLineHotel]);

    const hotelTariffsProviderValue = useMemo(() => ({ tariffs }), [tariffs]);

    const onCloseHotelRoom = (status: boolean) => {
        if (status) {
            reloadRooms();
        }
        changeIsViewHotelRoom(false);
    };

    useEffect(() => {
        if (!isTravelLineHotel || (isTravelLineHotel && checkExistTravelLineHotel)) {
            reloadTariffs();
            reloadRooms();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTravelLineHotel, checkExistTravelLineHotel]);

    const { openModalAlert } = useModalAlertContext();

    const onClickBackButton = useCallback(() => {
        const goBack = () => {
            history.push({
                pathname: routes.hotelsAll,
                state: location?.state,
            });
        };
        if (isTravelLineHotel) {
            openModalAlert(
                {
                    title: "Обновление не завершено",
                    description: "Вы внесли не всю информацию",
                    OKButton: "Сохранить и выйти",
                    cancelButton: "Внести оставшиеся данные",
                },
                goBack,
                () => {}
            );
        } else {
            goBack();
        }
    }, [isTravelLineHotel, location?.state, openModalAlert]);

    const handleDiscountsClick = useCallback(() => {
        history.push(`${location.pathname}/discounts`, { isNew: !isEmptyDiscounts });
    }, [location.pathname, isEmptyDiscounts]);

    const handleRatingsClick = useCallback(() => {
        history.push(`${location.pathname}/ratings`);
    }, [location.pathname]);

    const handleTariffClose = useCallback(
        (isSaveStatus: boolean = false) => {
            if (isSaveStatus) {
                reloadTariffs();
            }
            onCloseTariffWindow();
        },
        [reloadTariffs, onCloseTariffWindow]
    );

    const topRightButton = useMemo(
        () =>
            !isLoading && isViewExternalLink
                ? {
                      title: (
                          <>
                              {successTitleText.externalLink.text} {successTitleText.externalLink.icon}
                          </>
                      ),
                      onClick: handleClickSuccess,
                      iconMobile: successTitleText.externalLink.icon,
                  }
                : undefined,
        [handleClickSuccess, isLoading, isViewExternalLink]
    );

    const backButton = useMemo(
        () => ({
            title: "Назад",
            onClick: onClickBackButton,
        }),
        [onClickBackButton]
    );

    return (
        <HotelTariffsContext.Provider value={hotelTariffsProviderValue}>
            <CommonLayout
                progressBar={progressBar}
                description={descriptionPage}
                topRightButton={topRightButton}
                pageTitle={hotelInfoData.title || ""}
                backButton={backButton}
                isLoadingWithoutContent={isLoading}
                isHideControlPanel
            >
                <>
                    {hotelInfoData.isRejected && hotelInfoData.rejectionReason?.length && (
                        <RejectionComment reasons={hotelInfoData.rejectionReason} large />
                    )}
                </>

                <CardsGrid>
                    <div className="position-relative">
                        <Card
                            title={progressBarNames.info}
                            desc={hotelInfoStepDesc}
                            onClick={onChangeHotelInfo}
                            styles={hotelInfoStepStyles}
                        />
                        {isEnableMovingOldTL && isTravelLineHotel && !isValidHotelInfo && (
                            <Banner
                                body={
                                    <>
                                        Мы перенесли информацию <br />
                                        по объекту — проверьте ее <br />и заполните пустые поля
                                    </>
                                }
                                className={ClassNameBanner.MiddleTop}
                                style={{
                                    position: "absolute",
                                    top: "92px",
                                }}
                            />
                        )}
                    </div>
                    {isValidHotelInfo && (
                        <Card
                            title={progressBarNames.tariffs}
                            desc={hotelTariffStepDesc}
                            onClick={handlerTariffsClick}
                            styles={hotelTariffStepStyles}
                        />
                    )}
                    {isValidHotelInfo && isValidTariffs && (
                        <Card
                            title={progressBarNames.rooms}
                            desc={
                                notPublishedRoomsCount !== 0
                                    ? notPublishedRoomsCount +
                                      " " +
                                      declensionOfNum(notPublishedRoomsCount, [
                                          "номер не опубликован",
                                          "номера не опубликовано",
                                          "номеров не опубликовано",
                                      ])
                                    : hotelRoomsStepDesc
                            }
                            stylesDesc={{
                                color: roomsCardDescColor,
                            }}
                            onClick={handlerRoomsClick}
                            styles={hotelRoomsStepStyles}
                        />
                    )}

                    {isActiveHotel && !isTravelLineHotel && (
                        <Card
                            title={progressBarNames.discounts}
                            desc={discountsStepDesc}
                            onClick={handleDiscountsClick}
                            styles={discountsStepStyles}
                        />
                    )}

                    {!isTravelLineHotel && isValidRatings && (
                        <Card
                            title={progressBarNames.rating}
                            desc={hotelRatingsStepDesc}
                            onClick={handleRatingsClick}
                            styles={hotelRatingsStepStyles}
                        />
                    )}
                </CardsGrid>

                <>
                    {actionButtonSettings.title && actionButtonSettings.action && (
                        <div className="content-wrapper">
                            <div className="mt-32">
                                <button
                                    type="submit"
                                    className="button button--primary button--full-width"
                                    onClick={actionButtonSettings.action}
                                >
                                    {actionButtonSettings.title}
                                </button>
                            </div>
                        </div>
                    )}
                </>
            </CommonLayout>
            {isViewBanner && (
                <Banner
                    body="Отправим письмо на почту, когда внесенные изменения пройдут модерацию и появятся на сайте"
                    style={{
                        position: "absolute",
                        right: "56px",
                        top: "140px",
                    }}
                />
            )}
            <HotelInfo
                isView={isViewHotelInfo}
                onClose={changeIsViewHotelInfo}
                isViewCopyHotelId={isViewCopyHotelId}
                isExistingHotel={isExistingHotel}
                disabled={isDisabledInfoHotel}
                onSave={onSaveHotelInfoData}
                status={statusHotelInfo}
            />
            <HotelTariff
                isView={isViewHotelTariff}
                isLoading={isLoadingTariff}
                hotelTariffData={hotelTariffData}
                onClose={handleTariffClose}
                onSave={onSaveHotelTariffData}
                optionsTypeFood={optionsTypeFood}
                status={statusHotelTariff}
                setStatus={setStatusHotelTariff}
            />
            <HotelRoom
                isView={isViewHotelRoom}
                isLoading={isLoadingRoom}
                isSaving={isSavingRoom}
                onChangeHotelRoom={(room) => onChangeRoom(room, hotelRoomsData)}
                groupedAmenities={groupedAmenities}
                room={selectedHotelRoomData}
                onClose={onCloseHotelRoom}
                onAddMore={onAddRoom}
                status={statusHotelRoom}
                hotelResidenceTypes={hotelResidenceTypes}
            />
            <HotelRequestStatus
                status={statusRequest}
                onClose={() => handlerCloseStatusWindow().then(() => reloadPage())}
                handleRepeat={handleRepeatClick}
                externalId={hotelInfoData.hotelId}
            />
        </HotelTariffsContext.Provider>
    );
};

export default AddHotelPage;
