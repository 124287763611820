import { servicePlaceValidation } from "../../components/service-place/validation-schema";
import { Tariff } from "../../components/tariffs/types";
import { Facility } from "../../types";
import { ServiceDataClient } from "./types";
import { serviceInfoValidation } from "./validation-schema";

type Props = {
    data: ServiceDataClient;
    facility: Facility;
    tariffs: Tariff[];
};

export const checkValidServiceFields = ({ data, facility, tariffs }: Props) => {
    const isValidInfo = serviceInfoValidation.isValidSync(data);
    const isValidPlace = servicePlaceValidation.isValidSync(facility);
    const isValidPhoto = !!data.servicePhotoList?.length;
    const isValidTariffs = !!tariffs && tariffs.length > 0;
    const isValidAll = isValidInfo && isValidPlace && isValidPhoto && isValidTariffs;

    return {
        isValidInfo,
        isValidPlace,
        isValidPhoto,
        isValidTariffs,
        isValidAll,
    };
};
