import { array as arrayYup, number as numberYup, object as objectYup, string as stringYup } from "yup";
import {
    FILL_INFORMATION,
    FORM_FIELD_ERROR,
    MAX_CHATACTERS_ERROR,
    MAX_VALUE_NUMBER,
    MIN_VALUE_NUMBER,
    ONLY_INTEGER,
} from "../../../../../../../../../constants/errors";
import { DiscountBonusType, DiscountType } from "../../../../../types/hotel-discounts";

export const DISCOUNT_TITLE_MAX = 40;

export const hotelDiscountSchema = objectYup().shape({
    title: stringYup().required(FILL_INFORMATION).max(DISCOUNT_TITLE_MAX, MAX_CHATACTERS_ERROR),
    discountSize: numberYup()
        .integer(ONLY_INTEGER)
        .when("specialOfferType", {
            is: DiscountType.DISCOUNT,
            then: numberYup()
                .typeError(FORM_FIELD_ERROR)
                .max(100, MAX_VALUE_NUMBER)
                .min(1, MIN_VALUE_NUMBER)
                .required(FILL_INFORMATION),
        }),
    bonusCategory: stringYup().when("specialOfferType", {
        is: DiscountType.BONUS,
        then: stringYup().required(FILL_INFORMATION),
    }),
    roomId: stringYup().required(FILL_INFORMATION),
    tariffs: arrayYup().required(FILL_INFORMATION),
    date: objectYup().test("dateTest", FILL_INFORMATION, (value) => value && value.from && value.to),
    roomsCategoryUpgrade: arrayYup().when("bonusCategory", {
        is: DiscountBonusType.BONUS_UPGRADE,
        then: arrayYup().required(FILL_INFORMATION),
    }),
});
