import React from "react";
import { ReactComponent as IcEdit } from "../../../assets/images/icons/ic_edit.svg";
import styles from "./support-link.module.sass";
import routes from "../../../constants/routes";
import { Divider } from "@russpass/partner-front-ui";

const SupportLink = () => {
    return (
        <>
            <Divider fullWidth className={styles.supportLink__divider} />
            <div className={styles.supportLink}>
                <IcEdit className={styles.supportLink__icon} />
                Для изменения данных обратитесь в
                <a className={styles.supportLink__action} href={`${routes.support}`}>
                    Поддержку
                </a>
            </div>
        </>
    );
};

export default SupportLink;
