import "./hotel-discounts.sass";
import { IntegrationHotelRoom } from "../../types/hotel-room";
import { DiscountStatus, HotelDiscountsData } from "../../types/hotel-discounts";
import { DiscountStatusLabel } from "../../constants/hotel-discounts";
import { ReactComponent as ClockIcon } from "../../../../../../assets/images/icons/controls/ic_clock_circle.svg";
import { ReactComponent as PercentIcon } from "../../../../../../assets/images/icons/controls/ic_percent_circle.svg";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/icons/controls/ic_close_circle.svg";
import { ReactComponent as CheckIcon } from "../../../../../../assets/images/icons/controls/ic_check_circle.svg";
import classNames from "classnames";
import React, { useMemo, useCallback } from "react";
import { Tooltip } from "@russpass/partner-front-ui";
import useControlDiscountItem from "./hooks/useControlDiscountItem";
import checkDiscountStatus from "../../utils/checkDiscountStatus";
import useDeleteDiscount from "./hooks/useDeleteDiscount";

type Props = {
    data: HotelDiscountsData;
    roomData: IntegrationHotelRoom[];
    onSelectDiscount: (id: string) => Promise<void>;
};

const HotelDiscountsItem = ({ data, roomData, onSelectDiscount }: Props) => {
    const { descriptionDiscount, dateDiscount, roomTariffs } = useControlDiscountItem(data, roomData);
    const openDeleteAlert = useDeleteDiscount();

    const { isArchive, isModeration } = checkDiscountStatus(data.offerStatusCode);

    const tooltipProps = useMemo(() => {
        if (isArchive) return { overlay: "Удалить", tooltipWidth: "90px" };
        if (isModeration) return { overlay: "Снять с модерации", tooltipWidth: "164px" };

        return { overlay: "Завершить", tooltipWidth: "113px" };
    }, [isArchive, isModeration]);

    const handleDiscountListClick = useCallback(() => onSelectDiscount(data.id), [onSelectDiscount, data.id]);

    const handleDiscountItemClick = useCallback(
        (event) => {
            event.stopPropagation();
            openDeleteAlert(data.id, data.offerStatusCode);
        },
        [data.id, data.offerStatusCode, openDeleteAlert]
    );

    return (
        <div className="discounts-list-item" onClick={handleDiscountListClick}>
            <span className="discounts-list-item__close" onClick={handleDiscountItemClick}>
                <Tooltip {...tooltipProps}>
                    <CloseIcon />
                </Tooltip>
            </span>
            <div className="discounts-list-item__header">
                <h5 className="discounts-list-item__title">{data.title}</h5>
                {isArchive && (
                    <div
                        className={classNames(
                            "discounts-list-item__status",
                            data.offerStatusCode === DiscountStatus.REFUSED && "discounts-list-item__status--rejected"
                        )}
                    >
                        {DiscountStatusLabel[data.offerStatusCode]}
                    </div>
                )}
            </div>

            <div>
                <div className="discounts-list-item__line">
                    <PercentIcon />
                    <p>{descriptionDiscount}</p>
                </div>
                <div className="discounts-list-item__line">
                    <ClockIcon width="16" height="16" />
                    <p>{dateDiscount}</p>
                </div>
                {roomTariffs && (
                    <div className="discounts-list-item__line">
                        <CheckIcon />
                        <p>{roomTariffs}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HotelDiscountsItem;
