import React from "react";
import { useFormikContext } from "formik";
import { MAX_FILES_COUNT, MAX_FILES_SIZE_SUM } from "../../../../../../constants/validation";
import { FileUploadField } from "@russpass/partner-front-ui";
import { setFileUploadError } from "../../../../../../utils/react-files";

type Props = { name: string };

export const UploadField = ({ name }: Props) => {
    const { setFieldTouched, setFieldValue } = useFormikContext();

    return (
        <div className="form-item">
            <FileUploadField
                name={name}
                maxFileSize={MAX_FILES_SIZE_SUM}
                maxFiles={MAX_FILES_COUNT}
                accepts={[".jpeg", ".jpg", ".png", ".pdf"]}
                onChange={(files) => {
                    setFieldTouched(name);
                    setFieldValue(name, files);
                }}
                buttonTitle="Фото или документы"
                hint={`Загрузите подтверждающие документы — не более ${MAX_FILES_COUNT} файлов общим объемом ${MAX_FILES_SIZE_SUM} мб`}
                onError={setFileUploadError}
            />
        </div>
    );
};
