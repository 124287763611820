import { HotelTariffData } from "../types/hotel-tariff";

export const PENALTY_TYPE_FIX = {
    id: "1",
    name: "Фиксированный",
};

export const PENALTY_TYPE_PERCENT = {
    id: "2",
    name: "% от суммы брони",
};

export const PENALTY_TYPE_OPTIONS = [PENALTY_TYPE_FIX, PENALTY_TYPE_PERCENT];

export const foodTypeOptions = [
    {
        value: false,
        label: "Не включено",
    },
    {
        value: true,
        label: "Включено",
    },
];

export const hotelTariffData: HotelTariffData = {
    id: "",
    title: "",
    foodType: {
        include: false,
        cmsId: "",
    },
    cancellationPolicy: {
        freeCancellationPossible: false,
        penaltyType: PENALTY_TYPE_FIX.id,
        penaltyAmountFix: undefined,
        penaltyAmountPercent: undefined,
        freeCancellationDeadline: undefined,
    },
};

export const slugNameRoomOnly = "RoomOnly";
