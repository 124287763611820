import { RentHousesBooking } from "../../../../../types/rentHouses";
import { BookingTicket } from "./BookingTicket";
import { Card, CardTitle } from "./Card";
import { TextField } from "@russpass/partner-front-ui";
import { formatAges } from "../../helpers";

type Props = {
    booking: RentHousesBooking;
};

export const BaseBookingInfo = ({ booking }: Props) => (
    <>
        <BookingTicket booking={booking} />
        {booking.guestCount.childrenAges?.length && (
            <Card>
                <CardTitle>Дети</CardTitle>
                <TextField label="Возраст" value={formatAges(booking.guestCount.childrenAges)} />
            </Card>
        )}
        {booking.pets.included && (
            <Card>
                <CardTitle>Животные</CardTitle>
                <TextField label="Описание" value={booking.pets.description || "–"} />
            </Card>
        )}
        {booking.comment && (
            <Card>
                <TextField label="Комментарий" value={booking.comment} />
            </Card>
        )}
    </>
);
