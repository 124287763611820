import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { editHotel, getHotelCmsInfo } from "../../../../../api/hotels/hotels";
import { COMMON_ERROR, GET_DATA_ERROR } from "../../../../../constants/errors";
import eventDispatcher from "../../../../../lib/event-dispatcher";

import { isEmpty } from "lodash";
import routes from "../../../../../constants/routes";
import history from "../../../../../history";
import { hotelData as hotelDataDefault } from "../constants";
import { HotelData } from "../types";
import { mappingHotelCmsData, mappingHotelCmsTravelLineRequestData } from "../utils";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

const useHotelCmsTravelLineEditForm = () => {
    // @ts-ignore
    const { hotelId } = useParams();

    const [hotelData, setHotelData] = useState<HotelData>(hotelDataDefault);
    const [hotelDataOriginal, setHotelDataOriginal] = useState<HotelData>(hotelDataDefault);

    const [isLoadingForm, setIsLoadingForm] = useState(false);

    const loadData = useCallback(() => {
        setIsLoadingForm(true);
        let requestArray = [
            new Promise(async (resolve) => {
                try {
                    const hotelInfo = await getHotelCmsInfo(hotelId);
                    const hotelCmsData = mappingHotelCmsData(hotelInfo);
                    setHotelData(hotelCmsData);
                    setHotelDataOriginal(hotelCmsData);
                    return resolve(hotelInfo);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        ];
        Promise.all(requestArray).then(() => {
            setIsLoadingForm(false);
        });
    }, [hotelId]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeData = useCallback((values: any) => {
        setHotelData((prevState) => ({
            ...prevState,
            ...values,
        }));
    }, []);

    const onSubmit = useCallback(async () => {
        try {
            setIsLoadingForm(true);
            const hotelDataNew = mappingHotelCmsTravelLineRequestData(hotelData, hotelDataOriginal);
            if (!isEmpty(hotelDataNew)) {
                await editHotel(hotelId, hotelDataNew);
            }
            setIsLoadingForm(false);
            history.push(routes.hotelsAll);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Success,
                body: "Объект отправлен на модерацию",
                isAlwaysView: true,
            });
        } catch (err) {
            console.error(err);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: COMMON_ERROR,
            });
            setIsLoadingForm(false);
        }
    }, [hotelId, hotelData, hotelDataOriginal]);

    return {
        onSubmit,
        hotelData,
        isLoadingForm,
        onChangeData,
    };
};

export default useHotelCmsTravelLineEditForm;
