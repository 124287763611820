import { FC, useEffect, useState, useMemo } from "react";
import HotelDiscountModal from "../../components/card/hotel-discount-modal";
import { DiscountStatus } from "../../../../types/hotel-discounts";
import { useControlDiscountModal } from "../../hooks/useControlDiscountModal";
import { useLocation } from "react-router-dom";
import { HotelDiscountModalContext } from "./HotelDiscountContext";
import { useHotelDiscountStoreContext } from "../discounts-store/useHotelDiscountStoreContext";

export const HotelDiscountModalProvider: FC = ({ children }) => {
    const { loadDiscounts } = useHotelDiscountStoreContext();
    const { onSelectDiscount, onClose, onAddMore, isViewDiscount, selectedDiscountData, setSelectedDiscountData } =
        useControlDiscountModal(loadDiscounts);

    const [isShowAlert, setIsShowAlert] = useState(false);
    const [isFirstActive, setIsFirstActive] = useState(true);

    useEffect(() => {
        if (selectedDiscountData?.offerStatusCode === DiscountStatus.PUBLISHED) {
            if (isFirstActive) {
                setIsShowAlert(true);
                setIsFirstActive(false);
            }
        } else {
            setIsShowAlert(false);
            setIsFirstActive(true);
        }
    }, [isFirstActive, isShowAlert, selectedDiscountData]);

    let location = useLocation<{ discountId: string | undefined }>();
    useEffect(() => {
        const discountId = location.state?.discountId;
        if (!discountId) return;

        onSelectDiscount(discountId);
    }, [location.state?.discountId, onSelectDiscount]);

    const providerValue = useMemo(
        () => ({
            onSelectDiscount,
            onClose,
            onAddMore,
            isViewDiscount,
            selectedDiscountData,
            setSelectedDiscountData,
            isShowAlert,
            onCloseAlert: () => setIsShowAlert(false),
        }),
        [
            onSelectDiscount,
            onClose,
            onAddMore,
            isViewDiscount,
            selectedDiscountData,
            setSelectedDiscountData,
            isShowAlert,
            setIsShowAlert,
        ]
    );

    return (
        <HotelDiscountModalContext.Provider value={providerValue}>
            {children}
            <HotelDiscountModal />
        </HotelDiscountModalContext.Provider>
    );
};
