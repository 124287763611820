import classNames from "classnames";
import { useMemo } from "react";
import { useViewportContext } from "@russpass/partner-front-ui";
import { formatDateRange } from "../../../booking/helpers";
import { DialogueModel } from "../../types/DialogueModel";
import { DialogueObjectModel } from "../../types/DialogueObjectModel";

type DialoguesModalHeaderProps = {
    user?: { externalId: number; firstName: string; lastName: string; middleName?: string };
    object?: DialogueObjectModel;
    booking?: DialogueModel["booking"];
};

export const DialoguesModalHeader = ({ user, object, booking }: DialoguesModalHeaderProps) => {
    const { isMobile } = useViewportContext();

    const modalTitle = useMemo(() => {
        if (booking?.checkinDate && booking.checkoutDate) {
            return `${formatDateRange(booking.checkinDate, booking.checkoutDate)} ${object?.title || ""}`;
        }

        return object?.title || "";
    }, [object, booking]);

    return (
        <div className="dialogues__modal-header">
            {user && (
                <div
                    className={classNames("dialogues__modal-title", {
                        "dialogues__modal-title__mobile": isMobile,
                    })}
                >
                    {user.lastName} {user.firstName} {user.middleName}
                </div>
            )}
            <div
                className={classNames("dialogues__modal-subheader dialogues__modal-title", {
                    "dialogues__modal-subheader__mobile": isMobile,
                })}
            >
                {modalTitle}
            </div>
        </div>
    );
};
