import { ExternalUser, RatingsBadge } from "../containers/services/components/ratings/types";

export type RentHousesDictionaryItem = {
    id: string;
    title: string;
    slug: string;
    dictionary_data?: Record<string, any> & {
        title?: string;
        category?: string;
        utc?: string;
        is_self_checkin?: boolean;
    };
};

export enum RentHousesOwnerStatus {
    PENDING = "PENDING",
    PENDING_UPDATE = "PENDING_UPDATE",
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
}

export type RentHousesOwnerProfile = {
    id: string;
    lkpPartnerId: string;
    cmsId?: string;
    status: RentHousesOwnerStatus;
    createdAt: string;
    updatedAt: string;
    name: string;
    image: string;
    description: string;
    languages: string[];
    city: string;
    declineReason?: string;
};

export type RentHousesProfileUpdateInfo = {
    name: string;
    image: string;
    description: string;
    languages?: string[];
    city?: string;
};

export enum RentHousesObjectStatus {
    DRAFT = "DRAFT",
    MODERATION = "MODERATION",
    PUBLISHED = "PUBLISHED",
    DECLINED = "DECLINED",
    ARCHIVED = "ARCHIVED",
}

export enum RentHousesObjectPatchStatus {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
}

export type RentHousesObjectPatch = {
    id: string;
    createdAt: string;
    updatedAt: string;
    status: RentHousesObjectPatchStatus;
    cmsId: string;
    declineReason?: string;
};

export type RentHousesObject = {
    id: string;
    cmsId: string;
    createdAt: string;
    updatedAt: string;
    status: RentHousesObjectStatus;
    blocked: boolean;
    title?: string;
    // id изображения в CMS
    mainImage?: string;
    housingType?: string;
    patches?: RentHousesObjectPatch[];
    declineReason?: string;
    patchStatus?: RentHousesObjectPatchStatus;
    blockedFrom?: string;
    blockedUntil?: string;
    blockedReason?: string;
};

export type RentHousesCancellationPolicyItem = {
    id: string;
    createdAt: string;
    updatedAt: string;
    title: string;
    description?: string;
    freeCancellationDeadline?: number;
    penaltyCancellationDeadline?: number;
    penaltyType?: "FIRSTNIGHTPRICE";
    listOrder: number;
};

export enum RentHousesScheduleType {
    DAYS_OF_WEEK = "DAYS_OF_WEEK",
    DATES = "DATES",
    DATE_RANGES = "DATE_RANGES",
}

export enum RentHousesDaysOfWeek {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
}

export type RentHousesObjectRequestStatus = {
    id: string;
    status: RentHousesObjectStatus;
};

export type RentHousesRate = {
    scheduleType: RentHousesScheduleType;
    daysOfWeek?: RentHousesDaysOfWeek[];
    dates?: { from: string; to: string }[];
    price: number;
};

export type RentHousesObjectBed = { id: string; count: number };

export type RentHousesObjectInfo = {
    title?: string;
    description?: string;
    housingType?: string;
    area?: number;
    amenities?: string[];
    region?: string;
    timeZone?: string;
    timeZoneValue?: string;
    city?: string;
    address?: string;
    coordinates?: [number, number];
    maxGuestCount?: number;
    bathroomCount?: number;
    checkinTime?: string;
    checkoutTime?: string;
    checkinType?: string;
    homeRules?: string[];
    rooms?: { roomType: string; beds: RentHousesObjectBed[] }[];
    images?: { id: string; caption?: string; isMain: boolean }[];
    rates?: RentHousesRate[];
    cancellationPolicy?: string;
    discounts?: [{ type: "DAYSCOUNT"; amount: number; unit: "PERCENT"; daysCount: number }];
};

export type RentHousesObjectFullInfo = RentHousesObjectInfo & RentHousesObject;

export enum RentHousesBookingStatus {
    PENDING = "PENDING",
    ACTIVE = "ACTIVE",
    STARTED = "STARTED",
    COMPLETED = "COMPLETED",
    WAITING_TO_REJECT = "WAITING_TO_REJECT",
    REJECTED = "REJECTED",
}

export type RentHousesBookingItem = {
    id: string;
    createdAt: string;
    updatedAt: string;
    expireAt: string;
    checkinAt: string;
    status: RentHousesBookingStatus;
    checkinDate: string;
    checkoutDate: string;
    amount: number;
    guestCount: number;
    object: {
        id: string;
        title: string;
        cmsId: string;
    };
    paid: boolean;
    lookedByPartner: boolean;
};

export type RentHousesGuestsCount = {
    adults: number;
    children: number;
    childrenAges?: number[];
};

export enum BookingRejectReason {
    AUTO_CANCEL_WITHOUT_PAYMENT = "AUTO_CANCEL_WITHOUT_PAYMENT",
    AUTO_CANCEL_WITHOUT_PARTNER_CONFIRMATION = "AUTO_CANCEL_WITHOUT_PARTNER_CONFIRMATION",
    USER_CANCEL = "USER_CANCEL",
    USER_REFUND = "USER_REFUND",
    USER_REFUND_BY_SUPPORT = "USER_REFUND_BY_SUPPORT",
    USER_REPEAL_BY_ADMIN = "USER_REPEAL_BY_ADMIN",
    PARTNER_CANCEL = "PARTNER_CANCEL",
    PARTNER_REFUND = "PARTNER_REFUND",
    PARTNER_REFUND_BY_SUPPORT = "PARTNER_REFUND_BY_SUPPORT",
    PARTNER_REPEAL_BY_ADMIN = "PARTNER_REPEAL_BY_ADMIN",
}

export type RentHousesBooking = {
    id: string;
    createdAt: string;
    updatedAt: string;
    expireAt: string;
    checkinAt: string;
    status: RentHousesBookingStatus;
    checkinDate: string;
    checkoutDate: string;
    amount: number;
    guestCount: RentHousesGuestsCount;
    object: {
        id: string;
        title: string;
        cmsId: string;
    };
    user: {
        externalId: number;
        firstName: string;
        lastName: string;
        middleName?: string;
        rating?: number;
    };
    tripPurpose?: keyof typeof TripType;
    pets: { included: boolean; description?: string };
    paid: boolean;
    expireIn: number;
    payUntil?: string;
    comment?: string;
    rejectReason?: BookingRejectReason;
    rejectedAt?: string;
    guest: {
        firstName: string;
        lastName: string;
        middleName?: string;
    };
    uid: string;
    plannedCheckinAt: string;
    availableToCancellation?: boolean;
};

export enum TripType {
    REST = "Отдых",
    BUSINESS = "Работа",
}

export type RentHousesBookingRequestStatus = {
    id: string;
    status: RentHousesBookingStatus;
};

export type RentHousesBookingMark = {
    id: string;
    createdAt: string;
    updatedAt: string;
    status: string;
    value: number;
};

export type RentHousesBookingEventMark = {
    id: number;
    creationalDate: string;
    updateDate: string;
    value: number;
    badges: number[];
    status: string;
    externalUser: ExternalUser;
    order: {
        uid: string;
        createDate: string;
        tripPurpose?: keyof typeof TripType;
    };
};

export type RentHousesBookingEventMarkDetails = {
    id: number;
    creationalDate: string;
    updateDate: string;
    eventExternalId: string;
    value: number;
    badges: RatingsBadge[];
    status: string;
    loyalityExtraAccrued: boolean;
    externalUser: Omit<ExternalUser, "id"> & { externalId: number };
    orderId: number;
    orderUid: number;
    visitDateFrom: string;
    visitDateTo: string;
    positive: string;
    negative: string;
    comment: string;
    dispute?: boolean;
};

export enum RentHousesBookingByUidStatus {
    PENDING = "PENDING",
    ACTIVE = "ACTIVE",
    STARTED = "STARTED",
    COMPLETED = "COMPLETED",
    WAITING_TO_REJECT = "WAITING_TO_REJECT",
    REJECTED = "REJECTED",
}

export type RentHousesBookingByUid = {
    id: string;
    status: RentHousesBookingByUidStatus;
    paid: boolean;
    createdAt: string;
    updatedAt: string;
    expireAt: string;
    expireIn: number;
    checkinAt: string;
    payUntil?: string;
    checkinDate: string;
    checkoutDate: string;
    amount: string;
    guestCount: {
        adults: number;
        children: number;
        childrenAges?: number[];
    };
    object: {
        id: string;
        title: string;
        cmsId: string;
    };
    user: {
        externalId: number;
        firstName: string;
        lastName: string;
        middleName?: string;
        rating?: number;
    };
    guest: {
        firstName: string;
        lastName: string;
        middleName?: string;
    };
    tripPurpose?: keyof typeof TripType;
    pets: {
        included: boolean;
        description?: string;
    };
    comment?: string;
    rejectReason: string;
    rejectedAt?: string;
    uid: string;
    plannedCheckinAt: string;
    lookedByPartner: boolean;
    availableToCancellation: boolean;
};
