import React, { useCallback, useEffect, useState } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import routes from "../constants/routes";
import { History, LocationState } from "history";
import KeycloakManager from "../lib/keycloak-manager";
import signOut from "../lib/sign-out";
import useUserInfo from "../hooks/useUserInfo";
import useAccessManager from "../hooks/useAccessManager";

const ProtectedRoute = (props: RouteProps) => {
    const history = useHistory();
    const isAuthenticated = KeycloakManager.getIsAuthenticated();
    const [isView, setIsView] = useState(false);

    const { isGuide } = useUserInfo();
    const { getMainPageRedirectPath, getIsAvailableRoute } = useAccessManager();

    const checkAccessRights = useCallback(
        (path: string | readonly string[] | undefined, history: History<LocationState>) => {
            if (path === routes.main) {
                history.push(getMainPageRedirectPath());
                return false;
            } else {
                const isAvailable = getIsAvailableRoute(path);
                if (!isAvailable) {
                    history.push(routes.main);
                }
                return isAvailable;
            }
        },
        [getMainPageRedirectPath, getIsAvailableRoute]
    );

    useEffect(() => {
        if (!isAuthenticated) {
            signOut(window.location.href);
        }
        setIsView(checkAccessRights(props.path, history));
    }, [history, isAuthenticated, props.path, isGuide, checkAccessRights]);

    return isAuthenticated && isView ? <Route {...props} /> : null;
};

export default ProtectedRoute;
