import { boolean as booleanYup, object as objectYup, string as stringYup } from "yup";
import { FORMAT_ERROR, FORM_FIELD_REQUIRED_ERROR } from "../../../../../constants/errors";
import { onlyNumbersRegexp } from "../../../../../utils/validation";
import { PENALTY_TYPE_FIX, PENALTY_TYPE_PERCENT } from "../constants/hotel-tariff";

export const hotelTariffValidation = objectYup().shape({
    title: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    foodType: objectYup().shape({
        include: booleanYup().required(FORM_FIELD_REQUIRED_ERROR),
        cmsId: stringYup().test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
            const { include } = this.parent;
            return include ? !!value : true;
        }),
    }),
    cancellationPolicy: objectYup().shape({
        freeCancellationPossible: booleanYup().required(FORM_FIELD_REQUIRED_ERROR),
        penaltyAmountFix: stringYup()
            .test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
                const { penaltyType } = this.parent;
                return penaltyType === PENALTY_TYPE_FIX.id ? !!value : true;
            })
            .matches(onlyNumbersRegexp, {
                message: FORMAT_ERROR,
            }),
        penaltyAmountPercent: stringYup()
            .test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
                const { penaltyType } = this.parent;
                return penaltyType === PENALTY_TYPE_PERCENT.id ? !!value : true;
            })
            .matches(onlyNumbersRegexp, {
                message: FORMAT_ERROR,
            }),
        freeCancellationDeadline: stringYup()
            .test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
                const { freeCancellationPossible } = this.parent;
                return freeCancellationPossible ? !!value : true;
            })
            .matches(onlyNumbersRegexp, {
                message: FORMAT_ERROR,
            }),
    }),
});
