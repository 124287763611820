import React, { useCallback } from "react";
import styles from "./DiscountDateField.module.sass";
import { RadioSlider, RangeDatePickerField } from "@russpass/partner-front-ui";
import { useFormikContext } from "formik";
import { Range } from "../../../../../../../../../../components/timetable/place-timetable/types";

enum DiscountDate {
    BOOKING = "bookingDate",
    STAY = "stayDate",
}

const OPTIONS = [
    { label: "Даты бронирования", value: DiscountDate.BOOKING },
    { label: "Даты проживания", value: DiscountDate.STAY },
];

const PREFIX = {
    [DiscountDate.BOOKING]: "При бронировании",
    [DiscountDate.STAY]: "При проживании",
};

type Props = {
    name: string;
    tabName: string;
    disabled?: boolean;
};

const DiscountDateField = ({ name, tabName, disabled }: Props) => {
    const { getFieldProps, setFieldValue } = useFormikContext<any>();

    const value = getFieldProps(tabName).value as DiscountDate;

    const getDisplayValue = useCallback(
        (range: Range | undefined, displayValue: string) => {
            const prefix = PREFIX[value];
            if (!prefix) return displayValue;

            return `${prefix} ${displayValue}`;
        },
        [value]
    );

    const handleChange = useCallback((value) => setFieldValue(tabName, value), [setFieldValue, tabName]);

    return (
        <RangeDatePickerField
            name={name}
            label="Акция действует"
            fromMonth={new Date()}
            headerComponent={
                <div className={styles.Tabs}>
                    <RadioSlider options={OPTIONS} value={value} onChange={handleChange} />
                </div>
            }
            getDisplayValue={getDisplayValue}
            disabled={disabled}
            hint="Акция может распространяться на даты бронирования или даты проживания"
        />
    );
};

export default DiscountDateField;
