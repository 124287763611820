import React, { useEffect, useState, useCallback } from "react";
import { Form, Formik, FormikProps } from "formik";
import { HotelDataForm } from "../../types/hotel-info";
import "./styles.sass";
import HotelServices from "../../../../components/hotel-services/hotel-services";
import HotelInfoPhones from "./hotel-info-phones";
import { hotelInfoValidation } from "../../validation-schemas/hotel-info";
import { cloneDeep, noop } from "lodash";
import Region from "../../../../../../components/async-selects/region";
import SelectCity from "../../../../../../components/async-selects/select-city";
import SelectAddress from "../../../../../../components/async-selects/address";
import { starsOptions } from "../../constants/hotel-info";
import { maskedInputTime } from "../../../../../../utils/validation";
import { isEqualObjects } from "../../../../../../utils/common";
import { modalAlertDetails } from "./constants";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";
import { getRegionWithTZ } from "../../utils/hotel-info";
import {
    QuestionTooltip,
    useModalAlertContext,
    ModalWindowRight,
    InputField,
    TextareaField,
    StatusRequestCard,
    StatusRequestEnum,
    SelectInputField,
    DndUploadPhotosField,
} from "@russpass/partner-front-ui";
import useServicesData from "../../../../components/hotel-services/useServicesData";
import { HousingTypeSelect } from "./components/housing-type-select/housing-type-select";
import { useHotelDataContext } from "../../../hotel-view/hotel-data-context";
import useHotelTypes from "../../hooks/useHotelTypes";
import { StatusRequestCardSubtitleError } from "../../../../../../constants/errors";
import { onError, onFileTooLarge, onPhotosLimit } from "../../../../../../utils/images";
import { getImageById, uploadPhotoToCms } from "../../../../../../api/files";

type HotelInfoProps = {
    isView: boolean;
    onClose: (status: boolean) => void;
    disabled?: boolean;
    onSave: (values: HotelDataForm) => Promise<void>;
    isExistingHotel: boolean;
    isViewCopyHotelId: boolean;
    status: StatusRequestEnum | null;
};

const HotelInfo = ({ isView, onClose, onSave, disabled, isViewCopyHotelId, status }: HotelInfoProps) => {
    const { hotelInfoData, isTravelLineHotel } = useHotelDataContext();

    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const { services, loadServices, isLoadingServices } = useServicesData(false, true);

    const { onLoadHotelTypes, isLoadingHotelTypes } = useHotelTypes();

    useEffect(() => {
        if (isView) {
            loadServices();
            if (isTravelLineHotel) {
                onLoadHotelTypes().then(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isView, isTravelLineHotel]);

    const { openModalAlert } = useModalAlertContext();

    const handleCloseAfterRequest = () => {
        const isSuccessRequest = status === StatusRequestEnum.Success;
        onClose(!isSuccessRequest);
    };

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);

    return (
        <Formik
            initialValues={hotelInfoData}
            validationSchema={hotelInfoValidation()}
            enableReinitialize
            validateOnMount
            onSubmit={noop}
        >
            {({ values, setValues, setFieldValue, submitForm, isValid }: FormikProps<HotelDataForm>) => {
                const handleClick = () => {
                    if (isValid) {
                        setIsSubmittingForm(true);
                        onSave(values)
                            .then(null)
                            .finally(() => {
                                setIsSubmittingForm(false);
                            });
                        sendEvent(AnalyticEvent.object_info_save_button);
                    } else {
                        return;
                    }
                };

                const handleCloseModalWindowRight = () => {
                    if (isEqualObjects(hotelInfoData, values)) {
                        onClose(false);
                    } else {
                        openModalAlert(modalAlertDetails, handleCloseWithoutSave);
                    }
                };

                const handleCloseWithoutSave = () => {
                    setValues(cloneDeep(hotelInfoData) as HotelDataForm);
                    onClose(false);
                    sendEvent(AnalyticEvent.without_save_button_click);
                };

                const handleChangeValues = (newValue: HotelDataForm) => {
                    setValues((prevState) => ({
                        ...prevState,
                        ...newValue,
                    }));
                };

                const handleChangeRegion = async (value: { id: string }) => {
                    const data = await getRegionWithTZ(value.id);
                    setFieldValue("region", data.region);
                    setFieldValue("timeZone", data.timeZone);
                };

                const handleChangeAddress = (value: { data: { geo_lat?: string; geo_lon?: string } }) => {
                    setFieldValue("address", value);
                    if (value.data.geo_lat && value.data.geo_lon) {
                        setFieldValue("coordinates", [value.data.geo_lat, value.data.geo_lon]);
                    }
                };

                const handleRepeat = () => onSave(values);

                return (
                    <ModalWindowRight
                        isOpened={isView}
                        onClose={handleCloseModalWindowRight}
                        position={1}
                        isLoadingWithoutContent={isLoadingServices || isLoadingHotelTypes}
                        isLoadingWithContent={isSubmittingForm}
                        isHideCloseButton={!!status}
                        ComponentFooter={
                            !disabled && !status ? (
                                <button
                                    className="button button--primary"
                                    onClick={() => submitForm().then(handleClick)}
                                >
                                    Сохранить
                                </button>
                            ) : undefined
                        }
                        headerComponent={
                            !status && (
                                <div className="hotel-info__header">
                                    <div className="hotel-info__header--title">Информация об объекте</div>
                                    <div className="hotel-info__header--name">{values.title}</div>
                                </div>
                            )
                        }
                    >
                        {!status ? (
                            <div className="content-wrapper">
                                <div className="content-wrapper__form">
                                    <Form>
                                        <InputField
                                            label="Название"
                                            name="title"
                                            noHover
                                            placeholder="Пальмира Бизнес Клуб"
                                            disabled={disabled}
                                            errorSettings={{
                                                showOnChange: isSubmittingForm,
                                            }}
                                            maxLength={100}
                                        />
                                        {isViewCopyHotelId && (
                                            <InputField
                                                label="ID в системе RUSSPASS"
                                                name="hotelId"
                                                noHover
                                                placeholder="Пальмира Бизнес Клуб"
                                                disabled={true}
                                                errorSettings={{
                                                    showOnChange: isSubmittingForm,
                                                }}
                                            />
                                        )}
                                        <HousingTypeSelect />
                                        <SelectInputField
                                            name="stars"
                                            label="Звезды"
                                            placeholder="Выберите"
                                            options={starsOptions}
                                            disabled={disabled}
                                            value={
                                                starsOptions.find((option) => option.value === values.stars) || {
                                                    value: undefined,
                                                    label: "",
                                                }
                                            }
                                            isChecked={true}
                                            handleChange={(value) => {
                                                setFieldValue("stars", value.value);
                                            }}
                                            errorSettings={{
                                                showOnChange: isSubmittingForm,
                                            }}
                                        />

                                        <TextareaField
                                            label="Описание"
                                            name="description"
                                            noHover
                                            maxRows={6}
                                            disabled={disabled}
                                            placeholder="Расскажите подробно о вашем объекте"
                                            maxLength={800}
                                            hint="Эта информация отразится на странице вашего объекта на сайте RUSSPASS"
                                            errorSettings={{
                                                showOnChange: isSubmittingForm,
                                            }}
                                        />

                                        <HotelServices
                                            data={{
                                                commonServices: values.commonServices,
                                                fitnessServices: values.fitnessServices,
                                                entertainmentServices: values.entertainmentServices,
                                                facilityServices: values.facilityServices,
                                                beachServices: values.beachServices,
                                            }}
                                            disabled={disabled}
                                            hint={
                                                "Укажите, какие услуги и сервисы есть в объекте. Внести информацию об удобствах в номерах вы сможете позже"
                                            }
                                            services={services}
                                            onChange={handleChangeValues}
                                            errorSettings={{
                                                showOnChange: isSubmittingForm,
                                            }}
                                        />

                                        <Region
                                            name="region.id"
                                            placeholder="Иркутская область"
                                            value={values.region}
                                            handleChange={handleChangeRegion}
                                            disabled={disabled}
                                            isShowErrors={isSubmittingForm}
                                        />

                                        {values.region.id && (
                                            <SelectInputField
                                                name="timeZone"
                                                label="Часовой пояс"
                                                placeholder="Выберите"
                                                options={values.region.timeZone}
                                                disabled={disabled || values.region.timeZone.length <= 1}
                                                value={
                                                    values.region.timeZone.find(
                                                        (option) => option.id === values.timeZone
                                                    ) || {
                                                        value: undefined,
                                                        label: "",
                                                    }
                                                }
                                                labelField="name"
                                                valueField="id"
                                                isChecked={true}
                                                handleChange={(value) => {
                                                    setFieldValue("timeZone", value.id);
                                                }}
                                                errorSettings={{
                                                    showOnChange:
                                                        values.region.timeZone.length === 0 ? true : isSubmittingForm,
                                                }}
                                            />
                                        )}

                                        <SelectCity
                                            name="city.id"
                                            placeholder="Иркутск"
                                            value={values.city}
                                            disabled={disabled}
                                            handleChange={(value) => {
                                                setFieldValue("city", value);
                                            }}
                                            label="Населенный пункт"
                                            hint="Если в списке нет населенного пункта, в котором расположен ваш объект, выберите ближайший к нему"
                                            isShowErrors={isSubmittingForm}
                                        />

                                        <SelectAddress
                                            name="address.unrestricted_value"
                                            value={values.address}
                                            errorSettings={{
                                                showOnChange: isSubmittingForm,
                                            }}
                                            placeholder="Улица, дом"
                                            disabled={disabled}
                                            labelField="value"
                                            handleChange={handleChangeAddress}
                                        />

                                        <div className="hotel-info__row">
                                            <div className="hotel-info__col">
                                                <InputField
                                                    label="Время заезда"
                                                    name="arrivalTime"
                                                    placeholder="14:00"
                                                    renderComponent={maskedInputTime}
                                                    disabled={disabled}
                                                    errorSettings={{
                                                        showOnChange: isSubmittingForm,
                                                    }}
                                                    noHover
                                                />
                                            </div>
                                            <div className="hotel-info__col">
                                                <InputField
                                                    label="Время выезда"
                                                    name="departureTime"
                                                    placeholder="12:00"
                                                    disabled={disabled}
                                                    renderComponent={maskedInputTime}
                                                    errorSettings={{
                                                        showOnChange: isSubmittingForm,
                                                    }}
                                                    noHover
                                                />
                                            </div>
                                        </div>

                                        <InputField
                                            label="E-mail"
                                            name="email"
                                            noHover
                                            placeholder="name@mail.ru"
                                            hint="E-mail отображается у туристов в подтверждении бронирования"
                                            disabled={disabled}
                                            errorSettings={{
                                                showOnChange: isSubmittingForm,
                                            }}
                                        />

                                        <HotelInfoPhones
                                            phones={values.phones}
                                            onChange={handleChangeValues}
                                            disabled={disabled}
                                            errorSettings={{
                                                showOnChange: isSubmittingForm,
                                            }}
                                        />
                                        <div className="content-wrapper">
                                            <DndUploadPhotosField
                                                name="imageExplorePreview"
                                                items={values.imageExplorePreview.map((image) => ({ image })) || []}
                                                disabled={disabled}
                                                onChange={(newValue) => {
                                                    setFieldValue(
                                                        "imageExplorePreview",
                                                        newValue.map((item) => item.image)
                                                    );
                                                }}
                                                isAvailablePhotoGuide={false}
                                                errorSettings={{
                                                    showOnChange: isSubmittingForm,
                                                }}
                                                cardHeight={122}
                                                cardsGap={{
                                                    rowGap: 24,
                                                    columnGap: 16,
                                                }}
                                                title={
                                                    <div className="hotel-info__upload_title">
                                                        Добавить фото объекта
                                                        <QuestionTooltip
                                                            settings={{
                                                                offsetY: -125,
                                                                width: "222px",
                                                            }}
                                                            body={
                                                                "Загрузите фотографии \u00a0 фасада, общей территории, ресепшена, холла, бассейна, ресторана и нескольких номеров"
                                                            }
                                                        />
                                                    </div>
                                                }
                                                getImageById={getImageById}
                                                uploadPhoto={uploadPhotoToCms}
                                                onFileTooLarge={onFileTooLarge}
                                                onPhotosLimit={onPhotosLimit}
                                                onError={onError}
                                            />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        ) : (
                            <StatusRequestCard
                                title={{
                                    success: `Объект \u00ab${values.title}\u00bb сохранен`,
                                    error: (
                                        <>
                                            {"Не удалось сохранить объект "}
                                            <br />
                                            {`\u00ab${values.title}\u00bb`}
                                        </>
                                    ),
                                }}
                                subtitle={{
                                    error: StatusRequestCardSubtitleError,
                                }}
                                handleClose={handleCloseAfterRequest}
                                status={status}
                                handleRepeat={handleRepeat}
                                handleBeforeClose={handleSendEvent}
                            />
                        )}
                    </ModalWindowRight>
                );
            }}
        </Formik>
    );
};

export default HotelInfo;
