import { useMemo } from "react";
import { LegalStateEnum } from "../../../../../enums/profile";

export type LegalStateStatus = {
    isFL: boolean;
    isSE: boolean;
    isIP: boolean;
    isUL: boolean;
};

export const useLegalStateStatus = (legalState?: LegalStateEnum): LegalStateStatus =>
    useMemo(
        () => ({
            // isFL: true,
            isFL: legalState === LegalStateEnum.SIMPLE_PERSON,
            // isSE: false,
            isSE: legalState === LegalStateEnum.SELF_EMPLOYED,
            // isIP: false,
            isIP: legalState === LegalStateEnum.INDIVIDUAL,
            // isUL: false,
            isUL: legalState === LegalStateEnum.LEGAL_ENTITY,
        }),
        [legalState]
    );
