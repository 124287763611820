import classNames from "classnames";
import { Formik, FormikProps } from "formik";
import { Profile } from "../../../../../types/profile";
import useDefaultForm from "./hooks/useDefaultForm";
import { defaultFormValidationSchema } from "./validationSchema";
import { InputField, TextareaField, Loader, SelectInputField, FileUploadField } from "@russpass/partner-front-ui";
import styles from "./defaultForm.module.sass";
import { DefaultFormData } from "./types/types";
import { MAX_FILES_COUNT, MAX_FILES_SIZE_SUM } from "../../../../../constants/validation";
import { setFileUploadError } from "../../../../../utils/react-files";
import useUserInfo from "../../../../../hooks/useUserInfo";
import ApartmentFields from "../apartment/ApartmentFields";

type DefaultFormProps = {
    submitForm: (values: DefaultFormData) => Promise<void>;
    profile: Profile;
};

const DefaultForm = ({ submitForm, profile }: DefaultFormProps) => {
    const { initialErrors, initialValues, onSubmit, isMobile, topicOptions } = useDefaultForm({
        submitForm,
        profile,
    });

    const { isApartment } = useUserInfo();

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize
            validationSchema={defaultFormValidationSchema}
            initialErrors={initialErrors}
        >
            {(formikProps: FormikProps<DefaultFormData>) => {
                const { handleSubmit, setFieldValue, setFieldTouched, isSubmitting, values } = formikProps;

                return (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.default_form__form_items}>
                            <SelectInputField
                                name="topic"
                                options={topicOptions}
                                value={topicOptions.find((v) => v.value === values.topic) || {}}
                                handleChange={(value) => setFieldValue("topic", value.value)}
                                label="Тема"
                                placeholder="Выбрать"
                                valueField="value"
                                labelField="label"
                            />
                            {isApartment && <ApartmentFields />}
                            <InputField
                                name="email"
                                placeholder="name@mail.com"
                                label="E-mail"
                                disabled={isSubmitting}
                                autoComplete="email"
                            />
                            <InputField
                                name="name"
                                placeholder="Как к вам обращаться"
                                label="Имя"
                                disabled={isSubmitting}
                            />
                            <div className={styles.default_form__textarea}>
                                <TextareaField
                                    name="details"
                                    placeholder="Напишите сообщение"
                                    label="Описание"
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className={styles.default_form__file_upload}>
                                <FileUploadField
                                    name="files"
                                    buttonTitle="Прикрепить файлы"
                                    accepts={[".jpeg", ".jpg", ".png", ".pdf"]}
                                    onChange={(files) => {
                                        setFieldTouched("files");
                                        setFieldValue("files", files);
                                    }}
                                    maxFileSize={MAX_FILES_SIZE_SUM}
                                    maxFiles={MAX_FILES_COUNT}
                                    onError={setFileUploadError}
                                    hint={`Загрузите подтверждающие документы — не более ${MAX_FILES_COUNT} файлов общим объемом ${MAX_FILES_SIZE_SUM} мб`}
                                />
                            </div>
                        </div>
                        <div className={styles.default_form__agreement}>
                            <div className={styles.default_form__agreement_text}>
                                Нажимая на кнопку, вы подтверждаете корректность введенной информации и даете согласие
                                на
                                <a
                                    href="https://russpass.ru/personal-data"
                                    title="персональные данные"
                                    className="offer-text__link"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {" "}
                                    обработку персональных данных
                                </a>
                            </div>
                            <button
                                type="submit"
                                className={classNames("button button--primary", isMobile && "button--full-width")}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <span className="button__icon">
                                        <Loader color="black" />
                                    </span>
                                ) : (
                                    "Отправить"
                                )}
                            </button>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

export default DefaultForm;
