import { FC } from "react";
import PageLoader from "../page-loader";
import { useAuth } from "./hooks/useAuth";

export const AuthProvider: FC = ({ children }) => {
    const { isInitializedKeycloak, isAuthenticated } = useAuth();

    if (!isInitializedKeycloak || !isAuthenticated) {
        return <PageLoader />;
    }

    return <>{children}</>;
};
