// Функция собирает query параметры для запроса
export const getParamsForRequest = (params: { [index: string]: string | number | undefined | boolean }) => {
    let _params = "";
    (Object.keys(params) as (keyof typeof params)[]).forEach((keyName) => {
        if (typeof params[keyName] !== "undefined" && params[keyName] !== "") {
            if (_params.length) {
                _params += "&";
            }
            _params += `${keyName}=${params[keyName]}`;
        }
        return keyName;
    });
    if (_params.length > 0) {
        _params = "?" + _params;
    }
    return _params;
};
