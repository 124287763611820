import history from "../../../../../../history";
import TariffCardItem from "./components/tariff-card-item";
import { isButtonAddTariff, modalAlertDetailsDeleteTariff } from "./constants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import HotelTariff from "../hotel-tariff";
import useControlHotelTariffData from "../../hooks/useControlHotelTariffData";
import styles from "./add-tariff-page.module.sass";
import { extensionTariffInfo } from "./helper";
import AlertDetailsText from "./components/alert-details-text";
import HotelRoom from "../hotel-room";
import useControlHotelRoomData from "../../hooks/useControlHotelRoomData";
import { IntegrationHotelRoom } from "../../types/hotel-room";
import { useModalAlertContext, CommonLayout, Card } from "@russpass/partner-front-ui";
import { HotelDataContext, useHotelDataContext } from "../../../hotel-view/hotel-data-context";
import { HotelTariffsContext } from "../../context/hotel-tariffs-context";
import { useControlHotelTariffsData } from "../../hooks/useControlHotelTariffsData";
import useControlHotelRoomsData from "../../hooks/useControlHotelRoomsData";
import useHotel from "../../../hotel-view/useHotel";
import { ChannelManagerCodeEnum } from "../../../../../../enums/channel-manager-code";
import { isEnableMovingOldTL } from "../../../../../../constants/feature-toggles";

const HotelTariffsWrap = () => {
    const { hotelInfoData, loadHotelInfoData, isTravelLineHotel, isNewTravelLineHotel } = useHotel({});

    const hotelDataProviderValue = useMemo(
        () => ({
            hotelInfoData,
            isTravelLineHotel,
            isNewTravelLineHotel,
            loadHotelInfoData,
        }),
        [hotelInfoData, loadHotelInfoData, isNewTravelLineHotel, isTravelLineHotel]
    );

    return (
        <HotelDataContext.Provider value={hotelDataProviderValue}>
            <HotelTariffs />
        </HotelDataContext.Provider>
    );
};

const HotelTariffs = () => {
    const location = useLocation();

    const [isLoadingInitialContent, setIsLoadingInitialContent] = useState<boolean>(true);

    const [isLoadingWithContent, setIsLoadingWithContent] = useState<boolean>(false);

    const { hotelInfoData, loadHotelInfoData, isTravelLineHotel } = useHotelDataContext();

    const { tariffs, reloadTariffs } = useControlHotelTariffsData();

    const {
        onSaveHotelTariffData,
        optionsTypeFood,
        onSelectTariff,
        onDeleteTariff,
        statusHotelTariff,
        isDisabledTariff,
        hotelTariffData,
        isLoadingTariff,
        setStatusHotelTariff,
        isViewHotelTariff,
        onCloseTariffWindow,
        onGetTariff,
    } = useControlHotelTariffData({ isPreloadDelay: true });

    const { openModalAlert, closeModalAlert } = useModalAlertContext();

    const loadData = useCallback(() => {
        setIsLoadingInitialContent(true);
        loadHotelInfoData().then((data) => {
            if (data?.partnerSystemType === ChannelManagerCodeEnum.TravelLine && !isEnableMovingOldTL) {
                history.replace({
                    pathname: location.pathname.replace("/tariffs", ""),
                    state: location?.state,
                });
            } else {
                reloadTariffs().then(() => {
                    setIsLoadingInitialContent(false);
                });
            }
        });
    }, [reloadTariffs, loadHotelInfoData, location]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tariffsShow = useMemo(() => {
        return extensionTariffInfo(tariffs, optionsTypeFood);
    }, [optionsTypeFood, tariffs]);

    const { reloadRooms } = useControlHotelRoomsData();

    //  for open hotel room modal window
    const {
        groupedAmenities,
        onChangeRoom,
        selectedHotelRoomData,
        onSelectRoom,
        isLoadingRoom,
        statusHotelRoom,
        isViewHotelRoom,
        changeIsViewHotelRoom,
    } = useControlHotelRoomData();

    const handlerSelectRoom = useCallback(
        async (room: IntegrationHotelRoom) => {
            closeModalAlert();
            onSelectRoom(room);
        },
        [closeModalAlert, onSelectRoom]
    );

    const handlerDeleteTariffClick = useCallback(
        async (ev: React.MouseEvent, tariffId: string) => {
            ev.stopPropagation();
            setIsLoadingWithContent(true);
            const data = await onGetTariff(tariffId);
            const tariffBoundRooms = data?.rooms;
            if (!tariffBoundRooms) {
                setIsLoadingWithContent(false);
                return;
            }
            const roomsData = await reloadRooms();
            const sameRooms = roomsData?.filter((room) => tariffBoundRooms?.includes(room.id)) || [];
            const getModalAlertDetails = () => {
                if (sameRooms.length) {
                    return {
                        ...modalAlertDetailsDeleteTariff.isRoomsWithTariff,
                        description: <AlertDetailsText sameRooms={sameRooms} onNameClick={handlerSelectRoom} />,
                    };
                } else {
                    return modalAlertDetailsDeleteTariff.noRoomsWithTariff;
                }
            };
            openModalAlert(getModalAlertDetails(), () => {
                if (!sameRooms.length) {
                    onDeleteTariff(tariffId).then(() => reloadTariffs());
                }
            });
            setIsLoadingWithContent(false);
        },
        [onGetTariff, reloadRooms, openModalAlert, handlerSelectRoom, onDeleteTariff, reloadTariffs]
    );

    const handlerSaveTariff = useCallback(
        async (tariff) => {
            const result = await onSaveHotelTariffData(tariff);
            reloadTariffs();
            return result;
        },
        [onSaveHotelTariffData, reloadTariffs]
    );

    const hotelTariffsProviderValue = useMemo(() => ({ tariffs }), [tariffs]);

    const onBackButtonClick = useCallback(() => {
        history.push({
            pathname: location.pathname.replace("/tariffs", ""),
            state: Object.assign(location?.state || {}, {
                isMoveOldTL: isTravelLineHotel,
            }),
            search: isTravelLineHotel ? `isMoveOldTL=true` : "",
        });
    }, [location, isTravelLineHotel]);

    const backButton = useMemo(
        () => ({
            title: "Назад",
            onClick: onBackButtonClick,
        }),
        [onBackButtonClick]
    );

    return (
        <HotelTariffsContext.Provider value={hotelTariffsProviderValue}>
            <CommonLayout
                pageTitle="Тарифы"
                backButton={backButton}
                description={hotelInfoData.title}
                isLoadingWithoutContent={isLoadingInitialContent}
                isLoadingWithContent={isLoadingWithContent}
            >
                <>
                    <div className={styles.tariffCards__container}>
                        <Card title="Добавить тариф" onClick={onSelectTariff} styles={isButtonAddTariff} />
                        {tariffsShow.length > 0 &&
                            tariffsShow.map((tariff: any) => (
                                <TariffCardItem
                                    data={tariff}
                                    key={tariff.id}
                                    onClick={onSelectTariff}
                                    handleDeleteTariff={handlerDeleteTariffClick}
                                />
                            ))}
                    </div>
                    <HotelTariff
                        isView={isViewHotelTariff}
                        onClose={onCloseTariffWindow}
                        isLoading={isLoadingTariff}
                        hotelTariffData={hotelTariffData}
                        onSave={handlerSaveTariff}
                        optionsTypeFood={optionsTypeFood}
                        isDisabled={isDisabledTariff}
                        status={statusHotelTariff}
                        setStatus={setStatusHotelTariff}
                    />
                    <HotelRoom
                        isView={isViewHotelRoom}
                        isLoading={isLoadingRoom}
                        onChangeHotelRoom={onChangeRoom}
                        groupedAmenities={groupedAmenities}
                        room={selectedHotelRoomData}
                        onClose={changeIsViewHotelRoom}
                        status={statusHotelRoom}
                    />
                </>
            </CommonLayout>
        </HotelTariffsContext.Provider>
    );
};

export default HotelTariffsWrap;
