import { createContext, FC, useMemo } from "react";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

type StatusSigningOfferProps = {
    isLoadingForm: boolean;
    onCloseOffer: () => void;
    simpleRegistration: boolean;
    statusSigningOffer: StatusRequestEnum | null;
};

export const FormManagerContext = createContext<StatusSigningOfferProps>({
    isLoadingForm: false,
    onCloseOffer: () => {},
    simpleRegistration: false,
    statusSigningOffer: null,
});

const FormManagerProvider: FC<StatusSigningOfferProps> = ({
    children,
    isLoadingForm,
    onCloseOffer,
    simpleRegistration,
    statusSigningOffer,
}) => {
    const providerValue = useMemo(
        () => ({
            statusSigningOffer,
            isLoadingForm,
            onCloseOffer,
            simpleRegistration,
        }),
        [isLoadingForm, onCloseOffer, simpleRegistration, statusSigningOffer]
    );
    return <FormManagerContext.Provider value={providerValue}>{children}</FormManagerContext.Provider>;
};

export default FormManagerProvider;
