export const AGREEMENT_SLUG_TYPE = Object.freeze({
    publicOrerta: "publichnaya-oferta",
    covidCheckList: "covid-checklist",
    covidPersonalData: "covid-personal-data",
});

//Убрали лишние селекты для ЛКП
export const CONTACT_FORM_TOPIC_v3 = Object.freeze({
    technicalError: "50",
    partnership: "39",
    other: "40",
});

export const APARTMENTS_CONTACT_FORM_TOPIC = Object.freeze({
    rentHousesBookingProblem: "rentHousesBookingProblem",
    ...CONTACT_FORM_TOPIC_v3,
});
