import { HotelAddress, HotelCity, HotelData, HotelDataForm, HotelPhone, HotelRegion } from "../types/hotel-info";
import { cloneDeep } from "lodash";
import { allStatusesHotel } from "../../../all/constants";

export const HOTEL_INFO_STEP_DESC_FULL = "Заполнено";

export const hotelRegion: HotelRegion = {
    id: "",
    title: "",
    timeZone: [],
};

export const hotelCity: HotelCity = {
    id: "",
    title: "",
};

export const hotelAddress: HotelAddress = {
    unrestricted_value: "",
    value: "",
};

export const hotelPhone: HotelPhone = {
    name: "",
    number: "",
};

export const hotelData: HotelData = {
    address: "",
    archivedDt: null,
    city: "",
    creationDt: "",
    imageDetailedPageMain: [],
    images: [],
    meals: [],
    region: "",
    timeZone: "",
    rooms: [],
    services: [],
    url: "",
    id: "",
    hotelId: "",
    title: "",
    housingType: "",
    description: "",
    email: "",
    stars: null,
    commonServices: [],
    fitnessServices: [],
    entertainmentServices: [],
    beachServices: [],
    facilityServices: [],
    phones: [cloneDeep(hotelPhone)],
    imageExplorePreview: [],
    arrivalTime: "",
    departureTime: "",
    partnerSystemType: null,
    coordinates: [],
    isRejected: false,
    rejectionReason: "",
    status: allStatusesHotel.draft,
    cmsId: "",
    hotelRooms: [],
    notPublishedRooms: [],
    hotelType: null,
};

export const hotelDataForm: HotelDataForm = {
    ...hotelData,
    address: cloneDeep(hotelAddress),
    region: cloneDeep(hotelRegion),
    city: cloneDeep(hotelCity),
};

export const starsOptions = [
    {
        value: "0",
        label: "Без звезд",
    },
    {
        value: "1",
        label: "1 звезда",
    },
    {
        value: "2",
        label: "2 звезды",
    },
    {
        value: "3",
        label: "3 звезды",
    },
    {
        value: "4",
        label: "4 звезды",
    },
    {
        value: "5",
        label: "5 звезд",
    },
];
