import Router from "./router";
import Notification from "./components/notification";
import history from "./history";
import { Router as ReactRouter } from "react-router-dom";
import { AuthProvider } from "./components/auth/auth-provider";
import { UserInfoProvider } from "./components/user-info-provider";
import {
    ModalInfoProvider,
    ViewportProvider,
    ModalAlertProvider,
    LayoutProvider,
    LayoutContextProps,
} from "@russpass/partner-front-ui";
import { SWRConfig } from "swr";
import { SWR_GLOBAL_CONFIG } from "./constants/swr-config";
import { FeatureToggles } from "@russpass/feature-toggle";
import { featureTogglesList } from "./config/feature-toggles";
import useMenu from "./hooks/useMenu";
import { sendEventByCancelButton, sendEventByOKButton } from "./product_analytics/analytics";
import { useMemo } from "react";
import { EventLayout } from "./containers/services/layout";

const App = () => {
    const { mainMenu, footerMenu } = useMenu();

    const LayoutProviderValue = useMemo(
        (): LayoutContextProps => ({
            mainMenu,
            footerMenu,
            modalAlertSendEventByOKButton: sendEventByOKButton,
            modalAlertSendEventByCancelButton: sendEventByCancelButton,
        }),
        [mainMenu, footerMenu]
    );

    return (
        <>
            <FeatureToggles list={featureTogglesList} />
            <ReactRouter history={history}>
                <AuthProvider>
                    <UserInfoProvider>
                        <ViewportProvider>
                            <ModalAlertProvider>
                                <ModalInfoProvider>
                                    <SWRConfig value={SWR_GLOBAL_CONFIG}>
                                        <LayoutProvider value={LayoutProviderValue}>
                                            <EventLayout>
                                                {" "}
                                                {/* TODO: Переделать контекст на хук */}
                                                <Router />
                                            </EventLayout>
                                        </LayoutProvider>
                                    </SWRConfig>
                                </ModalInfoProvider>
                            </ModalAlertProvider>
                        </ViewportProvider>
                    </UserInfoProvider>
                </AuthProvider>
                <Notification />
            </ReactRouter>
        </>
    );
};

export default App;
