import React from "react";
import TicketItem from "../ticket-item";
import { OrderExpanded } from "../../types";

type Props = {
    order: OrderExpanded | null;
};

const TicketList = ({ order }: Props) => {
    return (
        <>
            {order &&
                order.orderLines.map((orderLine, index) => (
                    <TicketItem
                        order={order}
                        data={orderLine}
                        key={`${orderLine.personalDataId}-${index}`}
                        index={index}
                    />
                ))}
        </>
    );
};

export default TicketList;
