import { russpassApiServices } from "../constants/api";
import fetchRequest from "./manager";

/**
 * Отправка письма в поддержку
 * @param email - email получателя
 * @param text - текст получателя
 */

const notificationServicePath = russpassApiServices.emailNotify;

export const sendSupport = async (
    email: string,
    subject: {
        appealId: string;
        theme: string;
    },
    text: string
) =>
    await fetchRequest.post(`${notificationServicePath}/support`, {
        email,
        text,
        ...subject,
    });
