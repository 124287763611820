import { ColorActiveTab, HeaderTab } from "@russpass/partner-front-ui";
import { useMemo } from "react";
import { NavbarApartmentsNewName } from "../constants";

export const useNavbar = (isLoading: boolean, isHasComment: boolean, isPublishedOrArchived: boolean, errors?: any) =>
    useMemo(() => {
        const navbarBase: HeaderTab[] = [
            ...(isHasComment
                ? [
                      {
                          label: "Замечания модератора",
                          name: NavbarApartmentsNewName.COMMENT,
                          colorActiveTab: ColorActiveTab.Secondary,
                      },
                  ]
                : []),
            {
                label: "Объект",
                name: NavbarApartmentsNewName.OBJECT,
            },
            {
                label: "Детали",
                name: NavbarApartmentsNewName.DETAILS,
            },
            {
                label: "Фото",
                name: NavbarApartmentsNewName.PHOTO,
            },
            {
                label: "Тарифы",
                name: NavbarApartmentsNewName.TARIFFS,
            },
            {
                label: "Скидка и отмена",
                name: NavbarApartmentsNewName.SALE_AND_CANCEL,
            },
            ...(isPublishedOrArchived
                ? [
                      {
                          label: "Отзывы",
                          name: NavbarApartmentsNewName.RATING_MARKS,
                      },
                  ]
                : []),
        ];

        if (isLoading) return navbarBase;

        if (errors) {
            Object.keys(errors).forEach((step) => {
                const tabIdx = navbarBase.findIndex((el) => el.name === step);
                if (tabIdx !== -1) {
                    navbarBase[tabIdx].isError = true;
                }
            });
        }

        return navbarBase;
    }, [errors, isHasComment, isLoading, isPublishedOrArchived]);
