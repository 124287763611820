import styles from "./picker-of-arrival-field.module.sass";
import React from "react";
import { InputField } from "@russpass/partner-front-ui";
import { maskedInputTime } from "../../../../../../../utils/validation";
import { BaseFieldProps } from "../../../../../../../types/fields";

type InputProps = {
    label: string;
    placeholder: string;
};

type PickerOfArrivalFieldProps = Omit<BaseFieldProps, "label" | "placeholder"> & {
    startProps: InputProps;
    endProps: InputProps;
};

export const PickerOfArrivalField = ({ name, disabled, startProps, endProps }: PickerOfArrivalFieldProps) => (
    <div className={styles.wrapper}>
        <InputField
            name={`${name}.start`}
            renderComponent={maskedInputTime}
            disabled={disabled}
            noHover
            {...startProps}
        />
        <InputField name={`${name}.end`} disabled={disabled} renderComponent={maskedInputTime} noHover {...endProps} />
    </div>
);
