import { useRentHousesObjects } from "../../../api/hooks/rentHouses";
import { RentHousesObjectStatus } from "../../../types/rentHouses";
import { useMemo, useState } from "react";
import { Sorting } from "../../../constants/filters";

const ALL_OPTION = { label: "Все объекты", value: "" };

export const useObjectsFilter = (useCmsId?: boolean) => {
    const [activeObject, setActiveObject] = useState<string>(ALL_OPTION.value);

    const { data, isLoading } = useRentHousesObjects({
        page: 1,
        pageSize: 100,
        status: [RentHousesObjectStatus.PUBLISHED, RentHousesObjectStatus.ARCHIVED],
        sort: Sorting.TitleAsc,
    });

    const objectOptions = useMemo(() => {
        const objectOptions =
            data?.data?.items.map((el) => ({ label: el.title || "", value: useCmsId ? el.cmsId : el.id || "" })) || [];

        return [ALL_OPTION, ...objectOptions];
    }, [data?.data?.items, useCmsId]);

    return {
        objectOptions,
        activeObject,
        setActiveObject,
        isLoading,
    };
};
