import { ReportItemType } from "../types";
//Группировка отчетных периодов по годам
export const separateReportByYear = (data: ReportItemType[]) => {
    const uniqueYear = Array.from(
        new Set(
            data.map((item) => {
                return new Date(item.startDate).getFullYear();
            })
        )
    ).sort((a, b) => b - a);

    const resultData = [];
    for (let year of uniqueYear) {
        const arrayCurrYaer = data.filter((item) => {
            return new Date(item.startDate).getFullYear() === year;
        });
        resultData.push({ year, arrayReports: arrayCurrYaer });
    }
    return resultData;
};
