import * as yup from "yup";
import {
    EMAIL_FORMAT_ERROR,
    FORM_FIELD_REQUIRED_ERROR,
    FORM_MAX_ERROR,
    FORMAT_ERROR,
    MAX_DATE,
    MAX_VALUE_NUMBER,
    MIN_VALUE_NUMBER,
} from "../constants/errors";
import { onlyNumbersRegexp, testFilesSizeSum } from "../utils/validation";
import { MAX_FILES_COUNT, MAX_FILES_SIZE_SUM } from "../constants/validation";

// Дефолтные сообщения об ошибках
yup.setLocale({
    mixed: {
        required: FORM_FIELD_REQUIRED_ERROR,
    },
    string: {
        matches: FORMAT_ERROR,
        max: FORM_MAX_ERROR,
        length: FORMAT_ERROR,
        email: EMAIL_FORMAT_ERROR,
    },
    date: {
        max: MAX_DATE,
    },
    number: {
        min: MIN_VALUE_NUMBER,
        max: MAX_VALUE_NUMBER,
    },
});
// yup.string().required() теперь выдает ошибку на строки, состоящие только из пробелов
yup.addMethod(yup.string, "required", function validate(message: string) {
    return this.test("notEmpty", message || FORM_FIELD_REQUIRED_ERROR, (value) => !!value).test(
        "notOnlyWhitespaces",
        FORMAT_ERROR,
        // @ts-ignore
        (value) => value?.trim()?.length > 0
    );
});
// yup.string().onlyNumbers()
yup.addMethod(yup.string, "onlyNumbers", function () {
    return this.matches(onlyNumbersRegexp);
});
// yup.array().required() теперь выдает ошибку, если массив пустой
yup.addMethod(yup.array, "required", function validate(message?: string) {
    return this.test("notEmpty", message || FORM_FIELD_REQUIRED_ERROR, (value) => !!value?.length);
});
// yup.array().maxFilesSizeSum()
yup.addMethod(yup.array, "limitFilesSizeSum", function validate(limit: number = MAX_FILES_SIZE_SUM, message?: string) {
    return this.test(
        "limitFilesSizeSum",
        message || `Загрузите файлы jpeg, jpg, png или pdf объемом до ${limit} мб`,
        testFilesSizeSum(limit)
    );
});
// yup.array().limitFiles()
yup.addMethod(yup.array, "limitFiles", function validate(limit: number = MAX_FILES_COUNT, message?: string) {
    return this.max(limit, message || `Число файлов не должно быть более ${limit}`);
});

// Типы для кастомных методов
declare module "yup" {
    interface StringSchema {
        onlyNumbers(): this;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface ArraySchema<T> {
        limitFilesSizeSum(limit?: number, message?: string): this;
        limitFiles(limit?: number, message?: string): this;
    }
}

export { yup };
