import { useCallback, useEffect, useMemo, useState } from "react";
import { Booking, BookingFilters as BookingFiltersType, RestaurantNameFilter } from "../types";
import useDebounce from "../../../../hooks/useDebounce";
import { getNameFilterOptions } from "../utils";
import { bookingFilter, newBookingFilter, PAGE_SIZE } from "../constants";
import { getRestaurantsAll } from "../../../../api/restaurants";
import { getReservations } from "../../../../api/restaurants/booking";
import eventDispatcher from "../../../../lib/event-dispatcher";
import routes from "../../../../constants/routes";
import { cloneDeep } from "lodash";
import { isEqualObjects } from "../../../../utils/common";

const useBookingList = (isNew?: boolean) => {
    const [data, setData] = useState<Booking[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [restaurantNameOptions, setRestaurantNameOptions] = useState<RestaurantNameFilter[]>([]);

    const [filters, setFilters] = useState<BookingFiltersType>(cloneDeep(isNew ? newBookingFilter : bookingFilter));

    const isEmpty = useMemo(
        () => isEqualObjects(filters, isNew ? newBookingFilter : bookingFilter) && data.length === 0,
        [filters, data, isNew]
    );

    const onChangeFilters = useCallback((values) => {
        setIsLoading(true);
        setFilters((prev) => ({
            ...prev,
            ...values,
        }));
    }, []);

    const debouncedFilters: BookingFiltersType = useDebounce(filters, 500);

    const loadedData = useCallback(async () => {
        setIsLoading(true);
        Promise.all([
            await getRestaurantsAll(),
            await getReservations({
                status: debouncedFilters.status,
                page: debouncedFilters.page + 1,
                cmsId: debouncedFilters.cmsId,
                pageSize: PAGE_SIZE,
                sort: debouncedFilters.sorting,
            }),
        ])
            .then((data) => {
                setRestaurantNameOptions(getNameFilterOptions(data[0]));
                setTotal(data[1].totalElements);
                setData(data[1].items);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [debouncedFilters]);

    useEffect(() => {
        loadedData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilters]);

    const refresh = useCallback(() => {
        loadedData();
        eventDispatcher.updateCounter({ route: routes.bookingAll });
    }, [loadedData]);

    return {
        data,
        total,
        isLoading,
        filters,
        onChangeFilters,
        restaurantNameOptions,
        refresh,
        isEmpty,
    };
};

export default useBookingList;
