import { ModalWindowRight, useViewportContext, StatusRequestEnum, StatusRequestCard } from "@russpass/partner-front-ui";
import React, { useEffect, useState, useCallback } from "react";
import styles from "./EnterOtpModal.module.sass";
import classNames from "classnames";
import { ReactComponent as CancelIcon } from "../../../../../assets/images/icons/ic_arrow_light_left.svg";
import EnterOtpForm from "./EnterOtpForm";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    phone: string;
    email: string;
    refreshData: () => void;
};

const EnterOtpModal = ({ isOpen, onClose, phone, email, refreshData }: Props) => {
    const { isMobile } = useViewportContext();

    const [isConfirmed, setIsConfirmed] = useState(false);
    useEffect(() => {
        if (!isOpen) return;

        setIsConfirmed(false);
    }, [isOpen]);

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);

    return (
        <ModalWindowRight isOpened={isOpen} isHideCloseButton onClose={onClose}>
            {!isConfirmed ? (
                <div className={classNames(styles.OtpWrapper, { [styles.Compact]: isMobile })}>
                    <button onClick={onClose} className={classNames("button", styles.CloseButton)}>
                        <CancelIcon className="button__icon" />
                    </button>
                    <EnterOtpForm
                        phone={phone}
                        email={email}
                        isOpen={isOpen}
                        setConfirmed={() => {
                            setIsConfirmed(true);
                            refreshData();
                        }}
                    />
                </div>
            ) : (
                <StatusRequestCard
                    status={StatusRequestEnum.Success}
                    title={{
                        success: isMobile ? (
                            <>
                                Вы успешно подтвердили <br /> номер телефона
                            </>
                        ) : (
                            "Номер телефона подтвержден"
                        ),
                    }}
                    handleClose={onClose}
                    handleBeforeClose={handleSendEvent}
                />
            )}
        </ModalWindowRight>
    );
};

export default EnterOtpModal;
