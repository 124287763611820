import { useCallback, useState } from "react";
import { useModalAlertContext, StatusRequestEnum } from "@russpass/partner-front-ui";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { confirmRentHousesBooking } from "../../../../../../api/rentHouses";
import { POST_DATA_ERROR } from "../../../../../../constants/errors";
import { closeBookingModal } from "../../utils/closeBookingModal";
import routes from "../../../../../../constants/routes";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";

export const useBookingConfirm = (id: string) => {
    const [isLoading, setIsLoading] = useState(false);

    const confirmRequest = useCallback(async () => {
        setIsLoading(true);
        sendEvent(AnalyticEvent.confirm_request_click);
        try {
            sendEvent(AnalyticEvent.confirm_page);
            await confirmRentHousesBooking(id);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Success,
                body: "Заявка подтверждена",
            });
            closeBookingModal();
            eventDispatcher.updateCounter({ route: routes.apartmentsBookingAll });
        } catch (e) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: POST_DATA_ERROR,
            });
        }
        setIsLoading(false);
    }, [id]);

    const { openModalAlert } = useModalAlertContext();

    const openModal = useCallback(() => {
        openModalAlert(
            {
                title: "Вы уверены, что хотите подтвердить заявку?",
                OKButton: "Подтвердить заявку",
                cancelButton: "Вернуться назад",
            },
            confirmRequest
        );
    }, [confirmRequest, openModalAlert]);

    return {
        openModal,
        isLoading,
    };
};
