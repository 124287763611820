import styles from "./styles.module.sass";
import React, { ReactNode, useCallback, useState } from "react";
import userIcon from "../../../../../../assets/images/icons/ic_user_40.svg";
import classNames from "classnames";
import moment from "moment/moment";
import { MIN_DAY_LONG_MONTH_DATE_FORMAT, TIME_FORMAT } from "../../../../../../constants/dates-format";
import { getFileLink } from "../../../../../../api/attach";
import { convertToMegabytes } from "../../../../../../utils/format-bytes";
import { FeedbackAttachment, FeedbackAttachments } from "../../../../../feedback/components/modal/FeedbackAttachments";

type Props = {
    image?: string;
    imagePlaceholder?: string;
    content: ReactNode;
    attachments?: {
        id: string;
        attachId: string;
        size: number;
        filename: string;
    }[];
    date?: string;
    showDate?: boolean;
    rtl?: boolean;
    fromCurrentUser?: boolean;
};

const IMG_EXTS = ["jpeg", "jpg", "png"];

const Message = ({
    content,
    image,
    imagePlaceholder = userIcon,
    attachments,
    date,
    showDate,
    rtl,
    fromCurrentUser,
}: Props) => {
    const [imgSrc, setImgSrc] = useState(image || imagePlaceholder);

    const handleImgError = useCallback(() => setImgSrc(imagePlaceholder), [imagePlaceholder]);

    return (
        <div
            className={classNames(styles.message, {
                [styles.message_rtl]: rtl,
                [styles["message_from-current-user"]]: fromCurrentUser,
            })}
        >
            {!!(showDate && date) && (
                <div className={styles.message__date}>{moment(date).format(MIN_DAY_LONG_MONTH_DATE_FORMAT)}</div>
            )}
            <div className={styles.message__content}>
                <div className={styles["message__img-wrapper"]}>
                    <img src={imgSrc} onError={handleImgError} alt="avatar" />
                </div>
                <div className={styles["message__text-wrapper"]}>
                    <div className={styles.message__text}>
                        {content}

                        {!!attachments?.length && (
                            <FeedbackAttachments
                                attachments={
                                    attachments.map((file) => {
                                        const extension = file.filename.split(".").slice(-1).join("").toLowerCase();
                                        return {
                                            title: file.filename,
                                            previewUrl: IMG_EXTS.includes(extension)
                                                ? getFileLink(file.attachId)
                                                : undefined,
                                            info: `${extension} ${convertToMegabytes(file.size)} МБ`,
                                            downloadUrl: getFileLink(file.attachId),
                                        };
                                    }) as FeedbackAttachment[]
                                }
                            />
                        )}
                    </div>
                    {!!date && <div className={styles.message__time}>{moment(date).format(TIME_FORMAT)}</div>}
                </div>
            </div>
        </div>
    );
};

export default Message;
