import { HotelData } from "./types";

export const navbarHotelEditFormNames = {
    hotel: "hotel",
    photos: "photos",
    comment: "comment",
    ratings: "ratings",
};

export const navbarHotelEditFormLabels = {
    hotel: "Отель",
    photos: "Фото",
    comment: "Замечания модератора",
    ratings: "Отзывы",
};

export const navbarHotelEditForm = [
    {
        label: navbarHotelEditFormLabels.hotel,
        name: navbarHotelEditFormNames.hotel,
    },
    {
        label: navbarHotelEditFormLabels.photos,
        name: navbarHotelEditFormNames.photos,
    },
];

export const navbarHotelEditRatings = [
    {
        label: navbarHotelEditFormLabels.ratings,
        name: navbarHotelEditFormNames.ratings,
    },
];

export const navbarHotelComment = [
    {
        label: navbarHotelEditFormLabels.comment,
        name: navbarHotelEditFormNames.comment,
        colorActiveTab: "Secondary",
    },
];

export const hotelData: HotelData = {
    title: "",
    description: "",
    address: "",
    email: "",
    phones: [],
    imageExplorePreview: [],
    commonServices: [],
    meals: [],
    beachServices: [],
    entertainmentServices: [],
    fitnessServices: [],
    facilityServices: [],
    isRejected: false,
    rejectionReason: "",
};
