import { ExtendedTariffData, HotelTariffData, OptionTypeFood } from "../../types/hotel-tariff";
import { tariffDescriptionsText } from "./constants";
import { PENALTY_TYPE_FIX, PENALTY_TYPE_PERCENT } from "../../constants/hotel-tariff";

export const extensionTariffInfo = (tariffs: HotelTariffData[], optionsTypeFood: OptionTypeFood[]) => {
    const tariffsResult: ExtendedTariffData[] = [];
    tariffs.forEach((tariff: HotelTariffData) => {
        const _tariff: ExtendedTariffData = { ...tariff, tags: [] };
        const tags = [];
        let description: string = "";
        let classStyle;

        if (tariff.foodType.include) {
            classStyle = "included";
            const meal: OptionTypeFood | undefined = optionsTypeFood.find(
                (meal: OptionTypeFood) => meal.id === tariff.foodType.cmsId
            );
            description = meal?.title || "";
        } else {
            classStyle = "notincluded";
            description = "Без питания";
        }

        tags.push({ description, classStyle });
        if (tariff.cancellationPolicy.freeCancellationPossible) {
            tags.push({ description: tariffDescriptionsText.freeCancel, classStyle: "included" });
        } else {
            tags.push({ description: tariffDescriptionsText.paidСancel, classStyle: "payment" });
        }

        if (tariff.cancellationPolicy.penaltyAmountFix) {
            _tariff.cancellationPolicy.penaltyType = PENALTY_TYPE_FIX.id;
        } else if (tariff.cancellationPolicy.penaltyAmountPercent) {
            _tariff.cancellationPolicy.penaltyType = PENALTY_TYPE_PERCENT.id;
        }
        _tariff.tags = tags;
        tariffsResult.push(_tariff);
    });
    return tariffsResult;
};
