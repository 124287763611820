import { useMemo } from "react";
import { CONTACT_FORM_TOPIC_v3 } from "../../../../../../constants/support";
import { contactFormTopicType_v3 } from "../../dictionary";
import getApartmentTopicOptions from "../../apartment/utils/getApartmentTopicOptions";
import useUserInfo from "../../../../../../hooks/useUserInfo";
import { RentHousesBookingItem, RentHousesBookingStatus } from "../../../../../../types/rentHouses";
import moment from "moment";

const useTopicOptions = (reservations?: RentHousesBookingItem[] | undefined) => {
    const { isApartment } = useUserInfo();

    return useMemo(() => {
        const hasActiveBookings = reservations?.some((item) => item.status === RentHousesBookingStatus.ACTIVE);

        const twoWeeksAgo = moment().subtract(14, "days");
        const hasRecentCompletedBookings = reservations?.some(
            (item) =>
                item.status === RentHousesBookingStatus.COMPLETED && moment(item.checkoutDate).isAfter(twoWeeksAgo)
        );

        if (isApartment && (hasActiveBookings || hasRecentCompletedBookings)) {
            return getApartmentTopicOptions();
        }

        return Object.values(CONTACT_FORM_TOPIC_v3).map((item) => ({
            value: item,
            label: contactFormTopicType_v3(item),
        }));
    }, [isApartment]);
};

export default useTopicOptions;
