import { ProgressTab } from "@russpass/partner-front-ui";
import routes from "../../../../../constants/routes";

export const isNotDoneCardStyles = {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 12px 16px rgba(0, 0, 0, 0.04)",
    border: "none",
    padding: "24px 0",
    minHeight: "106px",
};
export const isDoneCardStyles = {
    border: "2px solid #FAEFDB",
    padding: "24px 0",
};

export const progressBarNames = {
    info: "Информация об объекте",
    tariffs: "Тарифы",
    rooms: "Номера",
    discounts: "Акции",
    rating: "Отзывы",
};

export const progressBarIds = {
    info: 1,
    tariffs: 2,
    rooms: 3,
    rating: 4,
};

export const progressBarHotel: Array<ProgressTab> = [
    {
        id: progressBarIds.info,
        label: progressBarNames.info,
        isFullFilled: false,
    },
    {
        id: progressBarIds.tariffs,
        label: progressBarNames.tariffs,
        isFullFilled: false,
    },
    {
        id: progressBarIds.rooms,
        label: progressBarNames.rooms,
        isFullFilled: false,
    },
];

export const hotelSubtitleError = (
    <>
        {"Повторите попытку или обратитесь"}
        <br />
        {" в "}
        {
            <a className="link" href={`${routes.support}`} target="_blank" rel="noreferrer">
                {"Поддержку"}
            </a>
        }
        {" для уточнения информации"}
    </>
);
