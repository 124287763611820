import { BaseFieldProps } from "../../../../../../types/fields";
import UploadFilesRenderer from "../../../../../../components/form-controls/upload-files/renderers/UploadFilesRenderer";
import { useCallback } from "react";
import styles from "./UploadPhotosField.module.sass";
import { getIn, useFormikContext } from "formik";
import { MAX_PHOTO_SIZE_BIT, MAX_PHOTO_SIZE_Mb, PHOTOS_LIMIT } from "../../../../../../constants/images";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import {
    FileRejection,
    FileWithPath,
    PhotosWithDescriptionsPreview,
    StatusRequestEnum,
    UploadFiles,
    UploadFilesType,
    UploadPhotosInput,
} from "@russpass/partner-front-ui";
import { FILE_FORMAT_ERROR, FILE_TOO_LARGE } from "../../../../../../constants/errors";
import { getImageById } from "../../../../../../api/files";

const UploadPhotosField = ({ name, disabled }: BaseFieldProps) => {
    const { setFieldValue, getFieldProps, errors, submitCount } = useFormikContext();

    const value = (getFieldProps(name).value as UploadFilesType[]) || undefined;

    const onDrop = useCallback(
        (acceptedFiles: FileWithPath[], rejectedFiles?: FileRejection[]) => {
            const formatted = acceptedFiles
                .filter((el) => el.size <= MAX_PHOTO_SIZE_BIT)
                .map((el: FileWithPath) => ({ file: el }));

            if (acceptedFiles.length !== formatted.length) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: FILE_TOO_LARGE(MAX_PHOTO_SIZE_Mb),
                });
            }

            if (rejectedFiles?.length) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: FILE_FORMAT_ERROR,
                });
            }

            const newValue = [...value, ...formatted].splice(0, PHOTOS_LIMIT);

            setFieldValue(name, newValue);
        },
        [name, setFieldValue, value]
    );

    const error = getIn(errors, name) || "";

    return (
        <div className="form-item">
            <UploadFiles
                preview={<UploadFilesRenderer preview={<PhotosWithDescriptionsPreview />} />}
                files={value}
                setFiles={(value) => setFieldValue(name, value)}
                error={submitCount ? error : undefined}
                accept={{ "image/jpeg": [".jpg", ".jpeg"], "image/png": [] }}
                maxFiles={PHOTOS_LIMIT}
                onDrop={onDrop}
                disabled={disabled}
                input={
                    <div className={styles.InputWrapper}>
                        <UploadPhotosInput
                            text="Добавить фото"
                            emptyText="Добавить фото"
                            hintText={`Максимум ${PHOTOS_LIMIT} фото по ${MAX_PHOTO_SIZE_Mb} мб, формат png, jpeg`}
                        />
                    </div>
                }
                getImageById={getImageById}
                style={{ gap: "48px" }}
                maxFileSize={MAX_PHOTO_SIZE_Mb}
            />
        </div>
    );
};

export default UploadPhotosField;
