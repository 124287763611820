import { useCallback, useMemo, useState } from "react";
import { DiscountStatus, DiscountType, HotelDiscountsData, HotelDiscountsForm } from "../../../types/hotel-discounts";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import {
    createSpecialOffers,
    sendSpecialOfferModeration,
    updateSpecialOffers,
} from "../../../../../../../api/specialOffers";
import eventDispatcher from "../../../../../../../lib/event-dispatcher";
import { COMMON_ERROR } from "../../../../../../../constants/errors";
import moment from "moment";
import { FormikHelpers } from "formik";
import { useHotelDiscountModalContext } from "../context/discount-modal/useHotelDiscountModalContext";
import { useHotelDiscountStoreContext } from "../context/discounts-store/useHotelDiscountStoreContext";
import { ISO_DATE_FORMAT } from "../../../../../../../constants/dates-format";
import useDeleteDiscount from "./useDeleteDiscount";

const DATE_UNAVAILABLE =
    "На эти даты в выбранном номере уже действует другая акция. Поменяйте даты или выберите другой номер";

const DISCOUNT_EXPIRED = "Невозможно опубликовать спецпредложение. Срок действия спецпредложения истек";

const showCommonError = () =>
    eventDispatcher.setNotification({
        status: StatusRequestEnum.Error,
        body: COMMON_ERROR,
    });

const checkIsValidDiscountDate = (discount: Partial<HotelDiscountsData>) =>
    moment(discount.bookingDateFrom).isAfter() || moment(discount.stayDateFrom).isAfter();

const useHotelDiscountModal = () => {
    const { onClose } = useHotelDiscountModalContext();

    const {
        hotelData: { cmsId, hotelId },
        hotelRoomsData,
    } = useHotelDiscountStoreContext();

    const openDeleteAlert = useDeleteDiscount();
    const { selectedDiscountData: discount, setSelectedDiscountData } = useHotelDiscountModalContext();

    const isDisabled = useMemo(() => {
        return !!discount?.id && discount.offerStatusCode !== DiscountStatus.DRAFT;
    }, [discount]);

    const [statusRequest, setStatusRequest] = useState<StatusRequestEnum | null>(null);

    const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

    const saveSpecialOffer = useCallback(async (values: HotelDiscountsForm) => {
        const saveValues = { ...values };
        delete saveValues.bookingDateFrom;
        delete saveValues.bookingDateTo;
        delete saveValues.stayDateFrom;
        delete saveValues.stayDateTo;

        const from = saveValues?.date?.from && moment(saveValues.date.from).format(ISO_DATE_FORMAT);
        const to = saveValues?.date?.to && moment(saveValues.date.to).format(ISO_DATE_FORMAT);

        const data = {
            ...saveValues,
            ...(saveValues.date && saveValues.dateView
                ? {
                      [saveValues.dateView + "From"]: `${from}T00:00:00`,
                      [saveValues.dateView + "To"]: `${to}T23:59:50`,
                  }
                : {}),
        };
        delete data.date;
        delete data.dateView;

        if (data.id) {
            delete data.cmsHotelId;
            delete data.hotelId;
            const result = (await updateSpecialOffers(data))?.data;
            return {
                ...result,
                id: data.id,
            };
        }

        return (await createSpecialOffers(data))?.data;
    }, []);

    const onEditDiscount = useCallback(() => {
        if (!discount) return;

        const discountCopy = { ...discount };

        if (!checkIsValidDiscountDate(discountCopy)) {
            delete discountCopy.stayDateFrom;
            delete discountCopy.stayDateTo;
            delete discountCopy.bookingDateFrom;
            delete discountCopy.bookingDateTo;
        }

        setSelectedDiscountData({ ...discountCopy, offerStatusCode: DiscountStatus.DRAFT });
    }, [discount, setSelectedDiscountData]);

    const validDate = useMemo(() => {
        if (!discount) return undefined;
        if (discount.bookingDateFrom && discount.bookingDateTo) {
            return {
                from: discount.bookingDateFrom,
                to: discount.bookingDateTo,
            };
        }

        if (discount.stayDateFrom && discount.stayDateTo) {
            return {
                from: discount.stayDateFrom,
                to: discount.stayDateTo,
            };
        }
        return undefined;
    }, [discount]);

    const initialValues: HotelDiscountsForm = useMemo(() => {
        const values: HotelDiscountsForm = {
            id: discount?.id,
            cmsHotelId: cmsId,
            title: discount?.title || "",
            roomId: discount?.roomId || "",
            specialOfferType: discount?.specialOfferType || DiscountType.DISCOUNT,
            bookingDateFrom: discount?.bookingDateFrom || null,
            bookingDateTo: discount?.bookingDateTo || null,
            stayDateFrom: discount?.stayDateFrom || null,
            stayDateTo: discount?.stayDateTo || null,
            dateView: discount?.stayDateFrom ? "stayDate" : "bookingDate",
            discountSize: discount?.discountSize || undefined,
            bonusCategory: discount?.bonusCategory || undefined,
            roomsCategoryUpgrade: discount?.roomsCategoryUpgrade || undefined,
            date: validDate,
            tariffs: discount?.tariffs,
        };

        if (!!discount && discount.offerStatusCode === DiscountStatus.DRAFT && !checkIsValidDiscountDate(discount)) {
            delete values.stayDateFrom;
            delete values.stayDateTo;
            delete values.bookingDateFrom;
            delete values.bookingDateTo;
            delete values.date;
            values.dateView = "stayDate";
        }

        return values;
    }, [cmsId, discount, validDate]);

    const onSubmitForm = useCallback(
        async (values: HotelDiscountsForm, formikHelpers: FormikHelpers<HotelDiscountsForm>) => {
            const roomName = hotelRoomsData.find((el) => el.id === values.roomId)?.name || "";

            const submitValues = { ...values, hotelId, roomName };

            if (
                discount?.offerStatusCode === DiscountStatus.ARCHIVE ||
                discount?.offerStatusCode === DiscountStatus.REFUSED
            ) {
                onEditDiscount();
                return;
            }

            if (discount && discount.offerStatusCode !== DiscountStatus.DRAFT) {
                openDeleteAlert(discount.id, discount.offerStatusCode, onClose);
                return;
            }

            setIsDisabledSubmit(true);

            let specialOfferId: string | undefined;
            try {
                const specialOffer = await saveSpecialOffer(submitValues);
                specialOfferId = specialOffer?.id;
            } catch (e) {
                console.error(e);
                if (e.data?.resp?.errorCode === 2006) {
                    formikHelpers.setFieldError("date", DATE_UNAVAILABLE);
                } else {
                    showCommonError();
                }
            }
            if (!specialOfferId) {
                setIsDisabledSubmit(false);
                return;
            }

            try {
                await sendSpecialOfferModeration(specialOfferId);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: "Акция отправлена на модерацию",
                });
                onClose(true, () => formikHelpers.resetForm({ values: initialValues }));
            } catch (e) {
                console.error(e);
                if (e.data?.resp?.errorCode === 2006) {
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: DISCOUNT_EXPIRED,
                    });
                } else {
                    showCommonError();
                }
            }

            setIsDisabledSubmit(false);
        },
        [hotelRoomsData, hotelId, discount, onEditDiscount, openDeleteAlert, onClose, saveSpecialOffer, initialValues]
    );

    const saveInArchive = useCallback(
        async (
            values: HotelDiscountsForm,
            setFieldError: (field: string, message: string | undefined) => void,
            onSuccess?: () => void
        ) => {
            try {
                setIsDisabledSubmit(true);
                await saveSpecialOffer(values);
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Success,
                    body: "Акция отправлена в Архив",
                });
                onClose(true);
                onSuccess?.();
            } catch (e) {
                console.error(e);
                if (e.data?.resp?.errorCode === 2006) {
                    setFieldError("date", DATE_UNAVAILABLE);
                } else {
                    showCommonError();
                }
            } finally {
                setIsDisabledSubmit(false);
            }
        },
        [onClose, saveSpecialOffer]
    );

    const beforeOnClose = useCallback(
        (status: boolean = false) => {
            setStatusRequest(null);
            onClose(status);
        },
        [onClose]
    );

    return useMemo(
        () => ({
            isDisabled,
            statusRequest,
            setStatusRequest,
            isDisabledSubmit,
            onSubmitForm,
            saveInArchive,
            beforeOnClose,
            initialValues,
        }),
        [beforeOnClose, initialValues, isDisabled, isDisabledSubmit, onSubmitForm, saveInArchive, statusRequest]
    );
};

export default useHotelDiscountModal;
