import { CommonLayout } from "@russpass/partner-front-ui";
import MosruIframe from "../../components/mosru-iframe";

const MOSRU_BI_IFRAME_URL = process.env.REACT_APP_MOSRU_BI_IFRAME_URL || "";
const TITLE = "Аналитика";

const extraStyles = {
    content: {
        padding: "0",
    },
    container: {
        padding: "0",
    },
    header: {
        border: "none",
    },
};

const Analytics = () => {
    return (
        <CommonLayout pageTitle={TITLE} extraStyles={extraStyles}>
            <MosruIframe url={MOSRU_BI_IFRAME_URL} title={TITLE} />
        </CommonLayout>
    );
};

export default Analytics;
