import { array as arrayYup, object as objectYup, string as stringYup } from "yup";
import { placeTimetableValidation } from "../../../../components/timetable/place-timetable/validation-schema";
import { FORM_FIELD_REQUIRED_ERROR } from "../../../../constants/errors";

export const serviceTariffValidation = (validateWithoutTime?: boolean) =>
    objectYup().shape({
        "tariffCaption[0].content": stringYup().test("test", FORM_FIELD_REQUIRED_ERROR, function () {
            return this.parent.tariffCaption[0].content?.length > 0;
        }),
        "itemPrices[0].price": stringYup().test("test", FORM_FIELD_REQUIRED_ERROR, function () {
            return this.parent.itemPrices[0].price?.length > 0;
        }),
        workingTime: arrayYup().of(placeTimetableValidation(false, validateWithoutTime)),
    });
