import moment from "moment";
import { saveTariff } from "../../../../api/billing";
import { weekDays } from "../../../../components/timetable/place-timetable/constants";
import { ISO_DATE_FORMAT } from "../../../../constants/dates-format";
import { Tariff } from "./types";
import uuid from "react-native-uuid";
import {
    dayOfTheWeekConversion,
    getDateControlArray,
    getDayTimeTableArray,
    getIsDays,
    getIsRangeDays,
    getIsWeekDays,
} from "../../../../components/timetable/place-timetable/utils";
import { PlaceTimetableItemType, Range } from "../../../../components/timetable/place-timetable/types";

export const createTariffs = async (newTariffs: Tariff[], billingProductId: number | string) => {
    const createdTariffs: Tariff[] = [];
    for (let tariff of newTariffs) {
        let _tariff: Tariff = {
            ...tariff,
            itemType: [
                {
                    id: billingProductId,
                },
            ],
        };
        delete _tariff["id"];
        const res = await saveTariff(_tariff);
        createdTariffs.push({ ..._tariff, id: res.id });
    }
    return createdTariffs;
};

const DATE_2061 = "2061-01-31";

// Подготовка данных действия тарифа в форме просмотра тарифа
export const preparedViewTariffWorkingTime = (data: Tariff) => {
    let workingDays: string[] | Range[] = [];
    if (data.activeSince && data.activeUntil) {
        const activeSince = moment(data.activeSince).format(ISO_DATE_FORMAT);
        const activeUntil = moment(data.activeUntil).format(ISO_DATE_FORMAT);

        if (activeSince !== activeUntil) {
            if (activeUntil === DATE_2061) {
                workingDays = data.workingDays;
            } else {
                workingDays = [
                    {
                        from: activeSince,
                        to: activeUntil,
                    },
                ];
            }
        } else {
            workingDays = [activeSince];
        }
    }
    return [
        {
            uuid: uuid.v4() as string,
            workingDays: workingDays,
            time:
                data.openedSince && data.openedUntil
                    ? [data.openedSince.slice(0, 5) + " - " + data.openedUntil.slice(0, 5)]
                    : [""],
        },
    ] as PlaceTimetableItemType[];
};

// Конвертация дней недели из объекта типа DayTimeTable в строку
export const convertFacilityToTariffWorkingTime = (data: PlaceTimetableItemType[]) =>
    data.map((workingDayItem) => ({
        ...workingDayItem,
        workingDays: getDayTimeTableArray(workingDayItem.workingDays)
            .filter((workingDay) => workingDay.value !== weekDays.allDays)
            .map((workingDay) => workingDay.value),
    }));

// Подготовка данных по новым тарифам для сохранения
export const preparedTariffData = (data: Tariff, isTimeData?: boolean) => {
    let workingDaysForDays = [
        weekDays.monday,
        weekDays.tuesday,
        weekDays.wednesday,
        weekDays.thursday,
        weekDays.friday,
        weekDays.saturday,
        weekDays.sunday,
    ];
    let newTariffs: Tariff[] = [];
    const fillTariffTime = (tariffData: Tariff, times: string[]) => {
        let data: Tariff[] = [];
        if (isTimeData) {
            times.forEach((timeItem) => {
                if (timeItem) {
                    const workTimeArray = timeItem.split(" - ");
                    const fromWorkTime: string = workTimeArray[0];
                    const toWorkTime: string = workTimeArray[1];
                    data.push({
                        ...tariffData,
                        openedSince: fromWorkTime + ":00",
                        openedUntil: toWorkTime + ":00",
                        id: tariffData.id || (uuid.v4() as string),
                    });
                }
                return timeItem;
            });
        } else {
            data.push({
                ...tariffData,
                id: tariffData.id || (uuid.v4() as string),
            });
        }
        return data;
    };

    data.workingTime.forEach((item) => {
        let newTariff: Tariff = {
            ...data,
        };
        const workingDays = getDateControlArray(item.workingDays);
        if (getIsWeekDays(workingDays)) {
            newTariff.workingDays = workingDays.map((val) => val.toUpperCase());
            newTariff.activeSince = moment(new Date()).format(ISO_DATE_FORMAT);
            newTariff.activeUntil = DATE_2061;
            newTariffs = [...newTariffs, ...fillTariffTime(newTariff, item.time)];
        }
        if (getIsDays(workingDays)) {
            let timetableIsDays: Tariff[] = [];
            newTariff.workingDays = workingDaysForDays.map((val) => val.toUpperCase());
            workingDays.forEach((day) => {
                newTariff.activeSince = day;
                newTariff.activeUntil = day;
                timetableIsDays = [...timetableIsDays, ...fillTariffTime(newTariff, item.time)];
                return day;
            });
            newTariffs = [...newTariffs, ...timetableIsDays];
        }
        if (getIsRangeDays(workingDays)) {
            newTariff.workingDays = workingDaysForDays.map((val) => val.toUpperCase());
            //@ts-ignore
            newTariff.activeSince = item.workingDays[0].from;
            //@ts-ignore
            newTariff.activeUntil = item.workingDays[0].to;
            newTariffs = [...newTariffs, ...fillTariffTime(newTariff, item.time)];
        }
        return item;
    });
    return newTariffs.map((item, indx) => ({ ...item, isBasic: indx === 0 ? item.isBasic : false })); // если сеансов несколько - оставляем isBasic только у первого тарифа
};

// Возвращает выбранное значение для отображения в компоненте date-control
export const getTariffItemDays = (
    workingDays: string[],
    dateFrom: string,
    dateTo: string,
    withPronoun?: boolean,
    withTime?: boolean
) => {
    let displayingValue = "";
    dateFrom = moment(new Date(dateFrom)).format(ISO_DATE_FORMAT);
    dateTo = moment(new Date(dateTo)).format(ISO_DATE_FORMAT);
    if (dateTo === DATE_2061) {
        displayingValue = workingDays.reduce((prev: string, current: string) => {
            const weekDay = dayOfTheWeekConversion(current.toLowerCase(), prev, withPronoun);
            if (weekDay) {
                if (prev.length > 0) prev += ", ";
                return prev + weekDay;
            }
            return prev;
        }, displayingValue);
    } else {
        if (dateFrom === dateTo) {
            displayingValue = moment(dateFrom, ISO_DATE_FORMAT).format("DD MMM");
        } else {
            const fromDay = moment(dateFrom, ISO_DATE_FORMAT).format("DD MMM");
            const toDay = moment(dateTo, ISO_DATE_FORMAT).format("DD MMM");
            displayingValue = fromDay + " - " + toDay;
        }
    }
    return displayingValue;
};
