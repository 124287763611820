import React, { useMemo } from "react";
import { HotelData } from "../types";
import { noop } from "lodash";
import { DndUploadPhotos } from "@russpass/partner-front-ui";
import { getImageById, uploadPhotoToCms } from "../../../../../api/files";

type Props = {
    data: HotelData;
};

const EditFormPhoto = ({ data }: Props) => {
    const items = useMemo(
        () => data.imageExplorePreview?.map((item) => ({ image: item })) || [],
        [data.imageExplorePreview]
    );
    return (
        <DndUploadPhotos
            items={items}
            onChange={noop}
            title="Добавить фото отеля"
            disabled
            getImageById={getImageById}
            uploadPhoto={uploadPhotoToCms}
            onFileTooLarge={noop}
            onPhotosLimit={noop}
            onError={noop}
        />
    );
};

export default EditFormPhoto;
