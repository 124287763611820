import { BaseFieldProps } from "../../../../../../types/fields";
import { useRentHousesDictionary } from "../../../../../../api/hooks/rentHouses";
import React, { useEffect, useMemo } from "react";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { StatusRequestEnum, SelectInputField } from "@russpass/partner-front-ui";
import { UNEXPECTED_ERROR } from "../../../../../../constants/errors";
import { useFormikContext } from "formik";

const SelectObjectTypeField = ({ name, label, placeholder, disabled }: BaseFieldProps) => {
    const { data, error: dictionaryError } = useRentHousesDictionary({
        dictionary: "housing_types",
        pageSize: 999,
        sort: "dictionary_data.title",
        useForRenthouses: true,
    });

    useEffect(() => {
        if (!dictionaryError) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [dictionaryError]);

    const options = useMemo(
        () => data?.data.items.map((el) => ({ value: el.id, label: el.dictionary_data?.title || "" })) || [],
        [data]
    );

    const { getFieldProps, setFieldValue } = useFormikContext();

    const value = getFieldProps(name).value;

    const selectedOption = useMemo(
        () => options.find((el) => el.value === value) || { value: "", label: "" },
        [options, value]
    );

    return (
        <SelectInputField
            name={name}
            label={label}
            placeholder={placeholder}
            value={selectedOption}
            options={options}
            disabled={disabled}
            isChecked
            handleChange={(option) => setFieldValue(name, option.value)}
        />
    );
};

export default SelectObjectTypeField;
