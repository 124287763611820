import { partnerApiServices, russpassApiServices } from "../../constants/api";
import {
    HotelBankDetails,
    HotelDataForSaveRequest,
    HotelDataUpdating,
    HotelInfoDataForEditRequest,
} from "../../containers/hotels/add-hotel-page/new-form/types/hotel-info";
import { CreateRelatedHotelsResponse, RelatedHotelsCreatedData } from "../../containers/hotels/hotel-new/types";
import { IHotelValueForCRM } from "../../types/hotels";
import fetchRequest from "../manager";
import { CheckHotelExistRequestData, CheckHotelExistResponse } from "../../containers/hotels/all/types";

const partnershipServicePath = russpassApiServices.partnership;
const hotelsPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.hotels}`;
const crmPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.crm}`;
const integrationPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.integration}`;

// Получение данных об отеле по идентификатору из LKP
export const getHotelData = async (hotelId: string): Promise<HotelDataUpdating> => {
    const content = await fetchRequest.get(`${hotelsPartnerServiceUrl}/${hotelId}`, null, {
        isProtected: true,
    });
    return content;
};

export const getHotelInfo = async (hotelId: string, type: string = "new") => {
    const content = await fetchRequest.get(
        `${hotelsPartnerServiceUrl}/exists?partnerSystemId=${hotelId}&type=${type}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getIntegrationTravelLineHotelInfo = async (travellineId: string) => {
    const content = await fetchRequest.get(`${hotelsPartnerServiceUrl}/integrationTravelline/${travellineId}`, null, {
        isProtected: true,
    });
    return content;
};

export const createRelatedHotels = async (
    applicationId: string,
    hotelData: RelatedHotelsCreatedData
): Promise<CreateRelatedHotelsResponse[]> => {
    const response = await fetchRequest.post(
        `${integrationPartnerServiceUrl}/hotels?applicationId=${applicationId}`,
        [hotelData],
        {
            isProtected: true,
        }
    );

    return response;
};

export const getHotelsAll = async () => {
    const data = await fetchRequest.get(hotelsPartnerServiceUrl, null, {
        isProtected: true,
    });
    return data;
};

export const getHotelCmsInfo = async (hotelId: string) => {
    const content = await fetchRequest.get(`${hotelsPartnerServiceUrl}/${hotelId}`, null, {
        isProtected: true,
    });
    return content;
};

export const editHotel = async (hotelId: string, hotelData: HotelInfoDataForEditRequest) => {
    const content = await fetchRequest.patch(`${hotelsPartnerServiceUrl}/${hotelId}`, hotelData, {
        isProtected: true,
    });
    return content;
};

export const sendForApprovalHotel = async (hotelId: string) => {
    const content = await fetchRequest.patch(`${hotelsPartnerServiceUrl}/sendForApproval/${hotelId}`, null, {
        isProtected: true,
    });
    return content;
};

export const createHotel = async (hotelData: any) => {
    const content = await fetchRequest.post(`${hotelsPartnerServiceUrl}/`, hotelData, {
        isProtected: true,
    });
    return content;
};

export const sendHotelInCRM = async (values: IHotelValueForCRM) => {
    const content = await fetchRequest.post(`${crmPartnerServiceUrl}/hotels`, values, {
        isProtected: true,
    });

    return content;
};

export const sendHotelToMSBilling = async (hotelId: string, data: { title: string; partnerSystemType: string }) => {
    const content = await fetchRequest.post(`${integrationPartnerServiceUrl}/hotels/${hotelId}/item`, data, {
        isProtected: true,
    });

    return content;
};

export const checkHotelExist = async (data: CheckHotelExistRequestData): Promise<CheckHotelExistResponse> => {
    const content = await fetchRequest.post(`${integrationPartnerServiceUrl}/hotels/checkExist`, data, {
        isProtected: true,
    });

    return content;
};

// Создание в МС hotels отеля который уже есть в ЛКП
export const saveExistingHotel = async (hotelId: string, data: HotelDataForSaveRequest) => {
    const content = await fetchRequest.post(`${integrationPartnerServiceUrl}/hotels/${hotelId}/save`, data, {
        isProtected: true,
    });

    return content;
};

// Получение банковских реквизитов отеля
export const getHotelBankDetails = async (hotelId: string, systemType: string): Promise<HotelBankDetails> => {
    const content = await fetchRequest.get(
        `${integrationPartnerServiceUrl}/hotels/${hotelId}/bankDetails?systemType=${systemType}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

// Переключить отель с TRAVEL_LINE на NEW_TRAVEL_LINE
export const switchNewTravelline = async (hotelId: string): Promise<any> => {
    const content = await fetchRequest.post(
        `${integrationPartnerServiceUrl}/hotels/switchNewTravelline/${hotelId}`,
        null,
        {
            isProtected: true,
        }
    );
    return content;
};

export const getPartnerHotel = async (hotelId: string) => {
    return await fetchRequest.get(`${partnerApiServices.hotels}/partner-hotels/${hotelId}`);
};
