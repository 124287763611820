import classes from "./UploadFilesRenderer.module.sass";
import { UploadFilesRendererProps } from "../types";
import CSS from "csstype";

type Props = UploadFilesRendererProps & {
    styles?: CSS.Properties;
};

const UploadFilesRenderer = ({ preview, input, styles }: Props) => (
    <div className={classes.Container} style={styles}>
        {input}
        {preview}
    </div>
);

export default UploadFilesRenderer;
