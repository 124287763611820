import { Issue, IssueRequest_v2 } from "../../../../types/jira-sd";
import { russpassApiUrl as apiUrl, russpassApiServices as apiService } from "../../../../constants/api";
import { jiraSD } from "../../../../constants/jiraSD";
import { prepareJiraDescription, prepareEmailDescription } from "./helper";
import { PreparedData } from "./defaultForm/types/types";
import { apartmentsContactFormTopicType, contactFormTopicType_v3 } from "./dictionary";
import * as jiraSDApi from "../../../../api/jira-sd";
import * as attachApi from "../../../../api/attach";
import * as emailNotifyApi from "../../../../api/email-notify";
import { useState, useCallback } from "react";
import { isNotFound } from "../../../../utils/errors";
import { getJiraUserToken } from "../../../../utils/jira-sd";
import { langValue } from "../../../../constants/language";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import useUserInfo from "../../../../hooks/useUserInfo";
import checkIsRentHousesSupportPreparedData from "./apartment/utils/checkIsRentHousesSupportPreparedData";
import rentHousesSupportSubmit from "./apartment/utils/rentHousesSupportSubmit";

const useContactingSupport = () => {
    const [status, setStatus] = useState<StatusRequestEnum | null>(null);

    const [isViewStatus, setIsViewStatus] = useState<boolean>(false);

    const [formData, setFormData] = useState<PreparedData>({} as PreparedData);
    const [isLoading, setIsLoading] = useState(false);

    const { userFullInfo } = useUserInfo();

    const authorized = !!userFullInfo?.email;

    const [email, setEmail] = useState<string>(userFullInfo?.email || "");

    const createJiraTask = async (data: PreparedData) => {
        const username = data.email;
        let userExists = false;
        // Ищем пользователя по почте, если такого не находим, то создаем нового
        try {
            const user = await jiraSDApi.getUser(username);
            userExists = true;
            // Имя с пробелами недопустимо
            if (data.name.trim() && user.displayName !== data.name) {
                // Намеренно не ждем с каким результатом произойдет обновление
                jiraSDApi.updateUserName(data.email, data.name);
            }
        } catch (err) {
            if (isNotFound(err)) {
                await jiraSDApi.createCustomer({
                    email: username,
                    fullName: data.name,
                });
                userExists = true;
            } else {
                console.error(err);
            }
        }
        if (!userExists) {
            throw new Error("User not found");
        }

        const description = prepareJiraDescription(data.details);

        // значения для поля "Язык запроса", по ру значение равно "10210"
        const request: IssueRequest_v2 = {
            serviceDeskId: jiraSD.id,
            requestTypeId: data?.topic,
            requestFieldValues: {
                summary: contactFormTopicType_v3(data?.topic),
                description,
                customfield_10202: { id: langValue },
            },
        };

        const issue = await jiraSDApi.createIssue_v2(request);
        await jiraSDApi.setIssueReporter(issue.issueId, username);

        if (data.files.length) {
            const userToken = await getJiraUserToken(username);
            const tempFiles = await jiraSDApi.attachTempFiles(data.files, userToken);

            if (tempFiles.length) {
                await jiraSDApi.attachFilesToIssue(tempFiles, issue.issueId, userToken);
            }
        }

        return issue;
    };

    const saveFiles = async (files: File[]) => {
        return await Promise.all(
            files.map(async (file) => {
                const addedFile = await attachApi.saveFile(file);
                if (addedFile) {
                    return {
                        name: file.name,
                        url: `${apiUrl}${apiService.attach}/file?file=${addedFile.uri}`,
                    };
                }
                return null;
            })
        );
    };

    const sendSupportMessage = async (jiraTask: Issue, data: PreparedData) => {
        let attachedHtml = "";
        if (data.files.length) {
            const filesData = await saveFiles(data.files);
            attachedHtml += "Вложения:";
            filesData.forEach((file) => {
                if (file) {
                    attachedHtml += `<br /><a href="${file.url}">${file.name}</a>`;
                }
            });
        }
        const topic = apartmentsContactFormTopicType(data?.topic);

        const details = prepareEmailDescription(data.details);

        let message = `<p>${details}</p><p>Тема обращения:<br />${topic}</p>`;
        if (attachedHtml) {
            message += `<p>${attachedHtml}</p>`;
        }
        await emailNotifyApi.sendSupport(
            data.email,
            {
                appealId: jiraTask.issueKey,
                theme: topic,
            },
            message
        );
    };

    const submitForm = useCallback(
        async (values: PreparedData) => {
            setEmail(values.email);
            if (checkIsRentHousesSupportPreparedData(values)) {
                setIsLoading(true);
                try {
                    await rentHousesSupportSubmit(values);
                    setStatus(StatusRequestEnum.Success);
                    setIsViewStatus(true);
                } catch (e) {
                    setStatus(StatusRequestEnum.Error);
                    setIsViewStatus(true);
                    console.error(e);
                }
                setIsLoading(false);
                return;
            }

            try {
                setIsLoading(true);
                const jiraTask = await createJiraTask(values);
                sendSupportMessage(jiraTask, values).catch((err) => {
                    console.error(err);
                });
                setStatus(StatusRequestEnum.Success);
                setIsViewStatus(true);
            } catch (err) {
                if (!formData.email) {
                    setFormData(values);
                }
                setStatus(StatusRequestEnum.Error);
                setIsViewStatus(true);
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [authorized, createJiraTask]
    );

    const handleRepeatSubmit = () => {
        submitForm(formData);
    };

    const handleToggleStatus = () => {
        setIsViewStatus(false);
    };

    return {
        submitForm,
        profile: userFullInfo,
        isLoading,
        status,
        email,
        handleToggleStatus,
        handleRepeatSubmit,
        isViewStatus,
    };
};

export default useContactingSupport;
