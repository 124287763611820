import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Loader, ModalWindowRight, InputField, RadioGroupField, StatusRequestCard } from "@russpass/partner-front-ui";
import { channelHotelNames, channelHotels } from "./constants";
import classes from "./styles.module.sass";
import useHotelNew from "./useHotelNew";
import { validationForm } from "./validation-shema";
import InfoHotel from "./components/hotel-bank-details";
import { HotelFormData } from "./types";
import { ChannelManagerCodeEnum } from "../../../enums/channel-manager-code";
import { sendEvent } from "../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../product_analytics/constants";
import { StatusRequestCardSubtitleError } from "../../../constants/errors";

const HotelComponent = () => {
    const {
        valuesFormState,
        submitOffer,
        isLoadingInitialValues,
        isLoading,
        onCloseModal,
        status,
        setStatus,
        handleValidateBankAccount,
        bankErrors,
    } = useHotelNew();

    const handleSendEvent = useCallback(() => {
        sendEvent(AnalyticEvent.add_object_error_page_close);
    }, []);

    return (
        <Formik
            initialValues={valuesFormState}
            validationSchema={validationForm}
            enableReinitialize
            validateOnMount
            onSubmit={submitOffer}
        >
            {({ values, submitForm, setFieldValue }: FormikProps<HotelFormData>) => {
                const channelName =
                    values.systemType === ChannelManagerCodeEnum.Bnovo
                        ? channelHotelNames.bnovo
                        : channelHotelNames.travelLine;

                const handleCloseModal = () => onCloseModal(values);
                return (
                    <>
                        <ModalWindowRight
                            title={!status ? "Добавление объекта" : ""}
                            isOpened={true}
                            onClose={handleCloseModal}
                            isLoadingWithContent={isLoading}
                            isLoadingWithoutContent={isLoadingInitialValues}
                            isHideCloseButton={!!status}
                            ComponentFooter={
                                !isLoadingInitialValues &&
                                !status && (
                                    <button
                                        className="button button--primary"
                                        onClick={() => {
                                            if (bankErrors.checkingAccount || bankErrors.corrAccount) return;
                                            void submitForm();
                                        }}
                                    >
                                        Добавить
                                    </button>
                                )
                            }
                        >
                            {isLoadingInitialValues ? (
                                <div className={classes.loaderWrap}>
                                    <Loader />
                                </div>
                            ) : (
                                <>
                                    {!status ? (
                                        <div className="content-wrapper">
                                            <div className="content-wrapper__form">
                                                <RadioGroupField
                                                    name="systemType"
                                                    options={channelHotels}
                                                    label="Channel manager"
                                                    valueField="code"
                                                    labelOptionField="name"
                                                    onChange={(value) => setFieldValue("systemType", value.code)}
                                                    value={
                                                        channelHotels.find((v) => v.code === values.systemType) || {
                                                            name: "",
                                                        }
                                                    }
                                                />
                                                <InputField
                                                    noHover
                                                    placeholder="0000"
                                                    name="id"
                                                    label={`ID в системе ${channelName}`}
                                                    hint={`ID объекта указан в личном кабинете ${channelName}`}
                                                    maxLength={32}
                                                />
                                                <InputField
                                                    noHover
                                                    name="title"
                                                    label="Название объекта"
                                                    placeholder="До 100 символов"
                                                    maxLength={100}
                                                    hint="Эта информация отразится на странице вашего объекта на сайте RUSSPASS"
                                                />
                                                <InfoHotel
                                                    onBankValidate={(fieldName, fieldValue) =>
                                                        handleValidateBankAccount(
                                                            {
                                                                ...values.bankDetails,
                                                                [fieldName]: fieldValue,
                                                            },
                                                            fieldName
                                                        )
                                                    }
                                                    bankErrors={bankErrors}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <StatusRequestCard
                                            title={{
                                                error: (
                                                    <>
                                                        {"Не удалось добавить объект "}
                                                        <br />
                                                        {`\u00ab${values.title}\u00bb`}
                                                    </>
                                                ),
                                            }}
                                            subtitle={{
                                                error: StatusRequestCardSubtitleError,
                                            }}
                                            status={status}
                                            buttonTitle={{ repeat: "Повторить" }}
                                            handleClose={() => setStatus(null)}
                                            handleRepeat={() => {
                                                sendEvent(AnalyticEvent.repeat_add_object);
                                                submitForm?.();
                                            }}
                                            handleBeforeClose={handleSendEvent}
                                        />
                                    )}
                                </>
                            )}
                        </ModalWindowRight>
                    </>
                );
            }}
        </Formik>
    );
};

export default HotelComponent;
