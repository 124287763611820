import moment from "moment";
import { useCallback, useMemo, useRef, useState } from "react";
import { ISO_DATE_FORMAT } from "../../../../constants/dates-format";
import { isEqualArrays } from "../../../../utils/common";
import { PlaceTimetableButtonKeys } from "../constants";
import { DateControlProps, DateControlValue, Matcher, Range } from "../types";
import {
    getDisabledDays,
    getDisabledOptions,
    getDisplayingValue,
    getIsDays,
    getIsDaysExisting,
    getIsRangeDays,
    getIsRangeDaysExisting,
    getIsWeekDays,
    getIsWeekDaysExisting,
    sortDays,
    sortWeekDays,
} from "../utils";

const useDateControl = (props: DateControlProps) => {
    const [selectedValue, setSelectedValue] = useState<DateControlValue>([]);

    const MenuRef = useRef<any>(null);

    const WeekDaysSelectRef = useRef<any>(null);
    const RangeDaysSelectRef = useRef<any>(null);
    const DaysSelectRef = useRef<any>(null);

    const weekDaysValueRef = useRef<DateControlValue>([]);
    const rangeDaysSelectValueRef = useRef<Range>({} as Range);
    const daysSelectValueRef = useRef<DateControlValue>([]);

    const isChangedValue = useMemo(() => !isEqualArrays(props.value, selectedValue), [props.value, selectedValue]);

    const isDaysExisting = useMemo(() => getIsDaysExisting(props.value), [props.value]);
    const isRangeDaysExisting = useMemo(() => getIsRangeDaysExisting(props.value), [props.value]);
    const isWeekDaysExisting = useMemo(() => getIsWeekDaysExisting(props.value), [props.value]);

    const displayingValue = useMemo(
        () => getDisplayingValue(props.value, props.isRenderValueWithPronoun),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.value]
    );

    const disabledOptions: (string | Matcher)[] = useMemo(
        () =>
            props.isWorkingMode
                ? props.disabledOptions || []
                : getDisabledOptions(selectedValue, props.disabledOptions),
        [props.disabledOptions, props.isWorkingMode, selectedValue]
    );

    const disabledDays: Matcher[] = useMemo(() => getDisabledDays(disabledOptions), [disabledOptions]);

    const weekDaysValue: DateControlValue = useMemo(
        () => (getIsWeekDays(props.value) ? props.value : []),
        [props.value]
    );

    const daysValue: DateControlValue = useMemo(() => (getIsDays(props.value) ? props.value : []), [props.value]);

    const rangeDaysValue: Range[] = useMemo(
        () => (getIsRangeDays(props.value) ? (props.value as Range[]) : []),
        [props.value]
    );

    const handleSelect = useCallback((values: DateControlValue) => {
        setSelectedValue(values);
    }, []);

    const onCloseMenu = () => {
        MenuRef?.current?.closeMenu();
    };

    const handleSave = useCallback(
        (activeTab: PlaceTimetableButtonKeys, value: DateControlValue) => {
            if (getIsWeekDays(value)) {
                props.handlerChange(value.sort(sortWeekDays), activeTab);
            } else if (getIsDays(value)) {
                props.handlerChange(value.sort(sortDays), activeTab);
            } else if (getIsRangeDays(value)) {
                props.handlerChange(
                    value.map((range) => {
                        if (typeof range === "string") return { from: undefined, to: undefined };

                        return {
                            from: moment(range.from).format(ISO_DATE_FORMAT),
                            to: moment(range.to).format(ISO_DATE_FORMAT),
                        };
                    }),
                    activeTab
                );
            } else {
                props.handlerChange(value, activeTab);
            }
            onCloseMenu();
        },
        [props]
    );

    const handleReset = useCallback(() => {
        switch (true) {
            case Boolean(daysSelectValueRef.current.length):
                setSelectedValue(daysSelectValueRef.current);
                break;
            case rangeDaysSelectValueRef.current.from !== undefined && rangeDaysSelectValueRef.current.to !== undefined:
                setSelectedValue([rangeDaysSelectValueRef.current]);
                break;
            case Boolean(weekDaysValueRef.current.length):
                setSelectedValue(weekDaysValueRef.current);
                break;
            default:
                setSelectedValue([]);
                break;
        }

        DaysSelectRef?.current?.clearSelectedValue();
        RangeDaysSelectRef?.current?.clearSelectedValue();
        WeekDaysSelectRef?.current?.clearSelectedValue();
    }, []);

    return {
        selectedValue,
        isChangedValue,
        daysValue,
        rangeDaysValue,
        weekDaysValue,
        handleSelect,
        handleSave,
        handleReset,
        MenuRef,
        displayingValue,
        disabledOptions,
        disabledDays,
        isDaysExisting,
        isRangeDaysExisting,
        isWeekDaysExisting,
        setSelectedValue,
        WeekDaysSelectRef,
        DaysSelectRef,
        RangeDaysSelectRef,
    };
};

export default useDateControl;
