import { EmptyList, Paginate } from "@russpass/partner-front-ui";
import styles from "./styles.module.sass";
import NotificationItem from "../item";
import { useEditNotificationsContext } from "../../../edit/context/useEditNotificationsContext";
import { useReceivedNotificationsContext } from "../../context/useReceivedNotificationsContext";
import { useCallback } from "react";

const NotificationsList = () => {
    const { items, page, pagesCount, setPage } = useReceivedNotificationsContext();

    const addNotification = useEditNotificationsContext().open;
    const handlePageChange = useCallback((page) => setPage(page + 1), [setPage]);

    if (!items.length && page === 1)
        return (
            <EmptyList
                subtitle="Здесь будут уведомления и сообщения от модераторов"
                description={
                    <>
                        Подключите уведомления, если хотите получать оповещения <br />о снижении интереса туристов к
                        вашим объектам
                    </>
                }
                buttonTitle="Подключить уведомления"
                onClick={() => addNotification()}
            />
        );

    return (
        <div className={styles.wrapper}>
            <div className={styles.list} data-qa-name="data-list">
                {items.map((item) => (
                    <NotificationItem key={item.id} item={item} />
                ))}
            </div>
            {pagesCount > 1 && <Paginate page={page - 1} totalPages={pagesCount} onPageChange={handlePageChange} />}
        </div>
    );
};

export default NotificationsList;
