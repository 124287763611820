import { Switch } from "@russpass/partner-front-ui";
import cl from "./styles.module.sass";
import cn from "classnames";

type Props = {
    onToggle: () => void;
    value: boolean;
};

export const OnlineSaleSwitch = ({ onToggle, value }: Props) => {
    return (
        <div className={cl.serviceSwitch}>
            <Switch name="service-switch" onChange={onToggle} value={value} />
            <label htmlFor="service-switch">
                <div className="body">Онлайн-продажа на RUSSPASS</div>
                <div className={cn(cl.desc, "caption")}>Пользователи сайта смогут купить билеты</div>
            </label>
        </div>
    );
};
