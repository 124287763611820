import { cloneDeep, debounce, isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { createRelatedHotels, sendHotelToMSBilling } from "../../../api/hotels/hotels";

import { getUserContactInfo } from "../../../api/profile";
import { COMMON_ERROR, POST_DATA_ERROR_SUPPORT } from "../../../constants/errors";
import routes from "../../../constants/routes";
import history from "../../../history";
import eventDispatcher from "../../../lib/event-dispatcher";
import { IBank, IBankField } from "../../../types/profile";
import { UserContractInfo } from "../../../types/user";
import { doubleErrorMessage, initialValueForm, successSavingMessage, textModalAlert } from "./constants";
import { HotelFormData, IUserDataForRequest, RelatedHotelsCreatedData } from "./types";
import { sendEvent } from "../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../product_analytics/constants";
import { useModalAlertContext, StatusRequestEnum } from "@russpass/partner-front-ui";
import { getAddObjectEventParams } from "./utils";
import { validateBankAccount } from "../../documents/offer/company-info/utils";
import useUserInfo from "../../../hooks/useUserInfo";

const useHotelNew = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [isLoadingInitialValues, setIsLoadingInitialValues] = useState(true);
    const [valuesFormState, setValuesFormState] = useState<HotelFormData>(cloneDeep(initialValueForm));

    const dataForSend = useRef<IUserDataForRequest>({} as IUserDataForRequest);

    const { userFullInfo } = useUserInfo();

    const [status, setStatus] = useState<StatusRequestEnum | null>(null);
    const [bankErrors, setBankErrors] = useState<{ corrAccount?: string; checkingAccount?: string }>({});

    const { openModalAlert } = useModalAlertContext();

    const loadUserContractInfo = useCallback(async () => {
        try {
            if (userFullInfo?.applicationId) {
                const userContractInfo: UserContractInfo = await getUserContactInfo(userFullInfo?.applicationId);
                return userContractInfo;
            }
        } catch (error) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: COMMON_ERROR,
            });
        }
    }, [userFullInfo]);

    const initFormValue = useCallback(async () => {
        const userContractInfo = await loadUserContractInfo();
        if (userContractInfo && userFullInfo) {
            const bankInfo: IBank = userContractInfo.bank;

            dataForSend.current.vat = userContractInfo.ndsRate;
            dataForSend.current.applicationId = userFullInfo.applicationId;

            if (bankInfo) {
                setValuesFormState((prevState) => ({
                    ...prevState,
                    bankDetails: {
                        ...bankInfo,
                    },
                }));
                setIsLoadingInitialValues(false);
            } else {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: COMMON_ERROR,
                });
            }
        }
    }, [userFullInfo, loadUserContractInfo]);

    useEffect(() => {
        initFormValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValidateBankAccount = useCallback(
        debounce(async (bank: IBank, fieldType: IBankField) => {
            if (userFullInfo && bank) {
                setBankErrors({ ...bankErrors, [fieldType]: undefined });
                const errorData = await validateBankAccount({ partnerId: userFullInfo.applicationId, bank, fieldType });
                if (errorData) {
                    setBankErrors(errorData);
                }
            }
        }, 300),
        [userFullInfo]
    );

    const submitOffer = useCallback(
        async (formValues: HotelFormData) => {
            setIsLoading(true);
            sendEvent(AnalyticEvent.add_object_button, getAddObjectEventParams(formValues));
            if (dataForSend.current.applicationId) {
                const valuesForRequest: RelatedHotelsCreatedData = {
                    ...formValues,
                    vat: dataForSend.current.vat,
                };
                createRelatedHotels(dataForSend.current.applicationId, valuesForRequest)
                    .then(async (data) => {
                        await sendHotelToMSBilling(data[0]?.id, {
                            title: valuesForRequest.title,
                            partnerSystemType: data[0]?.partnerSystemType,
                        });

                        eventDispatcher.setNotification({
                            status: StatusRequestEnum.Success,
                            isAlwaysView: true,
                            body: successSavingMessage,
                        });
                        history.push(routes.hotelsView.replace(":hotelId", data[0].id));
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        if (err.data?.status === 409) {
                            eventDispatcher.setNotification({
                                status: StatusRequestEnum.Error,
                                body: doubleErrorMessage,
                            });
                            sendEvent(AnalyticEvent.add_object_error_page);
                        } else {
                            eventDispatcher.setNotification({
                                status: StatusRequestEnum.Error,
                                body: POST_DATA_ERROR_SUPPORT,
                            });
                        }
                        setStatus(StatusRequestEnum.Error);
                    });
            }
        },
        [dataForSend]
    );

    const onCloseModal = useCallback(
        (valuesFormModified) => {
            if (!isEqual(valuesFormModified, valuesFormState)) {
                openModalAlert(
                    {
                        title: textModalAlert.notAdded,
                        description: textModalAlert.warning,
                        OKButton: textModalAlert.out,
                        cancelButton: textModalAlert.continue,
                    },
                    () => history.push(routes.hotelsAll)
                );
                return;
            }
            history.push(routes.hotelsAll);
        },
        [openModalAlert, valuesFormState]
    );

    return {
        valuesFormState,
        submitOffer,
        isLoadingInitialValues,
        isLoading,
        onCloseModal,
        status,
        setStatus,
        handleValidateBankAccount,
        bankErrors,
    };
};

export default useHotelNew;
