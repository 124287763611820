import { createContext } from "react";
import { ReceivedNotification } from "../hooks/useReceivedNotifications";

type ReceivedNotificationsContextType = {
    items: ReceivedNotification[];
    isLoading: boolean;
    isFirstLoading: boolean;
    refresh: () => void;
    page: number;
    pagesCount: number;
    setPage: (page: number) => void;
};

export const ReceivedNotificationsContext = createContext<ReceivedNotificationsContextType>({
    items: [],
    isLoading: true,
    isFirstLoading: true,
    refresh: () => undefined,
    page: 1,
    pagesCount: 0,
    setPage: () => undefined,
});
