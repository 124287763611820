import { ReactComponent as CircleIcon } from "../../../../assets/images/icons/circle.svg";
import { DialogBookingStatus, DialogueModel } from "../types/DialogueModel";
import { DialogueMessageStatus } from "../constants";
import { formatDate } from "../../../../utils/text-util";
import { DOTTED_MID_DATE_FORMAT } from "../../../../constants/dates-format";
import { getMessageText } from "../utils/getMessageText";
import { DataGridColumn } from "@russpass/partner-front-ui/build/components/DataGrid/types";

const getDataGridDialoguesColumns = (): DataGridColumn[] => [
    {
        value: (item) => {
            const text = (item as DialogueModel).lastMessage?.message
                ? getMessageText((item as DialogueModel).lastMessage?.message)
                : (item as DialogueModel).lastMessage?.files?.[0].filename;
            return text || "";
        },
        label: (item) =>
            `${(item as DialogueModel).user?.firstName || ""} ${(item as DialogueModel).user?.lastName || ""}`,
        icon: (item) =>
            (item as DialogueModel).lastMessage?.partnerStatus === DialogueMessageStatus.NEW ? (
                <CircleIcon width={12} height={12} fill="#FFCF08" />
            ) : null,
        customColumnClassName: (item) =>
            !(item as DialogueModel).lastMessage?.message && (item as DialogueModel).lastMessage?.files?.[0]
                ? "message-attachment bgfileico"
                : "",
        width: "177px",
    },
    {
        value: (item) => (item as DialogueModel).object?.title,
        width: `${798 - 177 - 220 - 90 - 24 * 4}px`,
        label: "Объект",
    },
    {
        value: (item) =>
            ({
                [DialogBookingStatus.PENDING]: "Заявка на бронь",
                [DialogBookingStatus.ACTIVE]: "Бронь подтверждена",
                [DialogBookingStatus.REJECTED]: "Заявка отклонена",
                [DialogBookingStatus.REJECTED_PAID]: "Бронь отклонена",
            }[item.booking?.status as string] || "Вопрос по объекту"),
        label: "Статус",
        width: "220px",
    },
    {
        value: (item) =>
            formatDate(
                (item as DialogueModel).lastMessage?.createdAt || (item as DialogueModel).createdAt,
                DOTTED_MID_DATE_FORMAT
            ),
        label: "Отправлено",
        width: "90px",
    },
];

export default getDataGridDialoguesColumns;
