import useQueryState from "../../../../../hooks/useQueryState";
import { useCallback, useMemo } from "react";

const KEY = "support";

const useSupportChatModal = () => {
    const [state, setState] = useQueryState(KEY);

    const isOpen = useMemo(() => state === "true", [state]);
    const open = useCallback(() => setState("true"), [setState]);
    const close = useCallback(() => setState(null), [setState]);

    return useMemo(() => ({ isOpen, open, close }), [close, isOpen, open]);
};

export default useSupportChatModal;
