import history from "../../../../../history";
import { navbarHotelEditFormNames, navbarHotelEditRatings } from "../constants";
import EditFormHotel from "./edit-form-hotel";
import EditFormPhoto from "./edit-form-photo";
import { CommonLayout } from "@russpass/partner-front-ui";
import React, { useMemo } from "react";
import { HotelData } from "../types";
import useHotelEditForm from "../useHotelEditForm";
import { HotelServiceData } from "../../../components/hotel-services/types";
import EditFormComment from "./edit-form-comment";
import routes from "../../../../../constants/routes";
import { useLocation } from "react-router-dom";
import { Ratings } from "../../../../services/components/ratings/ratings";

type Props = {
    hotelData: HotelData;
    services: HotelServiceData;
    onChangeHotelData: (values: any) => void;
    onSubmit: () => void;
    isLoadingForm: boolean;
};

const EditFormLayout = ({ hotelData, services, onChangeHotelData, isLoadingForm, onSubmit }: Props) => {
    const location = useLocation();

    const { activeTabName, changeTab, isSubmittingForm, navbar } = useHotelEditForm({
        hotelData,
        onSubmit,
    });

    const localNavbar = useMemo(() => {
        let _navbar = navbar;

        if (hotelData?.cmsId) {
            _navbar = _navbar.concat(navbarHotelEditRatings);
        }

        return _navbar;
    }, [navbar, hotelData]);

    const backButton = useMemo(
        () => ({
            title: "Выйти",
            onClick: () => {
                history.goBack();
                history.push({
                    pathname: routes.hotelsAll,
                    state: location?.state,
                });
            },
        }),
        [location?.state]
    );

    return (
        <CommonLayout
            pageTitle={hotelData.title}
            backButton={backButton}
            activeTabName={activeTabName}
            handlerChangeActiveTab={changeTab}
            isLoadingWithoutContent={isLoadingForm}
            navbar={localNavbar}
            fullHeight={activeTabName === navbarHotelEditFormNames.ratings}
            isHideControlPanel
        >
            <div data-name={navbarHotelEditFormNames.hotel}>
                <EditFormHotel
                    data={hotelData}
                    services={services}
                    onChange={onChangeHotelData}
                    onSubmit={changeTab}
                    isShowErrors={isSubmittingForm}
                    disabled
                />
            </div>
            <div data-name={navbarHotelEditFormNames.photos}>
                <EditFormPhoto data={hotelData} />
            </div>
            <div data-name={navbarHotelEditFormNames.comment}>
                <EditFormComment data={hotelData} />
            </div>
            <div data-name={navbarHotelEditFormNames.ratings}>
                <Ratings id={hotelData.cmsId} />
            </div>
        </CommonLayout>
    );
};

export default EditFormLayout;
