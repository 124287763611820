import { useMemo, useState } from "react";
import moment from "moment";
import { useRentHousesObjects } from "../../../../../api/hooks/rentHouses";
import { RentHousesObjectStatus } from "../../../../../types/rentHouses";
import { Sorting } from "../../../../../constants/filters";

export const useYearsSelect = () => {
    const { data: objectsData } = useRentHousesObjects({
        page: 1,
        pageSize: 100,
        status: [RentHousesObjectStatus.PUBLISHED, RentHousesObjectStatus.ARCHIVED],
        sort: Sorting.TitleAsc,
    });
    const start = useMemo(
        () =>
            objectsData?.data?.items?.reduce((oldest, object) => {
                const objectYear = moment(object.createdAt).year();

                return objectYear < oldest ? objectYear : oldest;
            }, moment().year()),
        [objectsData]
    );

    const years = useMemo(() => {
        if (!start) return [];

        const nextYear = moment().year() + 1;

        return [...new Array(nextYear - start + 1)].map((_, idx) => {
            const year = idx + start;
            return { label: `${year}`, value: `${year}` };
        });
    }, [start]);

    const [activeYear, setActiveYear] = useState(`${moment().year()}`);

    return { years, activeYear, setActiveYear };
};
