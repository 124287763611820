import React, { useCallback } from "react";
import { Tariff } from "./types";
import "./styles.sass";
import { ReactComponent as DeleteImg } from "../../../../assets/images/icons/ic_cross_circle.svg";
import { ReactComponent as CopyImg } from "../../../../assets/images/icons/ic_copy_circle.svg";
import { QuestionTooltip, Tooltip } from "@russpass/partner-front-ui";
import { getTariffItemDays } from "./utils";
import { ReactComponent as RoubleIcon } from "../../../../assets/images/icons/circle-rouble-icon.svg";

type Props = {
    data: Tariff;
    isDeleting?: boolean;
    onDelete: (tariff: Tariff) => void;
    isCopy?: boolean;
    isBasic: boolean;
    onClick: (tariff: Tariff) => void;
    onCopy: (tariff: Tariff) => void;
    isVisiblePlaceTimetable?: boolean;
    isRenderValueWithPronoun?: boolean;
};

const TariffsItem = ({
    data,
    onDelete,
    onClick,
    isCopy,
    onCopy,
    isDeleting,
    isVisiblePlaceTimetable,
    isRenderValueWithPronoun,
    isBasic,
}: Props) => {
    const handleItemClick = useCallback(() => onClick(data), [onClick, data]);

    const handleDeleteClick = useCallback(
        (event) => {
            event.stopPropagation();
            onDelete(data);
        },
        [onDelete, data]
    );

    const handleCopyClick = useCallback(
        (event) => {
            event.stopPropagation();
            onCopy(data);
        },
        [onCopy, data]
    );

    return (
        <div className="service_tariff_item" onClick={handleItemClick} data-qa-name="service-tariff-item">
            {isDeleting && (
                <div className="service_tariff_item__delete" data-qa-name="delete-tariff-button">
                    <Tooltip offset={{ X: 120, Y: 1 }} overlay="Удалить тариф" tooltipWidth="max-content">
                        <DeleteImg onClick={handleDeleteClick} />
                    </Tooltip>
                </div>
            )}
            {isCopy && (
                <div className="service_tariff_item__copy" data-qa-name="copy-tariff-button">
                    <Tooltip overlay="Дублировать тариф" tooltipWidth="max-content" offset={{ X: 160, Y: 1 }}>
                        <CopyImg onClick={handleCopyClick} />
                    </Tooltip>
                </div>
            )}
            {isBasic && (
                <div className="service_tariff_item__basic" onClick={(event) => event.stopPropagation()}>
                    <QuestionTooltip
                        settings={{ offsetX: 205, offsetY: -10 }}
                        icon={<RoubleIcon />}
                        body="Базовый тариф - отображается в каталоге на карточке услуги"
                    />
                </div>
            )}

            <div className="service_tariff_item__column__left_side">
                <div className="service_tariff_item__title">{data.tariffCaption && data.tariffCaption[0].content}</div>
                <div className="service_tariff_item__days">
                    {getTariffItemDays(
                        data.workingDays,
                        data.activeSince,
                        data.activeUntil,
                        isRenderValueWithPronoun,
                        isVisiblePlaceTimetable
                    )}
                </div>
            </div>
            <div className="service_tariff_item__column">
                <div className="service_tariff_item__price">{`${data.itemPrices[0].price} ₽`}</div>
                {isVisiblePlaceTimetable && (
                    <div className="service_tariff_item__time">
                        {data.openedSince.substring(0, 5) + " - " + data.openedUntil.substring(0, 5)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TariffsItem;
