import { ReactNode, useCallback, useState } from "react";
import { patchExtraBonus } from "../../../../../api/ratings";
import { ReactComponent as StarIcon } from "../../../../../assets/images/icons/ic_star.svg";
import { Loader, StatusRequestEnum } from "@russpass/partner-front-ui";
import { GET_DATA_ERROR } from "../../../../../constants/errors";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import getCustomErrorMessage from "../../../../../utils/getCustomErrorMessage";

type Props = {
    id: number;
    loyalityExtraAccrued: boolean;
    config?: {
        labelBefore?: string;
        labelAfter?: string;
        icon?: ReactNode;
    };
    onUpdate: () => void;
};

const RatingLoyalty = ({ id, loyalityExtraAccrued, config, onUpdate }: Props) => {
    const [isLoading, seIsLoading] = useState(false);
    const defaultConfig = {
        labelBefore: "Начислить дополнительные бонусы",
        labelAfter: "Были начислены бонусы",
        icon: <StarIcon className="button__icon--left" />,
    };
    const { labelBefore, labelAfter, icon } = { ...defaultConfig, ...config };

    const handleExtraBonuses = useCallback(async () => {
        seIsLoading(true);
        try {
            const response = await patchExtraBonus(id);
            if (response.errorCode && /^2\d{3}$/.test(`${response.errorCode}`)) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: response.errorCause || GET_DATA_ERROR,
                });
            }
            onUpdate();
        } catch (error) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: getCustomErrorMessage(error, GET_DATA_ERROR),
            });
        } finally {
            seIsLoading(false);
        }
    }, [id, onUpdate]);

    return (
        <div className="ratings-card__bonuses">
            {isLoading ? (
                <div className="content-wrapper--center mt-32 mb-32">
                    <Loader width="48px" />
                </div>
            ) : loyalityExtraAccrued ? (
                <span>
                    {icon} {labelAfter}
                </span>
            ) : (
                <button className="button button--second" type="button" onClick={handleExtraBonuses}>
                    {icon}
                    {labelBefore}
                </button>
            )}
        </div>
    );
};
export default RatingLoyalty;
