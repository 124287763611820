import React, { useMemo } from "react";
import { getIn, useFormikContext } from "formik";
import uuid from "react-native-uuid";
import { MultiMenuPlaceholderColor } from "@russpass/partner-front-ui";
import DateControl from "../../../../../../components/timetable/place-timetable/date-control/date-control";

type Props = {
    name: string;
    label: string;
    placeholder: string;
    disabledWeekDays?: string[];
    multipleRangeSelect?: boolean;
    disabled?: boolean;
};

export const DatePickerField = ({
    name,
    label,
    placeholder,
    disabledWeekDays,
    multipleRangeSelect = false,
    disabled,
}: Props) => {
    const { setFieldValue, errors, values, submitCount } = useFormikContext();
    const valueDate = useMemo(() => getIn(values, name + ".dates") || [], [name, values]);
    const id = useMemo(() => getIn(values, name + ".id") || [], [name, values]);
    const error = useMemo(() => getIn(errors, name + ".dates") || [], [errors, name]);

    const randomId = useMemo(() => id || uuid.v4(), [id]);

    return (
        <DateControl
            name={name}
            handlerChange={(values, activeTab) => {
                setFieldValue(name + ".dates", values);
                setFieldValue(name + ".scheduleType", activeTab);
                setFieldValue(name + ".id", randomId);
            }}
            isExistingTariff={false}
            value={valueDate}
            error={submitCount ? error : undefined}
            label={label}
            placeholder={placeholder}
            placeholderColor={MultiMenuPlaceholderColor.LightSilver}
            disabledWeekDays={disabledWeekDays}
            minWidth={378}
            modalTitle={label}
            fromMonth={new Date()}
            multipleRangeSelect={multipleRangeSelect}
            disabled={disabled}
        />
    );
};
