import { DiscountBonusType, DiscountStatus, DiscountType } from "../types/hotel-discounts";

export const navbarDiscountsNames = {
    active: DiscountStatus.PUBLISHED,
    moderation: DiscountStatus.TO_PUBLISH,
    archive: `${DiscountStatus.REFUSED},${DiscountStatus.ARCHIVE},${DiscountStatus.DRAFT}`,
};

export const navbarDiscounts = [
    {
        label: "Действующие",
        name: navbarDiscountsNames.active,
    },
    {
        label: "На модерации",
        name: navbarDiscountsNames.moderation,
    },
    {
        label: "Архив",
        name: navbarDiscountsNames.archive,
    },
];

export const DiscountTypeLabel: Readonly<Record<DiscountType, string>> = {
    [DiscountType.DISCOUNT]: "Скидка",
    [DiscountType.BONUS]: "Бонус",
};

export const DiscountStatusLabel: Readonly<Record<DiscountStatus, string>> = {
    [DiscountStatus.PUBLISHED]: "Действующий",
    [DiscountStatus.ARCHIVE]: "Завершена",
    [DiscountStatus.DRAFT]: "Черновик",
    [DiscountStatus.REFUSED]: "Отклонена",
    [DiscountStatus.TO_PUBLISH]: "На модерации",
};

export const DiscountBonusTypeLabel: Readonly<Record<DiscountBonusType, string>> = {
    [DiscountBonusType.BONUS_BREAKFAST]: "Бесплатный завтрак",
    [DiscountBonusType.BONUS_UPGRADE]: "Повышение категории номера",
};

export const DiscountSubmitButton: Readonly<Partial<Record<DiscountStatus, string>>> = {
    [DiscountStatus.PUBLISHED]: "Завершить акцию досрочно",
    [DiscountStatus.TO_PUBLISH]: "Снять с модерации",
    [DiscountStatus.ARCHIVE]: "Редактировать и опубликовать повторно",
    [DiscountStatus.DRAFT]: "Отправить на модерацию",
    [DiscountStatus.REFUSED]: "Редактировать",
};
