import styles from "./OtpInput.module.sass";
import VerificationInput from "react-verification-input";
import React, { useEffect } from "react";
import { useViewportContext } from "@russpass/partner-front-ui";
import classNames from "classnames";

const OTP_LENGTH = 4;

type Props = {
    value: string;
    onChange: (value: string) => void;
    onComplete: () => void;
};

const OtpInput = ({ value, onChange, onComplete }: Props) => {
    const { isMobile } = useViewportContext();

    useEffect(() => {
        if (value.length !== OTP_LENGTH) return;

        onComplete();
    }, [value, onComplete]);

    return (
        <VerificationInput
            length={OTP_LENGTH}
            autoFocus
            value={value}
            placeholder=""
            validChars="0-9"
            classNames={{
                container: classNames(styles.InputContainer, { [styles.Compact]: isMobile }),
                character: styles.InputCharacter,
                characterSelected: styles.InputCharacterSelected,
            }}
            onChange={onChange}
            inputProps={{
                pattern: "d*",
            }}
        />
    );
};

export default OtpInput;
