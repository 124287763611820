import { navbarProfile, navbarProfileName } from "../constants";
import useUserInfo from "../../../hooks/useUserInfo";

export const useNavbar = () => {
    const { isSignedApartment, isUL, isChecked, isReview, isMobileVerified, isSignedGuide } = useUserInfo();

    const { userFullInfo } = useUserInfo();

    if (!userFullInfo) return;

    const disabledTabs: string[] = [];
    if (!isSignedApartment) {
        disabledTabs.push(navbarProfileName.user);
    }
    if (!isSignedGuide) {
        disabledTabs.push(navbarProfileName.userGuide);
    }
    if (!isUL) {
        disabledTabs.push(navbarProfileName.company);
    }
    if (!(isChecked || isReview)) {
        disabledTabs.push(navbarProfileName.bankDetails);
    }

    const alertTabs: string[] = [];
    if (!isMobileVerified) {
        alertTabs.push(navbarProfileName.contact);
    }

    return navbarProfile
        .filter((el) => !disabledTabs.includes(`${el.name}`))
        .map((el) =>
            alertTabs.includes(`${el.name}`)
                ? {
                      ...el,
                      alert: true,
                  }
                : el
        );
};
