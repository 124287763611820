// TODO Возможно стоит использовать один enum для всех видов вкладок объектов
export const navbarHotelsNames = {
    all: "ALL",
    draft: "DRAFT",
    moderation: "MODERATION",
    published: "PUBLISHED",
    refused: "DECLINED",
    archive: "ARCHIVED",
};

export const navbarHotels = [
    {
        label: "Все",
        name: navbarHotelsNames.all,
    },
    {
        label: "Черновик",
        name: navbarHotelsNames.draft,
    },
    {
        label: "На модерации",
        name: navbarHotelsNames.moderation,
    },
    {
        label: "Опубликовано",
        name: navbarHotelsNames.published,
    },
    {
        label: "Отказано в публикации",
        name: navbarHotelsNames.refused,
    },
    {
        label: "Архив",
        name: navbarHotelsNames.archive,
    },
];

export const statusLabelsHotels = {
    draft: "Черновик",
    moderation: "На модерации",
    published: "Опубликовано",
    refused: "Отказано",
    archive: "Архив",
};

// TODO есть enum HotelStatuses
export const allStatusesHotel = {
    deleted: "deleted",
    inactive: "inactive",
    draft: "draft",
    import: "import",
    active: "active",
};
export const hotelsCategoryLabel = {
    hotel: "Отель",
};
