import { useCallback, useEffect, useState } from "react";
import {
    getBeachServices,
    getCommonServices,
    getEntertainmentServices,
    getFacilityServices,
    getFitnessServices,
    getMealsServices,
} from "../../../../api/dictionaries";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../../constants/errors";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { sortingAscByField } from "../../../../utils/sorting";
import { TSelectValue } from "../../../../types/dictionaries";

const useServicesData = (isViewMeals = true, isLoadingDelay?: boolean) => {
    const [isLoadingServices, setIsLoadingServices] = useState(false);
    const [isLoadedServices, setIsLoadedServices] = useState(false);

    const [commonServices, setCommonServices] = useState<TSelectValue[]>([]);
    const [meals, setMeals] = useState<TSelectValue[]>([]);
    const [beachServices, setBeachServices] = useState<TSelectValue[]>([]);
    const [entertainmentServices, setEntertainmentServices] = useState<TSelectValue[]>([]);
    const [fitnessServices, setFitnessServices] = useState<TSelectValue[]>([]);
    const [facilityServices, setFacilityServices] = useState<TSelectValue[]>([]);

    const loadData = useCallback(() => {
        if (isLoadedServices) {
            return;
        }
        setIsLoadingServices(true);
        let requestArray = [
            new Promise(async (resolve) => {
                try {
                    const content = await getCommonServices();
                    setCommonServices(content.rows.sort(sortingAscByField("title")));
                    return resolve(content);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const content = await getBeachServices();
                    setBeachServices(content.rows.sort(sortingAscByField("title")));
                    return resolve(content);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const content = await getEntertainmentServices();
                    setEntertainmentServices(content.rows.sort(sortingAscByField("title")));
                    return resolve(content);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const content = await getFitnessServices();
                    setFitnessServices(content.rows.sort(sortingAscByField("title")));
                    return resolve(content);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const content = await getFacilityServices();
                    setFacilityServices(content.rows.sort(sortingAscByField("title")));
                    return resolve(content);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        ];
        if (isViewMeals) {
            requestArray.push(
                new Promise(async (resolve) => {
                    try {
                        const content = await getMealsServices();
                        setMeals(content.rows.sort(sortingAscByField("title")));
                        return resolve(content);
                    } catch (err) {
                        console.error(err);
                        eventDispatcher.setNotification({
                            status: StatusRequestEnum.Error,
                            body: GET_DATA_ERROR,
                        });
                    }
                })
            );
        }
        Promise.all(requestArray).then(() => {
            setIsLoadingServices(false);
            setIsLoadedServices(true);
        });
    }, [isViewMeals, isLoadedServices]);

    useEffect(() => {
        if (!isLoadingDelay && !isLoadedServices) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingDelay]);

    return {
        isLoadingServices,
        loadServices: loadData,
        services: {
            commonServices,
            meals,
            beachServices,
            entertainmentServices,
            fitnessServices,
            facilityServices,
        },
    };
};

export default useServicesData;
