import React, { useCallback, useMemo } from "react";
import { Occupancy } from "../../../types/hotel-room";
import styles from "../occupancies.module.sass";
import { cloneDeep } from "lodash";
import { getChildOccupancyTL } from "../../../constants/hotel-room";
import { ReactComponent as Plus } from "../../../../../../../assets/images/icons/ic_plus.svg";
import ChildOccupancy from "./child-occupancy";
import { CounterField, ErrorField, Divider } from "@russpass/partner-front-ui";
import classNames from "classnames";
import { tlHotelRoomOccupanciesValidation } from "../../../validation-schemas/hotel-room";

type OccupancyChooserTLProps = {
    occupancies: Occupancy[];
    onChange: (field: string, values: Occupancy[]) => void;
};

const OccupancyChooserTL = ({ occupancies, onChange }: OccupancyChooserTLProps) => {
    const addChildOccupancy = useCallback(() => {
        if (tlHotelRoomOccupanciesValidation.isValidSync(occupancies)) {
            onChange("occupancies", [...occupancies, cloneDeep(getChildOccupancyTL())]);
        }
    }, [occupancies, onChange]);

    const deleteChildOccupancy = useCallback(
        (childOccupancyId: string) => {
            onChange(
                "occupancies",
                occupancies.filter((item) => item.id !== childOccupancyId)
            );
        },
        [occupancies, onChange]
    );

    const maxCountGuest = useMemo(() => {
        let guests = 0;
        occupancies.forEach((item) => {
            guests += item.adults + item.child;
        });
        return guests;
    }, [occupancies]);

    return (
        <div className={styles.occupancies__wrapper}>
            <div className={styles.occupancies__title}>Размещение</div>

            {occupancies.map((occupancy, index) => {
                return (
                    <React.Fragment key={occupancy.id}>
                        {index !== 0 && <Divider />}
                        {occupancy.adults > 0 ? (
                            <div className={styles.occupancyTl}>
                                <div
                                    className={classNames(
                                        styles.occupancyTl__wrapper,
                                        styles.occupancyTl__wrapper_flex,
                                        "mt-16",
                                        "mb-16"
                                    )}
                                >
                                    <div className="body">Основные спальные места</div>
                                    <CounterField name={`occupancies[${index}].adults`} max={20} min={1} />
                                </div>
                            </div>
                        ) : (
                            <ChildOccupancy
                                value={occupancy}
                                isViewHint={index > 1}
                                name={`occupancies[${index}]`}
                                onDelete={deleteChildOccupancy}
                            />
                        )}
                    </React.Fragment>
                );
            })}

            {occupancies.length < 3 && (
                <button className={classNames("button", "ml-8", "mb-8")} type="button" onClick={addChildOccupancy}>
                    <div className="button__icon button__icon--left">
                        <Plus />
                    </div>
                    {occupancies.length < 2 ? "Дополнительные места для детей" : "Места для детей другого возраста"}
                </button>
            )}

            <Divider />

            <div className={classNames(styles.occupancyTl__wrapper, styles.occupancyTl__wrapper_flex, "mt-24")}>
                <div className="body">Максимальное количество гостей</div>
                <div className="body">{maxCountGuest}</div>
            </div>

            <div className="mt-24 ml-24">
                <ErrorField
                    name={`occ`}
                    errorSettings={{
                        showOnChange: true,
                    }}
                />
            </div>
        </div>
    );
};

export default OccupancyChooserTL;
