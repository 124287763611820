import { useRentHousesDictionary } from "../../../../../api/hooks/rentHouses";
import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { StatusRequestEnum, MultiCheckedField } from "@russpass/partner-front-ui";
import { UNEXPECTED_ERROR } from "../../../../../constants/errors";
import { BaseFieldProps } from "../../../../documents/offer/company-info/components/fields/types";

type Option = { value: string; label: string };

const SelectLanguagesField = ({ name, disabled }: BaseFieldProps) => {
    const { data, error } = useRentHousesDictionary(
        { dictionary: "language", pageSize: 999, sort: "dictionary_data.title" },
        { revalidateOnFocus: false }
    );

    useEffect(() => {
        if (!error) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [error]);

    const { setFieldValue, getFieldProps } = useFormikContext();

    const options = useMemo(
        () => data?.data.items.map((el) => ({ value: el.id, label: el.dictionary_data?.title || "" })) || [],
        [data]
    );

    const fieldValue = getFieldProps(name).value as string[] | undefined;
    const checkedOptions = useMemo(
        () =>
            (fieldValue?.map((el) => options.find((option) => option.value === el)).filter(Boolean) as Option[]) || [],
        [fieldValue, options]
    );

    return (
        <MultiCheckedField
            placeholder="Выбрать"
            label="Знание языков"
            name={name}
            value={checkedOptions}
            options={options}
            onChange={(value) => setFieldValue(name, value?.map((el: Option) => el.value) || [])}
            noHover
            disabled={disabled}
            modalTitle="Знание языков"
        />
    );
};

export default SelectLanguagesField;
